import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { toData } from '../../../../service/rest/response-mapping';

import { RestResponse } from '../../../../model/response.interface';
import { NameAvailabilityResponse } from '../../../../model/rest-response/availability-response';
import { DeleteResponse } from '../../../../model/rest-response/delete-response.interface';
import { PostResponse } from '../../../../model/rest-response/post-response.interface';
import { PutResponse } from '../../../../model/rest-response/put-response.interface';
import { encodeParams } from '../../../../security/encode-params';
import { encodeEndpoint } from '../../../../security/encode-url';
import { ReserveRequest } from '../model/reserve-request.interface';
import { Reserve, ReserveVariantBase } from '../model/reserve.interface';

@Injectable()
export class ReserveResourceService {
  constructor(private http: HttpClient) {}

  upsertReserve(
    isEditedReserve: boolean,
    transactionId: number,
    reserveRequest: ReserveRequest<ReserveVariantBase>,
  ) {
    const url = isEditedReserve
      ? encodeEndpoint(
          'resources/transactions/{}/reserve/{}',
          transactionId,
          reserveRequest.entityId,
        )
      : encodeEndpoint('resources/transactions/{}/reserve', transactionId);

    if (isEditedReserve) {
      return this.http.put<RestResponse<PutResponse>>(url, reserveRequest).pipe(map(toData));
    } else {
      return this.http.post<RestResponse<PostResponse>>(url, reserveRequest).pipe(map(toData));
    }
  }

  getReserves(transactionId: number, transactionRevision: number, versionPreviewMode?: boolean) {
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/reserve',
          transactionId,
          transactionRevision,
        )
      : encodeEndpoint('resources/transactions/{}/reserve', transactionId);

    return this.http.get<RestResponse<Reserve<ReserveVariantBase>[]>>(url).pipe(map(toData));
  }

  deleteReserve(transactionId: number, reserveId: string, entityRevision: number) {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint('resources/transactions/{}/reserve/{}', transactionId, reserveId);
    return this.http.delete<RestResponse<DeleteResponse>>(url, { params }).pipe(map(toData));
  }

  checkReserveNameAvailable(transactionId: number, name: string, reserveId: string) {
    const params = encodeParams({ name, reserveId });
    const url = encodeEndpoint(
      'resources/transactions/{}/reserve/name-availability',
      transactionId,
    );
    return this.http
      .get<RestResponse<NameAvailabilityResponse>>(url, { params })
      .pipe(map((response) => toData(response).nameAvailable));
  }
}
