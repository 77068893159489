import { authorisationGuard, TRFRoute, TRFRoutes } from '@demica/core/core';

import { InsurersViewComponent } from '../feature-modules/insurers/view/insurers-view.component';

import { insurersMenuOptionRoutePermissions } from './insurers.routes-permissions';

export const insurersMenuOptionRoute: TRFRoute = {
  component: InsurersViewComponent,
  canActivate: [authorisationGuard],
  path: 'insurers',
  ...insurersMenuOptionRoutePermissions,
};

export const insurersRoutes: TRFRoutes = [insurersMenuOptionRoute];
