import { ComponentRef, Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { SORTABLE_TABLE_HEADER } from '../model/component-selector-constants';

import { FOCUSABLE_DATASET_ATTRIBUTE } from '../model/focusable.const';

@Injectable({ providedIn: 'root' })
export class KeyboardNavigationStrategyService {
  lastActionIconId$ = new BehaviorSubject<number | null>(null);
  lastActionParentTrIndex$ = new BehaviorSubject<number | null>(null);

  onRowKeyNavigate(event: KeyboardEvent): void {
    const key = event.key;
    const target = event.target as HTMLElement;
    const isSortableElement = handleSortableHeaderGenericTable(target, key);

    if (isArrowDown(key)) {
      const parentTr = findClosestTrParent(target);
      const tdIndex = findClosestTdParent(target)?.getAttribute('data-index') as string;

      if (isTableHeader(target)) {
        const nextElement = prepareTdRowElementForHeader(target);

        return nextElement?.focus();
      }

      if (parentTr?.nextElementSibling?.children.item(+tdIndex)) {
        const nextTr = parentTr?.nextElementSibling?.children.item(+tdIndex) as HTMLElement | null;
        const nextFocusableElement = nextTr && findClosestFocusableElements(nextTr, target);

        return nextFocusableElement?.focus();
      }
    }

    if (isArrowUp(key) && !findHeaderParent(target)) {
      const parentTd = findClosestTdParent(target);
      const parentTr = findClosestTrParent(target);

      const tdIndex = parentTd?.getAttribute('data-index') as string;

      if (
        parentTr?.previousElementSibling?.tagName &&
        !isTrRowColumn(parentTr.previousElementSibling.tagName)
      ) {
        prepareHeaderForTdRowElement(parentTd);
      }

      if (parentTr?.previousElementSibling?.children.item(+tdIndex)) {
        const previousTr = parentTr?.previousElementSibling?.children.item(
          +tdIndex,
        ) as HTMLElement | null;
        const previousFocusableElements =
          previousTr && findClosestFocusableElements(previousTr, target);

        return previousFocusableElements?.focus();
      }
    }

    if (isArrowLeft(key) && !isSortableElement) {
      const parentTd = findClosestTdParent(target);

      if (target.previousElementSibling || parentTd?.previousElementSibling) {
        const previousElement = (
          target.previousElementSibling
            ? target.previousElementSibling
            : parentTd?.previousElementSibling
        ) as HTMLElement;
        const previousFocusableElement = findFirstClosestFocusableElement(previousElement);

        previousFocusableElement?.focus();
      }
    }

    if (isArrowRight(key) && !isSortableElement) {
      const parentTd = findClosestTdParent(target);

      if (target.nextElementSibling || parentTd?.nextElementSibling) {
        const nextElement = (
          target.nextElementSibling ? target.nextElementSibling : parentTd?.nextElementSibling
        ) as HTMLElement;
        const nextFocusableElement = findFirstClosestFocusableElement(nextElement);

        nextFocusableElement?.focus();
      }
    }
  }

  onFocusToTable(key: string, firstHeaderElement: HTMLElement): void {
    if (isArrowUp(key) || isArrowDown(key) || isArrowLeft(key) || isArrowRight(key)) {
      firstHeaderElement.focus();
    }
  }

  onTableCheckboxKeyup(event: KeyboardEvent, action: (checked: boolean) => void): void {
    const td = event.target as HTMLTableCellElement;
    const checkbox = findClosestCheckbox(td);
    const checked = Boolean(JSON.parse(checkbox?.getAttribute('aria-checked') as string));

    action(!checked);
  }

  expandableTableNavigation(event: KeyboardEvent): void {
    const key = event.key;
    const target = event.target as HTMLElement;

    if (isArrowDown(key)) {
      const trParent = findClosestOrdinaryTrParent(target);
      const tdIndex = findClosestTdParent(target)
        ? (findClosestTdParent(target)?.getAttribute('data-index') as string)
        : (findClosestThParent(target)?.getAttribute('data-index') as string);

      if (trParent?.nextElementSibling?.children.item(+tdIndex)) {
        const nextTd = trParent?.nextElementSibling?.children?.item(+tdIndex) as HTMLElement | null;
        const nextFocusableElement = nextTd && findFirstClosestFocusableElement(nextTd);

        return nextFocusableElement?.focus();
      }
    }

    if (isArrowUp(key)) {
      const trParent = findClosestOrdinaryTrParent(target);
      const tdIndex = findClosestTdParent(target)
        ? (findClosestTdParent(target)?.getAttribute('data-index') as string)
        : (findClosestThParent(target)?.getAttribute('data-index') as string);

      if (
        trParent?.previousElementSibling &&
        trParent.previousElementSibling.children.item(+tdIndex)
      ) {
        const previousTd = trParent.previousElementSibling?.children?.item(
          +tdIndex,
        ) as HTMLElement | null;
        const focusableElement = previousTd && findFirstClosestFocusableElement(previousTd);

        return focusableElement?.focus();
      } else {
        const parentTd = findClosestTdParent(target);

        prepareHeaderForExpandedTableTdRowElement(parentTd);
      }
    }
  }

  ordinaryTableNavigation(event: KeyboardEvent): void {
    const key = event.key;
    const target = event.target as HTMLElement;

    handleSortableHeader(target, key);

    handleTooltipHeader(target);

    if (isArrowDown(key)) {
      const trParent = findClosestOrdinaryTrParent(target);

      if (target.tagName.toLowerCase() === 'th') {
        const nextElement = prepareTdRowForOrdinaryTableHeader(target);

        nextElement?.focus();
      }

      if (trParent?.nextElementSibling) {
        const tdIndex = findClosestTdParent(target)?.getAttribute('data-index') as string;
        const nextTd = trParent?.nextElementSibling?.children.item(+tdIndex) as HTMLElement | null;
        const nextFocusableElement = nextTd && findClosestFocusableElements(nextTd, target);

        nextFocusableElement?.focus();
      }
    }

    if (isArrowUp(key) && !findClosestThParent(target)) {
      const trParent = findClosestOrdinaryTrParent(target);
      const tdIndex = findClosestTdParent(target)?.getAttribute('data-index') as string;

      if (!trParent?.previousElementSibling) {
        const headerElement = prepareHeaderForTdRowElementForOrdinaryTable(target);

        return headerElement?.focus();
      }

      const previousTd = trParent?.previousElementSibling.children.item(
        +tdIndex,
      ) as HTMLElement | null;
      const focusableElement = previousTd && findClosestFocusableElements(previousTd, target);

      focusableElement?.focus();
    }

    if (isArrowLeft(key)) {
      if (target.tagName.toLowerCase() === 'th' && target.previousElementSibling) {
        const previousElement = target.previousElementSibling as HTMLElement;

        previousElement.focus();
      }
      this.onRowKeyNavigate(event);
    }

    if (isArrowRight(key)) {
      if (target.tagName.toLowerCase() === 'th' && target.nextElementSibling) {
        const nextElement = target.nextElementSibling as HTMLElement;

        nextElement.focus();
      }
      this.onRowKeyNavigate(event);
    }
  }

  onFocusToOrdinaryTable(target: HTMLElement): void {
    const firstElement = Array.from(target.getElementsByTagName('tr')[0].children).filter(
      (elem) => elem.getAttribute('tabindex') === '-1',
    )[0] as HTMLElement;

    firstElement.focus();
  }

  setLastActionElements(actionIconId: number, target: HTMLElement): void {
    const parentTr = findClosestTrParent(target);
    const table = findTableParent(target);
    const tbody = table?.getElementsByTagName('tbody') as HTMLCollectionOf<HTMLTableSectionElement>;
    const index = Array.from(tbody[0].children).findIndex((value) => value === parentTr);

    this.lastActionIconId$.next(actionIconId);
    this.lastActionParentTrIndex$.next(index);
  }

  focusLastActionElement(rowsRefs: ComponentRef<unknown>[]): void {
    const index = (this.lastActionParentTrIndex$.getValue() as number) - 1;
    const iElements = rowsRefs[index].location.nativeElement.getElementsByTagName('i');

    iElements[this.lastActionIconId$.getValue() as number]?.focus();
  }

  isTabIndexNeeded(element: HTMLElement): boolean {
    const focusedElement = findFirstClosestFocusableElement(element);

    return (
      !!focusedElement &&
      focusedElement.parentElement?.tagName.toLowerCase() !== SORTABLE_TABLE_HEADER
    );
  }
}

function prepareTdRowElementForHeader(target: HTMLElement): HTMLElement | undefined {
  const headerIndex = target.getAttribute('data-index') as string;
  const table = findTableParent(target);
  const tbody = table?.getElementsByTagName('tbody') as HTMLCollectionOf<HTMLTableSectionElement>;
  const closestTr = findClosestTrRowChild(Array.from(tbody[0].children) as HTMLElement[]);
  const td = closestTr?.children.item(+headerIndex) as HTMLElement | null;
  const closestFocusableElement = td && findFirstClosestFocusableElement(td);

  return closestFocusableElement ?? undefined;
}

function prepareHeaderForTdRowElement(target: HTMLElement | null): void {
  const tdIndex = target?.getAttribute('data-index') as string;
  const table = findTableParent(target);
  const thead = table?.getElementsByTagName('thead') as HTMLCollectionOf<HTMLTableSectionElement>;
  const previousTd = thead[0].getElementsByTagName('td').item(+tdIndex);

  previousTd?.focus();
}

function prepareHeaderForExpandedTableTdRowElement(target: HTMLElement | null): void {
  const tdIndex = target?.getAttribute('data-index') as string;
  const table = findTableParent(target);
  const thead = table?.getElementsByTagName('thead') as HTMLCollectionOf<HTMLTableSectionElement>;
  const previousTd = thead[0].getElementsByTagName('th').item(+tdIndex);

  previousTd?.focus();
}

function prepareTdRowForOrdinaryTableHeader(target: HTMLElement): HTMLElement | undefined {
  const index = target.getAttribute('data-index') as string;
  const table = findTableParent(target);
  const tbody = table?.getElementsByTagName('tbody')[0];
  const closestBodyTr = tbody?.children[0];
  const closestBodyDataIndex = closestBodyTr?.children?.item(+index) as HTMLElement | null;
  const closestFocusableElement =
    closestBodyDataIndex && findFirstClosestFocusableElement(closestBodyDataIndex);

  return closestFocusableElement ?? undefined;
}

function prepareHeaderForTdRowElementForOrdinaryTable(
  target: HTMLElement,
): HTMLElement | undefined {
  const index = findClosestTdParent(target)?.getAttribute('data-index') as string;
  const table = findTableParent(target);
  const thead = table?.getElementsByTagName('thead') as HTMLCollectionOf<HTMLTableSectionElement>;
  const headerTd = thead[0].getElementsByTagName('th').item(+index);
  const focusableElement = headerTd && findFirstClosestFocusableElement(headerTd);

  return focusableElement ?? undefined;
}

function handleSortableHeader(target: HTMLElement, key: string): void {
  if (target?.parentElement?.tagName?.toLowerCase() === SORTABLE_TABLE_HEADER) {
    const thParent = findClosestThParent(target);

    thParent?.focus();
  }

  if (isEnter(key) && target.tagName.toLowerCase() === 'th') {
    const sortableColumn = findSortableColumn(target);

    if (sortableColumn) {
      const interactiveElement = findFirstClosestFocusableElement(sortableColumn);

      interactiveElement?.click();
      interactiveElement?.focus();
    }
  }
}

function handleSortableHeaderGenericTable(target: HTMLElement, key: string): boolean {
  if (target.getAttribute('id')?.includes('sortableElement')) {
    if (isArrowUp(key) || isArrowDown(key) || isArrowLeft(key) || isArrowRight(key)) {
      const tdParent = findClosestTdParent(target);

      tdParent?.focus();

      return true;
    }
  }

  return false;
}

function handleTooltipHeader(target: HTMLElement): void {
  if (target.parentElement?.parentElement?.tagName.toLowerCase() === 'ngp-tooltip-icon') {
    const thParent = findClosestThParent(target);

    thParent?.focus();
  }
}

function getParentChain(elem: HTMLElement | null): HTMLElement[] {
  const parentChain = [];
  let current = elem;

  while (current?.parentElement) {
    parentChain.push(current);
    current = current.parentElement;
  }

  return parentChain;
}

function getChildChain(elem: HTMLElement): HTMLElement[] {
  const childChain = [];
  let current = elem;

  while (current.children && current.children[0]) {
    current = current.children[0] as HTMLElement;
    childChain.push(current);
  }

  return childChain;
}

function getFullChain(elem: HTMLElement | null): HTMLElement[] {
  const childChain = [];
  let current = elem;

  if (current) childChain.push(current);

  while (current?.children && current.children[0]) {
    current = current.children[0] as HTMLElement;

    if (current) childChain.push(current);
  }

  return childChain;
}

function findClosestThParent(elem: HTMLElement): HTMLElement | null {
  const parentChain = getParentChain(elem);

  for (const e of parentChain) {
    if (e.tagName?.toLowerCase() === 'th') return e;
  }

  return null;
}

function findClosestTdParent(elem: HTMLElement | null): HTMLElement | null {
  const parentChain = getParentChain(elem);

  for (const e of parentChain) {
    if (e.tagName?.toLowerCase() === 'td') return e;
  }

  return null;
}

function findClosestTrParent(elem: HTMLElement): HTMLElement | null {
  const parentChain = getParentChain(elem);

  for (const e of parentChain) {
    if (e.tagName && isTrRowColumn(e.tagName)) return e;
  }

  return null;
}

function findClosestOrdinaryTrParent(elem: HTMLElement): HTMLElement | null {
  const parentChain = getParentChain(elem);

  for (const e of parentChain) {
    if (e.tagName?.toLowerCase() === 'tr') return e;
  }

  return null;
}

function findTableParent(elem: HTMLElement | null): HTMLElement | null {
  const parentChain = getParentChain(elem);

  for (const e of parentChain) {
    if (e.tagName?.toLowerCase() === 'table') return e;
  }

  return null;
}

function findHeaderParent(elem: HTMLElement): boolean {
  const parentChain = getParentChain(elem);

  return parentChain.some((el: HTMLElement) => el.tagName?.toLowerCase() === 'trf-table-header');
}

function findClosestCheckbox(elem: HTMLElement): HTMLElement | null {
  const childChain = getChildChain(elem) as HTMLElement[];

  for (const e of childChain) {
    if (e.getAttribute('aria-checked')) return e;
  }

  return null;
}

function findClosestTrRowChild(tbody: HTMLElement[]): HTMLElement | null {
  for (const e of tbody) {
    if (isTrRowColumn(e.tagName)) return e;
  }

  return null;
}

function findSortableColumn(elem: HTMLElement): HTMLElement | null {
  const childChain = getChildChain(elem);

  for (const e of childChain) if (e.tagName?.toLowerCase() === SORTABLE_TABLE_HEADER) return e;

  return null;
}

function findClosestFocusableElements(elem: HTMLElement, target: HTMLElement): HTMLElement | null {
  const childChain = getFullChain(elem);

  for (const e of childChain) {
    if (isFocusable(e as HTMLElement)) {
      return target.tagName.toLowerCase() === 'td' ||
        (e.parentElement?.children && e.parentElement.children.length < 2)
        ? e
        : (Array.from(e.parentElement?.children || [])[
            +(target.getAttribute('data-index') as string)
          ] as HTMLElement) ?? null;
    }
  }

  return null;
}

function findFirstClosestFocusableElement(elem: HTMLElement): HTMLElement | undefined {
  return getFullChain(elem).find(isFocusable);
}

function isFocusable(elem: HTMLElement): boolean {
  return (
    elem.getAttribute('tabindex') === '-1' ||
    // 'no-prototype-builtins' =>  https://eslint.org/docs/rules/no-prototype-builtins
    Object.prototype.hasOwnProperty.call(elem.dataset, FOCUSABLE_DATASET_ATTRIBUTE)
  );
}

function isTrRowColumn(name: string): boolean {
  const regex1 = new RegExp('-row$');
  const regex2 = new RegExp('-component$');
  const regex3 = new RegExp('-item$');

  return (
    regex1.test(name.toLowerCase()) ||
    regex2.test(name.toLowerCase()) ||
    regex3.test(name.toLowerCase())
  );
}

function isTableHeader(target: HTMLElement): boolean {
  return target.parentElement?.tagName.toLowerCase() === 'trf-table-header';
}

function isArrowDown(key: string): boolean {
  return key === 'ArrowDown' || key === 'Down';
}

function isArrowUp(key: string): boolean {
  return key === 'ArrowUp' || key === 'Up';
}

function isArrowLeft(key: string): boolean {
  return key === 'ArrowLeft' || key === 'Left';
}

function isArrowRight(key: string): boolean {
  return key === 'ArrowRight' || key === 'Right';
}

function isEnter(key: string): boolean {
  return key === 'Enter';
}
