export enum ChartLegendPosition {
  RIGHT = 'right',
  BOTTOM = 'bottom',
  INSET = 'inset',
  INSET_TOP = 'top',
  INSET_TOP_LEFT = 'top-left',
  INSET_TOP_RIGHT = 'top-right',
  INSET_BOTTOM_LEFT = 'bottom-left',
  INSET_BOTTOM_RIGHT = 'bottom-right',
}

export function isChartPositionInset(position: ChartLegendPosition): boolean {
  return [
    ChartLegendPosition.INSET_TOP,
    ChartLegendPosition.INSET_TOP_LEFT,
    ChartLegendPosition.INSET_TOP_RIGHT,
    ChartLegendPosition.INSET_BOTTOM_LEFT,
    ChartLegendPosition.INSET_BOTTOM_RIGHT,
  ].includes(position);
}
