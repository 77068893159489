import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { encodeEndpoint } from '../../_commons_/helpers/encode-url/encode-url';
import { toData } from '../../_commons_/helpers/response-mapping/response-mapping.helper';

import { UserSettingType } from '../model/user-settings-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsResourceService {
  constructor(private _http: HttpClient) {}

  saveSettings$(object: unknown, settingType: UserSettingType): Observable<unknown> {
    const url = encodeEndpoint('resources/user-accounts/settings/{}', settingType);
    const data = { data: JSON.stringify(object) };

    return this._http.put(url, data);
  }

  getSettings$<T>(settingType: UserSettingType): Observable<T> {
    const url = encodeEndpoint('resources/user-accounts/settings/{}', settingType);

    return this._http.get<{ data: { data: string } }>(url).pipe(
      map(toData),
      map((resp) => JSON.parse(resp.data)),
    );
  }
}
