import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentOf',
})
export class PercentOfPipe implements PipeTransform {
  transform(value = 0, total: number, fractionDigits = 2): string {
    return Number(value) ? ((value * 100) / total).toFixed(fractionDigits) + '%' : 0 + '%';
  }
}
