<div
  #dragHandler
  class="trf-modal-header space-between"
>
  <h2 class="trf-modal-title">{{ headerText | translate }}</h2>
  <ngp-svg-icon
    *ngIf="closable"
    ngbAutofocus
    class="close-button"
    tabindex="0"
    role="button"
    iconName="times"
    [attr.aria-label]="'SLIDEIN.BUTTON_CLOSE_TITLE' | translate"
    (click)="onClose()"
    (keydown.enter)="onClose()"
  ></ngp-svg-icon>
</div>
<div
  #modalContent
  class="trf-modal-body"
>
  <ng-content></ng-content>
</div>
