<div class="controls space-between">
  <ngp-button
    key="{{ negativeName }}"
    classes="tertiary {{ isNegativeLock() || processing ? 'inactive' : '' }}"
    (click)="onNegativeClick()"
    [attr.data-testid]="closeButtonDataTestId"
  ></ngp-button>

  <ngp-button
    *ngIf="!previewMode"
    classes="primary {{ isPositiveLock() || processing || disableSaveButton ? 'inactive' : '' }}"
    key="{{ positiveName }}"
    (click)="onPositiveClick()"
    [attr.testid]="saveButtonDataTestId"
  ></ngp-button>
</div>
