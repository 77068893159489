import { HttpContextToken } from '@angular/common/http';

export const DISPLAY_CUSTOM_ERROR_HTTP_CONTEXT_TOKEN = new HttpContextToken(() => false);
export const DISPLAY_NOT_AVAILABLE_RESOURCE_PAGE = new HttpContextToken(() => false);

export type HttpContextType =
  | typeof DISPLAY_NOT_AVAILABLE_RESOURCE_PAGE
  | typeof DISPLAY_CUSTOM_ERROR_HTTP_CONTEXT_TOKEN;

export interface HttpError {
  errorCode: string;
  additionalInformationErrors?: AdditionalInformationError[];
}

export interface AdditionalInformationError {
  caughtErrorCode: string;
  informationError: { [key: string]: string };
}
