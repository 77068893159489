import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { asyncScheduler, Observable, ReplaySubject, Subject } from 'rxjs';
import { bufferTime, map, observeOn, shareReplay } from 'rxjs/operators';

import {
  AmountWithUnit,
  ConversionRate,
  ConversionRateResponseItem,
  ConversionRates,
  UnitConversionApi,
  unitConversionApi,
} from '@demica/trf-lib-unit-conversion/dist';
import { enterZone, leaveZone } from '@demica/utils';

import { NotificationService } from './notification.service';

import { apiUrl } from '../config/api.config';

interface FXRate {
  source: string;
  target: string;
}

@Injectable()
export class FxRatesService {
  fxRates: ConversionRates;

  previousNotificationsMap = new Map();
  unitConversionApi: UnitConversionApi = unitConversionApi;

  private notificationsSubject: Subject<FXRate> = new ReplaySubject(10);

  private observable = this.http
    .get<{ data: { rates: ConversionRates } }>(`${apiUrl}/resources/dashboards/fx-rates`)
    .pipe(
      map((data) => {
        this.fxRates = data.data.rates;
        return this.fxRates;
      }),
      shareReplay(),
    );

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private ngZone: NgZone,
  ) {
    this.setupNotificationsObservable();
    this.setupClearingPreviousNotificationsMap();
  }

  getFxRatesObservable(): Observable<ConversionRates> {
    return this.observable;
  }

  // TODO: handle precision and rounding somehow unified - for now each of the dashboards uses its own implementation
  convertAmount(
    baseAmount: string,
    baseUnit: string,
    referenceUnit: string,
    rate?: string,
  ): Observable<string> {
    const amountWithUnit = {
      id: { value: 1 },
      amount: { value: baseAmount },
      unit: baseUnit,
    };

    return this.observable.pipe(
      map(() => this.getConversionRatesOrTakeRateIfGiven(amountWithUnit, referenceUnit, rate)),
      map((rates: ConversionRate[]) => this.showNotificationForMissingRates(rates)),
      map((rates: ConversionRate[]) =>
        this.convertAmounts(rates, [amountWithUnit], referenceUnit)
          .map((amountWithValue) => amountWithValue.amount.value)
          .pop(),
      ),
    );
  }

  private getConversionRatesOrTakeRateIfGiven(
    amountWithUnit: AmountWithUnit,
    referenceUnit: string,
    rate?: string,
  ): ConversionRate[] {
    return rate
      ? [this.toConversionRate(amountWithUnit.unit, referenceUnit, rate)]
      : this.getConversionRates([amountWithUnit], referenceUnit);
  }

  private toConversionRate(
    baseUnit: string,
    referenceUnit: string,
    rate: string,
  ): ConversionRateResponseItem {
    return {
      baseUnit,
      referenceUnit,
      rate: {
        value: rate,
      },
      paths: {
        used: [baseUnit, referenceUnit],
        unused: [],
      },
    };
  }

  private getConversionRates(
    amountsWithUnits: AmountWithUnit[],
    referenceUnit: string,
  ): ConversionRate[] {
    return this.unitConversionApi.getConversionRates({
      baseUnits: this.toBaseUnits(amountsWithUnits),
      referenceUnit,
      conversionRates: this.fxRates,
    }).rates;
  }

  private convertAmounts(
    conversionRates: ConversionRate[],
    amountsWithUnits: AmountWithUnit[],
    referenceUnit: string,
  ): AmountWithUnit[] {
    return this.unitConversionApi.convertAmounts({
      amountsWithUnits,
      referenceUnit,
      conversionRates,
    }).amountsWithUnits;
  }

  private toBaseUnits(amountWithUnits: AmountWithUnit[]): string[] {
    return amountWithUnits.map((amountWithUnit) => amountWithUnit.unit);
  }

  private showNotificationForMissingRates(conversionRates: ConversionRate[]) {
    conversionRates
      .filter(this.isRateMissing)
      .forEach((rate) => this.notifyAboutMissingRate(rate.baseUnit, rate.referenceUnit));
    return conversionRates;
  }

  private isRateMissing(rate: ConversionRateResponseItem): boolean {
    return rate.paths.used.length === 0;
  }

  notifyAboutMissingRate(fromCurrency: string, toCurrency: string) {
    this.notificationsSubject.next({ source: fromCurrency, target: toCurrency });
  }

  private setupNotificationsObservable() {
    this.notificationsSubject
      .pipe(
        bufferTime(2000, leaveZone(this.ngZone, asyncScheduler)),
        observeOn(enterZone(this.ngZone, asyncScheduler)),
      )
      .subscribe((warnings) => {
        const localMap = new Map();

        this.updateNotification(warnings, localMap);
      });
  }

  private updateNotification(warnings: FXRate[], localMap: Map<string, FXRate>) {
    warnings.forEach((warningData) => {
      const key = warningData.source + '_' + warningData.target;
      if (!this.previousNotificationsMap.has(key)) {
        localMap.set(key, warningData);
        this.previousNotificationsMap.set(key, warningData);
      }
    });

    const uniqueWarnings = Array.from(localMap.values());
    uniqueWarnings.forEach((warningData) => {
      const notification = { source: warningData.source, target: warningData.target };
      this.notificationService.warning('WARNING.FX_RATE_MISSING', notification);
    });
  }

  private setupClearingPreviousNotificationsMap() {
    this.ngZone.runOutsideAngular(() => {
      window.setInterval(() => {
        this.ngZone.run(() => {
          this.previousNotificationsMap.clear();
        });
      }, 90000);
    });
  }
}
