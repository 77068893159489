import { Directive } from '@angular/core';

export interface TreeChecklistExpandTemplateContext<T> {
  $implicit: T;
  expanded: boolean;
  onExpandToggle: () => void;
}

@Directive({
  selector: '[ngpTreeChecklistExpand]',
  standalone: true,
})
export class TreeChecklistExpandTemplateDirective {}
