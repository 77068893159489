import { Injectable } from '@angular/core';

import { noop, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ConstructorOf, Modal } from '@demica/core/core';

import { ConfirmationModalComponent } from '../component/confirmation-modal/confirmation-modal.component';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export class ConfirmationResult<T> {
  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorHandler: (err: any) => any;
  successHandler: (t: T) => unknown;
  cancelHandler = noop;
  modalDismissedHandler = noop;
  modalSuccessfulHandler = noop;

  onSuccess(successHandler: (t: T) => unknown) {
    this.successHandler = successHandler;
    return this;
  }

  onError(errorHandler: (...args: unknown[]) => void) {
    this.errorHandler = errorHandler;
    return this;
  }

  onCancel(cancelHandler: () => void) {
    this.cancelHandler = cancelHandler;
    return this;
  }

  onModalDismissed(modalDismissedHandler: () => void) {
    this.modalDismissedHandler = modalDismissedHandler;
    return this;
  }

  onModalSuccessful(modalSuccessfulHandler: () => void) {
    this.modalSuccessfulHandler = modalSuccessfulHandler;
    return this;
  }
}

const defaultOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
};

/**
 * Due to hierarchical nature of dependency injection in Angular the NgbModal service will
 * receive ComponentFactoryResolver instance that will have registered only components from the chunk
 * where this service (ModalService) is provided in + the components in the main chunk (shared module).
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalService: NgbModal) {}

  openModal<T>(component: ConstructorOf<T>, options?: NgbModalOptions): Modal<T> {
    const ref = this.modalService.open(component, { ...defaultOptions, ...options });
    return new Modal(ref);
  }

  openConfirmationModal<T>(
    messageKey: string,
    actionKey: string,
    action?: Observable<T>,
    messageVariables?: Record<string, string | number>,
    cancelKey = 'CONFIRMATION_MODAL.CANCEL_BUTTON',
    options?: NgbModalOptions,
  ): ConfirmationResult<T> {
    const modalRef = this.open(ConfirmationModalComponent, { ...options });
    modalRef.componentInstance.messageVariables = messageVariables || {};
    modalRef.componentInstance.messageKey = messageKey;
    modalRef.componentInstance.actionNameKey = actionKey;
    modalRef.componentInstance.cancelNameKey = cancelKey;

    const result = new ConfirmationResult<T>();

    modalRef.componentInstance.actionInvoke
      .pipe(
        switchMap(() => action || of(noop())),
        catchError((err) => {
          if (result.errorHandler) return result.errorHandler(err);
          else return throwError('Unhandled confirmation modal error!');
        }),
      )
      .subscribe((data: T) => {
        result.successHandler(data);
        modalRef.close();
        result.modalSuccessfulHandler();
      });

    modalRef.componentInstance.actionReject.subscribe(() => {
      result.cancelHandler();
      modalRef.dismiss();
      result.modalDismissedHandler();
    });

    return result;
  }

  private open(component: unknown, options = {}) {
    const ngbModalRef = this.modalService.open(component, { ...defaultOptions, ...options });
    return ngbModalRef;
  }
}
