import { AnalysisGroupResource } from '@demica/core/core';

import { COUNTERPARTY_CODE_COLUMN } from '../../consts/file-format-column-definition.const';

export function removeDuplicatedCounterpartyCodes(analysisGroups: AnalysisGroupResource[]) {
  return analysisGroups.filter(
    (analysisGroup, index, analysisGroups) =>
      analysisGroup.entityId != COUNTERPARTY_CODE_COLUMN ||
      analysisGroups.findIndex(
        (foundAnalysisGroup) => foundAnalysisGroup.entityId == analysisGroup.entityId,
      ) === index,
  );
}
