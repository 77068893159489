import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { HttpErrorMessageService } from '../service/http-error/http-error-messages.service';
import { NotificationService } from '../service/notification.service';

import { poolingUrls } from '../config/pooling-urls';
import {
  DISPLAY_CUSTOM_ERROR_HTTP_CONTEXT_TOKEN,
  DISPLAY_NOT_AVAILABLE_RESOURCE_PAGE,
  HttpError,
} from '../interface/http-context.interface';
import { ConnectionVerifier } from './utils/connection-verifier';

export interface MetaData {
  errorCodes: HttpError[];
}

interface MetaDataResponse {
  metaData: MetaData;
}

const ignoredStatuses = [412];

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _injector: Injector) {}

  public static parseRequestedErrorCode(req: HttpRequest<MetaDataResponse>): MetaData {
    if (req.body && req.body.metaData) {
      return req.body.metaData;
    }
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this._shouldIgnoreHandleError(req)) {
      return next.handle(req);
    }
    const connectionVerifier = this._injector.get(ConnectionVerifier);

    return next.handle(req).pipe(
      catchError((err) => {
        return connectionVerifier
          .isOnline(err)
          .pipe(
            switchMap((isOnline) =>
              isOnline ? this._handleError(err, req) : this._handleConnectionError(err),
            ),
          );
      }),
    );
  }

  private _isOnIgnoredUrlsList(url: string): boolean {
    return poolingUrls.some((ignoredUrl) => url.match(ignoredUrl));
  }

  // errors are handled by GlobalErrorHandler, which intercepts errors from window object
  // and sends them via loggerService into an external service (f.e. frontend-logs)
  private _handleConnectionError(err: unknown): Observable<HttpEvent<unknown>> {
    const notificationService = this._injector.get(NotificationService);
    notificationService.notifyWithoutRepetitions('NO_CONNECTION');
    return throwError(err);
  }

  private _shouldIgnoreHandleError(req: HttpRequest<unknown>): boolean {
    return req.method === 'HEAD';
  }

  // some errors are handled by GlobalErrorHandler, which intercepts errors from window object
  // and sends them via loggerService into an external service (f.e. frontend-logs)
  // other errors are returned to the UI for the user
  private _handleError(
    errorResponse: HttpErrorResponse,
    req: HttpRequest<unknown>,
  ): Observable<HttpEvent<unknown>> {
    if (ignoredStatuses.indexOf(errorResponse.status) > -1) return throwError(errorResponse);

    const metaData = HttpErrorInterceptor.parseRequestedErrorCode(
      req as HttpRequest<MetaDataResponse>,
    );

    let error = errorResponse.error;
    if (error && error instanceof ArrayBuffer) {
      error = new TextDecoder('utf-8').decode(error);
    }

    if (error && typeof error === 'string') {
      return throwError(`Cannot parse error from backend: ${error}`);
    }

    let errors = error.errors as HttpError[];

    const hasRequestedErrorCode = (errors: HttpError[], requestedErrors: HttpError[]) =>
      errors.filter((val) => requestedErrors.includes(val));
    if (
      errorResponse.status === 409 &&
      metaData &&
      hasRequestedErrorCode(errors, metaData.errorCodes)
    ) {
      return throwError(metaData);
    }

    if (errorResponse.status === 422) {
      return throwError(errorResponse);
    }

    if (errorResponse.status === 403 && !(errors && errors.length > 0)) {
      errors = [{ errorCode: 'ACCESS_DENIED' }];
    }

    // Handle notification for File Provider Error
    if (error.data?.errorCode) {
      errors = [
        {
          errorCode: error.data.errorCode,
          additionalInformationErrors: error.data.additionalInformationErrors ?? null,
        },
      ];
    }

    if (req.context.get(DISPLAY_NOT_AVAILABLE_RESOURCE_PAGE) === true) {
      return throwError(errorResponse);
    }

    if (req.context.get(DISPLAY_CUSTOM_ERROR_HTTP_CONTEXT_TOKEN) === true) {
      return throwError(errorResponse);
    }

    if (errorResponse.status !== 401 && !this._isOnIgnoredUrlsList(errorResponse.url)) {
      const httpErrorMessageService = this._injector.get(HttpErrorMessageService);
      httpErrorMessageService.showHttpErrorMessage(errors);
    }

    return throwError(errorResponse);
  }
}

export function requiredErrorCodesRequestWrapper(errorCodes: string[]): {
  errorCodes: HttpError[];
} {
  return {
    errorCodes: errorCodes.map((value) => ({ errorCode: value } as HttpError)),
  };
}
