import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngp-not-defined',
  templateUrl: './not-defined.component.html',
  styleUrls: ['./not-defined.component.sass'],
})
export class NotDefinedComponent {
  @Input()
  message = '';
}
