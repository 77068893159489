import { Location } from '@angular/common';
import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import {
  getLocalStorage,
  ignoreDiscardUnsavedChanges,
  setHistoryState,
  SlideinContext,
  useConfirmDiscardOfUnsavedChanges,
} from '@demica/core/core';

const disableUnsavedGuard = getLocalStorage('disableUnsavedGuard');

@Directive({
  selector: '[trfSlideinFormChanges]',
})
export class SlideinUnsavedChangesDirective implements OnInit, OnDestroy {
  @Input()
  previewMode? = false;

  initialLocation: string;
  subscriptions: Subscription[] = [];
  changesSubject = new BehaviorSubject<boolean>(false);

  private _confirmDiscardOfUnsavedChanges = useConfirmDiscardOfUnsavedChanges();

  constructor(
    private location: Location,
    private translate: TranslateService,
    private slideinContext: SlideinContext,
  ) {
    this.initialLocation = this.location.path();
    this.slideinContext.isFormDirty = false;
    setHistoryState(this.initialLocation);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.changesSubject.pipe(debounceTime(250)).subscribe((result) => {
        this.slideinContext.isFormDirty = disableUnsavedGuard != null ? false : result;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadNotification(event: { returnValue: string }) {
    if (this.slideinContext.isFormDirty && !ignoreDiscardUnsavedChanges()) {
      return (event.returnValue = this.translate.instant('UNSAVED_CHANGES_MODAL.MESSAGE'));
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.backAction();
  }

  @HostListener('keydown', ['$event.currentTarget'])
  @HostListener('change', ['$event.currentTarget'])
  onFormChange() {
    if (!this.previewMode) this.changesSubject.next(true);
  }

  private backAction() {
    if (this.slideinContext.isFormDirty) {
      if (this._confirmDiscardOfUnsavedChanges()) {
        this.slideinContext.closeModal.next();
      } else {
        setHistoryState(this.initialLocation);
      }
    } else {
      this.slideinContext.closeModal.next();
    }
  }
}
