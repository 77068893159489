import { Directive } from '@angular/core';

export interface TreeChecklistItemContentContext<T> {
  $implicit: T;
}

@Directive({
  selector: '[ngpTreeChecklistItemContent]',
  standalone: true,
})
export class TreeChecklistItemContentDirective {}
