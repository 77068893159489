import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { asyncScheduler, merge, Observable, Subject, Subscription } from 'rxjs';
import { bufferTime, bufferWhen, filter, takeUntil } from 'rxjs/operators';

import { AnalyticsEventTypes, AnalyticsRequest } from '@demica/resources/analytics';
import { leaveZone } from '@demica/utils';

const DEFAULT_BUFFER_TIMEOUT = 1000;
const DEFAULT_BUFFER_SIZE = 1;
const FORCE_FLUSH_EVENTS = [AnalyticsEventTypes.UNLOAD_APP, AnalyticsEventTypes.USER_LOGOUT];

@Injectable({
  providedIn: 'root',
})
export class AnalyticsRequestsBufferService implements OnDestroy {
  onBufferData$ = new Subject<AnalyticsRequest[]>();
  eventStore: AnalyticsRequest[] = [];

  private _bufferLimit$: Observable<unknown>;
  private _analyticsDataSubscription: Subscription;
  private _flush$ = new Subject<void>();
  private _analyticsData$ = new Subject<AnalyticsRequest>();
  private _destroyed$ = new Subject<void>();

  constructor(private _ngZone: NgZone) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  push(payload: AnalyticsRequest, flushOnLogout = true): void {
    if (!this._analyticsDataSubscription) {
      this.eventStore.push(payload);

      return;
    }

    this._analyticsData$.next(payload);

    if (flushOnLogout && payload.events.some((ev) => FORCE_FLUSH_EVENTS.includes(ev.eventType))) {
      this.flush();
    }
  }

  flush(): void {
    this._flush$.next();
  }

  initBuffer(bufferSize: number, bufferTimeout: number): void {
    this._bufferLimit$ = this._analyticsData$.pipe(
      bufferTime(
        this.getBufferTimeout(bufferTimeout),
        null,
        this.getBufferSize(bufferSize),
        leaveZone(this._ngZone, asyncScheduler),
      ),
    );

    const fire$ = merge(this._bufferLimit$, this._flush$);

    this._analyticsDataSubscription = this._analyticsData$
      .pipe(
        bufferWhen(() => fire$),
        filter((payloads: AnalyticsRequest[]) => !!payloads.length),
        takeUntil(this._destroyed$),
      )
      .subscribe(this.onBufferData$);

    this._handleSavedEvents();
  }

  getBufferSize(bufferSize: number): number {
    if (isNaN(bufferSize)) {
      return DEFAULT_BUFFER_SIZE;
    }

    if (Math.round(bufferSize) < 1) {
      return DEFAULT_BUFFER_SIZE;
    } else {
      return Math.round(bufferSize);
    }
  }

  getBufferTimeout(bufferTimeout: number): number {
    if (isNaN(bufferTimeout)) {
      return DEFAULT_BUFFER_TIMEOUT;
    }

    if (Math.round(bufferTimeout) < 300) {
      return DEFAULT_BUFFER_TIMEOUT;
    } else {
      return Math.round(bufferTimeout);
    }
  }

  private _handleSavedEvents(): void {
    if (this.eventStore.length) {
      this.eventStore.forEach((ev) => this.push(ev));
    }
  }
}
