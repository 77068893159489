import { Directive, HostListener } from '@angular/core';

import { KeyboardNavigationStrategyService } from '../service/keyboard-navigation-strategy.service';

import { Key } from '../model/key';

@Directive({
  selector: '[trfExpandableTableFocusToRow]',
})
export class ExpandableTableFocusToRowDirective {
  constructor(private _keyboardTableNavigationService: KeyboardNavigationStrategyService) {}

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    const key = new Key(event);
    const target = event.target as HTMLElement;

    if (
      (key.isUp || key.isDown || key.isLeft || key.isRight) &&
      target.tagName.toLowerCase() === 'table'
    )
      this._keyboardTableNavigationService.onFocusToOrdinaryTable(target);
  }
}
