import { Pipe, PipeTransform } from '@angular/core';

import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'enumerationTranslate',
})
export class EnumerationTranslatePipe implements PipeTransform {
  constructor(private translatePipe: TranslatePipe) {}

  transform(value: string | number, ...args: string[]): string {
    return this.translatePipe.transform(`ENUMERATIONS.${args[0]}.${value}`);
  }
}
