<td
  class="cell-lower-range"
  [attr.title]="row.x"
  testid="cell-lower-range"
>
  {{ row.x }}
</td>

<td
  class="cell-value"
  [attr.title]="row.value | localisedNumber"
  testid="cell-value"
>
  {{ row.value | localisedNumber }}
</td>

<td
  class="cell-actions"
  testid="cell-actions"
>
  <trf-table-actions
    [actions]="actions"
    [actionSubject]="row"
  ></trf-table-actions>
</td>
