import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NavigationService } from '@demica/core/core';
import { Client } from '@demica/resources/client';
import { Seller } from '@demica/resources/seller';

type ParentType = 'transaction' | 'client' | 'seller';

@Component({
  selector: 'trf-versions-view-header',
  templateUrl: 'versions-view-header.component.html',
  styleUrls: ['versions-view-header.component.sass'],
})
export class VersionsViewHeaderComponent {
  @Input()
  preview: Client | Seller;
  @Input()
  createVersionRequiredAuthority: string;
  @Input()
  parentType: ParentType;

  @Output()
  newVersion = new EventEmitter<void>();

  constructor(private navigate: NavigationService) {}

  onNewButtonClick() {
    this.newVersion.emit();
  }

  onNameClick() {
    const previewType = this.parentType + 'Preview';
    (
      this.navigate[previewType as keyof NavigationService] as (
        entityId: number,
      ) => Promise<boolean>
    )(this.preview.entityId);
  }
}
