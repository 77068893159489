import { Injectable } from '@angular/core';

import { HasEntityId, isHasEntityId } from '@demica/core/core';

import { SelectComparator } from './select-comparator-strategy.service';

@Injectable({ providedIn: 'root' })
export class FlatIdSelectComparatorStrategy implements SelectComparator {
  bindValue? = 'entityId' as const;
  compareFn = (someOption: HasEntityId, selectedValue: string | number): boolean => {
    if (
      !isHasEntityId(someOption) ||
      (typeof selectedValue !== 'string' && typeof selectedValue !== 'number')
    ) {
      console.warn('Wrongly configured select with flatID and API value');
      return false;
    }
    return someOption.entityId === selectedValue;
  };
}
