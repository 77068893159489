export interface ColumnDefinitionsUiConfig {
  columnDefinitionsTranslationPrefix: string | string[];
  columnDefinitionsTranslationPrefixWithDot: string | string[];
  columnDefinitionsValidationTranslationPrefix: string;
}

export class StructuredProgrammeUiConfig implements ColumnDefinitionsUiConfig {
  columnDefinitionsTranslationPrefix = 'COLUMN_DEFINITION_TYPES';
  columnDefinitionsTranslationPrefixWithDot = 'COLUMN_DEFINITION_TYPES.';
  columnDefinitionsValidationTranslationPrefix =
    'FILE_FORMAT.COLUMN_DEFINITION_ERROR.NO_REQUIRED_COLUMN.';
}
