import { Component, Input } from '@angular/core';

import { PointValue } from '../../../../model/pointValue';
import { Actions } from '../../../actions/model/actions.interface';

@Component({
  selector: 'trf-points-list-row',
  templateUrl: './points-list-row.component.html',
  styleUrls: ['./points-list-row.component.sass'],
})
export class PointsListRowComponent {
  @Input()
  row: PointValue;
  @Input()
  actions: Actions;
}
