import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { ToasterService } from './services/toaster.service';

import { ToasterContainerComponent } from './components/toaster-container/toaster-container.component';
import { ToasterComponent } from './components/toaster/toaster.component';

import { TrustHtmlPipe } from './utils/trust-html.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ToasterComponent, ToasterContainerComponent, TrustHtmlPipe],
  exports: [ToasterContainerComponent, ToasterComponent],
})
export class ToasterModule {
  static forRoot(): ModuleWithProviders<ToasterModule> {
    return {
      ngModule: ToasterModule,
      providers: [ToasterService, ToasterContainerComponent],
    };
  }

  static forChild(): ModuleWithProviders<ToasterModule> {
    return {
      ngModule: ToasterModule,
      providers: [ToasterContainerComponent],
    };
  }
}
