import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';

import {
  AliasAwaitingApproval,
  AwaitingApprovalSearchParams,
} from '../../_domain/transaction/alias/alias-awaiting-approval.interface';
import {
  AliasMissingMapping,
  MissingMappingSearchParams,
} from '../../_domain/transaction/alias/alias-missing-mapping.interface';
import {
  AliasesAnalysisCodeRequest,
  AnalysisCode,
  AnalysisCodeRequest,
  AnalysisCodeWithMapping,
} from '../../_domain/transaction/alias/analysis-code.interface';
import { ProposedAliasList } from '../../_domain/transaction/alias/proposed-alias.interface';
import { RejectedAliases } from '../../_domain/transaction/alias/rejected-missing-mapping.interface';
import { AnalysisCodeReference } from '../../interface/analysis-code-reference.interface';
import { EntityReference } from '../../interface/entity-reference.interface';
import { EntityId } from '../../interface/has-entity-id.interface';
import { createPaginationParams, defaultResponse, PageRequest } from '../../model/pageable-data';
import { PageRestResponse, RestResponse } from '../../model/response.interface';
import { PostResponse } from '../../model/rest-response/post-response.interface';
import { PutResponse } from '../../model/rest-response/put-response.interface';
import { encodeParams } from '../../security/encode-params';
import { encodeEndpoint, encodeMockEndpoint } from '../../security/encode-url';
import { toData, toPageableData } from './response-mapping';

@Injectable()
export class AliasesResourceService {
  constructor(private http: HttpClient) {}

  getAnalysisCodes(pageRequest: PageRequest, analysisCodeRequest: AnalysisCodeRequest) {
    const httpParams = createPaginationParams(pageRequest);
    const url = encodeEndpoint('resources/aliases/analysis-codes/search');
    return this.http
      .post<PageRestResponse<AnalysisCode>>(url, analysisCodeRequest, { params: httpParams })
      .pipe(
        catchError(() => defaultResponse<AnalysisCode>()),
        map(toPageableData),
      );
  }

  getAnalysisCodeWithMapping(analysisCodeId: EntityId, transactionId: EntityId) {
    const url = encodeEndpoint('resources/aliases/analysis-codes/{}', analysisCodeId);
    const params = encodeParams({ transactionId });
    return this.http.get<RestResponse<AnalysisCodeWithMapping>>(url, { params }).pipe(map(toData));
  }

  deleteAlias(aliasData: AnalysisCode) {
    const entityRevision: number = aliasData.entityRevision;
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint('resources/aliases/analysis-codes/{}', aliasData.analysisCodeId);

    return this.http.delete(url, { params });
  }

  checkAnalysisCodeAvailable(
    transactionId: EntityId,
    columnTypeId: EntityId,
    value: string,
    analysisCodeId: EntityId,
  ) {
    const params = encodeParams({ transactionId, columnTypeId, value, analysisCodeId });
    const url = encodeEndpoint('resources/aliases/analysis-codes/availability');
    return this.http.get(url, { params }).pipe(map(toData));
  }

  checkAliasValueAvailable(transactionId: EntityId, dataSourceId: number, value: string) {
    const params = encodeParams({ transactionId, dataSourceId, value });
    const url = encodeMockEndpoint(
      'resources/transactions/{}/aliases/management/code-availability',
      transactionId,
    );
    return this.http.get(url, { params });
  }

  addAnalysisCode(request: AliasesAnalysisCodeRequest) {
    const url = encodeEndpoint('resources/aliases/analysis-codes');
    return this.http.post<RestResponse<EntityReference>>(url, request).pipe(map(toData));
  }

  editAnalysisCode(analysisCodeId: EntityId, request: AliasesAnalysisCodeRequest) {
    const url = encodeEndpoint('resources/aliases/analysis-codes/{}', analysisCodeId);
    return this.http.put<RestResponse<EntityReference>>(url, request).pipe(map(toData));
  }

  getMissingMappingsPageable(pageRequest: PageRequest, searchParams: MissingMappingSearchParams) {
    const httpParams = createPaginationParams(pageRequest);
    const url = encodeEndpoint('resources/aliases/missing-mappings/search');
    return this.http
      .post<PageRestResponse<AliasMissingMapping>>(url, searchParams, { params: httpParams })
      .pipe(
        catchError(() => defaultResponse<AliasMissingMapping>()),
        map(toPageableData),
      );
  }

  addMappings(mappings: ProposedAliasList) {
    const url = encodeEndpoint('resources/aliases/proposed-aliases');
    return this.http.put<RestResponse<PutResponse>>(url, mappings);
  }

  rejectMappings(rejections: RejectedAliases) {
    const url = encodeEndpoint('resources/aliases/missing-mappings/reject');
    return this.http.post<RestResponse<PostResponse>>(url, rejections);
  }

  addMappingsBySearch(searchParams: MissingMappingSearchParams) {
    const url = encodeEndpoint('resources/aliases/missing-mappings/approve-by-search');
    return this.http.put<RestResponse<PostResponse>>(url, searchParams);
  }

  rejectMappingsBySearch(searchParams: MissingMappingSearchParams) {
    const url = encodeEndpoint('resources/aliases/missing-mappings/reject-by-search');
    return this.http.post<RestResponse<PostResponse>>(url, searchParams);
  }

  getAnalysisCodesReferences(transactionId: EntityId, columnTypeId?: EntityId) {
    let httpParams = new HttpParams().append('transactionId', String(transactionId));
    if (columnTypeId) {
      httpParams = httpParams.append('columnTypeId', String(columnTypeId));
    }
    const url = encodeEndpoint('resources/aliases/analysis-codes');
    return this.http
      .get<RestResponse<AnalysisCodeReference[]>>(url, { params: httpParams })
      .pipe(map(toData));
  }

  getAnalysisCodesReferencesList(transactionId: EntityId, columnTypeIds: EntityId[]) {
    const url = encodeEndpoint('resources/aliases/analysis-codes/list');
    return this.http
      .post<RestResponse<AnalysisCodeReference[]>>(url, {
        transactionId: transactionId,
        columnTypeIds: columnTypeIds,
      })
      .pipe(map(toData));
  }

  getAwaitingApprovalPageable(
    pageRequest: PageRequest,
    searchParams: AwaitingApprovalSearchParams,
  ) {
    const httpParams = createPaginationParams(pageRequest);
    const url = encodeEndpoint('resources/aliases/proposed-aliases/search');
    return this.http
      .post<PageRestResponse<AliasAwaitingApproval>>(url, searchParams, { params: httpParams })
      .pipe(
        catchError(() => defaultResponse<AliasAwaitingApproval>()),
        map(toPageableData),
      );
  }

  rejectProposedMapping(mappings: ProposedAliasList) {
    const url = encodeEndpoint('resources/aliases/proposed-aliases/reject');
    return this.http.post<RestResponse<PutResponse>>(url, mappings);
  }

  acceptProposedMapping(mappings: ProposedAliasList) {
    const url = encodeEndpoint('resources/aliases/proposed-aliases/approve');
    return this.http.post<RestResponse<PutResponse>>(url, mappings);
  }

  approveAllProposedMapping(searchParams: AwaitingApprovalSearchParams) {
    const url = encodeEndpoint('resources/aliases/proposed-aliases/approve-by-search');
    return this.http.post<RestResponse<PutResponse>>(url, searchParams);
  }

  rejectAllProposedMapping(searchParams: AwaitingApprovalSearchParams) {
    const url = encodeEndpoint('resources/aliases/proposed-aliases/reject-by-search');
    return this.http.post<RestResponse<PutResponse>>(url, searchParams);
  }
}
