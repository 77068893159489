import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { DashboardFilters } from '../../model/filter/dashboard-filters.interface';
import { RestResponse } from '../../model/response.interface';
import { encodeEndpoint } from '../../security/encode-url';
import { toData } from './response-mapping';

@Injectable()
export class DashboardFiltersResourceService {
  constructor(private http: HttpClient) {}

  getAvailableFilters() {
    return this.http
      .get<RestResponse<DashboardFilters>>(encodeEndpoint('resources/dashboards/filters/available'))
      .pipe(map(toData));
  }
}
