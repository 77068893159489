import { HasEntityId } from '@demica/core/core';

export interface HasKey {
  key: unknown;
}

export function isHasKey(value: unknown): value is HasKey {
  return typeof (value as HasKey)?.key === 'string';
}

export type HasKeyOrId = HasKey | HasEntityId;
