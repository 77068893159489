import { Injectable } from '@angular/core';

import { LOGOUT_REASONS } from './logout-reasons.enum';

@Injectable({
  providedIn: 'root',
})
export class LogoutReasonStateService {
  private _logoutReason: LOGOUT_REASONS = LOGOUT_REASONS.USER_LOGOUT;

  setLogoutReason(reason: LOGOUT_REASONS): void {
    this._logoutReason = reason;
  }

  getReason(): LOGOUT_REASONS {
    return this._logoutReason;
  }
}
