import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { from, iif, Observable, of, Subject } from 'rxjs';
import { concatMap, filter, first, takeUntil, tap, toArray } from 'rxjs/operators';

import { NgpTabsComponent } from '@demica/components';
import { FeatureToggleService, User, UserService } from '@demica/core/core';

import { TabConfig } from './tab-config.interface';
import { Tab } from './tab.interface';

@Component({
  selector: 'trf-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.sass'],
})
export class TabsComponent implements OnInit, OnDestroy {
  @Input()
  tabsConfig: TabConfig;
  @Input()
  authoritySpec: (u: User) => boolean;
  tabsToShow: Tab[] = [];
  isAuthoritySpecFunc: boolean;
  showLabel = false;
  @ViewChild(NgpTabsComponent)
  private _ngpTabs!: NgpTabsComponent;
  private _destroyed$ = new Subject<void>();

  constructor(
    private _changeDetectionRef: ChangeDetectorRef,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _userService: UserService,
    private _featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit(): void {
    this._changeDetectionRef.detectChanges(); // prevents from ExpressionChangedAfterItHasBeenCheckedError in trfAriaTabPanelControl
    const tabs$ = from(this.tabsConfig.tabs);
    this.isAuthoritySpecFunc = !!this.authoritySpec;

    this._userService.currentUser
      .pipe(
        first(),
        concatMap((user: User) =>
          iif(
            () => (this.isAuthoritySpecFunc ? this.authoritySpec(user) : true),
            tabs$.pipe(
              tap(() => (this.showLabel = true)),
              this._checkTabRequiredAuthority(user),
              this._checkTabRequiredFeatureToggles(this._featureToggleService),
              takeUntil(this._destroyed$),
            ),
            [],
          ),
        ),
        toArray(),
        takeUntil(this._destroyed$),
      )
      .subscribe((tabs) => {
        this.tabsToShow = tabs;
      });
    this.setActiveTabs();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  setActiveTabs(): void {
    this.tabsToShow.forEach((tab) => (tab.active = false));

    const url = this._router.routerState.snapshot.url.split('?')[0];
    const urlSegments = url.split('/');
    urlSegments.reverse().some((segment) => {
      const foundTab = this.tabsToShow.find((tab) => tab.route.path === segment);
      if (foundTab) {
        foundTab.active = true;
        return true;
      }
      return false;
    });
  }

  onTabClick(tab: Tab): void {
    if (this._router.getCurrentNavigation()) return;
    this._router
      .navigate([tab.route.path], {
        queryParamsHandling: 'merge',
        relativeTo: this._activatedRoute,
      })
      .then((navigationResult) =>
        navigationResult ? this.setActiveTabs() : this._ngpTabs.tabChangeCancel(),
      )
      .catch((err) => {
        this._ngpTabs.tabChangeCancel();
        return of(err);
      });
  }

  private _checkTabRequiredAuthority(user: User): (source$: Observable<Tab>) => Observable<Tab> {
    return (source$) =>
      source$.pipe(filter((tab) => user.hasAuthority(tab.route.requiredAuthority)));
  }

  private _checkTabRequiredFeatureToggles(
    featureToggleService: FeatureToggleService,
  ): (source$: Observable<Tab>) => Observable<Tab> {
    return (source$) =>
      source$.pipe(
        filter((tab) => featureToggleService.hasGroupEnabled(tab.route.requiredFeatureToggleGroup)),
        filter((tab) =>
          featureToggleService.hasAllGroupsEnabled(tab.route.requiredAllFeatureToggleGroup),
        ),
      );
  }
}
