import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'trf-confirmation-modal',
  templateUrl: 'confirmation-modal.component.html',
  styleUrls: ['confirmation-modal.component.sass'],
})
export class ConfirmationModalComponent {
  @Input()
  messageKey: string;
  @Input()
  messageVariables: Record<string, string | number>;
  @Input()
  actionNameKey: string;
  @Input()
  cancelNameKey: string;

  @Output()
  actionInvoke = new EventEmitter();
  @Output()
  actionReject = new EventEmitter();

  invokeAction() {
    this.actionInvoke.emit();
  }

  onClose() {
    this.actionReject.emit();
  }
}
