import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { fromEvent, merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

/**
 *
 * @description
 * A directive that invokes a function when a click/touch event occurs outside of its host element or any other specified elements.
 * The function is invoked only when the event target is outside the host element and all the specified elements.
 *
 */
@Directive({
  selector: '[trfOutsideClickMonitor]',
})
export class OutsideClickMonitorDirective implements OnDestroy {
  private _destroyed$ = new Subject<void>();

  @Input()
  ignoreElements: HTMLElement[] = [];

  @Output()
  trfOutsideClick = new EventEmitter<void>();

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  constructor(private hostElement: ElementRef, @Inject(DOCUMENT) private _document: Document) {
    this.initialize();
  }

  private initialize() {
    merge(
      fromEvent(this._document, 'touchstart', { capture: true }),
      fromEvent(this._document, 'mousedown', { capture: true }),
    )
      .pipe(
        filter((ev: Event) => !this._isPropagatedFromIgnoredElements(ev)),
        takeUntil(this._destroyed$),
      )
      .subscribe(() => this._onOutsideClick());
  }

  private _onOutsideClick() {
    this.trfOutsideClick.emit();
  }

  private _isPropagatedFromIgnoredElements(event: Event) {
    const target = event.target as HTMLElement;
    return [this.hostElement.nativeElement, ...this.ignoreElements].some((el: HTMLElement) => {
      return el === target || el.contains(target);
    });
  }
}
