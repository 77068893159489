import { Injectable } from '@angular/core';

import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../utils/window';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setItem(key: string, item: unknown): void {
    setLocalStorage(key, JSON.stringify(item));
  }

  getItem<T>(key: string): T {
    return JSON.parse(getLocalStorage(key)) as T;
  }

  removeItem(key: string): void {
    removeLocalStorage(key);
  }
}
