import { EntityReference } from '../../interface/entity-reference.interface';

export const RATIOS_DSO = 'RATIOS_DSO';
export const FUNDING = 'FUNDING';

export type EnvironmentFeatures = 'RATIOS_DSO' | 'FUNDING';

export interface TransactionFilterResource extends EntityReference {
  code: string;
  type: EntityReference;
  environmentIds: number[];
  processingTrigger: EntityReference;
  environmentFeatures?: { [environmentId: number]: Array<EnvironmentFeatures> };
}
