<ng-container [formGroup]="group">
  <div
    class="column-label"
    [ngClass]="{ 'row-disabled': group.get(formControlName).disabled || disabled }"
  >
    <div
      *ngIf="!!label"
      class="column-label"
    >
      <label
        [attr.for]="formControlName"
        [attr.class]="labelClasses"
        [attr.id]="label"
      >
        {{ label | translate }}
      </label>
      <ngp-tooltip-icon
        *ngIf="showHelperToolTip()"
        [tooltipKey]="helperToolTip"
        [iconAriaDesc]="ariaDescText"
      ></ngp-tooltip-icon>
    </div>
    <div class="column-input">
      <ng-select
        #select
        class="form-control"
        role="combobox"
        aria-haspopup="listbox"
        [attr.tabindex]="group.get(formControlName).disabled || disabled ? -1 : 0"
        [attr.aria-expanded]="select.isOpen"
        [attr.aria-describedby]="
          formControlName +
          '_notification' +
          ' ' +
          formControlName +
          FormRowClass.warningNotificationIdPostfix
        "
        [attr.aria-labelledby]="label ? label : defaultLabel"
        [attr.aria-required]="labelClasses?.includes('required')"
        [formControlName]="formControlName"
        [items]="options"
        [placeholder]="defaultLabel | translate"
        [searchable]="searchable"
        [searchFn]="searchTerm"
        [clearable]="clearable"
        [clearOnBackspace]="false"
        [tabIndex]="-1"
        [groupBy]="groupByKey"
        [labelForId]="formControlName"
        [notFoundText]="'NG_SELECT.NOT_FOUND' | translate"
        [loading]="loading"
        [readonly]="readonly"
        [bindValue]="selectConfig.bindValue"
        [compareWith]="selectConfig.compareFn"
        (ngModelChange)="onSelected($event)"
        (close)="select.element.focus()"
        (keydown.delete)="select.clearModel()"
        (open)="markAsDirty()"
        data-testid="ng-select"
      >
        <ng-template
          ng-label-tmp
          let-item="item"
          let-clear="clear"
        >
          <span
            class="ng-value-label value-label-popup"
            [ngClass]="{ 'label-disabled': item?.disabled }"
          >
            <span
              [attr.title]="item | optionName: translationPrefix"
              data-testid="select-value-text"
            >
              {{ item | optionName: translationPrefix }}
            </span>
            <ngp-tooltip-icon
              *ngIf="item?.hoverText"
              [tooltipKey]="item?.hoverText"
            ></ngp-tooltip-icon>
          </span>
        </ng-template>

        <ng-template
          ng-optgroup-tmp
          let-item="item"
        >
          {{ item.groupName }}
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
        >
          <span class="ng-option-label">
            <span
              [attr.title]="item | optionName: translationPrefix"
              data-testid="select-option-text"
            >
              {{ item | optionName: translationPrefix }}
            </span>
            <ngp-tooltip-icon
              *ngIf="item?.hoverText"
              [tooltipKey]="item?.hoverText"
            ></ngp-tooltip-icon>
          </span>
        </ng-template>
      </ng-select>

      <div class="validation-error">
        <ngp-inline-notification
          [id]="formControlName + '_notification'"
          [message]="validations | firstError: (fieldPath ? fieldPath : formControlName)"
        >
        </ngp-inline-notification>

        <ng-container #additionalValidationContainer></ng-container>
      </div>
    </div>

    <div
      *ngIf="infoTooltip"
      class="column-helper info"
    >
      <ngp-tooltip-icon
        [tooltipKey]="infoTooltip"
        [reversed]="true"
      ></ngp-tooltip-icon>
    </div>
  </div>
</ng-container>
