<div class="trf-section-header">
  <h4>{{ componentHeader | translate }}</h4>
</div>
<div class="trf-section-content">
  <div
    *ngIf="previewMode && !transitions.length"
    class="no-transitions-notice"
  >
    <span>{{ "TRANSITIONS.NO_TRANSITIONS_NOTICE" | translate | uppercase }}</span>
  </div>

  <table
    *ngIf="transitions && transitions.length"
    trfOrdinaryTableKeyHandler
    class="transitions-table table"
    tabindex="0"
    role="table"
    [attr.aria-label]="componentHeader | translate"
  >
    <thead>
      <tr trfTableAccessibilityAttributesSetter>
        <th>{{ "TRANSITIONS.TRANSITION_TABLE_FROM" | translate }}</th>
        <th></th>
        <th>{{ "TRANSITIONS.TRANSITION_TABLE_TO" | translate }}</th>
        <th>{{ "TRANSITIONS.TRANSITION_TABLE_ACTIONS" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let transition of transitions; let i = index"
        trfTableAccessibilityAttributesSetter
        testid="transitions-row"
      >
        <td [title]="elementName">{{ elementName }}</td>
        <td>
          <ngp-svg-icon
            tabindex="-1"
            data-index="0"
            class="icon-arrow-right"
            iconName="long-arrow-alt-right"
          ></ngp-svg-icon>
        </td>
        <td [title]="transition.name">{{ transition.name }}</td>
        <td
          *ngIf="!previewMode"
          class="trf-actions"
        >
          <ngp-svg-icon
            iconName="trash-alt"
            data-index="1"
            tabindex="-1"
            title="{{ 'TRANSITIONS.REMOVE_TRANSITION_TITLE' | translate }}"
            (click)="removeTransition(transition)"
            (keyup.enter)="removeTransition(transition)"
          ></ngp-svg-icon>
        </td>
      </tr>
    </tbody>
  </table>

  <div
    *ngIf="!previewMode"
    class="column-label"
  >
    <label>
      {{ "TRANSITIONS.TRANSITION_SELECT_LABEL" | translate }}
    </label>

    <div class="selection-box">
      <ng-select
        #select
        tabIndex="0"
        class="form-control"
        role="combobox"
        [attr.aria-expanded]="select.isOpen"
        [(ngModel)]="selectModel"
        [items]="availableTransitions"
        [placeholder]="'TRANSITIONS.TRANSITION_SELECT_PLACEHOLDER' | translate"
        [searchable]="false"
        [clearOnBackspace]="false"
        [notFoundText]="'NG_SELECT.NOT_FOUND' | translate"
        [tabIndex]="-1"
        (close)="select.element.focus()"
        (keydown.delete)="select.clearModel()"
      >
        <ng-template
          ng-label-tmp
          let-item="item"
          let-clear="clear"
        >
          <span
            class="ng-value-label"
            [attr.title]="item.name + ' (' + item.code + ')'"
            data-testid="select-value-text"
          >
            {{ item.name + " (" + item.code + ")" }}
          </span>
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
        >
          <span
            [attr.title]="item.name + ' (' + item.code + ')'"
            data-testid="select-option-text"
          >
            {{ item.name + " (" + item.code + ")" }}
          </span>
        </ng-template>
      </ng-select>

      <ngp-button
        key="TRANSITIONS.TRANSITION_ADD_BUTTON"
        [classes]="canAdd ? 'tertiary' : 'inactive'"
        (click)="addTransition()"
        testid="add-transition"
      >
      </ngp-button>
    </div>
  </div>
</div>
