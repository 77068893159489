import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import {
  AliasAwaitingApproval,
  AliasesResourceService,
  AnalysisGroupResource,
  AwaitingApprovalSearchParams,
  byProperty,
  byStringPropertyCaseInsensitive,
  ComponentConfiguration,
  createSortParams,
  DictionaryEntry,
  Environment,
  isDefined,
  NotificationService,
  Order,
  PageRequest,
  PageResponse,
  ProposedAlias,
  SortDirection,
  SortParams,
  TransactionBaseResource,
  TransactionDataSource,
  TransactionEnvironmentMapping,
  TypedChanges,
} from '@demica/core/core';

import { ModalService } from '../../../../service/modal.service';

import { TextTableHeaderComponent } from '../../../data-table/text-table-header.component';
import { AliasesAwaitingApprovalRowComponent } from '../aliases-awaiting-approval-row/aliases-awaiting-approval-row.component';
import { AliasesAwaitingApprovalSearchFormValue } from '../aliases-awaiting-approval-search/aliases-awaiting-approval-search.component';

import { Actions } from '../../../actions/model/actions.interface';
import { DataSource } from '../../../data-table/data-source.interface';
import { DataTableSelection } from '../../../data-table/data-table-selection.interface';
import { defaultSearchParams, mapFormToSearchParams } from './aliases-awaiting-approval.utils';

@Component({
  selector: 'trf-aliases-awaiting-approval',
  templateUrl: 'aliases-awaiting-approval.component.html',
  styleUrls: ['aliases-awaiting-approval.component.sass'],
})
export class AliasesAwaitingApprovalComponent implements DataTableSelection, OnInit, OnChanges {
  @Input()
  transactions: TransactionBaseResource[] = [];
  @Input()
  versionPreviewMode = false;
  @Input()
  loaded = false;
  @Input()
  dataSources: TransactionDataSource[] = [];
  @Input()
  analysisGroups: AnalysisGroupResource[] = [];
  @Input()
  environments: Environment[] = [];
  @Input()
  environmentsMapping: TransactionEnvironmentMapping[];
  @Input()
  showTransactions = false;
  @Input()
  previewMode: boolean;

  loadingFilters = true;
  loading = false;
  dataLoaded = false;
  isEmpty = true;

  allSelected = false;
  indeterminateSelected = false;
  enableRejectButton = false;
  enableAcceptButton = false;
  enableBulkButtons = false;
  filtersChanged = false;
  hasSelectedTransaction = false;
  hasSelectedAnalysisGroup = false;

  searchParams: AwaitingApprovalSearchParams;

  aliasAwaitingApprovals: AliasAwaitingApproval[];
  dataSource: DataSource<AliasAwaitingApproval[]> = {
    data: new BehaviorSubject([]),
  };

  headerConfig: ComponentConfiguration;
  rowConfig: ComponentConfiguration;

  pageResponse: PageResponse;

  private _pageRequest: PageRequest = {
    pageNumber: 0,
    size: 25,
    sort: [
      createSortParams({
        sortColumn: 'firstAppearanceDate',
        sortDirection: SortDirection.DESC,
      }),
    ],
  };

  private _columnIdsToTranslate = [2, 17];

  constructor(
    private _aliasResourceService: AliasesResourceService,
    private _modalService: ModalService,
    private _notifications: NotificationService,
  ) {}

  _showTooltipHandler = (row: AliasAwaitingApproval): string | null =>
    isDefined(row.aliasInfo) ? 'ALIASES.' + row.aliasInfo.messageCode : null;

  ngOnInit(): void {
    this.headerConfig = this._createHeaderConfig();
    this.rowConfig = this._createRowConfig();
  }

  ngOnChanges(changes: TypedChanges<AliasesAwaitingApprovalComponent>): void {
    if (changes.loaded) {
      this._loadData();
    }
  }

  performSearch(): void {
    this.aliasAwaitingApprovals = [];
    this._resolvePage(0);
  }

  onSearch(event: AliasesAwaitingApprovalSearchFormValue): void {
    this.searchParams = mapFormToSearchParams(
      this.transactions.map((transaction) => transaction.entityId),
      event,
    );
    this._markSelectedCriteria(event);
    this.performSearch();
  }

  onClear(): void {
    this.searchParams = defaultSearchParams(
      this.transactions.map((transaction) => transaction.entityId),
    );
    this.aliasAwaitingApprovals = [];
    this.dataLoaded = false;
  }

  onPageChange(pageNumber: number): void {
    this._resolvePage(pageNumber);
  }

  onSortChanged(params: SortParams): void {
    this._pageRequest.sort = [
      createSortParams({
        sortColumn: params.property,
        sortDirection: params.order === Order.ASC ? SortDirection.ASC : SortDirection.DESC,
      }),
    ];
    this._resolvePage(0);
  }

  onFilterChange(): void {
    this.filtersChanged = true;
    this._checkEnableBulkButtons();
  }

  onAcceptMappings(): void {
    if (this.enableAcceptButton) {
      const selectedAliases = this.aliasAwaitingApprovals.filter(
        (item) => item.selected && !isDefined(item.aliasInfo),
      );
      this._acceptMappings(
        selectedAliases.map((alias) => this._proposedAlias(alias)),
        selectedAliases.length,
      );
    }
  }

  onRejectMappings(): void {
    if (this.enableRejectButton) {
      const selectedAliases = this.aliasAwaitingApprovals.filter((item) => item.selected);
      this._rejectMappings(
        selectedAliases.map((alias) => this._proposedAlias(alias)),
        selectedAliases.length,
      );
    }
  }

  onApproveAllMappings(): void {
    if (this.enableBulkButtons) {
      this._modalService
        .openConfirmationModal(
          'ALIASES.APPROVE_PROPOSED_MISSING_MAPPING_MESSAGE',
          'ALIASES.APPROVE_MISSING_MAPPING_ACTION',
          this._aliasResourceService.approveAllProposedMapping(this.searchParams),
        )
        .onSuccess(() => {
          this._notifications.success('ALIASES.STARTED_ALIASES_PROCESSING');
          this.performSearch();
        })
        .onError(this.performSearch);
    }
  }

  onRejectAllMappings(): void {
    if (this.enableBulkButtons) {
      this._modalService
        .openConfirmationModal(
          'ALIASES.REJECT_PROPOSED_MAPPING_MESSAGE',
          'ALIASES.REJECT_MISSING_MAPPING_ACTION',
          this._aliasResourceService.rejectAllProposedMapping(this.searchParams),
        )
        .onSuccess(() => {
          this._notifications.success('ALIASES.STARTED_ALIASES_PROCESSING');
          this.performSearch();
        })
        .onError(this.performSearch);
    }
  }

  isAllSelected(): boolean {
    return this.aliasAwaitingApprovals.every((item) => item.selected);
  }

  isIndeterminateSelected(): boolean {
    return (
      this.aliasAwaitingApprovals.some((item) => item.selected) &&
      !this.aliasAwaitingApprovals.every((item) => item.selected)
    );
  }

  private _getRowActions(): Actions {
    return {
      add: {
        titleKey: 'ALIASES.TABLE_ACTION_APPROVE',
        handler: this._onAcceptMapping.bind(this),
        icon: 'check',
        testId: 'approve-action-button',
        hidden: () => this.versionPreviewMode || this.previewMode,
        inactive: (row: AliasAwaitingApproval) => isDefined(row.aliasInfo),
        tooltipHandler: this._showTooltipHandler,
      },
      reject: {
        titleKey: 'ALIASES.TABLE_ACTION_REJECT',
        handler: this._onRejectMapping.bind(this),
        icon: 'times',
        testId: 'action-reject',
        hidden: () => this.versionPreviewMode || this.previewMode,
      },
    };
  }

  private _markSelectedCriteria(event: AliasesAwaitingApprovalSearchFormValue): void {
    const isAdministrationComponent = this.showTransactions;
    const isSelectedTransaction = event.transaction;
    this.hasSelectedTransaction = !(isAdministrationComponent && !isSelectedTransaction);
    this.hasSelectedAnalysisGroup = !!this.searchParams.analysisGroupName;
  }

  private _addKeys(analysisGroups: DictionaryEntry[]): DictionaryEntry[] {
    analysisGroups.forEach((analysisGroup) => {
      this._addKeyToTranslatedAnalysisGroup(analysisGroup);
    });
    return analysisGroups;
  }

  private _addKeyToTranslatedAnalysisGroup(analysisGroup: DictionaryEntry): void {
    if (this._columnIdsToTranslate.includes(Number(analysisGroup.entityId))) {
      analysisGroup.key = String(analysisGroup.entityId);
    }
  }

  private _resolvePage(pageNumber: number): void {
    this.allSelected = false;
    this.loading = true;
    this._pageRequest.pageNumber = pageNumber;
    this._aliasResourceService
      .getAwaitingApprovalPageable(this._pageRequest, this.searchParams)
      .subscribe((value) => {
        this.aliasAwaitingApprovals = value.data;
        this.aliasAwaitingApprovals.forEach((item) => {
          this._addKeyToTranslatedAnalysisGroup(item.analysisGroup);
        });
        this.dataSource.data.next(this.aliasAwaitingApprovals);
        this.pageResponse = value.meta.pagination;
        this.loading = false;
        this.dataLoaded = true;
        this.isEmpty = this.aliasAwaitingApprovals.length === 0;
        this.filtersChanged = false;
        this._checkEnableButtons();
        this._checkEnableBulkButtons();
      });
  }

  private _successHandler(elementsChange: number): void {
    const pageToGet =
      elementsChange === this.pageResponse.numberOfElements
        ? this.pageResponse.pageNumber - 1
        : this.pageResponse.pageNumber;
    this._resolvePage(pageToGet);
  }

  private _onSelectAllCheckboxChanged(changed: boolean): void {
    this.indeterminateSelected = false;
    this._setAllSelected(changed);
    this._checkEnableButtons();
  }

  private _onRowCheckboxChanged(): void {
    this.allSelected = this.isAllSelected();
    this.indeterminateSelected = this.isIndeterminateSelected();
    this._checkEnableButtons();
  }

  private _setAllSelected(value: boolean): void {
    this.allSelected = value;
    this.aliasAwaitingApprovals.forEach((item) => (item.selected = value));
  }

  private _checkEnableButtons(): void {
    this.enableRejectButton =
      this.aliasAwaitingApprovals.filter((item) => item.selected).length > 0;
    this.enableAcceptButton =
      this.enableRejectButton &&
      this.aliasAwaitingApprovals.find((alias) => !isDefined(alias.aliasInfo) && alias.selected) !==
        undefined;
  }

  private _onAcceptMapping(aliasAwaitingApproval: AliasAwaitingApproval): void {
    this._acceptMappings([this._proposedAlias(aliasAwaitingApproval)], 1);
  }

  private _onRejectMapping(aliasAwaitingApproval: AliasAwaitingApproval): void {
    this._rejectMappings([this._proposedAlias(aliasAwaitingApproval)], 1);
  }

  private _rejectMappings(mappings: ProposedAlias[], mappingsCount: number): void {
    this._modalService
      .openConfirmationModal(
        'ALIASES.REJECT_MISSING_MAPPING_MESSAGE',
        'ALIASES.REJECT_MISSING_MAPPING_ACTION',
        this._aliasResourceService.rejectProposedMapping({ proposedAliases: mappings }),
      )
      .onSuccess(() => this._successHandler(mappingsCount))
      .onError(this.performSearch);
  }

  private _acceptMappings(mappings: ProposedAlias[], mappingsCount: number): void {
    this._aliasResourceService
      .acceptProposedMapping({ proposedAliases: mappings })
      .subscribe(() => {
        this._successHandler(mappingsCount);
      });
  }

  private _proposedAlias(aliasAwaitingApproval: AliasAwaitingApproval): ProposedAlias {
    return {
      transactionId: aliasAwaitingApproval.transaction.entityId,
      dataSourceId: aliasAwaitingApproval.dataSource.entityId,
      columnTypeId: aliasAwaitingApproval.analysisGroup.entityId,
      environmentId: aliasAwaitingApproval.environment.entityId,
      value: aliasAwaitingApproval.value,
      proposedAnalysisCodeValue: aliasAwaitingApproval.proposedAnalysisCodeValue,
    };
  }

  private _createHeaderConfig(): ComponentConfiguration {
    const columns = [];
    if (this.showTransactions) {
      columns.push({ nameKey: 'ALIASES.TABLE_COLUMN_TRANSACTION' });
    }
    columns.push({
      nameKey: 'ALIASES.TABLE_COLUMN_PROPOSED_ANALYSIS_CODE',
      property: 'proposedAnalysisCodeValue',
      comparator: byStringPropertyCaseInsensitive('proposedAnalysisCodeValue'),
    });
    columns.push({
      nameKey: 'ALIASES.TABLE_COLUMN_ALIAS_VALUE',
      property: 'missingMappingId.value',
      comparator: byStringPropertyCaseInsensitive('value'),
    });
    columns.push({ nameKey: 'ALIASES.TABLE_COLUMN_DATA_SOURCE' });
    columns.push({ nameKey: 'ALIASES.TABLE_COLUMN_ANALYSIS_GROUP_NAME' });
    columns.push({ nameKey: 'ALIASES.TABLE_COLUMN_ENVIRONMENT' });
    columns.push({
      nameKey: 'ALIASES.TABLE_COLUMN_IMPORT_DATE',
      property: 'firstAppearanceDate',
      comparator: byProperty('firstAppearanceDate'),
    });
    columns.push({ nameKey: 'ALIASES.TABLE_COLUMN_ACTIONS', classes: 'actions fixed-width' });

    return {
      component: TextTableHeaderComponent,
      inputs: {
        columns: columns,
        initialSort: {
          property: 'firstAppearanceDate',
          order: Order.DESC,
        },
        hasSelectAllCheckbox: !this.previewMode,
        allSelected: false,
        indeterminateSelected: false,
      },
      outputs: {
        checkboxClicked: (change: boolean) => this._onSelectAllCheckboxChanged(change),
        sortChanged: (sp: SortParams) => this.onSortChanged(sp),
      },
    };
  }

  private _createRowConfig(): ComponentConfiguration {
    return {
      component: AliasesAwaitingApprovalRowComponent,
      inputs: {
        actions: this._getRowActions(),
        showTransactions: this.showTransactions,
        displayCheckbox: !this.previewMode,
      },
      outputs: {
        checkboxChanged: () => this._onRowCheckboxChanged(),
      },
    };
  }

  private _checkEnableBulkButtons(): void {
    this.enableBulkButtons =
      this.aliasAwaitingApprovals &&
      !this.filtersChanged &&
      this.hasSelectedTransaction &&
      this.hasSelectedAnalysisGroup;
  }

  private _loadData(): void {
    this.analysisGroups = this._addKeys(this.analysisGroups);
    this.searchParams = defaultSearchParams(
      this.transactions.map((transaction) => transaction.entityId),
    );
    this.loadingFilters = false;
  }
}
