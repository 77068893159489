import { AnalyticsEventTypes } from '@demica/resources/analytics';
import { AnalyticsClientType } from '@demica/resources/app-config';

export type AnalyticsEventAuth = AnalyticsEventTypes.USER_LOGIN | AnalyticsEventTypes.USER_LOGOUT;

export interface AnalyticsEventProperties {
  url?: string;
  lang?: string;
}

export abstract class AnalyticsClient {
  clientType: AnalyticsClientType;

  getType(): string {
    return this.clientType;
  }

  abstract postEvent(event: AnalyticsEventTypes, props?: AnalyticsEventProperties): void;
}
