import { HttpParams } from '@angular/common/http';

const toSingleObject = (o1: object, o2: object): object => Object.assign({}, o1, o2);

type PropertyMapper = ([string, any]: [string, unknown]) => unknown[];

interface UrlParams {
  [key: string]: string | number | boolean | undefined;
}

interface UrlParamsWithoutUndefined {
  [key: string]: string | number | boolean;
}

/**
 * Encode url params, filter out parameters that are 'undefined' or 'null'
 * @param inputParams
 */
export function encodeParams(inputParams: UrlParams): HttpParams {
  const params = { ...inputParams };

  filterOutUndefinedParams(params);
  const encodedParams = objectEntryMap(params, paramEncoder);

  return new HttpParams({ fromObject: encodedParams as UrlParamsWithoutUndefined });
}

function objectEntryMap(obj: UrlParams, propertyMapper: PropertyMapper): UrlParams {
  return Object.entries(obj)
    .map(propertyMapper)
    .map(([key, val]) => ({ [key as string]: val }))
    .reduce(toSingleObject, {});
}

function filterOutUndefinedParams(params: UrlParams): void {
  Object.keys(params).forEach((key) => params[key] == null && delete params[key]);
}

function paramEncoder(entry: [string, unknown]): [string, string] {
  const [key, value] = entry;

  if (typeof value === 'string') return [key, value];
  else if (typeof value === 'number') return [key, String(value)];
  else if (typeof value === 'boolean') return [key, String(value)];

  throw new Error(`Incorrect parameter type. Expected string|number|boolean. Got ${typeof value}`);
}
