import { Injectable } from '@angular/core';

import { requiredMsg } from '../forms/validation-messages/common-validation-messages';
import { buildMessages } from '../forms/validation-messages/message-builder';
import {
  msgDateFormat,
  msgDefaultMaxLength,
  msgRequired,
} from '../forms/validation-messages/message-definitions';
import { hasError } from '../forms/validation-messages/message-predicates';

@Injectable()
export class UserDefinitionFormValidations {
  requireValidations = requiredMsg;
  nameValidations = buildMessages([
    msgRequired,
    msgDefaultMaxLength,
    {
      func: hasError('user-definition-name-unavailable'),
      key: 'ERRORS.USER_DEFINITION_NAME_NOT_UNIQUE',
    },
  ]);
}
