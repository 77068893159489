import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AlertsCounter, MenuOption } from '@demica/core/core';

@Component({
  selector: 'trf-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.sass'],
})
export class MenuItemComponent implements OnInit, OnDestroy {
  @Input()
  menuOption: MenuOption;

  notificationCount = 0;

  private destroy$ = new Subject<void>();

  constructor(private alertsCounter: AlertsCounter) {}

  ngOnInit() {
    if (this.menuOption.id === 'alerts-option') {
      this.alertsCounter.unreadAlertsSubject.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.notificationCount = data[0].count + data[1].count;
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
