import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'ngp-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements ControlValueAccessor, OnInit {
  @Input()
  name = '';
  @Input()
  inputClasses?: string;
  @Input()
  label?: string;
  @Input()
  labelClasses?: string;
  @Input()
  value = '';
  @Input()
  disabled = false;
  @Input()
  checked = false;

  labelId = '';
  group = new FormGroup({});

  hash = Math.random().toString(36).substr(2, 5);

  @Output()
  radioButtonChecked = new EventEmitter<boolean>();

  constructor(private fgd: FormGroupDirective) {}

  writeValue(checked: boolean) {
    this.checked = checked;
  }

  ngOnInit() {
    this.group = this.fgd.form;
    if (this.label) {
      this.labelId = this.label.replace(' ', '-').replace('& ', '').toLowerCase();
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this._onTouched = fn;
  }

  toggleRadio(isChecked: boolean) {
    this.checked = isChecked;
    this._onChange(this.checked);
    this.radioButtonChecked.emit(this.checked);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  private _onChange = (_: boolean) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onTouched = () => {};
}
