import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Subject } from 'rxjs';

import { NotificationTypes } from '../interface/notification.types';
import { removeElementAtIndex } from '../utils/collection';

interface Notification {
  type: NotificationTypes;
  messageCode: string;
  messageParams?: Record<string, unknown>;
}

@Injectable()
export class NotificationService {
  notificationQueue$ = new Subject<Notification>();
  notificationClearAll$ = new Subject<void>();
  visibleNotifications: Notification[] = [];

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.notificationClearAll$.next();
      }
    });
  }

  success(messageCode: string, messageParams?: Record<string, unknown>) {
    const notification = { type: NotificationTypes.success, messageCode, messageParams };
    this.visibleNotifications.push(notification);
    this.notificationQueue$.next(notification);
  }

  warning(messageCode: string, messageParams?: Record<string, unknown>) {
    const notification = { type: NotificationTypes.warning, messageCode, messageParams };
    this.visibleNotifications.push(notification);
    this.notificationQueue$.next(notification);
  }

  error(messageCode: string, messageParams?: Record<string, unknown>) {
    const notification = { type: NotificationTypes.error, messageCode, messageParams };
    this.visibleNotifications.push(notification);
    this.notificationQueue$.next(notification);
  }

  errorWithoutRepetitions(messageCode: string, messageParams?: Record<string, unknown>) {
    if (!this.isNotificationRepeated(messageCode)) {
      const notification = { type: NotificationTypes.error, messageCode, messageParams };
      this.visibleNotifications.push(notification);
      this.notificationQueue$.next(notification);
    }
  }

  private isNotificationRepeated(messageCode: string): boolean {
    const repeatedNotification = this.visibleNotifications.find((visibleNotification) => {
      return (
        visibleNotification.messageCode === messageCode &&
        visibleNotification.type === NotificationTypes.error
      );
    });
    return repeatedNotification != null;
  }

  notify(errorCode: string, messageParams?: Record<string, unknown>) {
    const messageCode = 'NOTIFICATION.ERROR_CODE.' + (errorCode || 'DEFAULT');
    this.error(messageCode, messageParams);
  }

  notifyWithoutRepetitions(errorCode: string) {
    const messageCode = 'NOTIFICATION.ERROR_CODE.' + (errorCode || 'DEFAULT');
    this.errorWithoutRepetitions(messageCode);
  }

  clearAll() {
    this.visibleNotifications = [];
    this.notificationClearAll$.next();
  }

  hideNotification(messageCode: string, type: string) {
    const notificationToHide = this.visibleNotifications.findIndex(
      (notification) => notification.messageCode === messageCode && notification.type === type,
    );
    removeElementAtIndex(this.visibleNotifications, notificationToHide);
  }
}
