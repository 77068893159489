<div class="column-label">
  <label
    [id]="labelId"
    aria-hidden="true"
  >
    {{ labelKey | translate }}
  </label>
</div>
<div class="column-input text-wrap">
  <p [attr.aria-labelledby]="labelId">{{ text }}</p>
</div>
