<form
  *ngIf="!loading"
  [formGroup]="form"
  [ngClass]="{ 'trf-form-submitted': submitted }"
>
  <trf-form-decimal-group-row
    placeholder="RANGES.FORM_RANGE_LOWER_RANGE_PLACEHOLDER"
    [label]="lowerRangeFormLabel"
    [name]="RANGE_FORM_PARAMS.lowerRange"
    [labelClasses]="mandatory.lowerRange ? 'required' : ''"
    [appendText]="percentage.lowerRange ? '%' : ''"
    [acceptNegatives]="false"
    [readonly]="true"
    [removeTrailingZeros]="removeTrailingZeros"
    [decimalPlaces]="numberOfDecimalPlacesForRangePoints"
    data-testid="ranges-form-lower-range"
  ></trf-form-decimal-group-row>

  <trf-form-decimal-group-row
    placeholder="RANGES.FORM_RANGE_UPPER_RANGE_PLACEHOLDER"
    [label]="upperRangeFormLabel"
    [name]="RANGE_FORM_PARAMS.upperRange"
    [labelClasses]="mandatory.upperRange ? 'required' : ''"
    [appendText]="percentage.upperRange ? '%' : ''"
    [acceptNegatives]="false"
    [validations]="rangeValidation"
    [readonly]="previewMode"
    [removeTrailingZeros]="removeTrailingZeros"
    [decimalPlaces]="numberOfDecimalPlacesForRangePoints"
    [maxValue]="maxValueForRangePoints"
    data-testid="ranges-form-upper-range"
  ></trf-form-decimal-group-row>

  <trf-currency-amount-row
    *ngIf="!percentage.value && currency"
    placeholder="RANGES.FORM_RANGE_VALUE_PLACEHOLDER"
    [label]="valueLabel ? valueLabel : 'RANGES.FORM_RANGE_VALUE.' + rateType"
    [name]="RANGE_FORM_PARAMS.value"
    [labelClasses]="mandatory.value ? 'required' : ''"
    [acceptNegatives]="acceptNegativeValues"
    [validations]="validateMinMaxValue ? rangeValidation : requiredValidation"
    [readonly]="previewMode"
    [removeTrailingZeros]="removeTrailingZeros"
    [currency]="currency.entityId"
    data-testid="ranges-form-currency-value"
  ></trf-currency-amount-row>

  <trf-form-decimal-group-row
    *ngIf="!percentage.value && !currency"
    placeholder="RANGES.FORM_RANGE_VALUE_PLACEHOLDER"
    [label]="valueLabel ? valueLabel : 'RANGES.FORM_RANGE_VALUE.' + rateType"
    [name]="RANGE_FORM_PARAMS.value"
    [labelClasses]="mandatory.value ? 'required' : ''"
    [acceptNegatives]="acceptNegativeValues"
    [validations]="validateMinMaxValue ? rangeValidation : requiredValidation"
    [readonly]="previewMode"
    [removeTrailingZeros]="removeTrailingZeros"
    [decimalPlaces]="numberOfDecimalPlacesForValue"
    data-testid="ranges-form-value"
  ></trf-form-decimal-group-row>

  <trf-form-decimal-group-row
    *ngIf="percentage.value"
    placeholder="RANGES.FORM_RANGE_VALUE_PLACEHOLDER"
    [label]="valueLabel ? valueLabel : 'RANGES.FORM_RANGE_VALUE.' + rateType"
    [name]="RANGE_FORM_PARAMS.value"
    [labelClasses]="mandatory.value ? 'required' : ''"
    [appendText]="'%'"
    [acceptNegatives]="acceptNegativeValues"
    [validations]="rangeValidation"
    [readonly]="previewMode"
    [removeTrailingZeros]="removeTrailingZeros"
    data-testid="ranges-form-percentage-value"
  ></trf-form-decimal-group-row>
</form>

<div class="trf-section-buttons space-between">
  <ngp-button
    key="RANGES.BUTTON_CANCEL"
    [classes]="['tertiary', previewMode || versionPreviewMode ? 'inactive' : ''].join(' ')"
    (click)="onCancel()"
    data-testid="ranges-form-cancel-button"
  ></ngp-button>
  <ngp-button
    key="RANGES.BUTTON_SAVE"
    [classes]="['primary', previewMode || versionPreviewMode ? 'inactive' : ''].join(' ')"
    (click)="onSave()"
    data-testid="ranges-form-save-button"
  ></ngp-button>
</div>
