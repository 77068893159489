import { DECIMAL_FORMATS_CONFIGURATION, Locale } from '@demica/core/core';

import * as d3 from 'd3';
import { FormatLocaleDefinition, TimeLocaleDefinition } from 'd3';

export class LocaleDefinitionService {
  public static readonly BILLIONS_SUFFIX = 'B';

  private static _regex = new RegExp('^[0]$');

  static getDefaultCurrencyFormat(localeDefinition: FormatLocaleDefinition) {
    return (value: string) => {
      if (this._regex.test(value)) {
        return value;
      } else {
        const currency = localeDefinition.currency[0];
        const formattedValue = d3
          .formatDefaultLocale(localeDefinition)
          .format(',.3s')(Number(value))
          .replace('G', LocaleDefinitionService.BILLIONS_SUFFIX);
        return `${currency} ${formattedValue}`;
      }
    };
  }

  static getLocalDefinition(locale: Locale, currencySymbol: string) {
    const { decimalSeparator, thousandsSeparator } = DECIMAL_FORMATS_CONFIGURATION[locale];

    const localeDefinition = this.getEnGbLocaleDefinition(currencySymbol);

    if (Locale.EN_US !== locale) {
      localeDefinition.decimal = decimalSeparator;
      localeDefinition.thousands = thousandsSeparator;
    }

    return localeDefinition;
  }

  static getEnGbLocaleDefinition(symbol: string): FormatLocaleDefinition & TimeLocaleDefinition {
    return {
      decimal: '.',
      thousands: ',',
      grouping: [3],
      currency: [symbol, ''],
      dateTime: '%a %b %e %X %Y',
      date: '%m/%d/%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    };
  }
}
