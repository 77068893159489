import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { BehaviorSubject, combineLatest, Observable, Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  KeyboardNavigationStrategyService,
  TableAttributesSetterService,
} from '@demica/accessibility';
import {
  ComponentConfiguration,
  ExpandableComponentConfiguration,
  TypedChanges,
} from '@demica/core/core';

import { ColumnToggleStateService } from './column-toggle-state.service';

import { ColumnDefinition } from './column-definition.interface';
import { DataSource } from './data-source.interface';
import { TableDragDrop } from './table-drag-drop-strategy';

interface HeaderRef {
  allSelected: boolean;
  indeterminateSelected?: boolean;
}

interface RowRef {
  row: unknown;
}

@Component({
  selector: 'trf-data-table',
  templateUrl: 'data-table.component.html',
  styleUrls: ['./data-table.component.sass'],
  providers: [ColumnToggleStateService],
})
// TODO: TRFV2-3891 Refactor to proper types from "any"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DataTableComponent<T = any, T2 = any>
  implements OnInit, AfterContentInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input()
  tableClasses = '';
  @Input()
  bodyClasses = '';
  @Input()
  dataSource: DataSource<T[]>;
  @Input()
  headerConfiguration: ComponentConfiguration;
  @Input()
  rowConfiguration: ComponentConfiguration;
  @Input()
  expandableConfiguration: ExpandableComponentConfiguration<T, T2> | null = null;
  @Input()
  tableLabel = '';
  @Input()
  expanded: number[] = [];
  @Input()
  expandAll = false;
  @Input()
  dropdownOutput: ViewContainerRef;
  // dropdown props
  @Input()
  columnDefinitions: ColumnDefinition[];
  @Input()
  buttonTranslationPrefix: string;
  @Input()
  enabledColumns: ColumnDefinition[] = [];
  @Output()
  visibleColumnChanged = new EventEmitter<ColumnDefinition[]>();

  visibleColumns$: Observable<ColumnDefinition[]>;
  expandedSubject = new BehaviorSubject<number[]>([]);
  expandAllSubject = new BehaviorSubject<boolean>(false);

  @Input()
  set allSelected(value: boolean) {
    if (this.headerRef) {
      this.headerRef.instance.allSelected = value;
    }
  }

  @Input()
  set indeterminateSelected(value: boolean) {
    if (this.headerRef) {
      this.headerRef.instance.indeterminateSelected = value;
    }
  }

  @Output()
  rowClick = new EventEmitter<T>();

  @ViewChild('header', { read: ViewContainerRef, static: true })
  header: ViewContainerRef;

  @ViewChild('rows', { read: ViewContainerRef, static: true })
  rows: ViewContainerRef;

  @ViewChild('tableBody', { static: true })
  tableBody: HTMLElement;

  @ViewChild('tableColumnDropdownToggleTemplate', { static: true, read: TemplateRef })
  toggleColumnsDropdownComponentTemplate: TemplateRef<unknown>;

  displayTable = false;
  headerRef: ComponentRef<HeaderRef>;
  rowsRefs: ComponentRef<unknown>[] = [];

  private _destroyed$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private toggleStateService: ColumnToggleStateService,
    @Optional() private keyboardNavigationStrategy: KeyboardNavigationStrategyService,
    @Optional() private tableAttributesSetter: TableAttributesSetterService,
    @Optional() private dragDrop: TableDragDrop<T>,
  ) {}

  ngOnChanges(changes: TypedChanges<DataTableComponent>): void {
    if (changes.expanded) {
      this.expandedSubject.next(this.expanded);
    }
    if (changes.expandAll) {
      this.expandAllSubject.next(this.expandAll);
    }
  }

  ngOnInit(): void {
    this.dragDrop?.registerTable(this.tableBody, this.dataSource);
    if (this.shouldShowColumnToggleBeInitialized()) {
      this.toggleStateService.setColumnDefinitions(this.columnDefinitions);
      this.visibleColumns$ = this.toggleStateService.getVisibleColumns$();
      this.visibleColumns$.pipe(takeUntil(this._destroyed$)).subscribe((columns) => {
        this.visibleColumnChanged.emit(columns);
      });
    }
  }

  ngAfterViewInit(): void {
    this.addInteractiveClassToTableElement();

    combineLatest([this.dataSource.data, this.expandedSubject, this.expandAllSubject]).subscribe(
      ([data, expanded, expandAll]) => {
        this.rows.clear();
        this.displayTable = data.length > 0;
        this.rowsRefs = [];

        let index = 0;
        for (const row of data) {
          this.createRow(this.rowConfiguration, row);
          this.prepareExpandableRows(expanded, expandAll, index, row);

          index++;
        }

        this.dragDrop?.registerRows(data, this.rowsRefs);
        this.setAttributesForRowTdElements();
        this.returnFocusToLastActionIcon();
      },
    );
    this.changeDetector.detectChanges();
  }

  ngAfterContentInit(): void {
    if (!this.headerConfiguration) return;

    this.headerRef = this.header.createComponent(this.headerConfiguration.component);

    Object.assign(this.headerRef.instance, this.headerConfiguration.inputs);

    if (this.headerConfiguration.outputs) linkOutputs(this.headerRef, this.headerConfiguration);
    if (this.shouldShowColumnToggleBeInitialized()) {
      // We need to wait for the view to be initialized before we can create the embedded view,
      // to avoid ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => {
        this.dropdownOutput.createEmbeddedView(this.toggleColumnsDropdownComponentTemplate);
      });
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onClick(object: T): void {
    this.rowClick.emit(object);
  }

  onRowKeyup(event: KeyboardEvent): void {
    this.keyboardNavigationStrategy?.onRowKeyNavigate(event);
  }

  activateZoomOnRows(event: KeyboardEvent): void {
    const target = event.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'table') {
      const firstHeader = this.headerConfiguration
        ? this.headerRef.location.nativeElement.children[0]
        : this.rowsRefs[0].location.nativeElement.children[0];
      this.keyboardNavigationStrategy?.onFocusToTable(event.key, firstHeader);
    }
  }

  setAttributesForRowTdElements(): void {
    this.changeDetector.detectChanges();
    this.tableAttributesSetter?.setAttributes(this.rowsRefs);
  }

  returnFocusToLastActionIcon(): void {
    if (this.keyboardNavigationStrategy?.lastActionIconId$.getValue() !== null) {
      this.changeDetector.detectChanges();
      this.keyboardNavigationStrategy?.focusLastActionElement(this.rowsRefs);
    }
  }

  private prepareExpandableRows(
    expanded: number[],
    expandAll: boolean,
    index: number,
    row: T,
  ): void {
    const isExpanded = expandAll || expanded.includes(index);

    if (!isExpanded || !this.expandableConfiguration) {
      return;
    }

    const rows = this.expandableConfiguration.data(row);

    if (!this.expandableConfiguration.renderHeaderIfNoData && rows.length === 0) {
      return;
    }

    this.createRow(this.expandableConfiguration.header, row);

    for (const expandableRow of rows) {
      this.createRow(this.expandableConfiguration.row, expandableRow);
    }
  }

  private createRow(configuration: ComponentConfiguration, row: T | T2): void {
    try {
      const rowRef = this.rows.createComponent(configuration.component);
      const instance = rowRef.instance as RowRef;
      instance.row = row;
      rowRef.location.nativeElement.addEventListener('click', this.onClick.bind(this, row));
      rowRef.location.nativeElement.classList.add('data-table-row');
      rowRef.location.nativeElement.setAttribute('testid', 'table-row');
      rowRef.location.nativeElement.setAttribute('data-testid', 'table-row');

      Object.assign(rowRef.instance, configuration.inputs);

      if (configuration.outputs) linkOutputs(rowRef, configuration);

      this.rowsRefs.push(rowRef);
    } catch (err) {
      console.error(['Verify whether the component row instance is declared in the module', err]);
      throwError(err);
    }
  }

  /** 'interactive' class governs the hover effect on rows and is added only if table
   * has *actions* defined or has observers registered on the *rowClick* output.
   */
  private addInteractiveClassToTableElement(): void {
    const hasActions = !!this.rowConfiguration?.inputs?.actions;
    const hasRowClickObservers = !!this.rowClick?.observers?.length;

    if (hasActions || hasRowClickObservers)
      this.tableClasses = [...this.tableClasses.split(' '), 'interactive'].join(' ');
  }

  private shouldShowColumnToggleBeInitialized(): boolean {
    return !!this.dropdownOutput;
  }
}

function linkOutputs(componentRef: ComponentRef<unknown>, config: ComponentConfiguration): void {
  Object.keys(config.outputs).forEach((key) => {
    if ((componentRef.instance as any)[key]) {
      const output = componentRef.instance[
        key as keyof typeof componentRef.instance
      ] as EventEmitter<unknown>;
      output.subscribe(config.outputs[key]);
    }
  });
}
