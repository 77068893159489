<div testid="ranges">
  <div class="slidein-section-title info section-header-tooltip">
    <h5>{{ title | translate }}</h5>
    <ngp-tooltip-icon
      tooltipKey="RANGES.TOOL_TIP"
      tooltipTestId="ranges"
    ></ngp-tooltip-icon>
  </div>

  <trf-ranges-form
    *ngIf="rangeFormVisible"
    [range]="editedRange"
    [rangeType]="rangeType"
    [rateType]="rateType"
    [valueLabel]="valueLabel"
    [lowerRangeFormLabel]="lowerRangeFormLabel"
    [upperRangeFormLabel]="upperRangeFormLabel"
    [previewMode]="previewMode"
    [versionPreviewMode]="versionPreviewMode"
    [numberOfDecimalPlacesForValue]="numberOfDecimalPlacesForValue"
    [numberOfDecimalPlacesForRangePoints]="numberOfDecimalPlacesForRangePoints"
    [maxValueForRangePoints]="maxValueForRangePoints"
    [removeTrailingZeros]="removeTrailingZeros"
    [currency]="currency"
    [acceptNegativeValues]="acceptNegativeValues"
    [validateMinMaxValue]="validateMinMaxValue"
    (saveButtonClick)="onFormSaveButtonClick($event)"
    (cancelButtonClick)="onFormCancelButtonClick()"
  ></trf-ranges-form>

  <trf-ranges-list
    [ranges]="ranges"
    [rangeType]="rangeType"
    [rateType]="rateType"
    [previewMode]="previewMode"
    [versionPreviewMode]="versionPreviewMode"
    [formOpened]="rangeFormVisible"
    [valueLabel]="valueLabel"
    [lowerRangeTableLabel]="lowerRangeTableLabel"
    [upperRangeTableLabel]="upperRangeTableLabel"
    [numberOfDecimalPlacesForValue]="numberOfDecimalPlacesForValue"
    [editRangeRequiredAuthority]="editRangeRequiredAuthority"
    (editClick)="onEditRangeButtonClick($event)"
  ></trf-ranges-list>
</div>
