<ng-container [formGroup]="group">
  <div data-testid="form-number-row">
    <div
      *ngIf="!!label"
      class="column-label"
      [ngClass]="{ 'row-disabled': formControl.disabled || disable }"
    >
      <label
        [for]="htmlId || formControlName"
        [class]="labelClasses"
        >{{ label | translate }}</label
      >
      <ngp-tooltip-icon
        *ngIf="helperTooltip"
        [tooltipKey]="helperTooltip"
        [iconAriaDesc]="ariaDescText"
      ></ngp-tooltip-icon>
    </div>

    <div class="column-input">
      <div class="input-group">
        <input
          class="form-control {{ inputClasses }}"
          type="text"
          placeholder="{{ placeholder | translate }}"
          [id]="htmlId || formControlName"
          [formControlName]="formControlName"
          [readonly]="readonly"
          [disableControl]="disable"
          [attr.aria-required]="labelClasses?.includes('required')"
          [attr.aria-describedby]="
            formControlName + '_notification min_int_notification max-int-exceeded'
          "
          (blur)="onBlur()"
          (keypress)="onKeypress($event)"
          data-testid="form-number-row-input"
        />
        <div
          *ngIf="suffixLabel"
          class="input-group-append"
        >
          <span class="input-group-text">{{ suffixLabel | translate }}</span>
        </div>
      </div>

      <trf-form-number-row-default-validation-messages
        [validations]="validations"
        [name]="formControlName"
        [input]="formControl"
      >
        <ng-container #additionalValidationContainer></ng-container>
      </trf-form-number-row-default-validation-messages>
    </div>

    <div
      *ngIf="infoTooltip"
      class="column-helper info"
    >
      <ngp-tooltip-icon
        [tooltipKey]="infoTooltip"
        [reversed]="true"
      ></ngp-tooltip-icon>
    </div>
  </div>
</ng-container>
