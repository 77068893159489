export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortParameters {
  sortDirection: SortDirection;
  sortColumn: string;
  defaultValue?: boolean;
}
