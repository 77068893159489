import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { OrganisationResourceService } from '../../transaction/organisation/service/organisation-resource.service';

import { ajaxDelay } from '../../../config/ajax';
import { CodeAvailabilityResponse } from '../../../model/rest-response/availability-response';
import {
  MemoizedAsyncFormControlValidator,
  MemoizedAsyncFormControlValidatorForFactoryFn,
} from '../../../utils/memoized-async-form-control-validator';

@Injectable()
export class SellerCodeValidator implements Validator {
  constructor(private _organisationResource: OrganisationResourceService) {}

  @MemoizedAsyncFormControlValidator()
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const code = control.value;

    return ajaxDelay.pipe(
      switchMap(() =>
        this._organisationResource.checkClientCodeAvailable(code).pipe(
          distinctUntilChanged(),
          map((response) => responseToValidationErrors(response)),
        ),
      ),
    );
  }

  @MemoizedAsyncFormControlValidatorForFactoryFn()
  validateWithId(id: () => number) {
    return function (control: AbstractControl) {
      const code = control.value;

      return ajaxDelay.pipe(
        switchMap(() =>
          this._organisationResource
            .checkOpcoCodeAvailable(code, id())
            .pipe(distinctUntilChanged(), map(responseToValidationErrors)),
        ),
      );
    };
  }
}

function responseToValidationErrors(response: CodeAvailabilityResponse) {
  if (!response.codeAvailable) return { 'opco-code-unavailable': true };

  return null;
}
