import { FieldDefinition } from '../../forms/form';

export enum FormFields {
  environment = 'environment',
  transaction = 'transaction',
  client = 'client',
  seller = 'seller',
  assetBase = 'assetBase',
}

export type FormDefinition = Record<FormFields, FieldDefinition>;
