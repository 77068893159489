import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { EntityId } from '@demica/resources/common';

import { IdProvider } from '../../id-provider.service';

@Injectable({ providedIn: 'root' })
export class SystemUserIdService extends IdProvider {
  id$: Observable<EntityId | null>;

  private _id$ = new BehaviorSubject<EntityId | null>(null);

  constructor() {
    super();

    this.id$ = this._id$.asObservable();
  }

  getId(): EntityId | null {
    return this._id$.value;
  }

  setId(userId: EntityId | null) {
    this._id$.next(userId);
  }
}
