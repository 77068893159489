import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Translatable } from '../model/translatable.interface';

@Injectable()
export class PrefixTranslationService {
  constructor(private translate: TranslateService) {}

  performTranslation(
    translationPrefix: string | string[],
    item: Translatable,
    translateService?: TranslateService,
  ): string {
    if (!item.key) return item.name;

    const translate = translateService ?? this.translate;
    if (Array.isArray(translationPrefix)) {
      for (const prefix of translationPrefix) {
        const instant = translate.instant(prefix + item.key);
        if (instant !== prefix + item.key) {
          return instant;
        }
      }
    }
    return translate?.instant('' + translationPrefix + item.key);
  }
}
