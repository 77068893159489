<trf-modal-container
  headerText="NEW_VERSION_MODAL.TITLE"
  (modalClose)="onClose()"
  testid="new-version-modal"
>
  <form
    [ngClass]="{ 'trf-form-submitted': submitted }"
    [formGroup]="form"
  >
    <div class="trf-section-content">
      <ngp-contextual-notifications
        *ngIf="subjectInvalid"
        type="warning"
      >
        <p>{{ "NEW_VERSION_MODAL.CREATING_INVALID_VERSION_MESSAGE" | translate }}</p>
        <p>{{ "NEW_VERSION_MODAL.CREATING_INVALID_VERSION_REASON" | translate }}</p>
        <ul class="coma-separated-list">
          <li *ngFor="let validationWarning of validationWarnings">{{
            getValidationErrorTranslation(validationWarning)
          }}</li>
        </ul>
      </ngp-contextual-notifications>

      <ngp-contextual-notifications
        *ngIf="specialCase"
        type="warning"
      >
        <p>{{ "NEW_VERSION_MODAL.SPECIAL_CASE_MESSAGE" | translate }}</p>
        <p>{{ "NEW_VERSION_MODAL.SPECIAL_CASE_REASON" | translate }}</p>
        <p>{{ "NEW_VERSION_MODAL.SPECIAL_CASE_HEADER" | translate }}</p>
        <ul class="coma-separated-list">
          <li *ngFor="let specialCaseWarning of specialCaseWarnings">{{
            getValidationErrorTranslation(specialCaseWarning)
          }}</li>
        </ul>
        <p>{{ "NEW_VERSION_MODAL.SPECIAL_CASE_FOOTER" | translate }}</p>
      </ngp-contextual-notifications>

      <ngp-contextual-notifications
        *ngIf="subjectIncomplete"
        type="info"
      >
        <p>{{ "NEW_VERSION_MODAL.INCOMPLETE_VERSION_MESSAGE" | translate }}</p>
        <p>{{ "NEW_VERSION_MODAL.INCOMPLETE_VERSION_REASON" | translate }}</p>
        <ul class="coma-separated-list">
          <li *ngFor="let validationInfo of validationInfos">{{
            getValidationErrorTranslation(validationInfo)
          }}</li>
        </ul>
      </ngp-contextual-notifications>

      <legend>{{ "LEGEND.REQUIRED_ASTERISK" | translate }}</legend>

      <div class="trf-form-fields">
        <trf-form-text-row
          name="name"
          label="NEW_VERSION_MODAL.NAME_LABEL"
          placeholder="NEW_VERSION_MODAL.NAME_PLACEHOLDER"
          labelClasses="required"
          [validations]="versionNameValidations"
          testid="version-name"
        >
        </trf-form-text-row>

        <trf-form-text-area
          name="description"
          label="NEW_VERSION_MODAL.COMMENT_LABEL"
          [validations]="versionDescriptionValidations"
          testid="version-description"
        >
        </trf-form-text-area>
      </div>
    </div>

    <div class="trf-modal-controls-divider space-between trf-section-buttons">
      <ngp-button
        classes="tertiary"
        key="NEW_VERSION_MODAL.BUTTON_CANCEL"
        (click)="onClose()"
        testid="version-modal-close"
      >
      </ngp-button>

      <ngp-button
        classes="primary"
        key="NEW_VERSION_MODAL.BUTTON_CREATE"
        specid="create-action"
        (click)="onSave()"
        testid="version-modal-save"
      >
      </ngp-button>
    </div>
  </form>
</trf-modal-container>
