import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

// fix for TRFV2-21338
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngbRef]' })
export class NgbRefDirective implements OnInit, OnDestroy {
  @Output() ngbRef = new EventEmitter<HTMLElement | null>();
  constructor(private _El: ElementRef) {}

  ngOnInit() {
    this.ngbRef.emit(this._El.nativeElement);
  }

  ngOnDestroy() {
    this.ngbRef.emit(null);
  }
}

/**
 * Override of ngb-accordion to render our HTML
 *
 * Our code sometimes use interactive checkbox inside ngb-accordion title
 * but this have became impossible when ngb-accordion replaced <a> with <button>
 * that made Firefox/IE to not pass click events to our checkbox.
 *
 * This component provides custom HTML with <a> instead of <button> and styles to
 * match the visual look.
 */
@Component({
  selector: 'trf-ngb-accordion',
  templateUrl: 'accordion.component.html',
  styleUrls: ['accordion.component.sass'],
})
export class AccordionComponent extends NgbAccordion {
  @HostBinding('attr.class') hostClass = 'accordion';
  @HostBinding('attr.role') hostRole = 'tablist';
  @HostBinding('attr.aria-multiselectable') hostAriaMultiselectable = !this.closeOtherPanels;
}
