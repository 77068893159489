import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective, FormControl, AbstractControl } from '@angular/forms';

import { getFormControlName } from '@demica/core/core';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { LogoHolder } from '../logo-display/logo-holder.interface';

@Component({
  selector: 'trf-form-upload-logo',
  templateUrl: 'form-upload-logo.component.html',
  styleUrls: ['./form-upload-logo.component.sass'],
})
export class FormUploadLogoComponent implements OnInit {
  @ViewChild('logoPreviewImage', { static: true })
  logoPreviewImage: ElementRef;

  @ViewChild('selectLogoFileInput', { static: true })
  selectLogoFileInput: ElementRef;

  @Input()
  label?: string;
  @Input()
  labelClasses?: string;
  @Input()
  readonly?: boolean;
  @Input()
  infoTooltip?: string;
  @Input()
  logoHolder?: LogoHolder;
  @Input()
  logoPath: string;
  @Input()
  logoValidations: ValidationMessage[];

  @Input()
  formDeleteLogoKey?: string;
  @Input()
  formDeleteLogo?: FormControl;
  @Input()
  formLogoContentKey?: string;
  @Input()
  formLogoContent?: FormControl;
  @Input()
  formLogoNameKey?: string;
  @Input()
  formLogoName?: FormControl;

  logoChanged = false;
  group: UntypedFormGroup;
  formDeleteLogoControlName: string;
  formLogoContentControlName: string;
  formLogoNameControlName: string;
  formDeleteLogoControl: AbstractControl;
  formLogoContentControl: AbstractControl;
  formLogoNameControl: AbstractControl;

  constructor(protected fgd: FormGroupDirective) {}

  ngOnInit(): void {
    this.createFormFields();
  }

  isPreviewLogoVisible(): boolean {
    return !!(
      this.group.value.logoContent ||
      (this.logoHolder && this.logoHolder.logoId && !this.logoChanged)
    );
  }

  onDeleteLogo(): void {
    if (this.isPreviewLogoVisible()) {
      this.selectLogoFileInput.nativeElement.value = null;
      this.logoPreviewImage.nativeElement.src = '';
      if (this.logoHolder) this.logoHolder.logoId = null;
      this.formDeleteLogoControl.setValue(true);
      this.formLogoContentControl.setValue(null);
      this.formLogoNameControl.setValue(null);
      this.group.updateValueAndValidity();
      this.logoChanged = true;
    }
  }

  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logoFileSelected(changeFilesEvent: any): void {
    const file = changeFilesEvent.target.files[0];
    const imgNativeElement = this.logoPreviewImage.nativeElement;
    const reader = new FileReader();

    reader.onload = (e) => {
      const imageAsDataURL = e.target.result as string;
      imgNativeElement.src = imageAsDataURL;

      const split = imageAsDataURL.split('base64,');
      const fileAsBase64 = split[1];

      if (this.logoHolder) this.logoHolder.logoId = null;
      this.formDeleteLogoControl.setValue(null);
      this.formLogoContentControl.setValue(fileAsBase64);
      this.formLogoNameControl.setValue(file.name);
      this.group.updateValueAndValidity();
      this.logoChanged = true;
    };
    reader.readAsDataURL(file);
  }

  // TODO: formDeleteLogoKey, formLogoContentKey and formLogoNameKey should be remove after EPIC TRFV2-22133 is finished
  private createFormFields(): void {
    this.group = this.fgd.form;

    this.createDeleteLogoControl();
    this.createLogoContentControl();
    this.createLogoNameControl();
  }

  private createDeleteLogoControl(): void {
    this.formDeleteLogoControlName =
      this.formDeleteLogoKey || getFormControlName(this.formDeleteLogo);
    this.formDeleteLogoControl =
      this.formDeleteLogo || this.group.get(this.formDeleteLogoControlName);
  }

  private createLogoContentControl(): void {
    this.formLogoContentControlName =
      this.formLogoContentKey || getFormControlName(this.formLogoContent);
    this.formLogoContentControl =
      this.formLogoContent || this.group.get(this.formLogoContentControlName);
  }

  private createLogoNameControl(): void {
    this.formLogoNameControlName = this.formLogoNameKey || getFormControlName(this.formLogoName);
    this.formLogoNameControl = this.formLogoName || this.group.get(this.formLogoNameControlName);
  }
}
