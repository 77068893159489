import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, UntypedFormGroup } from '@angular/forms';

import { getFormControlName } from '@demica/core/core';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { NgSelectComponent } from '@ng-select/ng-select';

type ValidateFunction = (term: string) => null | Record<string, unknown>;

@Component({
  selector: 'trf-form-chips-input-row',
  templateUrl: 'form-chips-input-row.component.html',
  styleUrls: ['./form-chips-input-row.component.sass'],
})
export class FormChipsInputRowComponent implements OnInit {
  @Input()
  name?: string;
  @Input()
  control?: FormControl;
  @Input()
  fieldPath?: string;
  @Input()
  label: string;
  @Input()
  labelClasses?: string;
  @Input()
  defaultLabel?: string;
  @Input()
  options: unknown[] = [];
  @Input()
  validations?: ValidationMessage[];
  @Input()
  disabled: boolean;
  @Input()
  helperToolTip?: string;
  @Input()
  clearable = true;
  @Input()
  groupByKey: string = null;
  @Input()
  visible = true;
  @Input()
  previewMode: boolean;

  group: UntypedFormGroup;
  formControlName: string;

  @Input()
  validateFunction: ValidateFunction = () => null;

  constructor(private fgd: FormGroupDirective) {}

  ngOnInit(): void {
    this.group = this.fgd.form;
    this.formControlName = this.name ?? getFormControlName(this.control);
  }

  addTag = (valueToAdd: string): string | boolean => {
    const validationError = this.validateFunction(valueToAdd);
    if (validationError === null) {
      return valueToAdd;
    } else {
      this.getControl().setErrors(validationError);
      this.getControl().markAsTouched();
      return false;
    }
  };

  updateValidationMessages = () => {
    this.getControl().updateValueAndValidity();
  };

  private getControl(): AbstractControl {
    return this.fgd.form.get(this.formControlName);
  }

  keyDownFn(select: NgSelectComponent): (event: KeyboardEvent) => boolean {
    const control = this.getControl() as AbstractControl;
    return (event: KeyboardEvent): boolean => {
      if (event.key === 'Enter') {
        const searchTerm = select.searchTerm;
        const keys = control.value;
        if (searchTerm && !keys.includes(searchTerm)) {
          return true;
        }
        select.searchTerm = '';
        return false;
      }
      this.getControl().updateValueAndValidity();
      return true;
    };
  }
}
