import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import {
  arraysHaveCommonPart,
  byProperty,
  EntityId,
  Environment,
  FilterParameters,
  HasEntityId,
  RATIOS_DSO,
  TransactionFilterResource,
} from '@demica/core/core';

import { TransactionInstanceReference } from '../model/transaction-instance-reference.interface';

type FilterFunction = {
  (data: TransactionFilterResource[]): TransactionFilterResource[];
};

enum ENVIRONMENT_TYPE {
  PRODUCTION = 1,
  NON_PRODUCTION = 2,
}

const ALL_PRODUCTION_ENVIRONMENT_SELECT_ENTRY_VALUE = -1;

@Injectable()
/** A stateful service for communicating filter parameters changes between routed views and containers.
 * Actually used for filters both on Dashboards and Data Uploads.
 * When using this service do note of the scope where this service is provided */
export class FiltersContext {
  loadFilterParametersSubject: Subject<FilterParameters> = new ReplaySubject<FilterParameters>(1);
  allLoadedFilterParametersSubject: Subject<FilterParameters> = new ReplaySubject<FilterParameters>(
    1,
  );
  filters$: Observable<{
    activeFilters: FilterParameters;
    defaultFilters: FilterParameters;
  }> = combineLatest(this.allLoadedFilterParametersSubject, this.loadFilterParametersSubject).pipe(
    map(([defaultFilters, activeFilters]) => ({ defaultFilters, activeFilters })),
  );
  environmentsNamesMap$ = this.loadFilterParametersSubject.pipe(
    map((allFilters) =>
      allFilters.availableEnvironments.reduce(
        (prev, env) => ({ ...prev, [env.entityId]: env }),
        {} as Record<EntityId, Environment>,
      ),
    ),
  );
  selectedEnvironments$ = this.loadFilterParametersSubject.pipe(
    map((filters) => {
      if (filters.environments.length) {
        let environments = filters.environments.map(asEntityId);
        if (environments.includes(ALL_PRODUCTION_ENVIRONMENT_SELECT_ENTRY_VALUE)) {
          environments = removeAllProductionEnvironmentEntry(environments);
          environments = [
            ...environments,
            ...filters.availableEnvironments.filter(isProductionEnvironment).map(asEntityId),
          ];
        }
        return environments;
      } else {
        return filters.availableEnvironments.map(asEntityId);
      }
    }),
  );
  filteredTransactions$: Observable<TransactionFilterResource[]> = this.filters$.pipe(
    map((data) => {
      const filterFunctions: FilterFunction[] = [];
      data.activeFilters.transactions.length && filterFunctions.push(filterByTransactionIds(data));
      data.activeFilters.environments.length && filterFunctions.push(filterByEnvironments(data));
      return filterFunctions.reduce((prev, fn) => fn(prev), data.defaultFilters.transactions);
    }),
  );
  liceTransactionsWithSelectedEnvironments$ = combineLatest(
    this.filteredTransactions$,
    this.selectedEnvironments$,
  ).pipe(
    map(([liceTransactions, selectedEnvironments]) =>
      liceTransactions
        .map((liceTransaction) => ({
          ...liceTransaction,
          environmentIds: liceTransaction.environmentIds
            .filter((env) => selectedEnvironments.includes(env))
            .filter(
              (env) =>
                Array.isArray(liceTransaction.environmentFeatures[env]) &&
                liceTransaction.environmentFeatures[env].includes(RATIOS_DSO),
            ),
        }))
        .filter((transaction) => transaction.environmentIds.length > 0),
    ),
  );

  transactionInstances$: (value: boolean) => Observable<TransactionInstanceReference[]> = (
    filteredByGlobalFilters = true,
  ) =>
    this.filteredTransactions$.pipe(
      withLatestFrom(this.environmentsNamesMap$, this.selectedEnvironments$),
      map(([transactions, environmentNamesMap, selectedEnvironments]) =>
        transactions.flatMap((transaction) =>
          transaction.environmentIds
            .filter((environment) =>
              filteredByGlobalFilters ? selectedEnvironments.includes(environment) : true,
            )
            .map((environment) => ({
              transaction,
              environment: environmentNamesMap[environment],
            })),
        ),
      ),
    );

  getAllFiltersParamsObservable(): Observable<FilterParameters> {
    return new Observable((observer) => {
      this.allLoadedFilterParametersSubject.subscribe((allFilters) => {
        allFilters.environments.sort(byProperty('entityId'));
        observer.next(allFilters);
        observer.complete();
      });
    });
  }
}

function filterByTransactionIds(data: {
  activeFilters: FilterParameters;
  defaultFilters: FilterParameters;
}) {
  return (transactions: TransactionFilterResource[]) =>
    transactions.filter((transaction) =>
      data.activeFilters.transactions.map(asEntityId).includes(transaction.entityId),
    );
}

function filterByEnvironments(data: {
  activeFilters: FilterParameters;
  defaultFilters: FilterParameters;
}) {
  return (transactions: TransactionFilterResource[]) => {
    let environment: EntityId[] = [...data.activeFilters.environments.map(asEntityId)];
    if (environment.includes(ALL_PRODUCTION_ENVIRONMENT_SELECT_ENTRY_VALUE)) {
      environment = removeAllProductionEnvironmentEntry(environment);
      environment = [
        ...environment,
        ...data.defaultFilters.availableEnvironments
          .filter(isProductionEnvironment)
          .map(asEntityId),
      ];
    }
    return transactions.filter((transaction) =>
      arraysHaveCommonPart(transaction.environmentIds, environment),
    );
  };
}

const isProductionEnvironment = (env: Environment) => env.type === ENVIRONMENT_TYPE.PRODUCTION;
const asEntityId = (obj: HasEntityId) => obj.entityId;
const removeAllProductionEnvironmentEntry = (environment: EntityId[]) => {
  const index = environment.indexOf(ALL_PRODUCTION_ENVIRONMENT_SELECT_ENTRY_VALUE);
  environment = [...environment.slice(0, index), ...environment.slice(index + 1)];
  return environment;
};
