import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { apiUrl } from '../../config/api.config';
import { HasEntityId } from '../../interface/has-entity-id.interface';
import { Country } from '../../model/country.interface';
import { DictionaryEntry } from '../../model/dictionary-entry.interface';
import { RestResponse } from '../../model/response.interface';
import { byName } from '../../utils/collection';

@Injectable()
export class CountriesService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  countriesAsSelectOptions(): Observable<DictionaryEntry[]> {
    return this.http.get(`${apiUrl}/resources/countries`).pipe(
      map((r: RestResponse<Country[]>) => r.data),
      map((countries) =>
        countries
          .map((country) => ({
            entityId: country.entityId,
            key: 'COUNTRIES.' + country.entityId,
            name: this.translate.instant('COUNTRIES.' + country.entityId),
            transform: (option: HasEntityId, name: string) => `${name} (${option.entityId})`,
          }))
          .sort(byName()),
      ),
    );
  }
}
