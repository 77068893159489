import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { LoggerService } from '@demica/logger';

import { BroadcastChannelService } from '../broadcast-channel.service';

import { UserAuthContext } from '../../../model/auth/user-auth.context.interface';
import { BroadcastMessageType } from '../../../model/broadcast-message.interface';
import { BroadcastAutowiredClient } from '../broadcast-channel.types';

@Injectable({
  providedIn: 'root',
})
export class AuthUserContextChangeClient implements BroadcastAutowiredClient {
  readonly name = 'AuthUserContextChange';
  readonly userAuthContextChange$: Observable<UserAuthContext>;

  private readonly _userAuthContextChange$ = new Subject<UserAuthContext>();

  constructor(
    private _logger: LoggerService,
    private _broadcastChannelService: BroadcastChannelService,
  ) {
    this.userAuthContextChange$ = this._userAuthContextChange$.asObservable();
  }

  autowire(): void {
    this._logger.addConsoleLog(`Autowiring ${this.name}`);
    this._initBroadcastMessageListener();
  }

  broadcastAuthUserContext(userAuthContext: UserAuthContext): void {
    this._broadcastChannelService.broadcastMessage(
      this._broadcastChannelService.generateMessage(
        BroadcastMessageType.AuthUserContextUpdate,
        userAuthContext,
      ),
    );
  }

  private _initBroadcastMessageListener(): void {
    this._broadcastChannelService
      .getMessagesOfType$<UserAuthContext>(BroadcastMessageType.AuthUserContextUpdate)
      .subscribe((message) => this._userAuthContextChange$.next(message.data));
  }
}
