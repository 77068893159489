<div
  *ngIf="name && input"
  class="validation-error"
>
  <ngp-inline-notification
    *ngIf="validations | firstError: name; else minValidation"
    [id]="name + '_notification'"
    [message]="validations | firstError: name"
  >
  </ngp-inline-notification>

  <ng-template #minValidation>
    <ngp-inline-notification
      *ngIf="hasError(minIntExceededErrorKey); else maxValidation"
      id="min_int_notification"
      [message]="'VALIDATION.MIN_INTEGER_VALUE' | translate"
    >
    </ngp-inline-notification>
  </ng-template>

  <ng-template #maxValidation>
    <ngp-inline-notification
      *ngIf="hasError(maxIntExceededErrorKey); else formatValidation"
      id="max_int_notification"
      [message]="'VALIDATION.MAX_INTEGER_VALUE' | translate"
    >
    </ngp-inline-notification>
  </ng-template>

  <ng-template #formatValidation>
    <ngp-inline-notification
      *ngIf="hasError(invalidFormatErrorKey)"
      [id]="invalidFormatErrorKey"
      [message]="'VALIDATION.' + invalidFormatMessageKey | translate"
    >
    </ngp-inline-notification>
  </ng-template>

  <ng-content></ng-content>
</div>
