<div class="trf-section-header space-between">
  <div class="header-title section-header-tooltip">
    <h3>{{ "ALIASES_MANAGEMENT.HEADER_TITLE" | translate }}</h3>
    <ngp-tooltip-icon
      tooltipKey="ALIASES_MANAGEMENT.TOOLTIP_HEADER_TITLE"
      tooltipTestId="reports-search"
      iconAriaDesc="{{
        'DASHBOARD.TOOLTIP' | translate: { tabTitle: 'ALIASES_MANAGEMENT.HEADER_TITLE' | translate }
      }}"
    ></ngp-tooltip-icon>
  </div>

  <div
    *ngIf="showAddButton"
    class="add-new-button"
  >
    <ngp-button
      classes="tertiary"
      icon="plus"
      key="ALIASES_MANAGEMENT.BUTTON_ADD_NEW"
      (click)="onAddNew()"
      testid="add-new-button"
    ></ngp-button>
  </div>
</div>
<div class="trf-section-content">
  <div [ngClass]="transaction ? 'trf-search-form-two-columns' : 'trf-search-form'">
    <form [formGroup]="form">
      <div class="form-fields-wrapper">
        <trf-form-select-row
          *ngIf="!transaction"
          class="trf-form-field"
          translationPrefix="COLUMN_DEFINITION_TYPES"
          label="ALIASES_MANAGEMENT.TRANSACTION"
          labelClasses="required"
          defaultLabel="ALIASES_MANAGEMENT.FORM_TRANSACTION_DEFAULT_VALUE"
          [validations]="requireValidationsMessages"
          [control]="form.controls.transaction"
          [options]="transactions"
          testid="analysisGroup"
        ></trf-form-select-row>

        <trf-form-select-row
          translationPrefix="COLUMN_DEFINITION_TYPES"
          label="ALIASES_MANAGEMENT.ANALYSIS_GROUP"
          defaultLabel="ALIASES_MANAGEMENT.FORM_ALL_FILTERED_ANALYSIS_GROUPS"
          [ngClass]="!transaction ? 'trf-form-field' : 'trf-form-field-two-columns'"
          [control]="form.controls.analysisGroup"
          [options]="filteredAnalysisGroups"
          testid="analysisGroup"
        ></trf-form-select-row>

        <trf-form-text-row
          label="ALIASES_MANAGEMENT.ANALYSIS_CODE"
          placeholder="ALIASES_MANAGEMENT.ANALYSIS_CODE_PLACEHOLDER"
          [ngClass]="!transaction ? 'trf-form-field' : 'trf-form-field-two-columns'"
          [control]="form.controls.analysisCodeValue"
          [validations]="maxLengthValidation"
          [maxLength]="maxLength"
          data-testid="analysis-code-name"
        ></trf-form-text-row>
      </div>
    </form>
    <div class="controls">
      <ngp-button
        classes="tertiary"
        key="ALIASES_MANAGEMENT.BUTTON_CLEAR"
        (click)="onClear()"
        testid="clear-button"
      ></ngp-button>

      <ngp-button
        classes="primary"
        key="ALIASES_MANAGEMENT.BUTTON_SEARCH"
        (click)="onSearch()"
        testid="search-button"
      ></ngp-button>
    </div>
  </div>
</div>
