import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';

import { Subject } from 'rxjs';

import { CurrencyCode, EntityId, Range } from '@demica/core/core';

import { definitionsToMessages } from '../../forms/validation-messages/message-builder';
import {
  msgMaxDecimalPlacesExceeded,
  msgMaxIntegerPlacesExceeded,
  msgRequired,
} from '../../forms/validation-messages/message-definitions';
import { validateNotEmpty } from '../../forms/validators';
import { RANGE_FORM_PARAMS, RANGE_TYPE, RATE_TYPE } from '../../model/range.constants';
import { rangeValidations } from '../../model/range.interface';

@Component({
  selector: 'trf-ranges-form',
  templateUrl: './ranges-form.component.html',
  styleUrls: ['./ranges-form.component.sass'],
})
export class RangesFormComponent implements OnInit, OnDestroy {
  @Input()
  range: Range;
  @Input()
  versionPreviewMode: boolean;
  @Input()
  previewMode: boolean;
  @Input()
  rangeType: EntityId;
  @Input()
  rateType: EntityId;
  @Input()
  valueLabel: string;
  @Input()
  lowerRangeFormLabel: string;
  @Input()
  upperRangeFormLabel: string;
  @Input()
  numberOfDecimalPlacesForValue: number;
  @Input()
  numberOfDecimalPlacesForRangePoints: number;
  @Input()
  maxValueForRangePoints: number;
  @Input()
  removeTrailingZeros = false;
  @Input()
  currency: CurrencyCode;
  @Input()
  acceptNegativeValues: boolean;
  @Input()
  validateMinMaxValue: boolean;

  @Output()
  saveButtonClick = new EventEmitter<Range>();
  @Output()
  cancelButtonClick = new EventEmitter<void>();

  loading = true;
  submitted = false;

  RANGE_FORM_PARAMS = RANGE_FORM_PARAMS;
  form = this.fb.group({
    [RANGE_FORM_PARAMS.lowerRange]: ['', []],
    [RANGE_FORM_PARAMS.upperRange]: ['', []],
    [RANGE_FORM_PARAMS.value]: ['', []],
  });

  rangeValidation = rangeValidations(this.form, () => this.submitted);
  requiredValidation = definitionsToMessages(
    [msgRequired, msgMaxDecimalPlacesExceeded, msgMaxIntegerPlacesExceeded],
    () => this.form,
    () => this.submitted,
  );

  percentage = {
    lowerRange: true,
    upperRange: true,
    value: true,
  };

  mandatory = {
    lowerRange: true,
    upperRange: false,
    value: true,
  };

  private destroySubject = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.percentage.lowerRange = this.rangeType === RANGE_TYPE.percentage;
    this.percentage.upperRange = this.rangeType === RANGE_TYPE.percentage;
    this.percentage.value = this.rateType !== RATE_TYPE.amount;

    this.mandatory.upperRange = this.rangeType === RANGE_TYPE.percentage;

    this.setupValidators();

    this.form.patchValue(this.range);
    this.form.updateValueAndValidity();

    this.loading = false;
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  onSave() {
    this.submitted = true;
    if (this.form.valid) {
      this.saveButtonClick.emit(this.form.getRawValue() as Range);
    }
  }

  onCancel() {
    this.cancelButtonClick.emit();
  }

  private setupValidators() {
    const upperRangeValidators = [];
    const valueValidators = [];
    if (this.rangeType === RANGE_TYPE.percentage) {
      upperRangeValidators.push(validateNotEmpty, Validators.min(0), Validators.max(100));
    }
    upperRangeValidators.push(this.rangesValidator.bind(this));
    if (this.validateMinMaxValue) {
      if (this.rateType !== RATE_TYPE.amount) {
        valueValidators.push(Validators.min(0), Validators.max(100));
      } else {
        valueValidators.push(Validators.min(0));
      }
    }
    valueValidators.push(validateNotEmpty);
    this.form.get(RANGE_FORM_PARAMS.upperRange).setValidators(upperRangeValidators);
    this.form.get(RANGE_FORM_PARAMS.value).setValidators(valueValidators);
  }

  private rangesValidator(control: AbstractControl) {
    if (!this.form) {
      return null;
    }

    const lowerRange = this.form.get(RANGE_FORM_PARAMS.lowerRange);
    if (!lowerRange) {
      return null;
    }

    const lowerRangeValue = lowerRange.value ? Number(lowerRange.value) : null;
    const upperRangeValue = control.value ? Number(control.value) : null;

    if (
      lowerRangeValue !== null &&
      upperRangeValue !== null &&
      lowerRangeValue >= upperRangeValue
    ) {
      return { 'upper-lower-than-lower': true };
    }

    return null;
  }
}
