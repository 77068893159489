import { EntityId } from '@demica/core/core';

import { DndNestedElement } from '../model/drag-and-drop-nested.model';

const calculateDeepestElement = <T extends DndNestedElement>(
  data: T[],
  previousIndex: number,
): number[] =>
  data.reduce((prev, next) => {
    if (next.children?.length) {
      return [...prev, ...calculateDeepestElement(next.children, previousIndex + 1)];
    }
    return [...prev, previousIndex];
  }, [] as number[]);

type FunctionType = <T extends DndNestedElement>(data: T[]) => number;

export const findDeepestElementLevel: FunctionType = <T extends DndNestedElement>(
  data: T[],
): number => (data?.length ? Math.max(...calculateDeepestElement<T>(data, 0)) : 0);

export const findElementByIdDeep = <T extends DndNestedElement>(
  data: T[],
  elementId: EntityId,
): T | undefined => {
  const baseElement = data.find((el) => el.id === elementId);
  if (baseElement) {
    return baseElement;
  }
  for (const element of data) {
    return findElementByIdDeep<T>(element.children as T[], elementId);
  }
  return undefined;
};

export const removeElementFromTreeObject = <T extends DndNestedElement>(
  data: T[],
  elementId: string,
): T[] =>
  data.reduce((prev, rule) => {
    if (rule.id !== elementId && rule.children.length) {
      return [
        ...prev,
        { ...rule, children: removeElementFromTreeObject(rule.children, elementId) },
      ];
    }
    return rule.id !== elementId ? [...prev, rule] : prev;
  }, [] as T[]);

export const moveElementInArray = <T extends DndNestedElement>(
  array: T[],
  fromIndex: number,
  toIndex: number,
): T[] => {
  const element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);
  return array;
};
