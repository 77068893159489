import { LEADING_ZEROS_REGEX } from '@demica/core/core';

import { encodeRegexChar } from './encode-regex-char';

export function isValidLocalisedNumber(
  value: string,
  thousandsSeparator: string,
  decimalSeparator: string,
): boolean {
  const negativeSign = '-';
  const thousandsSeparatedNumber = `\\d{1,3}(${encodeRegexChar(thousandsSeparator)}\\d{3})*`;
  const rawNumber = `\\d*`;
  const decimalPart = `${encodeRegexChar(decimalSeparator)}\\d+`;

  const regex = new RegExp(
    `^${negativeSign}?(${thousandsSeparatedNumber}|${rawNumber})(${decimalPart})?$`,
  );

  return !!value.replace(LEADING_ZEROS_REGEX, '').trim().match(regex);
}
