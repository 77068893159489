import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { AnalyticsService, LocalisationService, TitleChangerService } from '@demica/core/core';
import { UserActivityAuditService, UserActivityType } from '@demica/logger';
import { setApplicationZone } from '@demica/shared';

import { AppBootstrapService } from './app.bootstrap.service';

import { NgbConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'trf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = true;
  loadingRoute = false;

  private _destroySubject$ = new Subject<void>();

  constructor(
    private bootstrapService: AppBootstrapService,
    private titleChanger: TitleChangerService,
    private router: Router,
    private userAudit: UserActivityAuditService,
    private ngbConfig: NgbConfig,
    private analytics: AnalyticsService,
    private localisationService: LocalisationService,
    zone: NgZone,
  ) {
    this.ngbConfig.animation = false;
    setApplicationZone(zone);
  }

  ngOnInit(): void {
    this.bootstrapService
      .initialize()
      .pipe(
        first(),
        finalize(() => (this.loading = false)),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(takeUntil(this._destroySubject$)).subscribe(this._handleRouteChange);
    this.localisationService.locale
      .pipe(takeUntil(this._destroySubject$))
      .subscribe(() => this.titleChanger.onLocaleChange());
  }

  ngOnDestroy(): void {
    this._destroySubject$.next();
    this._destroySubject$.complete();
  }

  private _handleRouteChange = (event: Event): void => {
    if (event instanceof NavigationStart) {
      this.userAudit.addActivity({
        activityType: UserActivityType.CHANGE_ROUTE,
        data: {
          routeUrl: event.url,
        },
      });
      this.loadingRoute = true;
    } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
      if (event instanceof NavigationEnd) {
        this.titleChanger.onNavigationChange(event);
        this.analytics.captureNavigationEvent(event);
      }
      this.loadingRoute = false;
    }
  };
}
