<ng-container [formGroup]="group">
  <div class="timepickers-container">
    <trf-form-select-row
      [control]="startControl"
      [label]="labelStart"
      [defaultLabel]="defaultLabelStart"
      [labelClasses]="labelClasses"
      [translationPrefix]="translationPrefix"
      [options]="options"
      [disabled]="disabled"
      [clearable]="clearable"
      [searchable]="searchable"
      [validations]="validationsStart"
      [sort]="false"
    ></trf-form-select-row>

    <trf-form-select-row
      [control]="endControl"
      [label]="labelEnd"
      [defaultLabel]="defaultLabelEnd"
      [labelClasses]="labelClasses"
      [translationPrefix]="translationPrefix"
      [options]="options"
      [disabled]="disabled"
      [clearable]="clearable"
      [searchable]="searchable"
      [validations]="validationsEnd"
      [sort]="false"
    ></trf-form-select-row>
  </div>
  <ngp-contextual-notifications
    id="timepicker_notifications"
    [notifications]="notifications"
  ></ngp-contextual-notifications>
</ng-container>
