import { inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';

import {
  getLocalStorage,
  setHistoryState,
  useConfirmDiscardOfUnsavedChanges,
  UserService,
} from '@demica/core/core';
import { isE2E } from '@demica/utils';

export interface Component {
  form?: UntypedFormGroup;
  graphModified?: boolean;
}

const disableUnsavedGuard = getLocalStorage('disableUnsavedGuard');

export const unsavedChangesGuard: CanDeactivateFn<Component> = (
  component: Component,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot,
): boolean => {
  const userService = inject(UserService);

  return canDeactivate();

  function canDeactivate() {
    const _confirmDiscardOfUnsavedChanges = useConfirmDiscardOfUnsavedChanges();

    if (!component || !userService.isLogged()) return true;

    const hasGraphModified = component.graphModified != null ? component.graphModified : false;
    const hasFormModified = component.form != null ? component.form.dirty : false;

    if ((!hasGraphModified && !hasFormModified) || disableUnsavedGuard || isE2E()) return true;

    if (hasFormModified) component.form.markAsPristine();

    setHistoryState(nextState.url);
    const result = _confirmDiscardOfUnsavedChanges();
    if (result) component.graphModified = false;
    return result;
  }
};
