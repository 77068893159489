import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { asyncScheduler, of, ReplaySubject, timer } from 'rxjs';
import { catchError, subscribeOn, switchMap, tap } from 'rxjs/operators';

import { enterZone, leaveZone } from '@demica/utils';

import { FeatureToggleService } from './feature-toggle.service';

import { FundingAlertsCount } from '../_domain/funder/model/funding-alerts-count.interface';
import { apiUrl } from '../config/api.config';
import { FUNDING_ALERTS } from '../config/feature-toggle-consts';

@Injectable({ providedIn: 'root' })
export class FundingAlertsCounterService {
  alertsCount$ = new ReplaySubject<FundingAlertsCount>(1);

  constructor(
    private http: HttpClient,
    private ngZone: NgZone,
    private toggleService: FeatureToggleService,
  ) {
    this.initialize();
  }

  private initialize() {
    if (this.fundingAlertDisabled()) return;

    timer(500, 60 * 1000, leaveZone(this.ngZone, asyncScheduler))
      .pipe(
        subscribeOn(enterZone(this.ngZone, asyncScheduler)),
        switchMap(() => this.fetchAlertsCount()),
        tap((data) => this.alertsCount$.next(data)),
      )
      .subscribe();
  }

  private fundingAlertDisabled() {
    return !this.toggleService.hasEnabled(FUNDING_ALERTS);
  }

  private fetchAlertsCount() {
    return this.http
      .get<FundingAlertsCount>(`${apiUrl}/resources/dashboards/funding-alerts-count`)
      .pipe(
        catchError(() =>
          of({ performing: 0, watchListWarning: 0, watchList: 0, atRiskWarning: 0, atRisk: 0 }),
        ),
      );
  }
}
