import { Injectable, OnDestroy } from '@angular/core';

import { ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { LoggerService, LogArea } from '@demica/logger';
import { SystemUserIdService } from '@demica/user-identification';

import { AuthService } from './auth/auth.service';
import { TokenStoreService } from './auth/token-store.service';
import { UserAccountResourceService } from './rest/user-account-resource.service';
import { UserPreferences } from './user-preferences/user-preferences.service';

import { User } from '../model/user';
import { UserResponse } from '../model/user.interface';
import { LOGOUT_REASONS } from './auth/logout-redirect/logout-reasons.enum';

@Injectable()
export class UserService implements OnDestroy {
  /** This observable will emit when auth token is refreshed! Use first() operator if you only want the initial result */
  public currentUser: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _user: User;
  private _destroyed$ = new Subject<void>();

  constructor(
    private _userAccountResourceService: UserAccountResourceService,
    private _auth: AuthService,
    private _userPreferences: UserPreferences,
    private _systemUserIdService: SystemUserIdService,
    private _tokenStoreService: TokenStoreService,
    private _logger: LoggerService,
  ) {}

  initialize(): void {
    this._initializeTokenChangedObserver();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  getUserSync(): User {
    return this._user;
  }

  isLogged(): boolean {
    return !!this.getUserSync();
  }

  private _initializeTokenChangedObserver() {
    this._tokenStoreService.tokenChanged$
      .pipe(
        tap((token) => !token && this._setCurrentUser(null)),
        filter(Boolean),
        takeUntil(this._destroyed$),
      )
      .subscribe(() => this.updateUserData());
  }

  updateUserData(): void {
    this._userAccountResourceService.getMyAccountDetails().subscribe(
      (userResponse) => this._processUserResponse(userResponse),
      (e) => console.error(e),
    );
  }

  private _processUserResponse(userResponse: UserResponse): void {
    const user = this._createUserFromUserResponse(userResponse);

    if (this._checkIfUserShouldSetPasswordFirst(user)) return;

    this._setCurrentUser(user);

    this._propagateUserPreferencesFromUserResponse(userResponse);
  }

  private _createUserFromUserResponse(userResponse: UserResponse): User {
    return new User(
      userResponse.accountId,
      userResponse.fullName,
      userResponse.disabledPasswordAuthentication,
      userResponse.fullAccessRegions,
      userResponse.internal,
      userResponse.email,
      userResponse.authorities,
      userResponse.organizationUnitContext,
    );
  }

  private _checkIfUserShouldSetPasswordFirst(user: User): boolean {
    if (user.hasAuthority('SET_PASSWORD')) {
      this._logger.addInstantLog({ message: 'No set password authorities', area: LogArea.AUTH });
      this._auth.signOut(LOGOUT_REASONS.PASSWORD_EXPIRED);

      return true;
    }

    return false;
  }

  private _setCurrentUser(user: User | null): void {
    this._propagateUserId(user);

    this._logger.addInstantLog({ message: 'Storing user info', area: LogArea.AUTH });

    // store user data locally
    this._user = user;

    // notify about new current User state
    this._notifyCurrentUser();
  }

  private _propagateUserId(user: User | null): void {
    if (user) {
      this._systemUserIdService.setId(user.accountId);
    }
  }

  private _propagateUserPreferencesFromUserResponse(userResponse: UserResponse): void {
    this._userPreferences.changeCurrency(userResponse.currency);
    this._userPreferences.changeLocale(userResponse.locale);
  }

  private _notifyCurrentUser(): void {
    this.currentUser.next(this._user);
  }
}
