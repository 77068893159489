import { NgModule, ModuleWithProviders } from '@angular/core';

/** This module is being replaced on build using fileReplacements feature */
@NgModule({})
export class DevToolsModule {
  static forRoot(): ModuleWithProviders<DevToolsModule> {
    return {
      ngModule: DevToolsModule,
      providers: [],
    };
  }
}
