import { getCrypto } from '../window/window';

export function generateUUID(): string {
  const crypto = getCrypto();
  const bytes = new Uint16Array(8);

  crypto.getRandomValues(bytes);

  return (
    covertTo4Digits(bytes[0]) +
    covertTo4Digits(bytes[1]) +
    '-' +
    covertTo4Digits(bytes[2]) +
    '-' +
    covertTo4Digits(bytes[3]) +
    '-' +
    covertTo4Digits(bytes[4]) +
    '-' +
    covertTo4Digits(bytes[5]) +
    covertTo4Digits(bytes[6]) +
    covertTo4Digits(bytes[7])
  );
}

function covertTo4Digits(num: number): string {
  let ret: string = num.toString(16);

  while (ret.length < 4) {
    ret = '0' + ret;
  }

  return ret;
}
