import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'ngp-custom-warning-notification',
  templateUrl: './custom-warning-notification.component.html',
  styleUrls: [
    '../inline-notification/inline-notification.component.sass',
    './custom-warning-notification.component.sass',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomWarningNotificationComponent {
  @Input()
  message = '';

  @Input() set id(value: string) {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'id', value);
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
}
