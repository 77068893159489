import { EntityId } from '../interface/has-entity-id.interface';

/**
 * Recursively copy an array and objects inside it (deep clone)
 * @param array Array to be copied
 * @param childrenProperty The name of a property (array) that holds the children.
 * @example
 *     const nodes = [ { nodes: [ { foo: {} } ] } ]
 *     const clone = recursiveCopy(nodes)
 *     nodes[0].nodes === clone[0].nodes // -> false
 *     nodes[0].nodes[0].foo === clone[0].nodes[0].foo // -> false
 *
 */
export function recursiveCopy<T>(array: T[], childrenProperty: string | keyof T): T[] {
  return array.map((obj) => {
    if (obj[childrenProperty as keyof T])
      return {
        ...obj,
        [childrenProperty]: recursiveCopy(
          obj[childrenProperty as keyof T] as T[],
          childrenProperty,
        ),
      };
    else return { ...obj };
  });
}

export function arraysHaveCommonPart<T>(array: T[], array2: T[]): boolean {
  return array.some((arrayItem) => array2.includes(arrayItem));
}

export function groupByEqualsObjects<T>(
  collection: T[],
  hashFunction: (elem: T) => string,
): Array<Array<T>> {
  const groupedDuplicates: { [hash: string]: T[] } = collection.reduce((prev, current) => {
    const hash = hashFunction(current);
    prev[hash] = prev[hash] ? [...prev[hash], current] : [current];
    return prev;
  }, {} as { [hash: string]: T[] });
  return Object.values(groupedDuplicates);
}

export function getLastElement<T>(array: Array<T>) {
  return Array.isArray(array) && array.length ? array[array.length - 1] : undefined;
}

export function findInArrayById<T extends { id?: EntityId }>(
  data: T[],
  id: EntityId,
): T | undefined {
  return data.find((x) => x?.id === id);
}

export const onlyUnique = <T>(value: T, i: number, array: T[]): boolean => {
  return array.indexOf(value) === i;
};

export const onlyUniqueByKey =
  <T extends Record<K, PropertyKey>, K extends keyof T>(key: keyof T) =>
  (item: T, i: number, arr: T[]): boolean => {
    return arr.findIndex((t) => t[key] === item[key]) === i;
  };

export const onlyUniqueById = <T extends { id: EntityId }>(
  item: T,
  i: number,
  arr: T[],
): boolean => {
  return onlyUniqueByKey('id')(item, i, arr);
};

// for primitives
export const arrToString = <T>(arr: T[]): string => {
  return arr.join(', ');
};

/**
 *
 * @param array
 * @param newItem
 * @param index
 * @returns new Array
 */
export function replaceAtIndex<T = unknown>(array: T[], newItem: T, index: number): T[] {
  return [...array.slice(0, index), newItem, ...array.slice(index + 1)];
}
