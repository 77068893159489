import { FormatWidth, getLocaleDateFormat } from '@angular/common';

import * as moment from 'moment';

export function currentIsoDate(): string {
  return moment().format('YYYY-MM-DD');
}

export function getCurrentDate(): Date {
  return moment().utc(true).toDate();
}

export function currentIsoDateMinusDays(days: number): string {
  return moment().subtract(days, 'days').format('YYYY-MM-DD');
}

export function currentIsoDateMinusWeeks(weeks: number): string {
  return moment().subtract(weeks, 'weeks').format('YYYY-MM-DD');
}

export function currentIsoDateMinusMonths(months: number): string {
  return moment().subtract(months, 'months').format('YYYY-MM-DD');
}

export function dateToISOString(date: string): string {
  return moment(date).format('YYYY-MM-DD');
}

export function isDateBefore(
  date: string | Date | moment.Moment,
  dateBefore: string | Date | moment.Moment,
): boolean {
  return moment(date).isBefore(dateBefore);
}

export function isDateAfter(
  date: string | Date | moment.Moment,
  afterDate: string | Date | moment.Moment,
): boolean {
  return !isDateBefore(date, afterDate);
}

export function setDateFormatForExport(locale: string): string {
  return getLocaleDateFormat(locale, FormatWidth.Short);
}

/**
 * Return ISO8601 date-time
 * @param date - Date object, if not provided return current datetime
 */
export function dateToISO8601(date?: Date | moment.Moment): string {
  return moment(date ?? new Date()).toISOString(true);
}

export function currentDateAsUTC(): Date {
  const date = new Date();

  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

/**
 * Return end of day of current date or provided date in local time
 * @param date
 */
export function endOfDay(date?: Date): Date {
  const dateObject = date ?? new Date();

  return new Date(
    dateObject.getFullYear(),
    dateObject.getMonth(),
    dateObject.getDate(),
    23,
    59,
    59,
  );
}

export function beginningOfMonth(date: Date): moment.Moment {
  return moment(date).startOf('month');
}

export function momentFromString(
  stringToParse: string,
  format: string,
  strict = true,
): moment.Moment {
  return moment(stringToParse, format, strict);
}

/**
 * Function return date or date time format in format that is accepted as moment parser argument
 * @param format
 * @param datePattern
 */
export function dateFormatToMomentCompatibleFormat(format: string, datePattern: string): string {
  return format.replace(datePattern, datePattern.toUpperCase());
}

export function nativeDateFromDateTimeString(
  stringToParse: string,
  dateTimeFormat: string,
  dateFormat: string,
): Date | null {
  const date = momentFromString(
    stringToParse,
    dateFormatToMomentCompatibleFormat(dateTimeFormat, dateFormat),
  );

  return date?.isValid() ? date.toDate() : null;
}

/**
 * Function which return current Date which is supported by testing within fakeAsync
 * https://github.com/angular/angular/issues/8678#issuecomment-361885998
 */
export function newDate(): Date {
  return new Date(Date.now());
}
