import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { asyncScheduler, Observable, ReplaySubject, timer } from 'rxjs';
import { flatMap, map, subscribeOn, switchMap, tap, toArray } from 'rxjs/operators';

import { enterZone, leaveZone } from '@demica/utils';

import { DashboardFiltersResourceService } from './rest/dashboard-filters-resource.service';

import { EntityId } from '../interface/has-entity-id.interface';
import { InputFileStatus, MissingFileSortFlag } from '../interface/input-file-status.interface';
import { FilterIds } from '../model/filter-ids.interface';
import { RestResponse } from '../model/response.interface';
import { SkipMissingFileRequest } from '../model/skip-missing-file-request.model';
import { encodeEndpoint } from '../security/encode-url';
import { SybasePage } from './paging/sybase/sybase-page-rows.interface';
import { toData } from './rest/response-mapping';

interface Filters {
  filtersParams: FilterIds;
}

@Injectable({ providedIn: 'root' })
export class InputFileStatusService {
  inputFileStatus$ = new ReplaySubject<InputFileStatus[]>(1);
  allInputFilesStatus$ = new ReplaySubject<InputFileStatus[]>(1);

  filters: Filters = { filtersParams: null };

  constructor(
    private http: HttpClient,
    private dashboardFiltersResourceService: DashboardFiltersResourceService,
    private ngZone: NgZone,
  ) {
    this.initialize();
  }

  private initialize() {
    const second = 1000;

    timer(second, 60 * second, leaveZone(this.ngZone, asyncScheduler))
      .pipe(
        subscribeOn(enterZone(this.ngZone, asyncScheduler)),
        switchMap(() => this.prepareFilterForAllEnv()),
      )
      .subscribe();
  }

  public prepareFilterForAllEnv(): Observable<InputFileStatus[]> {
    const createFilter = (environments: EntityId[]) => ({
      filtersParams: {
        clientIds: [] as EntityId[],
        environmentIds: environments,
        opcoIds: [] as EntityId[],
        transactionIds: [] as EntityId[],
      },
      keysetPageRequest: {
        lastRow: [] as unknown[],
        sort: {
          column: 'processingDate',
          direction: 'DESC',
        },
        limit: 999999,
      },
    });

    const filterInputFileStatus = (inputFileStatus: InputFileStatus) => inputFileStatus.canSkip;

    return this.dashboardFiltersResourceService.getAvailableFilters().pipe(
      flatMap((dashboardFilters) => dashboardFilters.environments),
      map((environment) => environment.entityId),
      toArray(),
      map(createFilter),
      tap((filters) => (this.filters = filters)),
      switchMap((filters) => this.getInputFilesStatus(filters)),
      map((result) => {
        // It should be splitted into `tap` and `map` operator but because of
        // issue https://github.com/ReactiveX/rxjs/issues/4221 its better to
        // use map for both operation instead of type casting
        this.setAllInputFilesStatus(result.rows);
        return result.rows;
      }),
      switchMap(() => this.allInputFilesStatus$),
      tap((allInputFileStatus) =>
        this.ngZone.run(() => {
          this.inputFileStatus$.next(allInputFileStatus.filter(filterInputFileStatus));
        }),
      ),
    );
  }

  private setAllInputFilesStatus(inputFilesStatus: InputFileStatus[]): void {
    this.allInputFilesStatus$.next(
      inputFilesStatus
        .map((value) => {
          if (!value.present && value.canSkip) value.sortFlag = MissingFileSortFlag.TO_SKIP;
          else if (!value.present && !value.canSkip) value.sortFlag = MissingFileSortFlag.PROCESSED;
          else value.sortFlag = MissingFileSortFlag.PRESENT;
          return value;
        })
        .sort((a, b) => (a.sortFlag > b.sortFlag ? 1 : -1)),
    );
  }

  getInputFilesStatus(filterParameters: Filters): Observable<SybasePage<InputFileStatus[]>> {
    const url = encodeEndpoint('resources/dashboards/missing-files-status');
    return this.http
      .post<RestResponse<SybasePage<InputFileStatus[]>>>(url, filterParameters)
      .pipe(map(toData));
  }

  putSkipMissingFile(skipMissingFileRequest: SkipMissingFileRequest): Observable<void> {
    const url = encodeEndpoint('resources/dashboards/skip-missing-file');
    return this.http.put<void>(url, skipMissingFileRequest);
  }
}
