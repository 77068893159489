import { EntityId } from '../interface/has-entity-id.interface';

type HasId = { id: EntityId };

/*
 * Generate Hash Map of collection by 'id'. Should be used to optimize nested iteration on collections
 * @param {array} collection - Collection to create HashMap from.
 * @example
 * getHashMapById([ { id: 1, name: 'item1' }, { id: 2, name: 'item2' } ])
 * result:
 * { 1: { id: 1, name: 'item1' }, 2: { id: 2, name: 'item2' } }
 * @returns Map with Record<id, collectionItem>
 */
export function getHashMapById<T extends { id: EntityId }>(collection: T[]): HashMapById<T> {
  return getHashMapByKey(collection, 'id');
}

type HashMapByKey<T extends Record<K, T[K]>, K extends keyof T> = Record<T[K], T>;

type HashMapById<T extends HasId> = Record<T['id'], T>;

/*
 * Generate Hash Map of collection by specified key. Should be used to optimize nested iteration on collections
 * @param {array} collection - Collection to create HashMap from.
 * @param {PropertyKey} key - Key from collection item, which can be used as key in resulted Hash Map
 * @example
 * getHashMapByKey([ { id: 1, name: 'item1' }, { id: 2, name: 'item2' } ], 'name')
 * result:
 * { 'item1': { id: 1, name: 'item1' }, 'item2': { id: 2, name: 'item2' } }
 * @returns Map with Record<id, collectionItem>
 */
export function getHashMapByKey<T extends Record<K, PropertyKey>, K extends keyof T & PropertyKey>(
  collection: T[],
  key: K,
): HashMapByKey<T, K> {
  return collection.reduce((acc, curr) => {
    return { ...acc, [curr[key]]: curr };
  }, {} as HashMapByKey<T, K>);
}
