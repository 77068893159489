import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureToggleService } from '@demica/core/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifHasFeatureToggles], [ifHasFeatureToggle]',
})
export class IfHasFeatureToggleDirective implements OnInit {
  @Input()
  ifHasFeatureToggles?: string[];

  @Input()
  ifHasFeatureToggle?: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit(): void {
    if (!this.templateRef) return;

    const requestedFeatureTogglesGroup = this.ifHasFeatureToggle
      ? [this.ifHasFeatureToggle]
      : this.ifHasFeatureToggles;

    if (this.ifHasFeatureToggles === undefined && this.ifHasFeatureToggle === undefined) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.featureToggleService.hasGroupEnabled(requestedFeatureTogglesGroup)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
