<trf-points-form
  *ngIf="formVisible"
  [points]="points"
  [pointToEdit]="pointToEdit"
  [pointLabel]="pointLabel"
  [pointPlaceholder]="pointPlaceholder"
  [numberOfDecimalPlacesForX]="numberOfDecimalPlacesForX"
  [numberOfDecimalPlacesForValue]="numberOfDecimalPlacesForValue"
  (editComplete)="onEditComplete($event)"
></trf-points-form>
<trf-points-list
  [points]="points"
  [pointLabel]="pointLabel"
  [numberOfDecimalPlacesForValue]="numberOfDecimalPlacesForValue"
  (editClick)="onEditClick($event)"
  (deleteClick)="onDeleteClick($event)"
></trf-points-list>
