import {
  CHART_FUNDING_FEES_AND_CHARGES_SRF,
  CHART_FUNDING_FEES_AND_CHARGES_TLF,
  CHART_FUNDING_FUNDING_SRF,
  CHART_FUNDING_INELIGIBLE_SRF,
  CHART_FUNDING_INELIGIBLE_TLF,
  CHART_FUNDING_PERFORMANCE_SRF,
  CHART_FUNDING_PERFORMANCE_TLF,
  CHART_FUNDING_RESERVE_SRF,
} from '@demica/core/core';

export const FUNDING_STRUCTURED_RECEIVABLE_FINANCE_FEATURE_TOGGLE_GROUP_SECTION1 = [
  CHART_FUNDING_PERFORMANCE_SRF,
  CHART_FUNDING_INELIGIBLE_SRF,
  CHART_FUNDING_RESERVE_SRF,
  CHART_FUNDING_FUNDING_SRF,
  CHART_FUNDING_FEES_AND_CHARGES_SRF,
];

export const FUNDING_RECEIVABLE_DISCOUNTING_FEATURE_TOGGLE_GROUP_SECTION1 = [
  CHART_FUNDING_PERFORMANCE_TLF,
  CHART_FUNDING_INELIGIBLE_TLF,
  CHART_FUNDING_FEES_AND_CHARGES_TLF,
];

export const FUNDING_STRUCTURED_RECEIVABLE_FINANCE_FEATURE_TOGGLE_GROUP = [
  ...FUNDING_STRUCTURED_RECEIVABLE_FINANCE_FEATURE_TOGGLE_GROUP_SECTION1,
];
