import { DatePipe } from '@angular/common';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, takeWhile, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import {
  ApiVersionNumber,
  NotificationService,
  PageableExportParamsType,
  PostResponse,
  RestResponse,
  RestResponseWithHeaders,
} from '@demica/core/core';

import { TraceableExportResourceService } from '../../service/tracable-export-resource.service';
import { ExportStatusService } from './model/export-status-service';

import { PostExportResponse, PostExportResponseWithHeaders } from './model/export-modal.model';
import { ExportFileInfo, ExportStatusResponse } from './model/export-status-response.interface';
import { ExportStatusStage } from './model/export-status-stage';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const WARNING_CODE = 'NOTIFICATION.ERROR_CODE.EXPORT_INCOMPLETE_DATA';
const ERROR_CODE = 'NOTIFICATION.ERROR_CODE.EXPORT_DATA_FAILED';

@Component({
  selector: 'trf-modal-export',
  templateUrl: 'modal-export.component.html',
  styleUrls: ['./modal-export.component.sass'],
})
export class ModalExportComponent implements OnInit, OnDestroy {
  @Input()
  url: string;

  @Input()
  exportParams: PageableExportParamsType;

  @Input()
  nameKey: string;

  @Input()
  contextService: ExportStatusService;

  @Input()
  version: ApiVersionNumber;

  fileInformation: ExportFileInfo;
  additionalParams: string[];
  loading = true;
  downloading = false;
  progressMessage: string;
  canDownload = false;

  fileStatusSubject$: BehaviorSubject<ExportStatusStage> = new BehaviorSubject<ExportStatusStage>(
    null,
  );

  readonly TRANSLATION_HEADER = 'Translation-Params';

  private _subscriptions = new Subscription();

  private _errorWhenGenerating: ExportStatusStage[] = [
    ExportStatusStage.FINISHED_WITH_ERROR,
    ExportStatusStage.FINISHED_WITH_SUCCESS,
    ExportStatusStage.DOWNLOADING_FILE,
  ];
  private _pingMultiplier = 1;
  private _requestsPerInterval = 10;

  constructor(
    private _modal: NgbActiveModal,
    private _traceableExportResourceService: TraceableExportResourceService,
    private _translateService: TranslateService,
    private _notificationService: NotificationService,
    private _datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this._traceableExportResourceService.contextStatusService = this.contextService;
    this._requestExportFile();
  }

  // step1: request start of generating export file
  // see: _requestExportFile()
  // step2: checking by file id if it's generated successfully
  // see: _startCheckingStatus()
  // step3: download file
  onDownload(): void {
    if (!this.canDownload && this.fileStatusSubject$.getValue() !== ExportStatusStage.FILE_READY) {
      return;
    }
    this.canDownload = false;
    this.downloading = true;
    this._setUpProgressMessage('0');

    this._traceableExportResourceService
      .downloadExportedData$(this.fileInformation.exportId)
      .pipe(
        tap(() => this.fileStatusSubject$.next(ExportStatusStage.DOWNLOADING_FILE)),
        catchError(() => {
          this.onCancel();
          throw new Error(`Error downloading the export file`);
        }),
      )
      .subscribe((response: { limit: number; data: Blob }) => {
        this._setUpProgressMessage(String(response.data.size - response.limit));
        if (response.limit) {
          this._notificationService.warning(WARNING_CODE, { limit: response.limit });
        }

        this._traceableExportResourceService.saveFile(
          response.data,
          this.fileInformation.exportFormat,
          this.nameKey,
          this.additionalParams,
        );

        this._onSuccessfulDownload();
      });
  }

  onCancel(): void {
    this._modal.close();
  }

  ngOnDestroy(): void {
    // Dispose all subscriptions
    this._subscriptions.unsubscribe();
  }

  // step1: request start of generating export file
  private _requestExportFile(): void {
    this._subscriptions.add(
      this._traceableExportResourceService
        .requestExportFile$(this.url, this.version, this.exportParams)
        .pipe(
          tap((fileInfo) => this._processExportResponse(fileInfo)),
          catchError(() => {
            this.onCancel();
            throw new Error(`Error requesting file export`);
          }),
        )
        .subscribe(),
    );
  }

  private _processExportResponse(
    fileInfo: HttpResponse<RestResponseWithHeaders<PostResponse> | PostExportResponseWithHeaders>,
  ): void {
    this.additionalParams = this._convertToTranslationParams(fileInfo.headers);

    const exportId =
      this.version === ApiVersionNumber.V1
        ? (fileInfo.body as RestResponseWithHeaders<PostResponse>).data.entityId
        : (fileInfo.body as PostExportResponse).exportId;

    this.fileInformation = {
      exportId,
      exportFormat: this.exportParams.exportParams.exportFormat,
    };

    this.fileStatusSubject$.next(ExportStatusStage.GENERATING_FILE);

    this._startCheckingStatus();
  }

  // step2: checking by file id if it's generated successfully
  private _startCheckingStatus(): void {
    if (this.fileInformation.exportId === null) this._onError(`Missing exportId`);

    this._pingMultiplier = 1;

    this._subscriptions.add(
      this.fileStatusSubject$
        .pipe(
          takeWhile((status) => status !== ExportStatusStage.FILE_READY),
          switchMap((status) => this._traceExportStatus$(status)),
        )
        .subscribe((data) => {
          if (data.data.stage === ExportStatusStage.FILE_READY) {
            this.canDownload = true;
            setTimeout(() => this.onDownload(), 100);
          }
        }),
    );
  }

  private _traceExportStatus$(
    status: ExportStatusStage,
  ): Observable<RestResponse<ExportStatusResponse>> {
    return this._traceableExportResourceService
      .schedulePings$(
        this._pingMultiplier,
        this.fileInformation.exportId,
        this._requestsPerInterval,
      )
      .pipe(
        tap((stage) => this._processPingRequest(stage.data)),
        catchError(() => {
          this.onCancel();
          throw new Error(`Unexpected error checking export file status`);
        }),
        finalize(() => {
          if (status === ExportStatusStage.GENERATING_FILE) {
            // increase interval, if the file is not ready for download, every 10 API requests (verified inside schedulePings$)
            this._increaseInterval();
            // then push the GENERATING_FILE status to the Subject in order to schedule next 10 API requests with longer interval
            setTimeout(() => {
              this.fileStatusSubject$.next(status);
            }, 100);
          }
        }),
      );
  }

  private _processPingRequest(stage: ExportStatusResponse): void {
    this._setUpProgressMessage(stage.processedLines);

    if (stage.stage === ExportStatusStage.FILE_READY) {
      this.fileStatusSubject$.next(ExportStatusStage.FILE_READY);
      this.loading = false;
    }

    if (this._errorWhenGenerating.includes(stage.stage)) {
      this._notificationService.error(ERROR_CODE);
      this._onError(`Something went wrong while generating the file`);
    }
  }

  private _setUpProgressMessage(progress: string): void {
    if (!progress) return;

    this.progressMessage = this._translateService.instant('EXPORT_MODAL.PROGRESS', {
      message: progress,
    });
  }

  private _increaseInterval() {
    if (this._pingMultiplier === 1) {
      this._pingMultiplier = 5;
      return;
    }

    if (this._pingMultiplier <= 15) {
      this._pingMultiplier += 5;
      return;
    }

    this._pingMultiplier = 30;
  }

  private _onError(message: string): void {
    this.onCancel();
    throw new Error(message);
  }

  private _onSuccessfulDownload(): void {
    this.fileStatusSubject$.next(ExportStatusStage.FINISHED_WITH_SUCCESS);
    this.downloading = false;
    this.onCancel();
  }

  private _convertToTranslationParams(headers: HttpHeaders): string[] {
    if (headers?.get(this.TRANSLATION_HEADER)) {
      return headers.get(this.TRANSLATION_HEADER).split(',');
    } else {
      return [this._datePipe.transform(Date.now(), 'yyyyMMdd_HHmmss')];
    }
  }
}
