import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RestResponseWithMetadata, encodeParams } from '@demica/resources/common';

import { EntityReference } from '../../interface/entity-reference.interface';
import { EntityId, HasEntityId } from '../../interface/has-entity-id.interface';
import { AuthoritiesGroup } from '../../model/authorities-group.interface';
import { createPaginationParams, defaultResponse, PageRequest } from '../../model/pageable-data';
import { PageRestResponse, RestResponse } from '../../model/response.interface';
import { AvailabilityResponse } from '../../model/rest-response/availability-response';
import { SystemMetadata } from '../../model/system-metadata.interface';
import { UserAccount } from '../../model/user-account.interface';
import { UserRoleSearchParams } from '../../model/user-role-search.params';
import { UserRole } from '../../model/user-role.interface';
import { UserSearchParams } from '../../model/user-search.params';
import { UserResponse } from '../../model/user.interface';
import { encodeEndpoint } from '../../security/encode-url';
import { toData, toPageableData } from './response-mapping';

@Injectable()
export class UserAccountResourceService {
  constructor(private http: HttpClient) {}

  getMyAccountDetails(): Observable<UserResponse> {
    const url = encodeEndpoint('resources/user-accounts/my-account/login-details');
    return this.http.get<RestResponse<UserResponse>>(url).pipe(map(toData));
  }

  putMyAccountCurrency(currencyCode: string): Observable<unknown> {
    const data = { currencyCode };
    return this.http.put(encodeEndpoint('resources/user-accounts/my-account/currency'), data);
  }

  putMyAccountLocale(locale: string): Observable<unknown> {
    const data = { locale };
    return this.http.put(encodeEndpoint('resources/user-accounts/my-account/locale'), data);
  }

  getUserAccounts(): Observable<UserAccount[]> {
    const params = encodeParams({ size: 1000 });
    const url = encodeEndpoint('resources/user-accounts');
    return this.http.get<RestResponse<UserAccount[]>>(url, { params }).pipe(map(toData));
  }

  getUserAccount(id: EntityId): Observable<UserAccount> {
    const url = encodeEndpoint('resources/user-accounts/{}', id);
    return this.http.get<RestResponse<UserAccount>>(url).pipe(map(toData));
  }

  getUserRoles(): Observable<UserRole[]> {
    const params = encodeParams({ size: 1000 });
    const url = encodeEndpoint('resources/user-roles/search');
    return this.http.post<RestResponse<UserRole[]>>(url, {}, { params }).pipe(map(toData));
  }

  getMetadatas(): Observable<SystemMetadata[]> {
    const url = encodeEndpoint('resources/user-accounts/metadata-configuration');
    return this.http.get<RestResponse<SystemMetadata[]>>(url).pipe(map(toData));
  }

  postUser(user: unknown): Observable<unknown> {
    return this.http.post(encodeEndpoint('resources/user-accounts'), user);
  }

  putUser(id: EntityId, user: unknown): Observable<unknown> {
    return this.http.put(encodeEndpoint('resources/user-accounts/{}', id), user);
  }

  putUserAccountStatus(id: EntityId, data: unknown): Observable<HasEntityId> {
    const url = encodeEndpoint('resources/user-accounts/{}/status', id);
    return this.http.put<RestResponse<HasEntityId>>(url, data).pipe(map(toData));
  }

  checkUsernameAvailability(
    username: string,
    userAccountId: EntityId,
  ): Observable<AvailabilityResponse> {
    const params = encodeParams({ username, userAccountId });
    const url = encodeEndpoint('resources/user-accounts/username-availability');
    return this.http.get<RestResponse<AvailabilityResponse>>(url, { params }).pipe(map(toData));
  }

  checkRoleNameAvailability(
    name: string,
    userRoleId: EntityId,
    organizationUnitId: EntityId,
  ): Observable<unknown> {
    const params = encodeParams({ name, userRoleId, organizationUnitId });
    return this.http
      .get(encodeEndpoint('resources/user-roles/name-availability'), { params })
      .pipe(map(toData));
  }

  getAuthorities(organizationUnitId: EntityId, internal: boolean): Observable<AuthoritiesGroup[]> {
    const url = encodeEndpoint('resources/authorities');
    const params = encodeParams({ organizationUnitId, internal });
    return this.http.get<RestResponse<AuthoritiesGroup[]>>(url, { params }).pipe(map(toData));
  }

  getUserRole(id: EntityId): Observable<UserRole> {
    const url = encodeEndpoint('resources/user-roles/{}', id);
    return this.http.get<RestResponse<UserRole>>(url).pipe(map(toData));
  }

  getUserOrganizationUnit(): Observable<EntityReference[]> {
    const url = encodeEndpoint('resources/organization-units');
    return this.http.get<RestResponse<EntityReference[]>>(url).pipe(map(toData));
  }

  postUserRole(userRole: unknown): Observable<unknown> {
    return this.http.post(encodeEndpoint('resources/user-roles'), userRole);
  }

  putUserRole(id: EntityId, userRole: unknown): Observable<unknown> {
    return this.http.put(encodeEndpoint('resources/user-roles/{}', id), userRole);
  }

  deleteUserRole(id: EntityId): Observable<unknown> {
    return this.http.delete(encodeEndpoint('resources/user-roles/{}', id));
  }

  deleteUser(userAccountId: EntityId): Observable<unknown> {
    return this.http.delete(encodeEndpoint('resources/user-accounts/{}', userAccountId));
  }

  unblockUser(userAccountId: EntityId): Observable<unknown> {
    return this.http.put(encodeEndpoint('resources/user-accounts/{}/unblock', userAccountId), {});
  }

  getUserRolesPageable(
    pageRequest: PageRequest,
    searchParams: UserRoleSearchParams,
  ): Observable<RestResponseWithMetadata<UserRole>> {
    const httpParams = createPaginationParams(pageRequest);
    const url = encodeEndpoint('resources/user-roles/search');
    return this.http
      .post<PageRestResponse<UserRole>>(url, searchParams, { params: httpParams })
      .pipe(
        catchError(() => defaultResponse<UserRole>()),
        map(toPageableData),
      );
  }

  getUserAccountsPageable(
    pageRequest: PageRequest,
    searchParams: UserSearchParams,
  ): Observable<RestResponseWithMetadata<UserAccount>> {
    const httpParams = createPaginationParams(pageRequest);
    const url = encodeEndpoint('resources/user-accounts/search');
    return this.http
      .post<PageRestResponse<UserAccount>>(url, searchParams, { params: httpParams })
      .pipe(
        catchError(() => defaultResponse<UserAccount>()),
        map(toPageableData),
      );
  }
}
