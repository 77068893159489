import { Injectable } from '@angular/core';
import type { AbstractControl, AsyncValidatorFn } from '@angular/forms';

import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ReserveResourceService } from '../service/reserve-resource.service';

import { ajaxDelay } from '../../../../config/ajax';

@Injectable()
export class ReserveNameValidator {
  constructor(private reserveResourceService: ReserveResourceService) {}

  createValidator(transactionId: number, reserveId: string): AsyncValidatorFn {
    return function (control: AbstractControl) {
      const name = control.value;

      return ajaxDelay.pipe(
        switchMap(() =>
          this.reserveResourceService
            .checkReserveNameAvailable(transactionId, name, reserveId)
            .pipe(
              distinctUntilChanged(),
              map((response) => (!response ? { 'reserve-name-unavailable': true } : null)),
            ),
        ),
      );
    };
  }
}
