import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { filter, first, mapTo, switchMap, tap as runSideEffect } from 'rxjs/operators';

import { LogArea, LoggerService } from '@demica/logger';

import { AppConfigService } from '../../app-config/app-config.service';
import { AuthService } from '../../auth/auth.service';
import { InactivityTimerService } from '../timer/inactivity-timer.service';

import { LOGOUT_REASONS } from '../../auth/logout-redirect/logout-reasons.enum';

const LOGOUT = 'LOGOUT';

@Injectable({
  providedIn: 'root',
})
export class InactivityLogoutMangerService implements OnDestroy {
  private _subscriptions = new Subscription();

  constructor(
    private _appConfigService: AppConfigService,
    private _authService: AuthService,
    private _inactivityTimer: InactivityTimerService,
    private _logger: LoggerService,
  ) {}

  initialize(): void {
    this._subscriptions.add(this._setupLogoutOnInactivity().subscribe());
  }

  restartTimer(): void {
    this._inactivityTimer.restartTimer();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _setupLogoutOnInactivity(): Observable<string> {
    /**
     * we need to listen for authentication status
     * and token refresh events */
    return this._authService.loggedIn$.pipe(
      /** 1. we are interested only in first log-in event */
      filter((isLoggedIn) => isLoggedIn === true),
      first(),
      /** 2. start timer by setting the initial value to left token time */
      /** WARNING: in theory different windows/tabs can have different
       * values for this setting - the window/tab with smallest time
       * at the moment of initialization will be the leader
       * as it will be the first one which will timeout */
      switchMap(() => this._appConfigService.appConfig$),
      switchMap(
        (config) =>
          this._inactivityTimer.startTimer(config.auth.inactivityLogoutTimeInMinutes).timeout$,
      ),
      runSideEffect(() => {
        /** 3. log logout reason */
        this._logger.addInstantLog({ message: 'Logout caused by inactivity', area: LogArea.AUTH });
        /** 4. just logout */
        this._authService.signOut(LOGOUT_REASONS.INACTIVITY);
      }),
      /** 5. below should happen, but let's cleanup */
      mapTo(LOGOUT),
      runSideEffect(() => this._subscriptions.unsubscribe()),
    );
  }
}
