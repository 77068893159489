import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import {
  KeepAliveEvent,
  KeepAliveEventsSource,
  KeepAliveEventType,
} from '../../keep-alive-session.types';

@Injectable({
  providedIn: 'root',
})
export class IntentionalKeepAliveEventsSourceService implements KeepAliveEventsSource {
  events$: Observable<KeepAliveEvent>;
  private _events$ = new Subject<KeepAliveEvent>();

  constructor() {
    this._initialize();
  }

  ping(broadcast = true): void {
    this._events$.next({
      broadcast,
      type: KeepAliveEventType.IntentionalPing,
    });
  }

  private _initialize(): void {
    this.events$ = this._events$.asObservable().pipe();
  }
}
