import { Component, Input } from '@angular/core';

import { Alias } from '@demica/core/core';

import { Actions } from '../../../actions/model/actions.interface';

@Component({
  selector: 'trf-list-alias-mapping-table-row',
  templateUrl: './list-alias-mapping-table-row.component.html',
  styleUrls: ['./list-alias-mapping-table-row.component.sass'],
})
export class ListAliasMappingTableRowComponent {
  @Input()
  row: Alias;

  @Input()
  actions: Actions;
}
