<div
  class="slidein-container"
  role="application"
  [@showSlideIn]="orientation"
  (@showSlideIn.start)="animationStart($event)"
  (@showSlideIn.done)="animationDone($event)"
>
  <div
    *ngIf="isCloseHandlerUsed || isDeleteHandlerVisible"
    class="slidein-action-panel-container"
  >
    <div
      class="slidein-action-panel"
      [@showActionPanel]="orientation"
    >
      <div
        *ngIf="isCloseHandlerUsed"
        #closeButton
        class="action-item"
        tabindex="0"
        role="button"
        ngbAutofocus
        [attr.aria-label]="'SLIDEIN.BUTTON_CLOSE_TITLE' | translate"
        (keydown.enter)="onClose()"
        (click)="onClose()"
        testid="action-close"
      >
        <ngp-svg-icon
          class="close-icon"
          iconName="times"
        ></ngp-svg-icon>
      </div>

      <ng-container *ngIf="isDeleteHandlerVisible">
        <div
          *ifHasAuthority="requiredAuthority"
          class="action-item"
          [ngClass]="{ inactive: isDeleteHandlerDisabled }"
          ngpTooltipRole="button"
          [ngpTooltipAriaLabel]="'SLIDEIN.BUTTON_DELETE_TITLE' | translate"
          (click)="onDelete()"
          (keyup.enter)="onDelete()"
          testid="action-delete"
          [attr.aria-disabled]="!isDeleteHandlerDisabled"
          ngpTooltipPosition="left"
          [ngpTooltip]="isDeleteHandlerDisabled ? usageRelationsTooltip : null"
        >
          <ngp-svg-icon iconName="trash-alt"></ngp-svg-icon>
        </div>
      </ng-container>

      <ng-template #usageRelationsTooltip>
        <div class="usage-relation-message">{{ deletionDisabledMessage | translate }}</div>
      </ng-template>
    </div>
  </div>

  <div
    cdkScrollable
    class="slidein-body"
  >
    <div class="slidein-header">
      <h3
        class="trf-slidein-title"
        [attr.aria-label]="headerText | translate"
      >
        {{ headerText | translate }}
      </h3>
      <h4
        *ngIf="subHeaderText"
        [attr.aria-label]="subHeaderText | translate"
      >
        {{ subHeaderText }}
      </h4>
    </div>
    <div class="slidein-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
