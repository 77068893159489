export const DASHBOARD_FUNDING_FEES_VIEW = 'DASHBOARD_FUNDING_FEES_VIEW';
export const DASHBOARD_FUNDING_FEES_CREATE = 'DASHBOARD_FUNDING_FEES_CREATE';
export const DASHBOARD_FUNDING_FEES_REFUND = 'DASHBOARD_FUNDING_FEES_REFUND';

export const DASHBOARD_FUNDING_RECOURSE_ACCOUNT_VIEW = 'DASHBOARD_FUNDING_RECOURSE_ACCOUNT_VIEW';
export const DASHBOARD_FUNDING_RECOURSE_ACCOUNT_CLEAR = 'DASHBOARD_FUNDING_RECOURSE_ACCOUNT_CLEAR';

export const DASHBOARD_TLF_FUNDING_FEES_TAB_VIEW_GROUP = [
  DASHBOARD_FUNDING_FEES_CREATE,
  DASHBOARD_FUNDING_FEES_REFUND,
  DASHBOARD_FUNDING_FEES_VIEW,
];

export const DASHBOARD_TLF_TAB_VIEW_GROUP = [...DASHBOARD_TLF_FUNDING_FEES_TAB_VIEW_GROUP];

export const DASHBOARD_TLF_FUNDING_RECOURSE_ACCOUNT_TAB_VIEW_GROUP = [
  DASHBOARD_FUNDING_RECOURSE_ACCOUNT_CLEAR,
  DASHBOARD_FUNDING_RECOURSE_ACCOUNT_VIEW,
];

export const TRANSACTION_RETENTION_PURCHASING_MANAGE = 'TRANSACTION_PURCHASING_RETENTION_MANAGE';
export const TRANSACTION_RETENTION_FUNDING_MANAGE = 'TRANSACTION_FUNDING_RETENTION_MANAGE';

export const TRANSACTION_LIMITS_PURCHASING_MANAGE = 'TRANSACTION_PURCHASING_LIMITS_MANAGE';
export const TRANSACTION_LIMITS_FUNDING_MANAGE = 'TRANSACTION_FUNDING_LIMITS_MANAGE';
