import { Injectable } from '@angular/core';

import { forkJoin, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserAuthContext } from '../../../model/auth/user-auth.context.interface';
import { User } from '../../../model/user';
import { digestMessage } from '../../../utils/crypto';
import {
  isUserAuthContextObject,
  UserAuthContextChangeReason,
} from './user-auth-context-change.reason';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsComparator {
  static async getUserContextSummary(user: User): Promise<UserAuthContext> {
    const hash = await digestMessage(user.getAuthorities().sort().join(''));
    return {
      userId: user.accountId,
      userRegionContextId: user.getOrganizationUnitReferenceId(),
      userAuths: hash.toString(),
    };
  }

  getUsersContextDiff$(
    prev: User | UserAuthContext,
    current: User | UserAuthContext,
  ): Observable<UserAuthContextChangeReason> {
    return forkJoin({
      prev: from(
        isUserAuthContextObject(prev)
          ? of(prev)
          : UserDetailsComparator.getUserContextSummary(prev),
      ),
      current: from(
        isUserAuthContextObject(current)
          ? of(current)
          : UserDetailsComparator.getUserContextSummary(current),
      ),
    }).pipe(
      map(({ prev, current }) => {
        if (prev.userRegionContextId !== current.userRegionContextId) {
          return UserAuthContextChangeReason.REGION_CHANGED;
        }
        if (prev.userAuths !== current.userAuths || prev.userId !== current.userId) {
          return UserAuthContextChangeReason.IDENTITY_CHANGED;
        }
        return UserAuthContextChangeReason.NOT_CHANGED;
      }),
    );
  }
}
