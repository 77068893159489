import { Injectable } from '@angular/core';

import { AppConfigService } from './app-config/app-config.service';

@Injectable()
export class CurrenciesDecimalPlacesService {
  private currencyDecimalMap = new Map<string, number>();
  private defaultValue = 2;

  constructor(private appConfigResource: AppConfigService) {
    this.appConfigResource.appConfig$.subscribe((config) => {
      this.parseMapValues(config.decimalCurrencies.decimalPlaces);
      this.defaultValue = config.decimalCurrencies.defaultValue;
    });
  }

  public currencyDecimalPlaces(currency: string): number {
    return this.currencyDecimalMap.has(currency)
      ? this.currencyDecimalMap.get(currency)
      : this.defaultValue;
  }

  private parseMapValues(decimalPlacesObject: Record<string, number>): void {
    const keys = Object.keys(decimalPlacesObject);
    keys.forEach((key) => this.currencyDecimalMap.set(key, decimalPlacesObject[key]));
  }
}
