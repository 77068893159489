import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { forkJoin, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  Alias,
  AliasesAnalysisCodeRequest,
  AliasesResourceService,
  AnalysisCode,
  CountriesService,
  CurrenciesService,
  CurrencyCode,
  DictionaryEntry,
  EntityId,
  EntityReference,
  Environment,
  EnvironmentResourceService,
  groupBy,
  maxUserDefinedColumnLength,
  MetaData,
  orNull,
  removeElement,
  requiredErrorCodesRequestWrapper,
  SlideinContainerAbstractClass,
  Transaction,
  TransactionDataSource,
  TransactionResourceService,
} from '@demica/core/core';
import { getKeys } from '@demica/utils';

import { AliasesManagementCountrySelectorDynamicGroup } from '../aliases-management-country-selector-group/aliases-management-country-selector-group.component';
import { AliasesManagementCurrencySelectorDynamicGroup } from '../aliases-management-currency-selector-group/aliases-management-currency-selector-group.component';

import { requireSelect, validateNotEmpty } from '../../../../forms/validators';
import {
  COUNTRY_COLUMN_ID,
  CURRENCY_COLUMN_ID,
  inactiveAnalysisGroups,
} from '../../../../model/alias/inactive-analysis-groups-constants';
import { AliasesValidator } from '../../../../validation/analysis-code-form-validations';

const HttpErrorConflictStatusNumber = 409;

export type AliasesManagementForm = FormGroup<
  {
    analysisGroup: FormControl<EntityReference>;
    analysisCode: FormControl<string>;
    transaction: FormControl<EntityReference>;
  } & AliasesManagementCurrencySelectorDynamicGroup &
    AliasesManagementCountrySelectorDynamicGroup
>;

type AliasByEnvironment = { [key: number | string]: Alias[] };

@Component({
  selector: 'trf-aliases-management-slidein',
  templateUrl: './aliases-management-slidein.container.html',
  styleUrls: ['./aliases-management-slidein.container.sass'],
})
export class AliasesManagementSlideinContainerComponent
  extends SlideinContainerAbstractClass
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input()
  analysisCodeData: AnalysisCode;
  @Input()
  transaction?: Transaction;
  @Input()
  transactionList: EntityReference[] = [];
  @Input()
  previewMode = false;

  analysisGroups: EntityReference[] = [];
  aliasMappings: Alias[] = [];
  mappingByEnvironment: AliasByEnvironment = {};
  transactionDataSource: TransactionDataSource[] = [];
  transactionId: EntityId;

  versionPreviewMode = false;
  submitted = false;
  currencyCodes: CurrencyCode[];
  countries: DictionaryEntry[];

  metaData: MetaData;
  environments: Environment[] = [];
  controlsDisabled = true;
  disabledToModify = false;
  showAnalysisCode = false;
  isActiveCurrency = false;
  isActiveCountry = false;
  disabledSaveButton = false;
  columnDefinitionId: EntityId;
  maxLength = maxUserDefinedColumnLength;
  initializedCommon = false;
  initializedAnalysisGroup = false;
  initializedTransaction = false;
  form: AliasesManagementForm = this._fb.group({
    analysisGroup: [null as EntityReference, [requireSelect]],
    analysisCode: ['', []],
    transaction: [null as EntityReference, [requireSelect]],
  });
  codeValidation = AliasesValidator.code(this.form, () => this.submitted);
  requireValidations = AliasesValidator.require(this.form, () => this.submitted);
  protected editMode = false;
  private _destroySubject = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _transactionResourceService: TransactionResourceService,
    private _environmentResourceService: EnvironmentResourceService,
    private _aliasesResourceService: AliasesResourceService,
    private _countriesService: CountriesService,
    private _currenciesService: CurrenciesService,
  ) {
    super();
  }

  private get _analysisCodeField(): FormControl<string> {
    return this.form.controls.analysisCode;
  }

  private get _currencyCodesField(): FormControl<EntityReference> {
    return this.form.controls.currencyCodesGroup.controls.currencyCodes;
  }

  private get _countriesField(): FormControl<EntityReference> {
    return this.form.controls.countriesGroup.controls.countries;
  }

  ngOnInit(): void {
    forkJoin([
      this._countriesService.countriesAsSelectOptions(),
      this._currenciesService.getCurrencyCodes(),
    ])
      .pipe(
        tap(([countries, currencyCodes]) => {
          this.countries = countries;
          this.currencyCodes = currencyCodes;
        }),
        takeUntil(this._destroySubject),
        finalize(() => (this.initializedCommon = true)),
      )
      .subscribe();

    this.form.controls.transaction.valueChanges
      .pipe(
        filter((transaction) => !!orNull(() => transaction.entityId)),
        distinctUntilChanged(),
        tap((transaction) => (this.transactionId = transaction.entityId)),
        switchMap((transaction) =>
          forkJoin([
            this._transactionResourceService.getAnalysisGroupsForTransactions([
              transaction.entityId,
            ]),
            this._transactionResourceService.getDataSourcesForTransactions([transaction.entityId]),
            this._environmentResourceService.getAllAssignedTransactionEnvironmentsForGivenUser(
              transaction.entityId,
            ),
          ]),
        ),
        tap(([analysisGroup, transactionDataSource, environments]) => {
          this.editMode = !!this.analysisCodeData;
          this.analysisGroups = analysisGroup;
          this.transactionDataSource = transactionDataSource;
          this.environments = environments;
          this.controlsDisabled = !analysisGroup.length;
        }),
        filter(() => {
          if (!this.editMode) {
            this._initializeFormControl();
          }

          return this.editMode;
        }),
        switchMap(() =>
          this._aliasesResourceService.getAnalysisCodeWithMapping(
            this.analysisCodeData.analysisCodeId,
            this.analysisCodeData.transaction.entityId,
          ),
        ),
        takeUntil(this._destroySubject),
      )
      .subscribe((mappings) => {
        const mappingGroupByEnvironments = groupBy(mappings.aliases, 'environmentId');

        getKeys(mappingGroupByEnvironments).forEach(
          (key) => (this.mappingByEnvironment[key] = mappingGroupByEnvironments[key]),
        );

        if (this.editMode) {
          this._initializeFormControl();
        }
        this.initializedTransaction = true;
      });

    this.form.controls.analysisGroup.valueChanges
      .pipe(takeUntil(this._destroySubject))
      .subscribe((value) => {
        if (!this.editMode) {
          this._checkIfCurrencyDynamicFormShouldBeDisabled();
          this._checkIfCountryDynamicFormShouldBeDisabled();
        }

        const valueName = orNull(() => value.entityId as number);
        this.showAnalysisCode = valueName && !inactiveAnalysisGroups.includes(valueName);
        this.columnDefinitionId = orNull(() => value.entityId);

        if (this.showAnalysisCode) {
          if (!this.previewMode) {
            const hasCodeAvailable = AliasesValidator.hasCodeAvailable(
              this._aliasesResourceService,
              orNull(() => this.form.controls.transaction.value.entityId),
              this.columnDefinitionId,
              this.editMode ? this.analysisCodeData.analysisCodeId : undefined,
            );

            this._analysisCodeField.setAsyncValidators(hasCodeAvailable);
            this._analysisCodeField.setValidators([validateNotEmpty]);
            this._analysisCodeField.updateValueAndValidity();
          }
        } else {
          this._analysisCodeField.clearAsyncValidators();
          this._analysisCodeField.clearValidators();
          this._analysisCodeField.reset('');
        }
        this.initializedAnalysisGroup = true;
      });

    if (this.analysisCodeData) {
      this.form.patchValue({ transaction: this.analysisCodeData.transaction });
    } else if (this.transaction) {
      this.form.patchValue({ transaction: this.transaction });
    }
  }

  isInitialized(): boolean {
    if (!this.editMode) return this.initializedCommon;
    return this.initializedCommon && this.initializedAnalysisGroup && this.initializedTransaction;
  }

  ngAfterViewChecked(): void {
    if (this.editMode) {
      this._checkIfCurrencyDynamicFormShouldBeDisabled();
      this._checkIfCountryDynamicFormShouldBeDisabled();
    }
  }

  ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  onAddNewAliasMapping(aliasMapping: Alias): void {
    const hasNoEnvironmentKey = (environmentId: number) =>
      this.mappingByEnvironment[environmentId] === undefined;

    hasNoEnvironmentKey(aliasMapping.environmentId)
      ? (this.mappingByEnvironment[aliasMapping.environmentId] = [aliasMapping])
      : this._findMappingsByEnvironmentId(aliasMapping.environmentId).push(aliasMapping);

    this.aliasMappings = [...(this._findMappingsByEnvironmentId(aliasMapping.environmentId) || [])];
  }

  onDeleteAliasMapping(aliasMapping: Alias): void {
    const aliasMappings = this._findMappingsByEnvironmentId(aliasMapping.environmentId);

    removeElement(
      aliasMappings,
      (alias) =>
        alias.dataSourceId === aliasMapping.dataSourceId && alias.value === aliasMapping.value,
    );
    this.aliasMappings = [...(this._findMappingsByEnvironmentId(aliasMapping.environmentId) || [])];
  }

  onSave(): void {
    this.submitted = true;

    if ((this.form.valid || this.form.disabled) && !this.disabledSaveButton) {
      this.disabledSaveButton = true;
      const analysisGroup: EntityReference = this.form.controls.analysisGroup.value;

      const request = {
        transactionId: orNull(() => this.form.controls.transaction.value.entityId),
        columnTypeId: analysisGroup.entityId,
        value: this._setProperAnalysisCodeValue(),
        aliases: getKeys(this.mappingByEnvironment)
          .flatMap((key) => this.mappingByEnvironment[key])
          .map(
            (alias) =>
              ({
                value: alias.value,
                environmentId: alias.environmentId,
                dataSourceId: (alias.dataSourceId as TransactionDataSource).entityId,
              } as Alias),
          ),
      };

      if (this.analysisCodeData) {
        this._updateAnalysisCode(this.analysisCodeData.analysisCodeId, request);
      } else {
        this._createAnalysisCode(request);
      }
    }
  }

  onEnvironmentChange(environment: EntityReference): void {
    let aliasMappings: Alias[] = [];
    if (environment && environment.entityId !== undefined) {
      aliasMappings = this.mappingByEnvironment[environment.entityId];
    }
    this.aliasMappings = [...(aliasMappings || [])];
  }

  isAnalysisCodeFormActive(): boolean {
    if (this._getAnalysisGroupFromValue())
      return inactiveAnalysisGroups.includes(this._getAnalysisGroupFromValue());
  }

  private _checkIfCurrencyDynamicFormShouldBeDisabled(): void {
    if (this._getAnalysisGroupFromValue()) {
      this.isActiveCurrency = this._getAnalysisGroupFromValue() === CURRENCY_COLUMN_ID;
      this._ifDynamicControlShouldBeDisabled(
        'currencyCodesGroup',
        'currencyCodes',
        this.currencyCodes,
        this.isActiveCurrency,
      );
    }
  }

  private _checkIfCountryDynamicFormShouldBeDisabled(): void {
    if (this._getAnalysisGroupFromValue()) {
      this.isActiveCountry = this._getAnalysisGroupFromValue() === COUNTRY_COLUMN_ID;
      this._ifDynamicControlShouldBeDisabled(
        'countriesGroup',
        'countries',
        this.countries,
        this.isActiveCountry,
      );
    }
  }

  private _ifDynamicControlShouldBeDisabled(
    formGroupName: string,
    formGroupControlName: string,
    collection: CurrencyCode[] | DictionaryEntry[],
    activeControl: boolean,
  ): void {
    if (this.editMode && activeControl) {
      const field = orNull(() => this.form.get(formGroupName).get(formGroupControlName));
      if (field) {
        field.setValue(
          (collection as DictionaryEntry[]).find(
            (country) => country.entityId === this.analysisCodeData.value,
          ),
        );
        field.disable();
        this.disabledToModify = true;
      }
    }
  }

  private _getAnalysisGroupFromValue(): number {
    return orNull(() => this.form.controls.analysisGroup.value.entityId as number);
  }

  private _setProperAnalysisCodeValue(): string {
    return (
      orNull(() => this._analysisCodeField.value) ||
      orNull(() => this._currencyCodesField.value.entityId as string) ||
      orNull(() => this._countriesField.value.entityId as string)
    );
  }

  private _findMappingsByEnvironmentId(environmentId: number): Alias[] {
    return this.mappingByEnvironment[environmentId];
  }

  private _createAnalysisCode(request: AliasesAnalysisCodeRequest): void {
    this._aliasesResourceService
      .addAnalysisCode(request)
      .pipe(catchError(this._resolveErrors))
      .subscribe(() => this.modal.close());
  }

  private _updateAnalysisCode(analysisCodeId: EntityId, request: AliasesAnalysisCodeRequest): void {
    this._aliasesResourceService
      .editAnalysisCode(String(analysisCodeId), request)
      .pipe(catchError(this._resolveErrors))
      .subscribe(() => this.modal.close());
  }

  private _resolveErrors = (errors: HttpErrorResponse) => {
    if (errors.status === HttpErrorConflictStatusNumber) {
      const errorCodes = ['ANALYSIS_CODE_ALIAS_VALUE_USED_BY_OTHER_ANALYSIS_CODE'];

      this.metaData = requiredErrorCodesRequestWrapper(errorCodes);
    }

    this.disabledSaveButton = false;
    return of();
  };

  private _initializeFormControl(): void {
    if (this.analysisCodeData) {
      const valueToPathValue = {
        analysisGroup: this.analysisCodeData.analysisGroup,
        analysisCode: this.analysisCodeData.value,
      };

      this.form.patchValue(valueToPathValue);
    }

    if (this.editMode && !this._checkForInactiveAnalysisGroups()) {
      this.disabledToModify = true;
    }
  }

  private _checkForInactiveAnalysisGroups(): boolean {
    return inactiveAnalysisGroups.includes(this.analysisCodeData.analysisGroup.entityId as number);
  }
}
