<ng-container
  *ngIf="form"
  [formGroup]="form"
>
  <div class="telephone-row">
    <div class="row no-gutters">
      <div class="col-4">
        <div class="column-telephone-prefix">
          <div
            class="column-label"
            [ngClass]="{ 'row-disabled': formCountryControl.disabled }"
          >
            <label [for]="formCountryControlName">{{ countryCodeLabel | translate }}</label>
          </div>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <span class="input-group-text-content">+</span>
              </span>
            </div>
            <input
              class="form-control"
              type="text"
              [id]="formCountryControlName"
              [placeholder]="countryCodePlaceholder | translate"
              [formControlName]="formCountryControlName"
              (blur)="onInputBlur(formCountryControl)"
              testid="telephone-country-code"
            />
          </div>
        </div>
      </div>

      <div class="col-8">
        <div class="column-telephone-number">
          <div class="column-label">
            <label [for]="formNumberControlName">{{ label | translate }}</label>
          </div>
          <input
            class="form-control"
            type="text"
            [id]="formNumberControlName"
            [formControlName]="formNumberControlName"
            [placeholder]="numberPlaceholder | translate"
            (blur)="onInputBlur(formNumberControl)"
            testid="telephone-number"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="column-label"></div>
    <div class="column-telephone-validation">
      <div class="validation-error">
        <span *ngFor="let validator of countryCodeValidators">
          <ngp-inline-notification
            *ngIf="validator.func(formCountryControlName)"
            [message]="validator.key | translate"
          >
          </ngp-inline-notification>
        </span>

        <span *ngFor="let validator of numberValidators">
          <ngp-inline-notification
            *ngIf="validator.func(formNumberControlName) && prefixValid"
            [message]="validator.key | translate"
          >
          </ngp-inline-notification>
        </span>
      </div>
    </div>
  </div>
</ng-container>
