<div
  *ngIf="tooltipTemplate"
  class="ngp-tooltip"
  role="tooltip"
>
  <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
</div>
<div
  *ngIf="tooltip"
  class="ngp-tooltip"
  role="tooltip"
  [innerHTML]="tooltip"
></div>
<div
  *ngIf="position"
  [ngClass]="['arrow', position]"
></div>
