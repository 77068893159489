import { animate, state, style, transition, trigger } from '@angular/animations';
import { ArrayDataSource } from '@angular/cdk/collections';
import { CdkTreeModule, NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TypedChanges } from '@demica/core/core';

import { TreeChecklistControl } from '../../tree-checklist-control';
import {
  TreeChecklistExpandTemplateContext,
  TreeChecklistExpandTemplateDirective,
} from '../../tree-checklist-templates/tree-checklist-expand-template.directive';
import {
  TreeChecklistItemContentContext,
  TreeChecklistItemContentDirective,
} from '../../tree-checklist-templates/tree-checklist-item-content.directive';
import {
  TreeChecklistSelectTemplateContext,
  TreeChecklistSelectTemplateDirective,
} from '../../tree-checklist-templates/tree-checklist-select-template.directive';
import {
  isTreeChecklistConfiguration,
  TreeChecklistConfiguration,
} from '../../types/tree-checklist-configuration.interface';
import { NodeId } from '../../types/tree-node-context.interface';

@Component({
  selector: 'ngp-tree-checklist',
  standalone: true,
  imports: [CommonModule, CdkTreeModule],
  templateUrl: './tree-checklist.component.html',
  styleUrls: ['./tree-checklist.component.sass'],
  animations: [
    trigger('detailExpanded', [
      state('false', style({ height: '0px', overflow: 'hidden', visibility: 'hidden' })),
      state('true', style({ height: '*' })),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class TreeChecklistComponent<T extends object = object> implements OnDestroy, OnChanges {
  @ContentChild(TreeChecklistItemContentDirective, { read: TemplateRef, static: true })
  checklistItemContentTemplate!: TemplateRef<TreeChecklistItemContentContext<T>>;
  @ContentChild(TreeChecklistExpandTemplateDirective, { read: TemplateRef, static: true })
  checklistExpandTemplate!: TemplateRef<TreeChecklistExpandTemplateContext<T>>;
  @ContentChild(TreeChecklistSelectTemplateDirective, { read: TemplateRef, static: true })
  checklistSelectTemplate!: TemplateRef<TreeChecklistSelectTemplateContext<T>>;

  @Input() configuration!: TreeChecklistConfiguration<T>;
  @Input() selectedNodes: T[] | NodeId[] = [];
  @Output() selectionChange = new EventEmitter<T[]>();

  treeControl!: NestedTreeControl<T>;
  treeChecklistControl!: TreeChecklistControl<T>;
  dataSource!: ArrayDataSource<T>;

  private _treeChecklistSubscription!: Subscription;
  private _destroyed$ = new Subject<void>();

  ngOnChanges(changes: TypedChanges<TreeChecklistComponent<T>>): void {
    if (changes.configuration && changes.configuration.currentValue) {
      this._synchronize();
    }
    if (changes.selectedNodes && !changes.selectedNodes.firstChange) {
      this.treeChecklistControl?.updateSelection(changes.selectedNodes.currentValue);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  hasChild: (index: number, node: T) => boolean = (index: number, node: T) => {
    return !!this.configuration?.childrenFactory(node)?.length;
  };

  onExpandToggle: (node: T) => () => void = (node: T) => () => {
    const isExpanded = this.treeControl.isExpanded(node);
    if (isExpanded) {
      this.treeControl.collapse(node);
    } else {
      this.treeControl.expand(node);
    }
  };

  onSelectionToggle: (node: T) => (change: boolean) => void = (node: T) => (change: boolean) => {
    this.treeChecklistControl.toggleSelection(node, change);
  };

  private _synchronize(): void {
    this._treeChecklistSubscription?.unsubscribe();

    if (!isTreeChecklistConfiguration<T>(this.configuration)) {
      return;
    }

    this.treeControl = new NestedTreeControl<T>(this.configuration.childrenFactory);
    this.treeChecklistControl = new TreeChecklistControl<T>(
      this.configuration.items,
      this.selectedNodes,
      this.configuration.childrenFactory,
      this.configuration.trackBy,
    );
    this._treeChecklistSubscription = this.treeChecklistControl.selectionChange$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((selected) => {
        this.selectionChange.emit(selected);
      });

    this.dataSource = new ArrayDataSource(this.configuration.items);
  }
}
