import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MenuOption, MenuService, UserService } from '@demica/core/core';

@Component({
  selector: 'trf-layouts-auth',
  templateUrl: './auth.html',
})
export class LayoutAuthComponent implements OnInit, OnDestroy {
  public menuOptions: MenuOption[] = [];

  destroySubject = new Subject<void>();

  sidebarClosed = false;

  constructor(private menuService: MenuService, private userService: UserService) {}

  public ngOnInit() {
    this.menuService.currentMenu
      .pipe(takeUntil(this.destroySubject))
      .subscribe((menu) => (this.menuOptions = menu));
    this.userService.currentUser
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => this.getOptions());
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  getOptions() {
    this.menuService.getOptions().then((options) => {
      this.menuOptions = options;
    });
  }

  onArrowClicked() {
    this.sidebarClosed = !this.sidebarClosed;
  }
}
