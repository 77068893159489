import { RequiredAuthority } from '../interface/route.interface';
import { intersection } from '../utils/collection';
import { $enum } from 'ts-enum-util';

export class AuthoritySet {
  constructor(private authoritiesConfig: RequiredAuthority) {}

  isSatisfiedBy(inputAuthorities: string[]): boolean {
    /* exhaustive pattern matching over possible require modes */
    return $enum.visitValue(this.authoritiesConfig.mode).with({
      Any: () => intersection(this.authoritiesConfig.authorities, inputAuthorities).length > 0,
      All: () =>
        intersection(this.authoritiesConfig.authorities, inputAuthorities).length ===
        this.authoritiesConfig.authorities.length,
    });
  }
}
