<ng-container [formGroup]="group">
  <div>
    <div
      *ngIf="!!label"
      class="column-label"
      [ngClass]="{ 'row-disabled': formControl.disabled || disable }"
    >
      <label
        [for]="formControlName"
        [class]="labelClasses"
        >{{ label | translate }}</label
      >
      <ngp-tooltip-icon
        *ngIf="helperToolTip"
        [tooltipKey]="helperToolTip"
      ></ngp-tooltip-icon>
    </div>

    <div class="column-input">
      <input
        class="form-control floating-point-input {{ inputClasses }}"
        type="text"
        placeholder="{{ placeholder | translate }}"
        trfDecimalFormatter
        [id]="formControlName"
        [formControl]="formControl"
        [formControlName]="formControlName"
        [disableControl]="disable"
        [readonly]="readonly"
        [attr.aria-required]="labelClasses?.includes('required')"
        [attr.aria-describedby]="formControlName + '_notification'"
        (keypress)="onKeypress($event)"
        (blur)="onBlur()"
        (decimalFormatted)="onDecimalFormatted($event)"
        data-testid="form-decimal-row-input"
      />
      <trf-form-number-row-default-validation-messages
        [validations]="validations"
        [name]="formControlName"
        [input]="formControl"
      >
        <ng-container #additionalValidationContainer></ng-container>
      </trf-form-number-row-default-validation-messages>
    </div>
  </div>
</ng-container>
