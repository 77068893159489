/* Base */
import { ReserveBarPaletteInterface } from './reserve-bar-palette.interface';

export const BaseColors = {
  MINT: '#60c3ad',
  LIGHT_BLUE: '#6e50ff',
  CLARET: '#942852',
  LIGHT_CLARET: '#c2356c',
  DARK_GRAY: '#9b9b9b',
};

/* Specified by components */

export const FundingGraphPalette = [
  BaseColors.MINT,
  '#45b79f',
  '#40a992',
  '#3a9a86',
  '#358c7a',
  '#358c7a',
  '#2f7e6d',
  '#2a7061',
  '#256154',
  '#1f5348',
];

export const ReservesBarPalette: Record<keyof Partial<ReserveBarPaletteInterface>, string> = {
  XS: '--chart-reserve-xs',
  XS_MISSING: '--chart-reserve-xs',
  LOSS: '--chart-reserve-loss',
  LOSS_MISSING: '--chart-reserve-loss-missing',
  DILUTION: '--chart-reserve-dilution',
  DILUTION_MISSING: '--chart-reserve-dilution-missing',
  YIELD: '--chart-reserve-yield',
  YIELD_MISSING: '--chart-reserve-yield-missing',
  FX: '--chart-reserve-fx',
  FX_MISSING: '--chart-reserve-fx-missing',
  FLOOR: '--chart-floor',
} as Record<keyof Partial<ReserveBarPaletteInterface>, string>;
