import {
  animate,
  animateChild,
  AnimationEvent,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Subject } from 'rxjs';

import { getDir, isDefined, SlideinContext } from '@demica/core/core';

import { DialogTrackingService } from '../service/dialog-tracking.service';

const ANIMATION = {
  TIME: '350ms',
  DELAY: '100ms',
  FUNCTION: 'ease-in-out',
};

const SLIDE_IN_ANIMATION_GAP = '40px';

@Component({
  selector: 'trf-slidein-container',
  templateUrl: 'slidein-container.component.html',
  styleUrls: ['./slidein-container.component.sass'],
  animations: [
    trigger('showSlideIn', [
      transition('void => ltr', [
        style({
          transform: `translateX(calc(100% - ${SLIDE_IN_ANIMATION_GAP}))`,
        }),
        animate(`${ANIMATION.TIME} ${ANIMATION.FUNCTION}`),
        query('@showActionPanel', [animateChild()], { optional: true }),
      ]),
      transition('void => rtl', [
        style({
          transform: `translateX(calc(-100% + ${SLIDE_IN_ANIMATION_GAP})`,
        }),
        animate(`${ANIMATION.TIME} ${ANIMATION.FUNCTION}`),
        query('@showActionPanel', [animateChild()], { optional: true }),
      ]),
    ]),
    trigger('showActionPanel', [
      transition('void => ltr', [
        style({
          right: '-40px',
          opacity: '.2',
        }),
        animate(`${ANIMATION.TIME} ${ANIMATION.DELAY} ${ANIMATION.FUNCTION}`),
      ]),
      transition('void => rtl', [
        style({
          left: '-40px',
          opacity: '.2',
        }),
        animate(`${ANIMATION.TIME} ${ANIMATION.DELAY} ${ANIMATION.FUNCTION}`),
      ]),
    ]),
  ],
})
export class SlideinContainerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  headerText: string;
  @Input()
  subHeaderText?: string;
  @Input()
  requiredAuthority?: string;
  @Input()
  canDelete = false;
  @Input()
  deletionDisabledMessage?: string;

  @Output()
  slideinClose = new EventEmitter<void>();
  @Output()
  delete = new EventEmitter<void>();

  @ViewChild('closeButton')
  closeButton: ElementRef;

  orientation: string = null;
  isCloseHandlerUsed = false;
  isDeleteHandlerDisabled = false;
  isDeleteHandlerVisible = false;

  private destroyed$ = new Subject<void>();

  constructor(
    private slideinContext: SlideinContext,
    private dialogTrackingService: DialogTrackingService,
  ) {}

  ngOnInit(): void {
    this.isCloseHandlerUsed = !!this.slideinClose.observers.length;
    this.orientation = getDir();
  }

  ngOnChanges(): void {
    if (isDefined(this.canDelete)) {
      this.deletionDisabledMessage = this.deletionDisabledMessage?.length
        ? `NOTIFICATION.ERROR_CODE.${this.deletionDisabledMessage}`
        : null;
      const isDeleteHandlerUsed = !!this.delete.observers.length && this.canDelete;
      this.isDeleteHandlerDisabled = !isDeleteHandlerUsed && !!this.deletionDisabledMessage;
      this.isDeleteHandlerVisible = isDeleteHandlerUsed || this.isDeleteHandlerDisabled;
    }
  }

  ngAfterViewInit() {
    this.dialogTrackingService.addNewOpenedSlidein(this.closeButton.nativeElement);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.slideinContext.unlockControls();
  }

  onClose() {
    this.slideinClose.emit();
    this.dialogTrackingService.removeClosedSlidein();
  }

  onDelete() {
    if (!this.isDeleteHandlerDisabled) {
      this.delete.emit();
    }
  }

  animationStart($event: AnimationEvent) {
    const el: Element = $event.element as HTMLElement;
    el.parentElement.classList.add('animation-start');
  }

  animationDone($event: AnimationEvent) {
    const el: Element = $event.element as HTMLElement;
    el.parentElement.classList.remove('animation-start');
    el.parentElement.classList.add('animation-done');
  }
}
