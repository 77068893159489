<ng-template #template>
  <ng-container
    *ngIf="form"
    [formGroup]="form"
  >
    <ng-container *ngIf="availableEntries$ | async as entries">
      <trf-form-select-row
        *ngIf="configuration.seller"
        class="filter-item"
        label="FILTERS.SELLER"
        defaultLabel="FILTERS.FORM_ALL_FILTERED_SELLERS"
        [name]="formFields.seller"
        [options]="entries.sellers"
        testid="seller-select"
      ></trf-form-select-row>

      <trf-form-select-row
        *ngIf="configuration.client"
        class="filter-item"
        label="FILTERS.CLIENT"
        defaultLabel="FILTERS.FORM_ALL_FILTERED_CLIENTS"
        [name]="formFields.client"
        [options]="entries.clients"
        testid="client-select"
      ></trf-form-select-row>

      <trf-form-select-row
        *ngIf="configuration.transaction"
        class="filter-item"
        label="FILTERS.TRANSACTION"
        defaultLabel="FILTERS.FORM_ALL_FILTERED_TRANSACTIONS"
        [name]="formFields.transaction"
        [options]="entries.transactions"
        testid="transaction-select"
      ></trf-form-select-row>

      <trf-form-select-row
        *ngIf="configuration.environment"
        class="filter-item"
        label="FILTERS.ENVIRONMENT"
        defaultLabel="FILTERS.FROM_ALL_FILTERED_ENVIRONMENTS"
        [name]="formFields.environment"
        [options]="entries.environments"
        testid="environment-select"
      ></trf-form-select-row>

      <trf-form-select-row
        *ngIf="configuration.assetBase"
        class="filter-item"
        label="FILTERS.ASSET_BASE"
        defaultLabel="FILTERS.FORM_ALL_ASSET_BASE"
        [name]="formFields.assetBase"
        [options]="entries.assetBases"
        testid="assetBase-select"
      ></trf-form-select-row>
    </ng-container>
  </ng-container>
</ng-template>
