import { Injectable } from '@angular/core';

import { Observable, Subject, Observer } from 'rxjs';
import { share } from 'rxjs/operators';

import { generateUUID } from '@demica/utils';

import { IClearWrapper } from '../model/clear-wrapper.model';
import { Toast, ToastType } from '../model/toast.model';

/*
  ToasterService is properly tested in integration in ToastContainerComponent
 */
@Injectable({ providedIn: 'root' })
export class ToasterService {
  addToast$: Observable<Toast>;
  clearToasts$: Observable<IClearWrapper>;
  removeToast$: Observable<IClearWrapper>;

  private _addToast!: Observer<Toast>;
  private _clearToasts!: Observer<IClearWrapper>;
  private _removeToastSubject$: Subject<IClearWrapper>;

  /**
   * Creates an instance of ToasterService.
   */
  constructor() {
    this.addToast$ = new Observable<Toast>((observer: any) => (this._addToast = observer)).pipe(
      share(),
    );
    this.clearToasts$ = new Observable<IClearWrapper>(
      (observer: any) => (this._clearToasts = observer),
    ).pipe(share());
    this._removeToastSubject$ = new Subject<IClearWrapper>();
    this.removeToast$ = this._removeToastSubject$.pipe(share());
  }

  /**
   * Synchronously create and show a new toast instance.
   *
   * @param {(string | Toast)} type The type of the toast, or a Toast object.
   * @param {string=} title The toast title.
   * @param {string=} body The toast body.
   * @returns {Toast}
   *          The newly created Toast instance with a randomly generated GUID Id.
   */
  pop(type: ToastType | Toast, title?: string, body?: string): Toast {
    const toast = typeof type === 'string' ? { type: type, title: title, body: body } : type;

    if (!toast.toastId) {
      toast.toastId = generateUUID();
    }

    if (!this._addToast) {
      throw new Error('No Toaster Containers have been initialized to receive toasts.');
    }

    this._addToast.next(toast);
    return toast;
  }

  /**
   * Asynchronously create and show a new toast instance.
   *
   * @param {(string | Toast)} type The type of the toast, or a Toast object.
   * @param {string=} title The toast title.
   * @param {string=} body The toast body.
   * @returns {Observable<Toast>}
   *          A hot Observable that can be subscribed to in order to receive the Toast instance
   *          with a randomly generated GUID Id.
   */
  popAsync(type: ToastType | Toast, title?: string, body?: string): Observable<Toast> {
    setTimeout(() => {
      this.pop(type, title, body);
    }, 0);

    return this.addToast$;
  }

  /**
   * Clears a toast by toastId and/or toastContainerId.
   *
   * @param {string} toastId The toastId to clear.
   * @param {number=} toastContainerId
   *        The toastContainerId of the container to remove toasts from.
   */
  clear(toastId?: string, toastContainerId?: number) {
    const clearWrapper: IClearWrapper = {
      toastId: toastId,
      toastContainerId: toastContainerId,
    };

    this._clearToasts.next(clearWrapper);
  }

  removeToastAction(ids: IClearWrapper): void {
    this._removeToastSubject$.next(ids);
  }
}
