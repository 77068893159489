import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ComponentConfiguration, getLastElement, trimDecimalPlaces } from '@demica/core/core';

import { TextTableHeaderComponent } from '../../../data-table/text-table-header.component';
import { PointsListRowComponent } from '../points-list-row/points-list-row.component';

import { getDefaultPoint, getEmptyPoint, isDefaultPoint, pointWithoutValues } from '../../helpers';

import { PointValue } from '../../../../model/pointValue';
import { Actions } from '../../../actions/model/actions.interface';
import { ColumnDefinition } from '../../../data-table/column-definition.interface';
import { DataSource } from '../../../data-table/data-source.interface';

@Component({
  selector: 'trf-points-list',
  templateUrl: './points-list.component.html',
  styleUrls: ['./points-list.component.sass'],
})
export class PointsListComponent implements OnChanges, OnInit {
  @Input()
  points: PointValue[] = [];
  @Input()
  previewMode: boolean;
  @Input()
  versionPreviewMode: boolean;
  @Input()
  formOpened: boolean;
  @Input()
  pointLabel: string;
  @Input()
  numberOfDecimalPlacesForValue: number;
  @Output()
  editClick = new EventEmitter<PointValue>();
  @Output()
  deleteClick = new EventEmitter<PointValue>();

  headerConfig: ComponentConfiguration;
  rowConfig: ComponentConfiguration;
  dataSource: DataSource<PointValue[]> = {
    data: new BehaviorSubject([]),
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.points) this.dataSource.data.next(this.getPoints());
  }

  ngOnInit(): void {
    this.headerConfig = this.configureTableHeader();
    this.rowConfig = this.configureTableRows();
  }

  private configureTableHeader() {
    return {
      component: TextTableHeaderComponent,
      inputs: {
        columns: this.configureTableColumns(),
      },
    };
  }

  private configureTableColumns(): ColumnDefinition[] {
    return [
      {
        nameKey: this.pointLabel,
        property: 'currency',
      },
      {
        nameKey: 'POINTS.TABLE_COLUMN_VALUE',
        property: 'value',
      },
      {
        nameKey: 'POINTS.TABLE_COLUMN_ACTIONS',
        property: 'actions',
        classes: 'actions',
      },
    ];
  }

  private configureTableRows(): ComponentConfiguration {
    const rowActions: Actions = {
      edit: {
        titleKey: 'POINTS.TABLE_ACTION_EDIT',
        handler: (point: PointValue) =>
          this.editClick.emit({
            ...point,
            value: point.value
              ? trimDecimalPlaces(String(point.value), this.numberOfDecimalPlacesForValue)
              : point.value,
          }),
        icon: 'pencil-alt',
        hidden: () => this.previewMode,
      },
      delete: {
        titleKey: 'POINTS.TABLE_ACTION_DELETE',
        handler: (point: PointValue) => this.deleteClick.emit(point),
        icon: 'trash',
        hidden: (point: PointValue) =>
          this.previewMode || isDefaultPoint(point) || pointWithoutValues(point),
      },
    };

    return {
      component: PointsListRowComponent,
      inputs: {
        actions: rowActions,
      },
    };
  }

  private getPoints() {
    if (!this.points?.length) return [getDefaultPoint()];
    this.points.forEach((point) => {
      point.value = trimDecimalPlaces(String(point.value), this.numberOfDecimalPlacesForValue);
    });
    if (getLastElement(this.points).value) return [...this.points, getEmptyPoint()];
    return [...this.points];
  }
}
