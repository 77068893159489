/** Reduction function - merge two objects into one
 * @returns a new object (shallow copy) with properties form both input objects */
// TODO: TRFV2-3891 Refactor to proper types from "any"
// eslint-disable-next-line
export const toSingleObject = (o1: any, o2: any) => Object.assign({}, o1, o2);

/**
 * Higher order function that maps over all properties form an input object, and then returns them in a new object
 *
 * @param obj input object with properties we want to transform
 * @param propertyMapper mapping function that will be applied to all input object properties
 * @returns an object in which all properties form the input obj have been transformed by the given mapping function
 */
export function objectMap<T>(obj: object, propertyMapper: (val: string) => T): T {
  return Object.keys(obj).map(propertyMapper).reduce(toSingleObject, {});
}

// TODO: TRFV2-3891 Refactor to proper types from "any"
// eslint-disable-next-line
type PropertyMapper = ([string, any]: [string, any]) => any;

/**
 * Higher order function that maps over object properties (as key-value pairs) and then returns them as a new object
 *
 * @param obj input object with properties we want to transform
 * @param propertyMapper mapping function that takes key-value pair and returns transformed key-value pair
 */
// TODO: TRFV2-3891 Refactor to proper types from "any"
// eslint-disable-next-line
export function objectEntryMap(obj: any, propertyMapper: PropertyMapper) {
  return Object.entries(obj)
    .map(propertyMapper)
    .map(([key, val]) => ({ [key]: val }))
    .reduce(toSingleObject, {});
}

export function getKeys<T extends object>(o: T): Array<keyof T> {
  return Object.keys(o) as Array<keyof T>;
}

/**
 * Checks if fields are equal, if one field is undefined and other is null it treats them as equal,
 * if fields are objects it compares JSONs
 * if one field is number and the other is string the string is converting to number first
 * @param field1
 * @param field2
 * @returns true if fields are equal
 */
export function fieldEquals(field1: unknown, field2: unknown): boolean {
  if (!field1 && !field2) {
    return true;
  }

  if (typeof field1 === 'number' && typeof field2 === 'string') {
    return field1 === Number(field2);
  }

  if (typeof field2 === 'number' && typeof field1 === 'string') {
    return field2 === Number(field1);
  }

  if (typeof field1 === 'object' && typeof field2 === 'object') {
    return compareObjectSlowly(field1, field2);
  }

  return field1 === field2;
}

/**
 * Function to check length of the object
 * @param object
 * @returns true if object has elements, false is there is nothing inside
 */

export function objectNotEmpty(object: object): boolean {
  return object === Object(object) && !!Object.entries(object).length;
}

const hasProp = Object.prototype.hasOwnProperty;

// eslint-disable-next-line @typescript-eslint/ban-types
export function isEmpty(obj: object): boolean {
  for (const key in obj) {
    if (hasProp.call(obj, key)) {
      return false;
    }
  }

  return true;
}

/**
 * It's slow! Don't use it on large objects!
 * @param object
 */
export function deepClone<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

/**
 * Deep compare two objects. This function is slow and should
 * not be used in critical sections or to compare large objects!
 * Props order matters - so can be easily misleading
 * https://stackoverflow.com/a/1144249
 * @param o1
 * @param o2
 */
export function compareObjectSlowly<T>(o1: T, o2: T): boolean {
  return JSON.stringify(o1) === JSON.stringify(o2);
}

export const compareBySameProperty = <T>(prop: keyof T): ((o1: T, o2: T) => boolean) => {
  return (o1: T, o2: T): boolean => {
    return !!(o1 && o2 && o1[prop] && o2[prop] && o1[prop] === o2[prop]);
  };
};

/**
 Extract property from nested objects using a lambda; return undefined if property not found.
 Warning!!! this function does not throw if the property is not present!
 */
export function pluck<T>(lambda: () => T): T | undefined {
  try {
    return lambda();
  } catch (e) {
    if (e instanceof TypeError) {
      return undefined;
    } else {
      throw e;
    }
  }
}

/**
 * Returns key from an Object according to value put as second param
 * @param object
 * @param value
 */
export function getKeyByValue(object: object, value: unknown): string | undefined {
  return getKeys(object).find((key) => object[key] === value);
}

/**
 * Return nested properties value by properties path string value or array of string
 * @param nestedObj
 * @param pathArr
 */

export function getNestedProperties(nestedObj: unknown, pathArr: string[] | string): unknown {
  pathArr = typeof pathArr == 'string' ? pathArr.split('.') : pathArr;

  return pathArr.reduce(
    (obj, key) =>
      obj && obj[key as keyof typeof obj] !== 'undefined'
        ? obj[key as keyof typeof obj]
        : undefined,
    nestedObj,
  );
}

// https://stackoverflow.com/a/35047888
export function objectsHaveSameKeys(...objects: Record<string, unknown>[]): boolean {
  const allKeys = objects.reduce((keys: string[], object) => {
    return object ? keys.concat(Object.keys(object)) : keys;
  }, []);
  const union = new Set(allKeys);

  if (union.size === 0) {
    return true;
  }

  return objects.every((object) => union.size === Object.keys(object ?? {}).length);
}

const proto = Object.prototype;
const getPrototypeOf = Object.getPrototypeOf;

// Check whether an object is a Plain Javascript Object
export function isPojo(value: unknown): value is Record<string, unknown> {
  if (value === null || typeof value !== 'object') {
    return false;
  }

  return getPrototypeOf(value) === proto;
}
