import { HasEntityId } from '../interface/has-entity-id.interface';

export enum UserDefinitionDataTypeEnum {
  Text = 1,
  AnalysisGroup = 2,
  Amount = 3,
  Number = 4,
  Date = 5,
}

export interface UserDefinitionDataType {
  dateFormat?: string;
  maxLength?: number;
  type: HasEntityId;
}
