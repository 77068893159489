export interface TransactionType {
  entityId: number;
  name: string;
  key: string;
}

export enum TxType {
  StructuredRF = 1, // those correspond to Transaction.Type.entityId
  TransactionLevelFunding = 3,
}
