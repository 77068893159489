import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableAttributesSetterService } from './directive/table-attributes-setter.service';
import { KeyboardNavigationStrategyService } from './service/keyboard-navigation-strategy.service';

import { ArrowKeysFocusDirective } from './directive/arrow-keys-focus.directive';
import { ExpandableTableFocusToRowDirective } from './directive/expandable-table-focus-to-row.directive';
import { ExpandableTableKeyHandlerDirective } from './directive/expandable-table-key-handler.directive';
import { KeyboardAccessibilityDirective } from './directive/keyboard-accessibility.directive';
import { OrdinaryTableKeyHandlerDirective } from './directive/ordinary-table-key-handler.directive';
import { RowKeyHandlerDirective } from './directive/row-key-handler.directive';
import { TableAccessibilityAttributesSetterDirective } from './directive/table-accessibility-attributes-setter.directive';

const exportedDirectives = [
  KeyboardAccessibilityDirective,
  ArrowKeysFocusDirective,
  TableAccessibilityAttributesSetterDirective,
  RowKeyHandlerDirective,
  OrdinaryTableKeyHandlerDirective,
  ExpandableTableFocusToRowDirective,
  ExpandableTableKeyHandlerDirective,
];

@NgModule({
  imports: [CommonModule],
  exports: [...exportedDirectives],
  declarations: [...exportedDirectives],
  providers: [KeyboardNavigationStrategyService, TableAttributesSetterService],
})
export class AccessibilityModule {}
