import { Injectable } from '@angular/core';

import { AuthenticationFlowStore } from '../../auth2/authentication-flow-store.service';
import { HybridFlowTokenStoreService } from '../hybrid-flow-token-store.service';

import { locationRedirect } from '../../../utils/window';
import { KeycloakService } from 'keycloak-angular';

@Injectable({ providedIn: 'root' })
export class LogoutService {
  constructor(
    private _kc: KeycloakService,
    private _flowStore: AuthenticationFlowStore,
    private _hybridFlowStore: HybridFlowTokenStoreService,
  ) {}

  logout(returnUrl: string): void {
    if (!this._flowStore.isUserLoggedInWithCodeFlow()) {
      this._clearToken();
      locationRedirect(returnUrl);
    } else {
      this._clearToken();
      this._kc.logout(returnUrl);
    }
  }

  private _clearToken() {
    this._flowStore.clearFlow();
    this._hybridFlowStore.clearTokens();
  }
}
