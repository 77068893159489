import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlSerializer,
} from '@angular/router';

import { FeatureToggleService } from './feature-toggle.service';
import { MenuService } from './menu.service';
import {
  NOT_AVAILABLE_RESOURCE,
  NotAvailableResourceService,
} from './not-available-resource/not-available-resource.service';

import { MenuItemConfig, MenuOptionType } from '../config/menu-option.interface';
import { TRFRoute, TRFRoutes } from '../interface/route.interface';
import { MenuOption } from '../model/menu-options';
import { User } from '../model/user';

@Injectable()
export class RoutingService {
  constructor(
    private _menuService: MenuService,
    private _urlSerializer: UrlSerializer,
    private _router: Router,
    private _featureToggleService: FeatureToggleService,
    private _notAvailableResourceService: NotAvailableResourceService,
  ) {}

  redirectToNotAvailableResourcePage(fallBackRoute: string): boolean {
    this._router.navigate([NOT_AVAILABLE_RESOURCE], { skipLocationChange: true });

    this._notAvailableResourceService.createPageObject({
      url: fallBackRoute,
      userLacksRequiredAuthority: true,
    });

    return false;
  }

  redirectToValidRouteAccess(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    user: User,
  ): boolean {
    const requestedRoute = route.routeConfig as TRFRoute;

    if (requestedRoute.isNavigableTab) {
      const siblingTab = this._findNavigableSibling(route, user);

      if (siblingTab) this._navigateToSiblingRoute(route, siblingTab.path, state);
      else this._navigateToAuthorisedMenuOption(user);
    } else {
      this._navigateToAuthorisedMenuOption(user);
    }
    return false;
  }

  private _navigateToAuthorisedMenuOption(user: User): void {
    this._menuService.getAuthorisedOptions(user.getAuthorities()).subscribe((options) => {
      if (options && options.length) {
        const link = this._searchNavigableLink(options, 0);
        this._router.navigateByUrl(link.join());
      } else {
        this._router.navigateByUrl('no-access', { skipLocationChange: true });
      }
    });
  }

  private _searchNavigableLink(menuOption: MenuOption[], rootIndex: number): string[] {
    const link = this._searchMenuOptionLink(menuOption[rootIndex]);

    if (!link) {
      this._searchNavigableLink(menuOption, ++rootIndex);
    }

    return link;
  }

  private _searchMenuOptionLink(menuOption: MenuOption): string[] {
    if (menuOption.element.type === MenuOptionType.SECTION) {
      return this._searchMenuOptionLink(menuOption.options.shift());
    }

    if (menuOption.element.type === MenuOptionType.ITEM) {
      const opt = <MenuItemConfig>menuOption.element;
      return opt.link;
    }
  }

  private _findNavigableSibling(route: ActivatedRouteSnapshot, user: User): TRFRoute {
    const siblings = route.parent.routeConfig.children as TRFRoutes;
    const siblingsOfNavigableTabs = this._findOnlyNavigableTabs(siblings);

    return this._firstAccessibleSibling(siblingsOfNavigableTabs, user);
  }

  private _navigateToSiblingRoute(
    route: ActivatedRouteSnapshot,
    routeName: string,
    state: RouterStateSnapshot,
  ): void {
    const routerStateUrlTree = this._urlSerializer.parse(state.url);
    const newSegment = new UrlSegment(routeName, {});
    const segments = routerStateUrlTree.root.children.primary.segments;

    const indexOfRequestedRouteSegment = segments
      .map((segment) => segment.path)
      .indexOf(route.routeConfig.path);
    segments.splice(indexOfRequestedRouteSegment, segments.length, newSegment);

    this._router.navigateByUrl(routerStateUrlTree);
  }

  private _findOnlyNavigableTabs(siblingsConfigs: TRFRoutes): TRFRoutes {
    return siblingsConfigs.filter((siblingsConfig) => siblingsConfig.isNavigableTab);
  }

  private _firstAccessibleSibling(siblings: TRFRoutes, user: User): TRFRoute | undefined {
    return siblings.find((candidateRoute) => {
      const requiredAuthority = candidateRoute.requiredAuthority;

      const authoritySatisfied = requiredAuthority ? user.hasAuthority(requiredAuthority) : true;
      const toggleSatisfied = this._featureToggleService.hasGroupEnabled(
        candidateRoute.requiredFeatureToggleGroup,
      );
      const allToggleSatisfied = this._featureToggleService.hasAllGroupsEnabled(
        candidateRoute.requiredAllFeatureToggleGroup,
      );

      return authoritySatisfied && toggleSatisfied && allToggleSatisfied;
    });
  }
}
