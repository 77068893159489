import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { LocalisationService, Version, WithAbstractUsageRelations } from '@demica/core/core';

import { UsageRelationsService } from '../../service/usage-relations.service';

import { Action, Actions } from '../actions/model/actions.interface';

@Component({
  selector: 'trf-entity-version-header',
  templateUrl: 'entity-version-header.component.html',
  styleUrls: ['entity-version-header.component.sass'],
  providers: [UsageRelationsService],
})
export class EntityVersionHeaderComponent<T extends WithAbstractUsageRelations>
  implements OnInit, OnDestroy
{
  @Input()
  versions: Version[];
  @Input()
  selection: Version;
  @Input()
  actions: Actions<T>;
  @Input()
  actionSubject: T;
  @Input()
  hasAnyUsages: boolean;

  @Output()
  selected: EventEmitter<Version> = new EventEmitter<Version>();

  actionsAsArray: Action<T>[];

  private _destroySubject$ = new Subject<void>();

  constructor(
    private _localisationService: LocalisationService,
    private _translate: TranslateService,
    private _usageRelationsService: UsageRelationsService<T>,
  ) {}

  ngOnInit(): void {
    this._localisationService.locale
      .pipe(filter(this._hasVersionsDefined), takeUntil(this._destroySubject$))
      .subscribe(() => {
        this.versions[0].name = this._translate.instant('VERSIONS.CURRENT_VERSION_DEFAULT_NAME');
        this.versions = [...(this.versions || [])];
      });

    this.buildActions();
  }

  ngOnDestroy(): void {
    this._destroySubject$.next();
    this._destroySubject$.complete();
  }

  onSelected(version: Version): void {
    this.selection = version;
    this.selected.emit(version);
  }

  buildActions(): void {
    this.actionsAsArray = this._usageRelationsService.buildActionsWithMessages(
      this.actions,
      this.actionSubject,
      this.hasAnyUsages,
    );
  }

  private _hasVersionsDefined: () => boolean = () =>
    this.versions !== undefined && !!this.versions.length;
}
