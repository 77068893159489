import { ExtendedConnectedPosition, NgpTooltipPosition } from '@demica/ui-core-api';

export function buildPositions(
  offset: number,
): Record<NgpTooltipPosition, ExtendedConnectedPosition> {
  return {
    top: {
      position: NgpTooltipPosition.top,
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -1 * offset,
    },
    bottom: {
      position: NgpTooltipPosition.bottom,
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: offset,
    },
    left: {
      position: NgpTooltipPosition.left,
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -1 * offset,
    },
    right: {
      position: NgpTooltipPosition.right,
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: offset,
    },
  };
}
