import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'trf-search-row',
  templateUrl: './search-row.component.html',
  styleUrls: ['./search-row.component.sass'],
})
export class SearchRowComponent {
  @Input()
  placeholder?: string;

  @Output()
  search = new EventEmitter<string>();

  inputChange(event: string) {
    this.search.emit(event);
  }
}
