import { AxisYNumbers } from './axis-y-numbers';
import BigNumber from 'bignumber.js';
import { YAxisConfiguration } from 'c3';

export class AxisYPercentage extends AxisYNumbers {
  constructor(label: string, private tickCount?: number) {
    super(label);
  }

  getAxisYConfiguration(): YAxisConfiguration {
    const numberFormat = this.numberFormat;
    return {
      show: this.show,
      tick: {
        count: this.tickCount,
        format(x: number): string {
          return new BigNumber(x).toFormat(numberFormat.decimalPlaces, {
            decimalSeparator: numberFormat.decimalSeparator,
            suffix: '%',
          });
        },
      },
      label: {
        position: 'outer-middle',
        text: this.label,
      },
    };
  }
}
