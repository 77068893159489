import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { NOT_AVAILABLE_RESOURCE, ORGANIZATION_UNITS, getLocationPath } from '@demica/core/core';

@Component({
  selector: 'trf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output()
  arrowClicked = new EventEmitter<void>();

  sidebarCollapsed = false;
  currentViewUrlSegment: string;

  private destroy$ = new Subject<void>();
  ORGANIZATION_UNITS = ORGANIZATION_UNITS;

  constructor(private router: Router) {}

  ngOnInit() {
    const extractTitle = (url: string) => {
      url = url === `/${NOT_AVAILABLE_RESOURCE}` ? getLocationPath() : url;
      return url.split('/')[1].toLocaleUpperCase();
    };
    this.currentViewUrlSegment = extractTitle(this.router.url);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => extractTitle(this.router.url)),
      )
      .subscribe((value) => (this.currentViewUrlSegment = value));
  }

  onArrowClick() {
    this.arrowClicked.emit();
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
