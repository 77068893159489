import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
  AliasesResourceService,
  AliasMissingMapping,
  AnalysisGroupResource,
  ComponentConfiguration,
  createSortParams,
  DictionaryEntry,
  Environment,
  EnvironmentResourceService,
  MissingMappingSearchParams,
  NotificationService,
  Order,
  PageRequest,
  PageResponse,
  SlideinService,
  SortDirection,
  SortParams,
  Transaction,
  TransactionBaseResource,
  TransactionDataSource,
  TransactionEnvironmentMapping,
  TransactionResourceService,
} from '@demica/core/core';

import { ModalService } from '../../../../service/modal.service';

import {
  AliasMissingSearchComponent,
  MissingMappingSearchFormValue,
} from '../alias-missing-search/alias-missing-search.component';

import { DataSource } from '../../../data-table/data-source.interface';
import { DataTableSelection } from '../../../data-table/data-table-selection.interface';
import { AliasesMissingSlideinContainerComponent } from '../aliases-missing-slidein/aliases-missing-slidein.container';
import { setupHeaderConfig, setupRowConfig } from './aliases-missing-table-config';
import {
  aliasToAdd,
  aliasToRemove,
  defaultSearchParams,
  searchFormToSearchParams,
} from './aliases-missing.utils';

@Component({
  selector: 'trf-aliases-missing',
  templateUrl: 'aliases-missing.component.html',
  styleUrls: ['aliases-missing.component.sass'],
})
export class AliasesMissingComponent implements DataTableSelection, OnInit, OnChanges, OnDestroy {
  @ViewChild('aliasesSearch')
  aliasesSearch: AliasMissingSearchComponent;

  @Input()
  transaction: Transaction;

  @Input()
  versionPreviewMode: boolean;

  @Input()
  previewMode: boolean;

  columnIdsToTranslate = [2, 17];
  isEmpty = false;
  loading = false;
  loadingFilters = false;
  filtersChanged = false;
  showButtons = false;
  dataLoaded = false;
  showPages = false;

  transactions: TransactionBaseResource[] = [];
  dataSources: TransactionDataSource[] = [];
  analysisGroups: AnalysisGroupResource[] = [];
  environments: Environment[];
  environmentsMapping: TransactionEnvironmentMapping[];
  allSelected = false;
  indeterminateSelected = false;
  enableButtons = false;
  enableAllButtons = false;

  hasSelectedTransaction = false;

  searchParams: MissingMappingSearchParams;

  aliasMissingMappings: AliasMissingMapping[];
  dataSource: DataSource<AliasMissingMapping[]> = {
    data: new BehaviorSubject([]),
  };

  headerConfig: ComponentConfiguration;
  rowConfig: ComponentConfiguration;
  pageResponse: PageResponse;

  private _pageRequest: PageRequest = {
    pageNumber: 0,
    size: 25,
    sort: [
      createSortParams({
        sortColumn: 'firstAppearanceDate',
        sortDirection: SortDirection.DESC,
      }),
    ],
  };
  private _destroyed$ = new Subject<void>();

  constructor(
    private _transactionResourceService: TransactionResourceService,
    private _environmentResourceService: EnvironmentResourceService,
    private _slideinService: SlideinService,
    private _aliasesResourceService: AliasesResourceService,
    private _modalService: ModalService,
    private _notifications: NotificationService,
  ) {}

  ngOnInit(): void {
    this.headerConfig = setupHeaderConfig(this);
    this.rowConfig = setupRowConfig(this);
  }

  ngOnChanges(): void {
    this.searchParams = defaultSearchParams(this.transaction?.entityId);
    this._loadData();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  performSearch(): void {
    this.aliasMissingMappings = [];
    this._resolvePage(0);
  }

  onSortChanged(params: SortParams): void {
    this._pageRequest.sort = [
      createSortParams({
        sortColumn: params.property,
        sortDirection: params.order === Order.ASC ? SortDirection.ASC : SortDirection.DESC,
      }),
    ];
    this._resolvePage(0);
  }

  onSearch(event: MissingMappingSearchFormValue): void {
    const transactionIds = [];
    if (this.transaction) {
      transactionIds.push(this.transaction.entityId);
    } else if (event.transaction) {
      transactionIds.push(event.transaction.entityId);
    } else {
      this.hasSelectedTransaction = false;
      transactionIds.push(...this.transactions.map((transaction) => transaction.entityId));
    }
    this.hasSelectedTransaction = !!this.transaction || !!event.transaction;
    this.searchParams = searchFormToSearchParams(transactionIds, event);
    this.performSearch();
  }

  onFilterChange(): void {
    this.filtersChanged = true;
    this._checkEnableAllButtons();
  }

  onClear(): void {
    this.dataSource.data.next([]);
    this.dataLoaded = false;
    this.showButtons = false;
  }

  onAddMapping(aliasMissingMapping: AliasMissingMapping): void {
    const proposedAlias = aliasToAdd(
      aliasMissingMapping.transaction.entityId,
      aliasMissingMapping,
      aliasMissingMapping.value,
    );
    this._aliasesResourceService
      .addMappings({ proposedAliases: [proposedAlias] })
      .subscribe(() => this._successHandler(1));
  }

  onAddMappings(): void {
    if (this.enableButtons) {
      const selectedAliases = this.aliasMissingMappings.filter((item) => item.selected);
      const selectedAliasesEligibleToAdd = selectedAliases.filter(
        (alias) => alias.canBeAnalysisCodeName,
      );
      const hasNotEligibleToAddAliases =
        selectedAliases.length > selectedAliasesEligibleToAdd.length;
      if (hasNotEligibleToAddAliases) {
        this._modalService
          .openConfirmationModal(
            'ALIASES.NOT_ELIGIBLE_TO_ADD_ALIASES_MESSAGE',
            'ALIASES.NOT_ELIGIBLE_TO_ADD_ALIASES_ACTION',
            this._addMappings(selectedAliasesEligibleToAdd),
          )
          .onSuccess(() => this.performSearch())
          .onError(() => this._clearSearchFormAndResults());
      } else {
        this._addMappings(selectedAliasesEligibleToAdd).subscribe(() =>
          this._successHandler(selectedAliasesEligibleToAdd.length),
        );
      }
    }
  }

  onAddAllMappings(): void {
    if (!this.enableAllButtons) return;
    this._modalService
      .openConfirmationModal(
        'ALIASES.ADD_ALL_MISSING_MAPPING_MESSAGE',
        'ALIASES.ADD_MISSING_MAPPING_ACTION',
        this._addAllMappings(this.searchParams),
      )
      .onSuccess(() => {
        this._notifications.success('ALIASES.STARTED_ALIASES_PROCESSING');
        this.performSearch();
      })
      .onError(() => this._clearSearchFormAndResults());
  }

  onRemoveMapping(aliasMissingMapping: AliasMissingMapping): void {
    this._modalService
      .openConfirmationModal(
        'ALIASES.REJECT_MISSING_MAPPING_MESSAGE',
        'ALIASES.REJECT_MISSING_MAPPING_ACTION',
        this._removeMapping(aliasMissingMapping),
      )
      .onSuccess(() => this.performSearch())
      .onError(() => this._clearSearchFormAndResults());
  }

  onRemoveMappings(): void {
    if (this.enableButtons) {
      this._modalService
        .openConfirmationModal(
          'ALIASES.REJECT_MISSING_MAPPING_MESSAGE',
          'ALIASES.REJECT_MISSING_MAPPING_ACTION',
          this._removeMappings(),
        )
        .onSuccess(() => this.performSearch())
        .onError(() => this._clearSearchFormAndResults());
    }
  }

  onRejectAllMappings(): void {
    if (!this.enableAllButtons) return;
    this._modalService
      .openConfirmationModal(
        'ALIASES.REJECT_ALL_MISSING_MAPPING_MESSAGE',
        'ALIASES.REJECT_MISSING_MAPPING_ACTION',
        this._removeAllMappings(this.searchParams),
      )
      .onSuccess(() => {
        this._notifications.success('ALIASES.STARTED_ALIASES_PROCESSING');
        this.performSearch();
      })
      .onError(() => this._clearSearchFormAndResults());
  }

  onMapAliasValue(aliasMissingMapping: AliasMissingMapping): void {
    const slideInRef = this._slideinService.openSlideIn(AliasesMissingSlideinContainerComponent);

    slideInRef.onSuccess = this._slideInSuccessHandler;
    slideInRef.onError = this._slideInErrorHandler;
    slideInRef.instance.aliasMissingMapping = aliasMissingMapping;
    slideInRef.instance.transactionId = aliasMissingMapping.transaction.entityId;
    slideInRef.instance.previewMode = this.previewMode || this.versionPreviewMode;
  }

  onPageChange(pageNumber: number): void {
    this._resolvePage(pageNumber);
  }

  onSelectAllCheckboxChanged(changed: boolean): void {
    this.indeterminateSelected = false;
    this._setAllSelected(changed);
    this._checkEnableButtons();
  }

  onRowCheckboxChanged(): void {
    this.allSelected = this.isAllSelected();
    this.indeterminateSelected = this.isIndeterminateSelected();
    this._checkEnableButtons();
  }

  isAllSelected(): boolean {
    return this.aliasMissingMappings.every((item) => item.selected);
  }

  isIndeterminateSelected(): boolean {
    return (
      this.aliasMissingMappings.some((item) => item.selected) &&
      !this.aliasMissingMappings.every((item) => item.selected)
    );
  }

  private _resolvePage(pageNumber: number): void {
    this.allSelected = false;
    this.loading = true;
    this._pageRequest.pageNumber = pageNumber;
    this._aliasesResourceService
      .getMissingMappingsPageable(this._pageRequest, this.searchParams)
      .subscribe((value) => {
        this.aliasMissingMappings = value.data;

        this.aliasMissingMappings.forEach((missing) => {
          this._addKeyToTranslatedAnalysisGroup(missing.analysisGroup);
        });

        this.dataSource.data.next(this.aliasMissingMappings);
        this.pageResponse = value.meta.pagination;
        this.loading = false;
        this.dataLoaded = true;
        this.isEmpty = this.aliasMissingMappings.length === 0;
        this.showButtons = !this.previewMode && !this.isEmpty;
        this.showPages = !this.isEmpty;
        this.filtersChanged = false;
        this._checkEnableButtons();
        this._checkEnableAllButtons();
      });
  }

  private _addKeys(analysisGroups: AnalysisGroupResource[]): AnalysisGroupResource[] {
    analysisGroups.forEach((analysisGroup) => {
      this._addKeyToTranslatedAnalysisGroup(analysisGroup);
    });
    return analysisGroups;
  }

  private _addMappings(selectedAliases: AliasMissingMapping[]) {
    const aliasesToAdd = selectedAliases.map((alias) =>
      aliasToAdd(alias.transaction.entityId, alias, alias.value),
    );
    return this._aliasesResourceService.addMappings({ proposedAliases: aliasesToAdd });
  }

  private _addAllMappings(searchParams: MissingMappingSearchParams) {
    return this._aliasesResourceService.addMappingsBySearch(searchParams);
  }

  private _removeMapping(aliasMissingMapping: AliasMissingMapping) {
    const rejectedAlias = aliasToRemove(
      aliasMissingMapping.transaction.entityId,
      aliasMissingMapping,
    );
    return this._aliasesResourceService.rejectMappings({ missingMappings: [rejectedAlias] });
  }

  private _removeMappings() {
    const selectedAliases = this.aliasMissingMappings.filter((item) => item.selected);
    const aliasesToRemove = selectedAliases.map((alias) =>
      aliasToRemove(alias.transaction.entityId, alias),
    );
    return this._aliasesResourceService.rejectMappings({ missingMappings: aliasesToRemove });
  }

  private _removeAllMappings(searchParams: MissingMappingSearchParams) {
    return this._aliasesResourceService.rejectMappingsBySearch(searchParams);
  }

  private _clearSearchFormAndResults(): void {
    this.aliasesSearch.onClear();
  }

  private _addKeyToTranslatedAnalysisGroup(analysisGroup: DictionaryEntry): void {
    if (this.columnIdsToTranslate.includes(Number(analysisGroup.entityId))) {
      analysisGroup.key = String(analysisGroup.entityId);
    }
  }

  private _successHandler(elementsChange: number): void {
    const pageToGet =
      elementsChange === this.pageResponse.numberOfElements
        ? this.pageResponse.pageNumber - 1
        : this.pageResponse.pageNumber;
    this._resolvePage(pageToGet);
  }

  private _slideInSuccessHandler = (): void => {
    this.performSearch();
    this._successHandler(1);
  };

  private _slideInErrorHandler = (): void => {
    this.performSearch();
  };

  private _setAllSelected(value: boolean): void {
    this.allSelected = value;
    this.aliasMissingMappings.forEach((item) => (item.selected = value));
  }

  private _checkEnableButtons(): void {
    this.enableButtons = this.aliasMissingMappings.filter((item) => item.selected).length > 0;
  }

  private _checkEnableAllButtons(): void {
    this.enableAllButtons =
      this.aliasMissingMappings &&
      this.hasSelectedTransaction &&
      !!this.searchParams.analysisGroupName &&
      !this.filtersChanged;
  }

  private _loadData(): void {
    this.loadingFilters = true;

    const transactions$: Observable<TransactionBaseResource[]> = this.transaction
      ? of([])
      : this._transactionResourceService.getTransactionsResource();

    transactions$
      .pipe(
        tap((transactions) => (this.transactions = transactions)),
        map((transactions) => transactions.map((transaction) => transaction.entityId)),
        switchMap((transactionIds) =>
          forkJoin([
            this.transaction
              ? this._transactionResourceService.getDataSources(
                  this.transaction.entityId,
                  this.transaction.entityRevision,
                  this.versionPreviewMode,
                )
              : this._transactionResourceService.getDataSourcesForTransactions(transactionIds),

            this.transaction
              ? this._transactionResourceService.getAnalysisGroups(
                  this.transaction.entityId,
                  this.transaction.entityRevision,
                  this.versionPreviewMode,
                )
              : this._transactionResourceService.getAnalysisGroupsForTransactions(transactionIds),

            this.transaction
              ? this._environmentResourceService.getAllAssignedTransactionEnvironmentsForGivenUser(
                  this.transaction.entityId,
                )
              : this._environmentResourceService.getAssignedTransactionEnvironments(transactionIds),

            this._environmentResourceService.getAssignedTransactionEnvironmentsMapping(
              this.transaction ? [this.transaction.entityId] : transactionIds,
            ),
          ]),
        ),
      )
      .subscribe(([dataSources, analysisGroups, environments, environmentsMapping]) => {
        this.dataSources = dataSources;
        this.analysisGroups = this._addKeys(analysisGroups);
        this.environments = environments;
        this.environmentsMapping = environmentsMapping;
        this.loadingFilters = false;
      });
  }
}
