<div
  class="trf-application-page"
  role="application"
  testid="trf-application-page"
>
  <trf-global-notification testid="toast"></trf-global-notification>
  <trf-user-auth-context-change></trf-user-auth-context-change>
  <ngp-inline-loader [loading]="loading"></ngp-inline-loader>

  <div
    *ngIf="!loading"
    class="trf-application-page-content"
  >
    <trf-modal-loader
      message="MODAL_LOADER.LOADING_ROUTE"
      [loadingData]="loadingRoute"
    ></trf-modal-loader>

    <router-outlet></router-outlet>
  </div>
</div>

<trf-responsive-ui-block></trf-responsive-ui-block>
