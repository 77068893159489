import { DragDrop, DropListRef, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentRef, Injectable } from '@angular/core';

import { DataSource } from './data-source.interface';

/**
 * *Default DataTable drag and drop strategy*
 *
 * **Usage:** Add this service to the providers array of the component that
 * holds the DataSource and uses trf-data-table in it's template.
 *
 * **Note:** This service is stateful!
 *
 * TODO: Extend this class to handle pagination, column sort, etc.
 */
@Injectable()
export class TableDragDrop<T> {
  private draggableList: DropListRef;
  private tableData: T[];

  constructor(private dragDrop: DragDrop) {}

  registerTable(tableBody: HTMLElement, dataSource: DataSource<T[]>) {
    this.draggableList = this.dragDrop.createDropList(tableBody);
    this.draggableList.disabled = false;

    this.draggableList.dropped.subscribe((array) => {
      moveItemInArray(this.tableData, array.previousIndex, array.currentIndex);
      dataSource.data.next(this.tableData);
    });
  }

  registerRows(rowsData: T[], rowsComponentRefs: ComponentRef<unknown>[]) {
    this.tableData = rowsData;

    const draggableItemsRefs = rowsComponentRefs
      .map((row) => row.location)
      .map((location) => this.dragDrop.createDrag(location));

    this.draggableList.withItems(draggableItemsRefs);
  }
}
