import { Injectable, NgZone } from '@angular/core';

import { Observable, Subject, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenExpirationTimerService {
  static TOKEN_EXPIRATION_OFFSET = 10 * 1000; // 10sec
  aboutToExpire$: Observable<boolean>;

  private _timeOutInMilliseconds: number;
  private _expiredSubject$ = new Subject<boolean>();
  private _timerReset$ = new Subject<void>();
  timeout: number;

  constructor(private _ngZone: NgZone) {
    this.aboutToExpire$ = this._expiredSubject$.asObservable();
    this._setupTimer();
  }

  scheduleTokenExpiration(timeAtWhichTokenWillBeExpired: number): this {
    const now = Date.now();
    this._timeOutInMilliseconds = timeAtWhichTokenWillBeExpired * 1000 - now;

    this._restartTimer();
    return this;
  }

  private _setupTimer(): void {
    this._timerReset$
      .pipe(
        switchMap(() =>
          timer(
            this._timeOutInMilliseconds - AccessTokenExpirationTimerService.TOKEN_EXPIRATION_OFFSET,
          ),
        ),
      )
      .subscribe(() => {
        this._signalTimeout();
      });
  }

  private _restartTimer(): void {
    this._ngZone.runOutsideAngular(() => {
      this._timerReset$.next();
    });
  }

  private _signalTimeout(): void {
    this._expiredSubject$.next(true);
  }
}
