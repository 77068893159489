import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { noop } from 'rxjs';

import { DecimalValuePipe } from '../pipe/decimal-value.pipe';

@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DecimalValueAccessorDirective,
      multi: true,
    },
  ],
})
export class DecimalValueAccessorDirective implements ControlValueAccessor {
  @HostListener('blur')
  _onTouched: () => void = noop;

  @HostListener('input', ['$event.target.value'])
  _onChange: (_: string) => void = noop;

  private _nativeElement: HTMLInputElement;

  constructor(
    private _renderer2: Renderer2,
    private _elRef: ElementRef,
    private _decimalValuePipe: DecimalValuePipe,
  ) {
    this._nativeElement = this._elRef.nativeElement;
  }

  writeValue(value: string): void {
    const formatted: string = this._decimalValuePipe.transform(value);
    this._setProperty(formatted);
  }

  registerOnChange(fn: (_: string | null) => void): void {
    this._onChange = (value) => {
      fn(this._decimalValuePipe.normalizeValue(value));
    };
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  private _setProperty(value: string): void {
    this._renderer2.setProperty(this._nativeElement, 'value', value);
  }
}
