import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { LocalisationService, NotificationService } from '@demica/core/core';
import { ToasterConfig } from '@demica/toaster';

import { NOTIFICATION_HANDLER, NotificationHandler } from './notification-handler.interface';
import { ToastrNotificationHandler } from './toastr-notification.handler';

const NOTIFICATION_TIMEOUT = 5000;
const NOTIFICATION_TIMEOUT_NONE = 0;

@Component({
  selector: 'trf-global-notification',
  templateUrl: './global-notification.component.html',
  providers: [{ provide: NOTIFICATION_HANDLER, useClass: ToastrNotificationHandler }],
})
export class GlobalNotificationComponent implements OnInit, OnDestroy {
  toasterConfig = this.createConfig();

  private destroyed$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private localeService: LocalisationService,
    @Inject(NOTIFICATION_HANDLER) private notificationHandler: NotificationHandler,
  ) {}

  ngOnInit(): void {
    this.initNotificationHandler();
    this.initOnClearHandler();
    this.subscribeToLanguageChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private createConfig() {
    const ariaLabel = this.translateService.instant('INFO_MODAL.CLOSE');

    return new ToasterConfig({
      showCloseButton: true,
      tapToDismiss: true,
      mouseoverTimerStop: true,
      animation: 'fade',
      timeout: {
        error: NOTIFICATION_TIMEOUT_NONE,
        success: NOTIFICATION_TIMEOUT,
        warning: NOTIFICATION_TIMEOUT,
      },
      closeHtml: `<span>x</span>`,
      closeButtonAriaLabel: ariaLabel,
    });
  }

  private initOnClearHandler() {
    this.notificationService.notificationClearAll$.subscribe(() =>
      this.notificationHandler.onClearAll(),
    );
  }

  private initNotificationHandler() {
    this.notificationService.notificationQueue$.subscribe((notification) =>
      this.notificationHandler.onNotification(notification),
    );
  }

  private subscribeToLanguageChanges() {
    this.localeService.locale
      .pipe(
        tap(() => (this.toasterConfig = this.createConfig())),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
