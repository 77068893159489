<a
  #link
  routerLinkActive="active"
  [routerLink]="(menuOption.element | menuItemCast).link"
  [attr.aria-current]="link.classList.contains('active')"
  [attr.aria-label]="(menuOption.element | menuItemCast).titleKey | translate"
  class="menu-item"
>
  <ngp-svg-icon
    [iconName]="(menuOption.element | menuItemCast).icon"
    title="{{ (menuOption.element | menuItemCast).titleKey | translate }}"
  ></ngp-svg-icon>
  <div>
    <span>{{ (menuOption.element | menuItemCast).titleKey | translate }}</span>
    <span *ngIf="menuOption.id === 'alerts-option' && notificationCount > 0">
      (&rlm;{{ notificationCount }})</span
    >
  </div>
</a>
