import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { KeepAliveBroadcastClientService } from './broadcast-client/keep-alive-broadcast-client.service';
import { IntentionalKeepAliveEventsSourceService } from './events-source/intentional/intentional-events-source.service';
import { OtherInstancesEventsSourceService } from './events-source/other-instances/other-instances-events-source.service';
import { UserActivitiesEventsSourceService } from './events-source/user-activities/user-activities-events-source.service';
import { KeepAliveSessionInitializerService } from './initializer/keep-alive-session-initializer.service';
import { KeepAliveSessionService } from './keep-alive-session.service';

import { BROADCAST_AUTOWIRED_CLIENT } from '../broadcast-channel/broadcast-channel.types';
import { KeepAliveSessionInterceptor } from './interceptor/keep-alive-session.interceptor';
import { KEEP_ALIVE_SESSION_EVENTS_SOURCE } from './keep-alive-session.types';

@NgModule({
  providers: [
    KeepAliveSessionInitializerService,
    KeepAliveSessionService,
    {
      provide: BROADCAST_AUTOWIRED_CLIENT,
      multi: true,
      useExisting: KeepAliveBroadcastClientService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeepAliveSessionInterceptor,
      multi: true,
    },
    {
      provide: KEEP_ALIVE_SESSION_EVENTS_SOURCE,
      multi: true,
      useExisting: IntentionalKeepAliveEventsSourceService,
    },
    {
      provide: KEEP_ALIVE_SESSION_EVENTS_SOURCE,
      multi: true,
      useExisting: UserActivitiesEventsSourceService,
    },
    {
      provide: KEEP_ALIVE_SESSION_EVENTS_SOURCE,
      multi: true,
      useExisting: OtherInstancesEventsSourceService,
    },
  ],
})
export class KeepAliveSessionModule {}
