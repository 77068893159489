import { AxisY } from './axis-y';
import { AxisYNumberFormat } from './axis-y-number-format';
import BigNumber from 'bignumber.js';
import { YAxisConfiguration } from 'c3';

export class AxisYNumbers extends AxisY {
  protected numberFormat: AxisYNumberFormat = {
    decimalPlaces: 2,
    decimalSeparator: '.',
  };

  protected tooltipFormat: AxisYNumberFormat = this.numberFormat;

  constructor(label: string) {
    super(label);
  }

  setNumberFormat(numberFormat: AxisYNumberFormat) {
    this.numberFormat = numberFormat;
    return this;
  }

  getNumberFormat() {
    return this.numberFormat;
  }

  setTooltipFormat(tooltipFormat: AxisYNumberFormat) {
    this.tooltipFormat = tooltipFormat;
  }

  getTooltipFormat() {
    return this.tooltipFormat;
  }

  getAxisYConfiguration() {
    const numberFormat = this.numberFormat;
    return {
      show: this.show,
      max: this.max,
      tick: {
        format(x: number): string {
          if (x % 1 === 0 || numberFormat.decimalPlaces > 0)
            return x !== 0
              ? new BigNumber(x).toFormat(numberFormat.decimalPlaces, {
                  decimalSeparator: numberFormat.decimalSeparator,
                })
              : '0';
          else return '';
        },
      },
      label: {
        position: 'outer-middle',
        text: this.label,
      },
    } as YAxisConfiguration;
  }
}
