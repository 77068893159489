import { Pipe, PipeTransform } from '@angular/core';

import { MenuItemConfig } from '@demica/core/core';

@Pipe({
  name: 'menuItemCast',
  pure: true,
})
export class MenuItemCastPipe implements PipeTransform {
  transform(value: unknown): MenuItemConfig {
    return <MenuItemConfig>value;
  }
}
