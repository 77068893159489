import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { EntityId } from '@demica/resources/common';
import { FeeKind, FeeType, FeeTypesResourcesService } from '@demica/resources/fees';

import { RuntimeTranslationsKeysService } from '../../../../service/translation/runtime-translations-keys/runtime-translations-keys.service';

import { feeTypeAsDictionaryItem } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class FeeTypeDictionaryService {
  constructor(
    private translationService: TranslateService,
    private runtimeTranslationsKeysService: RuntimeTranslationsKeysService,
    private feeTypesResourcesService: FeeTypesResourcesService,
  ) {}

  getFeeTypes(transactionTypes: EntityId) {
    const fees$ = this.feeTypesResourcesService.getFeeTypes$(transactionTypes);

    return fees$.pipe(
      mergeMap((fee) => fee),
      map((fees) => feeTypeAsDictionaryItem(fees)),
      mergeMap((fee) => this.updateTranslations(fee)),
      toArray(),
    );
  }

  getFeeTypesOfKind(transactionType: EntityId, feeKind: FeeKind): Observable<FeeType[]> {
    return this.getFeeTypes(transactionType).pipe(
      map((feeTypes) => feeTypes.filter((feeType) => feeType.feeKinds.includes(feeKind))),
    );
  }

  private updateTranslations(feeType: FeeType) {
    return this.translationService.get(feeType.translationKey).pipe(
      tap((translation) => {
        if (translation === feeType.translationKey) {
          this.runtimeTranslationsKeysService.setKey(
            feeType.translationKey,
            feeType.defaultTranslation,
          );
        }
      }),
      map(() => feeType),
    );
  }
}
