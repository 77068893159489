import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './authorization-token-change-modal.component.html',
  styleUrls: ['./authorization-token-change-modal.component.sass'],
})
export class AuthorizationTokenChangeModalComponent {
  message: string;

  constructor(private modalRef: NgbActiveModal) {}

  onClick() {
    this.modalRef.close();
  }
}
