import { Component, Input, TemplateRef } from '@angular/core';

import { Notifications } from '../notifications';

@Component({
  selector: 'ngp-contextual-notifications',
  templateUrl: './contextual-notifications.component.html',
  styleUrls: ['./contextual-notifications.component.sass'],
})
export class ContextualNotificationsComponent {
  @Input()
  notifications?: Notifications;
  @Input()
  type?: 'warning' | 'error' | 'info' | 'success';
  @Input()
  expandable = false;
  @Input()
  expandableHeader!: string | TemplateRef<any>;
}
