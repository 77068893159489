export interface DynamicFiltersConfiguration {
  transaction?: boolean;
  environment?: boolean;
  client?: boolean;
  seller?: boolean;
  assetBase?: boolean;
}

export const DEFAULT_DYNAMIC_FILTERS_CONFIGURATION: DynamicFiltersConfiguration = {
  client: true,
  environment: true,
  seller: true,
  transaction: true,
  assetBase: true,
};
