<a
  routerLinkActive="active"
  [routerLink]="(menuOption.element | menuItemCast).link"
>
  <ngp-svg-icon
    [iconName]="(menuOption.element | menuItemCast).icon"
    title="{{ (menuOption.element | menuItemCast).titleKey | translate }}"
  ></ngp-svg-icon>
  <span>{{ (menuOption.element | menuItemCast).titleKey | translate }}</span>
</a>
