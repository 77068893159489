import {
  FormatWidth,
  getLocaleDateFormat,
  getLocaleDateTimeFormat,
  getLocaleDirection,
  getLocaleId,
  getLocaleTimeFormat,
} from '@angular/common';
import { Injectable, InjectionToken } from '@angular/core';

import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { AppConfig } from '@demica/resources/app-config';

import { AppConfigService } from '../app-config/app-config.service';
import { getLangPrefixFromLocale } from '../translation/fake-translation-generator/fake-translation-generator.service';
import { UserPreferences } from './../user-preferences/user-preferences.service';

import { setDir } from '../../utils/window';
import { Locale } from './../../config/locale.enum';
import { LocaleLoader } from './loaders/locale-loader.interface';
import { LocaleRegistry } from './registry/locale-registry.class';

const MERIDIAN_SYMBOL = 'a';
export const DATE_TIME_PLACEHOLDER_TRANSLATIONS_PREFIX = 'LOCALES_DATE_TIME_FORMATS';

export interface LocaleFormats {
  format: string;
  formatLocalized: string;
}

export const LOCALE_LOADER = new InjectionToken('Locale loader');
@Injectable()
export class LocalisationService {
  /** This observable will emit when locale/language is changed!
   * Use first() operator if you only want the current value.
   * Failing to use first(), takeUntil() or unsubscribing will cause a memory-leak! */
  locale: Observable<Locale>;

  private _locale$: Subject<Locale> = new ReplaySubject(1);
  private _currentLocale: Locale;

  static registerSupportedLocalesData(
    localeLoader: LocaleLoader,
    appConfig?: AppConfig,
  ): Promise<string[]> {
    return LocaleRegistry.registerSupportedLocalesData(localeLoader, appConfig);
  }

  // \u200f is a rtl special characters
  private readonly _removeEverythingButSeparatorRegExp = new RegExp('([^dMy\u200F])', 'ui');
  private _separatorsCache = new Map<string, string>();
  private _mockedLocales: string[] = [];

  constructor(
    private _translate: TranslateService,
    private _userPreferences: UserPreferences,
    private _appConfig: AppConfigService,
  ) {
    this.locale = this._locale$.asObservable();

    this._appConfig
      .getMockedLocalisations$()
      .pipe(first())
      .subscribe((mockedLocales) => {
        this._mockedLocales = mockedLocales;
      });

    this._userPreferences.locale.subscribe((userLocale: Locale) => {
      let locale = Locale.EN_GB;

      if (userLocale) {
        locale = userLocale;
      }

      this._translate.use(locale).subscribe(() => {
        this._currentLocale = locale;
        this.setLocale(locale);
        setDir(getLocaleDirection(locale));
      });
    });
  }

  setLocale(locale: Locale): void {
    this._locale$.next(locale);
  }

  getDateSeparator(locale: string) {
    const valueFromCache = this._separatorsCache.has(locale);

    if (!valueFromCache) {
      const dateFormat = this.getLocaleDateFormat(locale, FormatWidth.Short);
      this._separatorsCache.set(
        locale,
        this._removeEverythingButSeparatorRegExp.exec(dateFormat)[0] ?? '',
      );
    }

    return this._separatorsCache.get(locale);
  }

  getLocaleId(locale: string): string {
    return getLocaleId(locale);
  }

  getLocaleDateFormat(locale: string, formatWidth: FormatWidth): string {
    return getLocaleDateFormat(locale, formatWidth);
  }

  getLocaleDateFormatLocalised(locale: string, formatWidth: FormatWidth): string {
    return this._getTranslationForFormat(this.getLocaleDateFormat(locale, formatWidth), locale);
  }

  getLocaleTimeFormat(locale: string, formatWidth: FormatWidth): string {
    return getLocaleTimeFormat(locale, formatWidth);
  }

  getLocaleTimeFormatLocalised(locale: string, formatWidth: FormatWidth): string {
    return this._getTranslationForFormat(this.getLocaleTimeFormat(locale, formatWidth), locale);
  }

  getLocaleDateTimeFormat(locale: string, formatWidth: FormatWidth): string {
    // Comment:
    // Angular locales definitions contain
    // interpolations in form {\d}
    // we are force to get the raw state with interpolations
    // and do the magic by our own;
    // Angular uses internally it's own implementation
    // see: https://github.com/angular/angular/blob/16.1.5/packages/common/src/i18n/format_date.ts#L201
    const rawDateTimeFormatWithInterpolations = getLocaleDateTimeFormat(locale, formatWidth);

    return rawDateTimeFormatWithInterpolations
      .replace('{1}', this.getLocaleDateFormat(locale, formatWidth))
      .replace('{0}', this.getLocaleTimeFormat(locale, formatWidth));
  }

  getLocaleDateTimeFormatLocalised(locale: string, formatWidth: FormatWidth): string {
    return this._getTranslationForFormat(this.getLocaleDateTimeFormat(locale, formatWidth), locale);
  }

  isMeridianEnabled(locale: string): boolean {
    return getLocaleTimeFormat(locale, FormatWidth.Short).includes(MERIDIAN_SYMBOL);
  }

  private _getTranslationForFormat(rawFormat: string, locale: string) {
    const localisedFormat: string =
      this._translate.instant(`${DATE_TIME_PLACEHOLDER_TRANSLATIONS_PREFIX}.${rawFormat}`) || '';
    const isFormatLocalised =
      localisedFormat.startsWith(DATE_TIME_PLACEHOLDER_TRANSLATIONS_PREFIX) === false;
    const isMockedLocale = this._mockedLocales.includes(locale);
    const rawFormatX = rawFormat
      ? rawFormat.replaceAll(/\u200F/giu, '')
      : 'ERROR_LOCALE_NOT_LOADED';

    if (isMockedLocale) {
      return `${getLangPrefixFromLocale(locale)}_${rawFormatX}`;
    }

    return isFormatLocalised ? localisedFormat : rawFormat;
  }
}
