import {
  AliasId,
  AliasMissingMapping,
  EntityId,
  MissingMappingSearchParams,
  ProposedAlias,
} from '@demica/core/core';

import { MissingMappingSearchFormValue } from '../alias-missing-search/alias-missing-search.component';

export function defaultSearchParams(transactionId: EntityId): MissingMappingSearchParams {
  return searchFormToSearchParams(transactionId ? [transactionId] : [], null);
}

export function searchFormToSearchParams(
  transactionIds: EntityId[],
  formSearchParams: MissingMappingSearchFormValue,
): MissingMappingSearchParams {
  return {
    transactionIds: transactionIds ? transactionIds : [],
    analysisGroupName: formSearchParams?.analysisGroup?.name || null,
    dataSourceId: formSearchParams?.dataSource?.entityId || null,
    environmentId: formSearchParams?.environment?.entityId || null,
    filename: formSearchParams?.file || null,
    minAppearanceDate: formSearchParams?.dateFrom || null,
    maxAppearanceDate: formSearchParams?.dateTo || null,
    analysisCode: formSearchParams?.analysisCode || null,
  };
}

export function aliasToAdd(
  transactionId: EntityId,
  aliasMissingMapping: AliasMissingMapping,
  proposedValue: string,
): ProposedAlias {
  return {
    transactionId: transactionId,
    dataSourceId: aliasMissingMapping.dataSource.entityId,
    columnTypeId: aliasMissingMapping.analysisGroup.entityId,
    environmentId: aliasMissingMapping.environment.entityId,
    value: aliasMissingMapping.value,
    proposedAnalysisCodeValue: proposedValue,
    appearanceDate: aliasMissingMapping.appearanceDate,
  };
}

export function aliasToRemove(
  transactionId: EntityId,
  aliasMissingMapping: AliasMissingMapping,
): AliasId {
  return {
    transactionId: transactionId,
    dataSourceId: aliasMissingMapping.dataSource.entityId,
    columnTypeId: aliasMissingMapping.analysisGroup.entityId,
    environmentId: aliasMissingMapping.environment.entityId,
    value: aliasMissingMapping.value,
  };
}
