import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { PrefixTranslationService } from '../service/prefix-translation.service';

import { Translatable } from '../model/translatable.interface';

export interface Item extends Translatable {
  displayName?: string;
  groupName?: string;
  key?: string;
  name?: string;
  transform?: (i: Item, translationKey: string) => string;
}

@Pipe({
  name: 'optionName',
  pure: true,
})
export class OptionNamePipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private prefixTranslationService: PrefixTranslationService,
  ) {}

  transform(item: string | Item, translationPrefix: string | string[] = ''): string {
    if (!item) return;

    if (typeof item === 'string') return item;

    if (item.displayName) return item.displayName;

    if (item.groupName) return item.groupName;

    if (item.transform) {
      const translatedKey = this.translate.instant(item.key);
      return item.transform(item, translatedKey);
    }
    return this.prefixTranslationService.performTranslation(
      translationPrefix,
      item,
      this.translate,
    );
  }
}
