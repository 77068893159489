import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import {
  DisplayUnitsResourceService,
  UserAccountResourceService,
  UserPreferences,
} from '@demica/core/core';

@Component({
  selector: 'trf-currencies-dropdown',
  styleUrls: ['./currencies-dropdown.component.sass'],
  templateUrl: 'currencies-dropdown.component.html',
})
export class CurrenciesDropdownComponent implements OnInit, OnDestroy {
  dropdownVisible = false;

  userCurrencyCode: string;
  currenciesCodes: string[];
  loading = true;
  subscriptions: Subscription[] = [];
  @ViewChild('dropdownButton')
  dropdownButton: ElementRef;
  @ViewChild('ulElement')
  ulElement: ElementRef;

  constructor(
    private userAccountService: UserAccountResourceService,
    private displayUnitsService: DisplayUnitsResourceService,
    private userPreferences: UserPreferences,
  ) {
    this.subscriptions.push(
      this.userPreferences.currency.subscribe(
        (userCurrency) => (this.userCurrencyCode = userCurrency),
      ),
    );
  }

  ngOnInit() {
    this.displayUnitsService.getDisplayUnits().subscribe((displayUnits) => {
      this.currenciesCodes = displayUnits.map((value) => value.unit);
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  setCurrency(currencyCode: string) {
    this.userCurrencyCode = currencyCode;
    this.userAccountService.putMyAccountCurrency(currencyCode).subscribe(() => {
      this.userPreferences.changeCurrency(currencyCode);
    });
    this.dropdownVisible = !this.dropdownVisible;
    this.dropdownButton.nativeElement.focus();
  }

  selectedCurrencyCode(): string {
    return this.userCurrencyCode;
  }

  closeDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
    this.dropdownButton.nativeElement.focus();
  }

  focusOnFirstElement() {
    this.ulElement.nativeElement.children[0].focus();
  }

  focusOnLastElement() {
    this.ulElement.nativeElement.children[this.currenciesCodes.length - 1].focus();
  }
}
