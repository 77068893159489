<div class="pb-4">
  <legend>{{ "LEGEND.REQUIRED_ASTERISK" | translate }}</legend>
</div>

<form
  [formGroup]="form"
  [ngClass]="{ 'trf-form-submitted': submitted }"
>
  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.BANK_ACCOUNT_NAME"
    labelClasses="required"
    placeholder="SELLER_BANK_ACCOUNT.FORM_BANK_ACCOUNT_NAME_PLACEHOLDER"
    [name]="FormFields.bankAccountName"
    [validations]="validationMessages.bankAccountNameValidations"
    testid="opco-bank-account-name"
  >
  </trf-form-text-row>

  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.BANK_ACCOUNT_NUMBER"
    labelClasses="required"
    placeholder="SELLER_BANK_ACCOUNT.FORM_BANK_ACCOUNT_NUMBER_PLACEHOLDER"
    [name]="FormFields.bankAccountNumber"
    [maxLength]="maxOpcoBankAccountNameLength"
    [validations]="validationMessages.bankAccountNumberValidations"
    testid="opco-bank-account-number"
  >
  </trf-form-text-row>

  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.BRANCH_CODE_ROUTING_NUMBER"
    placeholder="SELLER_BANK_ACCOUNT.FORM_BRANCH_CODE_PLACEHOLDER"
    [name]="FormFields.branchCodeOrRoutingNumber"
    [maxLength]="maxBranchCodeRoutingNumberLength"
    [validations]="validationMessages.branchCodeRoutingNumberValidations"
    testid="opco-bank-branch-routing"
  >
  </trf-form-text-row>

  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.SWIFT_BIC_CODE"
    placeholder="SELLER_BANK_ACCOUNT.FORM_SWIFT_BIC_CODE_PLACEHOLDER"
    [name]="FormFields.swiftBic"
    [validations]="validationMessages.swiftBicCodeValidations"
    testid="opco-bank-account-swift-bic-code"
  >
  </trf-form-text-row>

  <trf-form-select-row
    label="SELLER_BANK_ACCOUNT.TYPE"
    labelClasses="required"
    defaultLabel="SELLER_BANK_ACCOUNT.FORM_TYPE_PLACEHOLDER"
    translationPrefix="SELLER_BANK_ACCOUNT.ACCOUNT_TYPES"
    [name]="FormFields.bankAccountType"
    [options]="sellerBankAccountTypes"
    [validations]="validationMessages.bankAccountTypeValidations"
    [clearable]="false"
    [searchable]="false"
    testid="opco-bank-account-type"
  >
  </trf-form-select-row>

  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.ACCOUNT_HOLDER"
    placeholder="SELLER_BANK_ACCOUNT.FORM_ACCOUNT_HOLDER_PLACEHOLDER"
    labelClasses="required"
    [name]="FormFields.accountHolder"
    [validations]="validationMessages.accountHolderValidations"
    testid="opoc-bank-account-holder"
  >
  </trf-form-text-row>
  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.BANK_NAME"
    placeholder="SELLER_BANK_ACCOUNT.FORM_BANK_NAME_PLACEHOLDER"
    [name]="FormFields.bankName"
    [maxLength]="maxOpcoBankNameLength"
    [validations]="validationMessages.bankNameValidations"
    testid="opco-bank-account-bank-name"
  >
  </trf-form-text-row>
  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.BANK_ADDRESS_1"
    placeholder="SELLER_BANK_ACCOUNT.FORM_BANK_ADDRESS_LINE_PLACEHOLDER"
    [name]="FormFields.address1"
    [validations]="validationMessages.bankAddressValidations"
    testid="opco-bank-account-bank-address-1"
  >
  </trf-form-text-row>
  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.BANK_ADDRESS_2"
    placeholder="SELLER_BANK_ACCOUNT.FORM_BANK_ADDRESS_LINE_PLACEHOLDER"
    [name]="FormFields.address2"
    [validations]="validationMessages.bankAddressValidations"
    testid="opco-bank-account-bank-address-2"
  >
  </trf-form-text-row>
  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.BANK_ADDRESS_3"
    placeholder="SELLER_BANK_ACCOUNT.FORM_BANK_ADDRESS_LINE_PLACEHOLDER"
    [name]="FormFields.address3"
    [validations]="validationMessages.bankAddressValidations"
    testid="opco-bank-account-bank-address-3"
  >
  </trf-form-text-row>
  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.BANK_ADDRESS_4"
    placeholder="SELLER_BANK_ACCOUNT.FORM_BANK_ADDRESS_LINE_PLACEHOLDER"
    [name]="FormFields.address4"
    [validations]="validationMessages.bankAddressValidations"
    testid="opco-bank-account-bank-address-4"
  >
  </trf-form-text-row>
  <trf-form-text-row
    label="SELLER_BANK_ACCOUNT.POST_CODE"
    placeholder="SELLER_BANK_ACCOUNT.FORM_POST_CODE_PLACEHOLDER"
    [maxLength]="maxPostCodeLength"
    [validations]="validationMessages.bankPostCodeValidations"
    [name]="FormFields.postCode"
    testid="opco-bank-account-post-code"
  >
  </trf-form-text-row>
</form>
