import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { Key } from '../model/key';

@Directive({
  selector: '[trfArrowKeysFocus]',
})
export class ArrowKeysFocusDirective {
  @Input()
  elementsLength!: number;
  @Input()
  index!: number;
  @Output()
  focusOnFirst = new EventEmitter<boolean>();
  @Output()
  focusOnLast = new EventEmitter<boolean>();

  constructor(private elRef: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const key = new Key(event);

    if (key.isDown && this.index < this.elementsLength - 1) {
      event.preventDefault();

      return this.elRef.nativeElement.nextSibling.focus();
    }
    if (key.isDown && this.index === this.elementsLength - 1) {
      event.preventDefault();

      return this.focusOnFirst.emit(true);
    }
    if (key.isUp && this.elRef.nativeElement.previousSibling) {
      event.preventDefault();

      return this.elRef.nativeElement.previousSibling.focus();
    }
    if (key.isUp && this.index === 0) {
      event.preventDefault();

      return this.focusOnLast.emit(true);
    }
    if (event.key === 'Home') {
      event.preventDefault();

      return this.focusOnFirst.emit(true);
    }
    if (event.key === 'End') {
      event.preventDefault();

      return this.focusOnLast.emit(true);
    }
    if (event.key === 'Tab' && this.index === this.elementsLength - 1) {
      event.preventDefault();

      return this.focusOnFirst.emit(true);
    }
  }
}
