import { ViewContainerRef } from '@angular/core';

export class FormRowClass {
  /*
    Should be added to the control template:
    [attr.aria-describedby]="formControlName + FormRowClass.warningNotificationIdPostfix"
   */
  static warningNotificationIdPostfix = '_warning_notification';

  additionalValidationContainer: ViewContainerRef;
  formControlName: string;
}
