import { Injectable } from '@angular/core';

import { isDefined } from '@demica/core/core';

@Injectable({ providedIn: 'root' })
export class KeyboardEventKeyMatcher {
  static keyboardSpecials: Record<string, Partial<KeyboardEvent>> = {
    selectAll: { ctrlKey: true, key: 'a' },
    copy: { ctrlKey: true, key: 'c' },
    paste: { ctrlKey: true, key: 'v' },
    tab: { key: 'Tab' },
    backspace: { key: 'Backspace' },
    control: { key: 'Control' },
    arrowLeft: { key: 'ArrowLeft' },
    arrowRight: { key: 'ArrowRight' },
    right: { key: 'Right' },
    left: { key: 'Left' },
    delete: { key: 'Delete' },
  };

  private _specialKeysDef: Partial<KeyboardEvent>[] = Object.values(
    KeyboardEventKeyMatcher.keyboardSpecials,
  );
  private _specialKeysKeys = this._specialKeysDef.map((specialKey) => Object.keys(specialKey));

  isSpecialKey(keyboardEvent: KeyboardEvent): boolean {
    if (!isDefined(keyboardEvent.key)) return false;

    const EVENT_KEY_PROPERTY = 'key';

    return this._specialKeysDef.some((specialKey, index) => {
      return this._specialKeysKeys[index].every((key) => {
        if (key === EVENT_KEY_PROPERTY) {
          return keyboardEvent[key].toLowerCase() === specialKey[key].toLowerCase();
        } else {
          return (
            keyboardEvent[key as keyof KeyboardEvent] === specialKey[key as keyof KeyboardEvent]
          );
        }
      });
    });
  }
}
