import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CalendarTemplateResourceService,
  TemplateCalendar,
  TemplateCalendarDetails,
} from '@demica/resources/calendar';

import { CalendarResourceConvertService } from './calendar-resource-convert.service';

import { EntityId } from '../../../../interface/has-entity-id.interface';
import { PostResponse } from '../../../../model/rest-response/post-response.interface';
import { PutResponse } from '../../../../model/rest-response/put-response.interface';

@Injectable()
export class CalendarTemplateConversionResourceService {
  // TODO: This conversions are meant to be temporary - they, along with double models for calendars should be removed after epic TRFV2-22365 is done

  constructor(
    private calendarTemplateResourceService: CalendarTemplateResourceService,
    private calendarResourceConvertService: CalendarResourceConvertService,
  ) {}

  getCalendar(calendarId: EntityId): Observable<TemplateCalendar> {
    return this.calendarTemplateResourceService
      .getCalendar$(calendarId)
      .pipe(
        map((calendar) =>
          this.calendarResourceConvertService.convertCalendarTemplateFromResource(calendar),
        ),
      );
  }

  postCalendar(data: TemplateCalendarDetails): Observable<PostResponse> {
    return this.calendarTemplateResourceService.createCalendar$(
      this.calendarResourceConvertService.convertCalendarTemplateDetailsToResource(data),
    );
  }

  putCalendar(calendarId: EntityId, data: TemplateCalendarDetails): Observable<PutResponse> {
    return this.calendarTemplateResourceService.updateCalendar$(
      calendarId,
      this.calendarResourceConvertService.convertCalendarTemplateDetailsToResource(data),
    );
  }
}
