<div class="search">
  <ngp-svg-icon
    class="search-icon"
    iconName="search"
  ></ngp-svg-icon>
  <input
    type="text"
    placeholder="{{ placeholder | translate }}"
    (keyup)="inputChange($event.target.value)"
  />
</div>
