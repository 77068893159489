import { MAX_NUMBER_FIELD_LENGTH, MAX_TEXT_FIELD_LENGTH } from '../validators';
import {
  hasDateFormatError,
  hasDateTimeFormatError,
  hasDecimalPlacesExceededError,
  hasDisabledOptionSelected,
  hasEmailError,
  hasEndDateBeforeStart,
  hasError,
  hasIntegerPlacesExceededError,
  hasLogoFileToBigError,
  hasLogoFileWrongFormatError,
  hasMaxAvailableValueError,
  hasMaxLengthError,
  hasMaxValueError,
  hasMinLengthError,
  hasMinValueError,
  hasNgbDateError,
  hasRequiredError,
} from './message-predicates';
import { ValidationMessageDefinition } from './validation-message.interface';

/* Validation message definitions */
export const msgRequired: ValidationMessageDefinition = {
  func: hasRequiredError,
  key: 'VALIDATION.FIELD_REQUIRED',
};

export const msgNgbDate: ValidationMessageDefinition = {
  func: hasNgbDateError,
  key: 'VALIDATION.DATE_CORRECT',
};

export const msgMinLength = (minChars: number): ValidationMessageDefinition => ({
  func: hasMinLengthError,
  key: 'VALIDATION.MIN_TEXT_FIELD_LENGTH',
  params: { characters: minChars },
});

export const msgDefaultMaxLength: ValidationMessageDefinition = {
  func: hasMaxLengthError,
  key: 'VALIDATION.MAX_TEXT_FIELD_LENGTH',
  params: { characters: MAX_TEXT_FIELD_LENGTH },
};

export const msgDefaultNumberMaxLength: ValidationMessageDefinition = {
  func: hasMaxLengthError,
  key: 'VALIDATION.MAX_DECIMAL_FIELD_LENGTH',
  params: { characters: MAX_NUMBER_FIELD_LENGTH },
};

export const msgMaxLength = (maxChars: number): ValidationMessageDefinition => ({
  func: hasMaxLengthError,
  key: 'VALIDATION.MAX_TEXT_FIELD_LENGTH',
  params: { characters: maxChars },
});

export const msgMinNumber = (minNumber: number): ValidationMessageDefinition => ({
  func: hasMinValueError,
  key: 'VALIDATION.MIN_NUMBER_VALUE',
  params: { value: minNumber },
});

export const msgMaxNumber = (maxNumber: number): ValidationMessageDefinition => ({
  func: hasMaxValueError,
  key: 'VALIDATION.MAX_NUMBER_VALUE',
  params: { value: maxNumber },
});

export const msgMaxDecimalPlacesExceeded: ValidationMessageDefinition = {
  func: hasDecimalPlacesExceededError,
  key: 'VALIDATION.DECIMAL_PLACES_EXCEEDED',
};
export const msgMaxIntegerPlacesExceeded: ValidationMessageDefinition = {
  func: hasIntegerPlacesExceededError,
  key: 'VALIDATION.INTEGER_PLACES_EXCEEDED',
};

export const msgEmailFormat: ValidationMessageDefinition = {
  func: hasEmailError,
  key: 'VALIDATION.EMAIL_FORMAT',
};

export const msgDateFormat: ValidationMessageDefinition = {
  func: hasDateFormatError,
  key: 'VALIDATION.DATE_FORMAT',
};

export const msgDateTimeFormat: ValidationMessageDefinition = {
  func: hasDateTimeFormatError,
  key: 'VALIDATION.DATE_TIME_FORMAT',
};

export const msgLogoFileToBig: ValidationMessageDefinition = {
  func: hasLogoFileToBigError,
  key: 'VALIDATION.LOGO_FILE_TOO_BIG',
};
export const msgWrongLogoFileFormat: ValidationMessageDefinition = {
  func: hasLogoFileWrongFormatError,
  key: 'VALIDATION.LOGO_WRONG_FILETYPE',
};

export const msgMaxAvailableValue: ValidationMessageDefinition = {
  func: hasMaxAvailableValueError,
  key: 'VALIDATION.EXCEEDED_LONG_MAX_VALUE',
};
export const msgEndDateBeforeStartDate: ValidationMessageDefinition = {
  func: hasEndDateBeforeStart,
  key: 'ERRORS.END_DATE_NOT_AFTER_START_DATE',
};

export const msgDisabledOptionSelected: ValidationMessageDefinition = {
  func: hasDisabledOptionSelected,
  key: 'ERRORS.DISABLED_VALUE_SELECTED',
};

export const msgRequiredOne: ValidationMessageDefinition = {
  func: hasError('required-one'),
  key: 'VALIDATION.REQUIRED_ONE',
};
