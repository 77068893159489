import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { TranslationsPackage } from '../model/translations-package.model';

@Injectable({
  providedIn: 'root',
})
export class TranslationsResourceService {
  constructor(private _http: HttpClient) {}

  getTranslations$(url: string): Observable<TranslationsPackage> {
    return this._http.get<TranslationsPackage>(url);
  }
}
