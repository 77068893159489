export enum NotificationTypes {
  warning = 'warning',
  error = 'error',
  info = 'info',
  success = 'success',
}

export type NotificationType = keyof typeof NotificationTypes;

export type NotificationIconDefinition = {
  [iconName in NotificationType]?: string;
};
