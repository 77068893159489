import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DialogTrackingService {
  openedSlideins = 0;
  openedModals = 0;
  lastFocusedDialogElement: HTMLElement;

  addNewOpenedSlidein(element: HTMLElement) {
    this.lastFocusedDialogElement = element;
    this.openedSlideins++;
  }

  addNewModal(element: HTMLElement) {
    this.lastFocusedDialogElement = element;
    this.openedModals++;
  }

  removeClosedSlidein() {
    this.openedSlideins--;
  }

  removeModal() {
    this.openedModals--;
  }
}
