import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { toData } from '../../../../service/rest/response-mapping';

import { EntityId, HasEntityId } from '../../../../interface/has-entity-id.interface';
import { RestResponse } from '../../../../model/response.interface';
import {
  FxRateAvailabilityResponse,
  NameAvailabilityResponse,
} from '../../../../model/rest-response/availability-response';
import { encodeParams } from '../../../../security/encode-params';
import { encodeEndpoint } from '../../../../security/encode-url';
import { TransactionCollection } from '../../_common/model/transaction-collection.interface';
import { TimeSeriesPayload } from '../model/time-series-data.interface';
import { TimeSeriesTemplate } from '../model/time-series-template.interface';
import { TimeSeriesResource } from '../time-series-resource.interface';

@Injectable()
export class TimeSeriesTemplateResourceService implements TimeSeriesResource {
  constructor(private http: HttpClient) {}

  getTemplates() {
    const url = encodeEndpoint('resources/templates/time-series');
    return this.http.get<RestResponse<TimeSeriesTemplate[]>>(url).pipe(map(toData));
  }

  getTemplatesForTransaction(transactionId: number) {
    const params = encodeParams({ transactionId });
    const url = encodeEndpoint('resources/templates/time-series');
    return this.http.get<RestResponse<TimeSeriesTemplate[]>>(url, { params }).pipe(map(toData));
  }

  getTemplate(id: EntityId) {
    const url = encodeEndpoint('resources/templates/time-series/{}', id);
    return this.http.get<RestResponse<TimeSeriesTemplate>>(url).pipe(map(toData));
  }

  postTemplate(template: TimeSeriesPayload) {
    const url = encodeEndpoint('resources/templates/time-series');
    return this.http
      .post<RestResponse<TimeSeriesTemplate>>(url, { ...template, entityRevision: 0 })
      .pipe(map(toData));
  }

  putTemplate(id: EntityId, entityRevision: EntityId, template: TimeSeriesPayload) {
    const url = encodeEndpoint('resources/templates/time-series/{}', id);
    return this.http
      .put<RestResponse<TimeSeriesTemplate>>(url, { ...template, entityRevision })
      .pipe(map(toData));
  }

  deleteTemplate(id: EntityId) {
    const url = encodeEndpoint('resources/templates/time-series/{}', id);
    return this.http.delete<RestResponse<HasEntityId>>(url).pipe(map(toData));
  }

  checkTimeSeriesTemplateNameAvailable(name: string, templateId?: EntityId) {
    const params = encodeParams({ name, templateId });
    const url = encodeEndpoint('resources/templates/time-series-template-name-availability');
    return this.http.get<RestResponse<NameAvailabilityResponse>>(url, { params }).pipe(map(toData));
  }

  postCollection(data: TransactionCollection) {
    const url = encodeEndpoint('resources/templates/collections');
    return this.http.post<RestResponse<TransactionCollection>>(url, data).pipe(map(toData));
  }

  getCollections() {
    const url = encodeEndpoint('resources/templates/collections');
    return this.http.get<RestResponse<TransactionCollection[]>>(url).pipe(map(toData));
  }

  checkTimeSeriesFxRateAvailable(
    baseCurrency: string,
    referenceCurrency: string,
    reportingOnly: boolean,
    transactionId: number,
    timeSeriesId: number,
    useForDisplay: boolean,
  ) {
    const params = encodeParams({
      baseCurrency,
      referenceCurrency,
      reportingOnly,
      useForDisplay,
      timeSeriesId,
    });
    const url = encodeEndpoint('resources/templates/time-series-template-fxrate-availability');
    return this.http
      .get<RestResponse<FxRateAvailabilityResponse>>(url, { params })
      .pipe(map(toData));
  }
}
