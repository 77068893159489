import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CodeAvailabilityResponse,
  encodeEndpoint,
  encodeParams,
  EntityId,
  EntityReference,
  RestResponse,
} from '@demica/resources/common';

import { toData } from './response-mapping';

@Injectable({
  providedIn: 'root',
})
export class AssetBaseResourceService {
  constructor(private http: HttpClient) {}

  getAssetBases(transactionId: EntityId): Observable<EntityReference[]> {
    const url = encodeEndpoint('resources/transactions/{}/all-asset-bases', transactionId);
    return this.http.get<RestResponse<EntityReference[]>>(url).pipe(map(toData));
  }

  getAssetBasesForRevision(
    transactionId: EntityId,
    transactionRevision: number,
  ): Observable<EntityReference[]> {
    const url = encodeEndpoint(
      'resources/transactions/{}/entity-revisions/{}/asset-bases/search',
      transactionId,
      transactionRevision,
    );
    return this.http.post<RestResponse<EntityReference[]>>(url, {}).pipe(map(toData));
  }

  checkAssetBaseCodeAvailability$(code: string): Observable<CodeAvailabilityResponse> {
    const params = encodeParams({ code });
    const url = encodeEndpoint('resources/transactions/asset-bases/code-availability');
    return this.http.get<RestResponse<CodeAvailabilityResponse>>(url, { params }).pipe(map(toData));
  }
}
