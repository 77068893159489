import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

import { HasEntityId } from '@demica/core/core';

/** Form definition in a format of [Default_value, Array_of_sync_validators, Array_of_async_validators ] **/
export type FieldDefinition = [unknown, ValidatorFn[]?, AsyncValidatorFn[]?];

export const preventControlEventBubbling = { onlySelf: true, emitEvent: false };

/**
 * Adjust select input for entity edit.
 *
 * (There was @ deprecated, removed temporary to not see it in lint warnings)
 * Should not be needed anymore. If the select display is empty then the compareFn function in form-select-row
 * should be extended to handle your specific case.
 *
 *
 * @param object
 * @param {string} field
 * @param {HasEntityId[]} collection
 */
// TODO: TRFV2-3891 Refactor to proper types from "any"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function initSelect(object: any, field: string, collection: HasEntityId[]) {
  const props = field.split('.');
  let n = 0;

  for (n; n < props.length - 1; n++) {
    const prop = props[n];
    const candidate = object[prop];

    if (candidate) {
      object = candidate;
    }
  }

  if (object[props[n]]) {
    object[props[n]] = collection.find((i) => i.entityId === object[props[n]].entityId);
  } else {
    object[props[n]] = null;
  }
}

export function filterKeyForIntegersOnly(key: string, acceptNegatives: boolean): boolean {
  const isNullChar = key === '';
  const isDigit = '0123456789'.includes(key);
  const isMinusSign = key === '-';

  return isNullChar || isDigit || (acceptNegatives && isMinusSign);
}

export function filterKeyForDecimalsOnly(key: string, acceptNegatives: boolean): boolean {
  const isDecimalSeparator = key === ',' || key === '.';

  return filterKeyForIntegersOnly(key, acceptNegatives) || isDecimalSeparator;
}
