import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PageResponse } from '@demica/core/core';

@Component({
  selector: 'trf-spring-page-number',
  templateUrl: 'spring-page-number.component.html',
})
export class SpringPageNumberComponent {
  @Input()
  pageResponse: PageResponse;

  @Output()
  pageChange = new EventEmitter();

  disabled = false;

  onPageClick(pageNumber: number) {
    this.pageChange.emit(pageNumber);
  }
}
