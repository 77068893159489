<div class="trf-section-header">
  <h3>{{ "PREVIEW.CONTACT_DETAILS" | translate }}</h3>
</div>
<div class="trf-section-content">
  <div
    class="contact-person-name"
    [title]="preview.contactPersonFirstName + ' ' + preview.contactPersonLastName"
    data-testid="preview-contact-person-field"
  >
    <span *ngIf="preview.contactPersonFirstName">{{ preview.contactPersonFirstName + " " }}</span>
    <span *ngIf="preview.contactPersonLastName">{{ preview.contactPersonLastName }}</span>
  </div>
  <div
    *ngIf="preview.telephoneCountryCode || preview.telephoneNumber"
    class="text-ellipsis"
    [title]="preview.telephoneCountryCode + ' ' + preview.telephoneNumber"
    data-testid="preview-telephone-field"
  >
    <span class="contact-prefix">{{ "PREVIEW.CONTACT_PREFIX.PHONE" | translate }}</span>
    <span>{{ preview.telephoneCountryCode + " " }}</span>
    <span>{{ preview.telephoneNumber }}</span>
  </div>
  <div
    *ngIf="preview.email"
    data-testid="preview-email-field"
  >
    <span class="contact-prefix">{{ "PREVIEW.CONTACT_PREFIX.EMAIL" | translate }}</span>
    <a
      class="trf-link"
      testId="preview-mail-field"
      [href]="'mailto:' + preview.email"
      [title]="preview.email"
      >{{ preview.email }}</a
    >
  </div>
</div>
