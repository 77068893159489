import { Injectable, OnDestroy, EventEmitter } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export const LOCK_NONE = 0;
export const LOCK_SAVE = 1;
export const LOCK_CLOSE = 2;

function isLockControl(lockFlag: number): boolean {
  // eslint-disable
  return (lockFlag & LOCK_SAVE) !== 0 && (lockFlag & LOCK_CLOSE) !== 0;
  // eslint-enable
}

@Injectable()
export class SlideinContext implements OnDestroy {
  closeModal = new EventEmitter<void>();

  modal: NgbActiveModal;
  lockFlag = LOCK_NONE;

  lockNumbers = 0;

  lockSubject = new BehaviorSubject<number>(LOCK_NONE);

  isFormDirty = false;

  navigationLock = new BehaviorSubject<boolean>(false);

  private destroySubject = new Subject<void>();

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  getObservableLock(): Observable<number> {
    return Observable.create((observer: Subject<number>) => {
      this.lockSubject.pipe(takeUntil(this.destroySubject)).subscribe((result) => {
        observer.next(result);
      });
    });
  }

  lockControls() {
    if (!isLockControl(this.lockFlag)) {
      // eslint-disable
      this.lockFlag = this.lockFlag | LOCK_SAVE | LOCK_CLOSE;
      // eslint-enable
    } else {
      this.lockNumbers++;
    }

    this.lockSubject.next(this.lockFlag);
  }

  unlockControls() {
    if (this.lockNumbers > 0) {
      this.lockNumbers--;
    } else {
      // eslint-disable
      this.lockFlag = this.lockFlag & ~LOCK_SAVE & ~LOCK_CLOSE;
      // eslint-enable
      this.lockSubject.next(this.lockFlag);
    }
  }
}
