import { Injectable } from '@angular/core';

import { LogLevel } from '@demica/resources/app-config';
import { EntityId } from '@demica/resources/common';
import { isString, toBoolean } from '@demica/utils';

// TODO remove when re-exports are not needed anymore
export { LogLevel };

export function getLogLevelText(level: LogLevel): keyof typeof LogLevel {
  return LogLevel[level] as keyof typeof LogLevel;
}

export interface LoggerMetadata {
  source: 'trf-ui-frontend';
  sessionId: string;
  userId: string;
  trfUserId: EntityId | null;
  url: string;
}

export enum LogArea {
  AUTH = '[AUTH]',
  // used only in global error handler
  ERROR_HANDLER = '[EH]',
}

export interface LogMessage {
  message: string;
  area: LogArea | string;
}

function isLogMessage(value: unknown | LogMessage): value is LogMessage {
  return (
    typeof value === 'object' &&
    (value as LogMessage).message !== undefined &&
    (value as LogMessage).area !== undefined
  );
}

@Injectable()
export abstract class LoggerService {
  protected consoleEnabled = false;
  protected logLevel: LogLevel;

  abstract addLog(message: LogMessage | string, level?: LogLevel, stack?: string): void;

  abstract addInstantLog(message: LogMessage | string, level?: LogLevel, stack?: string): void;

  abstract addError(message: LogMessage | string, stack?: string): void;

  abstract addConsoleLog(message: LogMessage | string, level?: LogLevel, stack?: string): void;

  protected abstract addMetadata(): LoggerMetadata;

  setLevel(level: keyof typeof LogLevel) {
    this.logLevel = LogLevel[level];
  }

  setConsoleEnabled(enabled: boolean | string): void {
    this.consoleEnabled = toBoolean(enabled);
  }

  protected formatMessage(logMessage: LogMessage | string) {
    if (isLogMessage(logMessage)) {
      return `${logMessage.area}: ${logMessage.message}`;
    }
    if (isString(logMessage)) {
      return logMessage;
    }
    return '';
  }

  protected isLevelValid(level: LogLevel) {
    return level >= this.logLevel;
  }
}
