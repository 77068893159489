import { Injectable } from '@angular/core';

import { dateToISO8601 } from '@demica/utils';

import { UserActivity, UserActivityAudit } from './user-activity.model';

export const MAX_ACTIVITIES_LENGTH = 20;
@Injectable({
  providedIn: 'root',
})
export class UserActivityAuditService {
  private lastActivities: UserActivityAudit[] = [];

  addActivity(activity: UserActivity) {
    this.lastActivities.push({ createdAt: dateToISO8601(), ...activity });
    this.lastActivities = this.lastActivities.slice(-MAX_ACTIVITIES_LENGTH);
  }

  getActivities() {
    return [...this.lastActivities];
  }
}
