import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

export interface FieldMetadata {
  controlType: ControlType;
  dynamic?: boolean;
}

export interface Field {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [index: number]: object | string;

  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  0: any;
  1?: ValidatorFn[];
  2?: AsyncValidatorFn[];

  metadata?: FieldMetadata;
}

export enum ControlType {
  TEXT_INPUT,
  NUMBER_INPUT,
  SELECT,
  CHECKBOX,
  DYNAMIC_TEXT,
  DYNAMIC_NUMBER,
}

export class FieldBuilder {
  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
  validators: ValidatorFn[];
  asyncValidators: AsyncValidatorFn[];
  metadata: FieldMetadata;

  withDefaultValue(val: unknown) {
    this.defaultValue = val;
    return this;
  }

  withValidators(validators: ValidatorFn[]) {
    this.validators = validators;
    return this;
  }

  withAsyncValidators(validators: AsyncValidatorFn[]) {
    this.asyncValidators = validators;
    return this;
  }

  withMetadata(metadata: FieldMetadata) {
    this.metadata = metadata;
    return this;
  }

  build(): Field {
    if (
      this.metadata.controlType !== ControlType.DYNAMIC_TEXT &&
      this.metadata.controlType !== ControlType.DYNAMIC_NUMBER &&
      this.defaultValue === undefined
    )
      throw new Error('Default value has to be defined');

    const f: Field = [this.defaultValue];
    f.metadata = this.metadata;

    if (this.validators) f[1] = this.validators;

    if (this.asyncValidators) f[2] = this.asyncValidators;

    return f;
  }
}

export function newField() {
  return new FieldBuilder();
}

export function newTextInput() {
  return new FieldBuilder().withMetadata({ controlType: ControlType.TEXT_INPUT });
}

export function newNumberInput() {
  return new FieldBuilder().withMetadata({ controlType: ControlType.NUMBER_INPUT });
}

export function newSelect() {
  return new FieldBuilder().withMetadata({ controlType: ControlType.SELECT });
}

export function newCheckbox() {
  return new FieldBuilder().withMetadata({ controlType: ControlType.CHECKBOX });
}

export function newDynamicTextInput() {
  return new FieldBuilder().withMetadata({ controlType: ControlType.DYNAMIC_TEXT, dynamic: true });
}

export function newDynamicNumberInput() {
  return new FieldBuilder().withMetadata({
    controlType: ControlType.DYNAMIC_NUMBER,
    dynamic: true,
  });
}

export function newDynamicSelect() {
  return new FieldBuilder().withMetadata({ controlType: ControlType.SELECT, dynamic: true });
}
