<div [title]="title">
  <div class="text-ellipsis"
    >{{ preview.contactPersonFirstName }} {{ preview.contactPersonLastName }}</div
  >
  <div
    *ngIf="preview.telephoneNumber || preview.telephoneCountryCode"
    class="text-ellipsis"
  >
    <span class="contact-prefix">{{ "PREVIEW.CONTACT_PREFIX.PHONE" | translate }}</span>
    <span>{{ preview.telephoneCountryCode }} {{ preview.telephoneNumber }}</span>
  </div>
  <div
    *ngIf="preview.email"
    class="text-ellipsis"
  >
    <span class="contact-prefix">{{ "PREVIEW.CONTACT_PREFIX.EMAIL" | translate }}</span>
    <a
      class="trf-link"
      [href]="'mailto:' + preview.email"
      >{{ preview.email }}</a
    >
  </div>
</div>
