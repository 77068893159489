import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { encodeEndpoint } from '../../_commons_/helpers/encode-url/encode-url';
import { toData } from '../../_commons_/helpers/response-mapping/response-mapping.helper';

import { EntityId } from '../../_commons_/interface/entity/entity-id.interface';
import { RestResponse } from '../../_commons_/interface/rest-response/rest-response.interface';
import { FeeTypeResponse } from '../models/fee-type.interface';

export const FEE_TYPES_URL = 'resources/transactions/dictionaries/feeType';

@Injectable({ providedIn: 'root' })
export class FeeTypesResourcesService {
  constructor(private _http: HttpClient) {}

  getFeeTypes$(transactionType: EntityId): Observable<FeeTypeResponse[]> {
    const params = new HttpParams({ fromObject: { transactionType } });
    const url = encodeEndpoint(FEE_TYPES_URL);

    return this._http.get<RestResponse<FeeTypeResponse[]>>(url, { params }).pipe(map(toData));
  }
}
