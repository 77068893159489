import { Injectable } from '@angular/core';

import { compareBySameProperty, HasEntityId, isHasEntityId } from '@demica/core/core';

import { SelectComparator } from './select-comparator-strategy.service';

import { HasKey, HasKeyOrId, isHasKey } from '../../../model/has-key-or-id.interface';

@Injectable({ providedIn: 'root' })
export class DefaultSelectComparatorStrategy implements SelectComparator {
  bindValue?: 'key' = undefined;
  compareFn: (o1: HasKeyOrId, o2: HasKeyOrId | string) => boolean = (
    o1: HasKeyOrId,
    o2: HasKeyOrId,
  ) => {
    if (o1 && o2) {
      let comparator: (o1: unknown, o2: unknown) => boolean;
      if (isHasEntityId(o1 as unknown) && isHasEntityId(o2 as unknown)) {
        comparator = compareBySameProperty<HasEntityId>('entityId');
      } else if (isHasKey(o1) && isHasKey(o2)) {
        comparator = compareBySameProperty<HasKey>('key');
      } else {
        comparator = this.compareByReference;
      }

      return comparator(o1, o2);
    }
    return false;
  };

  private compareByReference = (o1: unknown, o2: unknown): boolean => {
    return o1 === o2;
  };
}
