import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';

import { asyncScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';

import { SortableTableColumnComponent } from '../component/sortable-table-column/sortable-table-column.component';

@Directive({
  selector: '[trfSortableColumnHeader]',
  exportAs: 'sortableElement',
})
export class SortableColumnHeaderDirective implements OnInit {
  @Input()
  key?: string;
  @Input()
  name: string;
  @Input()
  sortable = true;

  sortDirection: string;

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    const component = this.factoryResolver.resolveComponentFactory(SortableTableColumnComponent);
    const componentRef = this.viewContainer.createComponent(component);
    componentRef.instance.key = this.key;
    componentRef.instance.name = this.name;
    componentRef.instance.sortable = this.sortable;
    // async observable because of ExpressionChangedAfterItHasBeenCheckedError
    componentRef.instance.sendDirection
      .pipe(observeOn(asyncScheduler))
      .subscribe((value) => (this.sortDirection = value));
  }
}
