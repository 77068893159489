import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  ExtendedConnectedPosition,
  isTooltipString,
  isTooltipTemplate,
  NgpTooltipPosition,
  TooltipType,
} from '@demica/ui-core-api';

import { TooltipPositionUtilsService } from '../../services/tooltip-position-utils.service';

import { TooltipComponent } from './tooltip-component/tooltip.component';

export const TOOLTIP_OVERLAY_CLASS = 'ngp-tooltip-overlay';

@Directive({ selector: '[ngpTooltip]' })
export class NgpTooltipOnHoverDirective implements OnDestroy {
  @Input('ngpTooltip')
  tooltip!: TooltipType;
  @Input()
  ngpTooltipPosition: NgpTooltipPosition | string = NgpTooltipPosition.right;
  @Input()
  tooltipClasses = '';
  @Input()
  ngpTooltipTabIndex!: string;
  @Input()
  ngpTooltipRole!: string;
  @Input()
  ngpTooltipAriaLabel!: string;
  @HostBinding('style.cursor')
  cursorStyle = 'pointer';
  @HostBinding('attr.aria-label')
  public get ariaLabel() {
    return isTooltipString(this.tooltip) ? this.tooltip : this.ngpTooltipAriaLabel;
  }
  @HostBinding('attr.role')
  public get role() {
    return this.ngpTooltipRole || 'tooltip';
  }
  @HostBinding('attr.tabindex')
  public get tabIndex() {
    return this.ngpTooltipTabIndex || '0';
  }

  private overlayRef!: OverlayRef;
  private tooltipRef!: ComponentRef<TooltipComponent>;
  private positionChangeSubscription!: Subscription;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private positionUtils: TooltipPositionUtilsService,
  ) {}

  ngOnDestroy(): void {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef?.detach();
      this.positionChangeSubscription?.unsubscribe();
    }
  }

  @HostListener('focus')
  @HostListener('mouseenter')
  show() {
    if (!this.shouldShowTooltip() || this.overlayRef?.hasAttached()) return;
    this.buildOverlay();
    this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
    if (this.tooltipRef) {
      if (isTooltipString(this.tooltip)) {
        this.tooltipRef.instance.tooltip = this.tooltip;
      } else if (isTooltipTemplate(this.tooltip)) {
        this.tooltipRef.instance.tooltipTemplate = this.tooltip;
      }
      this.tooltipRef.instance.classes = this.tooltipClasses;
    }
  }

  @HostListener('blur')
  @HostListener('mouseleave')
  hide() {
    this.overlayRef?.detach();
    this.positionChangeSubscription?.unsubscribe();
  }

  buildOverlay() {
    const positions = this.positionUtils.positions;
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(
        [
          positions[this.ngpTooltipPosition as NgpTooltipPosition],
          ...Object.values(positions),
        ].filter((it) => !!it),
      );
    this.handlePositionChange(positionStrategy);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      panelClass: TOOLTIP_OVERLAY_CLASS,
    });
  }

  private handlePositionChange(positionStrategy: FlexibleConnectedPositionStrategy) {
    this.positionChangeSubscription = positionStrategy.positionChanges
      .pipe(
        tap((positionStrategy) =>
          this.tooltipRef.instance.setPosition(
            (positionStrategy.connectionPair as ExtendedConnectedPosition).position,
          ),
        ),
      )
      .subscribe();
  }

  private shouldShowTooltip() {
    const isValidString = isTooltipString(this.tooltip) && this.tooltip?.length;
    return isValidString || isTooltipTemplate(this.tooltip);
  }
}
