export const RANGE_FORM_PARAMS = {
  lowerRange: 'lowerRange',
  upperRange: 'upperRange',
  value: 'value',
};

export const RANGE_TYPE = {
  percentage: 1,
  amount: 2,
};

export const RATE_TYPE = {
  percentage: 1,
  amount: 2,
  baseRateMargin: 3,
};
