import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { asyncScheduler, Observable, Subject } from 'rxjs';
import { first, map, subscribeOn } from 'rxjs/operators';

import { generateUUID, getFormControlName, Transaction } from '@demica/core/core';

import { FiltersContext } from '../../service/filters-context.service';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import {
  TransactionInstance,
  TransactionInstanceReference,
} from '../../model/transaction-instance-reference.interface';

@Component({
  selector: 'trf-form-transaction-instance',
  templateUrl: './form-transaction-instance.component.html',
  styleUrls: ['./form-transaction-instance.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTransactionInstanceComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormTransactionInstanceComponent),
      multi: true,
    },
  ],
})
export class FormTransactionInstanceComponent
  implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit, Validator
{
  @Input()
  formControlName: string;
  @Input()
  formControl: FormControl;
  @Input()
  transaction: Transaction;
  @Input()
  validations: ValidationMessage[];
  @Input()
  selectFirst = true;
  @Input()
  filteredByGlobalFilters = false;
  @Input()
  additionalFilter: (transactionInstance: TransactionInstanceReference) => boolean;

  transactionInstances$: Observable<TransactionInstanceReference[]>;
  labelForId = generateUUID();
  selected: TransactionInstanceReference;
  isDisabled: boolean;
  private destroySubject = new Subject<void>();
  private onChange: (transactionInstance: TransactionInstanceReference) => void;
  private onTouched: () => void;
  private onValidationChange: () => void;
  private value: TransactionInstanceReference;

  constructor(private filtersContext: FiltersContext) {}

  ngOnInit(): void {
    this.formControlName = this.formControlName ?? getFormControlName(this.formControl);

    this.transactionInstances$ = this.filtersContext
      .transactionInstances$(this.filteredByGlobalFilters)
      .pipe(
        map((transactions) => {
          return transactions.sort((a, b) => a.transaction.name.localeCompare(b.transaction.name));
        }),
        map((transactions) => {
          if (this.additionalFilter) {
            return transactions.filter(this.additionalFilter);
          } else return transactions;
        }),
      );
  }

  ngAfterViewInit(): void {
    this.transactionInstances$
      .pipe(
        // asyn to avoid ExpressionChangedAfterItHasBeenCheckedError when setting selected properties after successfully subscription
        subscribeOn(asyncScheduler),
        first((value) => !!value),
      )
      .subscribe((value) => {
        if (this.selectFirst) {
          this.selected = value[0];
          this.change(value[0]);
        }
      });
  }

  writeValue(obj: TransactionInstanceReference | TransactionInstance): void {
    if (isTransactionInstance(obj)) {
      this.filtersContext
        .transactionInstances$(false)
        .pipe(
          first(),
          map((instances) =>
            instances.find(
              (instance) =>
                instance.environment.entityId === obj.environmentId &&
                instance.transaction.entityId === obj.transactionId,
            ),
          ),
        )
        .subscribe((instance) => (this.selected = instance));
    } else {
      this.selected = obj;
    }
  }

  registerOnChange(fn: (transactionInstance: TransactionInstanceReference) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  change(transactionInstance: TransactionInstanceReference) {
    this.value = transactionInstance;
    if (this.onChange) {
      this.onChange(transactionInstance);
      this.onTouched();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(): ValidationErrors | null {
    return this.value ? null : { requird: true };
  }
}

// TODO: TRFV2-3891 Refactor to proper types from "any" - type guard
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTransactionInstance(instance: any): instance is TransactionInstance {
  if (!instance) return false;
  return 'transactionId' in instance && 'environmentId' in instance;
}
