import { Pipe, PipeTransform } from '@angular/core';

import { MenuSectionConfig } from '@demica/core/core';

@Pipe({
  name: 'menuSectionCast',
  pure: true,
})
export class MenuSectionCastPipe implements PipeTransform {
  transform(value: unknown): MenuSectionConfig {
    return <MenuSectionConfig>value;
  }
}
