import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  NavigationService,
  PushRequest,
  PushRequestService,
  RelatedVersion,
} from '@demica/core/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const APPROVE = 'approve';
const REJECT = 'reject';

const CLIENT = 'client';
const OPCO = 'opco';
const TRANSACTION = 'transaction';

@Component({
  selector: 'trf-push-request-review-modal',
  templateUrl: 'push-request-review-modal.component.html',
  styleUrls: ['./push-request-review-modal.component.sass'],
})
export class PushRequestReviewModalComponent implements OnInit {
  @Input()
  pushRequest: PushRequest;
  @Input()
  pushRequestApproved: () => void;

  relatedVersions: RelatedVersion[] = [];

  loading = true;
  submitted = false;
  displayAlreadyReviewedWarning = false;

  form: UntypedFormGroup = this.fb.group({
    approvePush: [APPROVE, []],
    comment: ['', []],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private modal: NgbActiveModal,
    private navigate: NavigationService,
    private pushRequestService: PushRequestService,
  ) {}

  ngOnInit() {
    this.subscribeFormChanges();
    this.addRelatedVersionsToForm();
  }

  private subscribeFormChanges() {
    this.form.valueChanges.subscribe(() => {
      const changes = this.form.getRawValue();
      let approveVersionsContainsRejected = false;
      Object.keys(changes.approveVersions).forEach((index) => {
        if (changes.approveVersions[index] === REJECT) {
          approveVersionsContainsRejected = true;
        }
      });

      const approvePushFormControl = this.form.get('approvePush');
      if (approveVersionsContainsRejected) {
        approvePushFormControl.setValue(REJECT, { emitEvent: false, onlySelf: true });
        approvePushFormControl.disable({ emitEvent: false, onlySelf: true });
      } else {
        approvePushFormControl.enable({ emitEvent: false, onlySelf: true });
      }

      this.loading = false;
    });
  }

  private addRelatedVersionsToForm() {
    this.pushRequestService
      .getRelatedVersions(this.pushRequest.entityId)
      .subscribe((relatedVersions) => {
        const approveVersionsGroup = new UntypedFormGroup({});
        relatedVersions.forEach((relatedVersion) => {
          approveVersionsGroup.addControl(
            String(relatedVersion.subjectVersionId),
            this.createFormControl(relatedVersion),
          );
          relatedVersion.formLabel =
            relatedVersion.subjectName +
            ' (' +
            relatedVersion.subjectCode +
            ') - ' +
            relatedVersion.versionName;
        });
        this.form.addControl('approveVersions', approveVersionsGroup);
        this.displayAlreadyReviewedWarning = relatedVersions.some(
          (relatedVersion) => relatedVersion.valid != null,
        );
        this.relatedVersions = relatedVersions.sort((a) => (a.mainSubject ? -1 : 1));
      });
  }

  createFormControl(relatedVersion: RelatedVersion): UntypedFormControl {
    return new UntypedFormControl(
      {
        value: relatedVersion.valid === false ? REJECT : APPROVE,
        disabled: !(relatedVersion.valid === null || relatedVersion.valid === undefined),
      },
      Validators.required,
    );
  }

  onVersionClick(relatedVersion: RelatedVersion) {
    switch (relatedVersion.subjectType) {
      case CLIENT:
        this.navigate.clientPreview(relatedVersion.subjectId, {
          queryParams: { version: relatedVersion.subjectVersionId },
        });
        break;
      case OPCO:
        this.navigate.sellerPreview(relatedVersion.subjectId, {
          queryParams: { version: relatedVersion.subjectVersionId },
        });
        break;
      case TRANSACTION:
        this.navigate.transactionVersionPreview(relatedVersion.subjectId, {
          queryParams: { version: relatedVersion.subjectVersionId },
        });
        break;
    }
    this.onClose();
  }

  onSave() {
    const data = this.form.getRawValue();
    data.approvePush = data.approvePush === APPROVE;
    Object.keys(data.approveVersions).forEach(
      (index) => (data.approveVersions[index] = data.approveVersions[index] === APPROVE),
    );
    this.pushRequestService
      .approvePushRequest(this.pushRequest.entityId, data)
      .subscribe(this.handlePushRequestApproval.bind(this));
  }

  handlePushRequestApproval() {
    this.onClose();
    this.pushRequestApproved();
  }

  onClose() {
    this.modal.dismiss();
  }
}
