export interface TokensFromUrl {
  readonly accessToken: string;
  readonly refreshToken: string;
  readonly hasHybridFlowTokens: boolean;
  readonly expiredIn?: number;
}

export function acquireTokensFromUrl(): TokensFromUrl {
  const tokens = getTokensFromUrl();

  if (tokens.hasHybridFlowTokens) removeTokensFromUrl();

  return tokens;
}

function getTokensFromUrl(): TokensFromUrl {
  const urlParams = new URLSearchParams(location.hash?.substr(1));
  const tokenType = urlParams.get('token_type');
  const accessToken = urlParams.get('access_token');
  const refreshToken = urlParams.get('refresh_token');
  const expiredIn = urlParams.get('expires_in') ? parseInt(urlParams.get('expires_in')) : null;

  return {
    accessToken,
    refreshToken,
    hasHybridFlowTokens: accessToken?.length && refreshToken?.length && tokenType === 'Bearer',
    expiredIn,
  };
}

function removeTokensFromUrl() {
  window.location.replace('#');

  if (typeof window.history.replaceState == 'function')
    history.replaceState({}, '', window.location.href.slice(0, -1));
}
