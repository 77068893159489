import { Injectable } from '@angular/core';
import { NavigationEnd, RouterEvent } from '@angular/router';

import { Observable, forkJoin, fromEvent, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { AnalyticsEventTypes } from '@demica/resources/analytics';
import { AnalyticsClientType } from '@demica/resources/app-config';

import { AnalyticsDataBusService } from './analytics-data-bus.service';
import { AnalyticsClientsInitializerService } from './clients/analytics-clients-initializer.service';
import { AnalyticsServerSideClient } from './clients/server-side/analytics-server-side-client.service';

import { getLocationPath } from '../utils/window';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  withResources$ = (): Observable<{ lang: string }> => {
    return forkJoin({
      lang: this._analyticsDataBus.locale$.pipe(take(1)),
    });
  };

  constructor(
    private _analyticsClientsInitializer: AnalyticsClientsInitializerService,
    private _analyticsDataBus: AnalyticsDataBusService,
  ) {}

  initialize(activeClients: AnalyticsClientType[]): void {
    this._analyticsClientsInitializer.initClients(activeClients);

    if (this._analyticsClientsInitializer.hasActiveClient()) {
      this.unloadListener();
    }
  }

  captureNavigationEvent(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      this.withResources$().subscribe(({ lang }) => {
        this._analyticsClientsInitializer.each((client) =>
          client.postEvent(AnalyticsEventTypes.PAGE_LOAD, { url: getLocationPath(), lang }),
        );
      });
    }
  }

  logoutEvent(): Observable<null | unknown> {
    this._analyticsClientsInitializer.each((c) => c.postEvent(AnalyticsEventTypes.USER_LOGOUT));

    const serverSideClient: AnalyticsServerSideClient =
      this._analyticsClientsInitializer.clients.find(
        (client) => client.clientType === AnalyticsClientType.SERVER_SIDE,
      ) as AnalyticsServerSideClient;

    return serverSideClient ? serverSideClient.logoutFinish$ : of(null);
  }

  unloadListener(): void {
    fromEvent(window, 'beforeunload').subscribe(() => {
      this._analyticsClientsInitializer.each((c) => c.postEvent(AnalyticsEventTypes.UNLOAD_APP));
    });
  }
}
