import { ChartLegendPosition } from '../../model/chart-legend-position';
import { HorizontalLine } from '../line-chart/horizontal-line.interface';
import { PieChartValue } from './pie-chart-value';

export class PieChartData {
  constructor(
    public chartName: string,
    private values: PieChartValue[],
    public showLegend?: boolean,
    public legendPosition?: ChartLegendPosition,
    public isUnrated?: boolean,
  ) {}

  getChartValues(): string[][] {
    return this.values.map((value) => value.getData());
  }

  getChartValuesWithPercentage(): string[][] {
    return this.values.map((value) => value.getDataWithPercentage());
  }

  getChartValueNames(): string[] {
    return this.values.map((value) => value.name);
  }

  getPieChartValue(): PieChartValue[] {
    return this.values;
  }

  hasDataAvailable(): boolean {
    if (this.values === undefined) {
      return false;
    }

    return this.values.length > 0;
  }

  getChartName(): string {
    return this.chartName;
  }

  getYAxisLines(): HorizontalLine[] {
    return [];
  }
}
