import { Component, Input } from '@angular/core';

import { trimDecimalPlaces } from '@demica/core/core';

import { RangeRow } from '../../model/range.interface';
import { Actions } from '../actions/model/actions.interface';

@Component({
  selector: 'trf-ranges-list-row',
  templateUrl: './ranges-list-row.component.html',
  styleUrls: ['./ranges-list-row.component.sass'],
})
export class RangesListRowComponent {
  @Input()
  row: RangeRow;
  @Input()
  actions: Actions;
  @Input()
  numberOfDecimalPlacesForValue: number;

  getLowerRange() {
    return this.formatValue(this.row.lowerRange, this.row['rangePercentage']);
  }

  getUpperRange() {
    return this.formatValue(this.row.upperRange, this.row['rangePercentage']);
  }

  getValue() {
    return this.formatValue(
      trimDecimalPlaces(String(this.row.value), this.numberOfDecimalPlacesForValue),
      this.row['valuePercentage'],
    );
  }

  private formatValue(value: string | number, percentage: boolean) {
    return value == null || value === '' ? '' : value + (percentage ? ' %' : '');
  }
}
