import { Directive, HostListener } from '@angular/core';

import { DecimalFormatterDirective } from './decimal-formatter.directive';

@Directive({
  selector: '[trfCurrencyFormatter]',
})
export class CurrencyFormatterDirective extends DecimalFormatterDirective {
  @HostListener('paste', ['$event'])
  blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }
}
