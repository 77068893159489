<ng-container *ngIf="selectedLocalisation">
  <div
    #dropdownContainer
    class="localisations"
    role="application"
    (click)="$event.stopPropagation()"
    attr.aria-label="{{ 'HEADER.LOCALISATION' | translate }}"
    testid="localisation-header-text"
  >
    <button
      #dropdownButton
      class="trf-header-btn"
      [ngClass]="{ on: dropdownVisible }"
      (click)="toggleDropdown()"
      (keyup.escape)="closeDropdown()"
      (keyup.enter)="focusOnFirstElement()"
      testid="header-localisation-btn"
      attr.aria-label="{{
        (dropdownVisible
          ? 'HEADER.LOCALISATION_BUTTON_LABEL_EXPANDED'
          : 'HEADER.LOCALISATION_BUTTON_LABEL_COLLAPSED'
        ) | translate: { selectedLocale: 'LOCALISATIONS.' + selectedLocalisationCode() | translate }
      }}"
    >
      <span class="selectedLocalisation">{{
        "LOCALISATIONS." + selectedLocalisationCode() | translate | uppercase
      }}</span>
      <ngp-svg-icon
        class="dropdown-chevron"
        iconName="chevron-down"
        [ngClass]="{ dropdownVisible: dropdownVisible }"
      >
      </ngp-svg-icon>
    </button>

    <div
      *ngIf="dropdownVisible"
      class="dropdown"
      trfOutsideClickMonitor
      (trfOutsideClick)="closeDropdown()"
      [ignoreElements]="[dropdownButton]"
      testid="localisations-dropdown-container"
    >
      <ngp-inline-loader [loading]="loading"></ngp-inline-loader>

      <div class="localisations-header">
        <span>{{ "HEADER.SELECT_LOCALISATION" | translate }}</span>
      </div>
      <div
        *ngIf="!loading"
        class="localisations-list"
      >
        <ul
          #ulElement
          class="list-unstyled"
          role="listbox"
        >
          <li
            *ngFor="let locale of availableLocales; let i = index"
            class="localisation-item"
            trfArrowKeysFocus
            role="option"
            tabindex="0"
            [elementsLength]="availableLocales.length"
            [ngClass]="{ active: locale === selectedLocalisation }"
            [attr.testid]="'locale-' + locale"
            [index]="i"
            (keyup.escape)="closeDropdown($event)"
            (click)="setLocale(locale)"
            (keyup.enter)="setLocale(locale)"
            (focusOnFirst)="focusOnFirstElement()"
            (focusOnLast)="focusOnLastElement()"
          >
            <span class="flag-icon {{ flagCss(locale) }}"></span>
            <span class="currency-name">{{ "LOCALISATIONS." + locale | translate }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
