import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { encodeEndpoint } from '../../security/encode-url';
import { PageableExportParams } from './model/pageable-export-params';
import { PageableResponse } from './pageable-response.interface';

const WARNING_HEADER = 'incomplete-data';

@Injectable()
export class PageableExportDataResourceService {
  constructor(private http: HttpClient) {}

  postForPageableExportData(
    url: string,
    pageableExportParams: PageableExportParams,
  ): Observable<PageableResponse> {
    const urlEncoded = encodeEndpoint(url);
    return this.http
      .post(urlEncoded, pageableExportParams, {
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(
        map((res) => ({
          limit: res.headers.get(WARNING_HEADER),
          data: new Blob([res.body], { type: 'application/octet-stream' }),
        })),
      );
  }
}
