/*
  isKey function will only work if you have generic type specified for checked property
  In other case you can not use helper function, please do for type safety:

  if ('exampleKey' in copy) {
    delete copy['exampleKey'];
  }
 */
export function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
  return k in x;
}
