import { Injectable } from '@angular/core';

import { TranslationsPackage } from '@demica/resources/translations';

export function getLangPrefixFromLocale(locale: string): string {
  return locale.split('-')[0].toUpperCase();
}
@Injectable({
  providedIn: 'root',
})
export class FakeTranslationGeneratorService {
  generate = (obj: TranslationsPackage, langPrefix: string): TranslationsPackage => {
    const prefix = getLangPrefixFromLocale(langPrefix);

    return Object.entries(obj).reduce((prev, next) => {
      const [key, value] = next;

      if (typeof value === 'object') {
        const newObj = this.generate(value, langPrefix);
        return { ...prev, [key]: newObj };
      }

      return { ...prev, [key]: `${prefix}_${value}` };
    }, {});
  };
}
