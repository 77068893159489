<form [formGroup]="form">
  <div
    *ngIf="!!label"
    class="column-label"
  >
    <label
      for="navigation-dropdown"
      [attr.id]="label"
    >
      {{ label | translate }}
    </label>
  </div>

  <div class="column-input">
    <ng-select
      #select
      tabIndex="0"
      class="form-control"
      role="combobox"
      labelForId="navigation-dropdown"
      [attr.aria-expanded]="select.isOpen"
      [formControl]="form.controls.item"
      [items]="items"
      [placeholder]="label | translate"
      [searchable]="false"
      [clearOnBackspace]="false"
      [clearable]="false"
      [notFoundText]="'NG_SELECT.NOT_FOUND' | translate"
      [tabIndex]="-1"
      (close)="select.element.focus()"
      (keydown.delete)="select.clearModel()"
    >
      <ng-template
        ng-label-tmp
        let-item="item"
        let-clear="clear"
      >
        <span
          class="ng-value-label"
          [attr.title]="item.key | translate"
          data-testid="select-value-text"
        >
          {{ item.key | translate }}
        </span>
      </ng-template>

      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
      >
        <span
          [attr.title]="item.key | translate"
          data-testid="select-option-text"
        >
          {{ item.key | translate }}
        </span>
      </ng-template>
    </ng-select>
  </div>
</form>
