<ng-container [formGroup]="group">
  <div class="form-row">
    <div class="date-pickers">
      <trf-form-datepicker-row
        [control]="dateFromTargetControl"
        [label]="labelDateFrom"
        [reverseDirection]="true"
        [validations]="calendarScheduleStartDateValidations"
        [disabled]="disabled"
        [attr.testid]="dateFromTestIdName"
      ></trf-form-datepicker-row>
      <trf-form-datepicker-row
        [control]="dateToTargetControl"
        [label]="labelDateTo"
        [reverseDirection]="true"
        [validations]="calendarScheduleEndDateValidations"
        [disabled]="disabled"
        [attr.testid]="dateToTestIdName"
      ></trf-form-datepicker-row>
    </div>
    <div class="validation-error">
      <ngp-contextual-notifications [notifications]="notifications"></ngp-contextual-notifications>
    </div>
  </div>
</ng-container>
