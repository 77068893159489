import { ComponentRef, Injectable } from '@angular/core';

@Injectable()
export class TableAttributesSetterService {
  setAttributes(rowRefs: ComponentRef<unknown>[]): void {
    rowRefs.forEach((row: ComponentRef<unknown>) => {
      assignIndexToOrdinaryRow(row);
    });
  }
}

function assignIndexToOrdinaryRow(row: ComponentRef<unknown>): void {
  const tdElements: HTMLTableCellElement[] = Array.from(row.location.nativeElement.children);

  tdElements.forEach((item: HTMLTableCellElement, index: number) => {
    const actionElements = Array.from(item.getElementsByTagName('ngp-svg-icon')) as HTMLElement[];
    const buttonElements = Array.from(item.getElementsByTagName('ngp-button')) as HTMLElement[];

    if (actionElements.length > 0 || buttonElements.length > 0) {
      item.setAttribute('data-index', index.toString());
      item.setAttribute('role', 'cell');
      assignIndexToIconsOrButtons(item, buttonElements);
    } else {
      item.setAttribute('data-index', index.toString());
      item.setAttribute('tabindex', '-1');
      item.setAttribute('role', 'cell');
    }
  });
}

function assignIndexToIconsOrButtons(
  item: HTMLTableCellElement,
  buttonElements: HTMLElement[],
): void {
  const iElements = Array.from(item.getElementsByTagName('ngp-svg-icon')) as HTMLElement[];

  if (iElements.length > 0) {
    iElements.forEach((element, index) => element.setAttribute('data-index', index.toString()));
  }
  if (buttonElements.length > 0) {
    Array.from(buttonElements).forEach((value) => value.setAttribute('tabindex', '-1'));
  }
}
