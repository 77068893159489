import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const browserInfo = require('browser-info');

@Injectable({
  providedIn: 'root',
})
export class BrowserInfoService {
  getBrowserInfo() {
    return browserInfo();
  }
}
