import { Inject, Injectable, OnDestroy } from '@angular/core';

import { merge, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { LoggerService } from '@demica/logger';

import { AuthUserContextChangeClient } from '../../broadcast-channel/auth-user-context-change-client/auth-user-context-change-client.service';
import { UserService } from '../../user.service';
import { UserDetailsComparator } from './user-details-comparator.service';

import {
  AUTH_CONTEXT_EVENT_SOURCE,
  AuthContextChangeEventSourceInterface,
} from './context-change-sources/auth-context-change-event-source.interface';
import { UserAuthContextChangeReason } from './user-auth-context-change.reason';

@Injectable({
  providedIn: 'root',
})
export class UserAuthContextChangesService implements OnDestroy {
  userAuthContextChange$: Subject<UserAuthContextChangeReason> = new Subject();

  private _destroyed$ = new Subject<void>();

  constructor(
    @Inject(AUTH_CONTEXT_EVENT_SOURCE)
    private _authContextEventSources: AuthContextChangeEventSourceInterface[],
    private _logger: LoggerService,
    private _userService: UserService,
    private _authUserContextChangeClient: AuthUserContextChangeClient,
  ) {}

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  initialize(): void {
    this._initializeAuthContextEventsListener();
    this._initializeAuthContextChangeListener();
  }

  private _initializeAuthContextChangeListener(): void {
    this._userService.currentUser
      .pipe(
        filter((user) => !!user),
        switchMap((user) => UserDetailsComparator.getUserContextSummary(user)),
        takeUntil(this._destroyed$),
      )
      .subscribe({
        next: (userAuthContext) =>
          this._authUserContextChangeClient.broadcastAuthUserContext(userAuthContext),
      });
  }

  private _initializeAuthContextEventsListener(): void {
    merge(...this._getEventSources())
      .pipe(
        tap((reason) =>
          this._logger.addConsoleLog(
            `UserAuthContextChangesService propagate new change ${
              UserAuthContextChangeReason[reason as number]
            }`,
          ),
        ),
        takeUntil(this._destroyed$),
      )
      .subscribe(this.userAuthContextChange$);
  }

  private _getEventSources(): Observable<UserAuthContextChangeReason>[] {
    return this._authContextEventSources.map((source) => source.getEvents$());
  }
}
