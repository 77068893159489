export enum AnalyticsClientType {
  SERVER_SIDE = 'server_side',
  TEALIUM = 'tealium',
}

export interface AnalyticsConfig {
  bufferSize: number;
  bufferTimeout: number;
  activeConfigurations?: AnalyticsClientType[];
}
