export const FUNDING_FEES_FORM_PARAMS = {
  feeType: 'feeType',
  name: 'name',
  calculation: 'calculation',
  feeCurrency: 'feeCurrency',
  calculationCalendar: 'calculationCalendar',
  settlementCalendar: 'settlementCalendar',
  cutOffCalendar: 'cutOffCalendar',
  timeSeries: 'timeSeries',
  basis: 'basis',
  basisCurrency: 'basisCurrency',
  specificCurrency: 'specificCurrency',
  range: 'range',
  rateType: 'rateType',
  baseRate: 'baseRate',
  rateBasis: 'rateBasis',
  floor: 'floor',
  cap: 'cap',
};

export const FUNDING_FEES_FORM_SELECT_FIELDS = [
  FUNDING_FEES_FORM_PARAMS.feeType,
  FUNDING_FEES_FORM_PARAMS.calculation,
  FUNDING_FEES_FORM_PARAMS.feeCurrency,
  FUNDING_FEES_FORM_PARAMS.calculationCalendar,
  FUNDING_FEES_FORM_PARAMS.settlementCalendar,
  FUNDING_FEES_FORM_PARAMS.cutOffCalendar,
  FUNDING_FEES_FORM_PARAMS.timeSeries,
  FUNDING_FEES_FORM_PARAMS.basis,
  FUNDING_FEES_FORM_PARAMS.basisCurrency,
  FUNDING_FEES_FORM_PARAMS.specificCurrency,
  FUNDING_FEES_FORM_PARAMS.range,
  FUNDING_FEES_FORM_PARAMS.rateType,
  FUNDING_FEES_FORM_PARAMS.baseRate,
  FUNDING_FEES_FORM_PARAMS.rateBasis,
];

/**
 * These constants contain only a subset of real enum values
 * and they are used only to improve readability in
 * funding-fees-form.component.ts
 *
 * Field values are entityId of given option
 */
export const FUNDING_FEE_TYPE = {
  variableInterest: 5,
};

export const FUNDING_CALCULATION = {
  calculatedFee: 1,
  external: 2,
};

export enum FUNDING_AMOUNT_TYPE {
  none = 1,
  facalityAmount = 2,
  drawnAmount = 3,
  undrawnAmount = 4,
  transferred = 5,
}

export const FUNDING_AMOUNT_CURRENCY = {
  consolidatedAmount: 1,
  specificCurrency: 2,
};
