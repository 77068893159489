import { Injectable } from '@angular/core';

import { PageSortParameters } from '@demica/core/core';

import { ColumnDefinition } from '../component/data-table/column-definition.interface';

@Injectable()
export class HiddenColumnSortHelper {
  private _hiddenColumnsByDefault = new Set<string>();
  private _defaultSorting: PageSortParameters | null = null;

  setDefaultSorting(pageSort: PageSortParameters): void {
    this._defaultSorting = { ...pageSort };
  }

  resetSortingIfSortedColumnIsHidden(pageSort: PageSortParameters): PageSortParameters {
    const isHiddenColumn = this._hiddenColumnsByDefault.has(pageSort.sortColumn);

    if (isHiddenColumn) {
      return { ...this._defaultSorting };
    }

    return pageSort;
  }

  initColumns(columnDefinitions: ColumnDefinition[]): void {
    columnDefinitions
      .filter((column) => column.toggleable && !column.checked)
      .forEach((column) => this._hiddenColumnsByDefault.add(column.property));
  }
}
