<div class="trf-section-header">
  <h3>{{ "ALIASES_MANAGEMENT.LIST_OF_ALIASES" | translate }}</h3>
</div>
<div class="trf-section-content">
  <trf-data-table
    tableClasses="disabled-rows"
    [dataSource]="dataSource"
    [headerConfiguration]="headerConfig"
    [rowConfiguration]="rowConfig"
    [allSelected]="false"
    [tableLabel]="'ALIASES_MANAGEMENT.LIST_OF_ALIASES'"
    testid="reports-search-list"
  ></trf-data-table>
</div>
