import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
  transform<T>(value: T[], prediction: (item: T) => boolean): T[] {
    return value?.filter ? value.filter(prediction) : value;
  }
}
