import { UntypedFormGroup } from '@angular/forms';

import { objectEntryMap } from '@demica/core/core';

import { defaultMessageDefinitions } from './default-message-definitions';
import { SubmittedProvider, ValidationsCreatorFn } from './message-typedefs';
import { ValidationMessage, ValidationMessageDefinition } from './validation-message.interface';

/**
 * Map form validation message definitions to validation message provider functions.
 *
 * Use definitionGroupToMessages instead for groups of validation message definitions!
 * @param defs
 * @param formProvider
 * @param statusProvider
 */
export function definitionsToMessages(
  defs: ValidationMessageDefinition[],
  formProvider: () => UntypedFormGroup,
  statusProvider: () => boolean,
): ValidationMessage[] {
  return defs.map((definition) => ({
    key: definition.key,
    func: definition.func(formProvider, statusProvider),
    params: definition.params,
  }));
}

export function definitionGroupToMessages<T>(
  defsGroup: { [key in keyof T]: ValidationMessageDefinition[] },
  formProvider: () => UntypedFormGroup,
  statusProvider: () => boolean,
): { [key in keyof T]: ValidationMessage[] } {
  return objectEntryMap(defsGroup, ([k, defs]) => [
    k,
    definitionsToMessages(defs, formProvider, statusProvider),
  ]);
}

export function buildMessages(definitions: ValidationMessageDefinition[]) {
  const mergedDefinitions = [...definitions, ...defaultMessageDefinitions];

  return (form: UntypedFormGroup, subFn: SubmittedProvider) => {
    return new ValidationMessagesBuilder(form, subFn).fromDefinitions(mergedDefinitions).build();
  };
}

export class ValidationMessagesBuilder {
  validationMessages: ValidationMessage[] = [];
  form: UntypedFormGroup;
  submittedFn: () => boolean;

  constructor(f: UntypedFormGroup, subFn: SubmittedProvider) {
    this.form = f;
    this.submittedFn = subFn;
  }

  fromMessagesFunction(messagesFn: ValidationsCreatorFn) {
    this.validationMessages.push(...messagesFn(this.form, this.submittedFn));
    return this;
  }

  fromMessageObject(messageDefinition: ValidationMessageDefinition) {
    this.validationMessages.push({
      func: messageDefinition.func(this.form, this.submittedFn),
      key: messageDefinition.key,
      params: messageDefinition.params,
    });
    return this;
  }

  fromDefinitions(definitions: ValidationMessageDefinition[]) {
    definitions.forEach((definition) => this.fromMessageObject(definition));
    return this;
  }

  build() {
    return this.validationMessages;
  }
}
