import { SortDirection } from '@demica/resources/common';

import { Order, SortParams } from '../interface/sort-params.interface';
import { createSortParams } from '../model/pageable-data';

export const sortParamsToQueryString = (sp: SortParams): string => {
  return createSortParams({
    sortColumn: sp.property,
    sortDirection: sp.order === Order.ASC ? SortDirection.ASC : SortDirection.DESC,
  });
};
