import { Pipe, PipeTransform } from '@angular/core';

import {
  CurrenciesDecimalPlacesService,
  DECIMAL_FRACTION_CONST,
  LocalisationService,
} from '@demica/core/core';

import { DecimalValuePipe } from './decimal-value.pipe';

import BigNumber from 'bignumber.js';

@Pipe({
  name: 'currency',
})
export class CurrencyPipe extends DecimalValuePipe implements PipeTransform {
  constructor(
    protected currenciesFormat: CurrenciesDecimalPlacesService,
    protected localisationService: LocalisationService,
  ) {
    super(localisationService);
  }

  transform(value: number | string, currency: string | null = null): string {
    const fractionSize = currency
      ? this.currenciesFormat.currencyDecimalPlaces(currency)
      : this.fractionSize;

    value = value !== null ? DecimalValuePipe.replaceDecimalSeparator(String(value), '.') : '';

    return value
      ? new BigNumber(value.toString()).toFormat(fractionSize, BigNumber.ROUND_HALF_UP, {
          decimalSeparator: this.decimalSeparator,
          groupSeparator: this.thousandSeparator,
          groupSize: 3,
        })
      : value;
  }

  parse(value: number | string): string {
    let [integer, fraction = ''] = (value || '').toString().split(this.decimalSeparator);

    integer = integer.replace(
      new RegExp(this.thousandSeparator === '.' ? '\\.' : this.thousandSeparator, 'g'),
      '',
    );
    fraction =
      parseInt(fraction, 10) > 0 && this.fractionSize > 0
        ? this.decimalSeparator +
          (fraction + DECIMAL_FRACTION_CONST).substring(0, this.fractionSize)
        : '';

    return integer + fraction;
  }
}
