import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DictionaryEntry } from '../../model/dictionary-entry.interface';
import { encodeEndpoint } from '../../security/encode-url';

@Injectable()
export class DictionaryResourceService {
  constructor(private http: HttpClient) {}

  getDictionaries() {
    return this.http.get<Record<string, DictionaryEntry[]>>(
      encodeEndpoint('resources/transactions/dictionaries'),
    );
  }
}
