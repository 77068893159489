import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationEventService {
  private _applicationKillSubject$ = new Subject<void>();
  applicationKillEvent$ = this._applicationKillSubject$.asObservable();

  discardUserSubscriptionEvent(): void {
    this._applicationKillSubject$.next();
  }
}
