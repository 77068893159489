import {
  FormStyle,
  getLocaleDayNames,
  getLocaleId,
  getLocaleMonthNames,
  TranslationWidth,
} from '@angular/common';
import { Injectable } from '@angular/core';

import { LocalisationService } from '@demica/core/core';

import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  private _localeData: string;

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  constructor(private localisationService: LocalisationService) {
    super();
    // its ok because we can change locale only if picker is closed
    this.localisationService.locale.subscribe((locale) => (this._localeData = getLocaleId(locale)));
  }

  getWeekdayLabel(weekday: number): string {
    return this.getWeekdayShortName(weekday);
  }

  getWeekdayShortName(weekday: number): string {
    const weekdaysStartingOnSunday = getLocaleDayNames(
      this._localeData,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated,
    );
    const listOfWeekDaysNames = weekdaysStartingOnSunday.map(
      (day, index) => weekdaysStartingOnSunday[(index + 1) % 7],
    );

    return listOfWeekDaysNames[weekday - 1];
  }

  getMonthShortName(month: number): string {
    const listOfMonthNames = getLocaleMonthNames(
      this._localeData,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated,
    );

    return listOfMonthNames[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
}
