import { Injectable } from '@angular/core';

import { LocalesMappings } from '@demica/resources/app-config';

import { loadLocale } from '../../../../load-locale.helper';

import { LocaleLoadResult, LocaleLoader } from './locale-loader.interface';

@Injectable({
  providedIn: 'root',
})
export class LocaleWebpackLoader implements LocaleLoader {
  load(localesMappings: LocalesMappings): Promise<LocaleLoadResult[]> {
    return Promise.all(
      Object.entries(localesMappings).map(([localeId, localFilename]) =>
        loadLocale(localFilename).then(
          (m): LocaleLoadResult => {
            return {
              err: null,
              id: localeId,
              definitions: m.default,
            };
          },
          (err): LocaleLoadResult => {
            return {
              err,
              id: localeId,
              definitions: null,
            };
          },
        ),
      ),
    );
  }
}
