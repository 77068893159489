import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';

import { fromEvent, merge, Observable } from 'rxjs';
import { mapTo, throttleTime } from 'rxjs/operators';

import {
  KeepAliveEvent,
  KeepAliveEventsSource,
  KeepAliveEventType,
} from '../../keep-alive-session.types';

@Injectable({
  providedIn: 'root',
})
export class UserActivitiesEventsSourceService implements KeepAliveEventsSource {
  events$: Observable<KeepAliveEvent>;

  readonly throttleMilliseconds = 1000;

  constructor(@Inject(DOCUMENT) private _document: globalThis.Document, private _ngZone: NgZone) {
    this._initialize();
  }

  private _initialize(): void {
    const documentClick$ = fromEvent(this._document, 'click');
    const documentKeypress$ = fromEvent(this._document, 'keypress');

    this._ngZone.runOutsideAngular(() => {
      this.events$ = merge(documentClick$, documentKeypress$).pipe(
        mapTo({ broadcast: true, type: KeepAliveEventType.UserActivity }),
        throttleTime(this.throttleMilliseconds),
      );
    });
  }
}
