const analyticsEndpoint = '/resources/analytics';

const configFile = '/assets/client-assets/app-config.json';
const globalConfigFile = '/assets/global-app-config.json';

// It's a legacy endpoint to fetch configuration like feature toggles, decimal places and authorization url.
// Legacy endpoint can be removed after deploying 2.19 version on all instances.
// Task: https://support.demica.com/browse/TRFV2-24290
const legacyConfigurationEndpoint = '/resources/security/frontend-configuration';

const unsecuredResources = [
  analyticsEndpoint,
  configFile,
  globalConfigFile,
  legacyConfigurationEndpoint,
];

export function isUnsecuredResource(endpoint: string): boolean {
  return unsecuredResources.some((unsecuredResource) => !!endpoint.match(unsecuredResource));
}
