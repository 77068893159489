import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { User, UserService } from '@demica/core/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifHasAuthority] , [ifHasAnyAuthority] , [ifHasAllAuthorities] , [ifHasNotAuthority]',
})
export class IfHasAuthorityDirective implements OnInit, OnDestroy {
  @Input()
  ifHasAuthority?: string;
  @Input()
  ifHasAnyAuthority?: string[];
  @Input()
  ifHasAllAuthorities?: string[];
  @Input()
  ifHasNotAuthority?: string;

  private destroySubject = new Subject<void>();

  private createdView: ViewRef;

  constructor(
    private userService: UserService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
  ) {}

  ngOnInit(): void {
    if (!this.templateRef) return;

    if (
      !this.ifHasAuthority &&
      !this.ifHasAnyAuthority &&
      !this.ifHasNotAuthority &&
      !this.ifHasAllAuthorities
    ) {
      this.createdView = this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    // TODO TRFUI-4819: To discuss what we should do in case of permissions changing in the token
    this.userService.currentUser.pipe(first()).subscribe((user) => {
      if (this.createdView) {
        this.createdView.destroy();
      }
      if (this.ifHasAuthority && user.hasAuthority(this.ifHasAuthority)) {
        this.createdView = this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (this.ifHasAnyAuthority && this.userHasAnyAuthority(user, this.ifHasAnyAuthority)) {
        this.createdView = this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (
        this.ifHasAllAuthorities &&
        this.userHasAllAuthorities(user, this.ifHasAllAuthorities)
      ) {
        this.createdView = this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (this.ifHasNotAuthority && !user.hasAuthority(this.ifHasNotAuthority))
        this.createdView = this.viewContainer.createEmbeddedView(this.templateRef);
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private userHasAnyAuthority(user: User, authorities: string[]) {
    let userHasAnyAuthority = false;
    authorities.forEach((authority) => {
      if (user.hasAuthority(authority)) {
        userHasAnyAuthority = true;
      }
    });
    return userHasAnyAuthority;
  }

  private userHasAllAuthorities(user: User, authorities: string[]): boolean {
    return authorities.every((a) => user.hasAuthority(a));
  }
}
