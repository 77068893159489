import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { LocalisationService } from '@demica/core/core';

@Pipe({
  name: 'localisedNumber',
  pure: false,
})
export class LocalisedNumberPipe implements PipeTransform {
  private decimalPipe: DecimalPipe;

  constructor(private localisationService: LocalisationService) {
    this.localisationService.locale.subscribe(
      (locale) => (this.decimalPipe = new DecimalPipe(locale)),
    );
  }

  transform(value: number | string, digits = '1.0-10'): string {
    return this.decimalPipe.transform(value, digits);
  }
}
