<ng-container *ngIf="message">
  <ngp-svg-icon
    class="icon warning"
    iconName="notification-warning"
    data-testid="svg-icon-component"
  ></ngp-svg-icon>
  <span
    class="msg"
    data-testid="warning-notification-message"
    >{{ message }}</span
  >
</ng-container>
