export type MoveableTableRow = { id?: string };

export function canMoveUp(selected: MoveableTableRow, arrayData: MoveableTableRow[]): boolean {
  return !!selected && !!arrayData && arrayData.length > 1 && selected.id !== arrayData[0].id;
}

export function canMoveDown(selected: MoveableTableRow, arrayData: MoveableTableRow[]): boolean {
  return (
    !!selected &&
    !!arrayData &&
    arrayData.length > 1 &&
    selected.id !== arrayData[arrayData.length - 1].id
  );
}
