import { Component } from '@angular/core';

/*
  Empty component to force ActivateAuthorizedSiblingGuard to be fired.
  After angular 16 update, CanActivate guards stopped working with redirectTo router option.
 */
@Component({
  template: `<ng-content></ng-content>`,
})
export class ActivateAuthorizedSiblingEmptyComponent {}
