<trf-slidein-container
  [headerText]="'TRANSACTION_ALIASES_SLIDEIN.ENTITY_LABEL' | translate: titleParams"
  (slideinClose)="onClose()"
  testid="map-alias-values-slidein"
>
  <ngp-inline-loader [loading]="loading"></ngp-inline-loader>

  <div
    *ngIf="!loading"
    class="trf-section"
    trfSlideinFormChanges
    [previewMode]="previewMode"
  >
    <div class="trf-section-content">
      <legend>{{ "LEGEND.REQUIRED_ASTERISK" | translate }}</legend>
    </div>

    <div class="trf-section-content">
      <form
        [formGroup]="form"
        [ngClass]="{ 'trf-form-submitted': submitted }"
      >
        <trf-form-select-row
          label="TRANSACTION_ALIASES_SLIDEIN.FORM_DATA_MAP_TO"
          labelClasses="required"
          defaultLabel="TRANSACTION_ALIASES_SLIDEIN.FORM_DATA_MAP_TO_PLACEHOLDER"
          [control]="form.controls.analysisCodeId"
          [options]="analysisCodes"
          [validations]="requireValidationsMessages"
          testid="analysis-code"
        ></trf-form-select-row>

        <div
          *ngIf="!previewMode && isAnalysisCodeActive"
          class="analysis-code-input-wrapper"
        >
          <trf-form-text-row
            placeholder="TRANSACTION_ALIASES_SLIDEIN.ANALYSIS_CODE_PLACEHOLDER"
            [control]="form.controls.enterAnalysisCode"
            [validations]="maxLengthValidationsMessages"
            [maxLength]="maxLength"
            testid="enter-analysis-code"
          ></trf-form-text-row>

          <div class="analysis-code-add-button">
            <ngp-button
              key="TRANSACTION_ALIASES_SLIDEIN.ANALYSIS_CODE_BUTTON_ADD"
              [classes]="canAdd() ? 'primary' : 'inactive'"
              (click)="addAnalysisCode()"
              testid="add-analysis-group"
            ></ngp-button>
          </div>
        </div>
      </form>
    </div>
    <div class="trf-slidein-footer">
      <trf-button-controls
        positiveName="TRANSACTION_ALIASES_SLIDEIN.BUTTON_SAVE"
        negativeName="TRANSACTION_ALIASES_SLIDEIN.BUTTON_CLOSE"
        [form]="form"
        [previewMode]="previewMode"
        (positiveEvent)="onSave()"
        (negativeEvent)="onClose()"
      ></trf-button-controls>
    </div>
  </div>
</trf-slidein-container>
