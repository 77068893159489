import { TranslateService } from '@ngx-translate/core';

import { Version, isDefined } from '@demica/core/core';

export function isCurrentConfigurationSelected(
  selection: Version,
  translateService: TranslateService,
) {
  return (
    selection &&
    !isDefined(selection.entityId) &&
    selection.name == translateService.instant('VERSIONS.CURRENT_VERSION_DEFAULT_NAME')
  );
}

export function canPush(selection: Version): boolean {
  return (
    selection &&
    isDefined(selection.entityId) &&
    (selection.valid === null || selection.valid === undefined || selection.valid)
  );
}
