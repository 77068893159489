import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { finalize, first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { Locale } from '@demica/core/core';

const DEFAULT_LANG = Locale.EN_GB;

@Component({
  template: `
    <ng-container *ngIf="!loading; else loader"> <router-outlet></router-outlet> </ng-container>
    <ng-template #loader> <ngp-inline-loader [loading]="true"></ngp-inline-loader> </ng-template>
  `,
  styles: [],
})
export class AppInitializerComponent implements OnInit {
  loading = true;
  constructor(private route: ActivatedRoute, private translateService: TranslateService) {}

  ngOnInit(): void {
    const { lang = DEFAULT_LANG } = this.route.snapshot.params;

    this.translateService.setDefaultLang(DEFAULT_LANG);
    this.translateService
      .use(lang)
      .pipe(
        first(),
        finalize(() => (this.loading = false)),
      )
      .subscribe();
  }
}
