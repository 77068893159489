import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';

import { noop } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { Locale } from '@demica/core/core';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<unknown>((resolve: (value: unknown) => void) => {
      const getLocation = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      getLocation.then(() => {
        const defaultLang = Locale.EN_GB;
        translate.setDefaultLang(defaultLang);
        translate.use(defaultLang).subscribe(
          noop,
          (err) => {
            console.error('Translation service initialization failed ', err);
          },
          () => resolve(null),
        );
      });
    });
}
