export interface UsageRelation {
  errorCodesList: string[];
  usedByObjectId: string;
  contextObjectId?: string;
  contextObjectType?: string;
}

export interface SimpleUsageRelation {
  usedByObjectId: string;
  relation: string;
}

export const isSimpleUsageRelationObject = (
  object: UsageRelation | SimpleUsageRelation,
): object is SimpleUsageRelation => 'relation' in object;
