import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { LocalStorageService } from '../local-storage.service';

import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

export const AUTH_TOKEN = 'auth_token';
export const REFRESH_TOKEN = 'refresh_token';

@Injectable()
export class HybridFlowTokenStoreService implements OnDestroy {
  private _tokenOnAuthRefreshSuccess$ = this._keycloakService.keycloakEvents$.pipe(
    filter((ev) => ev.type === KeycloakEventType.OnAuthRefreshSuccess),
  );
  private _tokenOnAuthSuccess$ = this._keycloakService.keycloakEvents$.pipe(
    filter((ev) => ev.type === KeycloakEventType.OnAuthSuccess),
  );
  private _destroyed$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _keycloakService: KeycloakService,
    private _localStorageService: LocalStorageService,
  ) {}

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  init() {
    this._initTokenPersist();
  }

  getStoredTokens(): { accessToken: string; refreshToken: string } {
    return {
      accessToken: this._localStorageService.getItem(AUTH_TOKEN),
      refreshToken: this._localStorageService.getItem(REFRESH_TOKEN),
    };
  }

  clearTokens(): void {
    this._localStorageService.removeItem(AUTH_TOKEN);
    this._localStorageService.removeItem(REFRESH_TOKEN);
  }

  private _initTokenPersist(): void {
    merge(this._tokenOnAuthSuccess$, this._tokenOnAuthRefreshSuccess$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this._storeTokens());
  }

  private _storeTokens(): void {
    const { token, refreshToken } = this._keycloakService.getKeycloakInstance();
    this._localStorageService.setItem(AUTH_TOKEN, token);
    this._localStorageService.setItem(REFRESH_TOKEN, refreshToken);
  }
}
