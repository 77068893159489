/**
 * Access nested property with lambda... or return null if not present
 * @param lambda
 */
import { pluck } from './object';

export function orNull<T>(lambda: () => T) {
  try {
    return lambda();
  } catch (e) {
    if (e instanceof TypeError) return null;
    else throw e;
  }
}

/**
 * Access nested property with lambda... or return undefined if not present
 * @param lambda
 */
export const orUndefined = pluck;

/**
 * Put lambda in a nullable object wrapper.
 * The input lambda must be pure immutable function! No side-effects!
 * @param lambda
 * @constructor
 */
export function Nullable<T>(lambda: () => T) {
  return {
    hasValue(): boolean {
      return orUndefined(lambda) !== undefined;
    },

    or<X>(val: X) {
      const result = orUndefined(lambda);
      return result !== undefined ? result : val;
    },

    ifEmpty(consumer: (nullable: T) => void) {
      const result = orUndefined(lambda);
      if (result === undefined) consumer(result);
    },

    ifPresent(consumer: (nullable: T) => void) {
      const result = orUndefined(lambda);
      if (result !== undefined) consumer(result);
    },

    /**
     * Always returns false for undefined. Use hasValue for value presence tests.
     * @param val
     */
    equals(val: unknown) {
      if (this.hasValue()) return lambda() === val;

      return false;
    },

    isOneOf(values: unknown[]) {
      if (this.hasValue()) return values.includes(lambda());

      return false;
    },
  };
}
