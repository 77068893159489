import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { map, switchMap } from 'rxjs/operators';

import { AppConfigService } from '../service/app-config/app-config.service';
import { FeatureToggleService } from '../service/feature-toggle.service';
import { RoutingService } from '../service/routing.service';
import { UserService } from '../service/user.service';

import { TRFRoute } from '../interface/route.interface';

export const featureToggleGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const userService = inject(UserService);
  const routingService = inject(RoutingService);
  const appConfigResource = inject(AppConfigService);
  const featureToggleService = inject(FeatureToggleService);

  return canActivate();

  function canActivate() {
    return appConfigResource.appConfig$.pipe(
      // workaround for toggles missing in feature-toggle service
      switchMap(() => userService.currentUser),
      map((user) => {
        const requestedRoute = route.routeConfig as TRFRoute;

        const userHasRequiredAuthority = user.hasAuthority(requestedRoute.requiredAuthority);
        const hasFeatureToggleGroupEnabled = featureToggleService.hasGroupEnabled(
          requestedRoute.requiredFeatureToggleGroup,
        );
        const hasAllFeatureToggleGroupEnabled = featureToggleService.hasAllGroupsEnabled(
          requestedRoute.requiredAllFeatureToggleGroup,
        );

        if (
          !userHasRequiredAuthority ||
          !hasFeatureToggleGroupEnabled ||
          !hasAllFeatureToggleGroupEnabled
        )
          return routingService.redirectToValidRouteAccess(route, state, user);

        return userHasRequiredAuthority;
      }),
    );
  }
};
