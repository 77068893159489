import { Injectable } from '@angular/core';

import { ApiVersionNumber, Modal, PageableExportParamsType } from '@demica/core/core';

import { ExportStatusService } from '../model/export-status-service';

import { ModalExportComponent } from '../modal-export.component';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

const defaultOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass: 'trf-export',
};

@Injectable({ providedIn: 'root' })
export class ExportModalService {
  constructor(private modalService: NgbModal) {}

  openExportModal<T>(
    url: string,
    exportParams: PageableExportParamsType,
    nameKey: string,
    contextService: ExportStatusService,
    data?: {
      version?: ApiVersionNumber;
      modalOptions?: NgbModalOptions;
    },
  ): Modal<T> {
    const ref = this.modalService.open(ModalExportComponent, {
      ...defaultOptions,
      ...(data?.modalOptions || {}),
    });
    ref.componentInstance.url = url;
    ref.componentInstance.version = data?.version || ApiVersionNumber.V1;
    ref.componentInstance.exportParams = exportParams;
    ref.componentInstance.nameKey = nameKey;
    ref.componentInstance.contextService = contextService;
    return new Modal(ref);
  }
}
