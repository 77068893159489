export class ReverseCalendarIterator {
  private iteration = 0;

  constructor(private startDate: Date, private monthStep: number) {}

  next(): IteratorResult<Date> {
    const milliStep = getMillisStep(
      daysInMonth(this.startDate.getMonth(), this.startDate.getFullYear()),
    );
    return {
      value: (this.startDate = new Date(this.startDate.getTime() - milliStep)),
      done: ++this.iteration > this.monthStep,
    };
  }
}

function getMillisStep(maxDays: number) {
  return maxDays * 24 * 60 * 60 * 1000;
}

function daysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}
