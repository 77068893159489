<li
  cdkDrag
  cdkDropList
  ngpDragAndDropNested
  [cdkDragData]="item.id"
  [cdkDropListData]="item.children"
  [cdkDragDisabled]="previewMode"
  cdkDragLockAxis="y"
  [id]="item.uniqueId"
  (cdkDropListDropped)="onDragDrop$.next($event)"
  (cdkDropListExited)="addDraggingStyles($event)"
  [ngStyle]="styleData"
  class="ngp-dnd-element ngp-dnd-element-{{ nestingLevel - 1 }}"
  role="row"
>
  <ng-template
    #row
    rule="row"
  ></ng-template>
  <ul
    *ngIf="item.children.length"
    class="ngp-dnd-list ngp-dnd-list-{{ nestingLevel }}"
  >
    <ngp-drag-and-drop-nested-item
      *ngFor="let childItem of item.children"
      [onDragDrop]="onDragDrop$"
      [item]="childItem"
      [nestingLevel]="nestingLevel + 1"
      [styleData]="styleData"
      [rowConfiguration]="rowConfiguration"
      [parentName]="item.name"
      class="ngp-dnd-item ngp-dnd-item-{{ nestingLevel }} ngp-dnd-item-id{{ childItem.id }}"
      a11y-keyup-row-container
    ></ngp-drag-and-drop-nested-item>
  </ul>
</li>
