<div *ngIf="logoHolder.logoId">
  <ng-container *ngIf="url">
    <img
      class="img-fluid"
      [src]="url"
      [attr.alt]="'IMAGES.ALT_LOGO_TABLE' | translate: { name: logoHolder.name }"
      testid="logo-image"
    />
  </ng-container>
</div>
