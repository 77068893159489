import { DragDrop, DragRef } from '@angular/cdk/drag-drop';
import { ElementRef, Injectable, OnDestroy, Renderer2 } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MODAL_STYLE_PROPERTIES } from './movable-modal.props';

@Injectable()
export class MovableModalService implements OnDestroy {
  private boundary: HTMLDivElement;
  private destroySubject = new Subject<void>();
  private dragRef: DragRef;
  private dragHandlerRef: ElementRef<HTMLElement>;
  private modalContentRef: ElementRef<HTMLElement>;
  private MODAL_WRAPPER = '.modal-content';
  private BOUNDARY_HTML_ELEMENT_TAG_NAME = 'div';

  constructor(private dragDrop: DragDrop, private renderer: Renderer2) {}

  ngOnDestroy(): void {
    if (this.boundary) {
      document.body.removeChild(this.boundary);
    }
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  makeMovable(dragHandlerRef: ElementRef<HTMLElement>, modalContentRef: ElementRef<HTMLElement>) {
    const modalWindow = this.getModalWrapper();
    if (!modalWindow) return;
    this.dragHandlerRef = dragHandlerRef;
    this.modalContentRef = modalContentRef;
    this.boundary = this.createSyntheticBoundary(this.modalContentRef.nativeElement.clientHeight);
    this.dragRef = this.dragDrop
      .createDrag(modalWindow)
      .withBoundaryElement(this.boundary)
      .withHandles([this.dragHandlerRef]);
    this.dragRef.started
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => this.onDragStartHandler());
    this.dragRef.ended
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => this.onDragEndHandler());
  }

  private onDragEndHandler() {
    this.renderer.setStyle(
      this.dragRef.getRootElement(),
      'opacity',
      MODAL_STYLE_PROPERTIES.DEFAULT_OPACITY,
    );
    this.renderer.setStyle(
      this.dragRef.getRootElement(),
      'cursor',
      MODAL_STYLE_PROPERTIES.DEFAULT_CURSOR,
    );
  }

  private onDragStartHandler() {
    this.renderer.setStyle(
      this.dragRef.getRootElement(),
      'opacity',
      MODAL_STYLE_PROPERTIES.MOVE_OPACITY,
    );
    this.renderer.setStyle(
      this.dragRef.getRootElement(),
      'cursor',
      MODAL_STYLE_PROPERTIES.MOVE_CURSOR,
    );
  }

  private getModalWrapper() {
    const wrappers = document.querySelectorAll<HTMLDivElement>(this.MODAL_WRAPPER);
    return wrappers?.item(wrappers.length - 1);
  }

  private createSyntheticBoundary(modalSize: number) {
    const syntheticBoundary = this.renderer.createElement(this.BOUNDARY_HTML_ELEMENT_TAG_NAME);
    const styles = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: `calc(100% + ${modalSize}px)`,
    };
    Object.entries(styles).forEach(([k, v]) => this.renderer.setStyle(syntheticBoundary, k, v));
    this.renderer.appendChild(document.body, syntheticBoundary);
    return syntheticBoundary;
  }
}
