import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, TemplateRef } from '@angular/core';

import { NotificationIconDefinition, NotificationType } from '../notification.types';

const DEFAULT_DURATION = 200;
const CONTENT_TOP_MARGIN = '0.5rem';

@Component({
  selector: 'ngp-contextual-notification-body',
  templateUrl: './contextual-notification-body.component.html',
  styleUrls: ['./contextual-notification-body.component.sass'],
  animations: [
    trigger('expandAnimation', [
      state('true', style({ height: AUTO_STYLE, marginTop: CONTENT_TOP_MARGIN })),
      transition(':enter', [
        style({ height: '0', paddingTop: '0' }),
        animate(
          DEFAULT_DURATION + 'ms ease-in',
          style({ height: AUTO_STYLE, marginTop: CONTENT_TOP_MARGIN }),
        ),
      ]),
      transition(':leave', [
        animate(DEFAULT_DURATION + 'ms ease-out', style({ height: '0', marginTop: '0' })),
      ]),
    ]),
  ],
})
export class ContextualNotificationBodyComponent {
  @Input()
  type: NotificationType = 'error';

  @Input()
  expandable = false;

  @Input()
  set expandableHeader(expandableHeader: string | TemplateRef<unknown>) {
    if (expandableHeader instanceof TemplateRef) {
      this.expandableCustomTemplate = expandableHeader;
      this.expandableText = null;
    } else {
      this.expandableText = expandableHeader;
      this.expandableCustomTemplate = null;
    }
  }

  expandableCustomTemplate!: TemplateRef<unknown> | null;
  expandableText!: string | null;
  icons: NotificationIconDefinition = {
    error: 'notification-error',
    warning: 'notification-warning',
    info: 'notification-info',
    success: 'notification-success',
  };
}
