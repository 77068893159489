import { Injectable, Injector } from '@angular/core';

import { DefaultSelectComparatorStrategy } from './default-select-comparator-strategy.service';
import { FlatIdSelectComparatorStrategy } from './flat-id-select-comparator-strategy.service';
import { ReadableDictionarySelectComparatorStrategy } from './readable-dictionary-select-comparator-strategy.service';

import { HasKeyOrId } from '../../../model/has-key-or-id.interface';

export type SelectCompareStrategyType = 'default' | 'dictWithReadableValue' | 'flatIdValue';

export type SelectAllowedBindValues = 'key' | 'entityId';

export interface SelectComparator {
  bindValue?: SelectAllowedBindValues;
  compareFn?: (o1: Partial<HasKeyOrId>, o2: Partial<HasKeyOrId> | string | number) => boolean;
}

@Injectable({ providedIn: 'root' })
export class SelectComparatorStrategy {
  private strategies: Record<SelectCompareStrategyType, SelectComparator> = {
    default: this.injector.get(DefaultSelectComparatorStrategy),
    dictWithReadableValue: this.injector.get(ReadableDictionarySelectComparatorStrategy),
    flatIdValue: this.injector.get(FlatIdSelectComparatorStrategy),
  };

  constructor(private injector: Injector) {}

  getStrategy(type: SelectCompareStrategyType): SelectComparator {
    return this.strategies[type] ?? this.strategies.default;
  }
}
