import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { WithAbstractUsageRelations, isSimpleUsageRelationObject } from '@demica/core/core';

import { Action, Actions } from '../component/actions/model/actions.interface';

@Injectable()
export class UsageRelationsService<T extends WithAbstractUsageRelations> {
  constructor(private _translateService: TranslateService) {}

  buildActionsWithMessages(
    actions: Actions<T>,
    actionSubject: T,
    hasAnyUsages: boolean,
  ): Action<T>[] {
    const actionsAsArray = Object.values(actions);
    if (!hasAnyUsages) {
      return actionsAsArray;
    }

    return actionsAsArray.map((action) => {
      if (!(this._shouldIconBeInactiveBecauseHasUsages(action) && actionSubject.usageRelations)) {
        return action;
      }
      const allCodes = actionSubject.usageRelations.flatMap((usageRelation) =>
        isSimpleUsageRelationObject(usageRelation)
          ? usageRelation.relation
          : usageRelation.errorCodesList,
      );
      const uniqueCodesSet = new Set<string>(allCodes);

      const result = Array.from(uniqueCodesSet.values())
        .map((code) => `NOTIFICATION.ERROR_CODE.${code}`)
        .filter(
          (translationKey) => this._translateService.instant(translationKey) !== translationKey,
        );

      return {
        ...action,
        messages: result,
        inactiveWithMessages: !!result?.length,
      };
    });
  }

  private _shouldIconBeInactiveBecauseHasUsages(action: Action<T>): boolean {
    return action.actionId && action.actionId.hideWhenObjectIsUsed;
  }
}
