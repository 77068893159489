import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  extractPageEventResult,
  isSamePageParamsId,
  PageEventContainer,
} from './page-event-container';
import { PageableTableParameters } from './page-params.interface';

@Injectable()
export class PageLoaderService {
  private loaderSubject = new Subject<PageEventContainer<boolean>>();

  getLoaderObservable(): Observable<boolean> {
    return this.loaderSubject.pipe(map(extractPageEventResult));
  }

  getLoaderObservableFilteredById(id: string): Observable<boolean> {
    return this.loaderSubject.pipe(filter(isSamePageParamsId(id)), map(extractPageEventResult));
  }

  show(pageParams?: PageableTableParameters) {
    this.loaderSubject.next({ pageParams: pageParams, result: true });
  }

  hide(pageParams?: PageableTableParameters) {
    this.loaderSubject.next({ pageParams: pageParams, result: false });
  }
}
