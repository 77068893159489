export function getAxisTickCount(values: string[]) {
  if (values.length === 2) {
    const numValues = values.map((v) => parseFloat(v));
    const min = Math.min(...numValues);
    const max = Math.max(...numValues);
    const delta = max - min;

    // The delta between values is very small. C3 would render repeated labels in that case.
    // Instead we want to display just labels for minimum and maximum values.
    if (delta < 0.1) return 2;
  }

  // 'undefined' means that C3 should decide the tick count itself
  return undefined;
}
