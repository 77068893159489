import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { EMPTY, noop, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { AuthService } from '../service/auth/auth.service';
import { TokenStoreService } from '../service/auth/token-store.service';

import { isUnsecuredResource } from '../config/unsecured-resources';
import {
  AUTH_INTERCEPTOR_RETRY_STRATEGY_TOKEN,
  AuthInterceptorRetryStrategy,
} from '../model/auth/auth-interceptor-retry.strategy';
import { loginDetailsUrl } from './skip-token-refresh.interceptor';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  /** Is /login-details request "in-flight"? This specific request is used for token refreshes in case of 401s */
  retryInFlight = false;

  constructor(
    @Inject(AUTH_INTERCEPTOR_RETRY_STRATEGY_TOKEN)
    private retryStrategy: AuthInterceptorRetryStrategy,
    private tokenStore: TokenStoreService,
    private auth: AuthService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Guards
    if (
      // Don't request login-details again if such request is already "in-flight".
      req.url === loginDetailsUrl &&
      this.retryInFlight
    )
      return EMPTY;

    const retryHandler = this.createRetryErrorHandler(req, next);
    const logoutHandler = this.logoutErrorHandler;

    const retryFinalizer = () => (this.retryInFlight = false);
    const logoutFinalizer = noop;

    const errorHandler = this.retryStrategy.pickErrorHandler(req.url, retryHandler, logoutHandler);
    const finalizer = this.retryStrategy.pickFinalizer(req.url, retryFinalizer, logoutFinalizer);

    return next
      .handle(this.configureRequest(req))
      .pipe(catchError(errorHandler), finalize(finalizer));
  }

  private createRetryErrorHandler(req: HttpRequest<unknown>, next: HttpHandler) {
    return (err: HttpErrorResponse) => {
      if (err.status === 401) {
        this.retryInFlight = true;
        return this.renewTokenAndTryAgain(req, next);
      }
      return throwError(err);
    };
  }

  private logoutErrorHandler = (err: HttpErrorResponse) => {
    if (err.status === 401) this.auth.signOut();

    return throwError(err);
  };

  private renewTokenAndTryAgain(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.retryStrategy.renewTokenAndTryAgain(req, next);
  }

  private configureRequest(req: HttpRequest<unknown>) {
    return isUnsecuredResource(req.url) ? req : this.addAuthorizationHeader(req);
  }

  private addAuthorizationHeader(req: HttpRequest<unknown>) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.tokenStore.getToken()}`,
      },
    });
  }
}
