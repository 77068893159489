import { PageableTableParameters } from './page-params.interface';

export interface PageEventContainer<T> {
  pageParams: PageableTableParameters;
  result: T;
}

export function isSamePageParamsId(id: string) {
  return function <T>(response: PageEventContainer<T>) {
    return response.pageParams.pageParamsId === id;
  };
}

export function extractPageEventResult<T>(response: PageEventContainer<T>) {
  return response.result;
}
