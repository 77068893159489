import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AppConfigResource } from '@demica/resources/app-config';

import { TransactionResourceService } from './_domain/transaction/_common/service/transaction-resource.service';
import { FileFormatTemplateResourceService } from './_domain/transaction/file-format/service/file-format-template-resource.service';
import { OrganisationResourceService } from './_domain/transaction/organisation/service/organisation-resource.service';
import { ReceivableTypeResourceService } from './_domain/transaction/receivable-type/service/receivable-type-resource.service';
import { ReserveResourceService } from './_domain/transaction/reserve/service/reserve-resource.service';
import { TimeSeriesResourceService } from './_domain/transaction/time-series/service/time-series-resource.service';
import { TimeSeriesTemplateResourceService } from './_domain/transaction/time-series/service/time-series-template-resource.service';
import { AppConfigService } from './service/app-config/app-config.service';
import { BroadcastChannelModule } from './service/broadcast-channel/broadcast-channel.module';
import { ColumnDefinitionsUiConfigFactory } from './service/column-definitions-ui-config-factory.service';
import { CurrenciesDecimalPlacesService } from './service/currencies-decimal-places.service';
import { DictionaryService } from './service/dictionary.service';
import { FeatureToggleService } from './service/feature-toggle.service';
import { FxRatesService } from './service/fx-rates.service';
import { InactivityLogoutModule } from './service/inactivity-logout/inactivity-logout.module';
import { KeepAliveSessionModule } from './service/keep-alive-session/keep-alive-session.module';
import { LocaleWebpackLoader } from './service/localisation/loaders/locale-webpack-loader.service';
import { LOCALE_LOADER, LocalisationService } from './service/localisation/localisation.service';
import { MenuService } from './service/menu.service';
import { MoneyService } from './service/money.service';
import { NavigationService } from './service/navigation.service';
import { NotAvailableResourceService } from './service/not-available-resource/not-available-resource.service';
import { NotificationService } from './service/notification.service';
import { OneTrustService } from './service/one-trust/one-trust.service';
import { PageableExportDataResourceService } from './service/pageable-export/pageable-export-resources.service';
import { PageableExportService } from './service/pageable-export/pageable-export.service';
import { PageLoaderService } from './service/paging/page-loader.service';
import { SybasePageResourceService } from './service/paging/sybase/sybase-page-resource.service';
import { SybasePageService } from './service/paging/sybase/sybase-page.service';
import { AliasesResourceService } from './service/rest/aliases-resource.service';
import { CountriesService } from './service/rest/countries.service';
import { CurrenciesService } from './service/rest/currencies.service';
import { DashboardFiltersResourceService } from './service/rest/dashboard-filters-resource.service';
import { DictionaryResourceService } from './service/rest/dictionary-resource.service';
import { DisplayUnitsResourceService } from './service/rest/display-units-resource.service';
import { EnvironmentResourceService } from './service/rest/envrionment-resource';
import { PushRequestService } from './service/rest/push-request.service';
import { SubjectValidityService } from './service/rest/subject-validity.service';
import { UserAccountResourceService } from './service/rest/user-account-resource.service';
import { RoutingService } from './service/routing.service';
import { SlideinContext } from './service/slidein/slidein-context';
import { StatesService } from './service/states.service';
import { ThemingService } from './service/theming.service';
import { TitleChangerService } from './service/title-changer.service';
import { UserPreferences } from './service/user-preferences/user-preferences.service';
import { UserService } from './service/user.service';

import { ActivateAuthorizedSiblingEmptyComponent } from './guard/activate-authorized-sibling-empty.component';

import { SellerCodeValidator } from './_domain/seller/validation/seller-code.validator';
import { ReserveNameValidator } from './_domain/transaction/reserve/validation/reserve-name-validator';
import { API_V1, API_V1_CONFIG, API_V2, API_V2_CONFIG } from './di/api-provider';

const resourceServices = [
  AliasesResourceService,
  AppConfigResource,
  EnvironmentResourceService,
  FileFormatTemplateResourceService,
  OrganisationResourceService,
  PageableExportDataResourceService,
  PageableExportService,
  PageLoaderService,
  PushRequestService,
  ReceivableTypeResourceService,
  ReserveResourceService,
  RoutingService,
  SubjectValidityService,
  SybasePageResourceService,
  SybasePageService,
  TimeSeriesResourceService,
  TimeSeriesTemplateResourceService,
  TransactionResourceService,
  UserAccountResourceService,
];

/*
 https://angular.io/guide/ngmodule#the-core-module
 */
@NgModule({
  declarations: [ActivateAuthorizedSiblingEmptyComponent],
  imports: [KeepAliveSessionModule, InactivityLogoutModule, BroadcastChannelModule],
  providers: [
    LocalisationService,
    UserService,
    NotAvailableResourceService,
    MenuService,
    AppConfigService,
    CountriesService,
    CurrenciesService,
    { provide: API_V1, useValue: API_V1_CONFIG },
    { provide: API_V2, useValue: API_V2_CONFIG },
    { provide: LOCALE_LOADER, useFactory: () => new LocaleWebpackLoader() },
    NavigationService,
    NotificationService,
    StatesService,
    UserPreferences,
    SlideinContext,
    FeatureToggleService,
    CurrenciesDecimalPlacesService,
    FxRatesService,
    DictionaryResourceService,
    DictionaryService,
    DashboardFiltersResourceService,
    SellerCodeValidator,
    ReserveNameValidator,
    DisplayUnitsResourceService,
    ColumnDefinitionsUiConfigFactory,
    ThemingService,
    TitleChangerService,
    MoneyService,
    OneTrustService,
    ...resourceServices,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
