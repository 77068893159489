import { InjectionToken } from '@angular/core';

import { apiUrl, apiV2Url } from '../config/api.config';
import { encodeUrl } from '../security/encode-url';

export const API_V1 = new InjectionToken<ApiVersion>('api-provider');
export const API_V2 = new InjectionToken<ApiVersion>('api-provider');

export interface ApiVersion {
  getEncodedEndpoint(template: string, ...params: (string | number)[]): string;
}

export const API_V1_CONFIG: ApiVersion = {
  getEncodedEndpoint(template: string, ...params): string {
    return `${apiUrl}/${encodeUrl(template, ...params)}`;
  },
};

export const API_V2_CONFIG: ApiVersion = {
  getEncodedEndpoint(template: string, ...params): string {
    return `${apiV2Url}/${encodeUrl(template, ...params)}`;
  },
};
