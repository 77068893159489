import { Injectable } from '@angular/core';
import { AsyncValidatorFn } from '@angular/forms';

import { AliasesResourceService, EntityId, maxPredefinedColumnLength } from '@demica/core/core';

import { requiredMsg } from '../forms/validation-messages/common-validation-messages';
import { buildMessages } from '../forms/validation-messages/message-builder';
import { msgMaxLength } from '../forms/validation-messages/message-definitions';
import {
  COUNTRY_COLUMN_ID,
  CURRENCY_COLUMN_ID,
} from '../model/alias/inactive-analysis-groups-constants';
import { AliasesValidator } from './analysis-code-form-validations';

@Injectable()
export class AliasFormValidations {
  requireValidationsMessages = requiredMsg;
  maxLengthValidationsMessages = buildMessages([msgMaxLength(maxPredefinedColumnLength)]);

  constructor(private service: AliasesResourceService) {}

  public hasAvailableCountryCode(transactionId: EntityId): AsyncValidatorFn {
    return AliasesValidator.hasCodeAvailable(this.service, transactionId, COUNTRY_COLUMN_ID);
  }

  public hasAvailableCurrencyCode(transactionId: EntityId): AsyncValidatorFn {
    return AliasesValidator.hasCodeAvailable(this.service, transactionId, CURRENCY_COLUMN_ID);
  }
}
