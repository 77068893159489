import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

import { isDefined } from '@demica/core/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[disableControl]',
})
export class DisableControlDirective implements OnChanges {
  constructor(private ngControl: NgControl) {}

  @Input()
  disableControl?: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disableControl && isDefined(this.disableControl)) {
      if (this.disableControl) this.ngControl.control.disable();
      else this.ngControl.control.enable();
    }
  }
}
