import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngp-text-ellipsis',
  templateUrl: './text-ellipsis.component.html',
  styleUrls: ['./text-ellipsis.component.sass'],
})
export class TextEllipsisComponent {
  @Input()
  text: string | null = '';
  @Input()
  textLength = 130;

  isOpen = false;

  toggleText(): void {
    this.isOpen = !this.isOpen;
  }
}
