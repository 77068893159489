import { Injectable } from '@angular/core';

import { StructuredProgrammeUiConfig } from '../model/column-definitions-ui-config';

@Injectable({
  providedIn: 'root',
})
export class ColumnDefinitionsUiConfigFactory {
  forTransaction() {
    return new StructuredProgrammeUiConfig();
  }
}
