<ngp-button
  classes="tertiary"
  key="{{ buttonTranslationPrefix + '.' + 'TOGGLE_COLUMNS' }}"
  [ngClass]="{ on: dropdownVisible }"
  (click)="toggleDropdown()"
  (keyup.escape)="closeDropdown()"
  testid="toggle-columns-button"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
></ngp-button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownVisible"
>
  <div
    class="toggle-column-list"
    trfOutsideClickMonitor
    (trfOutsideClick)="closeDropdown()"
    [ignoreElements]="[triggerButton.nativeElement]"
    testid="toggle-column-list"
  >
    <ul class="list-unstyled">
      <li *ngFor="let toggle of columns$ | async">
        <ngp-checkbox
          #checkbox
          [checked]="toggle.checked"
          [labelText]="toggle.nameKey"
          [attr.testid]="'toggle-' + (toggle.nameKey | spaceToDashesPipe)"
          (checkboxChecked)="setToggleColumns(checkbox.checked, toggle)"
        ></ngp-checkbox>
      </li>
    </ul>
  </div>
</ng-template>
