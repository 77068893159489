import { Component, Input } from '@angular/core';

@Component({
  selector: 'trf-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.sass'],
})
export class ModalLoaderComponent {
  @Input()
  loadingData: boolean;
  @Input()
  message: string;
}
