import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';

import { UserService } from './user.service';

import { RequiredAuthority } from '../interface/route.interface';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private _userService: UserService) {}

  hasAuthority(authority: string | RequiredAuthority): Observable<boolean> {
    return this._userService.currentUser.pipe(
      map((user) => user.hasAuthority(authority)),
      distinctUntilChanged(),
    );
  }

  hasAuthoritySync(authority: string | RequiredAuthority): boolean {
    return this._userService.getUserSync().hasAuthority(authority);
  }

  whenHasAuthority(authorityName: string | RequiredAuthority): Observable<User> {
    return this._userService.currentUser.pipe(
      first(),
      filter((user) => user.hasAuthority(authorityName)),
    );
  }
}
