import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { API_URL } from '../../_commons_';
import { AppConfig } from '../model/app-config.model';
import { FallbackConfig } from '../model/interface/fallback-config.model';

@Injectable()
export class AppConfigResource {
  constructor(private _http: HttpClient) {}

  getAppConfig$(): Observable<AppConfig> {
    return this._http.get<AppConfig>('/assets/client-assets/app-config.json');
  }

  getFallbackConfigFromAssets$(): Observable<FallbackConfig> {
    return this._http.get<FallbackConfig>('/assets/global-app-config.json');
  }

  getFallbackConfigFromFromLegacyEndpoint$(): Observable<FallbackConfig> {
    return this._http.get<FallbackConfig>(`${API_URL}/resources/security/frontend-configuration`);
  }
}
