import { Component, Input } from '@angular/core';

import { AnalysisCode } from '@demica/core/core';

import { Actions } from '../../../actions/model/actions.interface';

@Component({
  selector: 'trf-aliases-management-list-row',
  templateUrl: './aliases-management-list-row.component.html',
  styleUrls: ['./aliases-management-list-row.component.sass'],
})
export class AliasesManagementListRowComponent {
  @Input()
  row: AnalysisCode;

  @Input()
  actions: Actions;

  @Input()
  showTransaction: boolean;
}
