/* Constants for component styles directly specified in TS */

export const CSS_VARIABLES_PROP_NAME = 'TRF_CSS_VARIABLES' as const;

export const DISPLAY_BLOCK = ':host { display: block }';

/** The minimum time a spinner should be visible while loading data.
 This is to prevent a visual effect where spinner shows up and immediately disappears -
 which to the user may look like a glitch or a bug. The spinner should be visible at least a few
 hundred ms so that user actually catches that something was actually loading.
 */
export const MINIMUM_SPINNER_TIME = 200;
