import { Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { FormDecimalRowComponent } from '../form-decimal-row/form-decimal-row.component';

@Component({
  selector: 'trf-form-decimal-group-row',
  templateUrl: 'form-decimal-group-row.component.html',
  styleUrls: ['./form-decimal-group-row.component.sass'],
})
export class FormDecimalGroupRowComponent extends FormDecimalRowComponent implements OnInit {
  @Input()
  onClickHandler?: () => void;
  @Input()
  prependIconClass?: string;
  @Input()
  prependText?: string;
  @Input()
  appendIconClass?: string;
  @Input()
  appendText?: string;
  @Input()
  disable? = false;

  constructor(protected fgd: FormGroupDirective) {
    super(fgd);
  }

  onClick(): void {
    this.onClickHandler();
  }
}
