import { buildMessages } from './message-builder';
import {
  msgDateTimeFormat,
  msgDefaultMaxLength,
  msgDefaultNumberMaxLength,
  msgEmailFormat,
  msgEndDateBeforeStartDate,
  msgLogoFileToBig,
  msgMaxLength,
  msgMaxNumber,
  msgMinNumber,
  msgNgbDate,
  msgRequired,
  msgWrongLogoFileFormat,
} from './message-definitions';

export const nameMsgs = buildMessages([msgRequired, msgDefaultMaxLength]);
export const requiredMsg = buildMessages([msgRequired]);
export const numberMinZeroMsg = buildMessages([msgMinNumber(0)]);
export const numberMinMaxMsgs = (min: number, max: number) =>
  buildMessages([msgMinNumber(min), msgMaxNumber(max)]);
export const ngbDateMsg = buildMessages([msgNgbDate]);
export const requiredDateMsgs = buildMessages([msgRequired, msgNgbDate]);
export const defaultMaxLengthMsg = buildMessages([msgDefaultMaxLength]);
export const emailFormatMsg = buildMessages([msgEmailFormat, msgDefaultMaxLength]);
export const requiredTextFieldMsgs = buildMessages([msgRequired, msgDefaultMaxLength]);
export const maxLengthFieldMsgs = (maxLength: number) =>
  buildMessages([msgRequired, msgMaxLength(maxLength)]);
export const requiredDecimalFieldMsgs = buildMessages([msgRequired, msgDefaultNumberMaxLength]);
export const requiredDateTimeFormatMsgs = buildMessages([msgRequired, msgDateTimeFormat]);
export const logoValidationMsgs = buildMessages([msgLogoFileToBig, msgWrongLogoFileFormat]);
export const endDateBeforeStartDateMsg = buildMessages([msgEndDateBeforeStartDate]);
