export interface CheckboxStates {
  enabled: boolean;
  default: boolean;
}

export enum SelectedSpecificAction {
  Undefined = -1,
  None = 1,
  Default = 2,
  WriteOff = 3,
}

export class ItemStatusesUtils {
  private defaultActionCheckboxesStates = new Map<SelectedSpecificAction, CheckboxStates[]>();

  constructor() {
    // ToDo  refactor those arrays to objects/maps with proper names
    this.defaultActionCheckboxesStates.set(SelectedSpecificAction.None, [
      { enabled: true, default: false },
      { enabled: true, default: false },
      { enabled: true, default: false },
      { enabled: true, default: false },
      { enabled: true, default: false },
      { enabled: true, default: false },
      { enabled: true, default: false },
    ]);

    this.defaultActionCheckboxesStates.set(SelectedSpecificAction.Default, [
      { enabled: true, default: false },
      { enabled: false, default: false },
      { enabled: false, default: false },
      { enabled: true, default: false },
      { enabled: false, default: true },
      { enabled: false, default: false },
      { enabled: false, default: false },
    ]);

    this.defaultActionCheckboxesStates.set(SelectedSpecificAction.WriteOff, [
      { enabled: false, default: false },
      { enabled: false, default: false },
      { enabled: false, default: false },
      { enabled: false, default: true },
      { enabled: false, default: true },
      { enabled: false, default: false },
      { enabled: false, default: false },
    ]);

    this.defaultActionCheckboxesStates.set(SelectedSpecificAction.Undefined, [
      { enabled: false, default: false },
      { enabled: false, default: false },
      { enabled: false, default: false },
      { enabled: false, default: false },
      { enabled: false, default: false },
      { enabled: false, default: false },
      { enabled: false, default: false },
    ]);
  }

  public getDefaultActionCheckboxes(key: number) {
    return this.defaultActionCheckboxesStates.get(key);
  }
}
