/**
 * @param value Value without any additional formatting.
 * Value should contain only digits, optional minus sign and single optional dot as decimal separator.
 */
import BigNumber from 'bignumber.js';

export const removeTrailingZeros = (value: string): string => {
  const bigNumber = new BigNumber(value);
  return bigNumber.isNaN() ? value : bigNumber.toString();
};
