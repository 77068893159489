import { DecimalRegexBuilder } from './decimal-regex.builder';

export function createMaxDecimalPlacesRegex(decimalPlaces: number): RegExp {
  return new DecimalRegexBuilder()
    .setDecimalPlacesInt(decimalPlaces)
    .setAcceptNegatives(true)
    .build();
}

export function createMaxIntegerPlacesRegex(integerPlaces: number): RegExp {
  return new DecimalRegexBuilder()
    .setIntegerPlacesInt(integerPlaces)
    .setAcceptNegatives(true)
    .build();
}
