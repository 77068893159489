export enum ReserveType {
  TYPE_YIELD = 1,
  TYPE_DILUTION = 2,
  TYPE_LOSS = 3,
  TYPE_FX = 4,
  TYPE_OTHER = 5,
}

export enum ChartReservesTypes {
  YIELD = 1,
  DILUTION = 2,
  LOSS = 3,
  FX = 4,
  OTHER = 5,
  XS = 999,
  POOL,
}
