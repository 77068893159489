import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const ANIMATION = {
  TIME: '200ms',
};

@Component({
  selector: 'ngp-toggle-icon',
  templateUrl: './toggle-icon.component.html',
  styleUrls: ['./toggle-icon.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('rotate', [
      state('false', style({ transform: 'rotate(0deg)' })),
      state('true', style({ transform: 'rotate(90deg)' })),
      transition('false <=> true', animate(ANIMATION.TIME)),
    ]),
  ],
})
export class ToggleIconComponent {
  @Input() expanded = false;
}
