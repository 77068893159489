import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngp-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.sass'],
})
export class EmptyComponent {
  @Input()
  key = '';
  @Input()
  isVisible = false;
  @Input()
  height?: string;
}
