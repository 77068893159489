export enum HorizontalLabelPosition {
  Start = 'start',
  Middle = 'middle',
  End = 'end',
}

export interface HorizontalLine {
  // FIXME add readonly to attributes and refactor rest of the code
  text: string;
  value: number;
  position?: HorizontalLabelPosition;
  currency?: string;
}
