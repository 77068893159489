import { Component, Input } from '@angular/core';

import { EmittedFormValue } from '@demica/core/core';
import { SellerBankAccount } from '@demica/resources/seller';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'trf-seller-bank-account-slidein',
  templateUrl: './seller-bank-account-slidein.container.html',
  styleUrls: ['./seller-bank-account-slidein.container.sass'],
})
export class SellerBankAccountSlideinContainerComponent {
  @Input()
  bankAccountData: SellerBankAccount;

  submitted = false;
  bankAccountDetails: EmittedFormValue<SellerBankAccount> = { data: null, isValid: false };

  constructor(private modal: NgbActiveModal) {}

  bankAccountDetailsChanged(bankAccountDetails: EmittedFormValue<SellerBankAccount>) {
    this.bankAccountDetails = bankAccountDetails;
  }

  onSave() {
    this.submitted = true;
    if (this.bankAccountDetails.isValid) {
      const payload: SellerBankAccount = {
        ...this.bankAccountDetails.data,
        id: this.bankAccountData ? this.bankAccountData.id : null,
      };

      this.modal.close(payload);
    }
  }

  onClose() {
    this.modal.dismiss();
  }
}
