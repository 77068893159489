import { MenuItemConfig, MenuSectionConfig } from '../config/menu-option.interface';
import { recursiveCopy } from '../utils/array-utils';

export interface MenuOption {
  id: string;
  enabled: boolean;
  element: MenuSectionConfig | MenuItemConfig;
  options?: MenuOption[];
}

// TODO: TRFUI - 4308. Refactor as much of the code base using MenuOptions[] to use this class instead.

/**
 *Menu options immutable composite for traversing the menu options tree structure in a safe manner.
 */
export class MenuOptions {
  constructor(private readonly options: MenuOption[]) {}

  filter(predicate: (opt: MenuOption) => boolean) {
    const result = this.options.map(copy).filter(function _filter(o): boolean {
      if (o.options) return !!(o.options = o.options.map(copy).filter(_filter)).length;

      if (predicate(o)) return true;
    });

    return new MenuOptions(result);
  }

  asArray() {
    const optionsProperty: keyof MenuOption = 'options';
    return recursiveCopy(this.options, optionsProperty);
  }
}

function copy(o: MenuOption) {
  return { ...o };
}
