import { Injectable } from '@angular/core';

import { filter, first } from 'rxjs/operators';

import { LogArea, LoggerService } from '@demica/logger';

import { AuthService } from './auth.service';

import { LOGOUT_REASONS } from './logout-redirect/logout-reasons.enum';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakEvent } from 'keycloak-angular/lib/core/interfaces/keycloak-event';

@Injectable()
export class AuthKeycloakService {
  logoutEvents$ = this._kc.keycloakEvents$.pipe(
    filter((event: KeycloakEvent) => event.type === KeycloakEventType.OnAuthLogout),
  );

  constructor(
    private _authService: AuthService,
    private _kc: KeycloakService,
    private _logger: LoggerService,
  ) {}

  refreshToken(minValidity?: number): Promise<void> {
    this._logger.addInstantLog({ message: 'Token start refreshing', area: LogArea.AUTH });
    return this._kc
      .updateToken(minValidity)
      .then(() => {
        this._logger.addLog({ message: 'Refresh token success', area: LogArea.AUTH });
        this.validateKeycloakConfiguration();
      })
      .catch(() => {
        this._logger.addInstantLog({ message: 'Refresh token failed', area: LogArea.AUTH });
        this._authService.signOut(LOGOUT_REASONS.REFRESH_TOKEN_FAIL);
      });
  }

  setAfterLogoutHandler(cb: () => void): void {
    this.logoutEvents$.pipe(first()).subscribe(() => {
      if (typeof cb === 'function') {
        cb();
      }
    });
  }

  validateKeycloakConfiguration() {
    const keycloakInstance = this._kc.getKeycloakInstance();
    const tokenExpiration = keycloakInstance.tokenParsed.exp;
    const refreshTokenExpiration = keycloakInstance.refreshTokenParsed.exp;

    if (tokenExpiration > refreshTokenExpiration) {
      this._logger.addError({
        message: 'Access token lifespan cannot be longer than Refresh token lifespan!',
        area: LogArea.AUTH,
      });
    }
  }
}
