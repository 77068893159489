import { Injectable } from '@angular/core';

import { SelectComparator } from './select-comparator-strategy.service';

import { HasKey, HasKeyOrId, isHasKey } from '../../../model/has-key-or-id.interface';

@Injectable({ providedIn: 'root' })
export class ReadableDictionarySelectComparatorStrategy implements SelectComparator {
  bindValue? = 'key' as const;
  compareFn: (o1: HasKeyOrId, o2: HasKeyOrId | string) => boolean = (
    someOption: HasKey,
    selectedValue: string,
  ) => {
    if (!isHasKey(someOption) || typeof selectedValue !== 'string') {
      console.warn('Wrongly configured select with Dictionary and API value');
      return false;
    }
    return someOption.key === selectedValue;
  };
}
