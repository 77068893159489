import { Component, Input } from '@angular/core';

import { FormDecimalRowComponent } from '../form-decimal-row/form-decimal-row.component';

import { DecimalValuePipe } from '../../pipe/decimal-value.pipe';

import { DecimalFormatterDirective } from '../../directive/decimal-formatter.directive';
import { FormRowClass } from '../../forms/model/form-row.class';

@Component({
  selector: 'trf-form-percentage-row',
  templateUrl: 'form-percentage-row.component.html',
  styleUrls: ['./form-percentage-row.component.sass'],
  providers: [
    DecimalValuePipe,
    DecimalFormatterDirective,
    { provide: FormRowClass, useExisting: FormPercentageRowComponent },
  ],
})
export class FormPercentageRowComponent extends FormDecimalRowComponent {
  @Input()
  showPercentLabel = false;
  @Input()
  maxValue = 100;

  protected customMaxIntegerPlacesInt = 3;
  protected customMaxDecimalPlacesInt = 6;
}
