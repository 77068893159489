export enum AnalyticsPages {
  OVERVIEW = 'overview',
  DATA_UPLOADS = 'data uploads',
  MESSAGES_AND_ALERTS = 'messages and alerts',
  CLIENTS = 'clients',
  FUNDERS = 'funders',
  TRANSACTIONS = 'transactions',
  TEMPLATES = 'templates',
  CURRENT_REGION = 'current region',
  ADMINISTRATION = 'administration',
  SETTINGS = 'settings',
}
