import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Action } from './model/actions.interface';

@Component({
  selector: 'trf-actions',
  templateUrl: 'actions.component.html',
  styleUrls: ['actions.component.sass'],
})
export class ActionsComponent<T> {
  @Input()
  actionsAsArray: Action<T>[];
  @Input()
  actionSubject: T;
  @Input()
  tooltipTabIndex = '0';

  constructor(private translateService: TranslateService) {}

  invokeAction(event: Event, action: Action<T>) {
    event.stopPropagation();

    if (action.inactiveWithMessages) {
      return;
    }

    if (!this.markedAsInactive(action, this.actionSubject)) action.handler(this.actionSubject);
  }

  onEnter(event: KeyboardEvent, action: Action<T>) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.key === 'Enter') {
      this.invokeAction(event, action);
    }
  }

  isInactive(action: Action<T>, actionSubject: T) {
    return action.inactiveWithMessages || this.markedAsInactive(action, actionSubject);
  }

  markedAsInactive(action: Action<T>, actionSubject: T) {
    return action.inactive && action.inactive(actionSubject);
  }

  getTitleContent(action: Action<T>): string | null {
    return !action.tooltipContent &&
      !this.markedAsInactive(action, this.actionSubject) &&
      !action.inactiveWithMessages
      ? this.translateService.instant(action.titleKey)
      : null;
  }

  getTooltipContent(action: Action<T>, actionSubject: T): string {
    return action.tooltipHandler ? action.tooltipHandler(actionSubject) : action.tooltipContent;
  }
}
