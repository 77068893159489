import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { RestResponse } from '../../model/response.interface';
import { encodeEndpoint } from '../../security/encode-url';

export interface DisplayUnit {
  unit: string;
}

export interface DisplayUnitsResource {
  units: DisplayUnit[];
}

@Injectable()
export class DisplayUnitsResourceService {
  constructor(private http: HttpClient) {}

  getDisplayUnits() {
    const url = encodeEndpoint('resources/dashboards/display-units');
    return this.http.get(url).pipe(map((r: RestResponse<DisplayUnitsResource>) => r.data.units));
  }
}
