import { Injectable } from '@angular/core';

import { Observable, ReplaySubject, Subject } from 'rxjs';

import { EntityReference } from '../../../interface/entity-reference.interface';

@Injectable({
  providedIn: 'root',
})
export class ProgrammeOwnerService {
  programmeOwner$: Observable<EntityReference>;

  private _programmeOwner$: Subject<EntityReference> = new ReplaySubject(1);

  constructor() {
    this.programmeOwner$ = this._programmeOwner$.asObservable();
  }

  setProgrammeOwner(id: EntityReference) {
    this._programmeOwner$.next(id);
  }
}
