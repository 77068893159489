import { RoutePermissions } from '../interface/route.interface';

export enum MenuOptionType {
  SECTION,
  ITEM,
}

export interface MenuOptionConfig {
  type: MenuOptionType;
}

export interface MenuSectionConfig extends MenuOptionConfig {
  sectionTitle: string;
  options: { [key: string]: MenuOptionConfig | MenuItemConfig };
  type: MenuOptionType.SECTION;
}

export interface MenuItemConfig extends MenuOptionConfig {
  titleKey: string;
  icon: string;
  link: string[];
  permissions: RoutePermissions;
  type: MenuOptionType.ITEM;
}

export interface MenuItemsConfig {
  [key: string]: MenuItemConfig | MenuSectionConfig;
}
