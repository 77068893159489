import { $enum } from 'ts-enum-util';

export enum FileFormatType {
  AssetsFile, // ContractFile in ContractRF transactions
  DebtorsFile,
  LimitsFile,
  AssetsDeltaFile,
}

export enum FileFormatTypeId {
  AssetsFile = 1,
  DebtorsFile = 3,
  LimitsFile = 9,
  AssetsDeltaFile = 20,
}

export function getFileFormatType(entityId: number): FileFormatType {
  return $enum.visitValue(entityId).with({
    [FileFormatTypeId.AssetsFile]: () => FileFormatType.AssetsFile,
    [FileFormatTypeId.DebtorsFile]: () => FileFormatType.DebtorsFile,
    [FileFormatTypeId.LimitsFile]: () => FileFormatType.LimitsFile,
    [FileFormatTypeId.AssetsDeltaFile]: () => FileFormatType.AssetsDeltaFile,
    [$enum.handleUnexpected]: () => undefined,
  });
}

export function isFileFormatTypeAsExpected(
  actual: FileFormatType,
  expected: FileFormatType[],
): boolean {
  return actual !== undefined && expected.includes(actual.valueOf());
}
