<div
  *ngIf="versions?.length"
  class="version"
>
  <div
    id="version-label"
    class="label"
    >{{ "ENTITY_VERSION_HEADER.VERSION_LABEL" | translate }}</div
  >

  <ng-select
    #select
    class="form-control"
    [tabIndex]="0"
    aria-labelledby="version-label"
    role="combobox"
    [attr.aria-expanded]="select.isOpen"
    [items]="versions"
    [searchable]="false"
    [clearable]="false"
    [notFoundText]="'NG_SELECT.NOT_FOUND' | translate"
    [(ngModel)]="selection"
    (ngModelChange)="onSelected($event)"
    (close)="select.element.focus()"
    testId="selected-version"
  >
    <ng-template
      ng-label-tmp
      let-item="item"
      let-clear="clear"
    >
      <span
        class="ng-value-label"
        [attr.title]="item | optionName"
        data-testid="select-value-text"
      >
        {{ item | optionName }}
      </span>
    </ng-template>

    <ng-template
      ng-option-tmp
      let-item="item"
      let-index="index"
    >
      <span
        [attr.title]="item | optionName"
        [attr.aria-selected]="item === selection"
        data-testid="select-option-text"
      >
        {{ item | optionName }}
      </span>
    </ng-template>
  </ng-select>
</div>

<div class="trf-actions">
  <trf-actions
    [actionsAsArray]="actionsAsArray"
    [actionSubject]="actionSubject"
  ></trf-actions>
</div>
