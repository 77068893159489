export enum SellerBankAccountFormFields {
  bankAccountName = 'bankAccountName',
  bankAccountNumber = 'bankAccountNumber',
  branchCodeOrRoutingNumber = 'branchCodeOrRoutingNumber',
  swiftBic = 'swiftBic',
  bankAccountType = 'bankAccountType',
  accountHolder = 'accountHolder',
  bankName = 'bankName',
  address1 = 'address1',
  address2 = 'address2',
  address3 = 'address3',
  address4 = 'address4',
  postCode = 'postCode',
}
