<div
  *ngIf="loading; else loadingFinished"
  class="loading-container"
>
  <div
    class="trf-loading-circle show-loading"
    data-testid="loader-with-status"
  >
    <div class="loader-checkmark"></div>
  </div>
  <div class="trf-loader-messages">
    <span
      *ngIf="actionMessage"
      data-testid="loader-message-action"
      >{{ actionMessage }}</span
    >
    <span
      *ngIf="progressMessage"
      data-testid="loader-message-progress"
      >{{ progressMessage }}</span
    >
  </div>
</div>

<ng-template #loadingFinished>
  <div
    *ngIf="showDoneIcon || !!doneMessage"
    class="loading-container"
  >
    <div
      *ngIf="showDoneIcon"
      class="show-done"
      data-testid="loader-icon"
    >
      <ngp-svg-icon
        class="custom-icon"
        iconName="file-check"
      >
      </ngp-svg-icon>
    </div>
    <div
      *ngIf="doneMessage"
      class="trf-loader-messages"
    >
      <span data-testid="loader-message-done">{{ doneMessage }}</span>
    </div>
  </div>
</ng-template>
