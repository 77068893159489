import { Injectable, OnDestroy, NgZone } from '@angular/core';

import { Observable, Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InactivityTimerService implements OnDestroy {
  timeout$: Observable<boolean>;

  /**
   * Timer timeout value in milliseconds.
   * There's a reasonable default value of 5 minutes set
   * to simplify timer logic, HOWEVER
   * in regular runtime this is defined based on
   * external conf. (ie. token time left)
   * @private
   * @memberof InactivityTimerService
   */
  private _timeOutInMilliseconds = 1000 * 60 * 5;
  private _timeoutSubject$ = new Subject<boolean>();
  private _timerReset$ = new Subject<void>();
  private _destroyed$ = new Subject<void>();

  constructor(private _ngZone: NgZone) {
    this.timeout$ = this._timeoutSubject$.asObservable();
    this._setupTimer();
  }

  startTimer(timeoutInMinutes: number): this {
    this._timeOutInMilliseconds = timeoutInMinutes * 60 * 1000;

    return this.restartTimer();
  }

  restartTimer(): this {
    this._restartTimer();

    return this;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  private _setupTimer(): void {
    this._timerReset$
      .pipe(
        switchMap(() => {
          return timer(this._timeOutInMilliseconds);
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe(() => {
        this._signalTimeout();
      });
  }

  private _restartTimer(): void {
    this._ngZone.runOutsideAngular(() => {
      this._timerReset$.next();
    });
  }

  private _signalTimeout(): void {
    this._timeoutSubject$.next(true);
  }
}
