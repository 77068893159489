import { FUNDING_REPORTS_FEATURE_TOGGLE_GROUP } from '../../funding-modules/reports-module/model/feature-toggle-group';
import { FUNDING_STRUCTURED_RECEIVABLE_FINANCE_FEATURE_TOGGLE_GROUP } from '../../funding-modules/structured-receivable-finance-module/model/feature-toggle-group';
import { PORTFOLIO_DEBTOR_GROUPS_FEATURE_TOGGLE_GROUP } from '../../portfolio-modules/debtor-groups-module/model/feature-toggle-group';
import { PORTFOLIO_DEBTORS_FEATURE_TOGGLE_GROUP } from '../../portfolio-modules/debtors-module/model/feature-toggle-group';
import { PORTFOLIO_OVERVIEW_FEATURE_TOGGLE_GROUP } from '../../portfolio-modules/overview-module/model/feature-toggle-group';
import { PORTFOLIO_RECEIVABLES_FEATURE_TOGGLE_GROUP } from '../../portfolio-modules/receivables-module/model/feature-toggle-group';

export const FUNDING_SETTLEMENTS_FEATURE_TOGGLE_GROUP = ['DASHBOARD_FUNDING_SETTLEMENTS'];

export const DASHBOARD_PORTFOLIO_OVERVIEW_FEATURE_TOGGLE_GROUP = [
  ...PORTFOLIO_OVERVIEW_FEATURE_TOGGLE_GROUP,
  ...PORTFOLIO_DEBTORS_FEATURE_TOGGLE_GROUP,
  ...PORTFOLIO_RECEIVABLES_FEATURE_TOGGLE_GROUP,
  ...PORTFOLIO_DEBTOR_GROUPS_FEATURE_TOGGLE_GROUP,
];

export const DASHBOARD_FUNDING_OVERVIEW_FEATURE_TOGGLE_GROUP = [
  ...FUNDING_STRUCTURED_RECEIVABLE_FINANCE_FEATURE_TOGGLE_GROUP,
  ...FUNDING_REPORTS_FEATURE_TOGGLE_GROUP,
  ...FUNDING_SETTLEMENTS_FEATURE_TOGGLE_GROUP,
];

export const DASHBOARD_FEATURE_TOGGLE_GROUP = [
  ...DASHBOARD_PORTFOLIO_OVERVIEW_FEATURE_TOGGLE_GROUP,
  ...DASHBOARD_FUNDING_OVERVIEW_FEATURE_TOGGLE_GROUP,
];

export const RECEIVABLE_DISCOUNTING_TOGGLE = 'RECEIVABLE_DISCOUNTING';
export const DASHBOARD_FUNDING_DASHBOARDS_TAB = 'DASHBOARD_FUNDING_DASHBOARDS_TAB';
