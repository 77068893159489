import { EntityId, HasEntityId } from '../interface/has-entity-id.interface';

export interface CurrencyCode extends HasEntityId<EntityId<string>> {
  // commonly not shipped with name
  name: string;
}

export function getCurrencyCode(currency: CurrencyCode): string {
  return currency?.entityId;
}
