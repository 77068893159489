// Type used to explicitly point places where there is "any" type caused by untyped dynamic forms operations
import { AbstractControl } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export type DynamicFormAny = any;

/** Flatten top level form groups in a "form.value" object.
 * Does not traverse the object recursively!
 *
 * e.g. input:
 * <pre>{
 *   foo: 1,
 *   barGroup: {
 *     bar: true
 *   }
 * }</pre>
 * output:
 * <pre>{
 *   foo: 1,
 *   bar: true
 * }</pre>
 * */
// TODO: TRFV2-3891 Refactor to proper types from "any"
/*
  @deprecated this function is not properly typed. Use explicit mapping instead
 */
export function flattenFormGroups(formData: DynamicFormAny): any {
  const out: Record<string, any> = {};

  for (const e of Object.entries(formData || {})) {
    const key = e[0];
    const val = e[1];

    if (key.match('Group')) {
      for (const v of Object.entries(val)) {
        out[v[0]] = v[1];
      }
    } else {
      out[key] = val;
    }
  }

  return out;
}

export function isControlValid$(control: AbstractControl): Observable<boolean> {
  if (!control) {
    return of(false);
  }
  return control.statusChanges.pipe(
    startWith(control.status),
    map((status) => status === 'VALID'),
  );
}
