<span class="logo">
  <span
    *ngIf="logoImg && !sidebarCollapsed"
    class="logo-lg"
  >
    <img
      class="img"
      [src]="logoImgPath"
      [alt]="'HEADER.LOGO_ALT_DESCRIPTION' | translate"
    />
  </span>
  <span
    *ngIf="iconImg && sidebarCollapsed"
    class="logo-sm"
  >
    <img
      class="img"
      [src]="logoIconPath"
      [alt]="'HEADER.LOGO_ALT_DESCRIPTION' | translate"
    />
  </span>
</span>
