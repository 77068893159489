import { Directive, forwardRef, Injectable } from '@angular/core';

import {
  SelectAllowedBindValues,
  SelectComparatorStrategy,
  SelectComparator,
  SelectCompareStrategyType,
} from './comparator-strategy/select-comparator-strategy.service';

import { HasKeyOrId } from '../../model/has-key-or-id.interface';

@Injectable({
  providedIn: 'root',
  useClass: forwardRef(() => DefaultSelectConfig),
})
export abstract class SelectComparatorConfig implements SelectComparator {
  bindValue?: SelectAllowedBindValues;
  compareFn?: (o1: Partial<HasKeyOrId>, o2: Partial<HasKeyOrId> | string) => boolean;

  protected constructor(
    protected selectComparatorStrategy: SelectComparatorStrategy,
    type: SelectCompareStrategyType,
  ) {
    const strategy = selectComparatorStrategy.getStrategy(type);
    this.bindValue = strategy.bindValue;
    this.compareFn = strategy.compareFn;
  }
}

@Injectable({ providedIn: 'root' })
export class DefaultSelectConfig extends SelectComparatorConfig {
  constructor(protected selectComparatorStrategy: SelectComparatorStrategy) {
    super(selectComparatorStrategy, 'default');
  }
}

@Injectable({ providedIn: 'root' })
export class ReadableDictionarySelectConfig extends SelectComparatorConfig {
  constructor(protected selectComparatorStrategy: SelectComparatorStrategy) {
    super(selectComparatorStrategy, 'dictWithReadableValue');
  }
}

@Directive({
  selector: '[trfCompareReadableDictionary]',
  providers: [{ provide: SelectComparatorConfig, useExisting: ReadableDictionarySelectConfig }],
})
export class SelectComparatorReadableDictionaryDirective {}

@Injectable({ providedIn: 'root' })
export class FlatIdSelectComparatorConfig extends SelectComparatorConfig {
  constructor(protected selectComparatorStrategy: SelectComparatorStrategy) {
    super(selectComparatorStrategy, 'flatIdValue');
  }
}

@Directive({
  selector: '[trfCompareFlatId]',
  providers: [{ provide: SelectComparatorConfig, useExisting: FlatIdSelectComparatorConfig }],
})
export class SelectComparatorFlatIdDirective {}
