import { isKey } from '@demica/utils';

const definitions = {
  WAITING_FOR_APPROVAL: {
    translationKey: 'STATUS.WAITING_FOR_APPROVAL',
    cssClass: 'trf-status-waiting-for-approval',
  },
  REJECTED: {
    translationKey: 'STATUS.REJECTED',
    cssClass: 'trf-status-rejected',
  },
  QUEUED: {
    translationKey: 'STATUS.QUEUED',
    cssClass: 'trf-status-queued',
  },
  PROCESSING: {
    translationKey: 'STATUS.PROCESSING',
    cssClass: 'trf-status-processing',
  },
  FAILED: {
    translationKey: 'STATUS.FAILED',
    cssClass: 'trf-status-failed',
  },
  COMPLETE: {
    translationKey: 'STATUS.COMPLETE',
    cssClass: 'trf-status-complete',
  },
  NEW: {
    translationKey: 'STATUS.NEW',
    cssClass: 'trf-status-new',
  },
  ROLLED_BACK: {
    translationKey: 'STATUS.ROLLED_BACK',
    cssClass: 'trf-status-rolled-back',
  },
  ROLLING_BACK: {
    translationKey: 'STATUS.ROLLING_BACK',
    cssClass: 'trf-status-processing',
  },
  ROLLBACK_FAILED: {
    translationKey: 'STATUS.ROLLBACK_FAILED',
    cssClass: 'trf-status-failed',
  },
};

export function statusDefinitionForId(statusId: keyof typeof definitions | string) {
  return isKey(definitions, statusId) ? definitions[statusId] : undefined;
}
