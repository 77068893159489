export function encodeRegexChar(char: string) {
  switch (char) {
    case '.': {
      return '\\.';
    }
    case ' ': {
      return '\\s';
    }
    default: {
      return char;
    }
  }
}
