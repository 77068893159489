<form
  *ngIf="pointToEdit"
  [formGroup]="form"
  [ngClass]="{ 'trf-form-submitted': submitted }"
>
  <trf-form-decimal-group-row
    labelClasses="required"
    removeTrailingZeros="true"
    [maxValue]="maxIntegerValue"
    [placeholder]="pointPlaceholder"
    [label]="pointLabel"
    [name]="formFields.x"
    [decimalPlaces]="numberOfDecimalPlacesForX"
    [validations]="validations[formFields.x]"
    [acceptNegatives]="false"
    [readonly]="isXReadOnly"
    data-testid="point-x-input"
  ></trf-form-decimal-group-row>
  <trf-form-decimal-group-row
    label="POINTS.VALUE_LABEL"
    placeholder="POINTS.VALUE_PLACEHOLDER"
    labelClasses="required"
    removeTrailingZeros="true"
    [decimalPlaces]="numberOfDecimalPlacesForValue"
    [name]="formFields.value"
    [validations]="validations[formFields.value]"
    [acceptNegatives]="true"
    data-testid="point-value-input"
  ></trf-form-decimal-group-row>
</form>
<div class="trf-section-buttons space-between">
  <ngp-button
    key="POINTS.CANCEL"
    classes="tertiary"
    (click)="cancel()"
    data-testid="point-cancel-btn"
  ></ngp-button>
  <ngp-button
    key="POINTS.SAVE"
    classes="primary"
    (click)="save()"
    data-testid="point-save-btn"
  ></ngp-button>
</div>
