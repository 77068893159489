export enum BroadcastMessageType {
  Hello = 'Hello',
  Logout = 'Logout',
  KeepAlive = 'KeepAlive',
  AuthUserContextUpdate = 'AuthUserContextUpdate',
}

export interface BroadcastMessage<T = null> {
  version: number;
  type: BroadcastMessageType;
  timestamp: number;
  sender: string;
  data: T | null;
}

export function isBroadcastMessageType<T>(value: unknown): value is BroadcastMessage<T> {
  return (
    typeof value === 'object' &&
    isPropertyOfBroadcastMessage(value, 'version') &&
    isPropertyOfBroadcastMessage(value, 'type') &&
    isPropertyOfBroadcastMessage(value, 'timestamp') &&
    isPropertyOfBroadcastMessage(value, 'sender') &&
    isPropertyOfBroadcastMessage(value, 'data')
  );

  function isPropertyOfBroadcastMessage(objectToTest: unknown, property: keyof BroadcastMessage) {
    return Object.prototype.hasOwnProperty.call(objectToTest, property);
  }
}
