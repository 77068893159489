declare global {
  interface Window {
    msCrypto: Crypto;
    IGNORE_DISCARD_UNSAVED_CHANGES_PROP: boolean;
    e2e: unknown;
  }
}

export function isInsideIFrame(): boolean {
  return !!window.frameElement;
}

export function getLocationHash(): string {
  return window.location.hash;
}

export function getLocationSearch(): string {
  return window.location.search;
}
export function getLocationPath(): string {
  return window.location.pathname;
}

export function getLocationUrl(): string {
  return window.location.href;
}

export function getLocationOrigin(): string {
  return window.location.origin;
}

export function locationRedirect(url: string): void {
  window.location.href = url;
}

export function locationReload(): void {
  window.location.reload();
}

export function createIFrame(): HTMLIFrameElement {
  return window.document.createElement('iframe');
}

export function appendElementToBody(elem: HTMLElement): void {
  window.document.body.appendChild(elem);
}

export function bodyToggleClass(className: string, force?: boolean): void {
  document.body.classList.toggle(className, force);
}

export function addBodyClass(className: string): void {
  document.body.classList.add(className);
}

export function documentContainsSelector(cssSelector: string): boolean {
  return !!document.querySelector(cssSelector);
}

export function setLocalStorage(key: string, value: string): void {
  window.localStorage.setItem(key, value);
}

export function removeLocalStorage(key: string): void {
  window.localStorage.removeItem(key);
}

export function getLocalStorage(key: string): string | null {
  return window.localStorage.getItem(key);
}

export function setOnError(
  callback: (message: string, url: string, lineNo: number, columnNo: number, error: Error) => void,
): void {
  window.onerror = callback as OnErrorEventHandler;
}

export function getCrypto(): Crypto {
  return window.crypto || window['msCrypto'];
}

export function setDir(dir: string): string {
  return (document.dir = dir);
}

export function getDir(): string {
  return document.dir;
}

export function setHistoryState(location: string): void {
  window.history.pushState({}, '', location);
}

export function browserConfirm(message: string): boolean {
  return window.confirm(message);
}

export function ignoreDiscardUnsavedChanges(): boolean {
  return window.IGNORE_DISCARD_UNSAVED_CHANGES_PROP;
}

export function setIgnoreDiscardUnsavedChanges(): void {
  window.IGNORE_DISCARD_UNSAVED_CHANGES_PROP = true;
}

export function isIndexedDBSupported(): boolean {
  return !!window.indexedDB;
}

export function isE2E(): boolean {
  return !!window.e2e;
}
