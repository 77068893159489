import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityId, EntityReference, FilterParameters, HasEntityId } from '@demica/core/core';

import { EnvironmentsDetailsResourcesService } from '../../../service/environments-details-resources.service';
import { FiltersContext } from '../../../service/filters-context.service';

import { EnvironmentDetails } from '../../../model/environment.details';
import { FilterViewResources } from '../filter-view-resources.interface';
import { FormFields } from '../form-fields';
import { EnvironmentDetailFilterFn } from './environment-detail-filter-fn';

@Injectable({
  providedIn: 'root',
})
export class FiltersOptionsResolverService {
  selectedFilters$: Observable<Record<FormFields, EntityReference>>;
  globalFiltersFunctions$: Observable<EnvironmentDetailFilterFn[]>;
  environmentDetails$ = new ReplaySubject<EnvironmentDetails[]>();
  additionalFilters$ = new BehaviorSubject<EnvironmentDetailFilterFn[]>([]);
  globalFiltersSellers$ = this.filterContext.filters$.pipe(map((gf) => gf.activeFilters.opcos));

  constructor(
    private filterContext: FiltersContext,
    private environmentsDetailsResourcesService: EnvironmentsDetailsResourcesService,
  ) {}

  setFiltersChangeStream(selectedFilters$: Observable<Record<FormFields, EntityReference>>) {
    this.selectedFilters$ = selectedFilters$;
  }

  getAvailableEntries() {
    this.initialize();
    return combineLatest([
      this.environmentDetails$.asObservable(),
      this.globalFiltersFunctions$,
      this.selectedFilters$,
      this.additionalFilters$,
      this.globalFiltersSellers$,
    ]).pipe(
      map(
        ([
          environmentDetails,
          globalFilters,
          selectedFilters,
          additionalFilters,
          activeGlobalFilterSellers,
        ]) => [
          this.filterEnvironmentDetailsByGlobalFilters(
            globalFilters,
            environmentDetails,
            additionalFilters,
            activeGlobalFilterSellers,
          ),
          selectedFilters,
        ],
      ),
      map(
        ([environmentDetails, selectedFilter]: [
          EnvironmentDetails[],
          Record<FormFields, EntityReference>,
        ]) => this.mapToFilterViewResources(selectedFilter, environmentDetails),
      ),
    );
  }

  additionalFilters(additionalFilters: EnvironmentDetailFilterFn[]) {
    this.additionalFilters$.next(additionalFilters);
  }

  private filterEnvironmentDetailsByGlobalFilters(
    globalFilters: EnvironmentDetailFilterFn[],
    environmentDetails: EnvironmentDetails[],
    additionalFilters: EnvironmentDetailFilterFn[],
    selectedSellers: HasEntityId[],
  ) {
    const filterToGlobalySellectedSellers = (sellers: HasEntityId[]) => {
      if (!selectedSellers?.length) return sellers;
      return sellers.filter(
        (seller) =>
          selectedSellers.findIndex(
            (selectedSeller: HasEntityId) => seller.entityId === selectedSeller.entityId,
          ) > -1,
      );
    };

    return [...globalFilters, ...additionalFilters]
      .reduce(
        (prev: EnvironmentDetails[], fn: EnvironmentDetailFilterFn) => fn(prev),
        environmentDetails,
      )
      .map((ed) => ({
        ...ed,
        sellers: filterToGlobalySellectedSellers(ed.sellers),
      }));
  }

  private mapToFilterViewResources(
    selectedFilter: Record<FormFields, EntityReference<EntityId>>,
    environmentDetails: EnvironmentDetails[],
  ): FilterViewResources {
    // assign filter to each selection on filter form
    const fieldsFilterFunctions: Record<FormFields, EnvironmentDetailFilterFn> = Object.keys(
      FormFields,
    ).reduce((prev, key) => {
      return {
        ...prev,
        [key]: selectedFilter[key as keyof typeof FormFields]
          ? fieldFilterFunctionMap[key as keyof typeof FormFields]([
              selectedFilter[key as keyof typeof FormFields].entityId,
            ])
          : null,
      };
    }, {} as Record<FormFields, EnvironmentDetailFilterFn>);

    // calculate each field available select option based on others field selection
    const processFiltersFor = (
      propName: FormFields,
      filterHolder: Record<FormFields, EnvironmentDetailFilterFn>,
    ) =>
      Object.entries(filterHolder)
        .filter(([k]) => k !== propName)
        .map(([, v]) => v)
        .filter((fn) => !!fn)
        .reduce(
          (prev: EnvironmentDetails[], fn: EnvironmentDetailFilterFn) => fn(prev),
          environmentDetails,
        )
        .flatMap((def) => def[environmentDetailsFormFieldPropNamesMap[propName]]);

    return {
      transactions: distinctEntity(
        processFiltersFor(FormFields.transaction, fieldsFilterFunctions),
      ),
      environments: distinctEntity(
        processFiltersFor(FormFields.environment, fieldsFilterFunctions),
      ),
      sellers: distinctEntity(processFiltersFor(FormFields.seller, fieldsFilterFunctions)),
      clients: distinctEntity(processFiltersFor(FormFields.client, fieldsFilterFunctions)),
      assetBases: distinctEntity(processFiltersFor(FormFields.assetBase, fieldsFilterFunctions)),
    };
  }

  private initialize() {
    if (!this.selectedFilters$) throw new Error('Form filter values stream not set');
    this.loadEnvironmentsDetails();
    this.globalFiltersAsFilterFunctions();
  }

  private loadEnvironmentsDetails() {
    this.environmentsDetailsResourcesService.get().pipe().subscribe(this.environmentDetails$);
  }

  /**
   * Transform globally selected list to filter array for `EnvironmentsDetails`
   * @private
   */
  private globalFiltersAsFilterFunctions() {
    this.globalFiltersFunctions$ = combineLatest([
      this.filterContext.filters$,
      this.filterContext.selectedEnvironments$,
    ]).pipe(
      map(([filters, selectedEnvironments]) => [filters.activeFilters, selectedEnvironments]),
      map(([active, selectedEnvironments]: [FilterParameters, EntityId[]]) =>
        [
          active.clients.length ? getClientFilter(active.clients.map(toEntityIds)) : null,
          active.transactions.length
            ? getTransactionFilter(active.transactions.map(toEntityIds))
            : null,
          selectedEnvironments.length ? getEnvironmentFilter(selectedEnvironments) : null,
          active.opcos.length ? getSellerFilter(active.opcos.map(toEntityIds)) : null,
        ].filter((fn) => !!fn),
      ),
    );
  }
}

export const toEntityIds = (entity: HasEntityId) => entity.entityId;

export const getTransactionFilter = (ids: EntityId[]) => (list: EnvironmentDetails[]) =>
  list.filter((item) => ids.includes(item.transaction.entityId));
export const getEnvironmentFilter = (ids: EntityId[]) => (list: EnvironmentDetails[]) =>
  list.filter((item) => ids.includes(item.environment.entityId));
export const getClientFilter = (ids: EntityId[]) => (list: EnvironmentDetails[]) =>
  list.filter((item) => ids.includes(item.client.entityId));
export const getSellerFilter = (ids: EntityId[]) => (list: EnvironmentDetails[]) =>
  list.filter((item) => ids.some((id) => !!item.sellers.find((it2) => it2.entityId === id)));
export const getAssetBasesFilter = (ids: EntityId[]) => (list: EnvironmentDetails[]) =>
  list.filter((item) => ids.some((id) => !!item.assetBases.find((it2) => it2.entityId === id)));

const distinctEntity = (collection: EntityReference[]) =>
  collection.filter(
    (v, i, a) => a.findIndex((t) => '' + t.entityId + t.name === '' + v.entityId + v.name) === i,
  );

const fieldFilterFunctionMap = {
  [FormFields.transaction]: getTransactionFilter,
  [FormFields.environment]: getEnvironmentFilter,
  [FormFields.client]: getClientFilter,
  [FormFields.seller]: getSellerFilter,
  [FormFields.assetBase]: getAssetBasesFilter,
};
const environmentDetailsFormFieldPropNamesMap: Record<FormFields, keyof EnvironmentDetails> = {
  [FormFields.transaction]: 'transaction',
  [FormFields.environment]: 'environment',
  [FormFields.client]: 'client',
  [FormFields.seller]: 'sellers',
  [FormFields.assetBase]: 'assetBases',
};
