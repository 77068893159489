import { registerLocaleData } from '@angular/common';

import { LocalesMappings, AppConfig, LocaleFormatsOverwrite } from '@demica/resources/app-config';

import { Locale } from '../../../config/locale.enum';
import { replaceAtIndex } from '../../../utils/array-utils';
import { LocaleLoadResult, LocaleLoader } from '../loaders/locale-loader.interface';

/**
 * Those magic number are derived from actual format within locales files. See docs
 */
export const LOCALE_DATE_FORMATTER_INDEX = 10;
export const LOCALE_TIME_FORMATTER_INDEX = 11;
export const LOCALE_DATE_TIME_FORMATTER_INDEX = 12;
/**
 * Default locales mapping have been defined
 * based on the state before introducing lazy-loading of locales
 */
export const DEFAULT_LOCALES_MAPPINGS: LocalesMappings = Object.freeze({
  [Locale.EN_GB]: 'en-GB',
  [Locale.EN_US]: 'en',
  [Locale.FR_FR]: 'fr',
  [Locale.FR_CA]: 'fr-CA',
  // fake languages
  [Locale.AR_BH]: 'ar-BH',
  [Locale.ES_ES]: 'es',
  [Locale.EL_EL]: 'el',
  [Locale.DE_DE]: 'de',
  [Locale.IT_IT]: 'it',
  [Locale.PL_PL]: 'pl',
  [Locale.RO_RO]: 'ro',
});

export class LocaleRegistry {
  static registerSupportedLocalesData(loader: LocaleLoader, config?: AppConfig): Promise<string[]> {
    // establish effective locales mappings to use
    const effectiveLocalesMappings = config?.locales?.mappings || DEFAULT_LOCALES_MAPPINGS;

    // load locales and process them
    return loader.load(effectiveLocalesMappings).then(
      (localeLoadResults: LocaleLoadResult[]) => {
        const loadedLocales: string[] = [];
        const thereAreFormatOverwrites = typeof config?.locales?.formatOverwrites === 'object';

        localeLoadResults.forEach((localeLoadResult: LocaleLoadResult) => {
          const localeId = localeLoadResult.id as Locale;
          const localeHasBeenLoaded = localeLoadResult.err === null;

          if (!localeHasBeenLoaded) {
            console.error(
              `Couldn't load locales definitions for "${localeId}" due to: "${localeLoadResult.err}". Please check locales mappings!`,
            );
            return;
          }

          const localeHasOverwrites =
            thereAreFormatOverwrites && !!config?.locales?.formatOverwrites[localeId];

          const localeFormatsOverwrite: LocaleFormatsOverwrite | null = localeHasOverwrites
            ? config.locales.formatOverwrites[localeId]
            : null;
          let localeDefinitions: unknown[] = [...localeLoadResult.definitions];

          loadedLocales.push(localeId);

          // if there's no overwrite for given locale register it directly
          if (!localeFormatsOverwrite) {
            registerLocaleData(localeDefinitions);
            return;
          }

          const { dateFormats, timeFormats, dateTimeFormats } = localeFormatsOverwrite;

          // overwrite date formats
          if (dateFormats) {
            localeDefinitions = replaceAtIndex(
              localeDefinitions,
              dateFormats,
              LOCALE_DATE_FORMATTER_INDEX,
            );
          }

          // overwrite time formats
          if (timeFormats) {
            localeDefinitions = replaceAtIndex(
              localeDefinitions,
              timeFormats,
              LOCALE_TIME_FORMATTER_INDEX,
            );
          }

          // overwrite dateTime formats
          if (dateTimeFormats) {
            localeDefinitions = replaceAtIndex(
              localeDefinitions,
              dateTimeFormats,
              LOCALE_DATE_TIME_FORMATTER_INDEX,
            );
          }

          registerLocaleData(localeDefinitions);
        });

        return loadedLocales;
      },
      (err: unknown): string[] => {
        console.error(`Loading locales definitions failed due to: "${err}"`);

        return [];
      },
    );
  }
}
