import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { generateUUID } from '@demica/utils';

import { IdProvider } from '../../id-provider.service';

@Injectable({
  providedIn: 'root',
})
export class SessionIdForAnalyticsService extends IdProvider {
  public id$: Observable<string | null>;

  private _id: string | null = null;
  private _id$ = new Subject<string | null>();

  constructor() {
    super();
    this.id$ = this._id$.asObservable();
  }

  getId(): string {
    if (this._id === null) {
      this._id = generateUUID();
    }

    return this._id;
  }
}
