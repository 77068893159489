/* abstract away platform dependant 'window' access for future server-side-rendering */
import { DB_NAME, DB_VERSION } from '../config/analytics-db-constants';
import { CSS_VARIABLES_PROP_NAME } from '../styling';

declare global {
  interface Window {
    [CSS_VARIABLES_PROP_NAME]: Record<string, string>;
  }
}

export {
  isInsideIFrame,
  getLocationHash,
  getLocationSearch,
  getLocationPath,
  getLocationUrl,
  getLocationOrigin,
  locationRedirect,
  locationReload,
  createIFrame,
  appendElementToBody,
  bodyToggleClass,
  addBodyClass,
  documentContainsSelector,
  setLocalStorage,
  removeLocalStorage,
  getLocalStorage,
  setOnError,
  getCrypto,
  setDir,
  getDir,
  setHistoryState,
  browserConfirm,
  ignoreDiscardUnsavedChanges,
  setIgnoreDiscardUnsavedChanges,
} from '@demica/utils';

export function getVariableValueByName(name: string) {
  return (
    getComputedStyle(document.documentElement).getPropertyValue(name).trim() ||
    (window[CSS_VARIABLES_PROP_NAME] && window[CSS_VARIABLES_PROP_NAME][name])
  );
}

export function isIndexedDBSupported() {
  return !!window.indexedDB;
}

export function openIndexedDB() {
  return window.indexedDB.open(DB_NAME, DB_VERSION);
}
