import { EntityId } from '../interface/has-entity-id.interface';
import { z } from 'zod';

export interface FilterIds {
  environmentIds: EntityId[];
  clientIds: EntityId[];
  opcoIds: EntityId[];
  transactionIds: EntityId[];
}

export const FILTER_IDS_SCHEMA = z.object({
  environmentIds: z.array(z.number()).optional(),
  clientIds: z.array(z.number()).optional(),
  opcoIds: z.array(z.number()).optional(),
  transactionIds: z.array(z.number()).optional(),
});
