import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { Observable, interval, Subscription } from 'rxjs';
import { finalize, startWith, tap } from 'rxjs/operators';

import { IntentionalKeepAliveEventsSourceService } from '../events-source/intentional/intentional-events-source.service';

import { KEEP_ALIVE_SESSION_HTTP_CONTEXT_TOKEN } from '../keep-alive-session.types';

@Injectable()
export class KeepAliveSessionInterceptor implements HttpInterceptor {
  readonly pingEveryMilliseconds = 10000;

  constructor(
    private _intentionalEvents: IntentionalKeepAliveEventsSourceService,
    private _ngZone: NgZone,
  ) {}

  intercept(req: HttpRequest<unknown>, httpHandler: HttpHandler): Observable<HttpEvent<unknown>> {
    // only intercept those requests and responses which are explicitly using
    // proper HttpContext token set to `true`
    if (req.context.get(KEEP_ALIVE_SESSION_HTTP_CONTEXT_TOKEN) === true) {
      let ping: Subscription;

      this._ngZone.runOutsideAngular(() => {
        ping = interval(this.pingEveryMilliseconds)
          .pipe(
            startWith(0),
            tap(() => this._ngZone.run(() => this._keepAliveSession())),
          )
          .subscribe();
      });

      return httpHandler.handle(req).pipe(finalize(() => ping.unsubscribe()));
    }

    return httpHandler.handle(req);
  }

  private _keepAliveSession() {
    this._intentionalEvents.ping();
  }
}
