import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { hasError } from '../forms/validation-messages/message-predicates';
import { ValidationMessageDefinition } from '../forms/validation-messages/validation-message.interface';

@Injectable()
export class StartEndDateValidator implements OnDestroy {
  static END_DATE_BEFORE_AFTER_ERROR_KEY = 'endDateBeforeAfter';
  private subscriptions: Subscription[] = [];
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getValidationMsgDefinition(
    key = 'VALIDATION.END_DATE_BEFORE_AFTER',
  ): ValidationMessageDefinition {
    return {
      func: hasError(StartEndDateValidator.END_DATE_BEFORE_AFTER_ERROR_KEY),
      key,
    };
  }

  getValidator(formProvider: () => UntypedFormGroup, starDateControlName: string) {
    let changeListenerSet = false;
    return (fc: AbstractControl) => {
      const form = formProvider();
      if (!form) return null;
      const endDateControl = form.get(starDateControlName);
      if (!changeListenerSet) {
        changeListenerSet = true;
        // subscribe to start date changes to update end date validator
        const changeSubscription = endDateControl.valueChanges
          .pipe(
            distinctUntilChanged(),
            tap(() => fc.updateValueAndValidity()),
          )
          .subscribe();
        this.subscriptions.push(changeSubscription);
      }

      const fromValue = endDateControl.value;
      const toValue = fc.value;
      try {
        if (new Date(toValue).valueOf() - new Date(fromValue).valueOf() < 0) {
          return { [StartEndDateValidator.END_DATE_BEFORE_AFTER_ERROR_KEY]: true };
        }
      } catch (e) {
        // one or two values are not valid Date so there is no error
        return null;
      }
      return null;
    };
  }
}
