<td
  class="name"
  [title]="row.version.name"
  testid="push-request-version-name"
>
  <span
    *ifHasAuthority="'TRANSACTION_VERSION_PREVIEW'"
    class="trf-link"
    [attr.testid]="row.entityId"
    (click)="onVersionClick(row.version)"
    >{{ row.version.name }}</span
  >
  <span
    *ifHasNotAuthority="'TRANSACTION_VERSION_PREVIEW'"
    [attr.testid]="row.entityId"
    >{{ row.version.name }}</span
  >
</td>
<td
  testId="push-environment-cell"
  [title]="row.environment.name"
  >{{ row.environment.name }}</td
>
<td [title]="row.createdTimestamp | localisedDate: 'medium'">{{
  row.createdTimestamp | localisedDate: "medium"
}}</td>
<td
  [title]="row.createdBy"
  testid="created-by"
  >{{ row.createdBy }}</td
>
<td [title]="row.approvedBy ? row.approvedBy : ''">{{ row.approvedBy }}</td>
<td [title]="(row.standalone ? 'PUSH.STANDALONE_YES' : 'PUSH.STANDALONE_NO') | translate">
  {{ (row.standalone ? "PUSH.STANDALONE_YES" : "PUSH.STANDALONE_NO") | translate }}</td
>
<td
  class="status"
  testId="status-cell"
  [title]="mapStatusIdToTranslationKey(row.status.entityId) | translate"
>
  <span
    class="{{ mapStatusIdToClass(row.status.entityId) }}"
    [title]="getErrorDetails(row.pushRequestLog)"
    testid="push-request-status"
  >
    {{ mapStatusIdToTranslationKey(row.status.entityId) | translate }}
  </span>
</td>
<td>
  <div class="trf-actions">
    <ngp-svg-icon
      *ngIf="canDisplayRollbackButton"
      iconName="undo"
      tabindex="-1"
      data-index="0"
      role="button"
      [title]="
        (row.rollbackPossibility.canBeRolledBack
          ? 'PUSH.ROLLBACK_BUTTON_TITLE'
          : getCannotRollbackReason(row)
        ) | translate
      "
      [class.inactive]="!row.rollbackPossibility.canBeRolledBack"
      (click)="onRollbackClick(row)"
      (keypress)="onKeypressRollbackClick($event, row)"
      testid="push-request-rollback-button"
    ></ngp-svg-icon>

    <ngp-svg-icon
      *ngIf="row.canDisplayApproveButton"
      iconName="file-alt"
      title="{{ 'PUSH.APPROVE_BUTTON_TITLE' | translate }}"
      tabindex="-1"
      data-index="1"
      role="button"
      [ngClass]="{ inactive: !row.canEnableApproveButton }"
      (click)="onApproveClick(row)"
      (keypress)="onKeypressApproveClick($event, row)"
      testid="push-request-approve-button"
    ></ngp-svg-icon>

    <ngp-svg-icon
      iconName="info-circle"
      tabindex="-1"
      data-index="2"
      role="button"
      title="{{ row.comment }}"
      [ngClass]="{ inactive: !row.comment }"
      testid="push-request-info-button"
    ></ngp-svg-icon>
  </div>
</td>
