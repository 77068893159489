import { Injectable } from '@angular/core';

import { LogoutRedirectUrlService } from './logout-redirect-url.service';

import { locationRedirect } from '../../../utils/window';

@Injectable({
  providedIn: 'root',
})
export class AfterLogoutEventHandlerService {
  // this flag indicates that there's no need to overwrite keycloak's redirect mechanism - that's required if current window/tab is not the one that initializes the logout -> this window/tab only reacts to this event
  private _handleLocally = false;

  constructor(private _logoutRedirectUrl: LogoutRedirectUrlService) {}

  handle = () => {
    if (this._handleLocally) return;
    queueMicrotask(() => {
      locationRedirect(this._logoutRedirectUrl.getRedirectUrl());
    });
  };
  /*
   *
   *  This allows to take control over logout process within current window/tab by preventing custom logout page feature to kick-in with its redirect.
   *  This forces current window to use keycloak mechanism (redirect to keycloak logout and finally a 302 redirect after it to logout page).
   *  It has to happen in current window, if it is the one initializing the process of logout - so not being the one reacting to such an event taking place elsewhere
   *
   * */
  ignoreCustomLogoutRedirect(): void {
    this._handleLocally = true;
  }
}
