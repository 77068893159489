<ng-container [formGroup]="group">
  <div>
    <div
      *ngIf="!!label"
      class="column-label"
      [ngClass]="{ 'row-disabled': formControl.disabled || disabled }"
    >
      <label
        class="{{ labelClasses }}"
        [attr.for]="formControlName"
        data-testid="datepicker-label"
      >
        {{ label | translate }}
      </label>
    </div>
    <div
      class="column-input"
      [ngClass]="{ 'reverse-direction': reverseDirection }"
      [id]="uniqueId"
    >
      <div class="input-group">
        <input
          #datepicker="ngbDatepicker"
          class="form-control {{ inputClasses }}"
          type="text"
          ngbDatepicker
          [placement]="['bottom-right', 'top-right']"
          [positionTarget]="calendarIcon"
          [attr.id]="formControlName"
          [attr.aria-describedby]="formControlName + '_notification'"
          [attr.aria-required]="labelClasses?.includes('required')"
          [placeholder]="dateFormat.formatLocalized"
          [formControlName]="formControlName"
          [disableControl]="disabled"
          [minDate]="minDate"
          [maxDate]="maxDate"
          onlyDateInput
          data-testid="datepicker-input"
        />
        <div
          class="input-group-append"
          #calendarIcon
        >
          <span
            class="input-group-text"
            tabindex="0"
            role="button"
            [attr.aria-label]="'DATEPICKER.BUTTON' | translate"
            [ngClass]="{ disabled: disabled }"
            (click)="onClickAction()"
            (keydown.enter)="onKeyAction()"
            data-testid="datepicker-button"
          >
            <ngp-svg-icon iconName="calendar-alt"></ngp-svg-icon>
          </span>
        </div>
      </div>
    </div>

    <div class="validation-error">
      <ngp-inline-notification
        [id]="formControlName + '_notification'"
        [message]="validations | firstError: (fieldPath ? fieldPath : formControlName)"
      >
      </ngp-inline-notification>
    </div>
  </div>
</ng-container>
