import { ComparatorFn } from '../types/comparator-function.type';

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortParams {
  property: string;
  order: Order;
  comparator?: ComparatorFn;
}
