export enum Locale {
  'EN_GB' = 'en-gb',
  'EN_US' = 'en-us',
  'RU_RU' = 'ru-ru',
  'FR_FR' = 'fr-fr',
  'FR_CA' = 'fr-ca',
  'RO_RO' = 'ro-ro',
  'ES_ES' = 'es-es',
  'IT_IT' = 'it-it',
  'DE_DE' = 'de-de',
  'PL_PL' = 'pl-pl',
  'EL_EL' = 'el-el',
  'AR_BH' = 'ar-bh',
}
