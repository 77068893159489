import { AfterViewInit, Directive, ElementRef } from '@angular/core';

import { A11Y_HEADER_CONTAINER_ATTR } from '../model/accessibility-attributes.const';
import { NOT_FOCUSABLE_DATASET_ATTRIBUTE } from '../model/focusable.const';

@Directive({
  selector: '[trfTableRowAttributesSetter]',
  standalone: true,
})
export class TableRowAttributesSetterDirective implements AfterViewInit {
  constructor(private el: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    Array.from(this.el.nativeElement.children).forEach((item, index) => {
      if (canElementBeFocusable(item)) {
        const actionElements = Array.from(item.getElementsByTagName('ngp-svg-icon'));
        const buttonElements = Array.from(item.getElementsByTagName('ngp-button'));

        item.setAttribute('data-index', index.toString());

        item.setAttribute(
          'role',
          item.closest<HTMLElement>(A11Y_HEADER_CONTAINER_ATTR) ? 'columnheader' : 'cell',
        );

        if (actionElements.length > 0 || buttonElements.length > 0) {
          assignIndexToIconsOrButtons(actionElements, buttonElements);
        } else {
          item.setAttribute('tabindex', '-1');
        }
      }
    });
  }
}

function assignIndexToIconsOrButtons(actionElements: Element[], buttonElements: Element[]): void {
  if (actionElements.length > 0) {
    actionElements.forEach((element, index) =>
      element.setAttribute('data-index', index.toString()),
    );
  }
  if (buttonElements.length > 0) {
    Array.from(buttonElements).forEach((value) => value.setAttribute('tabindex', '-1'));
  }
}

function canElementBeFocusable(item: Element): boolean {
  return !item.hasAttribute(`data-${NOT_FOCUSABLE_DATASET_ATTRIBUTE}`);
}
