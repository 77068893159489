export const TRANSACTION_CLIENT_DATA_VIEW = 'TRANSACTION_CLIENT_DATA_VIEW';

export const TRANSACTION_CLIENT_DATA_FILE_FORMAT_VIEW = 'TRANSACTION_CLIENT_DATA_FILE_FORMAT_VIEW';
export const TRANSACTION_CLIENT_DATA_FILE_FORMAT_MANAGE =
  'TRANSACTION_CLIENT_DATA_FILE_FORMAT_MANAGE';

export const TRANSACTION_CLIENT_DATA_USER_DEFINED_VIEW =
  'TRANSACTION_CLIENT_DATA_USER_DEFINED_VIEW';
export const TRANSACTION_CLIENT_DATA_USER_DEFINED_MANAGE =
  'TRANSACTION_CLIENT_DATA_USER_DEFINED_MANAGE';

export const TRANSACTION_COMPONENTS_VIEW = 'TRANSACTION_COMPONENTS_VIEW';

export const TRANSACTION_COMPONENTS_ASSET_TYPES_VIEW = 'TRANSACTION_COMPONENTS_ASSET_TYPES_VIEW';
export const TRANSACTION_COMPONENTS_ASSET_TYPES_MANAGE =
  'TRANSACTION_COMPONENTS_ASSET_TYPES_MANAGE';

export const TRANSACTION_COMPONENTS_ASSET_STATUSES_VIEW =
  'TRANSACTION_COMPONENTS_ASSET_STATUSES_VIEW';
export const TRANSACTION_COMPONENTS_ASSET_STATUSES_MANAGE =
  'TRANSACTION_COMPONENTS_ASSET_STATUSES_MANAGE';

export const TRANSACTION_COMPONENTS_ASSET_BASES_VIEW = 'TRANSACTION_COMPONENTS_ASSET_BASES_VIEW';
export const TRANSACTION_COMPONENTS_ASSET_BASES_MANAGE =
  'TRANSACTION_COMPONENTS_ASSET_BASES_MANAGE';

export const TRANSACTION_COMPONENTS_CALENDARS_VIEW = 'TRANSACTION_COMPONENTS_CALENDARS_VIEW';
export const TRANSACTION_COMPONENTS_CALENDARS_MANAGE = 'TRANSACTION_COMPONENTS_CALENDARS_MANAGE';

export const TRANSACTION_COMPONENTS_TIME_SERIES_VIEW = 'TRANSACTION_COMPONENTS_TIME_SERIES_VIEW';
export const TRANSACTION_COMPONENTS_TIME_SERIES_MANAGE =
  'TRANSACTION_COMPONENTS_TIME_SERIES_MANAGE';

// TODO: Zweryfikować czy UI działa
export const TRANSACTION_COMPONENTS_RESERVES_VIEW = 'TRANSACTION_COMPONENTS_RESERVES_VIEW';
// TODO: Zweryfikować czy UI działa
export const TRANSACTION_COMPONENTS_RESERVES_MANAGE = 'TRANSACTION_COMPONENTS_RESERVES_MANAGE';

export const TRANSACTION_COMPONENTS_BANK_ACCOUNTS_VIEW =
  'TRANSACTION_COMPONENTS_BANK_ACCOUNTS_VIEW';
export const TRANSACTION_COMPONENTS_BANK_ACCOUNTS_MANAGE =
  'TRANSACTION_COMPONENTS_BANK_ACCOUNTS_MANAGE';

export const TRANSACTION_PROGRAMMES_VIEW = 'TRANSACTION_PROGRAMMES_VIEW';
export const TRANSACTION_PROGRAMMES_MANAGE = 'TRANSACTION_PROGRAMMES_MANAGE';

export const TRANSACTION_FEE_DEFINITIONS_VIEW = 'TRANSACTION_FEE_DEFINITIONS_VIEW';

export const TRANSACTION_FEE_DEFINITIONS_PERIODIC_VIEW =
  'TRANSACTION_FEE_DEFINITIONS_PERIODIC_VIEW';
export const TRANSACTION_FEE_DEFINITIONS_PERIODIC_MANAGE =
  'TRANSACTION_FEE_DEFINITIONS_PERIODIC_MANAGE';

export const TRANSACTION_FEE_DEFINITIONS_UPFRONT_VIEW = 'TRANSACTION_FEE_DEFINITIONS_UPFRONT_VIEW';
export const TRANSACTION_FEE_DEFINITIONS_UPFRONT_MANAGE =
  'TRANSACTION_FEE_DEFINITIONS_UPFRONT_MANAGE';

export const TRANSACTION_FEE_DEFINITIONS_IN_ARREARS_VIEW =
  'TRANSACTION_FEE_DEFINITIONS_IN_ARREARS_VIEW';
export const TRANSACTION_FEE_DEFINITIONS_IN_ARREARS_MANAGE =
  'TRANSACTION_FEE_DEFINITIONS_IN_ARREARS_MANAGE';

export const TRANSACTION_FUNDING_VIEW = 'TRANSACTION_FUNDING_VIEW';

export const TRANSACTION_FUNDING_STRUCTURE_VIEW = 'TRANSACTION_FUNDING_STRUCTURE_VIEW';
export const TRANSACTION_FUNDING_STRUCTURE_MANAGE = 'TRANSACTION_FUNDING_STRUCTURE_MANAGE';

export const TRANSACTION_FUNDING_EXPENSES_AND_DIVIDEND_VIEW =
  'TRANSACTION_FUNDING_EXPENSES_AND_DIVIDEND_VIEW';
// TODO: Zweryfikować czy UI działa
export const TRANSACTION_FUNDING_EXPENSES_AND_DIVIDEND_MANAGE =
  'TRANSACTION_FUNDING_EXPENSES_AND_DIVIDEND_MANAGE';

export const TRANSACTION_FUNDING_WATERFALL_VIEW = 'TRANSACTION_FUNDING_WATERFALL_VIEW';
// TODO: Zweryfikować czy UI działa
export const TRANSACTION_FUNDING_WATERFALL_MANAGE = 'TRANSACTION_FUNDING_WATERFALL_MANAGE';

export const TRANSACTION_FUNDING_CALCULATIONS_VIEW = 'TRANSACTION_FUNDING_CALCULATIONS_VIEW';
// TODO: Zweryfikować gdzie go użyć
export const TRANSACTION_FUNDING_CALCULATIONS_MANAGE = 'TRANSACTION_FUNDING_CALCULATIONS_MANAGE';

export const TRANSACTION_VAT_VIEW = 'TRANSACTION_VAT_VIEW';
export const TRANSACTION_VAT_MANAGE = 'TRANSACTION_VAT_MANAGE';

export const TRANSACTION_REPORTS_VIEW = 'TRANSACTION_REPORTS_VIEW';
export const TRANSACTION_REPORTS_MANAGE = 'TRANSACTION_REPORTS_MANAGE';

export const TRANSACTION_DOCUMENTS_VIEW = 'TRANSACTION_DOCUMENTS_VIEW';
export const TRANSACTION_DOCUMENTS_MANAGE = 'TRANSACTION_DOCUMENTS_MANAGE';

export const TRANSACTION_ADMINISTRATION_VIEW = 'TRANSACTION_ADMINISTRATION_VIEW';

export const TRANSACTION_ADMINISTRATION_USERS_VIEW = 'TRANSACTION_ADMINISTRATION_USERS_VIEW';
export const TRANSACTION_ADMINISTRATION_USERS_MANAGE = 'TRANSACTION_ADMINISTRATION_USERS_MANAGE';

export const TRANSACTION_ADMINISTRATION_ENVIRONMENTS_VIEW =
  'TRANSACTION_ADMINISTRATION_ENVIRONMENTS_VIEW';
export const TRANSACTION_ADMINISTRATION_ENVIRONMENTS_MANAGE =
  'TRANSACTION_ADMINISTRATION_ENVIRONMENTS_MANAGE';

export const TRANSACTION_ADMINISTRATION_ALIASES_VIEW = 'TRANSACTION_ADMINISTRATION_ALIASES_VIEW';

export const TRANSACTION_ADMINISTRATION_ALIASES_MISSING_VIEW =
  'TRANSACTION_ADMINISTRATION_ALIASES_MISSING_VIEW';
export const TRANSACTION_ADMINISTRATION_ALIASES_MISSING_MANAGE =
  'TRANSACTION_ADMINISTRATION_ALIASES_MISSING_MANAGE';

export const TRANSACTION_ADMINISTRATION_ALIASES_AWAITING_APPROVAL_VIEW =
  'TRANSACTION_ADMINISTRATION_ALIASES_AWAITING_APPROVAL_VIEW';
export const TRANSACTION_ADMINISTRATION_ALIASES_AWAITING_APPROVAL_MANAGE =
  'TRANSACTION_ADMINISTRATION_ALIASES_AWAITING_APPROVAL_MANAGE';

export const TRANSACTION_ADMINISTRATION_ALIASES_MANAGEMENT_VIEW =
  'TRANSACTION_ADMINISTRATION_ALIASES_MANAGEMENT_VIEW';
export const TRANSACTION_ADMINISTRATION_ALIASES_MANAGEMENT_MANAGE =
  'TRANSACTION_ADMINISTRATION_ALIASES_MANAGEMENT_MANAGE';

export const TRANSACTION_CREATE = 'TRANSACTION_CREATE';
export const TRANSACTION_CREATE_VERSION = 'TRANSACTION_CREATE_VERSION';
export const TRANSACTION_DELETE = 'TRANSACTION_DELETE';
export const TRANSACTION_SETTINGS = 'TRANSACTION_SETTINGS';
