import { FormatWidth, getLocaleDateFormat, getLocaleId } from '@angular/common';
import { Injectable } from '@angular/core';

import { LocalisationService } from '@demica/core/core';

import { LocalisedDatePipe } from '../../pipe/localised-date.pipe';

import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
  private _localisedDatePipe: LocalisedDatePipe;
  private _localeData: string;

  constructor(private localisationService: LocalisationService) {
    super();
    this._localisedDatePipe = new LocalisedDatePipe(localisationService);
    this.localisationService.locale.subscribe((locale) => (this._localeData = getLocaleId(locale)));
  }

  parse(value: string): NgbDateStruct {
    value = value.trim();

    // Previously, dateFormat was of FormatWidth.Medium type but this kind of format permits month abbreviations
    // that had caused incorrect parsing when input have consisted of numeric values only.
    // FormatWidth.Short permits only numeric month is fully compatible with DD/MM/YYYY for every locale.
    const dateFormat = this.localisationService.getLocaleDateFormat(
      this._localeData,
      FormatWidth.Short,
    );

    // By default, two digit years above 68 are assumed to be in the 1900's and years 68 or below are assumed to be in the 2000's.
    // This can be changed by replacing the moment.parseTwoDigitYear method.
    // Source: https://momentjs.com/docs/#/parsing/string-format/

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    moment.parseTwoDigitYear = function (yearString) {
      return Number(yearString) + 2000;
    };

    // Strict mode was switched on according to the https://support.demica.com/browse/NGPRR-1171
    const inputDate = moment(value, dateFormat.toUpperCase(), true);

    if (inputDate.isValid()) {
      return { year: inputDate.year(), month: inputDate.month() + 1, day: inputDate.date() };
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date
      ? this._localisedDatePipe.transform(new Date(date.year, date.month - 1, date.day))
      : null;
  }
}
