<ngp-inline-loader [loading]="loading"></ngp-inline-loader>

<trf-modal-loader
  [loadingData]="modalLoader"
  [message]="modalMessage"
></trf-modal-loader>
<div
  *ngIf="!loading"
  testid="aliases-management-tab-content"
>
  <trf-aliases-management-search
    #aliasesSearch
    [analysisGroups]="analysisGroups"
    [transactions]="transactionsList"
    [transaction]="transaction"
    [showAddButton]="!previewMode"
    (searchChange)="onSearch($event)"
    (addChange)="onAdd()"
    (clearChange)="onClear()"
  >
  </trf-aliases-management-search>
</div>
<ng-container *ngIf="dataLoaded">
  <ng-container *ngIf="isEmpty">
    <trf-aliases-management-list
      [dataSource]="dataSource"
      [showTransaction]="isAdministrationView()"
      [previewMode]="previewMode"
      (sortChange)="onSortChange($event)"
      (editChanged)="onEdit($event, false)"
      (previewChanged)="onEdit($event, true)"
      (deleteChange)="onDelete($event)"
    ></trf-aliases-management-list>

    <div class="list-controls">
      <trf-spring-page-number
        [pageResponse]="pageResponse"
        (pageChange)="onPageChange($event)"
      ></trf-spring-page-number>
    </div>
  </ng-container>

  <ng-container *ngIf="!isEmpty">
    <ngp-empty
      key="EMPTY_VIEW.NO_RESULTS"
      height="14vh"
      [isVisible]="true"
    ></ngp-empty>
  </ng-container>
</ng-container>
