import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { FUNDING_ALERTS, NavigationService } from '@demica/core/core';

@Component({
  selector: 'trf-header-alerts',
  templateUrl: './header-alerts.component.html',
  styleUrls: ['./header-alerts.component.sass'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),

      transition(':enter', [style({ opacity: 0 }), animate(600)]),
    ]),
  ],
})
export class HeaderAlertsComponent {
  loading = true;
  hasFundingAlerts = false;
  fundingAlertToggle = FUNDING_ALERTS;

  constructor(private navigation: NavigationService) {}

  onAlertsClick() {
    return this.navigation.alertsFunding();
  }

  onMissingFilesClick() {
    return this.navigation.dashboardMissingFiles();
  }

  onLoaded(loaded: boolean) {
    if (loaded) {
      this.loading = false;
    }
  }

  onFundingAlertsCounter(fundingAlertsCounter: number) {
    this.hasFundingAlerts = fundingAlertsCounter > 0;
  }
}
