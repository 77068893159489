<trf-slidein-container
  [ngClass]="{ wide: hasEnabledSellerAccounts }"
  (slideinClose)="onClose()"
  headerText="OPCO_DETAILS_SLIDEIN.TITLE"
>
  <ngp-contextual-notifications
    class="mx-3 mt-2"
    [notifications]="notifications"
  ></ngp-contextual-notifications>

  <trf-create-seller-form
    trfSlideinFormChanges
    [countryOptions]="countryOptions"
    [standardIndustryCodeOptions]="sicCodesOptions"
    [legalStatusOptions]="legalStatusOptions"
    [nameClasses]="nameClasses"
    [clientName]="client.name"
    [sellerId]="sellerId"
    [hasEnabledSellerAccounts]="hasEnabledSellerAccounts"
    [partialsEnabled]="partialsEnabled"
    (sellerPrevName)="onSellerName($event)"
    (nameInputChanges)="onNameInputChangesRegister($event)"
    (sellerClose)="onClose()"
    (save)="onSave($event)"
  >
  </trf-create-seller-form>
</trf-slidein-container>
