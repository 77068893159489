<div class="trf-section-content">
  <trf-pagination
    [currentPage]="pageResponse.pageNumber || 0"
    [disabled]="disabled"
    [firstPage]="pageResponse.first"
    [lastPage]="pageResponse.last"
    [numberOfPages]="pageResponse.totalPages"
    (pageChange)="onPageClick($event)"
  ></trf-pagination>
</div>
