import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { TransactionFilterResource } from '@demica/core/core';

import { FiltersContext } from './filters-context.service';

import { TransactionTypes } from '../model/transaction-types.enum';

@Injectable()
export class FundingModuleContext {
  constructor(public transactionType: TransactionTypes, private filterContext: FiltersContext) {}

  getTransactionType() {
    return this.transactionType;
  }

  getTransactionTypeName() {
    return TransactionTypes[this.getTransactionType()];
  }

  /**
   * Return transaction stream filtered by provided transaction type
   * if used need to be unsubscribed
   */
  getTransactions() {
    return this.filterContext.filters$.pipe(
      map((filters) => ({
        allTransactions: filters.defaultFilters.transactions.filter(
          isTransactionInType(this.transactionType),
        ),
        selected: filters.activeFilters.transactions.filter(
          isTransactionInType(this.transactionType),
        ),
      })),
    );
  }
}

export const isTransactionInType =
  (type: TransactionTypes) => (transaction: TransactionFilterResource) =>
    transaction.type?.entityId === type;
