import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { Locale } from '@demica/resources/app-config';

@Injectable()
/** A stateful service for communicating user changes between routed views and containers */
export class UserPreferences {
  /** a subject for emitting user currency when it is first loaded... and reloaded after changes */
  private _changeCurrency = new ReplaySubject<string>(1);
  /** a subject for emitting user locale when it is first loaded... and reloaded after changes */
  private _changeLocale = new ReplaySubject<Locale>(1);

  currency = this._changeCurrency.pipe(distinctUntilChanged());
  locale = this._changeLocale.pipe(distinctUntilChanged());

  changeLocale(locale: Locale): void {
    this._changeLocale.next(locale);
  }

  changeCurrency(locale: string): void {
    this._changeCurrency.next(locale);
  }
}
