import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, UntypedFormGroup } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';

import { errorNotification, HasEntityId, Notifications } from '@demica/core/core';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { INVALID_NUMBER_FORMAT_ERROR_KEY } from '../form-number-row/form-number-row-error-keys';
import BigNumber from 'bignumber.js';

const amountValidationError = errorNotification(
  'DASHBOARD_RECEIVABLES.AMOUNT_VALIDATION_ERROR_MESSAGE',
);
const amountFormatInvalidError = errorNotification(
  'DASHBOARD_RECEIVABLES.AMOUNT_INVALID_FORMAT_ERROR_MESSAGE',
);
const MIN_GREATER_THAN_MAX = 'minGreaterThanMax';

@Component({
  selector: 'trf-validated-amount-with-currency',
  templateUrl: './validated-amount-with-currency.component.html',
  styleUrls: ['./validated-amount-with-currency.component.sass'],
})
export class ValidatedAmountWithCurrencyComponent implements OnInit, OnDestroy {
  @Input()
  validators: ValidationMessage[];

  @Input()
  amountMinlabel: string;

  @Input()
  amountMaxLabel: string;

  @Input()
  options: unknown[];

  @Input()
  amountMinFormControl: FormControl<number>;

  @Input()
  amountMaxFormControl: FormControl<number>;

  @Input()
  currencyFormControl: FormControl<HasEntityId>;

  notifications = new Notifications();
  showError = false;
  group: UntypedFormGroup;

  errorMessage = '';

  private destroySubject = new Subject<void>();

  private subscription: Subscription;

  constructor(private fgd: FormGroupDirective) {}

  ngOnInit() {
    this.group = this.fgd.form;
    this.group.addValidators((fg: AbstractControl) => {
      const amountMinimum = this.amountMinFormControl.value;
      const amountMaximum = this.amountMaxFormControl.value;
      const maximumAmount = new BigNumber(amountMaximum);
      const minimumAmount = new BigNumber(amountMinimum);

      if (this.isMinGreaterThanMaxValue(minimumAmount, maximumAmount, amountMaximum)) {
        return { [MIN_GREATER_THAN_MAX]: true };
      } else return null;
    });
    this.subscription = this.group.statusChanges.pipe().subscribe(() => this.handleFormChange());
    this.group.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  handleFormChange() {
    const ve = this.getValidationError();
    this.notifications.clear();
    if (ve) this.notifications.add(ve);
  }

  private getValidationError() {
    if (
      this.amountMinFormControl.hasError(INVALID_NUMBER_FORMAT_ERROR_KEY) ||
      this.amountMaxFormControl.hasError(INVALID_NUMBER_FORMAT_ERROR_KEY)
    ) {
      return amountFormatInvalidError;
    } else if (this.group.hasError(MIN_GREATER_THAN_MAX)) {
      return amountValidationError;
    } else return null;
  }

  private isMinGreaterThanMaxValue(
    minimumAmount: BigNumber,
    maximumAmount: BigNumber,
    amountMaximum: number,
  ) {
    return (amountMaximum || amountMaximum === 0) && minimumAmount.isGreaterThan(maximumAmount);
  }
}
