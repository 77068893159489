import { ExportType } from '@demica/ui-core-api';

import { ColumnType } from '../../_domain/export/model/column-type.interface';
import { ColumnsTranslations } from '../../_domain/export/model/columns-translations.type';
import { PageableExportParams } from './model/pageable-export-params';

export const createBaseExportParams = (
  exportType: ExportType,
  columnsNames: string[],
  columnsTypes: ColumnType[],
  columnsPositions: number[],
  labels: { [key: string]: string },
  locale: string,
  formatters: { [key: string]: string } = {},
  translations?: ColumnsTranslations,
): PageableExportParams => ({
  exportParams: {
    columns: {
      columnsNames,
      columnsTypes,
      columnsPositions,
    },
    exportFormat: exportType,
    descriptions: {
      map: labels,
    },
    formatters,
    translations,
    locale: locale,
  },
});
