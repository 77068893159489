import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { createSortParams, PageRequest } from '../model/pageable-data';
import { PageSortParameters, SortDirection } from './paging/page-params.interface';

export type PageableSearchServiceCallback<T> = (
  searchTerm: string,
  pageRequest: PageRequest,
) => Observable<T>;

export class PageableRequestHelper<T> {
  results$: Observable<T>;
  loading$: Observable<boolean>;

  readonly pageSize = 25;
  readonly debounceTime = 300;

  private _page$ = new Subject<number>();
  private _searchTerm$ = new Subject<string>();
  private _sort$ = new Subject<PageSortParameters>();
  private _loadingSubject$ = new Subject<boolean>();

  private _searchTerm = '';
  private _pageRequest: PageRequest;

  constructor(
    private _callback: PageableSearchServiceCallback<T>,
    private _initialSortingColumn: string,
  ) {
    this._init();
  }

  changePage(page: number): void {
    this._page$.next(page);
  }

  changeSort(sort: PageSortParameters): void {
    this._sort$.next(sort);
  }

  changeSearch(searchTerm: string): void {
    this._searchTerm$.next(searchTerm);
  }

  private _init(): void {
    this._pageRequest = {
      pageNumber: 0,
      size: this.pageSize,
      sort: [
        createSortParams({
          sortColumn: this._initialSortingColumn,
          sortDirection: SortDirection.ASC,
        }),
      ],
    };

    const pageChange$ = this._page$.pipe(
      tap((page) => {
        this._pageRequest.pageNumber = page;
      }),
    );

    const searchChange$ = this._searchTerm$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      tap((searchTerm) => {
        this._searchTerm = searchTerm;
        this._pageRequest.pageNumber = 0;
      }),
    );

    const sortChange$ = this._sort$.pipe(
      tap((sortParams) => {
        this._pageRequest.sort = [createSortParams(sortParams)];
        this._pageRequest.pageNumber = 0;
      }),
    );

    this.loading$ = this._loadingSubject$.pipe(distinctUntilChanged());

    this.results$ = merge(pageChange$, searchChange$, sortChange$).pipe(
      switchMap(() => {
        this._loadingSubject$.next(true);

        return this._callback(this._searchTerm, this._pageRequest);
      }),
      tap(() => {
        this._loadingSubject$.next(false);
      }),
    );
  }
}
