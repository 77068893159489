import { AbstractControl } from '@angular/forms';

import { memoizedAsyncFormControlValidator } from './memoized-async-form-control-validator';

function MemoizedAsyncFormControlValidatorForFactoryFn(): MethodDecorator {
  return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: unknown[]) {
      const validationFn = originalMethod.apply(this, args);
      return memoizedAsyncFormControlValidator(validationFn.bind(this));
    };

    return descriptor;
  };
}

function MemoizedAsyncFormControlValidator(): MethodDecorator {
  return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    let validationFn = memoizedAsyncFormControlValidator(originalMethod);

    descriptor.value = function (args: AbstractControl) {
      validationFn = validationFn.bind(this);
      return validationFn(args);
    };

    return descriptor;
  };
}

export { MemoizedAsyncFormControlValidatorForFactoryFn, MemoizedAsyncFormControlValidator };
