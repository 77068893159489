import { authorisationGuard, TRFRoute, TRFRoutes } from '@demica/core/core';

import { HealthCheckViewComponent } from '../feature-modules/health-check/view/health-check-view.component';

import { healthCheckRedirectGuard } from '../feature-modules/health-check/guards/health-check-redirect.guard';

export const healthCheckRoute: TRFRoute = {
  component: HealthCheckViewComponent,
  canActivate: [authorisationGuard, healthCheckRedirectGuard],
  requiredAuthority: 'SUPERUSER',
  path: 'health-check',
};

export const diagnosticRoutes: TRFRoutes = [healthCheckRoute];
