import { NodeId } from './tree-node-context.interface';

export interface TreeChecklistConfiguration<T> {
  items: T[];
  childrenFactory: (item: T) => T[];
  trackBy?: (item: T) => NodeId;
}

// type guard //
export function isTreeChecklistConfiguration<T>(
  obj: unknown,
): obj is TreeChecklistConfiguration<T> {
  return obj !== null && typeof obj === 'object' && 'items' in obj && 'childrenFactory' in obj;
}
