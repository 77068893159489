<form
  *ngIf="parentForm.controls.currencyCodesGroup as currentFormGroup"
  [formGroup]="currentFormGroup"
  [ngClass]="{ 'trf-form-submitted': submitted }"
>
  <trf-form-select-row
    label="ALIASES_MANAGEMENT.ANALYSIS_CODE"
    labelClasses="required"
    defaultLabel="ALIASES_MANAGEMENT.CURRENCY_PLACEHOLDER"
    [options]="currencyCodes"
    [control]="currentFormGroup.controls.currencyCodes"
    [validations]="validations.currencyCodes"
    data-testid="analysis-code-name"
  ></trf-form-select-row>
</form>
