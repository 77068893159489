/**
 *  Wrap text inside an SVG <text> node to a specified max-width
 * @param text D3 wrapped SVG <text> element
 * @param maxWidth Maximum width in relative SVG units
 * @param leftMargin (optional) Left margin for the text nodes - useful if you want to place something before the text
 * @return height Height of the produced block - useful if you want to place something right below the wrapped text
 */
import { getSVGBox } from '../common/get-svg-box';
import { D3Sel } from '../d3/d3-model';

export function wrapText(text: D3Sel, maxWidth: number, leftMargin?: number) {
  wrap(text, maxWidth, leftMargin);
  return getSVGBox(text).height;
}

function wrap(text: D3Sel, maxWidth: number, leftMargin = 0) {
  const words = text.text().split(/\s+/).reverse();
  const y = text.attr('y');
  const dy = parseFloat(text.attr('dy'));

  let tspan = text.text(null).append('tspan').attr('x', leftMargin).attr('y', y).attr('dy', dy);
  let line: string[] = [];
  let word = words.pop();

  while (word) {
    line.push(word);
    tspan.text(line.join(' ')); // insert into DOM so that width/length measurement has something to work on
    if (tspan.node().getComputedTextLength() > maxWidth) {
      line.pop();
      tspan.text(line.join(' '));
      line = [word];
      tspan = text.append('tspan').attr('x', leftMargin).attr('dy', dy).text(word);
    }
    word = words.pop();
  }
}
