import { ChartType } from '../../model/chart-type';

export class LineChartValues {
  label: string;
  showInLegend = true;
  excludedFromGroup = false;
  // FIXME add readonly to attributes and refactor rest of the code
  constructor(
    public name: string,
    public values: string[],
    public chartType?: ChartType,
    public currency?: string,
    public readonly hidden?: boolean,
  ) {}

  getData(): string[] {
    return [this.name].concat(this.values);
  }

  withLabel(label: string) {
    this.label = label;
    return this;
  }
  hideInLegend() {
    this.showInLegend = false;
    return this;
  }
  excludeFromGroup() {
    this.excludedFromGroup = true;
    return this;
  }
}
