import { PointValue } from '../../../model/pointValue';

export const DEFAULT_POINT: PointValue = { x: '0', value: null };
export const EMPTY_POINT: PointValue = { x: null, value: null };
export const getDefaultPoint = () => ({ ...DEFAULT_POINT });
export const getEmptyPoint = () => ({ ...EMPTY_POINT });
export const isDefaultPoint = (point: PointValue) => point.x === DEFAULT_POINT.x;
export const findIndexOf = (points: PointValue[], point: { x: number | string }) =>
  points.findIndex((p) => p.x === point.x);
export const pointWithoutValues = (point: PointValue) => !point.x && !point.value;
