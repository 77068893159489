import { Injectable } from '@angular/core';

import { maxUserDefinedColumnLength } from '@demica/core/core';

import { buildMessages } from '../forms/validation-messages/message-builder';
import {
  msgDateFormat,
  msgDefaultMaxLength,
  msgMaxLength,
  msgRequired,
} from '../forms/validation-messages/message-definitions';
import { hasError } from '../forms/validation-messages/message-predicates';

@Injectable()
export class FileFormatSharedControlsValidations {
  dateFormatValidationsMessages = buildMessages([msgRequired, msgDefaultMaxLength, msgDateFormat]);
  columnNameValidationMessages = buildMessages([
    msgRequired,
    msgDefaultMaxLength,
    {
      func: hasError('user-definition-name-unavailable'),
      key: 'ERRORS.USER_DEFINITION_NAME_NOT_UNIQUE',
    },
  ]);
  valueUserDefinedValidationsMessages = buildMessages([
    { func: hasError('required-one'), key: 'VALIDATION.REQUIRED_ONE' },
    msgRequired,
    msgMaxLength(maxUserDefinedColumnLength),
  ]);
}
