import { Component } from '@angular/core';

import { NotificationIconDefinition, NotificationType } from '@demica/core/core';
import { Toast, ToasterService } from '@demica/toaster';

@Component({
  selector: 'trf-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass'],
})
export class NotificationComponent {
  public toast: Toast;
  icons: NotificationIconDefinition = {
    error: 'notification-error',
    warning: 'notification-warning',
    success: 'notification-success',
  };

  constructor(private toasterService: ToasterService) {}

  get notificationType(): NotificationType {
    return this.toast.data.type;
  }

  getMessage(): string {
    return this.toast.data.message;
  }

  closeToaster(): void {
    this.toasterService.clear(this.toast.toastId, this.toast.toastContainerId);
  }
}
