import { YAxisConfiguration } from 'c3';

export abstract class AxisY {
  protected readonly label: string;

  protected show = true;
  protected max: number;

  protected constructor(label: string) {
    this.label = label;
  }

  public static createNoneAxisY() {
    return {
      show: false,
      label: {
        position: 'outer-middle',
        text: '',
      },
    };
  }

  abstract getAxisYConfiguration(): YAxisConfiguration;

  setMax(max: number) {
    this.max = max;
    return this;
  }

  getMax() {
    return this.max;
  }

  setShow(show: boolean) {
    this.show = show;
  }

  isShowing() {
    return this.show;
  }
}
