<div
  class="component-container"
  [class.disabled]="disabled"
  (click)="toggleCheckbox()"
  data-testid="checkbox-component"
>
  <div
    #checkbox
    class="check-frame"
    role="checkbox"
    [attr.tabindex]="disabled ? -1 : 0"
    [attr.aria-checked]="checked ? 'true' : 'false'"
    [attr.aria-labelledby]="labelId"
    (keydown.enter)="toggleCheckbox()"
    data-testid="checkbox-component-input"
  >
    <div
      class="check-tick"
      [class.checked]="checked && !indeterminate"
    ></div>

    <div
      class="check-square"
      [class.checked]="checked && !indeterminate"
    ></div>

    <div
      class="check-intermediate"
      [class.checked]="indeterminate"
    ></div>
  </div>

  <label
    *ngIf="!!labelText"
    class="checkbox-label"
    [attr.id]="labelId"
    >{{ labelText | translate }}</label
  >
</div>
