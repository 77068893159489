const getCssVariable = (variableName: string, iePolyfillVarName?: string | number): string => {
  return (
    getComputedStyle(document.documentElement).getPropertyValue(variableName).trim() ??
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (iePolyfillVarName && window[iePolyfillVarName] && window[iePolyfillVarName][variableName])
  );
};
const setCssVariable = (variable: string, value: string, important = false) => {
  document.documentElement.style.setProperty(variable, value, important ? 'important' : '');
};

export { getCssVariable, setCssVariable };
