import { Component, ElementRef, ViewChild } from '@angular/core';

import { first } from 'rxjs/operators';

import { AuthService, LOGOUT_REASONS, UserService } from '@demica/core/core';

@Component({
  selector: 'trf-profile-dropdown',
  styleUrls: ['./profile-dropdown.component.sass'],
  templateUrl: 'profile-dropdown.component.html',
})
export class ProfileDropdownComponent {
  username: string;
  dropdownVisible = false;
  @ViewChild('dropdownButton')
  dropdownButton: ElementRef;
  @ViewChild('ulElement')
  ulElement: ElementRef;
  allowToChangePassword: boolean;

  constructor(private _userServ: UserService, private _auth: AuthService) {
    this._userServ.currentUser.pipe(first()).subscribe((user) => {
      this.username = user.fullName;
      this.allowToChangePassword = !user.disabledPasswordAuthentication;
    });
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
  }

  signOut(clickedEvent: MouseEvent): void {
    clickedEvent.preventDefault();
    this._auth.signOut(LOGOUT_REASONS.USER_LOGOUT);
  }

  changePassword(clickedEvent: MouseEvent): void {
    clickedEvent.preventDefault();
    this._auth.changePassword();
  }

  closeDropdown(): void {
    this.dropdownVisible = false;
    this.dropdownButton.nativeElement.focus();
  }

  focusOnFirstElement(): void {
    this.ulElement.nativeElement.children[0].focus();
  }

  focusOnLastElement(): void {
    this.ulElement.nativeElement.children[1].focus();
  }

  handleTabkey(e: KeyboardEvent): void {
    e.preventDefault();
    this.focusOnFirstElement();
  }
}
