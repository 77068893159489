<ng-container [formGroup]="group">
  <div>
    <div
      *ngIf="!!label"
      class="column-label"
      [ngClass]="{ 'row-disabled': formControl.disabled || disable }"
    >
      <label
        *ngIf="!!label"
        [for]="formControlName"
        [class]="labelClasses"
        >{{ label | translate }}</label
      >
    </div>

    <div class="column-input">
      <div class="input-group">
        <div
          *ngIf="prependText || prependIconClass"
          class="input-group-prepend"
          (click)="onClickHandler ? onClick() : false"
        >
          <span class="input-group-text">
            <ngp-svg-icon
              *ngIf="prependIconClass"
              [iconName]="prependIconClass"
            ></ngp-svg-icon>
            <ng-container *ngIf="prependText">{{ prependText }}</ng-container>
          </span>
        </div>
        <input
          class="form-control floating-point-input {{ inputClasses }}"
          type="text"
          placeholder="{{ placeholder | translate }}"
          trfCurrencyFormatter
          [id]="formControlName"
          [formControl]="formControl"
          [formControlName]="formControlName"
          [disableControl]="disable"
          [readonly]="readonly"
          [attr.aria-required]="labelClasses?.includes('required')"
          [attr.aria-describedby]="formControlName + '_notification'"
          (keypress)="onKeypress($event)"
          (decimalFormatted)="onDecimalFormatted($event)"
        />
        <div
          *ngIf="appendText || appendIconClass"
          class="input-group-append"
          (click)="onClickHandler ? onClick() : false"
        >
          <span class="input-group-text">
            <ngp-svg-icon
              *ngIf="appendIconClass"
              [iconName]="appendIconClass"
            ></ngp-svg-icon>
            <ng-container *ngIf="appendText">{{ appendText }}</ng-container>
          </span>
        </div>
        <div class="input-group-addon"></div>
      </div>

      <trf-form-number-row-default-validation-messages
        [validations]="validations"
        [name]="formControlName"
        [input]="formControl"
      ></trf-form-number-row-default-validation-messages>
    </div>
  </div>
</ng-container>
