<div class="trf-section-header">
  <h3>{{ "PREVIEW.ADDRESS" | translate }}</h3>
</div>
<div
  class="trf-section-content"
  data-testid="preview-address-field"
>
  <div [title]="preview.address1">{{ preview.address1 }}</div>
  <div
    *ngIf="preview.address2"
    [title]="preview.address2"
    >{{ preview.address2 }}</div
  >
  <div
    *ngIf="preview.address3"
    [title]="preview.address3"
    >{{ preview.address3 }}</div
  >
  <div
    *ngIf="preview.address4"
    [title]="preview.address4"
    >{{ preview.address4 }}</div
  >
  <div
    *ngIf="preview.postCode"
    [title]="preview.postCode"
    >{{ preview.postCode }}</div
  >
  <div [title]="getCountry()">{{ getCountry() }}</div>
</div>
