import { ColumnType } from '../../../_domain/export/model/column-type.interface';
import { ColumnsTranslations } from '../../../_domain/export/model/columns-translations.type';
import { Sort, TableFilters } from '../../paging/page-params.interface';
import { PageableParams } from '../pageable-params.interface';

export type PageableExportParamsType = SimplePageableExportParams | PageableExportParams;

/* TO DO: all fields in these interfaces should be read-only */
export interface PageableExportParams {
  filtersParams?: TableFilters;
  sortParams?: Sort;
  readonly exportParams: ExportData;
}

export interface SimplePageableExportParams {
  readonly exportParams: PageableParams;
}

export interface ExportData extends PageableParams {
  readonly columns: PageColumns;
  descriptions: PageLabels;
  formatters?: { [key: string]: string };
  translations?: ColumnsTranslations;
  locale?: string;
}

export interface PageColumns {
  readonly columnsNames: string[];
  readonly columnsTypes: ColumnType[];
  readonly columnsPositions: number[];
}

export interface PageLabels {
  readonly map: { [key: string]: string };
}

export const DATE_FORMATTER = 'DATE_FORMATTER';
