import { NgZone } from '@angular/core';
import { FormControlStatus, FormGroup, UntypedFormGroup } from '@angular/forms';

import { combineLatest, EMPTY, interval, merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

import { compareObjectSlowly } from '@demica/core/core';

/*
TODO: Functionality provided in this file might no longer be necessary as
  the relevant issue in Angular has been fixed:
  https://github.com/angular/angular/issues/14542, https://github.com/angular/angular/pull/38354

Event emission on form add/remove can be controlled with new flags:
https://github.com/angular/angular/pull/29664
https://github.com/angular/angular/pull/31031
 */

let applicationZone: NgZone;
export const setApplicationZone = (zone: NgZone) => {
  applicationZone = zone;
};

/**
 * This function should be used to observe form status changes instead of
 * FormGroup.statusChanges because of this bug: https://github.com/angular/angular/issues/14542
 * when creating dynamic forms where fields can be hidden or shown depending on the rest of the model.
 * Warning: the resulting observable is hot and potentially infinite -
 * take care to "next" and complete the passed observeStop$ when the form gets destroyed
 */

export const observeFormStatus$: (form: FormGroup) => Observable<FormControlStatus> = (form) => {
  return new Observable((observer) => {
    let subscription: Subscription;
    const formStatusChanges$ = form?.statusChanges ?? EMPTY;

    const changes$ = merge(formStatusChanges$, interval(250)).pipe(
      map(() => form?.status),
      distinctUntilChanged(),
    );

    applicationZone.runOutsideAngular(() => {
      subscription = changes$.subscribe((status) => {
        applicationZone.run(() => {
          observer.next(status as FormControlStatus);
        });
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  });
};

/**
 * This function should be used to observe form status & value changes instead of
 * FormGroup.statusChanges & FormGroup.valueChanges because of
 * this bug: https://github.com/angular/angular/issues/14542
 * when creating dynamic forms where fields can be hidden or shown depending on the rest of the model.
 * Warning: the resulting observable is hot and potentially infinite -
 * take care to "next" and complete the passed observeStop$ when the form gets destroyed
 */
export function observeForm$(
  form: UntypedFormGroup,
  observeStop$: Subject<unknown>,
  sideEffect?: () => void,
) {
  return observeFormInternal$(form, observeStop$, observeFormValue$, sideEffect);
}

/**
 * This function should be used to observe form status & value changes instead of
 * FormGroup.statusChanges & FormGroup.valueChanges because of
 * this bug: https://github.com/angular/angular/issues/14542.
 * This functions observes the raw form value (includes disabled fields)!
 * when creating dynamic forms where fields can be hidden or shown depending on the rest of the model.
 * Warning: the resulting observable is hot and potentially infinite -
 * take care to "next" and complete the passed observeStop$ when the form gets destroyed
 */
export function observeRawForm$(
  form: UntypedFormGroup,
  observeStop$: Subject<unknown>,
  sideEffect?: () => void,
) {
  return observeFormInternal$(form, observeStop$, observeRawFormValue$, sideEffect);
}

type ObserveFormFn = (form: UntypedFormGroup) => Observable<Record<string, unknown>>;

export function observeFormChanges(form: UntypedFormGroup) {
  return combineLatest([observeFormStatus$(form), observeFormValue$(form)]);
}

function observeFormInternal$(
  form: UntypedFormGroup,
  observeStop$: Subject<unknown>,
  observeValueFn: ObserveFormFn,
  sideEffect?: () => void,
) {
  const resultSubject = new Subject<[FormControlStatus, unknown]>();
  return applicationZone.runOutsideAngular(() => {
    combineLatest([observeFormStatus$(form), observeValueFn(form)])
      .pipe(
        debounceTime(20),
        tap((v: [FormControlStatus, unknown]) => {
          resultSubject.next(v);
          if (sideEffect) {
            sideEffect();
          }
        }),
        takeUntil(observeStop$),
      )
      .subscribe();

    return resultSubject.asObservable().pipe(takeUntil(observeStop$));
  });
}

function observeFormValue$(form: UntypedFormGroup) {
  return form.valueChanges.pipe(distinctUntilChanged(compareObjectSlowly));
}

function observeRawFormValue$(form: UntypedFormGroup) {
  return form.valueChanges.pipe(
    map(() => form.getRawValue()),
    distinctUntilChanged(compareObjectSlowly),
  );
}
