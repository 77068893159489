import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngp-inline-loader-with-status',
  templateUrl: 'inline-loader-with-status.component.html',
  styleUrls: ['./inline-loader-with-status.component.sass'],
})
export class InlineLoaderWithStatusComponent {
  @Input()
  progressMessage?: string;

  @Input()
  actionMessage?: string;

  @Input()
  doneMessage?: string;

  @Input()
  loading = true;

  @Input()
  showDoneIcon = true;
}
