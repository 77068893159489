import { Injectable } from '@angular/core';

import { CssVariableService } from '../service/css-variable.service';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CLIENT_ASSETS_DIR } from '../../../../../../global-configuration/app-constant';

const CHART_COLOR_VARIABLE_PREFIX = '--visualisation-color-';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  private _chartColors: string[] = [];

  constructor(private cssVariableService: CssVariableService) {
    this.init();
  }

  get chartColors() {
    return this._chartColors;
  }

  get pieChartColors() {
    return this._chartColors;
  }

  get chartPieLegendTextMarginLeft() {
    return parseInt(this.cssVariableService.get('--trf-pie-chart-legend-text-margin-left'), 10);
  }

  get chartLegendIconSize() {
    return parseInt(this.cssVariableService.get('--trf-chart-legend-item-size'), 10);
  }

  get instrumentAlphaLevel() {
    return this.cssVariableService.get('--trf-funding-structure-instrument-alpha-level');
  }

  get chartPieLegendTopMargin() {
    return parseInt(this.cssVariableService.get('--trf-chart-pie-legend-entry-top-margin'), 10);
  }

  get charPieLegendAlign() {
    return this.cssVariableService.get('--trf-chart-pie-legend-align-content');
  }

  get spriteFileLocation() {
    return this.replaceClientAssets(this.cssVariableService.get('--trf-sprite-file'));
  }

  get logoImgLocation() {
    return this.replaceClientAssets(this.cssVariableService.get('--trf-brand-logo'));
  }

  get logoIconLocation() {
    return this.replaceClientAssets(this.cssVariableService.get('--trf-brand-icon'));
  }

  private init() {
    this.initChartColors();
  }

  private initChartColors() {
    const nextVariable = (index: number) => CHART_COLOR_VARIABLE_PREFIX + index;
    let colorIndex = 1;
    while (this.cssVariableService.variableExist(nextVariable(colorIndex))) {
      this._chartColors.push(this.cssVariableService.get(nextVariable(colorIndex)));
      colorIndex++;
      if (colorIndex > 100) break;
    }
  }

  private replaceClientAssets(location: string) {
    return location?.replace(/"/g, '').replace(/\{clientAssets\}/g, CLIENT_ASSETS_DIR);
  }
}
