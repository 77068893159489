<td
  *ngIf="displayCheckbox"
  class="row-selector check-box-cell"
  (keyup.enter)="onCheckboxKeyup($event)"
>
  <ngp-checkbox
    [checked]="row.selected"
    (checkboxChecked)="onCheckboxClick($event)"
  ></ngp-checkbox>
</td>

<td
  *ngIf="showTransactions"
  class="cell-transaction"
  [title]="row.transaction.name"
  testid="cell-transaction"
  >{{ row.transaction.name }}</td
>

<td
  class="cell-proposed-analysis-code-value"
  [title]="row.proposedAnalysisCodeValue"
  testid="cell-proposed-analysis-code-value"
  >{{ row.proposedAnalysisCodeValue }}</td
>

<td
  class="cell-value"
  [title]="row.value"
  testid="cell-value"
  >{{ row.value }}</td
>

<td
  class="cell-data-source"
  [title]="row.dataSource.name"
  testid="cell-data-source"
  >{{ row.dataSource.name }}</td
>

<td
  class="cell-analysis-group-name"
  [title]="row.analysisGroup.name"
  testid="cell-analysis-group-name"
  >{{ row.analysisGroup.name }}</td
>

<td
  class="cell-environment-name"
  [title]="row.environment.name"
  testid="cell-environment-name"
  >{{ row.environment.name }}</td
>

<td
  class="cell-import-date date"
  [title]="row.appearanceDate | localisedDate"
  testid="cell-import-date"
  >{{ row.appearanceDate | localisedDate }}</td
>

<td
  class="cell-actions"
  testid="cell-actions"
>
  <trf-table-actions
    [actions]="actions"
    [actionSubject]="row"
  ></trf-table-actions>
</td>
