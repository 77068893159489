import { Pipe, PipeTransform } from '@angular/core';

import { CurrenciesDecimalPlacesService, LocalisationService } from '@demica/core/core';

import { CurrencyPipe } from './currency.pipe';

@Pipe({
  name: 'localisedMoney',
  pure: false,
})
export class LocalisedMoneyPipe implements PipeTransform {
  private currencyPipe: CurrencyPipe;

  constructor(
    protected currenciesFormat: CurrenciesDecimalPlacesService,
    protected localisationService: LocalisationService,
  ) {
    this.currencyPipe = new CurrencyPipe(currenciesFormat, localisationService);
  }

  transform(value: string | number, currency: string): string {
    if (!value && value !== 0) return '';
    return `${currency} ${this.currencyPipe.transform(value, currency)}`;
  }
}
