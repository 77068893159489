import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ReplaySubject, Subscription } from 'rxjs';

import { ExportType } from '@demica/ui-core-api';

@Component({
  selector: 'ngp-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.sass'],
})
export class DataExportComponent implements OnInit, OnDestroy {
  @Input()
  exportEnabled!: boolean;
  @Input()
  exportLabel = 'EXPORT_DATA.EXPORT_DEFAULT_LABEL';
  @Input()
  dataExportId = '';

  @Output()
  requestEmitter = new EventEmitter<ExportType>();

  exportType = ExportType;

  private subscription!: Subscription;
  private exportSubject = new ReplaySubject<ExportType>();
  private canExportChart = true;

  ngOnInit(): void {
    this.subscription = this.exportSubject.subscribe((exportType) => {
      if (this.canExportChart) {
        this.canExportChart = false;
        this.requestEmitter.emit(exportType);
        setTimeout(() => {
          this.canExportChart = true;
        }, 1500);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  requestExportDownload(exportType: ExportType) {
    if (this.exportEnabled) {
      this.exportSubject.next(exportType);
    }
  }
}
