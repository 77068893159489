import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RemoteLoggerResourceService {
  constructor(private readonly _httpClient: HttpClient) {}

  log$<DATA>(endpoint: string, data: DATA): Observable<unknown> {
    return this._httpClient.post(endpoint, data);
  }
}
