import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { ColumnToggleStateService } from '../data-table/column-toggle-state.service';

import { ColumnDefinition } from '../data-table/column-definition.interface';

@Component({
  selector: 'trf-toggle-columns-dropdown',
  templateUrl: 'toggle-columns-dropdown.component.html',
  styleUrls: ['./toggle-columns-dropdown.component.sass'],
})
export class ToggleColumnsDropdownComponent {
  @Input()
  buttonTranslationPrefix: string;
  @ViewChild('trigger', { read: ElementRef })
  triggerButton: ElementRef;
  columns$ = this.columnToggleStateService.getToggleableColumns$();
  dropdownVisible = false;

  constructor(private columnToggleStateService: ColumnToggleStateService) {}

  closeDropdown(): void {
    this.dropdownVisible = false;
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
  }

  setToggleColumns(checked: boolean, column: ColumnDefinition): void {
    this.columnToggleStateService.onSelectionChange(checked, column);
  }
}
