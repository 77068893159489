import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { encodeParams } from '../../_commons_/helpers/encode-params/encode-params.helper';
import { encodeEndpoint } from '../../_commons_/helpers/encode-url/encode-url';
import { toData } from '../../_commons_/helpers/response-mapping/response-mapping.helper';

import { CodeAvailabilityResponse } from '../../_commons_/interface/domain/availability-response.interface';
import { EntityId } from '../../_commons_/interface/entity/entity-id.interface';
import { DeleteResponse } from '../../_commons_/interface/rest-response/delete-response.interface';
import { PostResponse } from '../../_commons_/interface/rest-response/post-response.interface';
import { PutResponse } from '../../_commons_/interface/rest-response/put-response.interface';
import { RestResponse } from '../../_commons_/interface/rest-response/rest-response.interface';
import { TemplateCalendarFinalVersion } from '../model/template-calendar-final-version.interface';
import {
  TemplateCalendarBase,
  TemplateCalendarDetailsDto,
  TemplateCalendarDto,
} from '../model/template-calendar.interface';

@Injectable({
  providedIn: 'root',
})
export class CalendarTemplateResourceService {
  constructor(private _http: HttpClient) {}

  getCalendars$(): Observable<TemplateCalendarBase[]> {
    const url = encodeEndpoint('resources/templates/calendars');

    return this._http.get<RestResponse<TemplateCalendarBase[]>>(url).pipe(map(toData));
  }

  getFinalCalendarsForWorkingDay$(workingDay: boolean): Observable<TemplateCalendarFinalVersion[]> {
    const params = encodeParams({ workingDay });
    const url = encodeEndpoint('resources/templates/calendars/finalVersion');

    return this._http
      .get<RestResponse<TemplateCalendarFinalVersion[]>>(url, { params })
      .pipe(map((result) => toData(result)));
  }

  createCalendar$(data: TemplateCalendarDetailsDto): Observable<PostResponse> {
    const url = encodeEndpoint('resources/templates/calendars');

    return this._http.post<RestResponse<PostResponse>>(url, data).pipe(map(toData));
  }

  getCalendar$(calendarId: EntityId): Observable<TemplateCalendarDto> {
    const url = encodeEndpoint('resources/templates/calendars/{}', calendarId);

    return this._http.get<RestResponse<TemplateCalendarDto>>(url).pipe(map(toData));
  }

  updateCalendar$(calendarId: EntityId, data: TemplateCalendarDetailsDto): Observable<PutResponse> {
    const url = encodeEndpoint('resources/templates/calendars/{}', calendarId);

    return this._http.put<RestResponse<PutResponse>>(url, data).pipe(map(toData));
  }

  deleteCalendar$(calendarId: EntityId, entityRevision: number): Observable<DeleteResponse> {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint('resources/templates/calendars/{}', calendarId);

    return this._http.delete<RestResponse<DeleteResponse>>(url, { params }).pipe(map(toData));
  }

  promoteCalendar$(calendarId: EntityId, data: unknown): Observable<PostResponse> {
    const url = encodeEndpoint('resources/templates/calendars/{}/promote', calendarId);

    return this._http.post<RestResponse<PostResponse>>(url, data).pipe(map(toData));
  }

  checkCalendarNameAvailable$(
    name: string,
    templateId?: EntityId,
  ): Observable<CodeAvailabilityResponse> {
    const params = encodeParams({ name, templateId });
    const url = encodeEndpoint('resources/templates/calendars/name-availability');

    return this._http
      .get<RestResponse<CodeAvailabilityResponse>>(url, { params })
      .pipe(map(toData));
  }
}
