import { DatePipe, FormatWidth } from '@angular/common';
import { AfterViewInit, Component, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { noop, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  LocalisationService,
  nativeDateFromDateTimeString,
  LocaleFormats,
} from '@demica/core/core';

import { CustomDatepickerI18n } from '../date-time-common/date-picker-i18n';
import { NgbDateTimeNativeAdapter } from './date-time-native-adapter';
import {
  NgbDateAdapter,
  NgbDatepickerI18n,
  NgbPopover,
  NgbPopoverConfig,
  NgbTimeAdapter,
} from '@ng-bootstrap/ng-bootstrap';

const INVALID_DATE_TIME: ValidationErrors = { dateTimeFormat: true };

@Component({
  selector: 'trf-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.sass'],
  providers: [
    DatePipe,
    { provide: NgbDateAdapter, useClass: NgbDateTimeNativeAdapter },
    { provide: NgbTimeAdapter, useClass: NgbDateTimeNativeAdapter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateTimePickerComponent), multi: true },
  ],
})
export class DateTimePickerComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  hourStep = 1;
  @Input()
  minuteStep = 1;
  @Input()
  secondStep = 1;
  @Input()
  seconds = true;

  _dateTimeInnerControl: FormControl<Date> = new FormControl<Date>(null);
  timeFormat: LocaleFormats = {
    format: '',
    formatLocalized: '',
  };
  dateFormat: LocaleFormats = {
    format: '',
    formatLocalized: '',
  };
  dateTimeFormat: LocaleFormats = {
    format: '',
    formatLocalized: '',
  };
  disabled = false;
  meridian = true;
  showTimePickerToggle = false;

  onTouched: () => void = noop;
  onValidationChange: () => void = noop;
  onChange: (_: Date) => void = noop;

  @ViewChild(NgbPopover)
  private _popover: NgbPopover;
  private _destroyed$ = new Subject<void>();
  private _validationStatus: ValidationErrors | null;

  constructor(
    private _fb: FormBuilder,
    private _config: NgbPopoverConfig,
    public localisationService: LocalisationService,
  ) {
    this._enableLocalisationSupport();
    // set/amend configuration
    _config.autoClose = 'outside';
    _config.placement = 'auto';
  }

  ngAfterViewInit(): void {
    this._popover.hidden.subscribe(() => (this.showTimePickerToggle = false));
    // listen to internal dateTime value
    this._dateTimeInnerControl.valueChanges.pipe(takeUntil(this._destroyed$)).subscribe((value) => {
      this._clearErrors();
      this.onChange(value);
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  writeValue(value: Date) {
    this._clearErrors();
    this._dateTimeInnerControl.patchValue(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void) {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl<Date>): ValidationErrors | null {
    if (!control?.value) return null;
    if (!(control.value instanceof Date)) {
      this._markAsInvalid();
    }
    return this._validationStatus;
  }

  toggleDateTimeState($event: MouseEvent) {
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onPickerOpen() {
    this._setDateToNow();
  }

  onInputChange($event: Event) {
    const target = $event.target as HTMLInputElement;
    const value = target.value;
    // mark our main control as
    this.onTouched();

    // check value and clear errors
    if (!value) {
      this._dateTimeInnerControl.patchValue(null);
      this._clearErrors();
      return;
    }
    // get the date
    const date = nativeDateFromDateTimeString(
      value,
      this.dateTimeFormat.format,
      this.dateFormat.format,
    );

    // deal with date or the lack of date
    if (date) {
      this._clearErrors();
      this.onChange(date);
      // align inner date time in case of manual insertion
      this._alignInnerDateTime(date);
    } else {
      this._markAsInvalid();
      this.onValidationChange();
    }
  }

  onInputBlur() {
    this.onTouched();
  }

  private _alignInnerDateTime(date: Date) {
    const dateTimeInnerIsDate = this._dateTimeInnerControl.value instanceof Date;
    const dateTimeInnerIsDifferentThanDate =
      dateTimeInnerIsDate && this._dateTimeInnerControl.value.getTime() !== date.getTime();

    if (dateTimeInnerIsDifferentThanDate || !dateTimeInnerIsDate) {
      this._dateTimeInnerControl.setValue(date);
    }
  }

  private _markAsInvalid() {
    this._validationStatus = INVALID_DATE_TIME;
  }

  private _clearErrors() {
    this._validationStatus = null;
  }

  private _enableLocalisationSupport() {
    this.localisationService.locale.pipe(takeUntil(this._destroyed$)).subscribe((locale) => {
      this.dateTimeFormat = {
        format: this.localisationService.getLocaleDateTimeFormat(locale, FormatWidth.Medium),
        formatLocalized: this.localisationService.getLocaleDateTimeFormatLocalised(
          locale,
          FormatWidth.Medium,
        ),
      };

      this.dateFormat = {
        format: this.localisationService.getLocaleDateFormat(locale, FormatWidth.Medium),
        formatLocalized: this.localisationService.getLocaleDateFormatLocalised(
          locale,
          FormatWidth.Medium,
        ),
      };

      this.timeFormat = {
        format: this.localisationService.getLocaleTimeFormat(locale, FormatWidth.Medium),
        formatLocalized: this.localisationService.getLocaleDateTimeFormatLocalised(
          locale,
          FormatWidth.Medium,
        ),
      };

      this.meridian = this.localisationService.isMeridianEnabled(locale);
    });
  }

  private _setDateToNow() {
    if (this._dateTimeInnerControl.value === null) {
      this._dateTimeInnerControl.setValue(new Date());
      setTimeout(() => {
        this._dateTimeInnerControl.updateValueAndValidity();
      });
    }
  }
}
