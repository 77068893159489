import { Directive, HostListener } from '@angular/core';

import { Key } from '../model/key';

@Directive({
  selector: '[trfRowKeyHandler]',
})
export class RowKeyHandlerDirective {
  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    const key = new Key(event);
    const target = event.target as HTMLElement;
    const parentElement = target?.parentElement;

    if (
      key.isDown &&
      parentElement?.nextElementSibling &&
      parentElement.nextElementSibling.children?.length > 0
    ) {
      const nextElement = parentElement.nextElementSibling;
      const index = target.getAttribute('data-index') as string;

      return (nextElement.children.item(+index) as HTMLElement)?.focus();
    }
    if (key.isUp && parentElement?.previousElementSibling) {
      const previousElement = parentElement.previousElementSibling;
      const index = target.getAttribute('data-index') as string;

      return (previousElement.children.item(+index) as HTMLElement)?.focus();
    }
    if (key.isLeft && target.previousElementSibling) {
      const focusableElement = target.previousElementSibling as HTMLElement;

      return focusableElement?.focus();
    }
    if (key.isRight && target.nextElementSibling) {
      const focusableElement = target.nextElementSibling as HTMLElement;

      return focusableElement?.focus();
    }
  }
}
