import { enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { PublicAppModule } from '@demica/public-app';
import { License } from '@demica/yfiles';

import { PUBLIC_APP_PATH } from '../../../global-configuration/app-constant';
import { AppModule } from './app/app.module';
import { configureYFilesLicense } from './app/yfiles/yfiles-license';
import { environment } from './environments/environment';

if (environment.production) enableProdMode();

const bootstrap = useUnauthorizedApp() ? publicAppBootstrap : trfAppBootstrap;

License.value = configureYFilesLicense(environment.yfilesLicense);
if (environment.profiler) {
  bootstrap()
    .then((appRef: never) => enableDebugTools(appRef))
    .catch((e) => console.log(e));
} else {
  bootstrap().catch((e) => console.log(e));
}

function trfAppBootstrap() {
  return platformBrowserDynamic().bootstrapModule(AppModule);
}

function publicAppBootstrap() {
  return platformBrowserDynamic().bootstrapModule(PublicAppModule);
}

function useUnauthorizedApp(): boolean {
  return window.location.pathname.startsWith(`/${PUBLIC_APP_PATH}`);
}
