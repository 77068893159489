<div class="trf-section">
  <div class="trf-section-header">
    <h4>{{ "ALIASES_MAPPING.LIST_OF_MAPPINGS" | translate }}</h4>
  </div>

  <div class="trf-section-content">
    <ngp-contextual-notifications
      *ngIf="environments.length > 1"
      [notifications]="notificationsTop"
    ></ngp-contextual-notifications>

    <div class="trf-search-form">
      <form [formGroup]="form">
        <trf-form-select-row
          label="ALIASES_MANAGEMENT.ENVIRONMENT"
          defaultLabel="ALIASES_MANAGEMENT.FORM_ENVIRONMENT_DEFAULT_VALUE"
          [control]="form.controls.environment"
          [options]="environments"
          testid="environment"
        ></trf-form-select-row>

        <ng-container *ngIf="aliasMappings.length">
          <trf-data-table
            tableClasses="disabled-rows"
            [dataSource]="dataSource"
            [headerConfiguration]="headerConfig"
            [rowConfiguration]="rowConfig"
            [allSelected]="false"
            [tableLabel]="'ALIASES_MAPPING.LIST_OF_MAPPINGS'"
            testid="alias-mapping-search-list"
          ></trf-data-table>
        </ng-container>

        <ng-container *ngIf="!aliasMappings.length">
          <ngp-not-defined message="ALIASES_MAPPING.NO_MAPPINGS"></ngp-not-defined>
        </ng-container>

        <div class="form-fields-wrapper controls-row-alignment">
          <trf-form-select-row
            label="ALIASES_MAPPING.DATA_SOURCE"
            defaultLabel="ALIASES_MAPPING.FORM_DATA_SOURCE_DEFAULT_VALUE"
            [control]="form.controls.dataSource"
            [options]="transactionDataSource"
            data-testid="data-source-name"
          ></trf-form-select-row>

          <trf-form-text-row
            label="ALIASES_MAPPING.ALIAS_VALUE"
            placeholder="ALIASES_MAPPING.ALIAS_VALUE_PLACEHOLDER"
            [control]="form.controls.aliasValue"
            [validations]="aliasValidations"
            [disable]="noDataSourceSelected"
            [maxLength]="maxLength"
            data-testid="alias-value-name"
          ></trf-form-text-row>

          <ngp-button
            key="ALIASES_MAPPING.BUTTON_ADD"
            [classes]="canAddMapping() ? 'tertiary' : 'inactive'"
            (click)="onAddAliasMapping()"
            data-testid="add-new-mapping-button"
          ></ngp-button>
        </div>
        <ngp-contextual-notifications
          *ngIf="metaData"
          [notifications]="notificationsBottom"
        ></ngp-contextual-notifications>
      </form>
    </div>
  </div>
</div>
