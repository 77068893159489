<div class="trf-section-header">
  <h3>{{ "ALIASES.TITLE_AWAITING_APPROVAL" | translate }}</h3>
</div>
<div class="trf-section-content">
  <div class="trf-search-form">
    <form [formGroup]="form">
      <div class="form-fields-wrapper">
        <trf-form-select-row
          *ngIf="transactions"
          label="ALIASES.TRANSACTION"
          defaultLabel="ALIASES.FORM_ALL_FILTERED_TRANSACTIONS"
          [control]="form.controls.transaction"
          [options]="transactions"
          testid="transactions"
        ></trf-form-select-row>

        <trf-form-text-row
          label="ALIASES.PROPOSED_ANALYSIS_CODE"
          placeholder="ALIASES.PROPOSED_ANALYSIS_CODE_PLACEHOLDER"
          data-testId="proposed-mapping"
          [control]="form.controls.proposedAnalysisCodeValue"
          [maxLength]="maxLength"
        ></trf-form-text-row>

        <trf-form-text-row
          label="ALIASES.ALIAS_VALUE"
          placeholder="ALIASES.ALIAS_VALUE_PLACEHOLDER"
          data-testId="alias-value"
          [control]="form.controls.analysisCode"
          [maxLength]="maxLength"
        ></trf-form-text-row>

        <trf-form-select-row
          label="ALIASES.DATA_SOURCE"
          defaultLabel="ALIASES.FORM_ALL_FILTERED_DATA_SOURCES"
          [control]="form.controls.dataSource"
          [options]="filteredDataSources"
          testid="data-source"
        ></trf-form-select-row>

        <trf-form-select-row
          label="ALIASES.ANALYSIS_GROUP"
          translationPrefix="COLUMN_DEFINITION_TYPES"
          defaultLabel="ALIASES.FORM_ALL_FILTERED_ANALYSIS_GROUPS"
          [control]="form.controls.analysisGroup"
          [options]="filteredAnalysisGroups"
          testid="analysis-groups"
        ></trf-form-select-row>

        <trf-form-select-row
          label="ALIASES.ENVIRONMENTS"
          defaultLabel="ALIASES.FORM_ALL_FILTERED_ENVIRONMENTS"
          [control]="form.controls.environment"
          [options]="filteredEnvironments"
          testid="environments"
        ></trf-form-select-row>
      </div>
    </form>

    <div class="controls">
      <ngp-button
        classes="tertiary"
        key="ALIASES.BUTTON_CLEAR_ALL"
        (click)="onClear()"
        testid="clear-button"
      ></ngp-button>

      <ngp-button
        classes="primary"
        key="ALIASES.BUTTON_SEARCH"
        (click)="onSearch()"
        testid="search-button"
      ></ngp-button>
    </div>
  </div>
</div>
