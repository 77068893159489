<ng-container [formGroup]="group">
  <div class="form-row">
    <div class="fields-row">
      <div class="amount-section">
        <div class="decimal-row">
          <trf-form-decimal-row
            [control]="amountMinFormControl"
            [label]="amountMinlabel"
            [validations]="validators"
            [acceptNegatives]="true"
            placeholder="DASHBOARD_RECEIVABLES.MINIMUM_AMOUNT"
            testid="amount-minimum"
          ></trf-form-decimal-row>

          <trf-form-decimal-row
            [control]="amountMaxFormControl"
            [label]="amountMaxLabel"
            [validations]="validators"
            [acceptNegatives]="true"
            placeholder="DASHBOARD_RECEIVABLES.MAXIMUM_AMOUNT"
            testid="amount-maximum"
          ></trf-form-decimal-row>
        </div>
      </div>

      <div class="currency-codes">
        <trf-form-select-row
          [options]="options"
          [control]="currencyFormControl"
          label="DASHBOARD_RECEIVABLES.CURRENCY"
          defaultLabel="DASHBOARD_RECEIVABLES.DEFAULT_LABEL_CURRENCY"
          testid="currency-codes"
        ></trf-form-select-row>
      </div>
    </div>
    <ngp-contextual-notifications [notifications]="notifications"></ngp-contextual-notifications>
  </div>
</ng-container>
