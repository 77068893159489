import { Directive, HostListener } from '@angular/core';

import {
  A11Y_BODY_CONTAINER_ATTR,
  A11Y_HEADER_CONTAINER_ATTR,
  A11Y_INDEX,
  A11Y_INDEX_ATTR,
  A11Y_MAIN_CONTAINER,
  A11Y_ROW_CONTAINER_ATTR,
  A11Y_ICON_TAG,
} from '../model/accessibility-attributes.const';
import { NOT_FOCUSABLE_DATASET_ATTRIBUTE } from '../model/focusable.const';
import { Key } from '../model/key';

@Directive({
  selector: '[trfTableKeyHandler]',
  standalone: true,
})
export class TableKeyHandlerDirective {
  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    const target = event.target as HTMLElement;
    const key = new Key(event);

    if (!(key.isUp || key.isDown || key.isLeft || key.isRight || key.isEnter)) {
      return;
    }

    if (target.hasAttribute(A11Y_MAIN_CONTAINER)) {
      const headerEl = target.querySelector<HTMLElement>(A11Y_HEADER_CONTAINER_ATTR);
      const firstEl = headerEl?.querySelector<HTMLElement>(A11Y_INDEX_ATTR);

      return firstEl?.focus();
    } else {
      const headerElement = target.closest<HTMLElement>(A11Y_HEADER_CONTAINER_ATTR);
      const rowElement = target.closest<HTMLElement>(A11Y_ROW_CONTAINER_ATTR);
      const bodyElement = target.closest<HTMLElement>(A11Y_BODY_CONTAINER_ATTR);
      const allRows = bodyElement?.querySelectorAll<HTMLElement>(A11Y_ROW_CONTAINER_ATTR);

      let downParent: Element | null | undefined;
      let upParent: Element | null | undefined;

      if (allRows) {
        const targetRowIndex = Array.from(allRows).findIndex((el) => el === rowElement);

        downParent = allRows[targetRowIndex + 1];
        upParent = allRows[targetRowIndex - 1] ?? bodyElement?.previousElementSibling;
      } else {
        downParent = headerElement?.nextElementSibling;
        upParent = bodyElement?.previousElementSibling;
      }

      const downParentChildren = downParent?.querySelectorAll<HTMLElement>(A11Y_INDEX_ATTR);

      if (key.isDown && downParentChildren && downParentChildren.length > 0) {
        let targetElement: HTMLElement | null | undefined;
        let iconIndex: string | null;
        let nextFocusableElement: HTMLElement | undefined;

        if (isTargetAnIcon(target)) {
          targetElement = target.parentElement?.closest<HTMLElement>(A11Y_INDEX_ATTR);
          iconIndex = target.getAttribute(A11Y_INDEX);
        } else {
          targetElement = target;
          iconIndex = '0';
        }

        const index = targetElement?.getAttribute(A11Y_INDEX);

        nextFocusableElement = Array.from(downParentChildren).find(
          (child) => child.getAttribute(A11Y_INDEX) === index,
        );

        if (nextFocusableElement && !isElementFocusable(nextFocusableElement)) {
          const parentIcons = nextFocusableElement.querySelectorAll<HTMLElement>(A11Y_INDEX_ATTR);

          nextFocusableElement = Array.from(parentIcons).find(
            (el) => el.getAttribute(A11Y_INDEX) === iconIndex,
          );
        }

        return nextFocusableElement?.focus();
      }

      const upParentChildren = upParent?.querySelectorAll<HTMLElement>(A11Y_INDEX_ATTR);

      if (key.isUp && upParentChildren && upParentChildren.length > 0) {
        let targetElement: HTMLElement | null | undefined;
        let iconIndex: string | null;
        let prevFocusableElement: HTMLElement | undefined;

        if (isTargetAnIcon(target)) {
          targetElement = target.parentElement?.closest<HTMLElement>(A11Y_INDEX_ATTR);
          iconIndex = target.getAttribute(A11Y_INDEX);
        } else {
          targetElement = target;
        }
        const index = targetElement?.getAttribute(A11Y_INDEX);

        prevFocusableElement = Array.from(upParentChildren).find(
          (child) => child.getAttribute(A11Y_INDEX) === index,
        );

        if (prevFocusableElement && !isElementFocusable(prevFocusableElement)) {
          const parentIcons = prevFocusableElement.querySelectorAll<HTMLElement>(A11Y_INDEX_ATTR);

          prevFocusableElement = Array.from(parentIcons).find(
            (el) => el.getAttribute(A11Y_INDEX) === iconIndex,
          );
        }

        return prevFocusableElement?.focus();
      }

      if (key.isLeft) {
        const prevElement: HTMLElement = target.previousElementSibling as HTMLElement;
        let prevFocusableElement: HTMLElement | null | undefined;

        if (prevElement && isElementFocusable(prevElement)) {
          prevFocusableElement = prevElement;
        } else if (isTargetAnIcon(target)) {
          prevFocusableElement = target.parentElement?.closest(A11Y_INDEX_ATTR)
            ?.previousElementSibling as HTMLElement;
        }

        return prevFocusableElement?.focus();
      }

      const targetNextEl = target.nextElementSibling as HTMLElement;

      if (key.isRight && targetNextEl) {
        const nextFocusableElement = isElementFocusable(targetNextEl)
          ? targetNextEl
          : targetNextEl.querySelector<HTMLElement>(A11Y_INDEX_ATTR);

        return nextFocusableElement?.focus();
      }
    }
  }
}

// TODO TRFV2-22638 - move this functions to some shared service
function isElementFocusable(item: Element | null | undefined): boolean {
  return (
    !!item &&
    !item.hasAttribute(`DATA-${NOT_FOCUSABLE_DATASET_ATTRIBUTE}`) &&
    item.getAttribute('tabindex') === '-1'
  );
}

function isTargetAnIcon(item: Element): boolean {
  return item.tagName.toLowerCase() === A11Y_ICON_TAG;
}
