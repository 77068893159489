import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { EntityId } from '../interface/has-entity-id.interface';

/* An intermediate service to hold route strings and provide ease of code lookup and refactoring */
@Injectable()
export class NavigationService {
  constructor(private _router: Router) {}

  /**
   * Intercept anchor click and navigate with Angular's router
   * @param e Mouse event of the click. Clicks on non-anchor elements will are ignored.
   */
  navigateToClickedAnchor(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    if (target instanceof HTMLAnchorElement)
      this._router.navigate([target.attributes['href' as any].value]);
  }

  clients(): Promise<boolean> {
    return this._router.navigate(['/clients']);
  }

  clientPreview(id: EntityId, extras?: NavigationExtras): Promise<boolean> {
    return this._router.navigate(['/clients/client-preview', id], extras);
  }

  clientVersions(entityId: number): Promise<boolean> {
    return this._router.navigate(['/clients/client-versions', entityId]);
  }

  clientEdit(entityId: number): Promise<boolean> {
    return this._router.navigate(['/clients/client-edit', entityId]);
  }

  sellerPreview(entityId: EntityId, extras?: NavigationExtras): Promise<boolean> {
    return this._router.navigate(['/sellers/seller-preview', entityId], extras);
  }

  sellerVersions(entityId: number): Promise<boolean> {
    return this._router.navigate(['/sellers/seller-versions', entityId]);
  }

  funderGroups(): Promise<boolean> {
    return this._router.navigate(['/funder-groups']);
  }

  funderGroupPreview(id: number, extras?: NavigationExtras): Promise<boolean> {
    return this._router.navigate(['/funder-groups/funder-group-preview', id], extras);
  }

  funderGroupEdit(entityId: EntityId): Promise<boolean> {
    return this._router.navigate(['/funder-groups/funder-group-edit', entityId]);
  }

  funderPreview(id: EntityId, extras?: NavigationExtras): Promise<boolean> {
    return this._router.navigate(['/funders/funder-preview', id], extras);
  }

  funderEdit(entityId: number): Promise<boolean> {
    return this._router.navigate(['/funders/funder-edit', entityId]);
  }

  transactions(): Promise<boolean> {
    return this._router.navigate(['/transactions']);
  }

  transactionPreview(entityId: number): Promise<boolean> {
    return this._router.navigate(['/transactions/transaction-preview', entityId]);
  }

  transactionVersionPreview(entityId: number, extras?: NavigationExtras): Promise<boolean> {
    return this._router.navigate(
      [`/transactions/transaction-preview/${entityId}/client-data/file-formats`],
      extras,
    );
  }

  transactionVersions(entityId: number): Promise<boolean> {
    return this._router.navigate(['/transactions/transaction-versions', entityId]);
  }

  transactionAdd(): Promise<boolean> {
    return this._router.navigate(['/transactions/transaction-add']);
  }

  transactionEdit(entityId: number): Promise<boolean> {
    return this._router.navigate(['/transactions/transaction-edit', entityId]);
  }

  dashboardPortfolio(): Promise<boolean> {
    return this._router.navigate(['/dashboard/portfolio']);
  }

  dashboardFunding(): Promise<boolean> {
    return this._router.navigate(['/dashboard/funding']);
  }

  alertsFunding(): Promise<boolean> {
    return this._router.navigate(['/alerts/funding-alerts']);
  }

  dashboardMissingFiles(): Promise<boolean> {
    return this._router.navigate(['/alerts/missing-files']);
  }
}
