import { ErrorHandler, NgModule } from '@angular/core';

import { GlobalErrorHandler } from './handlers/global-error/global-error.handler';

@NgModule({
  imports: [],
  providers: [
    GlobalErrorHandler,
    {
      provide: ErrorHandler,
      useExisting: GlobalErrorHandler,
    },
  ],
})
export class ErrorHandlerModule {}
