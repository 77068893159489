import { environment as overrides } from '../generated/environments';

export const environment = Object.assign(
  {
    production: true,
    yfilesLicense: 'development',
    profiler: false,
  },
  overrides,
);
