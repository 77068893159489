import { Injectable } from '@angular/core';

import { throwError } from 'rxjs';
import { retry } from 'rxjs/operators';

import { encodeEndpoint } from '@demica/resources/common';
import { RemoteLoggerResourceService } from '@demica/resources/remote-logger';

import { ErrorLogRecord } from '../../log-record.interface';
import { LogRecord } from '../../log-record.interface';
import { REMOTE_LOGGER_RETRIES_COUNT } from './remote-logger-retries.const';

@Injectable({ providedIn: 'root' })
export class RemoteLoggerService {
  static DEFAULT_ENDPOINT_UNENCODED = 'resources/frontend-logs';

  private _endpoint = encodeEndpoint(RemoteLoggerService.DEFAULT_ENDPOINT_UNENCODED);

  constructor(private _remoteLoggerResource: RemoteLoggerResourceService) {}

  setEndpoint(url: string, encode = false): void {
    this._endpoint = !encode ? url : encodeEndpoint(url);
  }

  getEndpoint(): string {
    return this._endpoint;
  }

  log<LOG_DATA = LogRecord | ErrorLogRecord>(data: LOG_DATA): void {
    this._remoteLoggerResource
      .log$<LOG_DATA>(this._endpoint, data)
      .pipe(retry(REMOTE_LOGGER_RETRIES_COUNT))
      .subscribe({ error: (error) => throwError(error) });
  }
}
