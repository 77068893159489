import { Injectable, NgZone } from '@angular/core';

import { asyncScheduler, Observable, Subject } from 'rxjs';
import { bufferTime, filter, map, subscribeOn } from 'rxjs/operators';

import { enterZone, leaveZone } from '@demica/utils';

import { ValidLogRecord } from '../../log-record.interface';

@Injectable({ providedIn: 'root' })
export class LogsAggregatorService {
  private _aggregatedRecords: Observable<ValidLogRecord[]>;
  private _incomingRecords = new Subject<ValidLogRecord>();

  constructor(private readonly _ngZone: NgZone) {
    this._aggregatedRecords = this._incomingRecords.pipe(
      bufferTime(2000, leaveZone(this._ngZone, asyncScheduler)),
      filter((logs) => logs.length > 0),
      map((buffer) =>
        buffer.filter(
          (value, index, self) =>
            self.findIndex((item) => item.message === value.message) === index,
        ),
      ),
      subscribeOn(enterZone(this._ngZone, asyncScheduler)),
    );
  }

  addLog(data: ValidLogRecord): void {
    this._incomingRecords.next(data);
  }

  getAggregatedLogs(): Observable<ValidLogRecord[]> {
    return this._aggregatedRecords;
  }
}
