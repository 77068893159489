import { authorisationGuard, TRFRoute, TRFRoutes } from '@demica/core/core';

import { OnboardingViewComponent } from '../feature-modules/onboarding/view/onboarding-view.component';

import { onboardingMenuOptionRoutePermissions } from './onboarding.routes-permissions';

export const onboardingMenuOptionRoute: TRFRoute = {
  component: OnboardingViewComponent,
  canActivate: [authorisationGuard],
  path: 'onboarding',
  ...onboardingMenuOptionRoutePermissions,
};

export const onboardingRoutes: TRFRoutes = [onboardingMenuOptionRoute];
