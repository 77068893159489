<td
  [title]="(row.dataSourceId | transactionDataSourceCast).name"
  testid="cell-alias-mapping-datasource"
>
  {{ (row.dataSourceId | transactionDataSourceCast).name }}
</td>
<td
  [title]="row.value"
  testid="cell-alias-mapping-value"
>
  {{ row.value }}
</td>
<td
  class="cell-actions"
  testid="cell-actions"
  testid="cell-alias-mapping-actions"
>
  <trf-table-actions
    [actions]="actions"
    [actionSubject]="row"
  ></trf-table-actions>
</td>
