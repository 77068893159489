import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { generateUUID, NotificationService } from '@demica/core/core';

import { findDeepestElementLevel } from '../../utils/drag-and-drop-nested.helper';

import {
  DndNestedElement,
  DndColumnDefinition,
  defaultContainerWidth,
  defaultMaxNestingLevel,
  defaultMaxBusinessLevel,
  defaultLevelWidth,
  DndOutputData,
  DndComponentConfiguration,
  DndOutputMovedData,
  DndInputSourceData,
  DndViewData,
} from '../../model/drag-and-drop-nested.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'ngp-drag-and-drop-nested',
  templateUrl: './drag-and-drop-nested.component.html',
  styleUrls: ['./drag-and-drop-nested.component.sass'],
})
export class DragAndDropNestedComponent<T extends DndNestedElement, K>
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  dataSource!: T[];
  @Input()
  containerName!: K;
  @Input()
  columnDefinitions!: DndColumnDefinition[];
  @Input()
  rowConfiguration!: DndComponentConfiguration;
  @Input()
  containerTranslation!: string | undefined;
  @Input()
  tableLabel = '';
  @Input()
  set viewSourceData(value: DndInputSourceData) {
    if (value) {
      this.viewData = {
        containerWidthNumber: value.containerWidth
          ? parseInt(value.containerWidth.split('px')[0], 10) - 100
          : this.viewData.containerWidthNumber,
        levelWidthNumber: value.levelWidth
          ? parseInt(value.levelWidth.split('px')[0], 10)
          : this.viewData.levelWidthNumber,
        maxNestingLevel: value.maxNestingLevel ?? this.viewData.maxNestingLevel,
        maxBusinessLevel: value.maxBusinessLevel ?? this.viewData.maxBusinessLevel,
        columnWidths: value.columnWidths ?? this.viewData.columnWidths,
        maxLevelReachedTranslation:
          value.maxLevelReachedTranslation ?? this.viewData.maxLevelReachedTranslation,
        emptyMsg: value.emptyMsg ?? this.viewData.emptyMsg,
      };
    }
  }

  @Output()
  recordMovedToDifferentContainer = new EventEmitter<DndOutputData<T, K>>();
  @Output()
  recordMovedInSameContainer = new EventEmitter<DndOutputMovedData<K>>();

  onDragDrop$ = new Subject<CdkDragDrop<T[]>>();
  data!: T[];
  baseId = 'BASE_' + generateUUID();

  viewData: DndViewData = {
    containerWidthNumber: defaultContainerWidth,
    levelWidthNumber: defaultLevelWidth,
    maxNestingLevel: defaultMaxNestingLevel,
    maxBusinessLevel: defaultMaxBusinessLevel,
    columnWidths: null,
    maxLevelReachedTranslation: '',
    emptyMsg: '',
  };
  styleData!: {
    width: string;
    'grid-template-columns': string;
    'grid-column-end': string;
  };

  private destroySubject$ = new Subject<void>();

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.onDragDrop$.pipe(takeUntil(this.destroySubject$)).subscribe(this.onDragDrop);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.data = cloneDeep(this.dataSource);
      this.setViewStyles();
    }
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  private onDragDrop = (event: CdkDragDrop<T[], T[], string>): void => {
    if (event.container === event.previousContainer) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      if (event.previousIndex !== event.currentIndex) {
        this.recordMovedInSameContainer.emit({
          containerName: this.containerName,
          previousIndex: event.previousIndex,
          currentIndex: event.currentIndex,
        });
      }
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

      const deepestLevel = findDeepestElementLevel<T>(this.data);
      const { maxNestingLevel, maxLevelReachedTranslation } = this.viewData;

      if (deepestLevel > maxNestingLevel) {
        transferArrayItem(
          event.container.data,
          event.previousContainer.data,
          event.currentIndex,
          event.previousIndex,
        );
        this.notificationService.warning(maxLevelReachedTranslation, { maxLevel: maxNestingLevel });
      } else {
        this.recordMovedToDifferentContainer.emit({
          data: this.data,
          itemId: event.item.data,
          containerName: this.containerName,
        });
      }
    }
    this.removeDraggingStyles();
  };

  private setViewStyles(): void {
    const deepestLevel = findDeepestElementLevel<T>(this.data);
    const gridContainerWidth = this.evaluateGridWidth(deepestLevel);
    const gridColumnsSetup = this.evaluateGridColumns(deepestLevel);
    const gridColumnsEnd = this.evaluateGridColumnsEnd();

    this.styleData = {
      width: gridContainerWidth,
      'grid-template-columns': gridColumnsSetup,
      'grid-column-end': gridColumnsEnd,
    };
  }

  private evaluateGridWidth(deepestLevel: number): string {
    const { maxBusinessLevel, containerWidthNumber, levelWidthNumber } = this.viewData;

    const additionalLevels = deepestLevel - maxBusinessLevel;
    return deepestLevel > maxBusinessLevel
      ? `${containerWidthNumber + additionalLevels * levelWidthNumber}px`
      : '100%';
  }

  private evaluateGridColumns(deepestLevel: number): string {
    const { maxBusinessLevel, columnWidths, levelWidthNumber } = this.viewData;

    const firstColumnWidth =
      deepestLevel > maxBusinessLevel
        ? (deepestLevel + 1) * levelWidthNumber
        : 3 * levelWidthNumber;

    if (!columnWidths) {
      return this.columnDefinitions.reduce(
        (prev, _) => (prev ? `${prev} 1fr` : `${firstColumnWidth}px`),
        '',
      );
    }

    return columnWidths.split(' ').reduce((prev, column) => {
      if (!prev) {
        return `${firstColumnWidth}px`;
      }
      return `${prev} ${column}`;
    }, '');
  }

  private evaluateGridColumnsEnd(): string {
    return `${this.columnDefinitions.length + 1}`;
  }

  private removeDraggingStyles(): void {
    const el = document.querySelector('.ngp-pre-dragging');
    el?.classList?.remove('ngp-pre-dragging');
  }
}
