export type EntityId<T = number | string> = T;

export interface HasEntityId<T = EntityId> {
  entityId: T;
}

export function isHasEntityId(value: Partial<HasEntityId>): value is HasEntityId {
  return (
    typeof (value as HasEntityId)?.entityId === 'string' ||
    typeof (value as HasEntityId)?.entityId === 'number'
  );
}
