import { Pipe, PipeTransform } from '@angular/core';

import { TransactionDataSource } from '@demica/core/core';

@Pipe({
  name: 'transactionDataSourceCast',
  pure: true,
})
export class TransactionDataSourceCastPipe implements PipeTransform {
  transform(value: unknown): TransactionDataSource {
    return <TransactionDataSource>value;
  }
}
