export class Key {
  isUp!: boolean;
  isDown!: boolean;
  isLeft!: boolean;
  isRight!: boolean;
  isEnter!: boolean;

  constructor(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'Up') this.isUp = true;
    if (event.key === 'ArrowDown' || event.key === 'Down') this.isDown = true;
    if (event.key === 'ArrowLeft' || event.key === 'Left') this.isLeft = true;
    if (event.key === 'ArrowRight' || event.key === 'Right') this.isRight = true;
    if (event.key === 'Enter') this.isEnter = true;
  }
}
