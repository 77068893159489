import { Observable, of } from 'rxjs';

import {
  EmptyPaginationMetadata,
  RestResponsePaginationMetadata,
} from '../../interface/pageable-data.interface';
import { RestResponseWithMetadata } from '../../interface/rest-response/rest-response.interface';

export function defaultEmptyResponse<T>(): Observable<
  RestResponseWithMetadata<T, RestResponsePaginationMetadata<EmptyPaginationMetadata>>
> {
  return of({
    data: [] as T[],
    meta: {
      pagination: {
        totalElements: 0,
      },
    },
  });
}
