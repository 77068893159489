import { Component, Input } from '@angular/core';

import { NotificationIconDefinition, NotificationType } from '../notification.types';

@Component({
  selector: 'ngp-inline-notification',
  templateUrl: './inline-notification.component.html',
  styleUrls: ['./inline-notification.component.sass'],
})
export class InlineNotificationComponent {
  @Input()
  message = '';

  @Input()
  type: Exclude<NotificationType, 'info'> = 'error';

  icons: NotificationIconDefinition = {
    error: 'notification-error',
    warning: 'notification-warning',
  };
}
