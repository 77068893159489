import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { TabsService } from '../tabs.service';

@Component({
  selector: 'ngp-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.sass'],
})
export class TabComponent implements OnChanges, AfterViewInit {
  @Input()
  title!: string;
  @Input()
  titleAttr!: string;
  @Input()
  hidden!: boolean;
  @Input()
  isActive!: boolean;
  @Input()
  tabId: unknown;
  @Input()
  titleSuffix!: string;
  @Input()
  tooltipContent!: string;
  @Input()
  contentTemplate!: TemplateRef<unknown>;
  @Input()
  enabled = true;
  @Input()
  disabled!: boolean;
  @Input()
  tooltipTestId!: string;
  @Input()
  iconAriaDesc!: string;
  @Input()
  testId!: string;
  @Input()
  headerClasses = '';
  @Input()
  error = false;
  @Output()
  selected = new EventEmitter<void>();
  @ViewChild('componentView')
  view?: TemplateRef<unknown>;

  constructor(private tabsService: TabsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isActive?.currentValue) {
      this.tabsService.markAsActive(this);
    }
  }

  ngAfterViewInit() {
    if (this.isActive) {
      this.tabsService.markAsActive(this);
    }
  }
}
