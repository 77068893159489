import { isKey } from '../utils/object';
import { AnalyticsCategories } from './enums/analytics-categories.enum';
import { AnalyticsPages } from './enums/analytics-pages.enum';

const ROUTES_MAP = {
  dashboard: {
    category: AnalyticsCategories.FINANCE,
    pageName: AnalyticsPages.OVERVIEW,
  },
  'data-uploads': {
    category: AnalyticsCategories.OPERATIONS,
    pageName: AnalyticsPages.DATA_UPLOADS,
  },
  alerts: {
    category: AnalyticsCategories.OPERATIONS,
    pageName: AnalyticsPages.MESSAGES_AND_ALERTS,
  },
  clients: {
    category: AnalyticsCategories.ADMINISTRATION,
    pageName: AnalyticsPages.CLIENTS,
  },
  'funder-groups': {
    category: AnalyticsCategories.ADMINISTRATION,
    pageName: AnalyticsPages.FUNDERS,
  },
  transactions: {
    category: AnalyticsCategories.ADMINISTRATION,
    pageName: AnalyticsPages.TRANSACTIONS,
  },
  templates: {
    category: AnalyticsCategories.ADMINISTRATION,
    pageName: AnalyticsPages.TEMPLATES,
  },
  'current-region': {
    category: AnalyticsCategories.ADMINISTRATION,
    pageName: AnalyticsPages.CURRENT_REGION,
  },
  administration: {
    category: AnalyticsCategories.ADMINISTRATION,
    pageName: AnalyticsPages.ADMINISTRATION,
  },
  settings: {
    category: AnalyticsCategories.ADMINISTRATION,
    pageName: AnalyticsPages.SETTINGS,
  },
};

const UNKNOWN_PAGE_PARAMS = {
  category: 'unknown category',
  pageName: 'unknown page',
};

export function mapRouteToPageParams(route: string | keyof typeof ROUTES_MAP) {
  return isKey(ROUTES_MAP, route) ? ROUTES_MAP[route] : UNKNOWN_PAGE_PARAMS;
}
