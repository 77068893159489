import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { LoggerService } from '@demica/logger';

import { BroadcastChannelService } from '../../broadcast-channel/broadcast-channel.service';

import { BroadcastMessage, BroadcastMessageType } from '../../../model/broadcast-message.interface';
import { LOGOUT_REASONS } from '../../auth/logout-redirect/logout-reasons.enum';
import { BroadcastAutowiredClient } from '../../broadcast-channel/broadcast-channel.types';

export type LogoutMessage = {
  reason: LOGOUT_REASONS;
};
@Injectable({
  providedIn: 'root',
})
export class LogoutBroadcastClientService implements BroadcastAutowiredClient {
  // client name
  readonly name = 'LOGOUT';
  // logout events streams
  logout$: Observable<BroadcastMessage<LogoutMessage>>;
  private _logout$ = new Subject<BroadcastMessage<LogoutMessage>>();

  constructor(
    private _broadcastChannelService: BroadcastChannelService,
    private _logger: LoggerService,
  ) {
    this.logout$ = this._logout$.asObservable();
  }

  autowire(): void {
    this._logger.addConsoleLog('Autowiring InactivityLogoutBroadcastClientService');
    this._initLogoutBroadcastMessageListener();
  }

  broadcastLogout(reason?: LOGOUT_REASONS): void {
    this._broadcastChannelService.broadcastMessage(this._getLogoutMessage({ reason }));
  }

  private _initLogoutBroadcastMessageListener(): void {
    this._broadcastChannelService
      .getMessagesOfType$<LogoutMessage>(BroadcastMessageType.Logout)
      .pipe(first())
      .subscribe(this._logout$);
  }

  private _getLogoutMessage(data?: LogoutMessage): BroadcastMessage<LogoutMessage> {
    return this._broadcastChannelService.generateMessage(BroadcastMessageType.Logout, data);
  }
}
