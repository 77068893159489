import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import {
  AppConfigService,
  Locale,
  LocalisationService,
  UserAccountResourceService,
  UserPreferences,
} from '@demica/core/core';

@Component({
  selector: 'trf-localisations-dropdown',
  styleUrls: ['./localisations-dropdown.component.sass'],
  templateUrl: 'localisations-dropdown.component.html',
})
export class LocalisationsDropdownComponent implements OnDestroy {
  dropdownVisible = false;

  selectedLocalisation: Locale;
  availableLocales: Locale[];
  loading = true;
  subscriptions: Subscription[] = [];
  @ViewChild('dropdownButton')
  dropdownButton: ElementRef;
  @ViewChild('ulElement')
  ulElement: ElementRef;

  constructor(
    private config: AppConfigService,
    private userAccountService: UserAccountResourceService,
    private localisationService: LocalisationService,
    private userPreferences: UserPreferences,
  ) {
    this.subscriptions.push(
      this.localisationService.locale.subscribe((locale) => (this.selectedLocalisation = locale)),
    );
    this.config
      .getSupportedLocalisations$()
      .subscribe((locales) => (this.availableLocales = locales));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  setLocale(localeCode: Locale) {
    this.selectedLocalisation = localeCode;
    this.userAccountService.putMyAccountLocale(localeCode).subscribe(() => {
      this.userPreferences.changeLocale(localeCode);
    });
    this.dropdownVisible = !this.dropdownVisible;
    this.dropdownButton.nativeElement.focus();
  }

  flagCss(localeCode: string): string {
    const [, country] = localeCode.split('-');
    return `flag-icon-${country.toLowerCase()}`;
  }

  selectedLocalisationCode(): string {
    return this.selectedLocalisation;
  }

  closeDropdown(): void {
    this.dropdownVisible = false;
    this.dropdownButton.nativeElement.focus();
  }

  focusOnFirstElement() {
    this.ulElement.nativeElement.children[0].focus();
  }

  focusOnLastElement() {
    this.ulElement.nativeElement.children[this.availableLocales.length - 1].focus();
  }
}
