import { Component, Input } from '@angular/core';

import { SelectItem } from '../form-tree-select-row/tree-select.converter';

@Component({
  selector: 'trf-tree-select-option',
  templateUrl: './tree-select-option.component.html',
  styleUrls: ['./tree-select-option.component.sass'],
})
export class TreeSelectOptionComponent<T> {
  @Input()
  option: SelectItem<T>;
  @Input()
  indentBase = 15;
  @Input()
  selected: boolean;
}
