import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { TranslatableListConfig } from './translatable-list.model';

type TranslationItem = {
  key: string;
  fullKey: string;
};

@Component({
  selector: 'ngp-translatable-list',
  templateUrl: './translatable-list.component.html',
  styleUrls: ['./translatable-list.component.sass'],
})
export class TranslatableListComponent implements OnInit, OnDestroy {
  private _translationPath = '';
  @Input()
  set translationPath(translationPath: string) {
    this._translationPath = translationPath;
    this._translationPath$.next(translationPath);
  }
  get translationPath() {
    return this._translationPath;
  }
  @Input()
  config: TranslatableListConfig = null;

  translationKeys$ = new ReplaySubject<TranslationItem[]>();

  private _translationPath$ = new ReplaySubject<string>();
  private _destroyed$ = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this._translationPath$
      .pipe(
        switchMap((translationPath) => {
          return this._getTranslationKeys(translationPath);
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe(this.translationKeys$);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _getTranslationKeys(translationPath: string): Observable<TranslationItem[]> {
    if (!translationPath) {
      return of([]);
    }
    return this.translateService.get(translationPath).pipe(
      map((translationObject) => {
        if (translationObject === translationPath) {
          return [];
        }

        return Object.keys(translationObject)
          .filter((key) => typeof translationObject[key] === 'string')
          .filter((key) => !!translationObject[key])
          .map((key) => ({
            key,
            fullKey: [translationPath, key].join('.'),
          }));
      }),
      catchError((error) => {
        console.error(error);
        return of([]);
      }),
    );
  }
}
