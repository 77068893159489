/** Endpoints that are continuously pooled for data */
export const poolingUrls = [
  '/api/trf/v1/resources/dashboards/filters/available',
  '/api/trf/v1/resources/dashboards/funding-alerts-count',
  '/api/trf/v1/resources/dashboards/input-files-status',
  '/api/trf/v1/resources/alerts/unread-notifications-count',
  '/api/trf/v1/resources/alerts/unread-actions-count',
  '/api/trf/v1/resources/dashboards/missing-files-status',
  '/api/trf/v1/resources/analytics',
];
