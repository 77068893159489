import { Inject, Injectable } from '@angular/core';

import { of, Subject } from 'rxjs';
import { catchError, concatAll, filter, finalize, mergeMap, switchMap, take } from 'rxjs/operators';

import { AnalyticsRequest, AnalyticsResourceService } from '@demica/resources/analytics';

import { ANALYTICS_STORE, AnalyticsLocalStorageService } from './analytics-local-storage.service';
import { AnalyticsRequestsBufferService } from './analytics-requests-buffer.service';

import { removeDuplicate } from '../utils/collection';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsRemoteStorageService {
  analyticsRequestSent$ = new Subject<void>();

  constructor(
    private _analyticsResourceService: AnalyticsResourceService,
    private _buffer: AnalyticsRequestsBufferService,
    @Inject(ANALYTICS_STORE) private _analyticsLocalStorageService: AnalyticsLocalStorageService,
  ) {
    _buffer.onBufferData$
      .pipe(
        switchMap((payloads: AnalyticsRequest[]) =>
          this._makeRequest(this._packData(payloads)).pipe(
            finalize(() => {
              this.analyticsRequestSent$.next();
            }),
            filter(() => this._analyticsLocalStorageService.isAvailable()),
            mergeMap(() =>
              payloads.map((payload) => this._analyticsLocalStorageService.delete(payload.id)),
            ),
            concatAll(),
            catchError((err) => {
              // If fail we keep Analytic Request in IndexDB and try send it on next application start
              console.error(err);
              return of();
            }),
          ),
        ),
      )
      .subscribe();
  }

  record(payload: AnalyticsRequest) {
    if (payload.id) {
      return this._pushToBuffer(payload);
    } else {
      return this._persistPayload(payload).pipe(
        switchMap((persistedPayload) =>
          this._pushToBuffer({ ...payload, id: persistedPayload as string }),
        ),
      );
    }
  }

  persist() {
    if (!this._analyticsLocalStorageService.isAvailable()) {
      return;
    }

    this._analyticsLocalStorageService
      .getAll()
      .subscribe((analyticsRequests: AnalyticsRequest[]) =>
        analyticsRequests.forEach((payload: AnalyticsRequest) => this._buffer.push(payload, false)),
      );
  }

  sendAnalyticsBeacon(payload: AnalyticsRequest): boolean {
    return this._analyticsResourceService.sendAnalyticsBeacon(payload);
  }

  private _persistPayload(payload: AnalyticsRequest) {
    return this._analyticsLocalStorageService?.isAvailable()
      ? this._analyticsLocalStorageService.add(payload)
      : of(null);
  }

  private _pushToBuffer(payload: AnalyticsRequest) {
    this._buffer.push(payload);

    return this.analyticsRequestSent$.pipe(take(1));
  }

  private _makeRequest(payload: AnalyticsRequest) {
    return this._analyticsResourceService.persistAnalyticsEvents$(payload);
  }

  private _packData(payloadsToSend: AnalyticsRequest[]): AnalyticsRequest {
    const payloads = removeDuplicate(payloadsToSend, (pl) => pl.id);

    return {
      ...payloads[0],
      events: payloads.flatMap((payload) =>
        payload.events.reduce((prev, curr) => [...prev, curr], []),
      ),
    };
  }
}
