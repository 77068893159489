import { CurrenciesDecimalPlacesService } from '@demica/core/core';

import { AxisYNumberFormat } from './axis-y-number-format';
import { BigNumber } from 'bignumber.js';
import { FormatLocaleDefinition } from 'd3-format';

export class AxisYFormatter {
  private readonly regex = new RegExp('^[0]$');
  private readonly suffixes = ['', 'K', 'M', 'B', 'T'];
  private readonly defaultDecimalPlaces = 2;
  private readonly numberFormat: AxisYNumberFormat = {
    decimalPlaces: this.defaultDecimalPlaces,
    decimalSeparator: '.',
  };

  constructor(
    private localeDefinition: FormatLocaleDefinition,
    private currenciesFormat: CurrenciesDecimalPlacesService,
  ) {
    this.numberFormat.decimalSeparator = localeDefinition.decimal;
    this.numberFormat.decimalPlaces = this.currenciesFormat.currencyDecimalPlaces(
      localeDefinition.currency[0],
    );
  }

  formatValue() {
    return (value: number) => {
      if (this.regex.test(String(value))) {
        return String(value);
      } else {
        return this.findResult(value, 1, 1000, this.suffixes, 0);
      }
    };
  }

  private findResult(
    value: number,
    divider: number,
    cond: number,
    suffixes: string[],
    index: number,
  ): string {
    if (Math.abs(value) >= cond) {
      return this.findResult(value, divider * 1000, cond * 1000, suffixes, ++index);
    }

    const result = value / divider;

    return new BigNumber(result).toFormat(
      value < 1000 ? this.numberFormat.decimalPlaces : this.defaultDecimalPlaces,
      {
        decimalSeparator: this.numberFormat.decimalSeparator,
        suffix: suffixes[index],
      },
    );
  }
}
