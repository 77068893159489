import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ValidationMessage } from '../forms/validation-messages/validation-message.interface';

@Pipe({
  name: 'firstError',
  pure: false,
})
export class FirstErrorPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(messages: ValidationMessage[], name: string | string[]): string {
    if (!messages) return;
    if (!name) throw new Error('Validation name is not specified.');

    if (!(name instanceof Array)) name = [name];

    const invalidValidation = this.findFailingValidationMessage(name, messages);
    if (invalidValidation)
      return this.translate.instant(invalidValidation.key, invalidValidation.params);

    return '';
  }

  private findFailingValidationMessage(names: string[], messages: ValidationMessage[]) {
    let invalidValidation: ValidationMessage;

    for (const name of names) {
      const invalid = messages.find((validation) => validation.func(name));
      if (invalid) {
        invalidValidation = invalid;
        break;
      }
    }
    return invalidValidation;
  }
}
