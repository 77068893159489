import { Component, Input, OnInit } from '@angular/core';

import { FormNumberRowComponent } from '../form-number-row/form-number-row.component';

import { DecimalValuePipe } from '../../pipe/decimal-value.pipe';

import { DecimalFormatterDirective } from '../../directive/decimal-formatter.directive';
import { filterKeyForDecimalsOnly } from '../../forms/form';
import { FormRowClass } from '../../forms/model/form-row.class';
import {
  decimalValidator,
  integerValidator,
  MAX_DECIMAL_PLACES_INT,
  MAX_INTEGER_PLACES_INT,
} from '../../forms/number-validators';
import { DecimalRegexBuilder } from './regex/decimal-regex.builder';

/**
 * Validations: please use decimalValidator, integerValidator instead of formatValidator for better UX
 */
@Component({
  selector: 'trf-form-decimal-row',
  templateUrl: './form-decimal-row.component.html',
  styleUrls: ['./form-decimal-row.component.sass'],
  providers: [
    DecimalValuePipe,
    DecimalFormatterDirective,
    { provide: FormRowClass, useExisting: FormDecimalRowComponent },
  ],
})
export class FormDecimalRowComponent extends FormNumberRowComponent implements OnInit {
  @Input()
  disable? = false;

  @Input()
  removeTrailingZeros = false;
  @Input()
  helperToolTip = '';
  @Input()
  formValueAsNumber = false;
  protected customMaxIntegerPlacesInt = MAX_INTEGER_PLACES_INT;
  protected customMaxDecimalPlacesInt = MAX_DECIMAL_PLACES_INT;

  @Input()
  set integerPlaces(integerPlaces: number) {
    this.setUpIntegerPlaces(integerPlaces);
  }

  @Input()
  set decimalPlaces(decimalPlaces: number) {
    this.setUpDecimalPlaces(decimalPlaces);
  }

  ngOnInit(): void {
    this._updateRegexp();
    this.setUpIntegerPlaces();
    this.setUpDecimalPlaces();
    super.initialize();
  }

  onKeypress(event: KeyboardEvent): boolean {
    return filterKeyForDecimalsOnly(event.key, this.acceptNegatives);
  }

  onBlur(): void {
    if (this.formControl.value?.toString().match(new RegExp('^-*$'))) {
      this.formControl.setValue(null);
    }
  }

  /** See Decimal Formatter Directive for details. */
  onDecimalFormatted({
    value,
    isNotValidLocalisedNumber,
  }: {
    value: string;
    isNotValidLocalisedNumber: boolean;
  }): void {
    this.isNotValidLocalisedNumber = isNotValidLocalisedNumber;
    this._updateModelValue(value, isNotValidLocalisedNumber);
  }

  setUpDecimalPlaces(decimalPlaces?: number): void {
    if (decimalPlaces !== undefined) {
      this.customMaxDecimalPlacesInt = Math.min(decimalPlaces, MAX_DECIMAL_PLACES_INT);
    }
    this.validatorsMap.set('decimalValidator', [decimalValidator(this.customMaxDecimalPlacesInt)]);
    if (this.formControl) {
      this.setValidators();
    }
    this._updateRegexp();
  }

  setUpIntegerPlaces(integerPlaces?: number): void {
    if (integerPlaces !== undefined) {
      this.customMaxIntegerPlacesInt = Math.min(integerPlaces, MAX_INTEGER_PLACES_INT);
    }
    this.validatorsMap.set('integerValidator', [integerValidator(this.customMaxIntegerPlacesInt)]);
    if (this.formControl) {
      this.setValidators();
    }
    this._updateRegexp();
  }

  private _updateModelValue(value: string, isNotValidLocalisedNumber: boolean): void {
    const isNotEmpty = value !== '';
    const isValidNumber = !isNotValidLocalisedNumber && isNotEmpty;
    const setModelValue = (value: string | number) =>
      this.formControl.setValue(value, { emitModelToViewChange: false });

    if (!this.formValueAsNumber) {
      setModelValue(value);
      return;
    }
    setModelValue(isValidNumber ? +value : null);
  }

  private _updateRegexp(): void {
    this.acceptRegex = new DecimalRegexBuilder()
      .setAcceptNegatives(this.acceptNegatives)
      .setIntegerPlacesInt(this.customMaxIntegerPlacesInt)
      .setDecimalPlacesInt(this.customMaxDecimalPlacesInt)
      .build();

    this.formControl?.updateValueAndValidity();
  }
}
