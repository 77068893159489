import { CdkTreeModule } from '@angular/cdk/tree';
import { NgModule } from '@angular/core';

import { TreeChecklistComponent } from './components/tree-checklist-component/tree-checklist.component';

import { TreeChecklistExpandTemplateDirective } from './tree-checklist-templates/tree-checklist-expand-template.directive';
import { TreeChecklistItemContentDirective } from './tree-checklist-templates/tree-checklist-item-content.directive';
import { TreeChecklistSelectTemplateDirective } from './tree-checklist-templates/tree-checklist-select-template.directive';

const declarations = [
  TreeChecklistComponent,
  TreeChecklistItemContentDirective,
  TreeChecklistExpandTemplateDirective,
  TreeChecklistSelectTemplateDirective,
  CdkTreeModule,
];

@NgModule({
  imports: declarations,
  exports: declarations,
})
export class TreeChecklistModule {}
