import { Injectable, Injector } from '@angular/core';

import { AnalyticsClientType } from '@demica/resources/app-config';

import { AnalyticsClient } from './abstract-analytics-client.class';
import { ANALYTICS_CLIENT_PROVIDERS } from './client-providers.const';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsClientsInitializerService {
  clients: AnalyticsClient[] = [];

  constructor(private injector: Injector) {}

  initClients(activeClients: AnalyticsClientType[]) {
    if (Array.isArray(activeClients)) {
      activeClients.forEach((selectedClient) => {
        this.clients.push(this.injector.get(ANALYTICS_CLIENT_PROVIDERS[selectedClient]));
      });
    }
  }

  each(cb: (client: AnalyticsClient) => unknown) {
    this.clients.forEach((c) => cb(c));
  }

  hasActiveClient() {
    return !!this.clients.length;
  }
}
