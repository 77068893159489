import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { generateUUID } from '../../../utils/uuidGenerator';

@Injectable({ providedIn: 'root' })
export class RuntimeTranslationsKeysService implements OnDestroy {
  translationService: TranslateService;
  destroyed$ = new Subject<void>();
  refreshTranslationsActions$ = new Subject<void>();
  translations = new Map<string | number, string>();
  constructor() {
    this.refreshTranslationsActions$
      .pipe(debounceTime(100), takeUntil(this.destroyed$))
      .subscribe(() => {
        // To force reload of translation in pipes we need to add a new key to the select language inner store.
        // UUID is used here because overriding key didn't work
        this.translationService.set(generateUUID(), '_IGNORE');
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setKey(translationKey: string, translationValue: string) {
    this.translations.set(translationKey, translationValue);
    this.refreshTranslationsActions$.next();
  }
  get(translationKey: string) {
    return this.translations.get(translationKey);
  }
  has(translationKey: string) {
    return this.translations.has(translationKey);
  }
  setTranslateService(translateService: TranslateService) {
    this.translationService = translateService;
  }
  refreshData() {
    this.translations.set('RefreshKey', 'newRefresh');
  }
}
