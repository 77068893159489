import { AbstractControl } from '@angular/forms';

import {
  DECIMAL_PLACES_EXCEEDED_ERROR_KEY,
  INTEGER_PLACES_EXCEEDED_ERROR_KEY,
} from '../../component/form-number-row/form-number-row-error-keys';
import { SelectControlError } from '../../component/form-select-row/form-select-row.component';

import { FormGroupOrProvider, SubmittedProvider } from './message-typedefs';

/** A higher order function that takes in form and submitted status providers and returns a
 predicate function that taking a field name returns if a validation message should be displayed for the given field
 */
export type ValidationPredicateFactory = (
  formOrProvider: FormGroupOrProvider,
  submittedFn: SubmittedProvider,
) => (fieldName: string) => boolean;

export const hasError =
  (error: string, errorMatcher = defaultErrorMatcher) =>
  (formOrProvider: FormGroupOrProvider, submittedFn: SubmittedProvider) =>
  (fieldName: string) => {
    const formControl = getFormControl(formOrProvider, fieldName);
    return errorMatcher(error, formControl, submittedFn);
  };

const getFormControl = (
  formOrProvider: FormGroupOrProvider,
  fieldName: string,
): AbstractControl => {
  const form = typeof formOrProvider === 'function' ? formOrProvider() : formOrProvider;

  const formControl = form.get(fieldName);
  if (!formControl) console.warn(`Couldn't find control with name "${fieldName}" in `, form);
  return formControl;
};

type ErrorMatcher = (
  error: string,
  formControl: AbstractControl,
  submittedFn: SubmittedProvider,
) => boolean;
export const defaultErrorMatcher: ErrorMatcher = (
  error: string,
  formControl: AbstractControl,
  submittedFn: SubmittedProvider,
) => {
  return (
    (formControl.hasError(error) && formControl.touched) ||
    (submittedFn() && formControl.hasError(error))
  );
};

export const instantErrorMatcher: ErrorMatcher = (error: string, formControl: AbstractControl) => {
  return formControl.hasError(error);
};

export const END_DATE_BEFORE_START_DATE_ERROR = 'endDateBeforeStartDate';

/* Validation message predicates */
export const hasRequiredError = hasError('required');
export const hasNgbDateError = hasError('ngbDate');
export const hasAtLeastOneCheckError = hasError('atLeastOneCheck');
export const hasMaxLengthError = hasError('maxlength');
export const hasMinLengthError = hasError('minlength');
export const hasEmailError = hasError('email');
export const hasNameUnavailableError = hasError('name-unavailable');
export const hasDateError = hasError('date');
export const hasDateFormatError = hasError('format');
export const hasRequireNumberError = hasError('number');
export const hasMinValueError = hasError('min');
export const hasMaxValueError = hasError('max');
export const hasDecimalPlacesExceededError = hasError(DECIMAL_PLACES_EXCEEDED_ERROR_KEY);
export const hasIntegerPlacesExceededError = hasError(INTEGER_PLACES_EXCEEDED_ERROR_KEY);
export const hasTooBigValueError = hasError('tooBig');
export const hasTooSmallValueError = hasError('tooSmall');
export const hasCheckedError = hasError('checked');
export const hasDateTimeFormatError = hasError('dateTimeFormat');
export const hasLogoFileToBigError = hasError('fileTooBig');
export const hasLogoFileWrongFormatError = hasError('wrongFileFormat');
export const hasValueInOtherRange = hasError('inRange');
export const hasValueCoverOtherRange = hasError('coverRange');
export const hasEqualRangeValues = hasError('equalRange');
export const hasGreaterThanError = hasError('notGreaterThan');
export const hasMaxAvailableValueError = hasError('maxAvailableValue');
export const hasEndDateBeforeStart = hasError('endDateBeforeStartDate');
export const hasDisabledOptionSelected = hasError(SelectControlError.DISABLED_OPTION_SELECTED);
