import { UntypedFormGroup } from '@angular/forms';

import { DictionaryEntry } from '../../../../model/dictionary-entry.interface';
import { LiabilityCalculationFeature } from '../model/liability-calculation-feature';
import { liabilityFormControlName } from '../model/liability-form-control-name';
import { TransactionUpsertRequest } from '../model/transaction-upsert-request';

export function updateRequestByLICEFeature(
  form: UntypedFormGroup,
  request: TransactionUpsertRequest,
  features: DictionaryEntry[],
) {
  const liceFeatures: LiabilityCalculationFeature[] = [];

  features.forEach((feature) => {
    liceFeatures.push({
      entityReference: {
        entityId: feature.entityId,
        name: feature.key,
      },
      // TODO UntypedFormGroup
      value: form.get(liabilityFormControlName[feature.key as any]).value,
    });
  });

  request.liabilityCalculationFeatures = liceFeatures;
}
