import { Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { OneTrustService } from '../one-trust/one-trust.service';

export function cookieConsentFactory(
  oneTrustService: OneTrustService,
  authService: AuthService,
): () => Subscription {
  return () =>
    authService.loggedIn$
      .pipe(
        filter(Boolean),
        take(1),
        tap(() => oneTrustService.loadScript()),
      )
      .subscribe();
}

export function publicAppCookieConsentFactory(oneTrustService: OneTrustService): () => void {
  return () => oneTrustService.loadScript();
}
