import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { NotificationService, NotificationTypes } from '@demica/core/core';
import { BodyOutputType, ToasterService, ToastType } from '@demica/toaster';

import { NotificationComponent } from '../../component/notification/notification.component';

import { NotificationHandler, Notification } from './notification-handler.interface';

@Injectable()
export class ToastrNotificationHandler implements NotificationHandler {
  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {}

  onNotification(notification: Notification): void {
    {
      const { messageCode, messageParams, type } = notification;
      const translatedMsg = this.translateService.instant(messageCode, messageParams);
      let title: string;
      switch (type) {
        case NotificationTypes.error: {
          title = this.translateService.instant('NOTIFICATION.TYPE.ERROR');
          if (translatedMsg === messageCode) {
            title = this.translateService.instant('NOTIFICATION.TYPE.TERRIBLY_WRONG');
          }
          break;
        }
        case NotificationTypes.warning: {
          title = this.translateService.instant('NOTIFICATION.TYPE.WARNING');
          break;
        }
        case NotificationTypes.success: {
          title = this.translateService.instant('NOTIFICATION.TYPE.SUCCESS');
          break;
        }
      }
      this.notify(type, title, translatedMsg, messageCode);
    }
  }

  onClearAll(): void {
    this.toasterService.clear();
  }

  private notify(type: ToastType, title: string, message: string, messageCode: string) {
    this.toasterService.pop({
      body: NotificationComponent,
      bodyOutputType: BodyOutputType.Component,
      data: { message, type, messageCode },
      title,
      type,
      onHideCallback: (toast) => {
        this.notificationService.hideNotification(toast.data.messageCode, toast.data.type);
      },
    });
  }
}
