import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'trf-chart-legend-item',
  templateUrl: './chart-legend-item.component.html',
  styleUrls: ['./chart-legend-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLegendItemComponent {
  @Input()
  disabled: boolean;
  @Input()
  color1: string;
  @Input()
  color2: string;
  @Input()
  label: string;
  @Input()
  seriesName: string;
  @Output()
  clicked = new EventEmitter<string>();

  onClick() {
    this.clicked.emit(this.seriesName);
  }

  background() {
    return `linear-gradient(  ${this.color1} 50%, ${this.color2} 50% )`;
  }
}
