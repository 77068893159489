import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isInsideIFrame } from '../utils/window';

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isInsideIFrame()) return next.handle(req);

    return next.handle(req).pipe(map((x) => x));
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function okResponse(url: string, body: unknown): Observable<HttpResponse<unknown>> {
  const resp = new HttpResponse({
    headers: defaultHeaders(),
    status: 200,
    statusText: 'OK',
    url,
    body,
  });

  return of(resp);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createdResponse(url: string, body: unknown): Observable<HttpResponse<unknown>> {
  const resp = new HttpResponse({
    headers: defaultHeaders(),
    status: 201,
    statusText: 'Created',
    url,
    body,
  });

  return of(resp);
}

function defaultHeaders() {
  return new HttpHeaders({
    'content-type': 'application/json;charset=UTF-8',
  });
}
