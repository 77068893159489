import { Injectable } from '@angular/core';

import { NotificationService } from '../notification.service';

import { AdditionalInformationError, HttpError } from '../../interface/http-context.interface';

@Injectable({ providedIn: 'root' })
export class HttpErrorMessageService {
  constructor(private notificationService: NotificationService) {}

  showHttpErrorMessage(
    errors: HttpError[],
    customHandler?: (val: AdditionalInformationError, err: HttpError) => Record<string, string>,
  ): void {
    if (!errors?.length) {
      this.notificationService.notify('DEFAULT');
      return;
    }

    errors.forEach((e) => {
      if (e.additionalInformationErrors?.length > 0) {
        e.additionalInformationErrors.forEach((aie) => {
          const info = customHandler ? customHandler(aie, e) : aie.informationError;
          this.notificationService.notify(e.errorCode, info);
        });
      } else {
        this.notificationService.notify(e.errorCode);
      }
    });
  }
}
