import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { apiUrl } from '../../config/api.config';
import { RestResponse } from '../../model/response.interface';
import { SubjectValidity } from '../../model/subject-validity.interface';

@Injectable()
export class SubjectValidityService {
  constructor(private http: HttpClient) {}

  checkSubjectValidity(subjectTypeId: number, entityId: number, entityRevision: number) {
    const params = new HttpParams()
      .set('subjectTypeId', String(subjectTypeId))
      .set('subjectId', String(entityId))
      .set('subjectRevision', String(entityRevision));

    return this.http
      .get(`${apiUrl}/resources/subject-validity`, { params: params })
      .pipe(map((r: RestResponse<SubjectValidity>) => r.data.validationErrors));
  }
}
