export const A11Y_MAIN_CONTAINER = 'a11y-keyup-container';
export const A11Y_HEADER_CONTAINER = 'a11y-keyup-header-container';
export const A11Y_ROW_CONTAINER = 'a11y-keyup-row-container';
export const A11Y_BODY_CONTAINER = 'a11y-keyup-body-container';
export const A11Y_INDEX = 'data-index';

export const A11Y_HEADER_CONTAINER_ATTR = '[a11y-keyup-header-container]';
export const A11Y_ROW_CONTAINER_ATTR = '[a11y-keyup-row-container]';
export const A11Y_BODY_CONTAINER_ATTR = '[a11y-keyup-body-container]';
export const A11Y_INDEX_ATTR = '[data-index]';

export const A11Y_ICON_TAG = 'ngp-svg-icon';
