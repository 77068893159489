import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoggerService } from '@demica/logger';

import { BroadcastChannelService } from '../../broadcast-channel/broadcast-channel.service';

import { BroadcastMessage, BroadcastMessageType } from '../../../model/broadcast-message.interface';
import { BroadcastAutowiredClient } from '../../broadcast-channel/broadcast-channel.types';
import { KeepAliveEvent, KeepAliveEventType } from '../keep-alive-session.types';

export interface KeepAliveMessage {
  lastActivity: number;
  type: KeepAliveEventType;
}

@Injectable({
  providedIn: 'root',
})
export class KeepAliveBroadcastClientService implements BroadcastAutowiredClient {
  // client name
  readonly name = 'KEEP_ALIVE';
  // keep alive messages stream
  userActivities$: Observable<KeepAliveMessage>;
  private _userActivities$ = new Subject<KeepAliveMessage>();

  constructor(
    private _broadcastChannelService: BroadcastChannelService,
    private _logger: LoggerService,
  ) {
    this.userActivities$ = this._userActivities$.asObservable();
  }

  autowire(): void {
    this._logger.addConsoleLog('Autowiring UserActivityBroadcastClientService');
    this._initUserActivityBroadcastMessageListener();
  }

  broadcastKeepAliveEvent(event: KeepAliveEvent): boolean {
    if (!event.broadcast) {
      return false;
    }

    // Construct the message
    const message = this._getKeepAliveMessage(event.type);

    // send the message
    this._broadcastChannelService.broadcastMessage(message);

    return true;
  }

  private _initUserActivityBroadcastMessageListener(): void {
    this._broadcastChannelService
      .getMessagesOfType$<KeepAliveMessage>(BroadcastMessageType.KeepAlive)
      .pipe(map((message) => message.data))
      .subscribe((message) => this._userActivities$.next(message));
  }

  private _getKeepAliveMessage(type: KeepAliveEventType): BroadcastMessage<KeepAliveMessage> {
    return this._broadcastChannelService.generateMessage<KeepAliveMessage>(
      BroadcastMessageType.KeepAlive,
      {
        lastActivity: Date.now(),
        type,
      },
    );
  }
}
