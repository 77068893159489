import { CurrenciesDecimalPlacesService } from '@demica/core/core';

import { AxisY } from './axis-y';
import { AxisYFormatter } from './axis-y-formatter';
import { YAxisConfiguration } from 'c3';
import { FormatLocaleDefinition } from 'd3-format';

export class AxisYSiNumbers extends AxisY {
  constructor(
    private localeDefinition: FormatLocaleDefinition,
    private currenciesFormat: CurrenciesDecimalPlacesService,
    label: string,
  ) {
    super(label);
  }

  getAxisYConfiguration() {
    const axisFormatter = new AxisYFormatter(this.localeDefinition, this.currenciesFormat);
    return {
      max: this.max,
      show: this.show,
      tick: {
        format(x: number): string {
          return axisFormatter.formatValue()(x);
        },
      },
      label: {
        position: 'outer-middle',
        text: `${this.label} [${this.localeDefinition.currency[0]}]`,
      },
    } as YAxisConfiguration;
  }
}
