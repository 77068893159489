<div
  class="option-wrapper"
  [ngClass]="{ isParent: option.hasChildren, selected: selected }"
>
  <div
    class="space"
    [style.flexBasis.px]="indentBase * option.level"
  ></div>
  <div class="name">{{ option.label | translate }}</div>
</div>
