<cdk-tree
  *ngIf="configuration"
  [dataSource]="dataSource"
  [treeControl]="treeControl"
>
  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node"
    class="tree-node"
  >
    <div class="tree-item-row hidden">
      <span
        class="expand-section"
        data-expand-hidden
      >
        <ng-container
          [ngTemplateOutlet]="checklistExpandTemplate"
          [ngTemplateOutletContext]="{
            $implicit: node,
            onExpandToggle: onExpandToggle(node),
            expanded: treeControl.isExpanded(node),
            isLeaf: true
          }"
        ></ng-container>
      </span>
      <span class="select-section">
        <ng-container
          [ngTemplateOutlet]="checklistSelectTemplate"
          [ngTemplateOutletContext]="{
            $implicit: node,
            selectionState: treeChecklistControl.getSelectionState(node),
            onSelectToggle: onSelectionToggle(node),
            isLeaf: true
          }"
        ></ng-container>
      </span>
      <span class="content-section">
        <ng-container
          [ngTemplateOutlet]="checklistItemContentTemplate"
          [ngTemplateOutletContext]="{ $implicit: node, isLeaf: true }"
        ></ng-container>
      </span>
    </div>
  </cdk-nested-tree-node>

  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node; when: hasChild"
    class="tree-node"
  >
    <div class="tree-item-row">
      <span
        class="expand-section"
        data-expand-visible
      >
        <ng-container
          [ngTemplateOutlet]="checklistExpandTemplate"
          [ngTemplateOutletContext]="{
            $implicit: node,
            onExpandToggle: onExpandToggle(node),
            expanded: treeControl.isExpanded(node)
          }"
        ></ng-container>
      </span>
      <span class="select-section">
        <ng-container
          [ngTemplateOutlet]="checklistSelectTemplate"
          [ngTemplateOutletContext]="{
            $implicit: node,
            selectionState: treeChecklistControl.getSelectionState(node),
            onSelectToggle: onSelectionToggle(node)
          }"
        ></ng-container>
      </span>
      <span class="content-section">
        <ng-container
          [ngTemplateOutlet]="checklistItemContentTemplate"
          [ngTemplateOutletContext]="{ $implicit: node }"
        ></ng-container>
      </span>
    </div>
    <div [@detailExpanded]="treeControl.isExpanded(node)">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>
