import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LogArea, LoggerService } from '@demica/logger';

import { AnalyticsService } from '../../analytics/analytics.service';
import {
  PASSWORD_CHANGE_SERVICE_TOKEN,
  PasswordChangeService,
} from '../../model/auth/password-change-service.interface';
import { LogoutBroadcastClientService } from '../broadcast-channel/logout-client/logout-broadcast-client.service';
import { AfterLogoutEventHandlerService } from './logout-redirect/after-logout-event-handler.service';
import { LogoutReasonStateService } from './logout-redirect/logout-reason-state.service';
import { LogoutRedirectUrlService } from './logout-redirect/logout-redirect-url.service';
import { LogoutService } from './logout-redirect/logout.service';

import { setIgnoreDiscardUnsavedChanges } from '../../utils/window';
import { LOGOUT_REASONS } from './logout-redirect/logout-reasons.enum';

@Injectable()
export class AuthService {
  private loggedInSubject = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedInSubject.asObservable();

  constructor(
    private _analytics: AnalyticsService,
    private _logoutService: LogoutService,
    private _logger: LoggerService,
    private _logoutRedirectUrlService: LogoutRedirectUrlService,
    private _logoutBroadcastClient: LogoutBroadcastClientService,
    private _logoutReasonStateService: LogoutReasonStateService,
    private _afterLogoutEventHandler: AfterLogoutEventHandlerService,
    @Inject(PASSWORD_CHANGE_SERVICE_TOKEN) private _passwordService: PasswordChangeService,
  ) {}

  setLoggedIn(): void {
    this._logger.addInstantLog({
      message: 'Successful authentication',
      area: LogArea.AUTH,
    });

    this.loggedInSubject.next(true);
  }

  signOut(reason?: LOGOUT_REASONS): void {
    setIgnoreDiscardUnsavedChanges();

    this._afterLogoutEventHandler.ignoreCustomLogoutRedirect();
    this._logoutBroadcastClient.broadcastLogout(reason);
    this._logoutReasonStateService.setLogoutReason(reason);

    this._internalSignOut(this._logoutRedirectUrlService.getRedirectUrl());
  }

  changePassword(): void {
    this._passwordService.changePassword();
  }

  private _internalSignOut(returnUrl: string): void {
    this._analytics
      .logoutEvent()
      .pipe(
        finalize(() => {
          this._logoutService.logout(returnUrl);
        }),
      )
      .subscribe();
  }
}
