import {
  ACTION_CREATE,
  ACTION_EDIT,
  ACTION_EXPORT,
  ACTION_PUSH,
  ACTION_REMOVE,
  Action,
} from '../model/actions.interface';

type RequiredDefaultAction = Partial<Action<unknown>> &
  Required<Pick<Action, 'actionId' | 'testId' | 'icon' | 'handler' | 'titleKey'>>;

type RequiredAction = Partial<Action<unknown>> & Required<Pick<Action, 'handler' | 'titleKey'>>;

function ngpDefaultAction({
  actionId,
  icon,
  testId,
  handler,
  titleKey,
  ...other
}: Partial<Action<unknown>> & RequiredDefaultAction): Action<unknown> {
  return {
    actionId,
    icon,
    testId,
    handler,
    titleKey,
    ...other,
  };
}

export function ngpPushAction({
  handler,
  titleKey,
  ...other
}: RequiredAction & Partial<Action<unknown>>): Action<unknown> {
  return ngpDefaultAction({
    actionId: ACTION_PUSH,
    icon: 'upload',
    testId: 'action-upload',
    handler,
    titleKey,
    ...other,
  });
}

export function ngpEditAction({
  handler,
  titleKey,
  ...other
}: RequiredAction & Partial<Action<unknown>>): Action<unknown> {
  return ngpDefaultAction({
    actionId: ACTION_EDIT,
    icon: 'pencil-alt',
    testId: 'action-edit',
    handler,
    titleKey,
    ...other,
  });
}

export function ngpCreateAction({
  handler,
  titleKey,
  ...other
}: RequiredAction & Partial<Action<unknown>>): Action<unknown> {
  return ngpDefaultAction({
    actionId: ACTION_CREATE,
    icon: 'file',
    testId: 'action-view-versions',
    handler,
    titleKey,
    ...other,
  });
}

export function ngpRemoveAction({
  handler,
  titleKey,
  ...other
}: RequiredAction & Partial<Action<unknown>>): Action<unknown> {
  return ngpDefaultAction({
    actionId: ACTION_REMOVE,
    icon: 'trash-alt',
    testId: 'action-delete',
    handler,
    titleKey,
    ...other,
  });
}

export function ngpExportAction({
  handler,
  titleKey,
  ...other
}: RequiredAction & Partial<Action<unknown>>): Action<unknown> {
  return ngpDefaultAction({
    actionId: ACTION_EXPORT,
    icon: 'file-export',
    testId: 'action-export',
    handler,
    titleKey,
    ...other,
  });
}
