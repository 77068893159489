import { NgModule } from '@angular/core';

import { NoAccessViewComponent } from './view/no-access-view.component';

@NgModule({
  exports: [NoAccessViewComponent],
  imports: [],
  declarations: [NoAccessViewComponent],
  providers: [],
})
export class NoAccessModule {}
