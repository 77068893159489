import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { apiUrl } from '../../config/api.config';
import { RelatedVersion } from '../../model/related-version.interface';
import { RestResponse } from '../../model/response.interface';

@Injectable()
export class PushRequestService {
  constructor(private http: HttpClient) {}

  getRelatedVersions(pushRequestId: number) {
    return this.http
      .get(`${apiUrl}/resources/push-requests/${pushRequestId}/related-versions`)
      .pipe(map((r: RestResponse<RelatedVersion[]>) => r.data));
  }

  approvePushRequest(pushRequestId: number, data: unknown) {
    return this.http.post(`${apiUrl}/resources/push-requests/${pushRequestId}/approval`, data);
  }
}
