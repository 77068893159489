import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { AuthUserContextChangeClient } from '../../../broadcast-channel/auth-user-context-change-client/auth-user-context-change-client.service';
import { UserService } from '../../../user.service';
import { UserDetailsComparator } from '../user-details-comparator.service';

import { UserAuthContext } from '../../../../model/auth/user-auth.context.interface';
import { UserAuthContextChangeReason } from '../user-auth-context-change.reason';
import { AuthContextChangeEventSourceInterface } from './auth-context-change-event-source.interface';

@Injectable({
  providedIn: 'root',
})
export class InternalChangeEventSourceService
  implements AuthContextChangeEventSourceInterface, OnDestroy
{
  private _internalAuthContextChange$ = new Subject<UserAuthContext>();
  private _destroyed$ = new Subject<void>();

  constructor(
    private _userService: UserService,
    private _userAuthContextComparator: UserDetailsComparator,
    private _authUserContextChangeClient: AuthUserContextChangeClient,
  ) {
    this._authUserContextChangeClient.userAuthContextChange$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(this._internalAuthContextChange$);
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  getEvents$(): Observable<UserAuthContextChangeReason> {
    return this._internalAuthContextChange$.pipe(
      withLatestFrom(this._userService.currentUser),
      switchMap(([userFromBroadcast, current]) =>
        this._userAuthContextComparator.getUsersContextDiff$(userFromBroadcast, current),
      ),
      filter((reason) => !!reason),
    );
  }
}
