import { Input, OnDestroy, OnInit, Directive } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PAGE_SIZE, PageLoaderService, PageNumberParams, PageService } from '@demica/core/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class PageNumberBaseComponent implements OnInit, OnDestroy {
  @Input() filterById?: string = null;

  pageNumberParams = {
    currentPage: 1,
    totalItems: 0,
    pageSize: PAGE_SIZE,
    totalPages: 0,
    startIndex: 0,
    endIndex: PAGE_SIZE,
    startPage: 0,
    endPage: 0,
    pages: [],
  } as PageNumberParams;

  disabled = false;

  private destroySubject = new Subject<void>();
  private total: number;

  protected constructor(
    protected pageLoaderService: PageLoaderService,
    protected pageService: PageService<unknown>,
  ) {}

  ngOnInit(): void {
    const pageLoaderObservable = this.filterById
      ? this.pageLoaderService.getLoaderObservableFilteredById(this.filterById)
      : this.pageLoaderService.getLoaderObservable();

    pageLoaderObservable
      .pipe(takeUntil(this.destroySubject))
      .subscribe((flag) => (this.disabled = flag));

    const pageParamsObservable = this.filterById
      ? this.pageService.getPageParamsObservableFilteredById(this.filterById)
      : this.pageService.getPageParamsObservable();

    pageParamsObservable.pipe(takeUntil(this.destroySubject)).subscribe((params) => {
      this.pageNumberParams = params;
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onPageClick(pageNumber: number) {
    this.pageService.resolvePage(pageNumber + 1, this.pageNumberParams.totalItems);
  }

  isCurrentPage(page: number) {
    return page === this.pageNumberParams.currentPage;
  }
}
