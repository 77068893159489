import { Injectable } from '@angular/core';

import { merge, Observable, Subject } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { HelloBroadcastClientService } from '../../../broadcast-channel/hello-broadcast-client/hello-broadcast-client.service';
import { KeepAliveBroadcastClientService } from '../../broadcast-client/keep-alive-broadcast-client.service';

import {
  KeepAliveEvent,
  KeepAliveEventsSource,
  KeepAliveEventType,
} from '../../keep-alive-session.types';

@Injectable({
  providedIn: 'root',
})
export class OtherInstancesEventsSourceService implements KeepAliveEventsSource {
  events$: Observable<KeepAliveEvent>;
  private _events$ = new Subject<KeepAliveEvent>();

  constructor(
    private _keepAliveBroadcastClient: KeepAliveBroadcastClientService,
    private _helloBroadcastClient: HelloBroadcastClientService,
  ) {
    this._initialize();
  }

  private _initialize(): void {
    merge(
      /** listen to hello events and other user activities */
      this._helloBroadcastClient.messages$,
      this._keepAliveBroadcastClient.userActivities$,
    )
      .pipe(
        /** we don't broadcast by design those events to mitigate endless loopback */
        mapTo({ broadcast: false, type: KeepAliveEventType.OtherInstanceActivity }),
      )
      .subscribe(this._events$);

    this.events$ = this._events$.asObservable();
  }
}
