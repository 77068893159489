import { Injectable } from '@angular/core';

import { Observable, Observer, ReplaySubject, Subject } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';

import { AnalyticsRequest } from '@demica/resources/analytics';

import { AnalyticsLocalStorageService } from '../../analytics/analytics-local-storage.service';

import { DB_OBJECT_STORAGE_NAME, DB_RECORD_LIMIT } from '../../config/analytics-db-constants';
import { isIndexedDBSupported, openIndexedDB } from '../../utils/window';

@Injectable()
export class IndexedDBService implements AnalyticsLocalStorageService {
  idb: Subject<IDBDatabase> = new ReplaySubject<IDBDatabase>(1);
  db$: Observable<IDBDatabase> = this.idb.pipe(
    take(1),
    filter((db) => !!db),
    shareReplay(1),
  );
  storeAvailable = false;

  private DEFAULT_SIZE_LIMIT = 100;

  initialize() {
    return new Observable<boolean>((res) => {
      if (!isIndexedDBSupported()) {
        res.next(false);
      } else {
        const openRequest = openIndexedDB();

        openRequest.onerror = () => {
          res.next(false);
        };

        openRequest.onsuccess = () => {
          this.storeAvailable = true;
          this.idb.next(openRequest.result);

          res.next(true);
        };

        openRequest.onupgradeneeded = () => {
          const db: IDBDatabase = openRequest.result;
          if (!db.objectStoreNames.contains(DB_OBJECT_STORAGE_NAME))
            db.createObjectStore(DB_OBJECT_STORAGE_NAME, { keyPath: 'id', autoIncrement: true });
        };
      }
    });
  }
  isAvailable(): boolean {
    return this.storeAvailable;
  }

  get(key: string): Observable<AnalyticsRequest> {
    return new Observable<AnalyticsRequest>((observer: Observer<AnalyticsRequest>) => {
      const onError = () => {
        observer.complete;
      };

      this.db$.subscribe((db: IDBDatabase) => {
        try {
          const tx = db.transaction(DB_OBJECT_STORAGE_NAME, 'readonly');
          const store = tx.objectStore(DB_OBJECT_STORAGE_NAME);
          const getRequest: IDBRequest<unknown> = store.get(key);

          getRequest.onerror = () => onError();
          getRequest.onsuccess = () => {
            observer.next(<AnalyticsRequest>getRequest.result);
            observer.complete();
          };
        } catch (err) {
          onError();
        }
      });
    });
  }

  add(payload: AnalyticsRequest): Observable<IDBValidKey> {
    return new Observable<IDBValidKey>((observer: Observer<IDBValidKey>) => {
      const onError = () => {
        observer.complete();
      };

      this.db$.subscribe((db) => {
        try {
          const tx = db.transaction(DB_OBJECT_STORAGE_NAME, 'readwrite');
          const store = tx.objectStore(DB_OBJECT_STORAGE_NAME);
          const addRequest = store.add(payload);

          addRequest.onerror = () => onError();
          addRequest.onsuccess = () => {
            observer.next(addRequest.result);
            this.checkLimit();
            observer.complete();
          };
        } catch (err) {
          onError();
        }
      });
    });
  }

  delete(key: string): Observable<unknown> {
    return new Observable<unknown>((observer: Observer<unknown>) => {
      const onError = () => {
        observer.complete();
      };

      this.db$.subscribe((db) => {
        try {
          const tx = db.transaction(DB_OBJECT_STORAGE_NAME, 'readwrite');
          const store = tx.objectStore(DB_OBJECT_STORAGE_NAME);
          const deleteRequest = store.delete(key);

          deleteRequest.onerror = () => onError();
          deleteRequest.onsuccess = () => {
            observer.next(deleteRequest.result);
            observer.complete();
          };
        } catch (err) {
          onError();
        }
      });
    });
  }

  getAll(): Observable<unknown> {
    return new Observable<unknown>((observer: Observer<AnalyticsRequest[]>) => {
      const onError = () => {
        observer.complete();
      };

      this.db$.subscribe((db) => {
        try {
          const tx = db.transaction(DB_OBJECT_STORAGE_NAME, 'readonly');
          const store = tx.objectStore(DB_OBJECT_STORAGE_NAME);
          const getRequest = store.openCursor();
          const resultArray: AnalyticsRequest[] = [];

          getRequest.onerror = () => onError();
          getRequest.onsuccess = () => {
            const cursor = getRequest.result;
            if (cursor) {
              resultArray.push({ id: cursor.key, ...cursor.value });
              cursor.continue();
            } else {
              observer.next(resultArray);
              observer.complete();
            }
          };
        } catch (err) {
          onError();
        }
      });
    });
  }

  private checkLimit() {
    this.getAll()
      .pipe(
        take(1),
        map((records: unknown[]) => records?.length ?? this.DEFAULT_SIZE_LIMIT),
        filter((size: number) => size >= DB_RECORD_LIMIT),
      )
      .subscribe(() => {
        this.idb.pipe().subscribe((db) => {
          db.transaction(DB_OBJECT_STORAGE_NAME, 'readwrite')
            .objectStore(DB_OBJECT_STORAGE_NAME)
            .clear();
        });
        throw new Error('Analytics store limit exceeded. All data has been removed');
      });
  }
}
