import { noop, race } from 'rxjs';
import { first } from 'rxjs/operators';

import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

type Handler = (x?: unknown) => void;

export class Modal<T> {
  closedOrDismissed = race(this.modalRef.closed, this.modalRef.dismissed).pipe(first());

  private successHandler: Handler;
  private errorHandler: Handler;
  private cancelHandler: Handler = noop;

  constructor(private modalRef: NgbModalRef) {}

  get instance(): T {
    return <T>(<unknown>this.modalRef.componentInstance);
  }

  set onSuccess(handler: Handler) {
    this.successHandler = handler;
    this.modalRef.result.then((x) => this.successHandler(x)).catch((e) => this.handlerReject(e));
  }

  set onError(handler: Handler) {
    this.errorHandler = handler;
  }

  set onCancel(handler: Handler) {
    this.cancelHandler = handler;
  }

  set onClose(handler: Handler) {
    this.modalRef.closed.toPromise().then(() => {
      handler();
    });
  }

  private handlerReject(error: unknown) {
    if (error) this.errorHandler(error);
    else this.cancelHandler();
  }
}
