import {
  msgDateFormat,
  msgDateTimeFormat,
  msgDefaultMaxLength,
  msgDefaultNumberMaxLength,
  msgEmailFormat,
  msgEndDateBeforeStartDate,
  msgMaxAvailableValue,
  msgMaxDecimalPlacesExceeded,
  msgMaxIntegerPlacesExceeded,
  msgNgbDate,
  msgRequired,
} from './message-definitions';
import { ValidationMessageDefinition } from './validation-message.interface';

export const defaultMessageDefinitions: ValidationMessageDefinition[] = [
  msgRequired,
  msgNgbDate,
  msgDefaultMaxLength,
  msgDefaultNumberMaxLength,
  msgMaxDecimalPlacesExceeded,
  msgMaxIntegerPlacesExceeded,
  msgEmailFormat,
  msgDateFormat,
  msgDateTimeFormat,
  msgMaxAvailableValue,
  msgEndDateBeforeStartDate,
];
