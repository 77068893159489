import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { UserAuthContextChangeReason } from '../user-auth-context-change.reason';

export const AUTH_CONTEXT_EVENT_SOURCE = new InjectionToken<AuthContextChangeEventSourceInterface>(
  'UserAuthContextChangeReason',
);

export interface AuthContextChangeEventSourceInterface {
  getEvents$(): Observable<UserAuthContextChangeReason>;
}
