import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

import { from, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export type MemoizedAsyncFormControlValidatorOptions = {
  valueProvider?: (formControl: AbstractControl) => string;
  maxTime?: number;
};

export function memoizedAsyncFormControlValidator(
  asyncValidator: AsyncValidatorFn,
  { valueProvider = (fc) => fc.value, maxTime }: MemoizedAsyncFormControlValidatorOptions = {},
): AsyncValidatorFn {
  let prevValue: string | null = null;
  let lastCheck: number | null = null;
  let previousResult: unknown;
  return function (control: AbstractControl) {
    const value = valueProvider(control);
    const maxTimeNotExceeded = maxTime ? Date.now() - lastCheck < maxTime : true;

    if (value === prevValue && maxTimeNotExceeded) {
      return of(previousResult);
    } else {
      return from(asyncValidator.call(this, control)).pipe(
        tap((result) => {
          previousResult = result;
          prevValue = value;
          lastCheck = Date.now();
        }),
      );
    }
  };
}
