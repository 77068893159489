import { Component, OnDestroy, OnInit } from '@angular/core';

import { PageLoaderService, SybasePageService } from '@demica/core/core';

import { PageNumberBaseComponent } from './page-number-base.component';

@Component({
  selector: 'trf-sybase-page-number',
  templateUrl: 'page-number-base.component.html',
})
export class SybasePageNumberComponent
  extends PageNumberBaseComponent
  implements OnInit, OnDestroy
{
  constructor(pageLoaderService: PageLoaderService, pageService: SybasePageService<unknown>) {
    super(pageLoaderService, pageService);
  }
}
