import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective, FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { emitChangeEvent } from '@demica/components';
import { getFormControlName, LocalisationService, TypedChanges } from '@demica/core/core';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { SelectComparatorConfig } from '../form-select-row/select-comparator.directive';
import { NgSelectComponent } from '@ng-select/ng-select';

type SearchFunction = (term: string, item: unknown) => boolean;

@Component({
  selector: 'trf-form-multi-select-row',
  templateUrl: 'form-multi-select-row.component.html',
  styleUrls: ['./form-multi-select-row.component.sass'],
})
export class FormMultiSelectRowComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('select')
  select: NgSelectComponent;

  @Input()
  name?: string;
  @Input()
  control?: FormControl;
  @Input()
  fieldPath?: string;
  @Input()
  label: string;
  @Input()
  labelClasses?: string;
  @Input()
  defaultLabel?: string;
  @Input()
  translationPrefix?: string;
  @Input()
  options: unknown[];
  @Input()
  validations?: ValidationMessage[];
  @Input()
  disabled: boolean;
  @Input()
  selectAll = false;
  @Input()
  closeOnSelect = true;
  @Input()
  helperToolTip?: string;
  @Input()
  clearable = true;
  @Input()
  groupByKey: string = null;
  @Input()
  searchable = false;
  @Input()
  searchFn: SearchFunction = null;
  group: UntypedFormGroup;
  formControlName: string;
  destroySubject = new Subject<void>();
  dirty = false;

  constructor(
    public selectConfig: SelectComparatorConfig,
    private fgd: FormGroupDirective,
    private translate: TranslateService,
    private localisationService: LocalisationService,
  ) {}

  ngOnChanges(changes: TypedChanges<FormMultiSelectRowComponent>): void {
    if (changes.name || changes.control) {
      this.formControlName = this.name ?? getFormControlName(this.control);
    }

    if (changes.disabled) {
      const typeControl = this.fgd.form.get(this.formControlName);
      changes.disabled.currentValue ? typeControl.disable() : typeControl.enable();
    }
  }

  ngOnInit(): void {
    this.group = this.fgd.form;
    if (this.translationPrefix) {
      this.translationPrefix += '.';
    } else {
      this.translationPrefix = '';
    }

    // TODO: Try to use method instead of pipe with changeDetection on onPush
    this.localisationService.locale.pipe(takeUntil(this.destroySubject)).subscribe(() => {
      this.options = [...(this.options || [])];
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  markAsDirty(): void {
    this.dirty = true;
  }

  showHelperToolTip(): string | undefined {
    return this.helperToolTip;
  }

  selectAllItems(): void {
    const control = this.group.get(this.formControlName);

    if (control) {
      control.setValue([...this.options]);
      this.select.close();
      this.onAddItem();
    }
  }

  onAddItem(): void {
    if (this.dirty) {
      emitChangeEvent(this.select.element);
    }
  }
}
