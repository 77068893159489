import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { OrganisationResourceService } from '../../transaction/organisation/service/organisation-resource.service';

import { ajaxDelay } from '../../../config/ajax';
import { MemoizedAsyncFormControlValidator } from '../../../utils/memoized-async-form-control-validator';

@Injectable()
export class SellerNameValidator implements Validator {
  constructor(private organisationResource: OrganisationResourceService) {}

  @MemoizedAsyncFormControlValidator()
  validate(control: AbstractControl): ValidationErrors | Observable<ValidationErrors> {
    const name = control.value;

    return ajaxDelay.pipe(
      switchMap(() =>
        this.organisationResource.checkClientNameAvailable(name).pipe(
          distinctUntilChanged(),
          map((response) => responseToValidationErrors(response)),
        ),
      ),
    );
  }
}

function responseToValidationErrors(response: boolean) {
  if (!response) return { 'opco-name-unavailable': true };

  return null;
}
