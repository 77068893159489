import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { TypedChanges } from '@demica/core/core';

import { RoutingSelectItem } from './routing-select.item';

@Component({
  selector: 'trf-routing-select',
  templateUrl: './routing-select.component.html',
  styleUrls: ['./routing-select.component.sass'],
})
export class RoutingSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  items: RoutingSelectItem[];

  @Input()
  label: string;

  form = this._fb.group({
    item: [null as RoutingSelectItem],
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {}

  ngOnChanges(changes: TypedChanges<RoutingSelectComponent>): void {
    if (changes.items) {
      this._setActiveTabs();
    }
  }

  ngOnInit(): void {
    this._listenForSelectedOptionChange();
    this._listenForNavigationEvents();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _listenForSelectedOptionChange() {
    this.form.controls.item.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((selectedItem) => {
        this._router.navigate([selectedItem.route.path], {
          queryParamsHandling: 'merge',
          relativeTo: this._activatedRoute,
        });
      });
  }

  private _listenForNavigationEvents() {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this._setActiveTabs());
  }

  private _setActiveTabs(): void {
    if (!this.items) {
      return;
    }

    const url = this._router.routerState.snapshot.url.split('?')[0];
    const urlSegments = url.split('/');

    urlSegments.reverse().some((segment) => {
      const foundItem = this.items.find((item) => item.route.path === segment);

      if (foundItem) {
        this.form.patchValue(
          {
            item: foundItem,
          },
          {
            emitEvent: false,
          },
        );

        return true;
      }
      return false;
    });
  }
}
