<td
  class="cell-lower-range"
  testid="cell-lower-range"
  [title]="getLowerRange()"
>
  {{ getLowerRange() }}
</td>

<td
  class="cell-upper-range"
  testid="cell-upper-range"
  [title]="getUpperRange()"
>
  {{ getUpperRange() }}
</td>

<td
  class="cell-value"
  testid="cell-value"
  [title]="getValue()"
>
  {{ getValue() }}
</td>

<td
  class="cell-actions"
  testid="cell-actions"
>
  <trf-table-actions
    [actions]="actions"
    [actionSubject]="row"
  ></trf-table-actions>
</td>
