import { AbstractControl } from '@angular/forms';

import { ReserveAmount } from '../model/reserve-amount';
import { ReserveFloor } from '../model/reserve-floor';
import { ReserveAmountTypeSelection, ReserveFloorSelection } from '../model/reserve.interface';

export function updateFormControlState(formControl: AbstractControl, flag: boolean) {
  if (!flag) {
    formControl.reset();
    formControl.disable();
  } else {
    formControl.enable();
  }
}

export function resolveFloorTypeSelection(
  floorFormControl: AbstractControl,
): ReserveFloorSelection {
  const floorValue = floorFormControl.value;

  if (!floorValue) {
    return { isExternal: false, isFixed: false, isNone: false };
  }

  const isFixedSelected = floorValue ? floorValue.entityId === ReserveFloor.FIXED : false;
  const isExternalSelected = floorValue ? floorValue.entityId === ReserveFloor.EXTERNAL : false;
  const isNoneSelected = floorValue ? floorValue.entityId === ReserveFloor.NONE : false;

  return {
    isFixed: isFixedSelected,
    isExternal: isExternalSelected,
    isNone: isNoneSelected,
  };
}

export function resolveAmountTypeSelection(
  amountTypeFormControl: AbstractControl,
): ReserveAmountTypeSelection {
  const amountTypeValue = amountTypeFormControl.value;

  if (!amountTypeValue) {
    return { isPercentage: false, isAmount: false };
  }

  const isAmountSelected = amountTypeValue
    ? amountTypeValue.entityId === ReserveAmount.AMOUNT
    : false;
  const isPercentageSelected = amountTypeValue
    ? amountTypeValue.entityId === ReserveAmount.PERCENTAGE
    : false;

  return {
    isAmount: isAmountSelected,
    isPercentage: isPercentageSelected,
  };
}
