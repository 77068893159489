import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { ToasterService } from '../../services/toaster.service';

import { IClearWrapper } from '../../model/clear-wrapper.model';
import { Toast, ToastClickEvent } from '../../model/toast.model';
import { ToasterConfig } from '../../toaster-config';
import { ToasterContainerTransitions } from './toaster-container.transitions';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'toaster-container',
  templateUrl: './toaster-container.component.html',
  styleUrls: ['./toaster-container.component.sass'],
  animations: ToasterContainerTransitions,
})
export class ToasterContainerComponent implements OnInit, OnDestroy {
  @Input()
  toasterConfig!: ToasterConfig;

  toasts: Toast[] = [];

  private _addToastSubscriber?: Subscription;
  private _clearToastsSubscriber?: Subscription;

  constructor(private _toasterService: ToasterService) {}

  ngOnInit() {
    this._registerSubscribers();
    if (this._isNullOrUndefined(this.toasterConfig)) {
      this.toasterConfig = new ToasterConfig();
    }
  }

  ngOnDestroy() {
    if (this._addToastSubscriber) {
      this._addToastSubscriber.unsubscribe();
    }
    if (this._clearToastsSubscriber) {
      this._clearToastsSubscriber.unsubscribe();
    }
  }

  click(toast: Toast, isCloseButton?: boolean) {
    if (toast.onClickCallback) {
      toast.onClickCallback(toast);
    }

    const tapToDismiss = !this._isNullOrUndefined(toast.tapToDismiss)
      ? toast.tapToDismiss
      : this.toasterConfig.tapToDismiss;

    if (tapToDismiss || (toast.showCloseButton && isCloseButton)) {
      this.removeToast(toast);
    }
  }

  childClick(event: ToastClickEvent) {
    this.click(event.value.toast, event.value.isCloseButton);
  }

  removeToast(toast: Toast) {
    const index = this.toasts.indexOf(toast);
    if (index < 0) {
      return;
    }

    const toastId = this.getToastIdOrDefault(toast);

    this.toasts.splice(index, 1);

    if (toast.onHideCallback) {
      toast.onHideCallback(toast);
    }
    this._toasterService.removeToastAction({
      toastId: toastId,
      toastContainerId: toast.toastContainerId,
    });
  }

  getToastIdOrDefault(toast: Toast) {
    return toast.toastId || '';
  }

  private _registerSubscribers() {
    this._addToastSubscriber = this._toasterService.addToast$.subscribe((toast: Toast) => {
      this._addToast(toast);
    });

    this._clearToastsSubscriber = this._toasterService.clearToasts$.subscribe(
      (clearWrapper: IClearWrapper) => {
        this._clearToasts(clearWrapper);
      },
    );
  }

  private _addToast(toast: Toast) {
    if (
      toast.toastContainerId &&
      this.toasterConfig.toastContainerId &&
      toast.toastContainerId !== this.toasterConfig.toastContainerId
    ) {
      return;
    }

    if (
      !toast.type ||
      !this.toasterConfig.typeClasses[toast.type] ||
      !this.toasterConfig.iconClasses[toast.type]
    ) {
      toast.type = this.toasterConfig.defaultToastType;
    }

    if (this.toasterConfig.preventDuplicates && this.toasts.length > 0) {
      if (toast.toastId && this.toasts.some((t) => t.toastId === toast.toastId)) {
        return;
      } else if (this.toasts.some((t) => t.body === toast.body)) {
        return;
      }
    }

    if (this._isNullOrUndefined(toast.showCloseButton)) {
      if (typeof this.toasterConfig.showCloseButton === 'object') {
        toast.showCloseButton = this.toasterConfig.showCloseButton[toast.type];
      } else if (typeof this.toasterConfig.showCloseButton === 'boolean') {
        toast.showCloseButton = this.toasterConfig.showCloseButton as boolean;
      }
    }

    if (toast.showCloseButton) {
      toast.closeHtml = toast.closeHtml || this.toasterConfig.closeHtml;
    }

    toast.bodyOutputType = toast.bodyOutputType || this.toasterConfig.bodyOutputType;

    if (this.toasterConfig.newestOnTop) {
      this.toasts.unshift(toast);
      if (this._isLimitExceeded()) {
        this.toasts.pop();
      }
    } else {
      this.toasts.push(toast);
      if (this._isLimitExceeded()) {
        this.toasts.shift();
      }
    }

    if (toast.onShowCallback) {
      toast.onShowCallback(toast);
    }
  }

  private _isLimitExceeded() {
    return this.toasterConfig.limit && this.toasts.length > this.toasterConfig.limit;
  }

  private _removeAllToasts() {
    for (let i = this.toasts.length - 1; i >= 0; i--) {
      this.removeToast(this.toasts[i]);
    }
  }

  private _clearToasts(clearWrapper: IClearWrapper) {
    const toastId = clearWrapper.toastId;
    const toastContainerId = clearWrapper.toastContainerId;

    if (
      this._isNullOrUndefined(toastContainerId) ||
      toastContainerId === this.toasterConfig.toastContainerId
    ) {
      this._clearToastsAction(toastId);
    }
  }

  private _clearToastsAction(toastId?: string) {
    if (toastId) {
      this.removeToast(this.toasts.filter((t) => t.toastId === toastId)[0]);
    } else {
      this._removeAllToasts();
    }
  }

  private _isNullOrUndefined(value: unknown): boolean {
    return value === null || typeof value === 'undefined';
  }
}
