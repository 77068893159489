import { Injectable } from '@angular/core';

import { AnalyticsEventTypes } from '@demica/resources/analytics';
import { AnalyticsClientType } from '@demica/resources/app-config';

import { getUTagData, trackView } from './tealium-analytics-data-provider.helpers';

import { AnalyticsClient, AnalyticsEventProperties } from '../abstract-analytics-client.class';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsTealiumClient extends AnalyticsClient {
  clientType = AnalyticsClientType.TEALIUM;

  postEvent(event: AnalyticsEventTypes, props?: AnalyticsEventProperties): void {
    switch (event) {
      case AnalyticsEventTypes.PAGE_LOAD: {
        trackView({
          ...getUTagData(props.lang, props.url),
        });
        break;
      }
    }
  }
}
