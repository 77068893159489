import {
  AfterViewInit,
  Directive,
  Host,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  TemplateRef,
} from '@angular/core';

import { CustomWarningNotificationComponent } from '@demica/components';
import { TypedChanges } from '@demica/core/core';

import { FormRowClass } from './model/form-row.class';

@Directive({
  selector: '[trfWarningValidation]',
})
export class WarningValidationDirective implements OnChanges, AfterViewInit, OnDestroy {
  @Input()
  @HostBinding('class.trf-custom-warning')
  trfWarningValidation = false;

  @Input()
  trfWarningValidationMessage = '';

  @Input()
  trfWarningValidationTemplate: TemplateRef<any>;

  constructor(@Host() private _formRow: FormRowClass) {}

  ngAfterViewInit(): void {
    this._toggleWarningValidation();
  }

  ngOnChanges(changes: TypedChanges<WarningValidationDirective>): void {
    if (
      changes.trfWarningValidation ||
      changes.trfWarningValidationMessage ||
      changes.trfWarningValidationTemplate
    ) {
      this._toggleWarningValidation();
    }
  }

  ngOnDestroy(): void {
    this._clearContainer();
  }

  private _toggleWarningValidation(): void {
    this._toggleMessageView();
  }

  private _toggleMessageView(): void {
    if (!this._formRow?.additionalValidationContainer) {
      return;
    }

    this._clearContainer();

    if (!this.trfWarningValidation) {
      return;
    }
    if (this.trfWarningValidationTemplate) {
      return this._createEmbeddedView();
    }
    if (this.trfWarningValidationMessage) {
      return this._createComponent();
    }
  }

  private _createEmbeddedView(): void {
    this._formRow?.additionalValidationContainer
      ?.createEmbeddedView(this.trfWarningValidationTemplate)
      ?.detectChanges();
  }

  private _createComponent(): void {
    const ref = this._formRow?.additionalValidationContainer?.createComponent(
      CustomWarningNotificationComponent,
    );
    ref.instance.message = this.trfWarningValidationMessage;
    ref.instance.id = this._formRow.formControlName + FormRowClass.warningNotificationIdPostfix;
    ref.changeDetectorRef.detectChanges();
  }

  private _clearContainer(): void {
    this._formRow?.additionalValidationContainer?.clear();
  }
}
