import { Injectable, OnDestroy } from '@angular/core';

import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from './user.service';

import { EntityReference } from '../interface/entity-reference.interface';
import { EntityId } from '../interface/has-entity-id.interface';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root',
})
export class UserRegionService implements OnDestroy {
  private _currentUser$: ReplaySubject<User> = this.userService.currentUser;

  private _destroyed$: Subject<void> = new Subject();

  constructor(private userService: UserService) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  getRegionReferenceId$(): Observable<EntityId> {
    return this._currentUser$.pipe(map((user) => user?.getOrganizationUnitReferenceId()));
  }

  getOrganizationUnitReference$(): Observable<EntityReference> {
    return this._currentUser$.pipe(map((user) => user?.getOrganizationUnitReference()));
  }
}
