import { Pipe, PipeTransform } from '@angular/core';

import { EntityId } from '@demica/core/core';

type Entity = { entityId: EntityId; name: string };

@Pipe({
  name: 'entityIdName',
})
export class EntityNamePipe implements PipeTransform {
  transform(
    entityId: EntityId | EntityId[],
    entities: Entity[],
    separator = ', ',
  ): string | number {
    const ids: EntityId[] = Array.isArray(entityId) ? entityId : [entityId];

    if (!(entityId && entities)) {
      return ids.join(separator);
    }

    return ids
      .map((id) => entities.find((entity) => entity.entityId === id)?.name ?? id)
      .join(separator);
  }
}
