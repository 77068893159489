<trf-modal-container
  [closable]="false"
  headerText="CONFIRMATION_MODAL.TITLE"
  testid="confirmation-modal"
>
  <div class="trf-section-content">
    <span>{{ message | translate }}</span>
    <ngp-button
      classes="primary"
      class="logout-button"
      [key]="'AUTHORIZATION.TOKEN_REFRESH_HANDLER.OK'"
      (click)="onClick()"
      testid="logout-confirmation-modal-button"
    >
    </ngp-button>
  </div>
</trf-modal-container>
