export type SetKey = number | string;

export const calculateNewIndexByReferenceSet = (
  referenceSet: SetKey[],
  alreadyAdded: SetKey[],
  toAdd: SetKey,
): number => {
  const added = [...alreadyAdded, toAdd];

  return referenceSet
    .filter((setItem) => added.includes(setItem))
    .findIndex((setItem) => setItem === toAdd);
};
