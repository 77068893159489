<p data-testid="text-ellipsis-container">
  <span data-testid="text-ellipsis">{{
    isOpen ? text : (text | textEllipsis: { textLength: textLength })
  }}</span>
  <a
    *ngIf="text?.length > textLength"
    (click)="toggleText()"
    class="trf-link"
    role="button"
    data-testid="text-ellipsis-toggle"
    >{{ (isOpen ? "COMMON.SEE_LESS" : "COMMON.SEE_MORE") | translate }}</a
  >
</p>
