import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { RestResponse } from '../../../model/response.interface';
import { encodeEndpoint } from '../../../security/encode-url';
import { toData } from '../../rest/response-mapping';
import { PageableTableParameters } from '../page-params.interface';
import { SybasePage } from './sybase-page-rows.interface';

@Injectable()
export class SybasePageResourceService {
  constructor(private http: HttpClient) {}

  postForPageResources<RESULT>(pageParams: PageableTableParameters, url: string) {
    return this.http
      .post<RestResponse<SybasePage<RESULT[]>>>(encodeEndpoint(url), pageParams)
      .pipe(map(toData));
  }
}
