import {
  EntityId,
  SortDirection,
  SortParameters as PageSortParameters,
} from '@demica/resources/common';

export { SortDirection, SortParameters as PageSortParameters } from '@demica/resources/common';

export interface IdentifiedPageParams {
  // IdentifiedPageParams can be used if we need to render multiple paginations in DOM at the same time
  // The id is passed everywhere so components can filter events and react only to theirs
  pageParamsId: string;
}

// TODO: TRFV2-389 Refactor to proper types from "any"
export interface PageParams extends PageSortParameters, Partial<IdentifiedPageParams> {
  // eslint-disable-next-line
  tableParams: any;
  lastRow: LastRowParameters[];
  sortColumn: string;
  sortDirection: SortDirection;
  // eslint-disable-next-line
  filters: any;
}

export interface PageableTableParameters extends Partial<IdentifiedPageParams> {
  filtersParams: TableFilters;
  keysetPageRequest: KeysetPageRequest;
}

export interface KeysetPageRequest {
  pageNumber?: number; //used in SybasePageService where it does not correlate directly to page shown on UI
  lastRow: LastRowParameters[];
  sort: Sort;
  limit?: number;
}

export interface Sort {
  column: string;
  direction: SortDirection;
}

export interface TableFilters {
  environmentIds: EntityId[];
  clientIds: EntityId[];
  transactionIds: EntityId[];
  /**
   * @deprecated new api use sellerIds instead of opcoIds, we slowly migrate to sellerIds and remove opcosIds
   */
  opcoIds?: EntityId[];
  sellerIds?: EntityId[];
}

export type BaseTableFilters<T = Record<string, unknown>> = TableFilters & T;

export interface LastRowParameters {
  lastRowDbId: number | string;
  lastRowEnvId: number;
}

export const invertSortDirection = (dir: SortDirection) =>
  dir === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
