import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroupDirective, UntypedFormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { emitChangeEvent } from '@demica/components';
import {
  addDotToPrefix,
  byProperty,
  getFormControlName,
  isString,
  LocalisationService,
  SelectOption,
  TypedChanges,
} from '@demica/core/core';
import { isPojo } from '@demica/utils';

import { OptionNamePipe } from '../../pipe/option-name.pipe';

import { FormRowClass } from '../../forms/model/form-row.class';
import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { HasKeyOrId } from '../../model/has-key-or-id.interface';
import { SelectComparatorConfig } from './select-comparator.directive';
import { NgSelectComponent } from '@ng-select/ng-select';

export enum SelectControlError {
  DISABLED_OPTION_SELECTED = 'disabled-option-selected',
}

export type SelectRowOption = SelectOption | string;

@Component({
  selector: 'trf-form-select-row',
  templateUrl: 'form-select-row.component.html',
  styleUrls: ['./form-select-row.component.sass'],
  providers: [OptionNamePipe, { provide: FormRowClass, useExisting: FormSelectRowComponent }],
})
export class FormSelectRowComponent implements FormRowClass, OnInit, OnChanges, OnDestroy {
  @ViewChild('select')
  select: NgSelectComponent;

  @Input()
  name?: string;
  @Input()
  control?: FormControl;
  // @deprecated
  @Input()
  fieldPath?: string;
  @Input()
  label: string;
  @Input()
  labelClasses?: string;
  @Input()
  defaultLabel?: string;
  @Input()
  translationPrefix?: string | string[];

  @Input()
  options: SelectRowOption[];

  @Input()
  validations?: ValidationMessage[];
  @Input()
  disabled: boolean;
  @Input()
  readonly: boolean;
  @Input()
  helperToolTip?: string;
  @Input()
  groupByKey: string | null = null;
  @Input()
  clearable = true;
  @Input()
  searchable = true;
  @Input()
  infoTooltip?: string;
  @Input()
  ariaDescText?: string;
  @Input()
  ariaDescId?: string;
  @Input()
  loading?: boolean;

  @ViewChild('additionalValidationContainer', { read: ViewContainerRef })
  additionalValidationContainer: ViewContainerRef;

  @Output()
  selected = new EventEmitter<SelectRowOption>();
  group: UntypedFormGroup;
  formControlName: string;
  destroySubject = new Subject<void>();
  dirty = false;

  protected readonly FormRowClass = FormRowClass;

  @Input()
  sort:
    | boolean
    | ((sortProperty: keyof SelectOption) => (a: SelectOption, b: SelectOption) => number) =
    (sortProperty: keyof SelectOption) =>
    (a: SelectOption, b: SelectOption): number => {
      // warning: be careful with sorting string - options can be SelectOption | string
      return (a[sortProperty] as string | null)?.localeCompare(b[sortProperty] as string);
    };

  constructor(
    private fgd: FormGroupDirective,
    private localisationService: LocalisationService,
    private optionName: OptionNamePipe,
    public selectConfig: SelectComparatorConfig,
  ) {
    this.searchTerm = this.searchTerm.bind(this);
  }

  ngOnChanges(changes: TypedChanges<FormSelectRowComponent>): void {
    if (changes.name || changes.control) {
      this.formControlName = this.name ?? getFormControlName(this.control);
    }

    if (changes.disabled) {
      const typeControl = this.fgd.form.get(this.formControlName);
      changes.disabled.currentValue ? typeControl.disable() : typeControl.enable();
    }

    if (changes.options) {
      this.transformOptions();
    }
  }

  ngOnInit(): void {
    const control = this.fgd.form.get(this.formControlName);
    control?.addValidators(this.validate);
    control?.updateValueAndValidity({ emitEvent: false });

    this.group = this.fgd.form;
    this.translationPrefix = addDotToPrefix(this.translationPrefix);

    // TODO: Try to use method instead of pipe with changeDetection on onPush
    this.localisationService.locale.pipe(takeUntil(this.destroySubject)).subscribe(() => {
      this.options = [...(this.options || [])];
      this.transformOptions();
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  validate = ({ value }: FormControl) => {
    if (value === null) {
      return null;
    }
    if (this.options?.length > 0 && isString(this.options[0])) {
      return null;
    }

    const selected = this.options?.find(
      (option) =>
        this.selectConfig.compareFn && this.selectConfig.compareFn(option as HasKeyOrId, value),
    ) as SelectOption;
    if (selected?.disabled) {
      return { [SelectControlError.DISABLED_OPTION_SELECTED]: true };
    }

    return null;
  };

  onSelected(event: SelectRowOption): void {
    if (this.options.length > 0 && this.dirty) {
      emitChangeEvent(this.select.element);
      this.selected.emit(event);
    }
  }

  showHelperToolTip(): string | undefined {
    return this.helperToolTip;
  }

  searchTerm(term: string, item: SelectRowOption): boolean {
    const termToCompare = term.toLowerCase();
    const optionNameToCompare = this.optionName
      .transform(item, this.translationPrefix)
      .toLowerCase();
    return optionNameToCompare.includes(termToCompare);
  }

  markAsDirty(): void {
    this.dirty = true;
  }
  private transformOptions(): void {
    // TODO: TRFUI-5436 extend to support language change
    if (this.options && this.sort) {
      this.transformOptionsSet(this.options);
      // warning type system do not support grouping options
      // current types are experimental
      if (this.groupByKey) {
        this.options.forEach((optionsSet) => {
          type OptionGroup = Record<keyof typeof this.groupByKey, SelectRowOption[]>;
          const optionGroup = (optionsSet as unknown as OptionGroup)[
            this.groupByKey as keyof OptionGroup
          ];
          return this.transformOptionsSet(optionGroup);
        });
      }
    }
  }

  private transformOptionsSet(set: SelectRowOption[]): void {
    const sortProperty: keyof SelectOption = '_sortProperty';

    set.forEach((o) => {
      if (isPojo(o)) {
        o[sortProperty] = this.optionName.transform(o, this.prefix);
      }
    });

    set.sort(isFunction(this.sort) ? this.sort(sortProperty) : byProperty(sortProperty));
  }

  get prefix(): string | string[] {
    if (!this.translationPrefix) return undefined;

    return this.translationPrefix[this.translationPrefix.length - 1] === '.'
      ? this.translationPrefix
      : this.translationPrefix + '.';
  }

  clear(): void {
    this.select.handleClearClick();
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(value: unknown): value is Function {
  return typeof value === 'function';
}
