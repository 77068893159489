import { AppConfigService } from '@demica/core/core';

import { AppInitializerAfterConfig } from './token/app-initializer-after-config.token';

export function appConfigInitializerFactory(
  appConfig: AppConfigService,
  configInitializers: AppInitializerAfterConfig[],
) {
  return async () => {
    await appConfig.appConfigAsPromise;

    for (const initializer of configInitializers) {
      await initializer();
    }
  };
}
