import { ConnectedPosition } from '@angular/cdk/overlay';
import { TemplateRef } from '@angular/core';

export enum NgpTooltipPosition {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export type TooltipType = string | TemplateRef<unknown>;

export interface ExtendedConnectedPosition extends ConnectedPosition {
  position: NgpTooltipPosition;
}

export function isTooltipString(tooltip: unknown): tooltip is string {
  return typeof tooltip === 'string' || tooltip instanceof String;
}

export function isTooltipTemplate(tooltip: unknown): tooltip is TemplateRef<unknown> {
  return tooltip instanceof TemplateRef;
}
