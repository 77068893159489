import { PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ColumnType, ExportType, uniq } from '@demica/core/core';

import { PieChartData } from '../component/pie-chart/pie-chart-data';
import { ExportableChart } from '../model/chart-component.interface';

import { ExportDataHelper } from './model/export-data/export-data-helper';

import { HorizontalLineData, RequestExport, SvgChartData, TableModel } from './model/chart-export';
import { ChartExportConfiguration } from './model/export-data/export-configuration';
import { YAxisConfiguration } from 'c3';

interface AdditionalChart {
  chartComponent: ExportableChart;
  svgChartTitle: string;
}

export const AXIS_X_DATE = 'x';
const MULTIPLE_AXIS_X_DATE = '_x';

export class ChartExportBuilder {
  private yAxisConfiguration: YAxisConfiguration;
  private definedColumnNames: string[];
  private definedColumnTypes: ColumnType[];
  private additionalChart: AdditionalChart[] = [];

  private numberFormatter: (v: string) => string;
  private dateFormatter: (v: string) => string;
  private shouldFormat = false;
  private localisedMoneyPipe: PipeTransform;
  private localisedDatePipe: PipeTransform;
  private currency: string;
  private subtitleChart: string;
  private subtitleTable: string;
  private svgChartTitle: string;
  private isTableData = false;
  private chartTitle: string;
  private tableModel: TableModel;
  private labelsMap: { [key: string]: string };

  constructor(private chartComponent: ExportableChart, private exportType: ExportType) {}

  setChartTitle(chartTitle: string) {
    this.chartTitle = chartTitle;
    return this;
  }

  addDefiedColumn(columnName: string, columnType: ColumnType) {
    if (this.definedColumnNames === undefined && this.definedColumnTypes === undefined) {
      this.definedColumnTypes = [];
      this.definedColumnNames = [];
    }

    this.definedColumnNames.push(columnName);
    this.definedColumnTypes.push(columnType);
    return this;
  }

  addStandaloneChart(chartComponent: ExportableChart, svgTitle?: string) {
    this.additionalChart.push({
      chartComponent: chartComponent,
      svgChartTitle: svgTitle,
    });
    return this;
  }

  setTableData(isTableData: boolean) {
    this.isTableData = isTableData;
    return this;
  }

  setSvgChartTitle(svgChartTitle: string) {
    this.svgChartTitle = svgChartTitle;
    return this;
  }

  setSubtitleChart(subtitleChart: string) {
    this.subtitleChart = subtitleChart;
    return this;
  }

  serSubtitleTable(subtitleTable: string) {
    this.subtitleTable = subtitleTable;
    return this;
  }

  setCurrency(currency: string) {
    this.currency = currency;
    return this;
  }

  setLocalisedDatePipe(localisedDatePipe: PipeTransform) {
    this.localisedDatePipe = localisedDatePipe;
    return this;
  }

  setLocalisedMoneyPipe(localisedMoneyPipe: PipeTransform) {
    this.localisedMoneyPipe = localisedMoneyPipe;
    return this;
  }

  addYAxisConfiguration(yAxisConfiguration: YAxisConfiguration) {
    this.yAxisConfiguration = yAxisConfiguration;
    return this;
  }

  setNumberFormatter(numberFormatter: (v: string) => string) {
    this.numberFormatter = numberFormatter;
    return this;
  }

  setDateFormatter(dateFormatter: (v: string) => string) {
    this.dateFormatter = dateFormatter;
    return this;
  }

  setShouldFormat(shouldFormat: boolean): ChartExportBuilder {
    this.shouldFormat = shouldFormat;
    return this;
  }

  createPieChartRequest(translateService: TranslateService): RequestExport {
    const svgNode = this.extractSvgNodeFromComponent(this.chartComponent);
    const pieChartData = this.chartComponent.getChartDataForExport() as PieChartData;

    return new ExportDataHelper(
      this.getExportConfiguration(translateService, pieChartData.getChartValuesWithPercentage(), [
        this.getSvgChartData(translateService, svgNode, this.svgChartTitle),
      ]),
    ).getRequestExport();
  }

  createLineChartRequest(translateService: TranslateService): RequestExport {
    const chartValues = this.filterChartDataForExportBySeriesName(
      this.chartComponent.getChartDataForExport().getChartValues(),
      MULTIPLE_AXIS_X_DATE,
    );
    const horizontalLineData: HorizontalLineData[] = [];

    this.chartComponent
      .getChartDataForExport()
      .getYAxisLines()
      .forEach((horizontalLine) => {
        horizontalLineData.push({ title: horizontalLine.text, value: horizontalLine.value });
      });

    const svgNode = this.extractSvgNodeFromComponent(this.chartComponent);

    const exportConfiguration = this.getExportConfiguration(
      translateService,
      chartValues,
      [this.getSvgChartData(translateService, svgNode, this.svgChartTitle)],
      horizontalLineData,
    );

    return new ExportDataHelper(exportConfiguration).getRequestExport();
  }

  createLineChartRequestForReserveGraph(translateService: TranslateService) {
    return Object.assign(this.createLineChartRequest(translateService), this.createRequestExport());
  }

  setTableModel(tableModel: TableModel) {
    this.tableModel = tableModel;
    return this;
  }

  setLabelsMap(labelsMap: { [key: string]: string }) {
    this.labelsMap = labelsMap;
    return this;
  }

  createRequestExport(): RequestExport {
    return {
      labelsMap: this.labelsMap,
      tableModel: this.tableModel,
    };
  }

  private getExportConfiguration(
    translateService: TranslateService,
    values: string[][],
    svgData: SvgChartData[],
    horizontalLines?: HorizontalLineData[],
  ): ChartExportConfiguration {
    this.additionalChart.forEach((additionalChart) => {
      const svgNode = this.extractSvgNodeFromComponent(additionalChart.chartComponent);
      svgData.unshift(
        this.getSvgChartData(translateService, svgNode, additionalChart.svgChartTitle),
      );
    });

    return {
      labels: {
        chartTitle:
          this.chartTitle === undefined
            ? this.chartComponent.getChartDataForExport().getChartName()
            : this.chartTitle,
        subtitleChart: this.subtitleChart,
        subtitleTable: this.subtitleTable,
      },
      columns: {
        definedColumnNames: this.definedColumnNames,
        definedColumnTypes: this.definedColumnTypes,
      },
      exportType: this.exportType,
      data: {
        tableData: values,
        svgData: this.exportType === ExportType.PDF ? svgData : [],
      },
      additionalYAxisLabel: this.yAxisConfiguration
        ? typeof this.yAxisConfiguration.label === 'string'
          ? this.yAxisConfiguration.label
          : this.yAxisConfiguration.label.text
        : null,
      isTableData: this.isTableData,
      translateService: translateService,
      horizontalLineData: horizontalLines,
      format: {
        numberFormat: (value: string) => (value ? this.numberFormat(value) : ''),
        dateFormat: (value: string) => (value ? this.dateFormat(value) : ''),
        shouldFormat: this.shouldFormat,
      },
    };
  }

  private filterChartDataForExportBySeriesName(chartValues: string[][], seriesName: string) {
    const VALUE_NOT_PRESENT: string = null;
    const isSingleXAxisData = (dates: string[][]) =>
      dates.length === 1 && dates[0][0] === AXIS_X_DATE;
    const dates = chartValues.filter(
      (value) =>
        value[0].toLowerCase().includes(seriesName) || value[0].toLowerCase() === AXIS_X_DATE,
    );
    const values = chartValues.filter(
      (value) =>
        value[0].toLowerCase() !== AXIS_X_DATE && !value[0].toLowerCase().includes(seriesName),
    );
    if (isSingleXAxisData(dates)) {
      return dates.concat(values);
    }
    const exportableData: { [date: string]: string }[] = [];
    const axisLabels: string[] = [];
    const valuesToExport: string[][] = [];

    dates
      .map((dateAxis) => {
        const valuesArray: string[] = values.find((valuesArray) =>
          (dateAxis[0] as string).includes(valuesArray[0]),
        );

        return dateAxis.reduce((prev, value, index) => {
          if (!valuesArray) return null;
          if (index === 0) {
            axisLabels.push(valuesArray[0] as string);
            return prev;
          }
          return { ...prev, [value]: valuesArray[index] };
        }, {});
      })
      .filter((valueArray) => !!valueArray)
      .forEach((it) => exportableData.push(it));
    const allDates = uniq([].concat(...exportableData.map(Object.keys))).sort();
    const result: string[][] = allDates.map((date) =>
      exportableData.map((chartData) => chartData[date] ?? VALUE_NOT_PRESENT),
    );

    valuesToExport.push([dates[0][0]].concat(allDates));
    axisLabels.forEach((label: string, index: number) =>
      valuesToExport.push([label].concat(result.map((it) => it[index]))),
    );
    return valuesToExport;
  }

  private numberFormat(value: string) {
    return this.numberFormatter
      ? this.numberFormatter(value)
      : this.localisedMoneyPipe.transform(value, this.currency);
  }

  private dateFormat(value: string) {
    return this.dateFormatter ? this.dateFormatter(value) : this.localisedDatePipe.transform(value);
  }

  private extractSvgNodeFromComponent(chartComponent: ExportableChart) {
    return chartComponent
      .getChartContainer()
      .nativeElement.getElementsByTagName('svg')[0]
      .cloneNode(true);
  }

  private getSvgChartData(
    translateService: TranslateService,
    svgNode: Node,
    svgTitle: string,
  ): SvgChartData {
    return {
      title: svgTitle
        ? svgTitle
        : translateService.instant(this.chartComponent.getChartDataForExport().getChartName()),
      svgData: svgNode,
    };
  }
}
