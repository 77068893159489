import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { AnalyticsRequest } from '@demica/resources/analytics';

export interface AnalyticsLocalStorageService {
  initialize(): Observable<boolean>;

  get(key: string): Observable<AnalyticsRequest>;

  add(payload: AnalyticsRequest): Observable<IDBValidKey>;

  delete(key: string): Observable<unknown>;

  getAll(): Observable<unknown>;

  isAvailable(): boolean;
}

export const ANALYTICS_STORE = new InjectionToken<AnalyticsLocalStorageService>(
  'Analytics Local Data Storage',
);
