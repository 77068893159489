import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[trfKeyboardAccessibility]',
})
export class KeyboardAccessibilityDirective implements OnChanges {
  @Input()
  disableAccessibility!: boolean;

  constructor(private renderer: Renderer2, private elem: ElementRef) {}

  ngOnChanges(): void {
    const ref = this.elem;
    const inputs: HTMLElement[] = ref.nativeElement.getElementsByTagName('input');
    const checkboxes: HTMLElement[] = ref.nativeElement.getElementsByClassName('check-frame');
    const selects: HTMLElement[] = ref.nativeElement.getElementsByClassName('ng-select');

    const array = [...inputs, ...checkboxes, ...selects];

    array.forEach((el) =>
      this.renderer.setAttribute(el, 'tabindex', this.disableAccessibility ? '-1' : '0'),
    );
  }
}
