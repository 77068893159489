<ng-container [formGroup]="group">
  <div data-testid="radio-button-component">
    <input
      id="{{ name + '-' + hash }}"
      class="form-radio-button-input radio-button-input {{ inputClasses }}"
      type="radio"
      formControlName="{{ name }}"
      [attr.disabled]="disabled ? 'disabled' : null"
      [attr.name]="name"
      [attr.checked]="checked ? 'true' : null"
      [value]="value"
    />

    <label
      class="form-radio-button-label radio-button-label {{ labelClasses }}"
      for="{{ name + '-' + hash }}"
      [attr.id]="labelId"
      [ngClass]="{ 'radio-button-disabled': disabled }"
      (click)="toggleRadio(true)"
      >{{ label | translate }}</label
    >

    <ng-content></ng-content>
  </div>
</ng-container>
