export const ANALYSIS_CODE_GROUP = {
  GROUP_NAME: 'analysisCodeGroup',
  ANALYSIS_CODE_FIELD: 'analysisCode',
  ANALYSIS_CODE_ID: 'analysisCodeId',
  ENTER_ANALYSIS_CODE_FIELD: 'enterAnalysisCode',
};

export const AUTO_CREATE_ALIASES_GROUP = {
  GROUP_NAME: 'autoCreateAliasesGroup',
  AUTO_CREATE_ALIASES_FIELD: 'autoCreateAliases',
};

export const COUNTERPARTY_COUNTRY_GROUP = {
  GROUP_NAME: 'counterpartyCountryGroup',
  FIELD: 'counterpartyCountry',
  ENTER_ANALYSIS_CODE_FIELD: 'enterAnalysisCode',
  TRANSLATION_PREFIX: 'COUNTRIES',
};
