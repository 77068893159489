<ng-container *ngIf="!loading">
  <div
    *ngIf="!!riskCount"
    id="at-risk"
    class="funding-alert-status at-risk"
    [attr.aria-label]="('HEADER.FUNDING_ALERTS_AT_RISK' | translate) + riskCount | max: COUNTER_MAX"
    [attr.title]="'HEADER.FUNDING_ALERTS_AT_RISK' | translate"
  >
    <span>
      {{ riskCount | max: COUNTER_MAX }}
      <ng-container *ngIf="riskCount > COUNTER_MAX">+</ng-container>
    </span>
  </div>

  <div
    *ngIf="!!riskWarningCount"
    id="at-risk-warning"
    class="funding-alert-status at-risk warning"
    [attr.aria-label]="
      ('HEADER.FUNDING_ALERTS_AT_RISK_WARNING' | translate) + riskWarningCount | max: COUNTER_MAX
    "
    [attr.title]="'HEADER.FUNDING_ALERTS_AT_RISK_WARNING' | translate"
  >
    <span>
      {{ riskWarningCount | max: COUNTER_MAX }}
      <ng-container *ngIf="riskWarningCount > COUNTER_MAX">+</ng-container>
    </span>
  </div>

  <div
    *ngIf="!!watchCount"
    id="watch-list"
    class="funding-alert-status watch-list"
    [attr.aria-label]="
      ('HEADER.FUNDING_ALERTS_WATCH_LIST' | translate) + watchCount | max: COUNTER_MAX
    "
    [attr.title]="'HEADER.FUNDING_ALERTS_WATCH_LIST' | translate"
  >
    <span>
      {{ watchCount | max: COUNTER_MAX }}
      <ng-container *ngIf="watchCount > COUNTER_MAX">+</ng-container>
    </span>
  </div>

  <div
    *ngIf="!!watchWarningCount"
    id="watch-list-warning"
    class="funding-alert-status watch-list warning"
    [attr.aria-label]="
      ('HEADER.FUNDING_ALERTS_WATCH_LIST_WARNING' | translate) + watchWarningCount
        | max: COUNTER_MAX
    "
    [attr.title]="'HEADER.FUNDING_ALERTS_WATCH_LIST_WARNING' | translate"
  >
    <span>
      {{ watchWarningCount | max: COUNTER_MAX }}
      <ng-container *ngIf="watchWarningCount > COUNTER_MAX">+</ng-container>
    </span>
  </div>

  <div
    *ngIf="!!performingCount"
    id="performing"
    class="funding-alert-status performing"
    [attr.aria-label]="
      ('HEADER.FUNDING_ALERTS_PERFORMING' | translate) + performingCount | max: COUNTER_MAX
    "
    [attr.title]="'HEADER.FUNDING_ALERTS_PERFORMING' | translate"
  >
    <span>
      {{ performingCount | max: COUNTER_MAX }}
      <ng-container *ngIf="performingCount > COUNTER_MAX">+</ng-container>
    </span>
  </div>
</ng-container>
