import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { getDocumentDir } from '@demica/ui-core-api';

@Component({
  selector: 'ngp-tooltip-icon',
  templateUrl: 'tooltip-icon.component.html',
  styleUrls: ['./tooltip-icon.component.sass'],
})
export class TooltipIconComponent {
  @Input()
  tooltipKey!: string;
  @Input()
  tooltipTestId!: string;
  @Input()
  reversed = false;
  @Input()
  showOnTop = false;
  @Input()
  iconName = 'info';
  @Input()
  iconClasses = '';
  @Input()
  iconSymbol?: string;
  @Input()
  iconAriaDesc?: string;
  @ViewChild('tooltipIconContainer')
  tooltipIconContainer!: ElementRef;

  getCurrentDir() {
    if (this.showOnTop) {
      return 'top';
    }
    return getDocumentDir() === 'rtl'
      ? this.reversed
        ? 'right'
        : 'left'
      : this.reversed
      ? 'left'
      : 'right';
  }

  activateTooltipInScreenReader(e: Event) {
    this.tooltipIconContainer?.nativeElement?.focus();
    e.stopPropagation();
    e.preventDefault();
  }

  deactivateTooltipInScreenReader(e: Event, element: HTMLElement) {
    this.tooltipIconContainer?.nativeElement?.blur();
    element?.focus();
    e.stopPropagation();
    e.preventDefault();
  }
}
