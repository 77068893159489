import { Pipe, PipeTransform } from '@angular/core';

const KILO_BYTES = 1024;
const MEGA_BYTES = 1048576;
const GIGA_BYTES = 1073741824;

@Pipe({
  name: 'bytePipe',
})
export class BytePipe implements PipeTransform {
  transform(value: number): string {
    const formatValue = (byteNumber: number, pow: number, suffix: string) =>
      `${Math.round(byteNumber / Math.pow(KILO_BYTES, pow))} ${suffix}`;

    if (value < KILO_BYTES) return formatValue(value, 0, 'B');

    if (value < MEGA_BYTES) return formatValue(value, 1, 'KB');

    if (value < GIGA_BYTES) return formatValue(value, 2, 'MB');

    return formatValue(value, 3, 'GB');
  }
}
