import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EntityReference, maxUserDefinedColumnLength } from '@demica/core/core';

import { AliasesSearchClass } from '../../aliases-search.class';

export type AliasesAwaitingApprovalSearchForm = FormGroup<{
  transaction: FormControl<EntityReference | null>;
  proposedAnalysisCodeValue: FormControl<string>;
  analysisCode: FormControl<string>;
  dataSource: FormControl<EntityReference>;
  analysisGroup: FormControl<EntityReference>;
  environment: FormControl<EntityReference>;
}>;

export type AliasesAwaitingApprovalSearchFormValue = AliasesAwaitingApprovalSearchForm['value'];

@Component({
  selector: 'trf-aliases-awaiting-approval-search',
  templateUrl: 'aliases-awaiting-approval-search.component.html',
  styleUrls: ['aliases-awaiting-approval-search.component.sass'],
})
export class AliasesAwaitingApprovalSearchComponent
  extends AliasesSearchClass
  implements OnInit, OnDestroy
{
  @Output()
  searchChange = new EventEmitter<AliasesAwaitingApprovalSearchFormValue>();

  @Output()
  clearChange = new EventEmitter<void>();

  @Output()
  filterChange = new EventEmitter<void>();

  form: AliasesAwaitingApprovalSearchForm = this._fb.group({
    transaction: [null],
    proposedAnalysisCodeValue: [null],
    analysisCode: [null],
    dataSource: [null],
    analysisGroup: [null],
    environment: [null],
  });

  readonly maxLength = maxUserDefinedColumnLength;
  private _destroyed$ = new Subject<void>();

  constructor(private _fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.loadDefaultFilters();

    this.form.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.filterChange.emit());

    this.form.controls.transaction.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((transaction) => {
        if (!transaction) {
          this.loadDefaultFilters();
        } else {
          this.filterByTransaction(transaction);
        }
        this.form.controls.dataSource.setValue(null);
        this.form.controls.analysisGroup.setValue(null);
        this.form.controls.environment.setValue(null);
      });
  }

  onSearch(): void {
    this.searchChange.emit(this.form.getRawValue());
  }

  onClear(): void {
    this.form.reset();
    this.clearChange.emit();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
