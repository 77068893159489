<trf-modal-container
  headerText="PUSH_REQUEST_REVIEW_MODAL.TITLE"
  (modalClose)="onClose()"
  testid="push-request-review-modal"
>
  <div class="trf-section-content mt-3">
    <div
      *ngIf="displayAlreadyReviewedWarning"
      class="alert alert-warning"
      role="alert"
    >
      <p>{{ "PUSH_REQUEST_REVIEW_MODAL.ALREADY_REVIEWED_ALERT" | translate }}</p>
    </div>

    <ngp-inline-loader [loading]="loading"></ngp-inline-loader>

    <form
      *ngIf="!loading"
      [formGroup]="form"
      [ngClass]="{ 'trf-form-submitted': submitted }"
    >
      <div class="trf-form-fields">
        <form
          *ngIf="form.get('approveVersions')"
          class="related-versions"
          [formGroup]="form.get('approveVersions')"
        >
          <div
            *ngFor="let relatedVersion of relatedVersions"
            [attr.testid]="'version-' + relatedVersion.subjectVersionId"
            [attr.datasubjecttype]="relatedVersion.subjectType"
          >
            <span
              class="trf-link"
              (click)="onVersionClick(relatedVersion)"
              >{{ relatedVersion.formLabel }}</span
            >
            <div class="radio-button-group">
              <ngp-radio-button
                label="PUSH_REQUEST_REVIEW_MODAL.APPROVE_VERSION"
                value="approve"
                [name]="relatedVersion.subjectVersionId"
              ></ngp-radio-button>

              <ngp-radio-button
                label="PUSH_REQUEST_REVIEW_MODAL.REJECT_VERSION"
                value="reject"
                [name]="relatedVersion.subjectVersionId"
              ></ngp-radio-button>
            </div>
          </div>
        </form>

        <div class="push-to-environment">
          {{ "PUSH_REQUEST_REVIEW_MODAL.PUSH_TO_ENVIRONMENT" | translate }}
          <span class="bold">{{ pushRequest.environment.name }}</span>
        </div>

        <div class="radio-button-group">
          <ngp-radio-button
            name="approvePush"
            label="PUSH_REQUEST_REVIEW_MODAL.APPROVE_PUSH_REQUEST"
            value="approve"
            testid="approve-push-request"
          ></ngp-radio-button>

          <ngp-radio-button
            name="approvePush"
            label="PUSH_REQUEST_REVIEW_MODAL.REJECT_PUSH_REQUEST"
            value="reject"
            testid="reject-push-request"
          ></ngp-radio-button>
        </div>
        <div class="comment">
          <trf-form-text-area
            name="comment"
            label="PUSH_REQUEST_REVIEW_MODAL.COMMENT"
            testid="push-request-review-comment"
          ></trf-form-text-area>
        </div>
      </div>
    </form>
  </div>

  <div class="trf-modal-controls-divider py-3 trf-section-buttons space-between">
    <ngp-button
      classes="tertiary"
      key="PUSH_REQUEST_REVIEW_MODAL.BUTTON_CANCEL"
      (click)="onClose()"
      testid="push-request-review-modal-cancel"
    ></ngp-button>

    <ngp-button
      classes="primary {{ loading ? 'inactive' : '' }}"
      key="PUSH_REQUEST_REVIEW_MODAL.BUTTON_PUSH"
      (click)="onSave()"
      testid="push-request-review-modal-save"
    ></ngp-button>
  </div>
</trf-modal-container>
