import { Injectable } from '@angular/core';

import { ConstructorOf } from '../../ts/type-system';
import { Modal } from '../modal-wrapper';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

const defaultOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  windowClass: 'trf-slidein',
};

/**
 * Due to hierarchical nature of dependency injection in Angular the NgbModal service will
 * receive ComponentFactoryResolver instance that will have registered only components from the chunk
 * where this service (SlideinService) is provided in + the components in the main chunk (shared module).
 */
@Injectable()
export class SlideinService {
  constructor(private modalService: NgbModal) {}

  openSlideIn<T>(component: ConstructorOf<T>, options?: NgbModalOptions): Modal<T> {
    const ref = this.modalService.open(component, { ...defaultOptions, ...options });
    return new Modal(ref);
  }
}
