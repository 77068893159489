export interface DndInputSourceData {
  maxBusinessLevel?: number;
  maxNestingLevel?: number;
  columnWidths?: string;
  levelWidth?: string;
  containerWidth?: string;
  maxLevelReachedTranslation?: string;
  emptyMsg?: string;
  tableLabel?: string;
}

export interface DndViewData {
  maxBusinessLevel: number;
  maxNestingLevel: number;
  columnWidths: string | null;
  levelWidthNumber: number;
  containerWidthNumber: number;
  maxLevelReachedTranslation: string;
  emptyMsg: string;
}

export interface DndNestedElement {
  id: string | number;
  uniqueId: string;
  name?: string;
  children: DndNestedElement[];
}

export interface DndNestedRowComponent<T> {
  row: T;
  parentName: string | undefined;
}

export interface DndColumnDefinition {
  nameKey: string;
  classes?: string;
}

export interface DndComponentConfiguration {
  component: any;
  inputs?: any;
}

export interface DnDRowDefinition {
  name: string;
  code: string;
  discountType: string;
  reference?: string;
  classes?: string;
}

export interface DndOutputData<T, K> {
  data: T[];
  itemId: string | number;
  containerName: K;
}

export interface DndOutputMovedData<K> {
  containerName: K;
  previousIndex: number;
  currentIndex: number;
}

export const defaultMaxBusinessLevel = 2;
export const defaultMaxNestingLevel = 8;
export const defaultLevelWidth = 40;

// from variable $slidein-first in pricing-slidein-variable.sass
// minus 100px for close button and inline padding
export const defaultContainerWidth = 900;
