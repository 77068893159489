import { Component, Input } from '@angular/core';

import { Preview } from '@demica/core/core';

@Component({
  selector: 'trf-contact-details',
  templateUrl: 'contact-details.component.html',
  styleUrls: ['contact-details.component.sass'],
})
export class ContactDetailsComponent {
  @Input()
  preview?: Preview;
}
