import { buildMessages } from '../../forms/validation-messages/message-builder';
import {
  msgDefaultMaxLength,
  msgRequired,
} from '../../forms/validation-messages/message-definitions';
import {
  hasMaxLengthError,
  hasNameUnavailableError,
} from '../../forms/validation-messages/message-predicates';

export const maxDescriptionLength = 2000;

export const versionNameValidations = buildMessages([
  msgRequired,
  msgDefaultMaxLength,
  { func: hasNameUnavailableError, key: 'VALIDATION.NAME_NOT_UNIQUE' },
]);

export const versionDescriptionValidations = buildMessages([
  {
    func: hasMaxLengthError,
    key: 'VALIDATION.MAX_TEXT_FIELD_LENGTH',
    params: { characters: maxDescriptionLength },
  },
]);
