export enum SellerFormFields {
  clientName = 'clientName',
  name = 'name',
  code = 'code',
  contactPersonFirstName = 'contactPersonFirstName',
  contactPersonLastName = 'contactPersonLastName',
  address1 = 'address1',
  address2 = 'address2',
  address3 = 'address3',
  address4 = 'address4',
  country = 'country',
  telephoneCountryCode = 'telephoneCountryCode',
  telephoneNumber = 'telephoneNumber',
  email = 'email',
  minimalPartialPortionThreshold = 'minimalPartialPortionThreshold',
}
