import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ColumnToggleStateService } from '../component/data-table/column-toggle-state.service';

@Directive({
  selector: '[trfIfColumnToggled]',
})
export class IfColumnToggledDirective implements OnInit, OnDestroy {
  @Input()
  trfIfColumnToggled: string;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private templateRef: TemplateRef<unknown>,
    private view: ViewContainerRef,
    @Optional() private toggleState: ColumnToggleStateService,
  ) {}

  ngOnInit(): void {
    if (!this.toggleState) {
      this.showContent();
      return;
    }
    this.toggleState
      .getVisibleColumns$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((columns) => {
        if (columns.find((column) => column.nameKey === this.trfIfColumnToggled)) {
          this.showContent();
        } else {
          this.view.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private showContent(): void {
    this.view.clear();
    this.view.createEmbeddedView(this.templateRef);
  }
}
