import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ClientIdForAnalyticsService,
  SessionIdForAnalyticsService,
  SystemUserIdService,
} from '@demica/user-identification';
import { getLocationUrl } from '@demica/utils';

import {
  LoggerService,
  getLogLevelText,
  LoggerMetadata,
  LogLevel,
  LogMessage,
} from '../../logger.service';
import { BrowserInfoService } from '../browser-info/browser-info.service';
import { LogsAggregatorService } from '../logs-aggregator/logs-aggregator.service';
import { RemoteLoggerService } from '../remote-logger/remote-logger.service';
import { UserActivityAuditService } from '../user-activity-audit/user-activity-audit.service';

import { LogRecord } from '../../log-record.interface';

@Injectable({
  providedIn: 'root',
})
export class DefaultLoggerService extends LoggerService {
  private _destroyed = new Subject<void>();

  constructor(
    private _browserInfo: BrowserInfoService,
    private _userActivityService: UserActivityAuditService,
    private _logsAggregatorService: LogsAggregatorService,
    private _remoteLoggerService: RemoteLoggerService,
    private _systemUserIdService: SystemUserIdService,
    private _sessionIdForAnalyticsService: SessionIdForAnalyticsService,
    private _clientIdForAnalyticsService: ClientIdForAnalyticsService,
  ) {
    super();

    this._logsAggregatorService
      .getAggregatedLogs()
      .pipe(takeUntil(this._destroyed))
      .subscribe((logs) => {
        this._remoteLoggerService.log(logs);
      });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
  }

  override addLog(message: LogMessage | string, level: LogLevel = LogLevel.INFO, stack = ''): void {
    const textMessage = this.formatMessage(message);
    this._validateLog(textMessage, level);

    this._logsAggregatorService.addLog({
      message: textMessage,
      level: getLogLevelText(level),
      stack,
      ...this.addMetadata(),
    });
  }

  override addConsoleLog(message: LogMessage | string, level: LogLevel = LogLevel.INFO): void {
    const textMessage = this.formatMessage(message);
    this._validateLog(textMessage, level, true);
  }

  override addInstantLog(message: LogMessage | string, level: LogLevel = LogLevel.INFO): void {
    const textMessage = this.formatMessage(message);
    this._validateLog(textMessage, level);

    this._remoteLoggerService.log<LogRecord[]>([
      {
        message: textMessage,
        level: getLogLevelText(level),
        ...this.addMetadata(),
      },
    ]);
  }

  override addError(message: LogMessage | string, stack = ''): void {
    const textMessage = this.formatMessage(message);
    this._validateLog(textMessage, LogLevel.ERROR);

    this._logsAggregatorService.addLog({
      message: textMessage,
      level: getLogLevelText(LogLevel.ERROR),
      stack,
      browserInfo: this._browserInfo.getBrowserInfo(),
      userActivities: this._userActivityService.getActivities(),
      ...this.addMetadata(),
    });
  }

  protected override addMetadata(): LoggerMetadata {
    return {
      source: 'trf-ui-frontend',
      url: getLocationUrl(),
      sessionId: this._sessionIdForAnalyticsService.getId(),
      userId: this._clientIdForAnalyticsService.getId(),
      trfUserId: this._systemUserIdService.getId(),
    };
  }

  private _validateLog(message: string, level: LogLevel, forceConsoleOutput = false) {
    if (!this.isLevelValid(level)) return;

    if (this.consoleEnabled || forceConsoleOutput) {
      console.log(`(${getLogLevelText(level)}) ${message}`);
    }
  }
}
