<trf-slidein-container
  headerText="SELLER_BANK_ACCOUNT.ADD_ACCOUNT_HEADER"
  (slideinClose)="onClose()"
  testid="opco-bank-account-slidein"
>
  <div class="trf-section-content">
    <trf-seller-bank-account
      [bankAccountData]="bankAccountData"
      [submitted]="submitted"
      (dataChanged)="bankAccountDetailsChanged($event)"
    >
    </trf-seller-bank-account>
  </div>

  <div class="trf-slidein-footer">
    <trf-button-controls
      positiveName="SELLER_BANK_ACCOUNT.BUTTON_SAVE"
      negativeName="SELLER_BANK_ACCOUNT.BUTTON_CLOSE"
      (positiveEvent)="onSave()"
      (negativeEvent)="onClose()"
    >
    </trf-button-controls>
  </div>
</trf-slidein-container>
