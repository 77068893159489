import { Component, Inject, Input, Optional } from '@angular/core';

import { IconName } from '@demica/ui-core-api';

import { SVG_ICON_HREF } from '../../tokens';

@Component({
  selector: 'ngp-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.sass'],
})
export class SvgIconComponent {
  @Input()
  iconName!: IconName;

  constructor(@Optional() @Inject(SVG_ICON_HREF) public spriteFile: string) {}
}
