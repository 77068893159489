<ng-container [formGroup]="group">
  <div>
    <div
      *ngIf="!!label"
      class="column-label"
      [ngClass]="{ 'row-disabled': formControl.disabled }"
    >
      <label
        [for]="formControlName"
        [class]="labelClasses"
        >{{ label | translate }}</label
      >
    </div>

    <div class="column-input">
      <div class="input-group">
        <input
          class="form-control {{ inputClasses }}"
          type="text"
          placeholder="{{ placeholder | translate }}"
          trfDecimalFormatter
          [id]="formControlName"
          [formControl]="formControl"
          [formControlName]="formControlName"
          [readonly]="readonly"
          [attr.aria-required]="labelClasses?.includes('required')"
          [attr.aria-describedby]="formControlName + '_notification'"
          (keypress)="onKeypress($event)"
          (blur)="onBlur()"
          (decimalFormatted)="onDecimalFormatted($event)"
        />
      </div>
      <trf-form-number-row-default-validation-messages
        [validations]="validations"
        [name]="formControlName"
        [input]="formControl"
      ></trf-form-number-row-default-validation-messages>
    </div>
  </div>
</ng-container>
