<ng-container [formGroup]="group">
  <div data-testid="form-percentage-row">
    <div
      *ngIf="!!label"
      class="column-label"
      [ngClass]="{ 'row-disabled': formControl.disabled }"
    >
      <label
        [for]="formControlName"
        [class]="labelClasses"
        >{{ label | translate }}</label
      >
      <ngp-tooltip-icon
        *ngIf="helperToolTip"
        [tooltipKey]="helperToolTip"
      ></ngp-tooltip-icon>
    </div>

    <div class="column-input">
      <div class="input-group">
        <input
          class="form-control {{ inputClasses }}"
          type="number"
          placeholder="{{ placeholder | translate }}"
          trfDecimalFormatter
          [id]="formControlName"
          [formControl]="formControl"
          [readonly]="readonly"
          [attr.aria-required]="labelClasses?.includes('required')"
          [attr.aria-describedby]="formControlName + '_notification'"
          (keypress)="onKeypress($event)"
          (blur)="onBlur()"
          (decimalFormatted)="onDecimalFormatted($event)"
          data-testid="form-percentage-row-input"
        />
        <div
          *ngIf="showPercentLabel"
          class="input-group-append"
        >
          <span class="input-group-text"> % </span>
        </div>
      </div>
      <trf-form-number-row-default-validation-messages
        [validations]="validations"
        [name]="formControlName"
        [input]="formControl"
      >
        <ng-container #additionalValidationContainer></ng-container>
      </trf-form-number-row-default-validation-messages>
    </div>
  </div>
</ng-container>
