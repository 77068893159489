<td
  *ngIf="hasSelectAllCheckbox"
  #tdElement
  class="toggle-select"
  tabindex="-1"
  role="columnheader"
  [attr.aria-checked]="allSelected"
  [attr.aria-label]="
    (allSelected ? 'TABLE_TEXT_HEADER.UNSELECT_ALL' : 'TABLE_TEXT_HEADER.SELECT_ALL') | translate
  "
>
  <ngp-checkbox
    [checked]="allSelected"
    [indeterminate]="indeterminateSelected"
    (checkboxChecked)="onCheckboxClick()"
    testid="select-all"
  ></ngp-checkbox>
</td>
<td
  *ngFor="let column of columns"
  #tdElement
  tabindex="-1"
  role="columnheader"
  [attr.aria-label]="column.nameKey | translate"
  [attr.aria-sort]="prepareAriaSort(column)"
  [class]="classes(column)"
  (click)="onColumnClick(column)"
  (keyup.enter)="sortableElement.focus()"
  [attr.data-testid]="column.dataTestId"
>
  <span
    #sortableElement
    id="sortableElement"
    class="header-cell-wrapper"
    aria-labelledby="sortDirectionLabel"
    [attr.tabindex]="column.comparator ? '-1' : null"
    role="button"
    (keyup.enter)="onColumnClick(column)"
  >
    <ngp-svg-icon
      *ngIf="shouldShowAsc(column)"
      iconName="caret-up"
      class="sort-indicator"
    ></ngp-svg-icon>
    <ngp-svg-icon
      *ngIf="shouldShowDesc(column)"
      iconName="caret-down"
      class="sort-indicator"
    ></ngp-svg-icon>
    <ngp-svg-icon
      *ngIf="shouldShowInactiveArrow(column)"
      iconName="caret-down"
      class="inactive-icon"
    >
    </ngp-svg-icon>
    <span class="header-tooltip">
      <ngp-tooltip-icon
        *ngIf="column.tooltip"
        [tooltipKey]="column.tooltip"
        [attr.data-testid]="'column-header-tooltip-' + column.nameKey"
      ></ngp-tooltip-icon>
    </span>
    <span class="header-cell-text">{{ column.nameKey | translate }}</span>
  </span>
  <span
    id="sortDirectionLabel"
    class="sr-only"
    aria-live="polite"
    >{{ prepareAriaSort(column) }}</span
  >
</td>
