import { Observable, of } from 'rxjs';

import {
  RestResponsePaginationMetadata as PageResponseMeta,
  CustomRestResponseWithMetadata as CustomPageRestResponse,
  EmptyPaginationMetadata,
  PaginationMetadata,
} from '@demica/resources/common';

import { TransactionClientOverview } from '../_domain/transaction/organisation/model/transaction-client-overview.interface';

// TODO remove when re-exports are not needed anymore
export {
  PaginationParams as PageRequest,
  CustomRestResponseWithMetadata as CustomPageRestResponse,
  defaultEmptyResponse as defaultResponse,
  createPaginationParams,
  createSortParams,
} from '@demica/resources/common';

// TODO find a way to deal with this and remove when re-exports are not needed anymore
export type PageResponse = EmptyPaginationMetadata | PaginationMetadata;

export interface TransactionMeta extends PageResponseMeta {
  transaction?: TransactionMetadata;
}

export interface TransactionMetadata {
  transactionAmount: number;
}

export function defaultTransactionCustomResponse(): Observable<
  CustomPageRestResponse<TransactionClientOverview, TransactionMeta>
> {
  return of({
    data: [],
    meta: {
      pagination: {},
      transaction: {
        transactionAmount: 0,
      },
    },
  } as CustomPageRestResponse<TransactionClientOverview, TransactionMeta>);
}
