import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { BodyOutputType } from '../../model/body-output-type.model';
import { Toast, ToastClickEvent } from '../../model/toast.model';
import { ToasterConfig } from '../../toaster-config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[toasterComp]',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('componentBody', { read: ViewContainerRef, static: false })
  componentBody!: ViewContainerRef;

  @Input()
  toasterConfig!: ToasterConfig;
  @Input()
  toast!: Toast;
  @Input()
  titleClass = '';
  @Input()
  messageClass = '';

  @Output()
  clickEvent = new EventEmitter<ToastClickEvent>();
  @Output()
  removeToastEvent = new EventEmitter<Toast>();

  get timeoutId(): number | null {
    return this._timeoutId;
  }
  get timeout(): number | null {
    return this._timeout;
  }
  get progressBarIntervalId(): number | null {
    return this._progressBarIntervalId;
  }

  progressBarWidth = -1;
  bodyOutputType = BodyOutputType;
  removeToastTick!: number;

  private _timeout = 0;
  private _timeoutId: number | null = null;
  private _progressBarIntervalId: number | null = null;

  private _removeMouseOverListener!: () => void;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _ngZone: NgZone,
    private _element: ElementRef,
    private _renderer2: Renderer2,
  ) {}

  ngOnInit() {
    if (this.toast.progressBar) {
      this.toast.progressBarDirection = this.toast.progressBarDirection || 'decreasing';
    }

    let timeout =
      typeof this.toast.timeout === 'number' ? this.toast.timeout : this.toasterConfig.timeout;

    if (typeof timeout === 'object') {
      timeout = timeout[this.toast.type] as number;
    }
    this._timeout = timeout as number;
  }

  ngAfterViewInit() {
    if (this.toast.bodyOutputType === this.bodyOutputType.Component) {
      const componentInstance: ComponentRef<{ toast: Toast }> = this.componentBody.createComponent(
        this.toast.body,
        {
          index: undefined,
          injector: this.componentBody.injector,
        },
      );
      componentInstance.instance.toast = this.toast;
      this._cdr.detectChanges();
    }

    if (this.toasterConfig.mouseoverTimerStop) {
      // only apply a mouseenter event when necessary to avoid
      // unnecessary event and change detection cycles.
      this._removeMouseOverListener = this._renderer2.listen(
        this._element.nativeElement,
        'mouseenter',
        () => this.stopTimer(),
      );
    }

    this.configureTimer();
  }

  ngOnDestroy() {
    if (this._removeMouseOverListener) {
      this._removeMouseOverListener();
    }
    this.clearTimers();
  }

  @HostListener('mouseleave')
  restartTimer() {
    if (this.toasterConfig.mouseoverTimerStop) {
      if (!this.timeoutId) {
        this.configureTimer();
      }
    } else if (this._timeout && !this.timeoutId) {
      this.removeToast();
    }
  }

  click(event: MouseEvent, toast: Toast) {
    event.stopPropagation();
    this.clickEvent.emit({ value: { toast: toast, isCloseButton: true } });
  }

  stopTimer() {
    this.progressBarWidth = 0;
    this.clearTimers();
  }

  configureTimer() {
    if (!this._timeout || this._timeout < 1) {
      return;
    }

    if (this.toast.progressBar) {
      this.removeToastTick = new Date().getTime() + this._timeout;
      this.progressBarWidth = -1;
    }

    this._ngZone.runOutsideAngular(() => {
      this._timeoutId = window.setTimeout(() => {
        this._ngZone.run(() => {
          this._cdr.markForCheck();
          this.removeToast();
        });
      }, this._timeout);

      if (this.toast.progressBar) {
        this._progressBarIntervalId = window.setInterval(() => {
          this._ngZone.run(() => {
            this.updateProgressBar();
          });
        }, 10);
      }
    });
  }

  updateProgressBar() {
    if (this.progressBarWidth === 0 || this.progressBarWidth === 100) {
      return;
    }

    this.progressBarWidth = ((this.removeToastTick - new Date().getTime()) / this._timeout) * 100;

    if (this.toast.progressBarDirection === 'increasing') {
      this.progressBarWidth = 100 - this.progressBarWidth;
    }
    if (this.progressBarWidth < 0) {
      this.progressBarWidth = 0;
    }
    if (this.progressBarWidth > 100) {
      this.progressBarWidth = 100;
    }
    this._cdr.markForCheck();
  }

  clearTimers() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    if (this._progressBarIntervalId) {
      window.clearInterval(this._progressBarIntervalId);
    }

    this._timeoutId = null;
    this._progressBarIntervalId = null;
  }

  removeToast() {
    this.removeToastEvent.emit(this.toast);
  }
}
