import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, NgZone, Optional, SkipSelf } from '@angular/core';

import {
  AccessTokenExpirationTimerService,
  AppConfigService,
  AUTH_CONTEXT_EVENT_SOURCE,
  AUTH_INTERCEPTOR_RETRY_STRATEGY_TOKEN,
  AuthenticationFlowStore,
  AuthKeycloakService,
  AuthService,
  ExternalChangeEventSourceService,
  HttpAuthInterceptor,
  HybridFlowTokenStoreService,
  initializeAuthentication,
  InternalChangeEventSourceService,
  LogoutRedirectInitializerService,
  LogoutService,
  OidcAuthInterceptorRetryStrategy,
  OidcPasswordChangeService,
  PASSWORD_CHANGE_SERVICE_TOKEN,
  TokenStoreService,
} from '@demica/core/core';

import { APP_INITIALIZER_AFTER_CONFIG } from './token/app-initializer-after-config.token';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

@NgModule({
  imports: [KeycloakAngularModule],
  providers: [
    HybridFlowTokenStoreService,
    TokenStoreService,
    AuthKeycloakService,
    LogoutService,
    AuthService,
    {
      provide: APP_INITIALIZER_AFTER_CONFIG,
      useFactory: initializeAuthentication,
      multi: true,
      deps: [
        KeycloakService,
        AppConfigService,
        TokenStoreService,
        AuthenticationFlowStore,
        AuthService,
        AuthKeycloakService,
        HybridFlowTokenStoreService,
        NgZone,
        LogoutRedirectInitializerService,
        AccessTokenExpirationTimerService,
      ],
    },
    {
      provide: PASSWORD_CHANGE_SERVICE_TOKEN,
      useClass: OidcPasswordChangeService,
    },
    {
      provide: AUTH_INTERCEPTOR_RETRY_STRATEGY_TOKEN,
      useClass: OidcAuthInterceptorRetryStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true,
    },
    {
      provide: AUTH_CONTEXT_EVENT_SOURCE,
      useExisting: ExternalChangeEventSourceService,
      multi: true,
    },
    {
      provide: AUTH_CONTEXT_EVENT_SOURCE,
      useExisting: InternalChangeEventSourceService,
      multi: true,
    },
  ],
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }
}
