import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, UntypedFormGroup } from '@angular/forms';

import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  errorNotification,
  isEndTimeAfterHours,
  NotificationEntry,
  Notifications,
} from '@demica/core/core';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';

type SearchFunction = (term: string, item: unknown) => boolean;

@Component({
  selector: 'trf-form-timepicker-start-end-row',
  templateUrl: './form-timepicker-start-end-row.component.html',
  styleUrls: ['./form-timepicker-start-end-row.component.sass'],
})
export class FormTimepickerStartEndRowComponent implements OnInit, OnDestroy {
  @Input()
  startControl: FormControl<string>;
  @Input()
  endControl: FormControl<string>;
  @Input()
  labelStart: string;
  @Input()
  labelEnd: string;
  @Input()
  minHoursAfter = 2;
  @Input()
  searchFn: SearchFunction = null;
  @Input()
  disabled: boolean;
  @Input()
  clearable = true;
  @Input()
  searchable = false;
  @Input()
  defaultLabelStart?: string;
  @Input()
  defaultLabelEnd?: string;
  @Input()
  labelClasses?: string;
  @Input()
  translationPrefix?: string;
  @Input()
  validationsStart?: ValidationMessage[];
  @Input()
  validationsEnd?: ValidationMessage[];

  showError = false;
  group: UntypedFormGroup;
  destroySubject = new Subject<void>();

  options: string[];

  notifications = new Notifications();

  private endTimeTooSoon = errorNotification(`ERRORS.END_TIME_MUST_BE_AFTER_N_HOURS`, {
    hours: String(this.minHoursAfter),
  });
  private destroyed$ = new Subject<void>();

  constructor(private fdg: FormGroupDirective) {}

  ngOnInit(): void {
    this.group = this.fdg.form;
    this.options = this.prepareOptions();

    combineLatest([this.startControl?.valueChanges, this.endControl?.valueChanges])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([timeStartValue, timeEndValue]) => {
        if (timeStartValue && timeEndValue) {
          if (!isEndTimeAfterHours(timeStartValue, timeEndValue, this.minHoursAfter)) {
            this.showGroupFormError(this.endTimeTooSoon);
          } else {
            this.hideGroupFormError();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private prepareOptions(): string[] {
    const options = [];

    for (let i = 0; i < 48; i++) {
      options.push(`${i / 2 < 10 ? 0 : ''}${Math.trunc(i / 2)}:${i % 2 === 0 ? 0 : 3}0`);
    }

    return options;
  }

  private showGroupFormError(error?: NotificationEntry) {
    this.notifications.add(error);

    this.startControl.setErrors({ error: true });
    this.endControl.setErrors({ error: true });
  }

  private hideGroupFormError() {
    this.notifications.clear();

    this.startControl.setErrors(null);
    this.endControl.setErrors(null);
  }
}
