import { AwaitingApprovalSearchParams } from '@demica/core/core';
import { EntityId } from '@demica/resources/common';

import { AliasesAwaitingApprovalSearchFormValue } from '../aliases-awaiting-approval-search/aliases-awaiting-approval-search.component';

export function defaultSearchParams(transactionIds: EntityId[]): AwaitingApprovalSearchParams {
  return mapFormToSearchParams(transactionIds ?? [], null);
}

export function mapFormToSearchParams(
  transactionIds: EntityId[],
  formSearchParams: AliasesAwaitingApprovalSearchFormValue,
): AwaitingApprovalSearchParams {
  return {
    transactionIds:
      formSearchParams && !!formSearchParams.transaction
        ? [formSearchParams.transaction.entityId]
        : transactionIds,
    analysisGroupName: formSearchParams?.analysisGroup?.name || null,
    dataSourceId: formSearchParams?.dataSource?.entityId || null,
    analysisCode: formSearchParams?.analysisCode || null,
    environmentId: formSearchParams?.environment?.entityId || null,
    proposedAnalysisCodeValue: formSearchParams?.proposedAnalysisCodeValue || null,
  };
}
