import { TranslateService } from '@ngx-translate/core';

export abstract class PageableFileName {
  constructor(private translate: TranslateService) {}

  abstract getValidFileName(shortName: string, translationParams?: string[]): string;

  protected createFileName(name: string, translationParams?: string[]): string {
    let params = '';
    translationParams?.forEach((param) => {
      params = `${params}_${param}`;
    });
    return params
      ? this.translate.instant(name, {
          params: params,
        })
      : this.translate.instant(name);
  }
}

export class PdfPageableFileName extends PageableFileName {
  getValidFileName(shortName: string, translationParams?: string[]): string {
    const fileName = this.createFileName(shortName, translationParams);
    return `${fileName}.pdf`;
  }
}

export class XlsxPageableFileName extends PageableFileName {
  getValidFileName(shortName: string, translationParams?: string[]): string {
    const fileName = this.createFileName(shortName, translationParams);
    return `${fileName}.xlsx`;
  }
}

export class ZipPageableFileName extends PageableFileName {
  getValidFileName(shortName: string, translationParams?: string[]): string {
    const fileName = this.createFileName(shortName, translationParams);
    return `${fileName}.zip`;
  }
}
