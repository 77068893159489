export enum UploadStatusFlag {
  READY_TO_UPLOAD = 'READY_TO_UPLOAD',
  UPLOADED = 'UPLOADED',
  FAILED = 'FAILED',
  FILE_PROVIDER_FILE_EXISTS = 'FILE_PROVIDER_FILE_EXISTS',
  FILE_PROVIDER_NAME_INVALID = 'FILE_PROVIDER_NAME_INVALID',
  FILE_PROVIDER_NAME_NOT_CORRESPONDING_TO_REQUESTED_FILE_FORMAT = 'FILE_PROVIDER_NAME_NOT_CORRESPONDING_TO_REQUESTED_FILE_FORMAT',
  FILE_CONTAINS_ERRORS_COLUMN = 'FILE_CONTAINS_ERRORS_COLUMN',
  FILE_FORMAT_CONFIGURATION_NOT_FOUND = 'FILE_FORMAT_CONFIGURATION_NOT_FOUND',
  FILE_NAME_DOES_NOT_MATCH_ANY_FILE_FORMAT = 'FILE_NAME_DOES_NOT_MATCH_ANY_FILE_FORMAT',
}
