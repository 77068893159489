import { Pipe, PipeTransform } from '@angular/core';

const ELLIPSIS = '...';
const THRESHOLD = 10;

const intelligentSlice = (text: string, textLength: number): string => {
  if (text.length <= textLength) {
    return text;
  }

  const basedTextSliced = text.slice(0, textLength);

  if (text.charAt(textLength) === ' ') {
    return basedTextSliced.trim() + ELLIPSIS;
  }

  const whenLastWordStarts = basedTextSliced.lastIndexOf(' ');
  const preLength = textLength - whenLastWordStarts;

  const lastWords = text.slice(textLength, textLength + THRESHOLD);
  const lastWordsArray = lastWords.split(' ');
  const lastWordString = lastWordsArray.shift();

  // slice text before last word when
  // 1. length to start of the word is smaller then to its end
  // 2. if the word is longer than threshold
  if ((lastWordString && preLength < lastWordString.length) || lastWordsArray.length === 1) {
    return basedTextSliced.slice(0, whenLastWordStarts) + ELLIPSIS;
  }

  return basedTextSliced + lastWordString + ELLIPSIS;
};

@Pipe({ name: 'textEllipsis' })
export class TextEllipsisPipe implements PipeTransform {
  transform(value: string | null, args: { textLength: number }): string | null {
    if (!value) {
      return null;
    }

    return intelligentSlice(value, args.textLength);
  }
}
