import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { LegendConfiguration, LocalisationService, ThemingService } from '@demica/core/core';

import { ChartExportService } from '../../chart-export/service/chart-export.service';
import { ChartGenerateService } from '../../service/chart-generate.service';

import { ExportableChart } from '../../model/chart-component.interface';

import { LocalisedNumberPipe } from '../../pipe/localised-number.pipe';
import { PercentOfPipe } from '../../pipe/percent-of.pipe';

import { ChartType } from '../../model/chart-type';
import { LineChartData } from '../line-chart/line-chart-data';
import {
  createLegendConfig,
  DEFAULT_PIE_LEGEND_CONFIG,
} from './legend/piechart-legend-configuration';
import { createPiechartLegendHandlers } from './legend/piechart-legend-interaction-handlers';
import { PieChartData } from './pie-chart-data';
import { PieChartPositioningController } from './pie-chart-positioning-controller';
import { ChartAPI } from 'c3';

const CHART_SELECTOR = '#pie-chart';

@Component({
  selector: 'trf-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.sass'],
})
export class PieChartComponent implements ExportableChart, OnInit, OnDestroy, OnChanges {
  @Input()
  chartData: PieChartData;

  @ViewChild('chartContainer', { static: true })
  chartContainer: ElementRef;

  private chart: ChartAPI;
  private subscriptions: Subscription[] = [];
  private percentOf = new PercentOfPipe();
  private localisedNumberPipe: LocalisedNumberPipe;
  private legendController: PieChartPositioningController;

  constructor(
    private chartExportService: ChartExportService,
    private localisationService: LocalisationService,
    private translateService: TranslateService,
    private chartConstantsService: ThemingService,
    private themeService: ThemingService,
    private chartGenerateService: ChartGenerateService,
  ) {
    this.localisedNumberPipe = new LocalisedNumberPipe(localisationService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.localisationService.locale.subscribe(() => {
        setTimeout(() => {
          this.updateSeriesNameWithPercentages(this.chartData);
          this.chart = this.createChart();

          const lastValue = this.getUnratedChartIdValue();

          if (this.chartData.isUnrated) {
            this.chart.load({
              columns: this.chartData.getChartValuesWithPercentage(),
              unload: [lastValue.name],
            });
          }
        });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.chart?.destroy();
    this.legendController = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.load({
        columns: changes.chartData.currentValue.getChartValues(),
        unload: changes.chartData.previousValue.getChartValueNames(),
      });
    }
  }

  getChartContainer(): ElementRef {
    return this.chartContainer;
  }

  getChartDataForExport(): LineChartData | PieChartData {
    return this.chartData;
  }

  getChartType(): ChartType {
    return ChartType.PIE;
  }

  private updateSeriesNameWithPercentages(chartData: PieChartData) {
    const total = chartData
      .getPieChartValue()
      .map((value) => value.getIntValue())
      .reduce((a, b) => a + b, 0);

    chartData.getPieChartValue().forEach((value) => {
      value.nameWithPercentage = this.getNameWithPercentage(value.name, value.getIntValue(), total);
      value.percentageValue = (value.getIntValue() * 100) / total;
    });

    if (this.chartData.isUnrated) {
      this.resolveUnratedData(total);
    }
  }

  private resolveUnratedData(total: number) {
    const unratedTranslation = this.translateService.instant(
      'DASHBOARD_PORTFOLIO_OVERVIEW.UNRATED',
    );
    const unratedList = this.chartData
      .getPieChartValue()
      .filter((c) => c.name === 'DASHBOARD_PORTFOLIO_OVERVIEW.UNRATED');
    let unrated = unratedList.length === 0 ? null : unratedList[0];
    if (!unrated) {
      unrated = this.chartData.getPieChartValue().pop();
      this.chartData.getPieChartValue().push(unrated);
    } else {
      unrated.name = unratedTranslation;
      unrated.nameWithPercentage = this.getNameWithPercentage(
        unratedTranslation,
        unrated.getIntValue(),
        total,
      );
    }
  }

  private getUnratedChartIdValue() {
    return this.chartData.getPieChartValue()[this.chartData.getPieChartValue().length - 1];
  }

  private getNameWithPercentage(name: string, value: number, total: number) {
    const percentageNumber = this.percentOf.transform(value, total, 2);
    return `${name} (${percentageNumber})`;
  }

  private createChart(): ChartAPI {
    return this.chartGenerateService.generate({
      bindto: CHART_SELECTOR,
      oninit: () => {
        const legendConfig = createLegendConfig(
          this.chartConstantsService.pieChartColors,
          {
            data: this.chartData,
            interactionHandlers: createPiechartLegendHandlers(() => this.chart),
          },
          this.getLegendConfig(),
        );

        this.legendController = new PieChartPositioningController(
          CHART_SELECTOR,
          this.getChartContainer().nativeElement,
        );
        this.legendController.appendLegend(legendConfig);
      },
      onrendered: () => this.legendController?.resize(),
      data: {
        columns: this.getChartValues(),
        type: ChartType.PIE,
      },
      legend: { show: false },
      interaction: {
        enabled: false,
      },
      color: {
        pattern: this.chartConstantsService.pieChartColors,
      },
      axis: {
        x: { show: false },
        y: { show: false },
      },
      pie: {
        expand: true,
        label: { show: false },
      },
    });
  }

  private getChartValues() {
    const chartValues = this.chartData.getChartValuesWithPercentage();
    if (chartValues)
      chartValues.forEach((value) => (value[0] = this.translateService.instant(value[0])));
    return chartValues;
  }

  private getLegendConfig(): LegendConfiguration {
    return {
      ...DEFAULT_PIE_LEGEND_CONFIG,
      textMarginLeft: this.themeService.chartPieLegendTextMarginLeft,
      iconSize: this.themeService.chartLegendIconSize,
      lineHeight: 1.6 * this.themeService.chartLegendIconSize,
      labelMarginTop: this.themeService.chartPieLegendTopMargin,
      align: this.themeService.charPieLegendAlign,
    };
  }
}
