import { Injectable } from '@angular/core';

import { HasEntityId } from '../../../../interface/has-entity-id.interface';
import { CurrencyCode } from '../../../../model/currency-code.interface';
import { replaceCommaWithDot } from '../../../../utils/string-utils';
import { ReserveFormModel } from '../model/reserve-form-model.interface';
import { ReserveMethod } from '../model/reserve-method.interface';
import { ReserveRequest } from '../model/reserve-request.interface';
import { ReserveType } from '../model/reserve-type.interface';
import { ReserveVariantID } from '../model/reserve-variant.interface';
import {
  Reserve,
  ReserveVariant1,
  ReserveVariant2,
  ReserveVariant3,
  ReserveVariant4,
  ReserveVariant5,
  ReserveVariant6,
  ReserveVariant7,
  ReserveVariantBase,
} from '../model/reserve.interface';

@Injectable({
  providedIn: 'root',
})
export class ReserveFormModelService {
  defaultModel(): ReserveFormModel {
    return {
      id: '',
      name: '',
      type: { entityId: null },
      typeName: '',
      method: { entityId: null },
      variant1: {
        spikePeriodAverage: 1,
        over: 12,
        expectedDilutionPeriod: 1,
        dilutionHorizon: 1,
        stress: '',
        floor: { entityId: null },
        floorTimeSeries: { entityId: null },
        amountType: { entityId: null },
        fixedAmount: '0',
        fixedPercentage: '0',
        amountCurrency: { entityId: null, name: null },
      },
      variant2: {
        interest: { entityId: null },
        margin: '',
        basis: { entityId: null },
        rateStress: '',
        cost: false,
        costTimeSeries: { entityId: null },
        duration: '0',
        durationDays: 0,
        durationStress: '0',
        durationStressDays: null,
        durationStressMultiplier: null,
        floor: { entityId: null },
        floorTimeSeries: { entityId: null },
        amountType: { entityId: null },
        fixedAmount: '0',
        fixedPercentage: '0',
        amountCurrency: { entityId: null, name: null },
      },
      variant3: {
        volatilityPeriod: { entityId: null },
        volatilityPeriodOther: null,
        averageDeviationPeriod: 0,
        averageVariationPeriod: 0,
        floor: { entityId: null },
        floorTimeSeries: { entityId: null },
        amountType: { entityId: null },
        fixedAmount: '0',
        fixedPercentage: '0',
        amountCurrency: { entityId: null, name: null },
      },
      variant4: {
        timeSeries: { entityId: null },
        floor: { entityId: null },
        floorTimeSeries: { entityId: null },
        amountType: { entityId: null },
        fixedAmount: '0',
        fixedPercentage: '0',
        amountCurrency: { entityId: null, name: null },
      },
      variant5: {
        averagePeriod: 3,
        margin: 0,
        floor: { entityId: null },
        floorTimeSeries: { entityId: null },
        amountType: { entityId: null },
        fixedAmount: '0',
        fixedPercentage: '0',
        amountCurrency: { entityId: null, name: null },
      },
      variant6: {
        lossRatio: 1,
        over: 12,
        lossHorizon: 1,
        stress: '',
        floor: { entityId: null },
        floorTimeSeries: { entityId: null },
        amountType: { entityId: null },
        fixedAmount: '0',
        fixedPercentage: '0',
        amountCurrency: { entityId: null, name: null },
      },
      variant7: {
        averagePeriod: 3,
        margin: 0,
        floor: { entityId: null },
        floorTimeSeries: { entityId: null },
        amountType: { entityId: null },
        fixedAmount: '0',
        fixedPercentage: '0',
        amountCurrency: { entityId: null, name: null },
      },
    };
  }

  fillModel(reserve: Reserve<ReserveVariantBase>, formModel: ReserveFormModel): ReserveFormModel {
    formModel.id = reserve.entityId;
    formModel.name = reserve.name;
    formModel.type.entityId = reserve.type.entityId as string;
    formModel.method.entityId = reserve.method.entityId as string;

    const variant = this.resolveReserveVariant(formModel);

    const updateAmountCurrencyOrNull = (amountCurrency: HasEntityId): CurrencyCode =>
      amountCurrency
        ? { entityId: amountCurrency.entityId as string, name: String(amountCurrency.entityId) }
        : null;

    if (variant === ReserveVariantID.VARIANT_1) {
      const data = reserve.data as ReserveVariant1;
      formModel.variant1.spikePeriodAverage = data.spikePeriodAverage;
      formModel.variant1.dilutionHorizon = data.dilutionHorizon;
      formModel.variant1.expectedDilutionPeriod = data.expectedDilutionPeriod;
      formModel.variant1.over = data.over;
      formModel.variant1.stress = data.stress;
      formModel.variant1.floor = data.floor as HasEntityId;
      formModel.variant1.floorTimeSeries.entityId = data.floorTimeSeries;
      formModel.variant1.amountType = data.amountType;
      formModel.variant1.fixedAmount = data.fixedAmount;
      formModel.variant1.fixedPercentage = data.fixedPercentage;
      formModel.variant1.amountCurrency = updateAmountCurrencyOrNull(data.amountCurrency);
    }

    if (variant === ReserveVariantID.VARIANT_2) {
      const data = reserve.data as ReserveVariant2;
      formModel.variant2.interest.entityId = data.interest;
      formModel.variant2.margin = data.margin;
      formModel.variant2.basis.entityId = data.basis.entityId as string;
      formModel.variant2.rateStress = data.rateStress;
      formModel.variant2.cost = data.cost;
      formModel.variant2.costTimeSeries.entityId = data.costTimeSeries;
      formModel.variant2.duration = data.duration.entityId as string;
      formModel.variant2.durationDays = data.durationDays;
      formModel.variant2.durationStress = data.durationStress.entityId as string;
      formModel.variant2.durationStressDays = data.durationStressDays;
      formModel.variant2.durationStressMultiplier = data.durationStressMultiplier;
      formModel.variant2.floor = data.floor as HasEntityId;
      formModel.variant2.floorTimeSeries.entityId = data.floorTimeSeries;
      formModel.variant2.amountType = data.amountType;
      formModel.variant2.fixedAmount = data.fixedAmount;
      formModel.variant2.fixedPercentage = data.fixedPercentage;
      formModel.variant2.amountCurrency = updateAmountCurrencyOrNull(data.amountCurrency);
    }

    if (variant === ReserveVariantID.VARIANT_3) {
      const data = reserve.data as ReserveVariant3;
      formModel.variant3.volatilityPeriod.entityId = data.volatilityPeriod.entityId as string;
      formModel.variant3.volatilityPeriodOther = data.volatilityPeriodOther;
      formModel.variant3.averageVariationPeriod = data.averageVariationPeriod;
      formModel.variant3.averageDeviationPeriod = data.averageDeviationPeriod;
      formModel.variant3.floor = data.floor as HasEntityId;
      formModel.variant3.floorTimeSeries.entityId = data.floorTimeSeries;
      formModel.variant3.amountType = data.amountType;
      formModel.variant3.fixedAmount = data.fixedAmount;
      formModel.variant3.fixedPercentage = data.fixedPercentage;
      formModel.variant3.amountCurrency = updateAmountCurrencyOrNull(data.amountCurrency);
    }

    if (variant === ReserveVariantID.VARIANT_4) {
      const data = reserve.data as ReserveVariant4;
      formModel.typeName = data.typeName;
      formModel.variant4.timeSeries.entityId = data.timeSeries;
      formModel.variant4.floor = data.floor as HasEntityId;
      formModel.variant4.floorTimeSeries.entityId = data.floorTimeSeries;
      formModel.variant4.amountType = data.amountType;
      formModel.variant4.fixedAmount = data.fixedAmount;
      formModel.variant4.fixedPercentage = data.fixedPercentage;
      formModel.variant4.amountCurrency = updateAmountCurrencyOrNull(data.amountCurrency);
    }

    if (variant === ReserveVariantID.VARIANT_5) {
      const data = reserve.data as ReserveVariant5;
      formModel.variant5.margin = data.margin;
      formModel.variant5.averagePeriod = data.averagePeriod;
      formModel.variant5.floor = data.floor as HasEntityId;
      formModel.variant5.floorTimeSeries.entityId = data.floorTimeSeries;
      formModel.variant5.amountType = data.amountType;
      formModel.variant5.fixedAmount = data.fixedAmount;
      formModel.variant5.fixedPercentage = data.fixedPercentage;
      formModel.variant5.amountCurrency = updateAmountCurrencyOrNull(data.amountCurrency);
    }

    if (variant === ReserveVariantID.VARIANT_6) {
      const data = reserve.data as ReserveVariant6;
      formModel.variant6.lossHorizon = data.lossHorizon;
      formModel.variant6.lossRatio = data.lossRatio;
      formModel.variant6.over = data.over;
      formModel.variant6.stress = data.stress;
      formModel.variant6.floor = data.floor as HasEntityId;
      formModel.variant6.floorTimeSeries.entityId = data.floorTimeSeries;
      formModel.variant6.amountType = data.amountType;
      formModel.variant6.fixedAmount = data.fixedAmount;
      formModel.variant6.fixedPercentage = data.fixedPercentage;
      formModel.variant6.amountCurrency = updateAmountCurrencyOrNull(data.amountCurrency);
    }

    if (variant === ReserveVariantID.VARIANT_7) {
      const data = reserve.data as ReserveVariant7;
      formModel.variant7.margin = data.margin;
      formModel.variant7.averagePeriod = data.averagePeriod;
      formModel.variant7.floor = data.floor as HasEntityId;
      formModel.variant7.floorTimeSeries.entityId = data.floorTimeSeries;
      formModel.variant7.amountType = data.amountType;
      formModel.variant7.fixedAmount = data.fixedAmount;
      formModel.variant7.fixedPercentage = data.fixedPercentage;
      formModel.variant7.amountCurrency = updateAmountCurrencyOrNull(data.amountCurrency);
    }

    return formModel;
  }

  getReserveRequest(formModel: ReserveFormModel, entityRevision: number) {
    const request: ReserveRequest<ReserveVariantBase> = {
      entityId: formModel.id,
      name: formModel.name,
      type: formModel.type,
      method: formModel.method,
      transactionEntityRevision: entityRevision,
      data: null,
    };

    const variant = this.resolveReserveVariant(formModel);

    const timeSeriesEntityIdOrNull = (timeSeries: HasEntityId) =>
      timeSeries != null ? timeSeries.entityId.toString() : null;

    if (variant === ReserveVariantID.VARIANT_1) {
      const data = formModel.variant1;

      request.data = {
        variantId: '1',
        spikePeriodAverage: data.spikePeriodAverage,
        over: data.over,
        expectedDilutionPeriod: data.expectedDilutionPeriod,
        dilutionHorizon: data.dilutionHorizon,
        stress: replaceCommaWithDot(data.stress),
        floor: data.floor,
        floorTimeSeries: timeSeriesEntityIdOrNull(data.floorTimeSeries),
        amountType: data.amountType,
        fixedAmount: data.fixedAmount ? replaceCommaWithDot(data.fixedAmount) : '',
        fixedPercentage: data.fixedPercentage ? replaceCommaWithDot(data.fixedPercentage) : '',
        amountCurrency: data.amountCurrency,
      } as ReserveVariant1;
    }

    if (variant === ReserveVariantID.VARIANT_2) {
      const data = formModel.variant2;

      request.data = {
        variantId: '2',
        interest: data.interest.entityId.toString(),
        margin: replaceCommaWithDot(data.margin),
        basis: data.basis,
        rateStress: replaceCommaWithDot(data.rateStress),
        cost: data.cost,
        costTimeSeries: timeSeriesEntityIdOrNull(data.costTimeSeries),
        duration: {
          entityId: data.duration,
        },
        durationDays: data.durationDays,
        durationStress: {
          entityId: data.durationStress,
        },
        durationStressDays: data.durationStressDays,
        durationStressMultiplier: data.durationStressMultiplier,
        floor: data.floor,
        floorTimeSeries: timeSeriesEntityIdOrNull(data.floorTimeSeries),
        amountType: data.amountType,
        fixedAmount: data.fixedAmount ? replaceCommaWithDot(data.fixedAmount) : '',
        fixedPercentage: data.fixedPercentage ? replaceCommaWithDot(data.fixedPercentage) : '',
        amountCurrency: data.amountCurrency,
      } as ReserveVariant2;
    }

    if (variant === ReserveVariantID.VARIANT_3) {
      const data = formModel.variant3;

      request.data = {
        variantId: '3',
        volatilityPeriod: data.volatilityPeriod,
        volatilityPeriodOther:
          data.volatilityPeriod.entityId === 8 ? data.volatilityPeriodOther : null,
        averageDeviationPeriod: data.averageDeviationPeriod,
        averageVariationPeriod: data.averageVariationPeriod,
        floor: data.floor,
        floorTimeSeries: timeSeriesEntityIdOrNull(data.floorTimeSeries),
        amountType: data.amountType,
        fixedAmount: data.fixedAmount ? replaceCommaWithDot(data.fixedAmount) : '',
        fixedPercentage: data.fixedPercentage ? replaceCommaWithDot(data.fixedPercentage) : '',
        amountCurrency: data.amountCurrency,
      } as ReserveVariant3;
    }

    if (variant === ReserveVariantID.VARIANT_4) {
      const data = formModel.variant4;

      request.data = {
        variantId: '4',
        typeName: formModel.typeName,
        timeSeries: data.timeSeries.entityId.toString(),
        floor: data.floor,
        floorTimeSeries: timeSeriesEntityIdOrNull(data.floorTimeSeries),
        amountType: data.amountType,
        fixedAmount: data.fixedAmount ? replaceCommaWithDot(data.fixedAmount) : '',
        fixedPercentage: data.fixedPercentage ? replaceCommaWithDot(data.fixedPercentage) : '',
        amountCurrency: data.amountCurrency,
      } as ReserveVariant4;
    }

    if (variant === ReserveVariantID.VARIANT_5) {
      const data = formModel.variant5;

      request.data = {
        variantId: '5',
        typeName: formModel.typeName,
        averagePeriod: data.averagePeriod,
        margin: data.margin,
        floor: data.floor,
        floorTimeSeries: timeSeriesEntityIdOrNull(data.floorTimeSeries),
        amountType: data.amountType,
        fixedAmount: data.fixedAmount ? replaceCommaWithDot(data.fixedAmount) : '',
        fixedPercentage: data.fixedPercentage ? replaceCommaWithDot(data.fixedPercentage) : '',
        amountCurrency: data.amountCurrency,
      } as ReserveVariant5;
    }

    if (variant === ReserveVariantID.VARIANT_6) {
      const data = formModel.variant6;

      request.data = {
        variantId: '6',
        lossRatio: data.lossRatio,
        over: data.over,
        lossHorizon: data.lossHorizon,
        stress: data.stress,
        floor: data.floor,
        floorTimeSeries: timeSeriesEntityIdOrNull(data.floorTimeSeries),
        amountType: data.amountType,
        fixedAmount: data.fixedAmount ? replaceCommaWithDot(data.fixedAmount) : '',
        fixedPercentage: data.fixedPercentage ? replaceCommaWithDot(data.fixedPercentage) : '',
        amountCurrency: data.amountCurrency,
      } as ReserveVariant6;
    }

    if (variant === ReserveVariantID.VARIANT_7) {
      const data = formModel.variant7;

      request.data = {
        variantId: '7',
        typeName: formModel.typeName,
        averagePeriod: data.averagePeriod,
        margin: data.margin,
        floor: data.floor,
        floorTimeSeries: timeSeriesEntityIdOrNull(data.floorTimeSeries),
        amountType: data.amountType,
        fixedAmount: data.fixedAmount ? replaceCommaWithDot(data.fixedAmount) : '',
        fixedPercentage: data.fixedPercentage ? replaceCommaWithDot(data.fixedPercentage) : '',
        amountCurrency: data.amountCurrency,
      } as ReserveVariant5;
    }

    return request;
  }

  resolveReserveVariant(formModel: ReserveFormModel): ReserveVariantID {
    const type = formModel?.type?.entityId as number;
    const method = formModel?.method?.entityId as number;

    if (type === ReserveType.TYPE_DILUTION) {
      if (method === ReserveMethod.METHOD_SNP) {
        return ReserveVariantID.VARIANT_1;
      } else if (method === ReserveMethod.METHOD_AVERAGE) {
        return ReserveVariantID.VARIANT_5;
      }
    }

    if (type === ReserveType.TYPE_LOSS) {
      if (method === ReserveMethod.METHOD_SNP) {
        return ReserveVariantID.VARIANT_6;
      } else if (method === ReserveMethod.METHOD_AVERAGE) {
        return ReserveVariantID.VARIANT_7;
      }
    }

    if (type === ReserveType.TYPE_YIELD && method === ReserveMethod.METHOD_SNP) {
      return ReserveVariantID.VARIANT_2;
    }

    if (type === ReserveType.TYPE_FX && method === ReserveMethod.METHOD_SNP) {
      return ReserveVariantID.VARIANT_3;
    }

    if (method === ReserveMethod.METHOD_TIME_SERIES) {
      return ReserveVariantID.VARIANT_4;
    }

    return null;
  }
}
