import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { NOT_AVAILABLE_RESOURCE } from './not-available-resource/not-available-resource.service';

import { getLocationPath } from '../utils/window';

const DEFAULT_VIEW_TITLE = 'DASHBOARD';

@Injectable()
export class TitleChangerService {
  private _translationRouteKey: string;

  constructor(private titleService: Title, private translateService: TranslateService) {}

  onNavigationChange(event: NavigationEnd): void {
    this._translationRouteKey = `TITLE.ROUTES.${this.prepareRouteTitleKey(event)}`;
    this._updateTitleTranslation();
  }

  onLocaleChange(): void {
    if (!this._translationRouteKey) return;

    this._updateTitleTranslation();
  }

  prepareRouteTitleKey(event: NavigationEnd): string {
    let redirectUrl =
      event.url === `/${NOT_AVAILABLE_RESOURCE}` ? getLocationPath() : event.urlAfterRedirects;

    // this allows to remove uuid from url '/dashboard/funding/dashboards/e5ff3e58-7f59-11ec-a669-cabfe09aae3c' and process title as usually
    const uuiRegex = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;
    const urlSegments = event.urlAfterRedirects.split('/');
    if (uuiRegex.test(urlSegments.pop())) {
      redirectUrl = urlSegments.join('/');
    }
    const validatedPathEnd = redirectUrl.replace(/\?.*$/g, '').replace(/[/0-9]+$/g, '');

    const match = validatedPathEnd.match(new RegExp('[a-z\\-]+$'));
    return (match ? match[0] : DEFAULT_VIEW_TITLE).toUpperCase();
  }

  private _updateTitleTranslation(): void {
    const title = this.translateService.instant('TITLE.FULL_TITLE', {
      routeTitle: this.translateService.instant(this._translationRouteKey),
    });
    this.titleService.setTitle(title);
  }
}
