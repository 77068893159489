import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { EmptyComponent } from '@demica/components';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifHasChartDataAvailable]',
})
export class IfHasChartDataAvailableDirective implements OnInit, OnChanges {
  @Input()
  ifHasChartDataAvailable?: boolean;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private resolver: ComponentFactoryResolver,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const hasDataAvailable = changes.ifHasChartDataAvailable.currentValue;

    this.viewContainer.clear();

    if (hasDataAvailable) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.createEmptyView();
    }
  }

  ngOnInit(): void {
    if (!this.templateRef) {
      return;
    }
  }

  private createEmptyView() {
    const factory = this.resolver.resolveComponentFactory(EmptyComponent);
    const componentRef = this.viewContainer.createComponent(factory);

    componentRef.instance.key = 'EMPTY_VIEW.NO_DATA_AVAILABLE';
    componentRef.instance.isVisible = true;
    componentRef.instance.height = '35vh';
  }
}
