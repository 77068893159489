import { CdkDropList } from '@angular/cdk/drag-drop';
import { Directive, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { DragAndDropNestedService } from '../../services/drag-and-drop-nested.service';

@Directive({
  selector: '[ngpDragAndDropNested]',
})
export class DragAndDropNestedDirective implements OnInit, OnDestroy {
  private subscription!: Subscription;
  constructor(
    private cdkDropList: CdkDropList,
    private dragAndDropNestedService: DragAndDropNestedService,
  ) {}

  ngOnInit(): void {
    this.dragAndDropNestedService.register(this.cdkDropList.id);

    this.subscription = this.dragAndDropNestedService.onListChange().subscribe((x) => {
      this.cdkDropList.connectedTo = x.filter((listId) => listId !== this.cdkDropList.id);
    });
  }

  ngOnDestroy(): void {
    this.dragAndDropNestedService.unregister(this.cdkDropList.id);
    this.subscription.unsubscribe();
  }
}
