import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import { noop } from 'rxjs';

import { KeyboardNavigationStrategyService } from '@demica/accessibility';
import { moveElement, TransactionOpcoAssignment } from '@demica/core/core';
import { Client } from '@demica/resources/client';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'trf-client-detail-preview',
  templateUrl: './client-detail-preview.component.html',
  styleUrls: ['./client-detail-preview.component.sass'],
})
export class ClientDetailPreviewComponent implements OnInit, OnChanges {
  @Input()
  client: Client;

  @Input()
  allClientOpcos: TransactionOpcoAssignment[];
  @Input()
  availableOpcos: TransactionOpcoAssignment[] = [];
  @Input()
  initiallySelectedOpcos: TransactionOpcoAssignment[] = [];

  @Output()
  opcosChanged = new EventEmitter<TransactionOpcoAssignment[]>();
  @Output()
  positionChanged = new EventEmitter<boolean>();

  selectedOpcos: TransactionOpcoAssignment[] = [];

  assignActions = {
    activate: {
      titleKey: 'TRANSACTION_OPCOS_ASSIGN.TABLE_ACTION_ACTIVATE',
      handler: this.activateEnvironment.bind(this),
      icon: 'toggle-off',
      hidden: (opco: TransactionOpcoAssignment) =>
        this.checkSelected(opco) || !this.checkIfAvailable(opco),
      testId: 'action-activate',
    },
    deactivate: {
      titleKey: 'TRANSACTION_OPCOS_ASSIGN.TABLE_ACTION_DEACTIVATE',
      handler: this.deactivateEnvironment.bind(this),
      icon: 'toggle-on',
      hidden: (opco: TransactionOpcoAssignment) =>
        !this.isRemovable(opco) || !this.checkSelected(opco) || this.checkIfAvailable(opco),
      testId: 'action-deactivate',
    },
    disabled: {
      handler: noop,
      icon: 'toggle-on',
      hidden: (opco: TransactionOpcoAssignment) =>
        this.isRemovable(opco) && (this.checkSelected(opco) || this.checkIfAvailable(opco)),
      testId: 'action-disabled',
      class: 'disabled',
      tooltipPosition: 'top',
      tooltipContent: 'CLIENTS.CANNOT_UNASSIGN',
    },
  };

  setOrderActions = {
    moveUp: {
      titleKey: 'TRANSACTION_OPCOS_ASSIGN.TABLE_ACTION_MOVE_UP',
      handler: this.moveOpcoUp.bind(this),
      icon: 'chevron-circle-up',
      inactive: (opco: TransactionOpcoAssignment) => !this.canMoveOpcoUp(opco),
      testId: 'action-move-up',
    },
    moveDown: {
      titleKey: 'TRANSACTION_OPCOS_ASSIGN.TABLE_ACTION_MOVE_DOWN',
      handler: this.moveOpcoDown.bind(this),
      icon: 'chevron-circle-down',
      inactive: (opco: TransactionOpcoAssignment) => !this.canMoveOpcoDown(opco),
      testId: 'action-move-down',
    },
  };

  constructor(private keyboardNavigationService: KeyboardNavigationStrategyService) {}

  isRemovable(opco: TransactionOpcoAssignment) {
    return this.initiallySelectedOpcos.every((value) =>
      value.code === opco.code ? value.removableFromTransaction : true,
    );
  }

  ngOnInit() {
    this.selectedOpcos = [...this.initiallySelectedOpcos];

    this.selectedOpcos.forEach((opco) => {
      const selectedOpcoIndex = this.selectedOpcos.indexOf(opco);
      const currentOpcoIndex = this.allClientOpcos.findIndex((ao) => ao.entityId === opco.entityId);
      moveElement(this.allClientOpcos, currentOpcoIndex, selectedOpcoIndex);
    });

    this.emitOpcos();
  }

  ngOnChanges() {
    this.selectedOpcos = [...this.initiallySelectedOpcos];
    this.emitOpcos();
  }

  activateEnvironment(opcoItem: TransactionOpcoAssignment) {
    if (opcoItem && this.availableOpcos) {
      this.selectedOpcos.push(opcoItem);
      this.availableOpcos = this.availableOpcos.filter((o) => o.entityId !== opcoItem.entityId);

      const currentIndex = this.allClientOpcos.indexOf(opcoItem);
      moveElement(this.allClientOpcos, currentIndex, this.selectedOpcos.length - 1);

      this.positionChanged.emit(true);
      this.emitOpcos();
    }
  }

  deactivateEnvironment(opcoItem: TransactionOpcoAssignment) {
    if (opcoItem && opcoItem.removableFromTransaction !== false) {
      this.availableOpcos.push(opcoItem);
      this.selectedOpcos = this.selectedOpcos.filter((o) => o.entityId !== opcoItem.entityId);

      const currentIndex = this.allClientOpcos.indexOf(opcoItem);
      moveElement(this.allClientOpcos, currentIndex, this.selectedOpcos.length);

      this.positionChanged.emit(true);
      this.emitOpcos();
    }
  }

  canMoveOpcoUp(selectedTarget: TransactionOpcoAssignment) {
    return (
      !!selectedTarget &&
      !(this.allClientOpcos.findIndex((ao) => ao.entityId === selectedTarget.entityId) < 1) &&
      !!this.selectedOpcos.find((selectedOpco) => selectedOpco.entityId === selectedTarget.entityId)
    );
  }

  canMoveOpcoDown(selectedTarget: TransactionOpcoAssignment) {
    return (
      !!selectedTarget &&
      !(
        this.allClientOpcos.findIndex((ao) => ao.entityId === selectedTarget.entityId) ===
        this.selectedOpcos.length - 1
      ) &&
      !!this.selectedOpcos.find((selectedOpco) => selectedOpco.entityId === selectedTarget.entityId)
    );
  }

  moveOpcoUp(selectedTarget: TransactionOpcoAssignment) {
    if (this.canMoveOpcoUp) {
      const currentIndex = this.allClientOpcos.indexOf(selectedTarget);
      moveElement(this.allClientOpcos, currentIndex, currentIndex - 1);

      this.positionChanged.emit(true);
      this.emitOpcos();
    }
  }

  moveOpcoDown(selectedTarget: TransactionOpcoAssignment) {
    if (this.canMoveOpcoDown) {
      const currentIndex = this.allClientOpcos.indexOf(selectedTarget);
      moveElement(this.allClientOpcos, currentIndex, currentIndex + 1);

      this.positionChanged.emit(true);
      this.emitOpcos();
    }
  }

  private emitOpcos() {
    const data = this.allClientOpcos.filter((opco) =>
      this.selectedOpcos.find((so) => so.entityId === opco.entityId),
    );
    this.opcosChanged.emit(data);
  }

  checkSelected(opcoItem: TransactionOpcoAssignment) {
    return !!this.selectedOpcos.find((so) => so.entityId === opcoItem.entityId);
  }

  checkIfAvailable(opcoItem: TransactionOpcoAssignment) {
    if (this.availableOpcos == null) {
      this.availableOpcos = [];
    }

    return !!this.availableOpcos.find((ao) => ao.entityId === opcoItem.entityId);
  }
}
