<div
  *ifHasAnyAuthority="['ALERTS_FUNDING_ALERTS', 'DASHBOARD_MISSING_FILES']"
  class="alerts-container"
  [@fadeAnimation]="'in'"
>
  <div
    *ngIf="!loading"
    id="alerts-label"
    class="alerts-label"
  >
    <span>
      {{ "HEADER.FUNDING_ALERTS" | translate }}
    </span>
  </div>

  <ng-container *ifHasFeatureToggle="fundingAlertToggle">
    <div
      *ifHasAuthority="'ALERTS_FUNDING_ALERTS'"
      class="funding-alerts"
      aria-labelledby="alerts-label"
      aria-describedby="at-risk at-risk-warning watch-list watch-list-warning performing"
      role="button"
      [attr.tabindex]="hasFundingAlerts ? 0 : -1"
      (keyup.enter)="onAlertsClick()"
      (click)="onAlertsClick()"
    >
      <trf-header-funding-alerts
        (loaded)="onLoaded($event)"
        (fundingAlertsCounter)="onFundingAlertsCounter($event)"
      ></trf-header-funding-alerts>
    </div>
  </ng-container>

  <div
    *ifHasAuthority="'DASHBOARD_MISSING_FILES'"
    class="missing-files"
    aria-labelledby="alerts-label"
    aria-describedby="missing-files-status"
    tabindex="0"
    role="button"
    (keyup.enter)="onMissingFilesClick()"
    (click)="onMissingFilesClick()"
  >
    <trf-header-missing-files (loaded)="onLoaded($event)"></trf-header-missing-files>
  </div>
</div>
