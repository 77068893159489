<td
  [title]="row.value"
  testid="cell-analysis-code-name"
>
  {{ row.value }}
</td>
<td
  [title]="
    row.analysisGroup?.name
      ? ('COLUMN_DEFINITION_TYPES.' + row.analysisGroup.entityId | translate)
      : row.analysisGroup.name
  "
  testid="cell-analysis-group-column-type"
>
  {{
    row.analysisGroup?.name || ("COLUMN_DEFINITION_TYPES." + row.analysisGroup.entityId | translate)
  }}
</td>
<td
  *ngIf="showTransaction"
  [title]="row.transaction?.name"
  testid="cell-transaction-name"
>
  {{ row.transaction?.name }}
</td>
<td
  class="cell-actions"
  testid="cell-actions"
  testid="cell-aliases-management-actions"
>
  <trf-table-actions
    [actions]="actions"
    [actionSubject]="row"
    [hasAnyUsages]="row.usageRelations.length > 0"
  ></trf-table-actions>
</td>
