const timeExp = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';
const timeWithSecExp = '^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$';

export function isTimeFormat(value: string): boolean {
  return new RegExp(timeExp).test(value) || new RegExp(timeWithSecExp).test(value);
}

export function hasSeconds(value: string): boolean {
  return new RegExp(timeWithSecExp).test(value);
}

function splitTimeToArray(value: string): string[] {
  if (!isTimeFormat(value)) return null;

  return value.split(':');
}

export function isEndTimeAfterStart(startTime: string, endTime: string): boolean {
  if (!isTimeFormat(startTime) || !isTimeFormat(endTime)) return false;
  return endTime > startTime;
}

export function trimSeconds(value: string): string {
  if (!hasSeconds(value)) return value;

  return value.slice(0, -3);
}

export function isEndTimeAfterHours(startTime: string, endTime: string, hours: number): boolean {
  const startTimeHour = parseInt(splitTimeToArray(startTime)[0]);
  const endTimeHour = parseInt(splitTimeToArray(endTime)[0]);

  if (startTimeHour + hours > endTimeHour) return false;
  if (startTimeHour + hours === endTimeHour) {
    return splitTimeToArray(startTime)[1] <= splitTimeToArray(endTime)[1];
  }
  return true;
}
