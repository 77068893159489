import { InjectionToken } from '@angular/core';

import { NotificationTypes } from '@demica/core/core';

export interface NotificationHandler {
  onNotification(notification: Notification): void;

  onClearAll(): void;
}

export interface Notification {
  type: NotificationTypes;
  messageCode: string;
  messageParams?: Record<string, unknown>;
}

export const NOTIFICATION_HANDLER = new InjectionToken<NotificationHandler>('NOTIFICATION_HANDLER');
