import { Directive, HostListener } from '@angular/core';

import { KeyboardNavigationStrategyService } from '../service/keyboard-navigation-strategy.service';

import { Key } from '../model/key';

@Directive({
  selector: '[trfOrdinaryTableKeyHandler]',
})
export class OrdinaryTableKeyHandlerDirective {
  constructor(private _keyboardTableNavigationService: KeyboardNavigationStrategyService) {}

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    const key = new Key(event);

    if (key.isUp || key.isDown || key.isLeft || key.isRight || key.isEnter) {
      const target = event.target as HTMLElement;

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      target.tagName.toLowerCase() === 'table'
        ? this._keyboardTableNavigationService.onFocusToOrdinaryTable(target)
        : this._keyboardTableNavigationService.ordinaryTableNavigation(event);
    }
  }
}
