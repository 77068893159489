import { Component, OnInit } from '@angular/core';

import { from, Observable, of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

import {
  locationReload,
  setIgnoreDiscardUnsavedChanges,
  UserAuthContextChangeReason,
  UserAuthContextChangesService,
} from '@demica/core/core';

import { ModalService } from '../../service/modal.service';

import { AuthorizationTokenChangeModalComponent } from '../../component/authorization-token-change-modal/authorization-token-change-modal.component';

@Component({
  selector: 'trf-user-auth-context-change',
  templateUrl: './user-auth-context-change.component.html',
})
export class UserAuthContextChangeComponent implements OnInit {
  constructor(
    private _modalService: ModalService,
    private _userAuthContextChangeService: UserAuthContextChangesService,
  ) {}

  ngOnInit(): void {
    this._userAuthContextChangeService.userAuthContextChange$
      .pipe(exhaustMap((changeReason) => this._handleChange(changeReason)))
      .subscribe();
  }

  private _handleChange(changeReason: UserAuthContextChangeReason): Observable<unknown> {
    switch (changeReason) {
      case UserAuthContextChangeReason.REGION_CHANGED: {
        const message = 'AUTHORIZATION.TOKEN_REFRESH_HANDLER.MODAL_TOKEN_REGION_CHANGED_MSG';
        return this._openMessageModal(message);
      }
      case UserAuthContextChangeReason.IDENTITY_CHANGED: {
        const message = 'AUTHORIZATION.TOKEN_REFRESH_HANDLER.MODAL_TOKEN_INVALID_MSG';
        return this._openMessageModal(message);
      }
      default:
        return of(null);
    }
  }

  private _openMessageModal = (messageText: string) => {
    const dialog = new Promise<void>((res) => {
      const modalCallback = () => {
        setIgnoreDiscardUnsavedChanges();
        locationReload();
        res();
      };
      const modal = this._modalService.openModal(AuthorizationTokenChangeModalComponent, {
        centered: true,
      });

      modal.instance.message = messageText;
      modal.onSuccess = modalCallback;
    });
    return from(dialog);
  };
}
