export class PieChartValue {
  private _nameWithPercentage: string;
  private _percentageValue: number;

  constructor(private _name: string, private _value: string) {}

  getData(): string[] {
    return [this._name, this._value];
  }

  getDataWithPercentage(): string[] {
    return [this._nameWithPercentage, this._value];
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get nameWithPercentage(): string {
    return this._nameWithPercentage;
  }

  set nameWithPercentage(nameWithPercentage) {
    this._nameWithPercentage = nameWithPercentage;
  }

  set percentageValue(percentageValue: number) {
    this._percentageValue = percentageValue;
  }

  get percentageValue(): number {
    return this._percentageValue;
  }

  getIntValue(): number {
    return parseInt(this._value, 10);
  }
}
