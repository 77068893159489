import { isArray } from 'lodash';

export function isString(value: unknown | string): value is string {
  return typeof value === 'string';
}

export function joinIfNotEmpty(stringArray: Array<string>, separator: string): string {
  return stringArray.filter((val) => val && val.trim()).join(separator);
}

export function replaceCommaWithDot(object: unknown): string | null {
  return object
    ? object.toString().replace(',', '.')
    : object === 0
    ? '0'
    : object === null
    ? null
    : '';
}

export function addDotToPrefix(translationPrefix: string | string[]): string | string[] {
  if (translationPrefix) {
    if (isArray(translationPrefix))
      return translationPrefix.map((prefix) => (prefix ? prefix + '.' : ''));
    else return translationPrefix + '.';
  } else {
    return '';
  }
}

export function cutAfterChars(value: string, chars: string[]): string {
  let charIndexInValue = -1;
  let preparedValue = value;

  chars.forEach((char) => {
    charIndexInValue = preparedValue.indexOf(char);

    // using substring instead of regex due to improved performance
    if (charIndexInValue > -1) preparedValue = preparedValue.substring(0, charIndexInValue);
  });

  return preparedValue;
}

export function toBoolean(value: boolean | string): boolean {
  if (typeof value === 'string') {
    return value === 'true';
  } else {
    return value;
  }
}

export function capitalizeString(value: string, lowerCaseRest = false): string {
  if (!value) return '';
  const firstLetter = value.charAt(0);
  const restOfTheString = lowerCaseRest ? value.slice(1).toLowerCase() : value.slice(1);

  return firstLetter.toUpperCase() + restOfTheString;
}

export function capitalizeSentence(value: string, lowerCaseRest = false): string {
  if (!value) return '';

  return value
    .split(' ')
    .map((word) => capitalizeString(word, lowerCaseRest))
    .join(' ');
}
