<header
  class="main-header"
  data-testid="main-header"
>
  <trf-logo [sidebarCollapsed]="sidebarCollapsed"></trf-logo>

  <nav class="navbar navbar-static-top">
    <div class="header-left">
      <div class="sidebar-menu-toggle">
        <button
          class="trf-header-btn"
          [attr.aria-label]="
            (sidebarCollapsed
              ? 'HEADER.TOGGLE_BUTTON_LABEL_COLLAPSED'
              : 'HEADER.TOGGLE_BUTTON_LABEL_EXPANDED'
            ) | translate
          "
          (click)="onArrowClick()"
        >
          <ngp-svg-icon
            class="menu-toggle-icon"
            iconName="bars"
          ></ngp-svg-icon>
        </button>
      </div>

      <div
        class="actual-view-name"
        data-testid="header-text"
      >
        <h1>
          {{ "HEADER." + currentViewUrlSegment | translate }}
        </h1>
      </div>
    </div>

    <div class="navbar-header-menu">
      <ul class="navbar-nav">
        <li class="nav-item">
          <trf-header-alerts></trf-header-alerts>
        </li>
        <li
          class="dropdown notifications-menu notificationsBox"
          aria-haspopup="listbox"
        ></li>
        <li
          *ifHasFeatureToggle="ORGANIZATION_UNITS"
          class="dropdown nav-item"
        >
          <trf-regions-dropdown testid="regions-dropdown"></trf-regions-dropdown>
        </li>
        <li class="dropdown nav-item">
          <trf-localisations-dropdown testid="localisations-dropdown"></trf-localisations-dropdown>
        </li>
        <li
          *ifHasAnyAuthority="['DASHBOARD_PORTFOLIO_VIEW', 'DASHBOARD_FUNDING_VIEW']"
          class="dropdown nav-item"
        >
          <trf-currencies-dropdown testid="currencies-dropdown"></trf-currencies-dropdown>
        </li>
        <li class="dropdown user user-menu userBox nav-item">
          <trf-profile-dropdown testid="profile-dropdown"></trf-profile-dropdown>
        </li>
      </ul>
    </div>
  </nav>
</header>
