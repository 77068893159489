import { Locale } from './locale.enum';

export const DECIMAL_FORMATS_CONFIGURATION = {
  [Locale.EN_GB]: { decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 2 },
  [Locale.EN_US]: { decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 2 },
  [Locale.RU_RU]: { decimalSeparator: ',', thousandsSeparator: ' ', decimalPlaces: 2 },
  [Locale.FR_FR]: { decimalSeparator: ',', thousandsSeparator: ' ', decimalPlaces: 2 },
  [Locale.FR_CA]: { decimalSeparator: ',', thousandsSeparator: ' ', decimalPlaces: 2 },
  [Locale.RO_RO]: { decimalSeparator: ',', thousandsSeparator: '.', decimalPlaces: 2 },
  [Locale.ES_ES]: { decimalSeparator: ',', thousandsSeparator: '.', decimalPlaces: 2 },
  [Locale.IT_IT]: { decimalSeparator: ',', thousandsSeparator: '.', decimalPlaces: 2 },
  [Locale.DE_DE]: { decimalSeparator: ',', thousandsSeparator: '.', decimalPlaces: 2 },
  [Locale.PL_PL]: { decimalSeparator: ',', thousandsSeparator: ' ', decimalPlaces: 2 },
  [Locale.EL_EL]: { decimalSeparator: ',', thousandsSeparator: '.', decimalPlaces: 2 },
  [Locale.AR_BH]: { decimalSeparator: ',', thousandsSeparator: "'", decimalPlaces: 3 },
};
export const DECIMAL_FRACTION_CONST = '00000000';
export const DECIMAL_DEFAULT_FRACTION_SIZE = 4;
export const LEADING_ZEROS_REGEX = /^0+(?!\.|$)/g;
