import { HttpErrorResponse } from '@angular/common/http';

import { errorNotification, NotificationEntry } from '@demica/core/core';
import { AdditionalInformationError } from '@demica/resources/common';

export function convertErrorsToNotifications(errors: HttpErrorResponse): NotificationEntry[] {
  return errors.error.errors[0].additionalInformationErrors.map(
    (err: AdditionalInformationError) => {
      const errorCodes = `NOTIFICATION.ERROR_CODE.${err.caughtErrorCode}`;
      const variables = err.informationError;
      return errorNotification(errorCodes, variables);
    },
  );
}
