import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DragAndDropNestedService {
  private list = new BehaviorSubject<string[]>([]);
  onListChange = (): Observable<string[]> => this.list.asObservable();

  register = (id: string) => {
    if (!id || this.list.value.includes(id)) {
      return;
    }

    const list = [id, ...this.list.value];

    if (id.includes('BASE')) {
      list.sort((_, b) => (b.includes('BASE') ? -1 : 0));
    }

    this.list.next(list);
  };

  unregister = (id: string) => {
    this.list.next(this.list.value.filter((x) => x !== id));
  };
}
