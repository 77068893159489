export const ACTION_EDIT: ActionId = { id: 1, hideWhenObjectIsUsed: false };
export const ACTION_REMOVE: ActionId = { id: 2, hideWhenObjectIsUsed: true };
export const ACTION_PREVIEW: ActionId = { id: 3, hideWhenObjectIsUsed: false };
export const ACTION_UNLINK: ActionId = { id: 4, hideWhenObjectIsUsed: true };
export const ACTION_PROMOTE: ActionId = { id: 5, hideWhenObjectIsUsed: false };
export const ACTION_CREATE: ActionId = { id: 6, hideWhenObjectIsUsed: false };
export const ACTION_UNLINK_ALWAYS: ActionId = { id: 7, hideWhenObjectIsUsed: false };
export const ACTION_REPLACE_WITH_TEMPLATE: ActionId = { id: 8, hideWhenObjectIsUsed: false };
export const ACTION_PAUSE: ActionId = { id: 9, hideWhenObjectIsUsed: false };
export const ACTION_UNPAUSE: ActionId = { id: 10, hideWhenObjectIsUsed: false };
export const ACTION_EXPORT: ActionId = { id: 11, hideWhenObjectIsUsed: false };
export const ACTION_PUSH: ActionId = { id: 13, hideWhenObjectIsUsed: false };

export interface Action<T = unknown> {
  titleKey?: string;
  handler: (actionSubject: T) => void;
  icon?: string;
  inactive?: (actionSubject: T) => boolean;
  hidden?: (actionSubject: T) => boolean;
  testId?: string;
  requiredAuthority?: string;
  class?: string;
  tooltipPosition?: string;
  tooltipContent?: string;
  actionId?: ActionId;
  tooltipHandler?: (actionSubject: T) => string | null;
  inactiveWithMessages?: boolean;
  messages?: string[];
}

export interface Actions<T = unknown> {
  [key: string]: Action<T>;
}

export interface ActionId {
  id: number;
  hideWhenObjectIsUsed: boolean;
}
