import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngp-readonly-text-control',
  templateUrl: 'readonly-text-control.component.html',
  styleUrls: ['readonly-text-control.component.sass'],
})
export class ReadonlyTextControlComponent {
  @Input()
  labelId = '';
  @Input()
  labelKey = '';
  @Input()
  text = '';
}
