import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HasEntityId } from '../../interface/has-entity-id.interface';
import { createPaginationParams, defaultResponse, PageRequest } from '../../model/pageable-data';
import { ReportDefinitionSearchParams } from '../../model/report-definition-search.params';
import { ReportDefinitionWithTemplate } from '../../model/report-definition-with-template.interface';
import { ReportDefinition } from '../../model/report-definition.interface';
import { PageRestResponse, RestResponse } from '../../model/response.interface';
import { CodeAvailabilityResponse } from '../../model/rest-response/availability-response';
import { encodeParams } from '../../security/encode-params';
import { encodeEndpoint } from '../../security/encode-url';
import { toData, toPageableData } from './response-mapping';

interface GetReportsOptions {
  transactionScope?: boolean;
  regionScope?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ReportDefinitionResourceService {
  constructor(private _http: HttpClient) {}

  getTransactionScopeReportDefinition(): Observable<ReportDefinition[]> {
    return this.getReportsDefinitionDictionary({ transactionScope: true });
  }

  getRegionScopeReportDefinition(): Observable<ReportDefinition[]> {
    return this.getReportsDefinitionDictionary({ regionScope: true });
  }

  getReports(): Observable<ReportDefinition[]> {
    const params = encodeParams({ size: 1000 });
    const url = encodeEndpoint('resources/report-definitions');
    return this._http.get<RestResponse<ReportDefinition[]>>(url, { params }).pipe(map(toData));
  }

  postReport(data: FormData): Observable<unknown> {
    return this._http.post(encodeEndpoint('resources/report-definitions'), data);
  }

  putReport(reportDefinitionId: number, data: FormData): Observable<unknown> {
    return this._http.put(
      encodeEndpoint('resources/report-definitions/{}', reportDefinitionId),
      data,
    );
  }

  getReport(reportDefinitionId: number): Observable<ReportDefinitionWithTemplate> {
    const url = encodeEndpoint('resources/report-definitions/{}', reportDefinitionId);
    return this._http.get<RestResponse<ReportDefinitionWithTemplate>>(url).pipe(map(toData));
  }

  checkReportCodeAvailable(
    code: string,
    reportDefinitionId?: number,
  ): Observable<CodeAvailabilityResponse> {
    const params = encodeParams({ code, reportDefinitionId });
    return this._http
      .get<RestResponse<CodeAvailabilityResponse>>(
        encodeEndpoint('resources/report-definitions/code-availability'),
        { params },
      )
      .pipe(map(toData));
  }

  putReportStatus(reportDefinitionId: number, data: unknown): Observable<HasEntityId> {
    const url = encodeEndpoint('resources/report-definitions/{}/status', reportDefinitionId);
    return this._http.put<RestResponse<HasEntityId>>(url, data).pipe(map(toData));
  }

  getReportsPageable(
    pageRequest: PageRequest,
    searchParams: ReportDefinitionSearchParams,
  ): Observable<PageRestResponse<ReportDefinition>> {
    const httpParams = createPaginationParams(pageRequest);
    const url = encodeEndpoint('resources/report-definitions/search');
    return this._http
      .post<PageRestResponse<ReportDefinition>>(url, searchParams, { params: httpParams })
      .pipe(
        catchError(() => defaultResponse<ReportDefinition>()),
        map(toPageableData),
      );
  }

  downloadTemplate(reportDefinitionId: number): Observable<{ name: string; content: Blob }> {
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob',
    };
    const url = encodeEndpoint(
      'resources/report-definitions/{}/template/download',
      reportDefinitionId,
    );
    return this._http.get(url, <unknown>options).pipe(
      map((res: HttpResponse<BlobPart>) => ({
        name: res.headers.get('Content-Disposition'),
        content: new Blob([res.body], { type: 'application/octet-stream' }),
      })),
    );
  }

  private getReportsDefinitionDictionary(
    options: GetReportsOptions,
  ): Observable<ReportDefinition[]> {
    const url = encodeEndpoint('resources/report-definitions/dictionary');
    const params = encodeParams({ ...options });

    return this._http.get<RestResponse<ReportDefinition[]>>(url, { params }).pipe(
      map(toData),
      map((reports) =>
        reports.map((report) => ({
          ...report,
          name: report.name + ` (${report.code})`,
        })),
      ),
    );
  }
}
