<aside class="main-sidebar">
  <section class="sidebar">
    <ul class="sidebar-menu">
      <ng-container *ngFor="let menuOption of menuOptions; trackBy: menuSectionTrackBy">
        <ng-container *ifHasMenuSectionAuthority="menuOption.options">
          <li class="menu-section">
            <trf-menu-section
              *ngIf="menuOption.element.type === menuOptionType.SECTION"
              [element]="menuOption.element"
              [menuOptions]="menuOption.options"
            >
            </trf-menu-section>
          </li>
        </ng-container>
        <ng-container *ngIf="menuOption.element.type === menuOptionType.ITEM">
          <ng-container
            *ifHasAuthority="(menuOption.element | menuItemCast).permissions.requiredAuthority"
          >
            <li>
              <trf-menu-help
                *ngIf="menuOption.id === 'help-option' && menuOption.enabled"
                [attr.testid]="menuOption.id"
                [menuOption]="menuOption"
              >
              </trf-menu-help>
            </li>
          </ng-container>
          <li>
            <trf-menu-sign-out
              *ngIf="menuOption.id === 'sign-out-option' && menuOption.enabled"
              [attr.testid]="menuOption.id"
              [menuOption]="menuOption"
            >
            </trf-menu-sign-out>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </section>
</aside>
