export class NumberRegexBuilder {
  protected acceptNegatives = false;

  setAcceptNegatives(value: boolean) {
    this.acceptNegatives = value;
    return this;
  }

  build(): RegExp {
    const regexpStr = '^' + (this.acceptNegatives ? '(?:-|$)?' : '') + '(?:\\d+|$)$';
    return new RegExp(regexpStr);
  }
}
