import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { getLocalStorage, generateUUID, setLocalStorage } from '@demica/utils';

import { IdProvider } from '../../id-provider.service';

@Injectable({
  providedIn: 'root',
})
export class ClientIdForAnalyticsService extends IdProvider {
  override id$: Observable<string>;

  private _id$ = new Subject<string>();
  private _storageKey = 'user-id-token';

  constructor() {
    super();

    this.id$ = this._id$.asObservable();
  }

  getId(): string {
    const userId = getLocalStorage(this._storageKey);

    if (userId === null) {
      return this._generateId();
    }

    return userId;
  }

  private _generateId(): string {
    const userId = generateUUID();

    setLocalStorage(this._storageKey, userId);

    return userId;
  }
}
