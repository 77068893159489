import {
  AliasMissingMapping,
  byObjectProperty,
  byProperty,
  byStringPropertyCaseInsensitive,
  ComponentConfiguration,
  Order,
  SortParams,
  Transaction,
} from '@demica/core/core';

import { TextTableHeaderComponent } from '../../../data-table/text-table-header.component';
import { AliasMissingRowComponent } from '../alias-missing-row/alias-missing-row.component';
import { AliasesMissingComponent } from './aliases-missing.component';

import { Actions } from '../../../actions/model/actions.interface';

export function setupHeaderConfig(aliasesMissing: AliasesMissingComponent): ComponentConfiguration {
  return {
    component: TextTableHeaderComponent,
    inputs: {
      columns: getColumnDefinitionConfiguration(aliasesMissing.transaction),
      initialSort: {
        property: 'firstAppearanceDate',
        order: Order.DESC,
      },
      hasSelectAllCheckbox: !aliasesMissing.previewMode,
      allSelected: false,
      indeterminateSelected: false,
    },
    outputs: {
      checkboxClicked: (change: boolean) => aliasesMissing.onSelectAllCheckboxChanged(change),
      sortChanged: (sp: SortParams) => aliasesMissing.onSortChanged(sp),
    },
  };
}

function getColumnDefinitionConfiguration(transaction: Transaction) {
  const columns = [];

  columns.push({
    nameKey: 'ALIASES.TABLE_COLUMN_UNKNOWN_ALIAS_VALUE',
    property: 'missingMappingId.value',
    comparator: byObjectProperty('missingMappingId', 'value'),
  });

  if (!transaction) {
    columns.push({
      nameKey: 'ALIASES.TABLE_COLUMN_TRANSACTION',
      property: 'transaction',
      // TODO we have only IDs on backend - unable to sort by name
      // comparator: byObjectProperty('transaction', 'name'),
    });
  }

  columns.push(
    {
      nameKey: 'ALIASES.TABLE_COLUMN_DATA_SOURCE',
      property: 'dataSource',
      // TODO TRFUI-4722 we have only IDs on backend - unable to sort by name
      // comparator: byObjectProperty('dataSource', 'name'),
    },
    {
      nameKey: 'ALIASES.TABLE_COLUMN_ANALYSIS_GROUP_NAME',
      property: 'analysisGroup',
      // TODO TRFUI-4722 we have only IDs on backend - unable to sort by name
      // comparator: byObjectProperty('analysisGroup', 'name'),
    },
    {
      nameKey: 'ALIASES.TABLE_COLUMN_ENVIRONMENT',
      property: 'environment',
      // TODO TRFUI-4722 we have only IDs on backend - unable to sort by name
      // comparator: byObjectProperty('environment', 'name'),
    },
    {
      nameKey: 'ALIASES.TABLE_COLUMN_FILE',
      property: 'firstAppearanceFilename',
      comparator: byStringPropertyCaseInsensitive('firstAppearanceFilename'),
    },
    {
      nameKey: 'ALIASES.TABLE_COLUMN_IMPORT_DATE',
      property: 'firstAppearanceDate',
      comparator: byProperty('firstAppearanceDate'),
    },
    { nameKey: 'ALIASES.TABLE_COLUMN_ACTIONS', classes: 'actions fixed-width' },
  );

  return columns;
}

export function setupRowConfig(aliasesMissing: AliasesMissingComponent): ComponentConfiguration {
  const rowActions: Actions = {
    add: {
      titleKey: 'ALIASES.TABLE_ACTION_ADD',
      handler: aliasesMissing.onAddMapping.bind(aliasesMissing),
      icon: 'plus',
      testId: 'action-add-mapping',
      hidden: () => aliasesMissing.versionPreviewMode || aliasesMissing.previewMode,
      inactive: (row: AliasMissingMapping) => !row.canBeAnalysisCodeName,
    },
    reject: {
      titleKey: 'ALIASES.TABLE_ACTION_REJECT',
      handler: aliasesMissing.onRemoveMapping.bind(aliasesMissing),
      icon: 'ban',
      testId: 'action-reject',
      hidden: () => aliasesMissing.versionPreviewMode || aliasesMissing.previewMode,
    },
    map: {
      titleKey: 'ALIASES.TABLE_ACTION_MAP',
      handler: aliasesMissing.onMapAliasValue.bind(aliasesMissing),
      icon: 'link',
      testId: 'action-map-alias-value',
      hidden: () => aliasesMissing.versionPreviewMode || aliasesMissing.previewMode,
    },
  };

  return {
    component: AliasMissingRowComponent,
    inputs: {
      actions: rowActions,
      displayTransactionColumn: !aliasesMissing.transaction,
      displayCheckbox: !aliasesMissing.previewMode,
    },
    outputs: {
      checkboxChanged: () => aliasesMissing.onRowCheckboxChanged(),
    },
  };
}
