import { CalendarBase } from '@demica/resources/calendar';

import { EntityReference } from '../../../interface/entity-reference.interface';
import { HasEntityId } from '../../../interface/has-entity-id.interface';
import { Range } from './range.interface';

export interface FundingInstrumentFunderFee {
  feeType: EntityReference;
  name: string;
  calculation: HasEntityId;
  feeCurrency: HasEntityId;
  calculationCalendar: HasEntityId | string;
  settlementCalendar: HasEntityId | string;
  cutOffCalendar: HasEntityId | string;
  timeSeries: HasEntityId | string;
  basis: HasEntityId;
  basisCurrency: HasEntityId;
  specificCurrency: HasEntityId;
  range: HasEntityId;
  rateType: HasEntityId;
  baseRate: HasEntityId | string;
  rateBasis: HasEntityId;
  floor: number;
  cap: number;
  feeRanges: Range[];
}

export interface FundingInstrumentFunderFeeIdentified extends FundingInstrumentFunderFee {
  localId: number;
}

export interface FundingInstrumentFunderFeeTableView extends FundingInstrumentFunderFeeIdentified {
  calculationCalendar: CalendarBase;
  name: string;
}

export const FundingInstrumentFunderFee = {
  createEmpty: (): FundingInstrumentFunderFee => ({
    feeType: { entityId: null, name: null },
    name: null,
    calculation: { entityId: null },
    feeCurrency: { entityId: null },
    calculationCalendar: { entityId: null },
    settlementCalendar: { entityId: null },
    cutOffCalendar: { entityId: null },
    timeSeries: { entityId: null },
    basis: { entityId: null },
    basisCurrency: { entityId: null },
    specificCurrency: { entityId: null },
    range: { entityId: null },
    rateType: { entityId: null },
    baseRate: { entityId: null },
    rateBasis: { entityId: null },
    floor: null,
    cap: null,
    feeRanges: [],
  }),
};
