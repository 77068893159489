<ng-container *ngIf="transactionInstances$ | async as transactionInstances">
  <div
    class="column-label"
    [ngClass]="{ 'row-disabled': isDisabled }"
  >
    <label
      class="required"
      [for]="labelForId"
      >{{ "DASHBOARD_STATUS_UPLOAD.TRANSACTION_INSTANCE" | translate }}</label
    >
  </div>
  <ng-select
    #select
    class="form-control"
    tabIndex="0"
    role="combobox"
    [attr.aria-describedby]="labelForId + '_notification'"
    [attr.aria-required]="true"
    [attr.aria-expanded]="select.isOpen"
    [clearable]="false"
    [ngModel]="selected"
    [labelForId]="labelForId"
    [items]="transactionInstances"
    [searchable]="false"
    [tabIndex]="-1"
    [disabled]="isDisabled"
    [placeholder]="'DASHBOARD_STATUS_UPLOAD.FORM_ALL_FILTERED_TRANSACTION_INSTANCE' | translate"
    (change)="change($event)"
    (close)="select.element.focus()"
    data-testid="transaction-instance-select"
  >
    <ng-template
      ng-label-tmp
      let-item="item"
    >
      <span data-testid="select-value-text">
        {{ item | transactionInstanceToString }}
      </span>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item="item"
    >
      <span
        class="ng-value-label"
        [attr.title]="item | transactionInstanceToString"
        data-testid="select-option-text"
      >
        {{ item | transactionInstanceToString }}
      </span>
    </ng-template>
  </ng-select>
  <div class="validation-error">
    <ngp-inline-notification
      [id]="labelForId + '_notification'"
      [message]="validations | firstError: formControlName"
    >
    </ngp-inline-notification>
  </div>
</ng-container>
