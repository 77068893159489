import { EntityId } from '../interface/has-entity-id.interface';
import { Environment } from '../model/environment.interface';
import { FilterParameters } from '../model/filter/filter-parameters.interface';

export interface DilutionTriggerFilters {
  chartParams: { programmeLevel: string; numberOfMonths: string };
  filters: {
    [p: string]: unknown;
    transactionIds: EntityId[];
    opcoIds: EntityId[];
    environmentIds: EntityId[];
    clientIds: EntityId[];
  };
}

export const ALL_PRODUCTION_OPTION_ID = -1;
export const ENV_TYPE_PRODUCTION = 1;
export const ENV_TYPE_NON_PRODUCTION = 2;

export const ONE_MONTH = '1';
export const QUARTER = '3';
export const YEAR = '12';

export function ineligibleFilterParams(
  numberOfMonths: number,
  filters: FilterParameters,
  extendFilter?: Record<string, unknown>,
) {
  return {
    chartParams: { numberOfMonths },
    filters: prepareFilters(filters, extendFilter),
  };
}

export function basicFilterParams(
  filters: FilterParameters,
  extendFilter?: Record<string, unknown>,
) {
  return {
    filters: prepareFilters(filters, extendFilter),
  };
}

export function portfolioOverviewCumulativeCollectionsFilter(
  requestedDate: string,
  filters: FilterParameters,
) {
  return {
    chartParams: {
      requestedDate,
    },
    filters: prepareFilters(filters),
  };
}

export function portfolioOverviewValueFilter(
  programmeLevel: string,
  numberOfMonths: string,
  filters: FilterParameters,
) {
  return {
    chartParams: {
      programmeLevel,
      numberOfMonths,
    },
    filters: prepareFilters(filters),
  };
}

export function availabilityTrendValueFilter(
  numberOfMonths: string,
  filters: FilterParameters,
  extendFilter?: Record<string, unknown>,
) {
  return {
    chartParams: {
      numberOfMonths,
    },
    filters: prepareFilters(filters, extendFilter),
  };
}

export function ageingValueFilter(
  programmeLevel: string,
  numberOfMonths: string,
  filters: FilterParameters,
) {
  return {
    chartParams: {
      programmeLevel,
      numberOfMonths,
    },
    filters: prepareFilters(filters),
  };
}

export function prepareFilters(filters: FilterParameters, extendFilter?: Record<string, unknown>) {
  return {
    environmentIds: prepareEnvironments(filters.availableEnvironments, filters.environments),
    clientIds: filters.clients.map((value) => value.entityId),
    opcoIds: filters.opcos.map((o) => o.entityId),
    transactionIds: filters.transactions.map((t) => t.entityId),
    ...extendFilter,
  };
}

export function prepareEnvironments(available: Environment[], selected: Environment[]) {
  if (selected.length === 0) {
    return available.map((e) => e.entityId);
  }

  const envIds = new Set<EntityId>();

  selected.filter((e) => e.entityId >= 0).forEach((e) => envIds.add(e.entityId));

  if (selected.find((e) => e.entityId === ALL_PRODUCTION_OPTION_ID))
    available
      .filter((e) => e.type !== ENV_TYPE_NON_PRODUCTION)
      .forEach((e) => envIds.add(e.entityId));

  return Array.from(envIds.values());
}
