import { range } from '@demica/core/core';

import { USER_DEFINITION_IDS } from '../model/user-definition';
import {
  AMOUNT_COLUMN,
  COUNTERPARTY_CITY_COLUMN,
  COUNTERPARTY_CODE_COLUMN,
  COUNTERPARTY_GROUP_CODE_COLUMN,
  COUNTERPARTY_GROUP_NAME,
  COUNTERPARTY_NAME_COLUMN,
  COUNTERPARTY_STATE_COLUMN,
  CREDIT_RATING,
  CURRENCY,
  DUE_DATE_COLUMN,
  FUNDING_DATE,
  INVOICE_ID_COLUMN,
  PERCENTAGE,
  RECEIVABLE_DATE_COLUMN,
  RECEIVABLE_TYPE,
  RISK_FACTOR_VALUE,
  SCHEDULE_ID_COLUMN,
  SELLER_CODE_COLUMN,
  TRUST_COLUMN,
} from './file-format-column-definition.const';

export const additionalDataColumnTypeIds = range(101, 112);

export const userDefinedColumnTypeIds = [...USER_DEFINITION_IDS];

export const columnTypeIdsForMandatoryField = [
  ...range(1, 112).asArray(),
  SCHEDULE_ID_COLUMN,
  RISK_FACTOR_VALUE,
  ...USER_DEFINITION_IDS,
];

export const columnTypeIdsForDateFormatField = [...range(4, 6).asArray(), FUNDING_DATE];

export const columnTypeIdsForIdColumnField = [
  INVOICE_ID_COLUMN,
  DUE_DATE_COLUMN,
  ...range(7, 18).asArray(),
  ...userDefinedColumnTypeIds,
  COUNTERPARTY_GROUP_NAME,
  COUNTERPARTY_GROUP_CODE_COLUMN,
];

export const columnTypeIdsForAutoCreateAliasesField = [
  ...userDefinedColumnTypeIds,
  COUNTERPARTY_CODE_COLUMN,
];

export const columnTypeIdsForAnalysisGroup = userDefinedColumnTypeIds;

export const columnTypeIdsForValidationRule = [
  CURRENCY,
  TRUST_COLUMN,
  COUNTERPARTY_CODE_COLUMN,
  ...range(15, 17).asArray(),
  ...userDefinedColumnTypeIds,
];

export const columnTypeIdsForInheritanceRule = [
  TRUST_COLUMN,
  ...range(15, 17).asArray(),
  ...userDefinedColumnTypeIds,
  COUNTERPARTY_GROUP_CODE_COLUMN,
];

export const columnTypeIdsForUseDefaultValueIfMissing = [
  TRUST_COLUMN,
  ...range(15, 17).asArray(),
  ...userDefinedColumnTypeIds,
  COUNTERPARTY_GROUP_CODE_COLUMN,
];

export const columnTypeIdsRequiredAndMandatory = [
  AMOUNT_COLUMN,
  RECEIVABLE_DATE_COLUMN,
  SCHEDULE_ID_COLUMN,
  RECEIVABLE_TYPE,
  SELLER_CODE_COLUMN,
];

export const columnTypeIdsWithReportChanges = [SELLER_CODE_COLUMN];

export const columnTypeIdDefaultMandatoryFalseValue = [CREDIT_RATING];

export const columnTypeIdsForIdColumnIsDisabled = [INVOICE_ID_COLUMN];

export const columnTypeIdsForIdColumnIsChecked = [INVOICE_ID_COLUMN];

export const columnTypeIdsForDisplayingDefaultValue = [
  TRUST_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
  COUNTERPARTY_CITY_COLUMN,
  COUNTERPARTY_STATE_COLUMN,
  COUNTERPARTY_GROUP_CODE_COLUMN,
  ...userDefinedColumnTypeIds,
];

export const columnTypeIdsForLimitsFile = [AMOUNT_COLUMN, PERCENTAGE, CURRENCY, RISK_FACTOR_VALUE];

export const excludedColumnTypeIdsForDebtorFiles = [SELLER_CODE_COLUMN];

const excludedColumnTypeIdsForAssetAndDebtorFiles = [
  SCHEDULE_ID_COLUMN,
  RISK_FACTOR_VALUE,
  PERCENTAGE,
];

export function isColumnTypeApplicableForAssetsAndDebtors(columnTypeId: number): boolean {
  return !excludedColumnTypeIdsForAssetAndDebtorFiles.includes(columnTypeId);
}
