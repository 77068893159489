import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { LocalisationService } from '@demica/core/core';

import { DecimalValuePipe } from '../pipe/decimal-value.pipe';

import { DecimalValueAccessorDirective } from './decimal-value-accessor.directive';

@Directive({
  selector: '[trfDecimalFormatter]',
  hostDirectives: [DecimalValueAccessorDirective],
})
export class DecimalFormatterDirective implements OnDestroy {
  @Input()
  formControl: AbstractControl;

  /**
   * Emits a normalized value whenever it is a valid-localised number
   *
   * In other cases it emits user input value with isNotValidLocalisedNumber true
   */
  @Output()
  decimalFormatted = new EventEmitter<{ value: string; isNotValidLocalisedNumber: boolean }>();

  private _nativeElement: HTMLInputElement;
  private _destroyed$ = new Subject<void>();

  constructor(
    private _renderer2: Renderer2,
    private _elRef: ElementRef,
    private _decimalValuePipe: DecimalValuePipe,
    private _localisationService: LocalisationService,
  ) {
    this._nativeElement = this._elRef.nativeElement;
    this._localisationService.locale
      .pipe(skip(1), takeUntil(this._destroyed$))
      .subscribe(() => this.onBlur(this.getRawValue()));
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  getRawValue(): string {
    return '' + (this.formControl?.value ?? this._nativeElement.value);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: string): void {
    if (this._decimalValuePipe.isValidLocalisedNumber(value)) {
      const parsedValue = value === '' ? '' : this._decimalValuePipe.parse(value);
      this._setProperty(parsedValue);
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    if (this._decimalValuePipe.isValidLocalisedNumber(value)) {
      const normalizedValue = this._decimalValuePipe.normalizeValue(value);
      this.decimalFormatted.emit({ value: normalizedValue, isNotValidLocalisedNumber: false });
      const formatted = this._decimalValuePipe.transform(normalizedValue);
      this._setProperty(formatted);
    } else {
      this.decimalFormatted.emit({ value, isNotValidLocalisedNumber: true });
    }
  }

  private _setProperty(value: string): void {
    this._renderer2.setProperty(this._nativeElement, 'value', value);
  }
}
