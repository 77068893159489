import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FundingAlertsCounterService } from '@demica/core/core';
import { FundingAlertsCount } from '@demica/core/core';

@Component({
  selector: 'trf-header-funding-alerts',
  templateUrl: './header-funding-alerts.component.html',
})
export class HeaderFundingAlertsComponent implements OnInit, OnDestroy {
  @Output()
  loaded = new EventEmitter<boolean>();

  @Output()
  fundingAlertsCounter = new EventEmitter<number>();

  loading = true;

  riskCount = 0;
  riskWarningCount = 0;
  watchCount = 0;
  watchWarningCount = 0;
  performingCount = 0;

  private destroy$ = new Subject<void>();

  constructor(private fundingAlerts: FundingAlertsCounterService) {}

  ngOnInit() {
    this.fundingAlerts.alertsCount$.pipe(takeUntil(this.destroy$)).subscribe((alertsCount) => {
      this.riskCount = alertsCount.atRisk;
      this.watchWarningCount = alertsCount.watchListWarning;
      this.riskWarningCount = alertsCount.atRiskWarning;
      this.performingCount = alertsCount.performing;
      this.watchCount = alertsCount.watchList;

      this.fundingAlertsCounter.emit(this.getNumberOfFundingAlerts(alertsCount));

      this.loading = false;
      this.loaded.emit(true);
    });
  }

  getNumberOfFundingAlerts(alertsCount: FundingAlertsCount) {
    return (
      alertsCount.atRisk +
      alertsCount.atRiskWarning +
      alertsCount.watchList +
      alertsCount.watchListWarning +
      alertsCount.performing
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
