import { UserAuthContext } from '../../../model/auth/user-auth.context.interface';

export enum UserAuthContextChangeReason {
  NOT_CHANGED,
  IDENTITY_CHANGED,
  REGION_CHANGED,
}

export const isUserAuthContextObject = (obj: unknown): obj is UserAuthContext => {
  return (obj as UserAuthContext).userRegionContextId !== undefined;
};
