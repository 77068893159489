import { DOCUMENT, FormatWidth } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

import { fromEvent, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {
  generateUUID,
  getFormControlName,
  LocaleFormats,
  LocalisationService,
  SlideinContext,
  TypedChanges,
} from '@demica/core/core';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { NgbDateNativeAdapter } from '../date-time-common/date-native-adapter';
import { CustomNgbDateParserFormatter } from '../date-time-common/date-parser-formatter';
import { CustomDatepickerI18n } from '../date-time-common/date-picker-i18n';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'trf-form-datepicker-row',
  templateUrl: 'form-datepicker-row.component.html',
  styleUrls: ['./form-datepicker-row.component.sass'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class FormDatepickerRowComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  name?: string;
  @Input()
  control?: FormControl;
  @Input()
  fieldPath: string;
  @Input()
  disabled: boolean;
  @Input()
  label?: string;
  @Input()
  labelClasses?: string;
  @Input()
  validations?: ValidationMessage[];
  @Input()
  inputClasses?: string[];
  @Input()
  startDate?: Date;
  @Input()
  endDate?: Date;
  @Input()
  reverseDirection?: boolean;
  @Input()
  updateSlideInContext = true;

  @ViewChild('datepicker', { static: true })
  datePicker: NgbInputDatepicker;

  group: FormGroup;
  formControlName: string;
  formControl: AbstractControl;
  dateFormat: LocaleFormats = {
    format: '',
    formatLocalized: '',
  };
  uniqueId: string;

  minDate = { year: 1900, month: 1, day: 1 };
  maxDate = { year: 2999, month: 12, day: 31 };

  private _documentClickSubscription: Subscription;
  private _destroyed$ = new Subject<void>();

  constructor(
    private _fgd: FormGroupDirective,
    private _slideInContext: SlideinContext,
    private _ngbDateAdapter: NgbDateAdapter<Date>,
    private _localisationService: LocalisationService,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this.uniqueId = generateUUID();
  }

  ngOnChanges(changes: TypedChanges<FormDatepickerRowComponent>): void {
    if (changes.startDate?.currentValue) {
      this.minDate = this._ngbDateAdapter.fromModel(this.startDate);
    }

    if (changes.endDate?.currentValue) {
      this.maxDate = this._ngbDateAdapter.fromModel(this.endDate);
    }

    if (!this.group) {
      return;
    }

    if (changes.disabled) {
      const formControl = this.formControl;
      changes.disabled.currentValue ? formControl.disable() : formControl.enable();
    }
  }

  ngOnInit(): void {
    this._createFormControl();

    this.formControl.valueChanges
      .pipe(
        filter(() => this.updateSlideInContext),
        takeUntil(this._destroyed$),
      )
      .subscribe(() => (this._slideInContext.isFormDirty = true));

    this._localisationService.locale.pipe(takeUntil(this._destroyed$)).subscribe((locale) => {
      this.dateFormat = {
        format: this._localisationService.getLocaleDateFormat(locale, FormatWidth.Short),
        formatLocalized: this._localisationService.getLocaleDateFormatLocalised(
          locale,
          FormatWidth.Short,
        ),
      };

      this._initializeDatePicker();
    });

    this.datePicker.closed.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this._documentClickSubscription.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onClickAction(): void {
    if (!this.disabled) {
      setTimeout(() => {
        this.datePicker.toggle();
        this._startDatePickerOverlayDocumentListener();
      });
    }
  }

  onKeyAction(): void {
    if (!this.datePicker.isOpen()) {
      this.onClickAction();
    }
  }

  closeDatepickerOnclick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (
      !!target?.offsetParent &&
      target.offsetParent.tagName !== 'NGB-DATEPICKER' &&
      target.offsetParent.id !== this.uniqueId &&
      this.datePicker.isOpen()
    ) {
      this.datePicker.close();
      this._documentClickSubscription.unsubscribe();
    }
  }

  private _createFormControl(): void {
    this.group = this._fgd.form;
    this.formControlName = this.name ?? getFormControlName(this.control);
    this.formControl = this.control ?? this.group.get(this.formControlName);
  }

  private _initializeDatePicker(): void {
    const initDate: string = this.formControl.value;
    let date: Date = null;
    if (initDate !== '' && initDate !== null) {
      date = new Date(initDate);
    }

    this.formControl.setValue(date, { emitEvent: false });

    if (this.disabled !== true && this.disabled !== false) {
      this.disabled = false;
    }
  }

  private _startDatePickerOverlayDocumentListener(): void {
    this._documentClickSubscription = fromEvent(this._document, 'click')
      .pipe(
        filter(() => this.datePicker.isOpen()),
        takeUntil(this._destroyed$),
      )
      .subscribe((event: MouseEvent) => this.closeDatepickerOnclick(event));
  }
}
