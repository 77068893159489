import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { OnboardingViewComponent } from './onboarding-view.component';

@NgModule({
  exports: [OnboardingViewComponent],
  imports: [TranslateModule.forChild()],
  declarations: [OnboardingViewComponent],
})
export class OnboardingModule {}
