<span class="menu-section-title">{{ (element | menuSectionCast).sectionTitle | translate }}</span>
<ul class="menu-items">
  <ng-container *ngFor="let menuOption of menuOptions; trackBy: menuOptionTrackBy">
    <ng-container
      *ifHasFeatureToggles="
        (menuOption.element | menuItemCast).permissions.requiredFeatureToggleGroup
      "
    >
      <ng-container
        *ifHasAuthority="(menuOption.element | menuItemCast).permissions.requiredAuthority"
      >
        <li>
          <trf-menu-item
            *ngIf="menuOption.enabled === true"
            [attr.testid]="menuOption.id"
            [menuOption]="menuOption"
          ></trf-menu-item>
        </li>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>
