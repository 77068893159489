import { UserDefinitionDataTypeEnum } from '../../../../model/user-definition-data-type';
import { UserDefinition } from '../../../../model/user-definition.interface';

export function getCurrentColumnTypeUserDefinition(
  userDefinitions: UserDefinition[],
  columnTypeId: number,
) {
  return userDefinitions?.find((userDefinition) => userDefinition.typeId === columnTypeId);
}

export function isUserDefinitionOfAnalysisGroupType(userDefinition: UserDefinition) {
  if (!userDefinition) return false;
  return userDefinition.dataType.type.entityId === UserDefinitionDataTypeEnum.AnalysisGroup;
}
