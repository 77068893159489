import { AbstractControl, FormControl } from '@angular/forms';

export function getFormControlName(c: AbstractControl): string | null {
  // Omitting FormArray in type casting
  const siblings: Record<string, AbstractControl> =
    (c.parent?.controls as Record<string, AbstractControl>) ?? {};

  return Object.keys(siblings).find((name) => c === siblings[name]) || null;
}

export const ENABLED_STATUS = true;
export const DISABLED_STATUS = false;
export function setStatusForControls(
  controls: FormControl[],
  status: typeof ENABLED_STATUS | typeof DISABLED_STATUS,
) {
  controls.forEach((field) => (status ? field.enable() : field.disable()));
}
