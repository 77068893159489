import { Injectable, OnDestroy, NgZone } from '@angular/core';

import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

@Injectable()
export class TokenStoreService implements OnDestroy {
  tokenChanged$: Observable<boolean>;

  private _destroyed$ = new Subject<void>();
  private _tokenChanged$ = new BehaviorSubject<boolean | null>(null);
  private _tokenOnAuthRefreshSuccess$ = this.keycloakService.keycloakEvents$.pipe(
    filter((ev) => ev.type === KeycloakEventType.OnAuthRefreshSuccess),
  );
  private _tokenOnAuthSuccess$ = this.keycloakService.keycloakEvents$.pipe(
    filter((ev) => ev.type === KeycloakEventType.OnAuthSuccess),
  );
  private _tokenOnAuthLogout$ = this.keycloakService.keycloakEvents$.pipe(
    filter((ev) => ev.type === KeycloakEventType.OnAuthLogout),
  );

  constructor(private keycloakService: KeycloakService, private _ngZone: NgZone) {
    this.tokenChanged$ = this._tokenChanged$.asObservable();

    merge(this._tokenOnAuthSuccess$, this._tokenOnAuthRefreshSuccess$, this._tokenOnAuthLogout$)
      .pipe(
        map(() => this.getToken()),
        distinctUntilChanged(),
        map(Boolean),
        takeUntil(this._destroyed$),
      )
      .subscribe((hasTokenChanged: boolean) => {
        this._ngZone.run(() => {
          this._tokenChanged$.next(hasTokenChanged);
        });
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  getToken(): string | null {
    return this.keycloakService.getKeycloakInstance().token;
  }
}
