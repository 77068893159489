import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective, FormControl } from '@angular/forms';

import { getFormControlName } from '@demica/core/core';

@Component({
  selector: 'trf-form-checkbox-row',
  templateUrl: 'form-checkbox-row.component.html',
  styleUrls: ['./form-checkbox-row.component.sass'],
})
export class FormCheckboxRowComponent implements OnInit {
  @Input()
  name?: string;
  @Input()
  control?: FormControl;
  @Input()
  inputClasses?: string;
  @Input()
  label: string;
  @Input()
  labelClasses?: string;
  @Input()
  value: string;
  @Input()
  infoTooltip?: string;
  @Input()
  disabled?: boolean;
  @Input()
  infoTooltipOnDisabled?: string;

  @Output()
  checkboxChecked = new EventEmitter<boolean>();

  group: UntypedFormGroup;
  formControlName: string;

  constructor(private fgd: FormGroupDirective) {}

  ngOnInit() {
    this.group = this.fgd.form;
    this.formControlName = this.name ?? getFormControlName(this.control);
  }

  onChange(value: boolean) {
    this.checkboxChecked.emit(value);
  }
}
