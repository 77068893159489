export interface SubjectValidity {
  validationErrors: SubjectValidationItem[];
}

export interface SubjectValidationItem {
  invalidityReason: string;
  severity: SubjectValidationSeverity;
  metadata: Record<string, string>;
}

export enum SubjectValidationSeverity {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
