<div
  [class]="'context-notification-body ' + type"
  [ngClass]="{ expandable: expandable }"
>
  <ng-template [ngIf]="expandable">
    <ng-container *ngTemplateOutlet="expandableTemplate"></ng-container>
  </ng-template>
  <ng-template [ngIf]="!expandable">
    <ng-container *ngTemplateOutlet="nonExpandableTemplate"></ng-container>
  </ng-template>
</div>
<ng-template #expandableTemplate>
  <cdk-accordion>
    <cdk-accordion-item
      #accordionItem="cdkAccordionItem"
      (click)="accordionItem.toggle()"
      (keydown.enter)="accordionItem.toggle()"
      [attr.aria-controls]="'accordion-body'"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.id]="'accordion-header'"
      role="button"
      tabindex="0"
      data-testid="notification-expandable-header"
    >
      <div class="header">
        <ng-container *ngTemplateOutlet="notificationIcon"></ng-container>
        <div
          class="header-wrapper"
          data-testid="header-wrapper"
        >
          <ng-container *ngIf="expandableText">{{ expandableText }}</ng-container>
          <ng-container *ngIf="expandableCustomTemplate">
            <ng-container *ngTemplateOutlet="expandableCustomTemplate"></ng-container>
          </ng-container>
        </div>
        <ngp-svg-icon
          [iconName]="accordionItem.expanded ? 'chevron-up' : 'chevron-down'"
          class="icon expand-icon d-inline-block"
          data-testid="expand-icon"
        ></ngp-svg-icon>
      </div>
      <div
        *ngIf="accordionItem.expanded"
        [@expandAnimation]="accordionItem.expanded"
        [attr.aria-hidden]="!accordionItem.expanded"
        [attr.aria-labelledby]="'accordion-header'"
        [attr.id]="'accordion-body'"
        class="body"
        role="region"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>
</ng-template>
<ng-template #nonExpandableTemplate>
  <ng-container *ngTemplateOutlet="notificationIcon"></ng-container>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #notificationIcon>
  <ngp-svg-icon
    [iconName]="icons[type]!"
    class="icon notification-icon"
    data-testid="svg-icon-component"
  ></ngp-svg-icon>
</ng-template>
<ng-template #content>
  <div
    class="description"
    role="alert"
    data-testid="alert-description"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
