import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { encodeEndpoint, RestResponse, toData } from '@demica/core/core';

import { EnvironmentDetails } from '../model/environment.details';

export const ENVIRONMENTS_DETAILS_REST_PATH =
  'resources/dashboards/filters/pushed-transactions/details';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentsDetailsResourcesService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http
      .get<RestResponse<EnvironmentDetails[]>>(encodeEndpoint(ENVIRONMENTS_DETAILS_REST_PATH))
      .pipe(map(toData));
  }
}
