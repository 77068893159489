import { Injectable } from '@angular/core';

import { Observable, zip } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { TranslateLoader } from '@ngx-translate/core';

import { TranslationsPackage, TranslationsResourceService } from '@demica/resources/translations';

import { AppConfigService } from '../../app-config/app-config.service';
import { FakeTranslationGeneratorService } from '../fake-translation-generator/fake-translation-generator.service';

import { Locale } from '../../../config/locale.enum';

export const DEFAULT_LANG = Locale.EN_GB;

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  private _defaultLangRequest$: Observable<TranslationsPackage> = this._translationsResourceService
    .getTranslations$(this._appConfig.getLanguageFileURL(DEFAULT_LANG))
    .pipe(shareReplay(1));

  constructor(
    private _translationsResourceService: TranslationsResourceService,
    private _appConfig: AppConfigService,
    private _fakeTranslationGeneratorService: FakeTranslationGeneratorService,
  ) {}

  getTranslation(lang: Locale): Observable<TranslationsPackage> {
    return zip(
      this._appConfig.getSupportedLocalisations$(),
      this._appConfig.getMockedLocalisations$(),
    ).pipe(
      switchMap(([localisations, mockedLocalisations]) => {
        if (mockedLocalisations.includes(lang)) {
          return this._generateMockedTranslations$(lang);
        } else if (localisations.includes(lang)) {
          return this._loadTranslationsWithFallback$(lang);
        }

        return this._defaultLangRequest$;
      }),
    );
  }

  private _loadTranslationsWithFallback$(lang: Locale): Observable<TranslationsPackage> {
    return this._translationsResourceService
      .getTranslations$(this._appConfig.getLanguageFileURL(lang))
      .pipe(catchError(() => this._defaultLangRequest$));
  }

  private _generateMockedTranslations$(lang: Locale): Observable<TranslationsPackage> {
    return this._defaultLangRequest$.pipe(
      map((response) => this._fakeTranslationGeneratorService.generate(response, lang)),
    );
  }
}
