import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { forkJoin, Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';

import {
  AppConfigService,
  EntityId,
  EntityReference,
  UserAccountResourceService,
  UserRegionService,
} from '@demica/core/core';

@Component({
  selector: 'trf-regions-dropdown',
  styleUrls: ['./regions-dropdown.component.sass'],
  templateUrl: 'regions-dropdown.component.html',
})
export class RegionsDropdownComponent implements OnInit, OnDestroy {
  dropdownVisible = false;

  selectedRegion: EntityReference;
  availableRegions: EntityReference[];
  @ViewChild('dropdownButton')
  dropdownButton: ElementRef;
  @ViewChild('ulElement')
  ulElement: ElementRef;

  private _destroyed$ = new Subject<void>();

  constructor(
    private config: AppConfigService,
    private userAccountService: UserAccountResourceService,
    private userRegionService: UserRegionService,
    private router: Router,
  ) {}

  ngOnInit() {
    this._getData();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  changeRegion(region: EntityReference): void {
    this.router.navigate(['/organization-unit-change', region.entityId]);
    this.dropdownVisible = !this.dropdownVisible;
    this.dropdownButton.nativeElement.focus();
  }

  closeDropdown(): void {
    this.dropdownVisible = false;
    this.dropdownButton.nativeElement.focus();
  }

  focusOnFirstElement() {
    this.ulElement.nativeElement.children[0].focus();
  }

  focusOnLastElement() {
    this.ulElement.nativeElement.children[this.availableRegions.length - 1].focus();
  }

  isActive(region: EntityReference) {
    return region.entityId.toString() === this.selectedRegion.entityId.toString();
  }

  onRegionSelect(region: EntityReference<EntityId>) {
    if (this.isActive(region)) return;
    this.changeRegion(region);
  }

  private _getData(): void {
    const selectedRegion = this.userRegionService.getOrganizationUnitReference$().pipe(first());
    const availableRegions = this.userAccountService.getUserOrganizationUnit().pipe(first());
    forkJoin({ selectedRegion, availableRegions })
      .pipe(
        tap(({ selectedRegion, availableRegions }) => {
          this.selectedRegion = selectedRegion;
          this.availableRegions = availableRegions;
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }
}
