<form
  *ngIf="parentForm.controls.countriesGroup as currentFormGroup"
  [formGroup]="currentFormGroup"
  [ngClass]="{ 'trf-form-submitted': submitted }"
>
  <trf-form-select-row
    label="ALIASES_MANAGEMENT.ANALYSIS_CODE"
    labelClasses="required"
    defaultLabel="ALIASES_MANAGEMENT.COUNTRY_PLACEHOLDER"
    [options]="countries"
    [control]="currentFormGroup.controls.countries"
    [validations]="validations.countries"
    data-testid="analysis-code-name"
  ></trf-form-select-row>
</form>
