import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { CurrencyCode, EntityId, EntityReference } from '@demica/core/core';

import { DynamicFormDirective } from '../../../../forms/dynamic-form.directive';
import { requireSelect } from '../../../../forms/validators';
import { AliasFormValidations } from '../../../../validation/alias-form-validations';
import { AliasesValidator } from '../../../../validation/analysis-code-form-validations';

export type AliasesManagementCurrencySelectorDynamicGroup = {
  currencyCodesGroup?: FormGroup<{ currencyCodes: FormControl<EntityReference> }>;
};

@Component({
  selector: 'trf-aliases-management-currency-selector-group',
  templateUrl: './aliases-management-currency-selector-group.component.html',
  styleUrls: ['./aliases-management-currency-selector-group.component.sass'],
})
export class AliasesManagementCurrencySelectorGroupComponent extends DynamicFormDirective<
  AliasesManagementCurrencySelectorDynamicGroup,
  'currencyCodesGroup'
> {
  @Input()
  currencyCodes: CurrencyCode[] = [];

  @Input()
  transactionId: EntityId;

  @Input()
  previewMode: boolean;

  constructor(fb: FormBuilder, private _aliasFormValidations: AliasFormValidations) {
    super(fb);
  }

  getFormGroup() {
    return {
      name: 'currencyCodesGroup' as const,
      controls: [
        {
          name: 'currencyCodes' as const,
          defaultValue: null as EntityReference,
          validations: [requireSelect],
          asyncValidations: this.previewMode
            ? []
            : [this._aliasFormValidations.hasAvailableCurrencyCode(this.transactionId)],
          messages: AliasesValidator.code,
        },
      ],
    };
  }
}
