<div class="input-group">
  <input
    class="form-control"
    [ngModel]="_dateTimeInnerControl.value | localisedDate: 'medium'"
    [disabled]="disabled"
    [placeholder]="dateTimeFormat.formatLocalized"
    (blur)="onInputBlur()"
    (change)="onInputChange($event)"
    data-testid="date-time-picker-input"
  />
  <div class="input-group-append">
    <button
      class="input-group-text"
      popoverClass="date-time-picker-popover"
      container="body"
      [disabled]="disabled"
      [ngbPopover]="calendarContent"
      [placement]="['bottom-right', 'top-right']"
      [triggers]="disabled ? 'manual' : 'click'"
      (click)="onPickerOpen()"
      (keydown.enter)="onPickerOpen()"
      (keypress.space)="onPickerOpen()"
      data-testid="picker-popup-button"
    >
      <ngp-svg-icon iconName="calendar-alt"></ngp-svg-icon>
    </button>
  </div>
</div>

<ng-template #calendarContent>
  <div class="calendar-content">
    <div *ngIf="!showTimePickerToggle">
      <ngb-datepicker
        [attr.aria-label]="'DATEPICKER.BUTTON' | translate"
        [formControl]="_dateTimeInnerControl"
      ></ngb-datepicker>
      <div class="calendar-switch-button">
        <ngp-button
          classes="tertiary"
          key="DATEPICKER.CHOOSE_TIME"
          (click)="toggleDateTimeState($event)"
          data-testid="toggle-timepicker"
        ></ngp-button>
      </div>
    </div>
    <div *ngIf="showTimePickerToggle">
      <ngb-timepicker
        [formControl]="_dateTimeInnerControl"
        [meridian]="meridian"
        [seconds]="seconds"
        [hourStep]="hourStep"
        [minuteStep]="minuteStep"
        [secondStep]="secondStep"
      ></ngb-timepicker>
      <div class="calendar-switch-button">
        <ngp-button
          classes="tertiary"
          key="DATEPICKER.CHOOSE_DATE"
          [ngbPopover]="calendarContent"
          (click)="toggleDateTimeState($event)"
          data-testid="toggle-datepicker"
        ></ngp-button>
      </div>
    </div>
  </div>
</ng-template>
