import { HttpParams } from '@angular/common/http';

import { PaginationParams } from '../../interface/pageable-data.interface';

export function createPaginationParams(pagination: PaginationParams): HttpParams {
  let httpParams = new HttpParams()
    .append('page', String(!pagination.pageNumber ? 0 : pagination.pageNumber))
    .append('size', String(!pagination.size ? 25 : pagination.size));

  if (pagination.sort !== undefined) {
    pagination.sort.forEach(
      (sortParam) => (httpParams = httpParams.append('sort', String(sortParam))),
    );
  }

  return httpParams;
}
