<ng-template
  ngFor
  let-panel
  [ngForOf]="panels"
>
  <div class="card">
    <div
      id="{{ panel.id }}-header"
      role="application"
      aria-roledescription="panel"
      [class]="'card-header ' + (panel.type ? 'bg-' + panel.type : type ? 'bg-' + type : '')"
    >
      <h5 class="mb-0">
        <a
          class="btn btn-link"
          role="tab"
          tabindex="0"
          [class.collapsed]="!panel.isOpen"
          [attr.aria-expanded]="panel.isOpen"
          [attr.aria-controls]="panel.id"
          (keydown.arrowRight)="expand(panel.id)"
          (keydown.arrowLeft)="collapse(panel.id)"
          (click)="toggle(panel.id)"
          (keyup.enter)="toggle(panel.id)"
        >
          {{ panel.title }}
          <ng-template [ngTemplateOutlet]="panel.titleTpl?.templateRef"></ng-template>
        </a>
      </h5>
    </div>
    <div
      *ngIf="!destroyOnHide || panel.isOpen || panel.transitionRunning"
      id="{{ panel.id }}"
      class="collapse"
      role="tabpanel"
      [class.show]="panel.isOpen"
      (ngbRef)="panel.panelDiv = $event"
    >
      <div class="card-body">
        <ng-template [ngTemplateOutlet]="panel.contentTpl?.templateRef"></ng-template>
      </div>
    </div>
  </div>
</ng-template>
