import { Pipe, PipeTransform } from '@angular/core';

import {
  DECIMAL_FORMATS_CONFIGURATION,
  isDefined,
  LEADING_ZEROS_REGEX,
  LocalisationService,
} from '@demica/core/core';

import { encodeRegexChar } from '../utils/encode-regex-char';
import { isValidLocalisedNumber } from '../utils/is-valid-localised-number';

@Pipe({
  name: 'decimalValue',
})
export class DecimalValuePipe implements PipeTransform {
  protected decimalSeparator: string;
  protected thousandSeparator: string;
  protected fractionSize: number;

  constructor(protected localisationService: LocalisationService) {
    localisationService.locale.subscribe((value) => {
      const decimalValuePipeConfiguration = DECIMAL_FORMATS_CONFIGURATION[value];
      this.decimalSeparator = decimalValuePipeConfiguration.decimalSeparator;
      this.thousandSeparator = decimalValuePipeConfiguration.thousandsSeparator;
      this.fractionSize = parseInt(decimalValuePipeConfiguration.decimalPlaces.toString(), 10);
    });
  }

  protected static addLeadingZero = (obj: string): string => {
    return obj.replace(
      /^-?(\.|,)\d/,
      (match) => (match.startsWith('-') ? '-0' : '0') + match.replace('-', ''),
    );
  };

  protected static replaceDecimalSeparator = (obj: string, separator: string): string =>
    obj.match('[\\.]') != null ? obj.replace('.', separator) : obj.replace(',', separator);

  transform(value: number | string | null): string {
    if (!isDefined(value)) {
      return '';
    }

    value = DecimalValuePipe.addLeadingZero(value.toString());
    value = DecimalValuePipe.replaceDecimalSeparator(value.toString(), this.decimalSeparator);

    let [integer, fraction = ''] = (value || '').toString().split(this.decimalSeparator);

    if (fraction !== '') {
      fraction = this.decimalSeparator + fraction;
    }
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator);

    return integer + fraction;
  }

  parse(value: number | string): string {
    let [integer, fraction = ''] = (value || '').toString().split(this.decimalSeparator);

    integer = integer.replace(
      new RegExp(this.thousandSeparator === '.' ? '\\.' : this.thousandSeparator, 'g'),
      '',
    );
    if (fraction !== '') {
      fraction = this.decimalSeparator + fraction;
    }

    return integer + fraction;
  }

  getDecimalSeparator(): string {
    return this.decimalSeparator;
  }

  normalizeValue(value: string): string {
    if (!value) return '';
    return value
      .replace(new RegExp(encodeRegexChar(this.thousandSeparator), 'g'), '')
      .replace(this.decimalSeparator, '.')
      .replace(LEADING_ZEROS_REGEX, '');
  }

  isValidLocalisedNumber(value: string): boolean {
    return isValidLocalisedNumber(value, this.thousandSeparator, this.decimalSeparator);
  }
}
