<table
  class="{{ tableClasses }}"
  tabindex="0"
  role="table"
  [attr.aria-label]="tableLabel | translate"
  [hidden]="!displayTable"
  (keyup)="activateZoomOnRows($event)"
>
  <thead
    role="rowgroup"
    (keyup)="onRowKeyup($event)"
  >
    <span
      #header
      role="row"
    ></span>
  </thead>

  <tbody
    #tableBody
    class="{{ bodyClasses }}"
    role="rowgroup"
    (keyup)="onRowKeyup($event)"
  >
    <span
      #rows
      role="row"
    ></span>
  </tbody>
</table>

<ng-template #tableColumnDropdownToggleTemplate>
  <trf-toggle-columns-dropdown
    [buttonTranslationPrefix]="buttonTranslationPrefix"
    testid="toggle-columns-dropdown"
  ></trf-toggle-columns-dropdown>
</ng-template>
