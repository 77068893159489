import { Pipe, PipeTransform } from '@angular/core';

import { TransactionInstanceReference } from '../../model/transaction-instance-reference.interface';

@Pipe({
  name: 'transactionInstanceToString',
})
export class TransactionInstanceToStringPipe implements PipeTransform {
  transform(value: TransactionInstanceReference): unknown {
    if (!value) return '';
    return `${value.transaction.name} (${value.environment.name})`;
  }
}
