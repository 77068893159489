<trf-modal-container
  [headerText]="'EXPORT_MODAL.TITLE' | translate"
  (modalClose)="onCancel()"
>
  <div class="export-modal-content">
    <div class="trf-section-content pb-0">
      <ng-container *ngIf="loading">
        <ngp-inline-loader-with-status
          [loading]="loading"
          [actionMessage]="'EXPORT_MODAL.GENERATING_FILE' | translate"
          [progressMessage]="progressMessage"
          [doneMessage]="'EXPORT_MODAL.GENERATING_FILE_SUCCESS' | translate"
        ></ngp-inline-loader-with-status>
        <p>{{ "EXPORT_MODAL.PLEASE_WAIT" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="downloading">
        <ngp-inline-loader-with-status
          [loading]="downloading"
          [actionMessage]="'EXPORT_MODAL.DOWNLOADING_FILE' | translate"
          [progressMessage]="progressMessage"
          [doneMessage]="'EXPORT_MODAL.GENERATING_FILE_SUCCESS' | translate"
        ></ngp-inline-loader-with-status>
        <p>{{ "EXPORT_MODAL.PLEASE_WAIT_DOWNLOADING" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="!downloading && !loading">
        <ngp-inline-loader-with-status
          [loading]="false"
          [doneMessage]="'EXPORT_MODAL.GENERATING_FILE_SUCCESS' | translate"
        ></ngp-inline-loader-with-status>
        <p>{{ "EXPORT_MODAL.FILE_READY" | translate }}</p>
      </ng-container>
    </div>
  </div>
  <div class="trf-modal-controls-divider trf-section-buttons space-between">
    <ngp-button
      key="EXPORT_MODAL.BUTTON_CANCEL"
      classes="tertiary"
      (click)="onCancel()"
    ></ngp-button>

    <ngp-button
      key="EXPORT_MODAL.BUTTON_DOWNLOAD"
      [classes]="this.canDownload ? 'primary' : 'inactive'"
      (click)="onDownload()"
    ></ngp-button>
  </div>
</trf-modal-container>
