import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppConfigService, locationRedirect } from '@demica/core/core';

export const healthCheckRedirectGuard: CanActivateFn = (): Observable<boolean> => {
  return inject(AppConfigService).appConfig$.pipe(
    map((config) => {
      const externalUrl = config.healthCheck.externalUrl;

      return externalUrl === 'null' || externalUrl === '' ? null : externalUrl;
    }),
    tap((externalUrl) => {
      if (externalUrl !== null) {
        locationRedirect(externalUrl);
      } else {
        inject(Router).navigateByUrl('dashboard');
      }
    }),
    map(() => false),
  );
};
