import { UntypedFormControl } from '@angular/forms';

import * as moment from 'moment';

export const dateTimeFormatValidator = (fc: UntypedFormControl) => {
  // TODO: strict?
  const converterDateTime = moment(fc.value, 'DD-MM-YYYY H:M:s');

  return converterDateTime.isValid() ? null : { dateTimeFormat: true };
};
