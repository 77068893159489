import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { encodeEndpoint, encodeParams, ExportType } from '@demica/core/core';

import { RequestExport } from '../model/chart-export';

@Injectable({
  providedIn: 'root',
})
export class ChartExportResourceService {
  constructor(private http: HttpClient) {}

  postExportChart(chartType: ExportType, requestChartExport: RequestExport[]) {
    const params = encodeParams({ chartType });
    const url = encodeEndpoint('resources/dashboards/chart-export/download');
    return this.http
      .post(url, requestChartExport, { params, responseType: 'arraybuffer' })
      .pipe(map((res) => new Blob([res], { type: 'application/octet-stream' })));
  }
}
