import { EntityReference, Transaction } from '@demica/core/core';

export enum TransactionTypes {
  'STRUCTURED_RF' = 1,
  'TRANSACTION_LEVEL_FUNDING' = 3,
}

export function isTLFTransaction(transaction: Transaction) {
  return transaction.type.entityId === TransactionTypes.TRANSACTION_LEVEL_FUNDING;
}

export function getTransactionTypeAsEntityReference(
  transactionType: TransactionTypes,
): EntityReference {
  return {
    entityId: transactionType,
    name: TransactionTypes[transactionType],
  };
}
