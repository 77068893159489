<ng-container [formGroup]="group">
  <div class="form-row">
    <div class="date-pickers">
      <trf-form-date-time-picker-row
        [control]="dateTimeFromControl"
        [label]="labelDateTimeFrom"
        [validations]="fromDateTimeValidations"
        [disabled]="disabled"
        [attr.testid]="dateTimeFromTestIdName"
      ></trf-form-date-time-picker-row>
      <trf-form-date-time-picker-row
        [control]="dateTimeToControl"
        [label]="labelDateTimeTo"
        [validations]="toDateTimeValidations"
        [disabled]="disabled"
        [attr.testid]="dateTimeToTestIdName"
      ></trf-form-date-time-picker-row>
    </div>
    <div class="validation-error">
      <ngp-contextual-notifications [notifications]="notifications"></ngp-contextual-notifications>
    </div>
  </div>
</ng-container>
