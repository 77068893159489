import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { invertSortDirection, PageSortParameters, SortDirection } from '@demica/core/core';

export const DEFAULT_SORT_PARAMS = {
  sortColumn: '',
  sortDirection: SortDirection.ASC,
  defaultValue: true,
};

@Injectable()
export class TableSortService {
  private sortParams = new BehaviorSubject<PageSortParameters>(DEFAULT_SORT_PARAMS);
  manualSorted = new BehaviorSubject<boolean>(false);

  get sortParamsChanges() {
    return this.sortParams.asObservable();
  }

  changeSorting(column: string) {
    const currentValue = this.sortParams.getValue();
    this.sortParams.next({
      defaultValue: false,
      sortColumn: column,
      sortDirection:
        currentValue.sortColumn === column
          ? invertSortDirection(currentValue.sortDirection)
          : currentValue.sortDirection,
    });
    this.manualSorted.next(true);
  }

  reset(defaultColumn: string, sortDirection = SortDirection.ASC) {
    this.sortParams.next({
      ...DEFAULT_SORT_PARAMS,
      sortColumn: defaultColumn,
      sortDirection,
    });
  }
}
