import { Injectable, OnDestroy } from '@angular/core';

import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { LoggerService, LogLevel } from '@demica/logger';

import {
  BroadcastMessage,
  BroadcastMessageType,
  isBroadcastMessageType,
} from '../../model/broadcast-message.interface';
import { generateUUID } from '../../utils/uuidGenerator';

@Injectable({
  providedIn: 'root',
})
export class BroadcastChannelService implements OnDestroy {
  static NAMESPACE = 'ngpInstanceBroadcast';
  static INSTANCE_ID_EXPOSE_PROP = `${BroadcastChannelService.NAMESPACE}Id`;
  static CHANNEL_NAME = `${BroadcastChannelService.NAMESPACE}Channel`;

  readonly instanceBroadcastId: string = generateUUID();

  private _subscriptions = new Subscription();
  private _broadcastChannel = new BroadcastChannel(BroadcastChannelService.CHANNEL_NAME);
  private readonly _broadcastMessages$ = fromEvent(this._broadcastChannel, 'message').pipe(
    map((event: MessageEvent) => event.data),
  );
  private readonly _messages$ = new Subject<BroadcastMessage<unknown>>();

  constructor(private _logger: LoggerService) {}

  initialize(): void {
    this._exposeInstanceId();
    this._startListening();
    this._logger.addConsoleLog(
      `___________BROADCAST_ID___${this.instanceBroadcastId}___________`,
      LogLevel.INFO,
    );
  }

  getMessages$<T>(): Observable<BroadcastMessage<T>> {
    return this._messages$.asObservable() as Observable<BroadcastMessage<T>>;
  }

  getMessagesOfType$<T = unknown>(type: BroadcastMessageType): Observable<BroadcastMessage<T>> {
    return this.getMessages$<T>().pipe(filter((message) => message.type === type));
  }

  broadcastMessage(message: BroadcastMessage<unknown>): void {
    this._logger.addConsoleLog(
      `OUTGOING BROADCAST MESSAGE ${JSON.stringify(message)}`,
      LogLevel.TRACE,
    );
    this._broadcastChannel.postMessage(message);
  }

  generateMessage<T = null>(
    type: BroadcastMessageType,
    data: T | null = null,
    version = 1,
  ): BroadcastMessage<T> {
    return {
      type,
      version,
      timestamp: Date.now(),
      sender: this.instanceBroadcastId,
      data,
    };
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._logger.addConsoleLog('BroadcastChannelService destroyed', LogLevel.TRACE);
  }

  private _exposeInstanceId(): void {
    // expose for on this angular application ID
    // on globalThis(window) under: ngpInstanceBroadcastId
    // ie. window.ngpInstanceBroadcastId
    // to be able to identify which tab/window in which one
    Object.defineProperty(globalThis, BroadcastChannelService.INSTANCE_ID_EXPOSE_PROP, {
      value: this.instanceBroadcastId,
    });
  }

  private _startListening(): void {
    this._subscriptions.add(
      this._broadcastMessages$
        .pipe(
          filter((message) => isBroadcastMessageType<unknown>(message)),
          tap((message: BroadcastMessage<unknown>) =>
            this._logger.addConsoleLog(
              `INCOMING BROADCAST MESSAGE ${JSON.stringify(message)}`,
              LogLevel.TRACE,
            ),
          ),
        )
        .subscribe(this._messages$),
    );
  }
}
