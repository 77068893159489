import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { TabsService } from '../tabs.service';

import { TabComponent } from '../tab/tab.component';

import { TabChange } from '../types/tab-change';
import { TabsType } from '../types/tabs-type';

@Component({
  selector: 'ngp-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.sass'],
  providers: [TabsService],
})
export class NgpTabsComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  type: TabsType = 'primary';
  @Input()
  activeFirst!: boolean;
  @Input()
  activeTabId: unknown;
  @Input()
  contentTemplate!: TemplateRef<unknown>;
  @Input()
  label!: string;
  @Input()
  showLabel = true;
  @ContentChildren(TabComponent)
  tabs!: QueryList<TabComponent>;

  @Output()
  tabChange = new EventEmitter<TabChange>();
  activeTab!: TabComponent;
  private _previousTab!: TabComponent;
  private _destroyed$ = new Subject<void>();

  constructor(private _cdr: ChangeDetectorRef, private _tabsService: TabsService) {
    this._tabsService.activatedTab$
      .pipe(
        tap((tab) => this._markAsActive(tab as TabComponent)),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.activeTabId) {
      this._selectByActiveId();
      this._cdr.detectChanges();
    } else if (this.activeFirst && !this.activeTab) {
      this._markAsActive(this.tabs?.get(0) as TabComponent);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeTabId?.currentValue) {
      this._selectByActiveId();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  tabClicked(tab: TabComponent): void {
    if (tab.disabled || this.activeTab === tab) return;
    this._previousTab = this.activeTab;
    this._tabChange(tab);
    this.activeTab.selected.emit();
  }

  tabChangeCancel(): void {
    this._tabChange(this._previousTab);
  }

  private _tabChange(tab: TabComponent): void {
    this._markAsActive(tab);
    this.tabChange.emit({ activeTab: tab });
  }

  private _markAsActive(tab: TabComponent): void {
    if (tab) {
      this.activeTab = tab;
      this._cdr.detectChanges();
    }
  }

  private _selectByActiveId(): void {
    const activeTab = this.tabs?.find((tab) => tab.tabId === this.activeTabId);
    if (activeTab) this._markAsActive(activeTab);
  }
}
