<ng-container *ngIf="!loading">
  <div
    id="missing-files-status"
    class="missing-files-status"
    [attr.aria-label]="('HEADER.MISSING_FILES' | translate) + missingFilesCounter"
    [attr.title]="'HEADER.MISSING_FILES' | translate"
  >
    <span>
      {{ missingFilesCounter }}
    </span>
  </div>
</ng-container>
