<div
  #columnContainer
  tabindex="-1"
  role="button"
  [ngClass]="{ sortable: sortable }"
>
  {{ name | translate }}
  <ngp-svg-icon
    *ngIf="direction"
    [iconName]="direction === SORT_DIRECTION.ASC ? 'caret-up' : 'caret-down'"
    class="ml-1 active-icon"
  ></ngp-svg-icon>
  <ngp-svg-icon
    *ngIf="!direction && sortable"
    iconName="caret-down"
    class="sort-icon inactive-icon"
  ></ngp-svg-icon>
</div>
