import { TranslateService } from '@ngx-translate/core';

import { ColumnsTranslations, ColumnType, ExportType } from '@demica/core/core';
import { getVariableValueByName } from '@demica/core/core';

import { convertMapToKeyValueArray } from '../../../component/line-chart/convert-map-to-key-value-array';

import { HorizontalLineData, RequestExport, SvgChartData, TableModel } from '../chart-export';
import { ChartExportConfiguration } from './export-configuration';

export const LABEL_CHART = 'CHART';
export const LABEL_DATA_TABLE = 'DATA_TABLE';
export const LABEL_DATA_TITLE = 'DATA_TITLE';
export const LABEL_CHART_TITLE = 'CHART_TITLE';
export const LABEL_SUBTITLE_CHART = 'SUBTITLE_CHART';
export const LABEL_SUBTITLE_TABLE = 'SUBTITLE_TABLE';

export function fillColumnTranslation<T>(
  translateService: TranslateService,
  translationsObject: ColumnsTranslations,
  columnKey: string,
  listToTranslate: T[],
  mapper?: (arg: T) => keyof T & string,
): ColumnsTranslations {
  if (!mapper) {
    mapper = (toTranslate) =>
      (typeof toTranslate === 'string'
        ? toTranslate
        : toTranslate.toString()) as unknown as keyof T & string;
  }

  const objectsTranslations = {} as Record<keyof T, string>;
  listToTranslate.forEach(
    (value) =>
      (objectsTranslations[mapper(value)] = translateService.instant(
        `ENUMERATIONS.${columnKey}.${mapper(value)}`,
      )),
  );
  translationsObject[columnKey] = objectsTranslations;
  return translationsObject;
}

const STYLESHEET_TYPE = 1;

export class ExportDataHelper {
  private columnCount = 0;

  constructor(private config: ChartExportConfiguration) {}

  getConfiguration(): ChartExportConfiguration {
    return this.config;
  }

  getExportType(): ExportType {
    return this.config.exportType;
  }

  /**
   * This method processing all data that is delivered by export configuration.
   * @returns {RequestExport}
   */
  getRequestExport(): RequestExport {
    const tempData: string[][] = this.prepareTempData();

    if (this.hasHorizontalLines()) {
      this.addMissingHorizontalLineData(tempData);
    }

    let columnNames: string[];
    let columnTypes: ColumnType[];

    if (this.hasDefinedColumns()) {
      columnNames = this.translateColumnNames(this.config.columns.definedColumnNames);
      columnTypes = this.config.columns.definedColumnTypes;
    } else {
      columnNames = this.prepareColumnNames(tempData);
      columnTypes = this.prepareColumnTypes(tempData);
    }

    const svgChartData: SvgChartData[] = this.hasSvgContent() ? this.getArrayOfSvgChartData() : [];
    const data: string[][] = this.transformDataToValidFormat(tempData);

    if (this.config.exportType === ExportType.PDF || this.config.format.shouldFormat) {
      this.formatData(data, columnTypes);
    } else {
      this.fixNullValues(data);
    }

    const tableModel: TableModel = {
      columnNames: columnNames,
      columnTypes: columnTypes,
      data: data,
    };

    return {
      labelsMap: convertMapToKeyValueArray(this.createLabelsMap()),
      svgChartData: svgChartData,
      tableModel: tableModel,
    };
  }

  private createLabelsMap(): Map<string, string> {
    const map = new Map<string, string>();

    map.set(LABEL_CHART, this.config.translateService.instant('EXPORT_CHART.CHART'));
    map.set(LABEL_DATA_TABLE, this.config.translateService.instant('EXPORT_CHART.DATA_TABLE'));
    map.set(LABEL_CHART_TITLE, this.config.translateService.instant(this.config.labels.chartTitle));

    if (this.config.labels.subtitleChart !== undefined) {
      try {
        map.set(
          LABEL_SUBTITLE_CHART,
          this.config.translateService.instant(this.config.labels.subtitleChart),
        );
      } catch (e) {
        map.set(LABEL_SUBTITLE_CHART, this.config.labels.subtitleChart);
      }
    }

    if (this.config.labels.subtitleTable !== undefined) {
      try {
        map.set(
          LABEL_SUBTITLE_TABLE,
          this.config.translateService.instant(this.config.labels.subtitleTable),
        );
      } catch (e) {
        map.set(LABEL_SUBTITLE_TABLE, this.config.labels.subtitleTable);
      }
    }

    return map;
  }

  private isTableData(): boolean {
    return this.config.isTableData;
  }

  private hasHorizontalLines(): boolean {
    return this.config.horizontalLineData && this.config.horizontalLineData.length > 0;
  }

  private hasDefinedColumns(): boolean {
    return (
      this.config.columns !== undefined &&
      this.config.columns.definedColumnTypes !== undefined &&
      this.config.columns.definedColumnNames !== undefined
    );
  }

  private hasSvgContent(): boolean {
    return this.config.data.svgData !== undefined;
  }

  private prepareColumnNames(tableData: string[][]): string[] {
    const columnNames: string[] = [];

    if (this.isTableData()) {
      tableData.slice(0, 1).forEach((columnName) => {
        columnNames.push(...columnName);
        this.columnCount++;
      });

      return columnNames;
    }

    tableData
      .map((value) => value[0])
      .forEach((columnName) => {
        if (columnName.toLowerCase().includes('_x') || columnName === 'x') {
          columnNames.unshift(
            this.config.translateService.instant('EXPORT_CHART.DATE').toUpperCase(),
          );
        } else if (columnName.toLowerCase().includes('y2')) {
          columnNames.push(
            this.config.translateService.instant(this.config.additionalYAxisLabel).toUpperCase(),
          );
        } else {
          columnNames.push(this.config.translateService.instant(columnName).toUpperCase());
        }

        this.columnCount++;
      });

    return columnNames;
  }

  private prepareColumnTypes(tableData: string[][]): ColumnType[] {
    const columnTypes: ColumnType[] = [];

    if (this.isTableData()) {
      tableData.slice(1, 2).forEach((row) => {
        row.forEach((elem) => {
          if (Number.isFinite(Number(elem))) {
            columnTypes.push(ColumnType.NUMBER);
          } else {
            columnTypes.push(ColumnType.OTHER);
          }
        });
      });

      return columnTypes;
    }

    tableData.forEach((rows) => {
      const x: string[] = rows.slice(0, 2);

      if (x[0].toLowerCase().includes('_x') || x[0] === 'x') {
        columnTypes.unshift(ColumnType.DATE);
      } else if (
        !x[0].toLowerCase().includes('_x') &&
        x[0] !== 'x' &&
        Number.isFinite(Number(x[1]))
      ) {
        columnTypes.push(ColumnType.NUMBER);
      } else {
        columnTypes.push(ColumnType.OTHER);
      }
    });

    return columnTypes;
  }

  private prepareTempData(): string[][] {
    const data: string[][] = this.config.data.tableData;

    const index = data.map((row) => row[0]).findIndex((cell) => cell === 'x');

    data.unshift(data[index]);
    data.splice(index + 1, 1);

    return data;
  }

  private addMissingHorizontalLineData(tableData: string[][]) {
    const horizontalLineData: HorizontalLineData[] = this.config.horizontalLineData;
    const sizeElementsInRow: number = tableData[0].length - 1;

    horizontalLineData.forEach((value) => {
      const values: string[] = new Array(sizeElementsInRow).fill(
        value.value.toString(),
        0,
        sizeElementsInRow,
      );
      const additionalColumnsData: string[] = [
        this.config.translateService.instant(value.title),
        ...values,
      ];

      tableData.push(additionalColumnsData);
    });
  }

  private transformDataToValidFormat(tempData: string[][]): string[][] {
    if (this.isTableData()) {
      return tempData;
    }

    const rowsSize: number = tempData.length;
    const rowItemsSize: number = tempData[0].length;
    const tableModel: string[][] = [];

    for (let colIndex = 1; colIndex < rowItemsSize; colIndex++) {
      const row: string[] = [];
      for (let rowIndex = 0; rowIndex < rowsSize; rowIndex++) {
        const value: string =
          tempData[rowIndex][colIndex] !== undefined ? tempData[rowIndex][colIndex] : '0';

        row.push(value);
      }

      tableModel.push(row);
    }

    return tableModel;
  }

  private fixNullValues(data: string[][]) {
    data.forEach((value) => {
      value.forEach((item, index) => {
        if (item == null) {
          value[index] = '0';
        }
      });
    });
  }

  private formatData(data: string[][], columnTypes: ColumnType[]) {
    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      for (let colIndex = 0; colIndex < columnTypes.length; colIndex++) {
        const columnType = columnTypes[colIndex];

        switch (columnType) {
          case ColumnType.NUMBER:
            try {
              data[rowIndex][colIndex] = this.config.format.numberFormat(data[rowIndex][colIndex]);
            } catch (e) {
              console.log('Please set up NUMBER formatter! \n', e);
            }
            break;
          case ColumnType.CURRENCY:
            try {
              data[rowIndex][colIndex] = this.config.format.currencyFormat(
                data[rowIndex][colIndex],
              );
            } catch (e) {
              console.log('Please set up CURRENCY formatter! \n', e);
            }
            break;
          case ColumnType.DATE:
            try {
              data[rowIndex][colIndex] = this.config.format.dateFormat(data[rowIndex][colIndex]);
            } catch (e) {
              console.log('Please set up DATE formatter! \n', e);
            }
            break;
          case ColumnType.PERCENTAGE:
            try {
              data[rowIndex][colIndex] = this.config.format.percentageFormat(
                data[rowIndex][colIndex],
              );
            } catch (e) {
              console.log('Please set up PERCENTAGE formatter! \n', e);
            }
        }
      }
    }
  }

  private translateColumnNames(definedColumnNames: string[]): string[] {
    return definedColumnNames.map((columnName) =>
      this.config.translateService.instant(columnName).toUpperCase(),
    );
  }

  private getArrayOfSvgChartData() {
    const svgChartData: SvgChartData[] = [];

    this.config.data.svgData.forEach((elem) => {
      svgChartData.push({ title: elem.title, svgData: this.getSvgData(elem.svgData as Element) });
    });

    return svgChartData;
  }

  private getSvgData(svgNode: Element): string {
    this.prepareSvgChartForExport(svgNode);
    return new XMLSerializer().serializeToString(svgNode);
  }

  private prepareSvgChartForExport(node: Element) {
    if (node.attributes) {
      const nodeClasses = node.attributes.getNamedItem('class');
      this.addStylesFromClassesToGivenNode(nodeClasses, node);
      this.removeClipPathAttribute(node);
      this.addMissingWidthHeightAttributes(node);
      this.fixChartLabels(node);
    }

    const nodeList = node.childNodes as NodeListOf<SVGElement>;

    this.fixPieChartScale(nodeList);
    this.fixLines(nodeList);

    for (let i = 0; i < nodeList.length; i++) {
      this.prepareSvgChartForExport(nodeList.item(i) as Element);
    }
  }

  private fixLines(nodeList: NodeListOf<SVGElement>) {
    const nodesSize = nodeList.length;
    for (let i = 0; i < nodesSize; i++) {
      const item = nodeList.item(i);
      if (item.localName === 'path' && item.className.baseVal === 'domain') {
        this.fixAxisPath(item);
      }

      if (
        item.localName === 'g' &&
        (item.className.baseVal === 'c3-xgrids' || item.className.baseVal === 'c3-ygrids')
      ) {
        this.fixXYGrids(item);
      }
    }
  }

  private fixAxisPath(svgElement: SVGElement): void {
    const styleAttr = document.createAttribute('style');
    styleAttr.value = `fill: none; stroke-width: 1px; stroke: rgb(0,0,0); display: ${getVariableValueByName(
      '--trf-chart-axis-display',
    )};`;
    svgElement.attributes.setNamedItem(styleAttr);
  }

  private fixXYGrids(svgElement: SVGElement): void {
    for (const childItem of (<unknown>svgElement.childNodes) as Iterable<SVGElement>) {
      if (childItem.localName === 'line') {
        let styleAttr = childItem.getAttribute('style');
        styleAttr = `stroke-dasharray: 0; stroke: ${getVariableValueByName(
          '--trf-chart-grid-lines-color',
        )};`;
        childItem.setAttribute('style', styleAttr);
      }
    }
  }

  private fixPieChartScale(nodeList: NodeListOf<SVGElement>) {
    const nodesSize = nodeList.length;
    for (let i = 0; i < nodesSize; i++) {
      const item = nodeList.item(i);
      if (item.localName === 'g' && item.className.baseVal === 'c3-chart-arcs') {
        let attrValue = item.getAttribute('transform');
        attrValue += ' scale(0.8)';
        item.setAttribute('transform', attrValue);
      }
    }
  }

  private fixTextNode(nodeList: NodeListOf<SVGElement>) {
    const nodesSize = nodeList.length;
    for (let i = 0; i < nodesSize; i++) {
      const item = nodeList.item(i);
      if (item.localName === 'text' || item.localName === 'g' || item.localName === 'path') {
        this.removeTransformAttribute(item);
      }
    }
  }

  private removeTransformAttribute(item: SVGElement) {
    const attributesSize = item.attributes.length;
    for (let j = 0; j < attributesSize; j++) {
      const attr = item.attributes.item(j);
      if (attr != null && attr.name === 'transform' && attr.value === 'none') {
        item.attributes.removeNamedItem('transform');
      }
    }
  }

  private addStylesFromClassesToGivenNode(nodeClasses: Attr, node: Element) {
    if (nodeClasses) {
      const cssRules = this.getCSSRules();
      const classNames = nodeClasses.value.split(' ');
      for (const className of classNames) {
        this.fillNodeWithApplicableStyles(cssRules, className, node);
        this.fixChartLinesStyle(className, node);
      }
    }
  }

  private getCSSRules(): CSSRuleList[] {
    const cssRules = [];
    for (let i = 0; i < document.styleSheets.length; i++) {
      const styleSheet = document.styleSheets[i] as CSSStyleSheet;
      let styleRule = null;

      for (let j = 0; j < styleSheet.cssRules.length; j++) {
        if (styleSheet.cssRules.item(j).type === 1) {
          styleRule = styleSheet.cssRules.item(j) as CSSStyleRule;
          break;
        }
      }

      if (styleRule && styleRule.selectorText && styleRule.selectorText.startsWith('.c3')) {
        cssRules.push(styleSheet.cssRules);
      }
    }
    return cssRules;
  }

  private fillNodeWithApplicableStyles(cssRules: CSSRuleList[], className: string, node: Element) {
    for (const ruleList of cssRules) {
      for (let i = 0; i < ruleList.length; i++) {
        const styleRule = ruleList.item(i) as CSSStyleRule;
        if (
          styleRule.type === STYLESHEET_TYPE &&
          (this.isApplicableForGivenClass(styleRule, className) ||
            this.isApplicableForGivenNode(styleRule, node))
        ) {
          this.addStylesToGivenNode(styleRule, node);
        }
      }
    }
  }

  private addStylesToGivenNode(styleRule: CSSStyleRule, node: Element) {
    const styles = node.attributes.getNamedItem('style');
    if (styles) {
      styles.value += styleRule.style.cssText;
    } else {
      const attr = document.createAttribute('style');
      attr.value = styleRule.style.cssText;
      node.attributes.setNamedItem(attr);
    }
  }

  private isApplicableForGivenClass(styleRule: CSSStyleRule, className: string) {
    const fullClassName = '.' + className;
    return (
      !className.startsWith('c3-legend-item') &&
      (styleRule.selectorText.includes(fullClassName + ' ') ||
        styleRule.selectorText.includes(fullClassName + ',') ||
        styleRule.selectorText.endsWith(fullClassName))
    );
  }

  private isApplicableForGivenNode(styleRule: CSSStyleRule, node: Element) {
    if (!styleRule.selectorText.endsWith(node.nodeName)) {
      return false;
    }

    const parentNodeClasses = node.parentElement.attributes.getNamedItem('class');
    if (!parentNodeClasses) {
      return false;
    }

    const classNames = parentNodeClasses.value.split(' ');
    for (const className of classNames) {
      const fullClassName = '.' + className;
      if (className !== '' && styleRule.selectorText.includes(fullClassName + ' ')) {
        return true;
      }
    }
    return false;
  }

  private fixChartLinesStyle(className: string, node: Element) {
    if (!className.endsWith('c3-chart-lines')) {
      return;
    }

    const styles = node.attributes.getNamedItem('style');
    if (styles) {
      styles.value += ' fill: none;';
    } else {
      const styleAttribute = document.createAttribute('style');
      styleAttribute.value = 'fill: none;';
      node.attributes.setNamedItem(styleAttribute);
    }
  }

  private removeClipPathAttribute(node: Element) {
    const clipPathAttribute = node.attributes.getNamedItem('clip-path');
    if (clipPathAttribute) {
      node.attributes.removeNamedItem('clip-path');
    }
  }

  private addMissingWidthHeightAttributes(node: Element) {
    let widthAttribute = node.attributes.getNamedItem('width');
    let heightAttribute = node.attributes.getNamedItem('height');
    if (widthAttribute && !heightAttribute) {
      heightAttribute = document.createAttribute('height');
      heightAttribute.value = '0';
      node.attributes.setNamedItem(heightAttribute);
    } else if (!widthAttribute && heightAttribute) {
      widthAttribute = document.createAttribute('width');
      widthAttribute.value = '0';
      node.attributes.setNamedItem(widthAttribute);
    }
  }

  private fixChartLabels(node: Element) {
    if (!node.parentNode || !node.parentElement) {
      return;
    }

    const parentNodeClasses = node.parentElement.attributes.getNamedItem('class');
    if (
      parentNodeClasses &&
      parentNodeClasses.value.startsWith('c3-chart-arc') &&
      node.nodeName === 'text'
    ) {
      node.attributes.getNamedItem('style').value += ' stroke: none;';
    }
  }
}
