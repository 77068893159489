<div
  *ngIf="numberOfPages > 1"
  class="paginator-container"
  dir="ltr"
>
  <button
    [attr.aria-label]="'PAGING.PREVIOUS' | translate"
    class="trf-pagination-btn trf-pagination-icon chevron-left"
    [ngClass]="{ disabled: firstPage }"
    (click)="onPageClick(currentPage - 1)"
    testid="paging-prev"
  >
    <ngp-svg-icon iconName="chevron-left"></ngp-svg-icon>
  </button>
  <div class="pagination-pages">
    <ng-container *ngFor="let page of pages; index as i">
      <button
        class="trf-pagination-btn"
        type="button"
        [disabled]="disabled || isCurrentPage(page)"
        [ngClass]="{ inactive: isCurrentPage(page) }"
        (click)="onPageClick(page)"
        >{{ page + 1 }}</button
      >
      <div
        *ngIf="page !== pages[i + 1] - 1 && page < numberOfPages - 1"
        class="gap"
        >• •
      </div>
    </ng-container>
  </div>
  <button
    [attr.aria-label]="'PAGING.NEXT' | translate"
    class="trf-pagination-btn trf-pagination-icon chevron-right"
    [ngClass]="{ disabled: lastPage }"
    (click)="onPageClick(currentPage + 1)"
    testid="paging-next"
  >
    <ngp-svg-icon iconName="chevron-right"></ngp-svg-icon>
  </button>
</div>
