import {
  AbstractControl,
  FormArray,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { identity } from 'rxjs';

import { CustomRange, getNestedProperties, isKey, replaceCommaWithDot } from '@demica/core/core';

import { INVALID_NUMBER_FORMAT_ERROR_KEY } from '../component/form-number-row/form-number-row-error-keys';

import BigNumber from 'bignumber.js';

export const MAX_TEXT_FIELD_LENGTH = 128;
export const MAX_NUMBER_FIELD_LENGTH = 64;
export const MAX_VALUE_LONG = new BigNumber('9223372036854000000');
export const MAX_VALUE_MULTI = 1000000;

// eslint-disable-next-line
const localPartRegex = "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+";

const domainPartRegex =
  '((?=.{1,63}\\.)[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9]{2,63}';
const rfc5322EmailRegex =
  '^' + localPartRegex + '(?:\\.' + localPartRegex + ')*@' + domainPartRegex + '$';
const dateFormatRegex = '^[DMY/.-]+$';

function isEmptyInputValue(value: string | number): boolean {
  return value == null || value.toString().length === 0;
}

export function validateNotEmpty(control: AbstractControl) {
  if (control.value == null) return { required: true };

  const val = control.value.toString().trim();
  return val === '' ? { required: true } : null;
}

export function validateUniqueValue<TYPE>(valuesToCompare: TYPE[]) {
  return function (control: AbstractControl) {
    const controlValue = control.value ? control.value.trim() : '';

    return valuesToCompare.includes(controlValue) ? { 'name-unavailable': true } : null;
  };
}

export function createUniqueValueByMappedPropertyValidator<T extends object>(
  objectsToCompareProvider: () => T[],
  propertyName: string,
  objectsToCompareMapper: (value: string) => string,
) {
  return function (control: AbstractControl) {
    const controlValue = control.value ? objectsToCompareMapper(control.value).trim() : '';
    const objectsToCompare = objectsToCompareProvider();
    const valuesToCompare = objectsToCompare
      ? objectsToCompare
          .map((object) => {
            if (!isKey(object, propertyName)) {
              return '';
            }
            const property = object[propertyName] as string;
            return property ? objectsToCompareMapper(property).trim() : '';
          })
          .filter((value) => value)
      : [];

    return valuesToCompare.includes(controlValue) ? { 'name-unavailable': true } : null;
  };
}

export function createUniqueValueByPropertyValidator<T extends object>(
  objectsToCompareProvider: () => T[],
  propertyName: string,
) {
  return createUniqueValueByMappedPropertyValidator(
    objectsToCompareProvider,
    propertyName,
    identity,
  );
}

export function createUniqueValueByPropertyIgnoringCaseValidator<T extends object>(
  objectsToCompareProvider: () => T[],
  propertyName: string,
) {
  return createUniqueValueByMappedPropertyValidator(
    objectsToCompareProvider,
    propertyName,
    (value) => (value ? value.toLowerCase() : value),
  );
}

export function validateMinLength(length: number) {
  return function (control: AbstractControl) {
    const val = control.value.trim();
    return val.length >= length || val === '' ? null : { minlength: true };
  };
}

export const validateDefaultMaxLength = Validators.maxLength(MAX_TEXT_FIELD_LENGTH);
export const validateDefaultMaxDecimalLength = Validators.maxLength(MAX_NUMBER_FIELD_LENGTH);

export function validateEmailString(val: string): boolean {
  return !val || val.match(rfc5322EmailRegex) !== null;
}

export function validateEmail(control: AbstractControl) {
  const val = control.value;
  return validateEmailString(val) ? null : { email: true };
}

export function validateMinMaxValue(minValue: number, maxValue: number, acceptNulls = false) {
  return function (control: AbstractControl) {
    const val = Number(replaceCommaWithDot(control.value));

    if (acceptNulls && control.value === null) return null;
    if (isNaN(val) || (!acceptNulls && control.value === null)) return { number: true };
    if (val < minValue) return { min: true };
    if (val > maxValue) return { max: true };
    return null;
  };
}

export function maxLongWithDecimals(control: AbstractControl) {
  const val: BigNumber = new BigNumber(replaceCommaWithDot(control.value)).multipliedBy(
    MAX_VALUE_MULTI,
  );
  if (val.isGreaterThan(MAX_VALUE_LONG)) {
    return { [INVALID_NUMBER_FORMAT_ERROR_KEY]: { maxAvailableValue: true } };
  }
  return null;
}

export function validateNumber(minValue: number, maxValue: number) {
  return function (control: AbstractControl) {
    if (control.value == null) return { number: true };

    const val = control.value.toString().trim();
    return !isNaN(val) && (!minValue || val >= minValue) && (!maxValue || val < maxValue)
      ? null
      : { number: true };
  };
}

export function validateBiggerSmallerValue(minValue: number, maxValue: number, acceptNulls = true) {
  return function (control: AbstractControl) {
    const val = Number(replaceCommaWithDot(control.value));

    if (isNaN(val) || (!acceptNulls && val === null)) return { number: true };
    if (minValue && val < minValue) return { tooSmall: true };
    if (maxValue && val > maxValue) return { tooBig: true };
    return null;
  };
}

/**
 * Check if range cover elements of ranges
 *
 * @param range Analysed Range ( min and max values)
 * @param ranges List of Ranges that 'range' cann't be cover any point of any element of list
 */
export function createCoverValidator(range: CustomRange, ranges: CustomRange[]) {
  return function (control: AbstractControl) {
    const val = Number(replaceCommaWithDot(control.value));
    if (isNaN(val) || control.value === null) return { number: true };
    if (Number(range.min) === range.max) return { equalRange: true };
    for (const limit of ranges) {
      if (
        ((range.min === range.max || limit.min === limit.max) &&
          range.min.toString() === limit.min.toString()) ||
        (range.min > limit.min && range.min < limit.max) ||
        (range.max > limit.min && range.max < limit.max)
      ) {
        return { inRange: true };
      }
      if (range.min <= limit.min && range.max >= limit.max) {
        return { coverRange: true };
      }
    }
    return null;
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function createUniqueValidator<T extends object, U>(
  initialObject: T,
  field: string,
  allObjects: T[] = [],
  uniqueMapper: (obj: unknown) => U,
  exclude?: (obj: unknown) => boolean,
  controlValueMapper?: (obj: unknown) => U,
) {
  const equalObjects = (a: unknown, b: unknown) =>
    a && b && getNestedProperties(a, field) === getNestedProperties(b, field);

  return function (control: AbstractControl) {
    if (
      control.value &&
      !!allObjects.filter(
        (object) =>
          !equalObjects(object, initialObject) &&
          uniqueMapper(object) ===
            (controlValueMapper ? controlValueMapper(control.value) : control.value) &&
          (exclude ? !exclude(object) : true),
      ).length
    )
      return { notUnique: true };
    else return null;
  };
}

export function requireSelect(control: AbstractControl) {
  if (!control.value) return { required: true };

  return null;
}

export function requireMultiSelect(control: AbstractControl) {
  if (!control.value || !control.value.length) return { required: true };

  return null;
}

export function validateNotEmptyList(control: AbstractControl) {
  if (!control.value || control.value.length === 0) return { emptyList: true };

  return null;
}

export function validateChecked(control: AbstractControl) {
  if (control.value === null || control.value === false || control.value === 'false')
    return { checked: true };

  return null;
}

export function atLeastOneCheck(control: AbstractControl) {
  const options = control.value;

  if (Object.keys(options).every((option) => !options[option])) return { atLeastOneCheck: true };

  return null;
}

export function validateAtLeastOne<T extends AbstractControl>(
  controlArray: FormArray<T>,
  checkFunction: (control: AbstractControl) => boolean,
): ValidationErrors {
  const valid =
    controlArray.controls.length == 0 || controlArray.controls.some((c) => checkFunction(c));
  return !valid ? { atLeastOneCheck: true } : null;
}

export function validateDateFormat(control: AbstractControl) {
  if (control.value != null) {
    const val = control.value.toString().trim();
    return val.match(dateFormatRegex) && containsAtLeastOneOfEach(val, ['D', 'M', 'Y'])
      ? null
      : { format: true };
  }
}

function containsAtLeastOneOfEach(input: string, values: string[]) {
  for (const value of values) {
    if (input.search(value) === -1) {
      return false;
    }
  }
  return true;
}

export function createFileFormatValidator(logoFilenameRegex: string) {
  return function validateFileFormats(control: AbstractControl) {
    if (control.value) {
      const filename = control.value.toLowerCase();

      if (!filename.match(logoFilenameRegex)) return { wrongFileFormat: true };
    }
    return null;
  };
}

export function createFileSizeValidator(logoMaxFileSizeBase64: number) {
  return function validateMaxFileSize(control: AbstractControl) {
    if (control.value && control.value.length > logoMaxFileSizeBase64) return { fileTooBig: true };

    return null;
  };
}

export const greaterThanValidator =
  (value: number): ValidatorFn =>
  (control: UntypedFormControl) => {
    if (isEmptyInputValue(control.value) || isEmptyInputValue(value)) {
      return null;
    }
    const valueToCheck = parseFloat(control.value);
    if (!isNaN(valueToCheck) && valueToCheck <= value) {
      return { notGreaterThan: true, value: value, currentValue: control.value };
    } else {
      return null;
    }
  };
