<ngp-svg-icon [iconName]="icons[notificationType]"></ngp-svg-icon>
<div
  class="notification-content"
  role="alert"
  tabindex="-1"
  trfToasterAccessibility
  [attr.aria-label]="toast.title + getMessage()"
  (keyup.escape)="closeToaster()"
  data-testid="notification-content"
>
  <span class="toast-title-inline">{{ toast.title }} </span>
  <span>{{ getMessage() }}</span>
</div>
