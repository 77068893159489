<ng-container *ngIf="username">
  <div
    role="application"
    (click)="$event.stopPropagation()"
    attr.aria-label="{{ username }}"
  >
    <button
      #dropdownButton
      class="trf-header-btn"
      tabindex="0"
      aria-haspopup="listbox"
      (click)="toggleDropdown()"
      (keyup.escape)="closeDropdown($event)"
      (keyup.enter)="focusOnFirstElement()"
      attr.aria-label="{{
        (dropdownVisible
          ? 'HEADER.PROFILE_BUTTON_LABEL_EXPANDED'
          : 'HEADER.PROFILE_BUTTON_LABEL_COLLAPSED'
        ) | translate
      }}"
    >
      <ngp-svg-icon
        class="user-circle"
        iconName="user-circle"
      ></ngp-svg-icon>
      <span
        class="username"
        data-testid="top-bar-profile-dropdown"
        >{{ username }}</span
      >
      <ngp-svg-icon
        class="dropdown-chevron"
        iconName="chevron-down"
        [ngClass]="{ dropdownVisible: dropdownVisible }"
      ></ngp-svg-icon>
    </button>

    <div
      *ngIf="dropdownVisible"
      trfOutsideClickMonitor
      (trfOutsideClick)="closeDropdown()"
      [ignoreElements]="[dropdownButton]"
      class="dropdown"
    >
      <ul
        #ulElement
        class="list-unstyled"
        role="listbox"
      >
        <li
          *ngIf="allowToChangePassword"
          class="trf-header-link"
          trfArrowKeysFocus
          role="option"
          tabindex="0"
          index="0"
          [elementsLength]="2"
          (click)="changePassword($event)"
          (keyup.escape)="closeDropdown($event)"
          (keyup.enter)="changePassword($event)"
          (keydown.arrowUp)="focusOnLastElement()"
          testid="change-password-button"
          >{{ "CHANGE_PASSWORD" | translate }}</li
        >
        <li
          class="trf-header-link signout"
          trfArrowKeysFocus
          role="option"
          tabindex="0"
          index="1"
          [elementsLength]="2"
          (click)="signOut($event)"
          (keyup.escape)="closeDropdown($event)"
          (keyup.enter)="signOut($event)"
          (keydown.tab)="handleTabkey($event)"
          (keydown.arrowDown)="focusOnFirstElement()"
          testid="sign-out-button"
          >{{ "SIGN_OUT" | translate }}
          <ngp-svg-icon iconName="sign-out-alt"></ngp-svg-icon>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
