<ng-container
  [formGroup]="group"
  *ngIf="visible"
>
  <div
    class="column-row"
    [ngClass]="{ 'row-disabled': group.get(formControlName).disabled || disabled }"
    data-testid="chips-column-row"
  >
    <div
      *ngIf="!!label"
      class="column-label"
    >
      <label
        [attr.aria-label]="label | translate"
        [attr.for]="formControlName"
        [attr.class]="labelClasses"
      >
        {{ label | translate }}
      </label>
      <ngp-tooltip-icon
        *ngIf="helperToolTip"
        [tooltipKey]="helperToolTip"
      ></ngp-tooltip-icon>
    </div>
    <div class="column-input">
      <div class="column-input-wrapper">
        <div class="input-wrapper">
          <ng-select
            #select
            class="form-control arrow-hidden chips-input"
            role="combobox"
            [attr.aria-required]="labelClasses?.includes('required')"
            [attr.aria-label]="label ? label : defaultLabel"
            [attr.aria-describedby]="formControlName + '_notification'"
            [items]="options"
            [placeholder]="defaultLabel | translate"
            [addTag]="addTag"
            [multiple]="true"
            [clearable]="!previewMode"
            [formControlName]="formControlName"
            [searchable]="true"
            [openOnEnter]="false"
            [hideSelected]="true"
            [isOpen]="false"
            [keyDownFn]="keyDownFn(select)"
            (clear)="updateValidationMessages()"
          >
            <ng-template
              ng-label-tmp
              let-item="item"
              let-clear="clear"
            >
              <span
                class="ng-value-label"
                [attr.title]="item"
                data-testid="select-value-text"
              >
                {{ item }}
              </span>
              <span
                class="ng-value-icon"
                tabindex="0"
                role="button"
                [attr.aria-label]="'FILTER_DASHBOARD.REMOVE'"
                (click)="clear(item)"
              >
                <ngp-svg-icon iconName="times"></ngp-svg-icon>
              </span>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="validation-error">
        <ngp-inline-notification
          [id]="formControlName + '_notification'"
          [message]="validations | firstError: (fieldPath ? fieldPath : formControlName)"
        >
        </ngp-inline-notification>
      </div>
    </div>
  </div>
</ng-container>
