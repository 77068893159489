import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { errorNotification, Notifications } from '@demica/core/core';

import { maxPercentageValue } from '../../validation/funding-validation-constants';
import BigNumber from 'bignumber.js';

@Component({
  selector: 'trf-form-decimal-min-max-row',
  templateUrl: './form-decimal-min-max-row.component.html',
  styleUrls: ['./form-decimal-min-max-row.component.sass'],
})
export class FormDecimalMinMaxRowComponent implements OnInit, OnDestroy {
  @Input()
  nameMin: string;
  @Input()
  nameMax: string;
  @Input()
  labelMin: string;
  @Input()
  labelMax: string;
  @Input()
  isPercentage?: boolean;

  percentageMaxValue = maxPercentageValue;

  showError = false;
  group: UntypedFormGroup;

  notifications = new Notifications();

  private errors = errorNotification('');
  private destroySubject = new Subject<void>();
  private groupErrorCode = 'error';

  constructor(private fgd: FormGroupDirective) {}

  ngOnInit() {
    this.errors.key = this.isPercentage
      ? 'DASHBOARD_LIMITS.PERCENTAGE_VALIDATION_ERROR_MESSAGE'
      : 'DASHBOARD_LIMITS.AMOUNT_VALIDATION_ERROR_MESSAGE';

    this.group = this.fgd.form;

    this.group.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((value) => {
      const maximumAmountValue: number = value[this.nameMax];
      if (maximumAmountValue || maximumAmountValue === 0) {
        const maximumAmount = new BigNumber(maximumAmountValue);
        const minimumAmount = new BigNumber(value[this.nameMin]);

        if (minimumAmount.isGreaterThan(maximumAmount)) {
          this.showGroupFormError(true);
        } else {
          this.showGroupFormError(false);
        }
      } else {
        this.showGroupFormError(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private showGroupFormError(showError: boolean) {
    if (showError) {
      this.notifications.add(this.errors);
    } else {
      this.notifications.remove(this.errors);
    }
    const setFormFieldErrors = (formControlName: string, hasError: boolean) => {
      const errors = this.group.get(formControlName).errors ?? {};

      if (hasError) {
        errors[this.groupErrorCode] = true;
      } else if (errors[this.groupErrorCode]) {
        delete errors[this.groupErrorCode];
      }

      this.group.get(formControlName).setErrors(Object.keys(errors).length ? errors : null);
    };

    setFormFieldErrors(this.nameMin, showError);
    setFormFieldErrors(this.nameMax, showError);
  }
}
