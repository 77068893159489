import { Injectable } from '@angular/core';

import { AuthenticationFlow } from '../../model/auth/authentication-flow.enum';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../utils/window';
import { LOGGED_IN_FLOW } from '../auth/auth-local-storage';

@Injectable({ providedIn: 'root' })
export class AuthenticationFlowStore {
  setHybridFlow() {
    setLocalStorage(LOGGED_IN_FLOW, AuthenticationFlow.HYBRID);
  }

  setCodeFlow() {
    setLocalStorage(LOGGED_IN_FLOW, AuthenticationFlow.CODE);
  }

  isUserLoggedInWithHybridFlow() {
    return getLocalStorage(LOGGED_IN_FLOW) === AuthenticationFlow.HYBRID;
  }

  isUserLoggedInWithCodeFlow() {
    return getLocalStorage(LOGGED_IN_FLOW) === AuthenticationFlow.CODE;
  }

  clearFlow() {
    removeLocalStorage(LOGGED_IN_FLOW);
  }
}
