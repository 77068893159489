import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  AnalysisCode,
  byStringPropertyCaseInsensitive,
  ComponentConfiguration,
  Order,
  SortParams,
} from '@demica/core/core';

import { TextTableHeaderComponent } from '../../../data-table/text-table-header.component';
import { AliasesManagementListRowComponent } from '../aliases-management-list-row/aliases-management-list-row.component';

import { ACTION_REMOVE, Actions } from '../../../actions/model/actions.interface';
import { ColumnDefinition } from '../../../data-table/column-definition.interface';
import { DataSource } from '../../../data-table/data-source.interface';

@Component({
  selector: 'trf-aliases-management-list',
  templateUrl: './aliases-management-list.component.html',
  styleUrls: ['./aliases-management-list.component.sass'],
})
export class AliasesManagementListComponent implements OnInit {
  @Input()
  dataSource: DataSource<AnalysisCode[]>;

  @Input()
  showTransaction?: boolean;

  @Input()
  previewMode: boolean;

  @Output()
  previewChanged = new EventEmitter<AnalysisCode>();

  @Output()
  editChanged = new EventEmitter<AnalysisCode>();

  @Output()
  deleteChange = new EventEmitter<AnalysisCode>();

  @Output()
  sortChange = new EventEmitter<SortParams>();

  rowActions: Actions = {
    preview: {
      titleKey: 'ALIASES_MANAGEMENT.TABLE.ACTIONS.PREVIEW',
      handler: this.onPreviewAction.bind(this),
      icon: 'eye',
      testId: 'action-preview-alias',
      hidden: () => !this.previewMode,
    },
    edit: {
      titleKey: 'ALIASES_MANAGEMENT.TABLE.ACTIONS.EDIT',
      handler: this.onEditAction.bind(this),
      icon: 'pencil-alt',
      testId: 'action-edit-alias',
      hidden: () => this.previewMode,
    },
    delete: {
      actionId: ACTION_REMOVE,
      titleKey: 'ALIASES_MANAGEMENT.TABLE.ACTIONS.DELETE',
      handler: this.onDeleteAction.bind(this),
      icon: 'trash-alt',
      testId: 'action-delete-alias',
      hidden: () => this.previewMode,
    },
  };

  headerConfig: ComponentConfiguration;
  rowConfig: ComponentConfiguration;

  ngOnInit(): void {
    this.headerConfig = this._defineTableHeader();
    this.rowConfig = this._defineTableRows();
  }

  onEditAction(row: AnalysisCode): void {
    this.editChanged.emit(row);
  }

  onPreviewAction(row: AnalysisCode): void {
    this.previewChanged.emit(row);
  }

  onDeleteAction(row: AnalysisCode): void {
    this.deleteChange.emit(row);
  }

  onSortAction(sp: SortParams): void {
    this.sortChange.emit(sp);
  }

  private _configureTableColumnsWithTransaction(): ColumnDefinition[] {
    return [
      {
        nameKey: 'ALIASES_MANAGEMENT.TABLE.COLUMN_ANALYSIS_CODE',
        property: 'value',
        comparator: byStringPropertyCaseInsensitive('value'),
        position: 1,
      },
      {
        nameKey: 'ALIASES_MANAGEMENT.TABLE.COLUMN_ANALYSIS_GROUP_NAME',
        property: 'analysisGroup',
        position: 2,
      },
      {
        nameKey: 'ALIASES_MANAGEMENT.TABLE.COLUMN_TRANSACTION',
        property: 'transaction',
        position: 3,
      },
      {
        nameKey: 'ALIASES_MANAGEMENT.TABLE.COLUMN_ACTIONS',
        classes: 'actions',
        property: 'actions',
        position: 4,
      },
    ];
  }

  private _configureTableColumns(): ColumnDefinition[] {
    return [
      {
        nameKey: 'ALIASES_MANAGEMENT.TABLE.COLUMN_ANALYSIS_CODE',
        property: 'value',
        comparator: byStringPropertyCaseInsensitive('value'),
        position: 1,
      },
      {
        nameKey: 'ALIASES_MANAGEMENT.TABLE.COLUMN_ANALYSIS_GROUP_NAME',
        property: 'analysisGroup',
        position: 2,
      },
      {
        nameKey: 'ALIASES_MANAGEMENT.TABLE.COLUMN_ACTIONS',
        classes: 'actions',
        property: 'actions',
        position: 3,
      },
    ];
  }

  private _defineTableHeader(): ComponentConfiguration {
    return {
      component: TextTableHeaderComponent,
      inputs: {
        allSelected: false,
        initialSort: {
          property: 'value',
          order: Order.DESC,
        },
        columns: this.showTransaction
          ? this._configureTableColumnsWithTransaction()
          : this._configureTableColumns(),
      },
      outputs: {
        sortChanged: (sp: SortParams) => this.onSortAction(sp),
      },
    };
  }

  private _defineTableRows(): ComponentConfiguration {
    return {
      component: AliasesManagementListRowComponent,
      inputs: {
        actions: this.rowActions,
        showTransaction: this.showTransaction,
      },
    };
  }
}
