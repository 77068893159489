import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { EntityId } from '../../../../interface/has-entity-id.interface';
import { encodeEndpoint } from '../../../../security/encode-url';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class TransactionConfigurationReportService {
  constructor(private http: HttpClient) {}

  getConfigurationReport(transactionId: EntityId, revision?: number) {
    const url = revision
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/configuration-report',
          transactionId,
          revision,
        )
      : encodeEndpoint('resources/transactions/{}/configuration-report', transactionId);

    return this.http
      .get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' })
      .pipe(
        map((result) => saveAs(result.body, result.headers.get('reportConfigurationFilename'))),
      );
  }
}
