<div
  class="trf-section-header"
  *ngIf="header"
>
  <h2>{{ header | translate }}</h2>
</div>
<div class="trf-section-content organisation-header">
  <div class="header-start">
    <div
      class="logo-container"
      *ngIf="preview.logoId"
    >
      <trf-logo-display
        [logoHolder]="preview"
        [logoPath]="logoPath"
      ></trf-logo-display>
    </div>
    <div class="organisation-information">
      <div
        class="name"
        [title]="getName()"
        testId="preview-name-field"
        >{{ getName() }}</div
      >
      <div
        class="code"
        [title]="preview.code"
        testId="preview-code-field"
        >{{ preview.code }}</div
      >
    </div>
  </div>

  <div class="header-end">
    <ng-content></ng-content>
  </div>
</div>
