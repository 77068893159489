<div *ngIf="client">
  <div class="trf-section-header">
    <h3>{{ "TRANSACTION_OPCOS_ASSIGN.CLIENT_DETAILS" | translate }}</h3>
  </div>
  <div class="trf-section-content">
    <table
      trfOrdinaryTableKeyHandler
      class="table"
      tabindex="0"
      role="table"
      [attr.aria-label]="'TRANSACTION_OPCOS_ASSIGN.CLIENT_DETAILS' | translate"
    >
      <thead>
        <tr trfTableAccessibilityAttributesSetter>
          <th>{{ "TRANSACTION_OPCOS_ASSIGN.CLIENT_NAME" | translate }}</th>
          <th>{{ "TRANSACTION_OPCOS_ASSIGN.CLIENT_CODE" | translate }}</th>
          <th>{{ "TRANSACTION_OPCOS_ASSIGN.CLIENT_ADDRESS" | translate }}</th>
          <th>{{ "TRANSACTION_OPCOS_ASSIGN.OPCO_CONTACT_DETAILS" | translate }}</th>
          <th class="action-space"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          trfTableAccessibilityAttributesSetter
          class="client-row"
          testid="client-preview-client-row"
        >
          <td>
            <div class="client-name-section">
              <trf-logo-display
                class="logo-container"
                logoPath="clients"
                [logoHolder]="client"
              ></trf-logo-display>
              <div class="client-name">
                <span
                  class="a-text-cut"
                  title="{{ client.name }}"
                  >{{ client.name }}</span
                >
                <div [ngPlural]="allClientOpcos.length">
                  <ng-template ngPluralCase="=0">
                    {{ "CLIENTS.OPCO_COMPANIES_COUNT.0" | translate }}
                  </ng-template>
                  <ng-template ngPluralCase="=1">
                    {{ "CLIENTS.OPCO_COMPANIES_COUNT.1" | translate }}
                  </ng-template>
                  <ng-template ngPluralCase="other">
                    {{ "CLIENTS.OPCO_COMPANIES_COUNT.ALL" | translate: this }}
                  </ng-template>
                </div>
              </div>
            </div>
          </td>
          <td title="{{ client.code }}">
            {{ client.code }}
          </td>
          <td>
            <div>{{ client.address1 }}</div>
            <div>{{ client.address2 }}</div>
            <div>{{ client.address3 }}</div>
            <div>{{ client.address4 }}</div>
            <div>{{ client.postCode }}</div>
            <div>{{ "COUNTRIES." + client.country.entityId | translate }}</div>
          </td>
          <td>
            <trf-contact-table-cell [preview]="client"></trf-contact-table-cell>
          </td>
          <td class="action-space"></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="allClientOpcos?.length">
    <div class="trf-section-header">
      <h3>
        {{ "TRANSACTION_OPCOS_ASSIGN.ASSIGN_OPCO_TO_TRANSACTION_HEADER" | translate }}
      </h3>
    </div>
    <div class="trf-section-content">
      <table
        trfOrdinaryTableKeyHandler
        class="table sellers-table"
        tabindex="0"
        role="table"
        [attr.aria-label]="'TRANSACTION_OPCOS_ASSIGN.ASSIGN_OPCO_TO_TRANSACTION_HEADER' | translate"
      >
        <thead>
          <tr trfTableAccessibilityAttributesSetter>
            <th>{{ "TRANSACTION_OPCOS_ASSIGN.OPCO_NAME" | translate }}</th>
            <th>{{ "TRANSACTION_OPCOS_ASSIGN.OPCO_CODE" | translate }}</th>
            <th>{{ "TRANSACTION_OPCOS_ASSIGN.OPCO_ADDRESS" | translate }}</th>
            <th>{{ "TRANSACTION_OPCOS_ASSIGN.OPCO_CONTACT_DETAILS" | translate }}</th>
            <th class="seller-assign">{{
              "TRANSACTION_OPCOS_ASSIGN.ACTION_ASSIGN_OPCO_TO_TRANSACTION" | translate
            }}</th>
            <th class="seller-order">{{
              "TRANSACTION_OPCOS_ASSIGN.ACTION_SET_ORDER" | translate
            }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let opcoItem of allClientOpcos"
            trfTableAccessibilityAttributesSetter
            class="seller-row"
            testid="client-preview-opco-list-row"
          >
            <td>
              <span
                class="a-text-cut"
                title="{{ opcoItem.name }}"
              >
                {{ opcoItem.name }}
              </span>
            </td>
            <td title="{{ opcoItem.code }}">{{ opcoItem.code }}</td>
            <td>
              <div>{{ opcoItem.address1 }}</div>
              <div>{{ opcoItem.address2 }}</div>
              <div>{{ opcoItem.address3 }}</div>
              <div>{{ opcoItem.address4 }}</div>
              <div>{{ "COUNTRIES." + opcoItem.country.entityId | translate }}</div>
            </td>
            <td>
              <trf-contact-table-cell [preview]="opcoItem"></trf-contact-table-cell>
            </td>
            <td class="seller-assign">
              <trf-table-actions
                [actions]="assignActions"
                [actionSubject]="opcoItem"
              ></trf-table-actions>
            </td>
            <td class="seller-order">
              <trf-table-actions
                [actions]="setOrderActions"
                [actionSubject]="opcoItem"
              ></trf-table-actions>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
