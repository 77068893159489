import { SelectOption } from '@demica/core/core';

/* If there is a need to show tooltip only when option is disabled */
export const tooltipOnlyOnDisabled = (options: SelectOption[]): SelectOption[] =>
  options.map((option) => {
    if (option.disabled) {
      return option;
    }

    const { hoverText, ...other } = option;
    return other;
  });

export const flatStringOptionSorter =
  () =>
  (a: string, b: string): number => {
    return a.localeCompare(b);
  };
