import { Component, Input, OnInit } from '@angular/core';

import { WithAbstractUsageRelations } from '@demica/core/core';

import { UsageRelationsService } from '../../service/usage-relations.service';

import { Action, Actions } from '../actions/model/actions.interface';

@Component({
  selector: 'trf-table-actions',
  templateUrl: 'table-actions.component.html',
  providers: [UsageRelationsService],
})
export class TableActionsComponent<T extends WithAbstractUsageRelations> implements OnInit {
  @Input()
  actions: Actions<T>;
  @Input()
  actionSubject: T;
  @Input()
  hasAnyUsages: boolean;

  actionsAsArray: Action<T>[];

  constructor(private _usageRelationsService: UsageRelationsService<T>) {}

  ngOnInit(): void {
    this._buildActions();
  }

  private _buildActions(): void {
    this.actionsAsArray = this._usageRelationsService.buildActionsWithMessages(
      this.actions,
      this.actionSubject,
      this.hasAnyUsages,
    );
  }
}
