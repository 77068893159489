import { NgZone } from '@angular/core';

import { AppConfigService } from '../app-config/app-config.service';
import { AuthenticationFlowStore } from '../auth2/authentication-flow-store.service';
import { AuthKeycloakService } from './auth-keycloak.service';
import { AuthService } from './auth.service';
import { HybridFlowTokenStoreService } from './hybrid-flow-token-store.service';
import { TokenStoreService } from './token-store.service';

import { acquireTokensFromUrl } from '../auth2/acquire-tokens-from-url';
import { configureCodeFlow } from './configure-code-flow';
import { configureHybridFlow } from './configure-hybrid-flow';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';

export function initializeFlow(
  keycloak: KeycloakService,
  appConfig: AppConfigService,
  tokenStore: TokenStoreService,
  flowStore: AuthenticationFlowStore,
  authService: AuthService,
  authKeycloakService: AuthKeycloakService,
  ngZone: NgZone,
  hybridFlowTokenStoreService: HybridFlowTokenStoreService,
  kcConfig: KeycloakConfig,
) {
  const tokens = acquireTokensFromUrl();
  if (tokens.hasHybridFlowTokens) {
    return initializeHybridFlow(tokens.accessToken, tokens.refreshToken);
  }

  if (flowStore.isUserLoggedInWithHybridFlow()) {
    const { accessToken, refreshToken } = hybridFlowTokenStoreService.getStoredTokens();
    return initializeHybridFlow(accessToken, refreshToken);
  }

  return initializeCodeFlow();

  function initializeHybridFlow(accessToken?: string, refreshToken?: string) {
    // eslint-disable-next-line prefer-rest-params
    return configureHybridFlow(
      accessToken,
      refreshToken,
      keycloak,
      appConfig,
      flowStore,
      ngZone,
      hybridFlowTokenStoreService,
      kcConfig,
    );
  }

  function initializeCodeFlow() {
    return configureCodeFlow(keycloak, appConfig, flowStore, ngZone, kcConfig);
  }
}
