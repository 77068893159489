<div
  class="wrapper"
  [ngClass]="{ disabled: disabled }"
  (click)="onClick()"
>
  <div
    *ngIf="color1; else customIcon"
    class="dot"
    [ngStyle]="{ background: background() }"
  ></div>
  <ng-template #customIcon>
    <ng-content></ng-content>
  </ng-template>
  <div class="label">{{ label }}</div>
</div>
