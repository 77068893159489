import { Injectable } from '@angular/core';

import { InactivityLogoutMangerService } from '../manager/inactivity-logout-manager.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityLogoutInitializerService {
  constructor(private _inactivityLogoutManger: InactivityLogoutMangerService) {}

  initialize(): void {
    this._inactivityLogoutManger.initialize();
  }
}
