import { LegendConfiguration, LegendElement, LegendEventAction } from '@demica/core/core';

import { PieChartData } from '../pie-chart-data';

export const DEFAULT_PIE_LEGEND_CONFIG: LegendConfiguration = {
  legendContainerId: 'pie-chart-legend',
  labelMarginTop: 5,
  lineHeight: 17,
  maxWidth: 200,
  textMarginLeft: 15,
  elements: [],
  interactionHandlers: [],
  iconSize: 12,
  align: 'middle',
};

export interface LegendConfigArgs {
  data: PieChartData;
  interactionHandlers: LegendEventAction[];
}

export function createLegendConfig(
  pieChartColors: string[],
  args: LegendConfigArgs,
  config = DEFAULT_PIE_LEGEND_CONFIG,
): LegendConfiguration {
  const elements: LegendElement[] = args.data.getChartValuesWithPercentage().map((cv, index) => {
    const labelText = cv[0];
    return {
      text: labelText,
      class: 'pie-label',
      color: pieChartColors[index % pieChartColors.length],
    };
  });

  return { ...config, elements, interactionHandlers: args.interactionHandlers };
}
