<ngp-inline-loader [loading]="loadingFilters && !loaded"></ngp-inline-loader>

<ng-container *ngIf="!loadingFilters && loaded">
  <trf-aliases-awaiting-approval-search
    [dataSources]="dataSources"
    [analysisGroups]="analysisGroups"
    [environments]="environments"
    [environmentsMapping]="environmentsMapping"
    [transactions]="showTransactions ? transactions : null"
    (searchChange)="onSearch($event)"
    (clearChange)="onClear()"
    (filterChange)="onFilterChange()"
  ></trf-aliases-awaiting-approval-search>
</ng-container>

<trf-modal-loader
  message="MODAL_LOADER.LOADING_MORE_RESULTS"
  [loadingData]="loading"
></trf-modal-loader>

<div class="trf-section">
  <ngp-empty
    key="EMPTY_VIEW.NO_RESULTS"
    height="12vh"
    [isVisible]="!loading && dataLoaded && isEmpty"
  ></ngp-empty>
</div>

<ng-container *ngIf="!loading && dataLoaded && !isEmpty">
  <div class="trf-section-header">
    <h3>
      {{ "ALIASES.TITLE_AWAITING_APPROVAL_LIST" | translate }}
    </h3>
  </div>
  <div class="trf-section-content">
    <div
      *ngIf="!previewMode"
      class="trf-section-buttons"
    >
      <ngp-button
        icon="check"
        key="ALIASES.BUTTON_APPROVE"
        [classes]="enableAcceptButton ? 'primary' : 'inactive'"
        (click)="onAcceptMappings()"
        testid="approve-button"
      ></ngp-button>

      <ngp-button
        icon="times"
        key="ALIASES.BUTTON_REJECT"
        [classes]="enableRejectButton ? 'primary' : 'inactive'"
        (click)="onRejectMappings()"
        testid="reject-button"
      ></ngp-button>

      <ngp-button
        icon="check"
        key="ALIASES.BUTTON_APPROVE_ALL"
        [classes]="enableBulkButtons ? 'primary' : 'inactive'"
        (click)="onApproveAllMappings()"
        testid="approve-all-button"
      ></ngp-button>

      <ngp-button
        icon="times"
        key="ALIASES.BUTTON_REJECT_ALL"
        [classes]="enableBulkButtons ? 'primary' : 'inactive'"
        (click)="onRejectAllMappings()"
        testid="reject-all-button"
      ></ngp-button>
    </div>

    <trf-data-table
      tableClasses="disabled-rows"
      [dataSource]="dataSource"
      [headerConfiguration]="headerConfig"
      [rowConfiguration]="rowConfig"
      [allSelected]="allSelected"
      [indeterminateSelected]="indeterminateSelected"
      [tableLabel]="'ALIASES.TITLE_AWAITING_APPROVAL_LIST'"
      testid="missing-aliases-table"
    ></trf-data-table>
    <trf-spring-page-number
      [pageResponse]="pageResponse"
      (pageChange)="onPageChange($event)"
    ></trf-spring-page-number>
  </div>
</ng-container>
