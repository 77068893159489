import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  createPaginationParams,
  DownloadFileParams,
  encodeEndpoint,
  PageRequest,
  PageRestResponse,
  RestResponse,
  toData,
} from '@demica/core/core';

import { BlobWithFileName } from '../model/blob-with-file-name.interface';
import { StatusAvailableFilters } from '../model/status/status-available-filters';
import { StatusAvailableTransaction } from '../model/status/status-available-transaction';
import { StatusFile } from '../model/status/status-file';

@Injectable()
export class StatusResourceService {
  constructor(private http: HttpClient) {}

  getFiles(pagingUrl: string, pageRequest: PageRequest, searchParams: unknown) {
    const httpParams = createPaginationParams(pageRequest);
    const url = encodeEndpoint(pagingUrl);
    return this.http.post<PageRestResponse<StatusFile>>(url, searchParams, { params: httpParams });
  }

  getAvailableFilters() {
    const url = encodeEndpoint('resources/dashboards/portfolio/status/available-filters');
    return this.http.get<RestResponse<StatusAvailableFilters>>(url).pipe(map(toData));
  }

  getAvailableTransactions(): Observable<StatusAvailableTransaction[]> {
    const url = encodeEndpoint('resources/dashboards/portfolio/status/files/transactions');
    return this.http.get<RestResponse<StatusAvailableTransaction[]>>(url).pipe(map(toData));
  }

  getFailedFile(params: DownloadFileParams): Observable<BlobWithFileName> {
    const url = encodeEndpoint('resources/dashboards/portfolio/status/failed');
    return this.http
      .post(
        url,
        {
          transactionId: params.transactionId,
          environmentId: params.environmentId,
          errorFileName: params.fileName,
        },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(map((result) => this.convertBlobResponseToBlobWithFileName(result)));
  }

  getInputFile(params: DownloadFileParams): Observable<BlobWithFileName> {
    const url = encodeEndpoint(
      `resources/dashboards/portfolio/status/files/{}/{}`,
      params.transactionId,
      params.environmentId,
    );
    return this.http
      .get(url, {
        responseType: 'blob',
        observe: 'response',
        params: { fileName: params.fileName },
      })
      .pipe(map((result) => this.convertBlobResponseToBlobWithFileName(result)));
  }

  convertBlobResponseToBlobWithFileName(res: HttpResponse<Blob>): BlobWithFileName {
    const contentDispositionHeader = res.headers.get('Content-Disposition');
    const fileName = this.getFileNameFromContentDispositionHeader(contentDispositionHeader);

    return {
      blob: res.body,
      fileName: fileName,
    };
  }

  getFileNameFromContentDispositionHeader(contentDispositionHeader: string) {
    try {
      return contentDispositionHeader.includes(';')
        ? contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '')
        : null;
    } catch (e) {
      return null;
    }
  }
}
