import { Observable } from 'rxjs';

import { PageNumberParams } from './page-number-params';

export const PAGE_SIZE = 25;

export abstract class PageService<RESULTS> {
  abstract getPageResultSetObservable(): Observable<RESULTS[]>;

  abstract getPageResultSetObservableFilteredById(id: string): Observable<RESULTS[]>;

  abstract getPageParamsObservable(): Observable<PageNumberParams>;

  abstract getPageParamsObservableFilteredById(id: string): Observable<PageNumberParams>;

  abstract resolvePage(pageNumber: number, totalItems: number): void;

  protected getPageNumberParams(currentPage = 1, totalItems: number) {
    const totalPages = this.getTotalPages(totalItems);

    currentPage = currentPage < 1 ? 1 : currentPage > totalPages ? totalPages : currentPage;

    let startPage: number, endPage: number;

    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, totalItems);
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: PAGE_SIZE,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    } as PageNumberParams;
  }

  private getTotalPages(totalItems: number): number {
    return Math.ceil(totalItems / PAGE_SIZE);
  }
}
