import { Component, EventEmitter, Input, Output } from '@angular/core';

import { byNumericProperty } from '@demica/core/core';

import { findIndexOf } from './helpers';

import { DEFAULT_DECIMAL_PLACES } from '../../model/point-range-defaults.consts';
import { PointValue } from '../../model/pointValue';
import { EditedPoint } from './model/edited-point.interface';

@Component({
  selector: 'trf-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.sass'],
})
export class PointsComponent {
  @Input()
  points: PointValue[];
  @Input()
  pointLabel = 'POINTS.X_VALUE_LABEL';
  @Input()
  pointPlaceholder = 'POINTS.X_VALUE_PLACEHOLDER';
  @Input()
  numberOfDecimalPlacesForValue = DEFAULT_DECIMAL_PLACES;
  @Input()
  numberOfDecimalPlacesForX = DEFAULT_DECIMAL_PLACES;
  @Output()
  pointsChanges = new EventEmitter<PointValue[]>();

  formVisible = false;
  pointToEdit: EditedPoint;

  onEditClick(point: PointValue) {
    this.formVisible = true;
    this.pointToEdit = { point, index: findIndexOf(this.points, point) };
  }

  onEditComplete(point: EditedPoint) {
    this.formVisible = false;
    if (point) {
      this.updatePointsArray(point);
    }
  }

  onDeleteClick(point: PointValue) {
    this.formVisible = false;
    const newPoints = this.points.filter((p) => !(p.x === point.x));
    this.pointsChanges.emit(newPoints);
  }

  private updatePointsArray(editedPoint: EditedPoint) {
    const points = [...this.points];
    if (editedPoint.index > -1) points[editedPoint.index] = editedPoint.point;
    else points.push(editedPoint.point);
    points.sort(byNumericProperty('x'));
    this.pointsChanges.emit(points);
  }
}
