<a
  href="#"
  (click)="signOut($event)"
  class="menu-item"
>
  <ngp-svg-icon
    title="{{ (menuOption.element | menuItemCast).titleKey | translate }}"
    [iconName]="(menuOption.element | menuItemCast).icon"
  ></ngp-svg-icon>
  <div>
    <span>{{ (menuOption.element | menuItemCast).titleKey | translate }}</span>
  </div>
</a>
