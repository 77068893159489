import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { LOCK_CLOSE, LOCK_SAVE, SlideinContext } from '@demica/core/core';

import { FormStatus } from '../../forms/model/form-status.interface';

@Component({
  selector: 'trf-button-controls',
  templateUrl: 'button-controls.component.html',
  styleUrls: ['button-controls.component.sass'],
})
export class ButtonControlsComponent implements OnInit, OnDestroy {
  @Input()
  form: UntypedFormGroup;
  @Input()
  positiveName: string;
  @Input()
  negativeName: string;
  @Input()
  defaultPositiveBehaviour?: number;
  @Input()
  previewMode?: boolean;
  @Input()
  icon?: string;
  @Input()
  key: string;
  @Input()
  disableSaveButton?: boolean;
  @Input()
  saveButtonDataTestId = 'save-button';
  @Input()
  closeButtonDataTestId = 'close-button';

  lockFlag: number;
  processing = false;

  @Output()
  positiveEvent = new EventEmitter<void>();
  @Output()
  negativeEvent = new EventEmitter<void>();

  subscription: Subscription;

  constructor(private slideinContext: SlideinContext) {}

  ngOnInit(): void {
    this.subscription = this.slideinContext.getObservableLock().subscribe((lockFlag) => {
      this.lockFlag = lockFlag;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onPositiveClick() {
    if (this.isPositiveLock() || this.processing) return;

    if (!this.form || this.form.status !== FormStatus.PENDING) {
      this.positiveEvent.emit();
      return;
    }

    this.processing = true;
    const sub = this.form.statusChanges
      .pipe(
        filter((s) => s !== FormStatus.PENDING),
        tap(() => {
          sub.unsubscribe();
          this.processing = false;
          this.positiveEvent.emit();
        }),
      )
      .subscribe();
  }

  onNegativeClick() {
    if (this.isNegativeLock() || this.processing) return;

    this.negativeEvent.emit();
  }

  isPositiveLock() {
    return this.lockFlag & LOCK_SAVE;
  }

  isNegativeLock() {
    return this.lockFlag & LOCK_CLOSE;
  }
}
