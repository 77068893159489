import { AllProd } from '../_domain/dashboard/dashboard-filters.model';
import { EntityId } from '../interface/has-entity-id.interface';
import { FilterIds } from '../model/filter-ids.interface';
import { DashboardFilters } from '../model/filter/dashboard-filters.interface';
import { FilterResource } from '../model/filter/filter-resource.interface';
import { TransactionFilterResource } from '../model/filter/transaction-filter-resource.interface';

export function prepareStartingDashboardFilters(
  filters: DashboardFilters,
  savedFilterIds: FilterIds,
) {
  const startingFilter = {
    availableEnvironments: filters.environments,
    environments: [AllProd],
    clients: [] as FilterResource[],
    opcos: [] as FilterResource[],
    transactions: [] as TransactionFilterResource[],
  };

  const idsToEntities = (ids: EntityId[], entities: FilterResource[]) =>
    ids.map((cId) => entities.find((c) => c.entityId === cId));

  if (savedFilterIds) {
    const clients = idsToEntities(savedFilterIds.clientIds, filters.clients);
    const opcos = idsToEntities(savedFilterIds.opcoIds, filters.opcos);
    const transactions = idsToEntities(savedFilterIds.transactionIds, filters.transactions);
    const environments = savedFilterIds.environmentIds.map((cId) =>
      cId === -1 ? AllProd : filters.environments.find((c) => c.entityId === cId),
    );

    const hasUndefinedEntity = [...clients, ...opcos, ...transactions, ...environments].some(
      (id) => id === undefined,
    );
    if (!hasUndefinedEntity) {
      startingFilter.clients = clients;
      startingFilter.opcos = opcos;
      startingFilter.transactions = <TransactionFilterResource[]>transactions;
      startingFilter.environments = environments;
    }
  }
  return startingFilter;
}
