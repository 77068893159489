import { Component, Input } from '@angular/core';

import { MenuOption, MenuOptionType } from '@demica/core/core';

@Component({
  selector: 'trf-menu-aside',
  templateUrl: './menu-aside.component.html',
  styleUrls: ['./menu-aside.component.sass'],
})
export class MenuAsideComponent {
  @Input()
  public menuOptions: MenuOption[] = [];

  menuOptionType = MenuOptionType;

  menuSectionTrackBy(index: number, menuSection: MenuOption) {
    return menuSection.id;
  }
}
