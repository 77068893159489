import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { ExportType } from '@demica/ui-core-api';

import { LocalisationService } from '../localisation/localisation.service';
import { NotificationService } from '../notification.service';
import { PageableExportDataResourceService } from './pageable-export-resources.service';

import { PageableExportParams } from './model/pageable-export-params';
import { PageableExportState } from './model/pageable-export-state';
import {
  PdfPageableFileName,
  XlsxPageableFileName,
  ZipPageableFileName,
} from './pageable-file-name';
import { saveAs } from 'file-saver';
import { $enum } from 'ts-enum-util';

const WARNING_CODE = 'NOTIFICATION.ERROR_CODE.EXPORT_INCOMPLETE_DATA';

@Injectable()
export class PageableExportService {
  private datePipe: DatePipe;

  constructor(
    protected pageableExportDataResourceService: PageableExportDataResourceService,
    protected translateService: TranslateService,
    protected notificationService: NotificationService,
    private localisationService: LocalisationService,
  ) {
    localisationService.locale.subscribe((locale) => {
      this.datePipe = new DatePipe(locale);
    });
  }

  getPageableExportObservable(
    url: string,
    pageableExportParams: PageableExportParams,
    nameKey: string,
  ): Observable<PageableExportState> {
    return new Observable((observer) => {
      observer.next(PageableExportState.START);

      const translatedName = this.translateService.instant(nameKey);

      this.pageableExportDataResourceService
        .postForPageableExportData(url, pageableExportParams)
        .subscribe((response: { limit: number; data: Blob }) => {
          if (response.limit) {
            this.notificationService.warning(WARNING_CODE, { limit: response.limit });
          }
          this.saveFile(
            response.data,
            pageableExportParams.exportParams.exportFormat,
            translatedName,
          );
          observer.next(PageableExportState.END);
          observer.complete();
        });
    });
  }

  saveFile(blob: Blob, exportType: ExportType, fileName: string): void {
    const pageableFileName = $enum.visitValue(exportType).with({
      PDF: () => new PdfPageableFileName(this.translateService),
      XLSX: () => new XlsxPageableFileName(this.translateService),
      CSV: () => new ZipPageableFileName(this.translateService),
      ZIP: () => new ZipPageableFileName(this.translateService),
    });
    saveAs(
      blob,
      pageableFileName.getValidFileName(fileName, [
        this.datePipe.transform(Date.now(), 'yyyyMMdd_HHmmss'),
      ]),
    );
  }
}
