<ng-container *ngFor="let action of actionsAsArray; index as actionIndex">
  <ng-container *ifHasAuthority="action.requiredAuthority">
    <ng-template #inactiveWithMessagesTooltip>
      <ng-container *ngIf="action.inactiveWithMessages">
        <div
          *ngFor="let message of action.messages"
          class="tooltip-message"
          role="tooltip"
          data-testid="tooltip-message"
          >{{ message | translate }}</div
        >
      </ng-container>
    </ng-template>

    <ngp-svg-icon
      *ngIf="!action.hidden || !action.hidden(actionSubject)"
      class="{{ action.class }}"
      ngpTooltipPosition="{{ action.inactiveWithMessages ? 'left' : action.tooltipPosition }}"
      ngpTooltipRole="button"
      [ngpTooltipTabIndex]="tooltipTabIndex"
      title="{{ getTitleContent(action) }}"
      [ngpTooltip]="
        action.inactiveWithMessages
          ? inactiveWithMessagesTooltip
          : (getTooltipContent(action, actionSubject) | translate)
      "
      [iconName]="action.icon"
      [attr.data-icon-name]="action.icon"
      [ngClass]="{ inactive: isInactive(action, actionSubject) }"
      [ngpTooltipAriaLabel]="action.titleKey | translate"
      [attr.testid]="action.testId"
      [attr.data-testid]="action.testId"
      [attr.aria-disabled]="isInactive(action, actionSubject)"
      (click)="invokeAction($event, action)"
      (keypress)="onEnter($event, action)"
    ></ngp-svg-icon>
  </ng-container>
</ng-container>
