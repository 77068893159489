import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { toData } from '../../../../service/rest/response-mapping';

import { EntityId } from '../../../../interface/has-entity-id.interface';
import { RestResponse } from '../../../../model/response.interface';
import {
  FxRateAvailabilityResponse,
  NameAvailabilityResponse,
} from '../../../../model/rest-response/availability-response';
import { DeleteResponse } from '../../../../model/rest-response/delete-response.interface';
import { PostResponse } from '../../../../model/rest-response/post-response.interface';
import { encodeParams } from '../../../../security/encode-params';
import { encodeEndpoint } from '../../../../security/encode-url';
import { endOfDay } from '../../../../utils/date-time-utils';
import { TransactionCollection } from '../../_common/model/transaction-collection.interface';
import { KpiCalculationDetails } from '../model/kpi-calculation-details.interface';
import { PermissionFilterParams } from '../model/permission-filter-params.interface';
import { TimeSeries } from '../model/time-series-data.interface';
import { TimeSeriesSearchParam } from '../model/time-series-search-param';
import { ValueWithCurrency } from '../model/value-with-currency.interface';
import { TimeSeriesResource } from '../time-series-resource.interface';

@Injectable({
  providedIn: 'root',
})
export class TimeSeriesResourceService implements TimeSeriesResource {
  constructor(private http: HttpClient) {}

  getAllTimeSeries(
    transactionId: number | EntityId,
    entityRevision: number | EntityId,
    versionPreviewMode: boolean,
    reporting?: boolean,
    acceptedTimeSeriesTypes?: number[],
    searchParams?: TimeSeriesSearchParam,
    sort?: string,
  ): Observable<TimeSeries[]> {
    const ids =
      acceptedTimeSeriesTypes !== undefined ? acceptedTimeSeriesTypes.join(',') : undefined;
    const params = encodeParams({ reporting, ids, sort });
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/time-series/search',
          transactionId,
          entityRevision,
        )
      : encodeEndpoint('resources/transactions/{}/time-series/search', transactionId);
    return this.http
      .post<RestResponse<TimeSeries[]>>(url, searchParams, { params })
      .pipe(map(toData));
  }

  deleteTimeSeries(
    transactionId: number,
    timeSeriesId: string,
    entityRevision: number,
  ): Observable<DeleteResponse> {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint(
      'resources/transactions/{}/time-series/{}',
      transactionId,
      timeSeriesId,
    );
    return this.http.delete<RestResponse<DeleteResponse>>(url, { params }).pipe(map(toData));
  }

  upsertLinkTimeSeriesTemplate(
    transactionId: number,
    templateId: EntityId,
    entityRevision: number,
    isPrivate: boolean | string,
  ): Observable<PostResponse> {
    const params = encodeParams({ entityRevision, isPrivate });
    const url = encodeEndpoint(
      'resources/transactions/{}/time-series/link/{}',
      transactionId,
      templateId,
    );
    return this.http.put<RestResponse<PostResponse>>(url, null, { params }).pipe(map(toData));
  }

  unlinkTimeSeriesTemplate(
    transactionId: number,
    templateId: EntityId,
    entityRevision: number,
  ): Observable<DeleteResponse> {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint(
      'resources/transactions/{}/time-series/unlink/{}',
      transactionId,
      templateId,
    );
    return this.http.delete<RestResponse<DeleteResponse>>(url, { params }).pipe(map(toData));
  }

  getTimeSeries(
    transactionId: number,
    timeSeriesId: string,
    entityRevision: number,
    versionPreviewMode: boolean,
  ): Observable<TimeSeries> {
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/time-series/{}',
          transactionId,
          entityRevision,
          timeSeriesId,
        )
      : encodeEndpoint('resources/transactions/{}/time-series/{}', transactionId, timeSeriesId);
    return this.http.get<RestResponse<TimeSeries>>(url).pipe(map(toData));
  }

  postTimeSeries(transactionId: number, data: TimeSeries): Observable<TimeSeries> {
    const url = encodeEndpoint('resources/transactions/{}/time-series', transactionId);
    return this.http.post<RestResponse<TimeSeries>>(url, data).pipe(map(toData));
  }

  putTimeSeries(
    transactionId: number,
    timeSeriesId: EntityId,
    data: TimeSeries,
  ): Observable<TimeSeries> {
    const url = encodeEndpoint(
      'resources/transactions/{}/time-series/{}',
      transactionId,
      timeSeriesId,
    );
    return this.http.put<RestResponse<TimeSeries>>(url, data).pipe(map(toData));
  }

  checkTimeSeriesNameAvailable(
    name: string,
    transactionId: number,
    timeSeriesId?: number,
  ): Observable<NameAvailabilityResponse> {
    const params = encodeParams({ name, timeSeriesId });
    const url = encodeEndpoint(
      'resources/transactions/{}/time-series-name-availability',
      transactionId,
    );
    return this.http.get<RestResponse<NameAvailabilityResponse>>(url, { params }).pipe(map(toData));
  }

  checkTimeSeriesFxRateAvailable(
    baseCurrency: string,
    referenceCurrency: string,
    reportingOnly: boolean,
    transactionId: number,
    timeSeriesId: number,
  ): Observable<FxRateAvailabilityResponse> {
    const params = encodeParams({ baseCurrency, referenceCurrency, reportingOnly, timeSeriesId });
    const url = encodeEndpoint(
      'resources/transactions/{}/time-series-fxrate-availability',
      transactionId,
    );
    return this.http
      .get<RestResponse<FxRateAvailabilityResponse>>(url, { params })
      .pipe(map(toData));
  }

  postCollection(
    transactionId: number,
    data: TransactionCollection,
  ): Observable<TransactionCollection> {
    const url = encodeEndpoint('resources/transactions/{}/collections', transactionId);
    return this.http.post<RestResponse<TransactionCollection>>(url, data).pipe(map(toData));
  }

  getTimeSeriesMetricsByNamingPatterns(
    namingPatterns: string[],
    permissionFilters: PermissionFilterParams,
    dateTime: Date = endOfDay(),
  ): Observable<ValueWithCurrency[]> {
    const url = encodeEndpoint('resources/time-series/aggregated-values-by-currencies');

    return this.http
      .post<RestResponse<ValueWithCurrency[]>>(url, { namingPatterns, permissionFilters, dateTime })
      .pipe(
        map(toData),
        catchError(() => of([])),
      );
  }

  valuesSearch(
    namingPatterns: string[],
    permissionFilters: PermissionFilterParams,
    currency: string,
    dateTime: Date = endOfDay(),
  ): Observable<KpiCalculationDetails[]> {
    const url = encodeEndpoint('resources/time-series/details-by-currency');
    return this.http
      .post<RestResponse<KpiCalculationDetails[]>>(url, {
        namingPatterns,
        permissionFilters,
        currency,
        dateTime,
      })
      .pipe(map(toData));
  }
}
