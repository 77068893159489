import { getCrypto } from './window';

/**
 * This function encodes a message, then calculates its SHA-256 digest
 * then converts the ArrayBuffer to a hex string. Not working for not secure contexts.
 * @param message
 *
 */
export async function digestMessage(message: string): Promise<string> {
  const _crypto: Crypto = getCrypto();
  /*

  Fallback to original message for 'local-spark' instance that is not secure

  https://developer.mozilla.org/en-US/docs/Web/API/Crypto
  You should avoid using the Web Crypto API on insecure contexts,
  even though the Crypto interface is present on insecure contexts,
  as is the crypto property. In addition,
  the Crypto method getRandomValues() is available on insecure contexts,
  but the subtle property is not.

  */
  if (!Object.hasOwn(_crypto, 'subtle')) {
    return Promise.resolve(message);
  }

  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await _crypto.subtle.digest('SHA-256', data);

  const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}
