import { Injectable } from '@angular/core';

import { KeepAliveSessionService } from '../keep-alive-session.service';

@Injectable({
  providedIn: 'root',
})
export class KeepAliveSessionInitializerService {
  constructor(private _keepAliveSessionService: KeepAliveSessionService) {}

  initialize(): void {
    this._keepAliveSessionService.initialize();
  }
}
