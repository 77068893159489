import { ChartAPI } from 'c3';
import * as d3 from 'd3';

export const createPiechartLegendHandlers = (chartApiProvider: () => ChartAPI) => [
  createMouseOverHandler(chartApiProvider),
  createMouseOutHandler(chartApiProvider),
];

const createMouseOverHandler = (chartApiProvider: () => ChartAPI) => ({
  eventName: 'mouseover',
  eventHandler: function () {
    const chartElement = d3.select(this).attr('data-id');
    chartApiProvider().focus(chartElement);
  },
});

const createMouseOutHandler = (chartApiProvider: () => ChartAPI) => ({
  eventName: 'mouseout',
  eventHandler: () => chartApiProvider().revert(),
});
