import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const randomStringStartPosition = 2;
const radix = 36;

@Injectable()
export class ConnectionVerifier {
  constructor(private http: HttpClient) {}

  isOnline(err: HttpErrorResponse): Observable<boolean> {
    if (!this.statusConnectionBrowser()) {
      return of(false);
    }

    if (this.isNetworkError(err)) {
      return this.http.head(this.prepareUrl()).pipe(
        map(() => true),
        catchError(() => of(false)),
      );
    } else {
      return of(true);
    }
  }

  private statusConnectionBrowser() {
    return window.navigator.onLine;
  }

  private getRandomString() {
    return Math.random().toString(radix).substring(randomStringStartPosition);
  }

  private prepareUrl(): string {
    return `${window.location.origin}?random=${this.getRandomString()}`;
  }

  /**
   *  Check if the error is caused by a network error
   *  ```
   *  A network error is a response whose status is always 0,
   *  status message is always the empty byte sequence,
   *  header list is always empty, and body is always null.
   *  ```
   *  https://xhr.spec.whatwg.org/#the-status-attribute
   *  https://fetch.spec.whatwg.org/#concept-network-error
   @return boolean
   * @param err: HttpErrorRespone
   * @private
   */
  private isNetworkError(err: HttpErrorResponse) {
    return err.status === 0;
  }
}
