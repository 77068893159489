import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  HttpErrorMessageService,
  NotAvailableResource,
  NotAvailableResourceService,
} from '@demica/core/core';

@Component({
  selector: 'ngp-not-available-resource',
  templateUrl: './not-available-resource.component.html',
  styleUrls: ['./not-available-resource.component.sass'],
})
export class NotAvailableResourceComponent implements OnInit, OnDestroy {
  page$!: Observable<NotAvailableResource | null>;
  constructor(
    private _notAvailableResourceService: NotAvailableResourceService,
    private _router: Router,
    private _httpErrorMessageService: HttpErrorMessageService,
  ) {}

  ngOnInit(): void {
    this.page$ = this._notAvailableResourceService.notAvailableResourcePage$.pipe(
      tap((data) => {
        if (data?.errors || data?.showMessage) {
          this._httpErrorMessageService.showHttpErrorMessage(data.errors || []);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this._notAvailableResourceService.clearPage();
  }

  goToUrl(url: string): void {
    this._router.navigateByUrl(url);
  }
}
