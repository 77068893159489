import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ComponentConfiguration, EntityId, Range, trimDecimalPlaces } from '@demica/core/core';

import { TextTableHeaderComponent } from '../data-table/text-table-header.component';
import { RangesListRowComponent } from '../ranges-list-row/ranges-list-row.component';

import { RANGE_TYPE, RATE_TYPE } from '../../model/range.constants';
import { RangeRow } from '../../model/range.interface';
import { Actions } from '../actions/model/actions.interface';
import { ColumnDefinition } from '../data-table/column-definition.interface';
import { DataSource } from '../data-table/data-source.interface';

@Component({
  selector: 'trf-ranges-list',
  templateUrl: './ranges-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangesListComponent implements OnChanges, OnInit {
  @Input()
  rangeType: EntityId;
  @Input()
  rateType: EntityId;
  @Input()
  ranges: Range[];
  @Input()
  previewMode: boolean;
  @Input()
  versionPreviewMode: boolean;
  @Input()
  formOpened: boolean;
  @Input()
  valueLabel: string;
  @Input()
  lowerRangeTableLabel: string;
  @Input()
  upperRangeTableLabel: string;
  @Input()
  numberOfDecimalPlacesForValue: number;
  @Input()
  editRangeRequiredAuthority: string;
  @Output()
  editClick = new EventEmitter<Range>();

  dataSource: DataSource<Array<Range & RangeRow>> = this.configureDataSource();
  headerConfig: ComponentConfiguration;
  rowConfig: ComponentConfiguration;

  ngOnInit(): void {
    this.headerConfig = this.configureTableHeader();
    this.rowConfig = this.configureTableRows();
  }

  ngOnChanges(): void {
    this.dataSource.data.next(
      this.ranges.map((range) => ({
        ...range,
        rangePercentage: this.rangeType === RANGE_TYPE.percentage,
        valuePercentage: this.rateType !== RATE_TYPE.amount,
      })),
    );
  }

  private configureTableColumns(): ColumnDefinition[] {
    return [
      {
        nameKey: this.lowerRangeTableLabel,
        property: 'type',
      },
      {
        nameKey: this.upperRangeTableLabel,
        property: 'currency',
      },
      {
        nameKey: this.valueLabel ? this.valueLabel : 'RANGES.TABLE_COLUMN_VALUE.' + this.rateType,
        property: 'value',
      },
      {
        nameKey: 'RANGES.TABLE_COLUMN_ACTIONS',
        property: 'actions',
        classes: 'actions',
      },
    ];
  }

  private configureDataSource() {
    return {
      data: new BehaviorSubject([]),
    };
  }

  private configureTableHeader() {
    return {
      component: TextTableHeaderComponent,
      inputs: {
        columns: this.configureTableColumns(),
      },
    };
  }

  private configureTableRows(): ComponentConfiguration {
    const rowActions: Actions = {
      edit: {
        titleKey: 'RANGES.TABLE_ACTION_EDIT',
        handler: (range: Range) =>
          this.editClick.next({
            ...range,
            value: String(
              trimDecimalPlaces(String(range.value), this.numberOfDecimalPlacesForValue),
            ),
          }),
        icon: 'pencil-alt',
        testId: 'action-edit',
        hidden: () => this.previewMode,
        inactive: () => !this.rangeType || !this.rateType || this.formOpened,
        ...(this.editRangeRequiredAuthority
          ? { requiredAuthority: this.editRangeRequiredAuthority }
          : {}),
      },
    };

    return {
      component: RangesListRowComponent,
      inputs: {
        numberOfDecimalPlacesForValue: this.numberOfDecimalPlacesForValue,
        actions: rowActions,
      },
    };
  }
}
