import { Injectable } from '@angular/core';

import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  scan,
  shareReplay,
  startWith,
  take,
  tap,
} from 'rxjs/operators';

import { AliasesResourceService, AnalysisCodeReference, EntityId } from '@demica/core/core';

type DataStreamType = Map<EntityId, AnalysisCodeReference[]>;

@Injectable()
export class AnalysisCodesPartialLoaderService {
  loading$: Observable<boolean>;
  analysisCodes$: Observable<DataStreamType>;

  private _loadAnalysisCodes$ = new Subject<{
    transactionId: EntityId;
    columnTypeIds: EntityId[];
  }>();
  private _appendAnalysisCodes$ = new Subject<{
    columnTypeId: EntityId;
    analysisCode: AnalysisCodeReference;
  }>();

  private _loading$ = new BehaviorSubject<boolean>(false);
  private _loadingCompletedIds = new Set<EntityId>();
  private _loadingRequestedIds = new Set<EntityId>();
  private _lastResult: DataStreamType;

  constructor(private readonly aliasesResourceService: AliasesResourceService) {
    this.loading$ = this._loading$.pipe(distinctUntilChanged());

    this.analysisCodes$ = merge(this._createApiLoaderStream(), this._createAppendStream()).pipe(
      scan(
        (result, analysisCodes) => new Map([...result.entries(), ...analysisCodes.entries()]),
        new Map<EntityId, AnalysisCodeReference[]>(),
      ),
      startWith(new Map<EntityId, AnalysisCodeReference[]>()),
      tap((result) => {
        this._lastResult = result;
      }),
      shareReplay(1),
    );
  }

  loadAnalysisCodes(transactionId: EntityId, columnTypeIds: EntityId[]): Observable<boolean> {
    this._loadAnalysisCodes$.next({ transactionId, columnTypeIds });

    return this.analysisCodes$.pipe(
      map(() => columnTypeIds.every((columnTypeId) => this._loadingCompletedIds.has(columnTypeId))),
      filter((loaded) => loaded),
      take(1),
    );
  }

  appendAnalysisCodes(columnTypeId: EntityId, analysisCode: AnalysisCodeReference): void {
    this._appendAnalysisCodes$.next({
      columnTypeId,
      analysisCode,
    });
  }

  private _createApiLoaderStream(): Observable<DataStreamType> {
    return this._loadAnalysisCodes$.pipe(
      map((request) => ({
        ...request,
        columnTypeIds: this._filterOutPreviousRequests(request.columnTypeIds),
      })),
      filter(({ columnTypeIds }) => !!columnTypeIds.length),
      tap(({ columnTypeIds }) => this._loadingStarted(columnTypeIds)),
      mergeMap(({ transactionId, columnTypeIds }) =>
        this._getAnalysisCodes(transactionId, columnTypeIds),
      ),
    );
  }

  private _createAppendStream(): Observable<DataStreamType> {
    return this._appendAnalysisCodes$.pipe(
      map(({ columnTypeId, analysisCode }): DataStreamType => {
        const columnAnalysisCodes = [...this._lastResult.get(columnTypeId)];

        columnAnalysisCodes.push(analysisCode);

        return new Map<EntityId, AnalysisCodeReference[]>([[columnTypeId, columnAnalysisCodes]]);
      }),
    );
  }

  private _getAnalysisCodes(
    transactionId: EntityId,
    columnTypeIds: EntityId[],
  ): Observable<Map<EntityId, AnalysisCodeReference[]>> {
    return this.aliasesResourceService
      .getAnalysisCodesReferencesList(transactionId, columnTypeIds)
      .pipe(
        map((data) => this._groupByColumnType(data)),
        tap(() => this._loadingCompleted(columnTypeIds)),
      );
  }

  private _filterOutPreviousRequests(columnTypeIds: EntityId[]): EntityId[] {
    return columnTypeIds.filter(
      (columnTypeId) =>
        !this._loadingRequestedIds.has(columnTypeId) &&
        !this._loadingCompletedIds.has(columnTypeId),
    );
  }

  private _loadingStarted(columnTypeIds: EntityId[]): void {
    this._loadingRequestedIds = new Set([...this._loadingRequestedIds.values(), ...columnTypeIds]);
    this._handleLoadingIndicator();
  }

  private _loadingCompleted(columnTypeIds: EntityId[]): void {
    this._loadingCompletedIds = new Set([...this._loadingCompletedIds.values(), ...columnTypeIds]);
    this._handleLoadingIndicator();
  }

  private _handleLoadingIndicator(): void {
    this._loading$.next(this._loadingCompletedIds.size !== this._loadingRequestedIds.size);
  }

  private _groupByColumnType(data: AnalysisCodeReference[]): DataStreamType {
    return data.reduce((result, currentItem): DataStreamType => {
      const group = currentItem.columnTypeId;
      const items = result.get(group) ?? [];

      items.push(currentItem);
      result.set(group, items);

      return result;
    }, new Map<EntityId, AnalysisCodeReference[]>());
  }
}
