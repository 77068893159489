import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { getFormControlName, maxIntegerValue, minIntegerValue } from '@demica/core/core';

import { filterKeyForIntegersOnly } from '../../forms/form';
import { FormRowClass } from '../../forms/model/form-row.class';
import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import {
  INVALID_NUMBER_FORMAT_ERROR_KEY,
  MAX_INT_EXCEEDED_ERROR_KEY,
  MIN_INT_EXCEEDED_ERROR_KEY,
} from './form-number-row-error-keys';
import { NumberRegexBuilder } from './regex/number-regex.builder';

@Component({
  selector: 'trf-form-number-row',
  templateUrl: 'form-number-row.component.html',
  styleUrls: ['./form-number-row.component.sass'],
  providers: [{ provide: FormRowClass, useExisting: FormNumberRowComponent }],
})
export class FormNumberRowComponent implements OnInit, FormRowClass {
  @Input()
  name?: string;
  @Input()
  control?: FormControl;
  @Input()
  htmlId?: string;
  @Input()
  label?: string;
  @Input()
  placeholder?: string;
  @Input()
  labelClasses?: string;
  @Input()
  validations?: ValidationMessage[];
  @Input()
  inputClasses?: string[];
  @Input()
  minValue?: number;
  @Input()
  maxValue?: number;
  @Input()
  regExp?: RegExp | string;
  @Input()
  acceptNegatives? = false;
  @Input()
  suffixLabel: string;
  @Input()
  readonly?: boolean;
  @Input()
  helperTooltip?: string;
  @Input()
  infoTooltip?: string;
  @Input()
  ariaDescText?: string;
  @Input()
  disable?: boolean;
  @ViewChild('additionalValidationContainer', { read: ViewContainerRef })
  additionalValidationContainer: ViewContainerRef;

  group: FormGroup;
  formControl: FormControl<string | number | null>;
  formControlName: string;
  regExpStr: RegExp;
  protected acceptRegex: RegExp;
  protected validatorsMap: Map<string, ValidatorFn[]> = new Map();
  protected isNotValidLocalisedNumber = false;

  constructor(protected fgd: FormGroupDirective) {
    this.validatorsMap.set('numberValidators', [this.formatValidator, this._validateMinMaxValue]);
  }

  ngOnInit(): void {
    this.initialize();
    this.acceptRegex = new NumberRegexBuilder().setAcceptNegatives(this.acceptNegatives).build();
  }

  onKeypress(event: KeyboardEvent): boolean {
    return filterKeyForIntegersOnly(event.key, this.acceptNegatives);
  }

  onBlur(): void {
    if (this.numberInvalid()) {
      this.formControl.setValue(null);
    }
  }

  hasOtherErrors(): boolean {
    return (
      !!this.formControl.errors &&
      Object.getOwnPropertyNames(this.formControl.errors).length >
        (this.formControl.hasError(MIN_INT_EXCEEDED_ERROR_KEY) ? 1 : 0) +
          (this.formControl.hasError(MAX_INT_EXCEEDED_ERROR_KEY) ? 1 : 0)
    );
  }

  hasError(errorName: string): boolean {
    return (
      !!this.formControl.errors &&
      Object.getOwnPropertyNames(this.formControl.errors).length === 1 &&
      this.formControl.hasError(errorName)
    );
  }

  protected initialize(): void {
    this.regExpStr = new RegExp(this.regExp);
    this.group = this.fgd.form;
    this.formControl = this.control ?? (this.group.get(this.name) as FormControl);
    this.formControlName = this.name ?? getFormControlName(this.formControl);
    if (this.formControl?.validator) {
      this.validatorsMap.set('formControlValidators', [this.formControl.validator]);
    }
    this.setValidators();
  }

  protected setValidators(): void {
    const validators = Array.from(this.validatorsMap.values()).flatMap((fn) => fn);
    this.formControl.setValidators(validators);
  }

  protected formatValidator = (fc: AbstractControl): ValidationErrors | null => {
    const invalidError = { [INVALID_NUMBER_FORMAT_ERROR_KEY]: { formatInvalid: true } };

    if (this.isNotValidLocalisedNumber) return invalidError;
    const validationResult = Validators.pattern(this.acceptRegex ?? this.regExpStr)(fc)
      ? invalidError
      : null;
    return validationResult;
  };

  protected numberInvalid(): boolean {
    const val = this.formControl.value;
    return Number.isNaN(val);
  }

  private _validateMinMaxValue = (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const value = +control.value;
      if (value < this.minValue ?? minIntegerValue) {
        return { [MIN_INT_EXCEEDED_ERROR_KEY]: true };
      }
      if (value > this.maxValue ?? maxIntegerValue) {
        return { [MAX_INT_EXCEEDED_ERROR_KEY]: true };
      }
    }
    return null;
  };
}
