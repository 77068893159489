import { poolingUrls } from '../config/pooling-urls';

export const loginDetailsUrl = '/api/trf/v1/resources/user-accounts/my-account/login-details';

const urlsToSkipTokenRefresh = [
  '/api/trf/v1/resources/dashboards/funding-alerts',
  ...poolingUrls,
  loginDetailsUrl,
];

export function urlShouldBeIgnored(currentUrl: string): boolean {
  return urlsToSkipTokenRefresh.includes(currentUrl.split('?')[0]);
}
