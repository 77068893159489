import {
  BoundingBox,
  D3Sel,
  LegendConfiguration,
  appendLegend,
  getSVGBox,
} from '@demica/core/core';

import * as d3 from 'd3';

/** Horizontal margin between chart elements and chart edges */
const MARGIN = 20;

export class PieChartPositioningController {
  private readonly initialSVGHeight: number;
  private readonly svg: D3Sel;
  private chartWrapper: D3Sel;
  private legendConfiguration: LegendConfiguration;

  constructor(bindSelector: string, chartContainer: HTMLElement) {
    this.svg = d3.select(bindSelector).select('svg');
    this.chartWrapper = d3.select(chartContainer);

    this.initialSVGHeight = parseFloat(this.svg.attr('height'));
  }

  appendLegend(config: LegendConfiguration) {
    this.legendConfiguration = config;
    appendLegend(this.svg, config);
    requestAnimationFrame(() => this.resize());
  }

  resize() {
    if (!this.legendConfiguration) throwDetachedFromDOM();

    const svgWidth = parseFloat(this.svg.attr('width'));
    const middle = this.initialSVGHeight / 2;

    this.positionLegend(middle);
    const availableBox = this.calculatePieBoundingBox(svgWidth, this.initialSVGHeight);
    this.positionPie(middle, availableBox);
  }

  private positionLegend(middle: number) {
    const legend = d3.select('#' + this.legendConfiguration.legendContainerId);
    const legendX = MARGIN;
    let legendY: number | string;
    if (this.legendHigherThanPie(legend, this.svg)) {
      this.updateSVGViewPort(getSVGBox(legend).height + 2 * MARGIN);
      legendY = MARGIN;
    } else {
      if (this.legendConfiguration.align === 'middle') {
        legendY = middle - getSVGBox(legend).height / 2;
      } else {
        legendY = this.legendConfiguration.align ?? MARGIN;
      }
    }
    legend.attr('transform', `translate(${legendX}, ${legendY})`);
  }

  private calculatePieBoundingBox(containerWidth: number, containerHeight: number) {
    const legend = d3.select('#' + this.legendConfiguration.legendContainerId);
    return new BoundingBox(
      MARGIN + Math.min(getSVGBox(legend).width, this.initialSVGHeight) + MARGIN,
      MARGIN,
      containerWidth - MARGIN,
      containerHeight - MARGIN,
    );
  }

  private positionPie(middle: number, availableBox: BoundingBox) {
    const pie = this.svg.select('.c3-chart-arcs');
    const pieBox = getSVGBox(pie);
    const pieX = availableBox.x + availableBox.width / 2;
    const pieY = middle;
    const scale = Math.min(availableBox.width, availableBox.height) / pieBox.width;

    if (scale > 0 && scale !== Infinity)
      pie.attr('transform', `translate(${pieX}, ${pieY}) scale(${scale})`);
  }

  private updateSVGViewPort(legendHeight: number) {
    this.chartWrapper.style(
      'margin-bottom',
      `${legendHeight - this.initialSVGHeight}px`,
      'important',
    );
    this.svg.attr('height', legendHeight);
  }

  private legendHigherThanPie(legend: D3Sel, svg: D3Sel) {
    return getSVGBox(legend).height + 2 * MARGIN > getSVGBox(svg).height;
  }
}

function throwDetachedFromDOM() {
  throw new Error('Legend is detached from DOM');
}
