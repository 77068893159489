<div
  class="data-export"
  aria-labelledby="export-label"
  [id]="dataExportId"
  testid="data-export-component"
>
  <p id="export-label">{{ exportLabel | translate }}</p>
  <div>
    <ngp-button
      key="CSV"
      [classes]="exportEnabled ? 'tertiary' : 'inactive'"
      (click)="requestExportDownload(exportType.CSV)"
      testid="export-csv-button"
    ></ngp-button>
    <ngp-button
      key="XLSX"
      [classes]="exportEnabled ? 'tertiary' : 'inactive'"
      (click)="requestExportDownload(exportType.XLSX)"
      testid="export-xlsx-button"
    ></ngp-button>
    <ngp-button
      key="PDF"
      [classes]="exportEnabled ? 'tertiary' : 'inactive'"
      (click)="requestExportDownload(exportType.PDF)"
      testid="export-pdf-button"
    ></ngp-button>
  </div>
</div>
