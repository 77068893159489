import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { setOnError, toBoolean } from '@demica/core/core';
import { LoggerService, LogArea } from '@demica/logger';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {
  private destroyed = new Subject<void>();
  private enabled = true;

  constructor(private logger: LoggerService) {
    setOnError((message: string, url: string, lineNo: number, columnNo: number, error: Error) => {
      this.sendError(error);
    });
  }

  setEnabled(enabled: boolean | string): void {
    this.enabled = toBoolean(enabled);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
  }

  handleError(error: unknown): void {
    if (!this.enabled) return;
    this.sendError(error);
  }

  private sendError(error: unknown): void {
    const message = this.isError(error)
      ? `[${error.name}] ${error.message}${error.stack ? '\n\n' + error.stack : ''}`
      : error.toString();

    console.error(error);
    this.logger.addError({ message, area: LogArea.ERROR_HANDLER }, (error as Error)?.stack);
  }

  private isError(obj: unknown): obj is Error {
    const hasProp = Object.prototype.hasOwnProperty;
    return hasProp.call(obj, 'name') && hasProp.call(obj, 'message');
  }
}
