import { inject, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';

import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

/*
 *  Custom NgbDate Adapter
 *  Its internal adapter only to resolve ngb date_time struct  in context of js native Date object
 * */
@Injectable()
export class NgbDateTimeNativeAdapter {
  // We are using this adapter only in context of DateTimePicker component so this hard binding is ok here
  private _originDate: FormControl<Date> = inject(DateTimePickerComponent)._dateTimeInnerControl;
  fromModel(date: Date): NgbDateStruct & NgbTimeStruct {
    if (!isDate(date)) return null;
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
  }

  toModel(date: NgbDateStruct | NgbTimeStruct): Date {
    if (isTimeStruct(date)) {
      return new Date(
        this._originDate?.value.getFullYear(),
        this._originDate?.value.getMonth(),
        this._originDate?.value.getDate(),
        date.hour,
        date.minute,
        date.second,
      );
    } else if (isDateStruct(date)) {
      return new Date(
        date.year,
        date.month - 1,
        date.day,
        this._originDate?.value.getHours(),
        this._originDate?.value.getMinutes(),
        this._originDate?.value.getSeconds(),
      );
    } else return null;
  }
}

export const isTimeStruct = (value: object): value is NgbTimeStruct => {
  return ['hour', 'minute', 'second'].every((propKey) => Object.hasOwn(value, propKey));
};

export const isDateStruct = (value: object): value is NgbDateStruct => {
  return ['year', 'month', 'day'].every((propKey) => Object.hasOwn(value, propKey));
};

export const isDate = (value: unknown): value is Date => {
  return value instanceof Date;
};
