import { SelectionModel } from '@angular/cdk/collections';

import { Observable, Subject } from 'rxjs';

import { CheckboxStatesEnum } from '../types/checkbox-states.enum';
import { NodeId, TreeNodeContext } from '../types/tree-node-context.interface';

type SelectedNode<T> = T | NodeId;

export class TreeChecklistControl<T extends object> {
  selectionChange$!: Observable<T[]>;

  private _treeStruct = new WeakMap<T, TreeNodeContext<T>>();
  private _selectedItems = new SelectionModel<T>(true);
  private _selectionChangeSubject$ = new Subject<T[]>();

  constructor(
    private _itemRoots: T[],
    initialSelect: SelectedNode<T>[] = [],
    private _getChildren: (dataNode: T) => T[] | undefined | null,
    private _trackBy?: (item: T) => NodeId,
  ) {
    this._rebuildStructure(initialSelect);

    this.selectionChange$ = this._selectionChangeSubject$.asObservable();
  }

  toggleSelection(node: T, change: boolean): void {
    this.markSelection(node, change);

    const branchesToUpdate = this._getRootPath(node);
    branchesToUpdate.forEach((branch) => {
      this._resolveBranchesState(branch);
    });
    this._updateSelectedItems();
  }

  markSelection(node: T, change: boolean): void {
    const nodeContext = this.getNodeContext(node);
    if (nodeContext.isLeaf) {
      if (change) {
        this._selectedItems.select(node);
      } else {
        this._selectedItems.deselect(node);
      }
    }
    nodeContext.selectionState = change ? CheckboxStatesEnum.CHECKED : CheckboxStatesEnum.UNCHECKED;
    nodeContext.children.forEach((child) => this.markSelection(child, change));
  }

  getSelectionState(node: T): CheckboxStatesEnum {
    return this.getNodeContext(node)?.selectionState || CheckboxStatesEnum.UNCHECKED;
  }

  getNodeContext(node: T): TreeNodeContext<T> {
    return this._treeStruct.get(node) as TreeNodeContext<T>;
  }

  getSelectedItems(): T[] {
    return this._selectedItems.selected;
  }

  updateSelection(selected: T[] | NodeId[]) {
    const selectedItems = this._selectedItems.selected;
    if (selectedItems.length === selected.length) {
      const currentlySelected = selectedItems.map((it) =>
        this._trackBy ? this._trackBy(it) ?? it : it,
      );
      const newSelected = selected.map((it) => (this._trackBy ? this._trackBy(it as T) ?? it : it));
      if (currentlySelected.every((it) => newSelected.includes(it))) {
        return;
      }
    }
    this._rebuildStructure(selected);
    this._updateSelectedItems();
  }

  private _rebuildStructure(selected: SelectedNode<T>[]): void {
    this._treeStruct = new WeakMap<T, TreeNodeContext<T>>();
    this._selectedItems.clear();
    this._itemRoots.forEach((item) => {
      this._buildTreeStructure(0, null, item, selected);
      this._resolveInitialBranchState(item);
    });
  }

  private _buildTreeStructure(
    level: number,
    parent: TreeNodeContext<T> | null,
    item: T,
    selected: SelectedNode<T>[],
  ): void {
    const children = this._getChildren(item) ?? [];
    const isLeaf = !children?.length;
    const node: TreeNodeContext<T> = {
      item,
      parent: parent?.item,
      children,
      level,
      isLeaf,
      selectionState:
        isLeaf &&
        selected.find(
          (it) =>
            ((this._trackBy && this._trackBy(item)) ?? item) ===
            ((this._trackBy && this._trackBy(it as T)) ?? (it as T)),
        )
          ? CheckboxStatesEnum.CHECKED
          : CheckboxStatesEnum.UNCHECKED,
    };
    this._treeStruct.set(item, node);
    if (node.isLeaf && node.selectionState === CheckboxStatesEnum.CHECKED) {
      this._selectedItems.select(node.item);
    }
    if (children) {
      children.forEach((child) => {
        this._buildTreeStructure(level + 1, node, child, selected);
      });
    }
  }

  private _resolveInitialBranchState(node: T): void {
    const desc = this._getAllDescendants(node).filter((descendant) => descendant.isLeaf);

    desc.forEach((descendant) => {
      const branchesToUpdate = this._getRootPath(descendant.item);
      branchesToUpdate.forEach((branch) => {
        this._resolveBranchesState(branch);
      });
    });
  }

  private _resolveBranchesState(node: T): void {
    const nodeContext = this.getNodeContext(node);
    const allSelected = nodeContext.children?.every(
      (child) => this.getNodeContext(child).selectionState === CheckboxStatesEnum.CHECKED,
    );
    const partialySelected = nodeContext.children?.some(
      (child) =>
        this.getNodeContext(child).selectionState === CheckboxStatesEnum.CHECKED ||
        this.getNodeContext(child).selectionState === CheckboxStatesEnum.INDETERMINATE,
    );
    if (allSelected) {
      nodeContext.selectionState = CheckboxStatesEnum.CHECKED;
    } else if (partialySelected) {
      nodeContext.selectionState = CheckboxStatesEnum.INDETERMINATE;
    } else {
      nodeContext.selectionState = CheckboxStatesEnum.UNCHECKED;
    }
  }

  private _getAllDescendants(node: T): TreeNodeContext<T>[] {
    const nodeContext = this.getNodeContext(node);
    if (nodeContext.isLeaf) {
      return [nodeContext];
    }
    const descendants = nodeContext.children?.reduce(
      (acc, child) => {
        return [...acc, ...this._getAllDescendants(child)];
      },
      [nodeContext] as TreeNodeContext<T>[],
    );

    descendants?.sort((a, b) => b.level - a.level);

    return [...descendants];
  }

  private _getRootPath(node: T): T[] {
    const nodeContext = this.getNodeContext(node);
    const path = nodeContext.isLeaf ? [] : [node];
    let parent = nodeContext.parent;
    while (parent) {
      path.push(parent);
      parent = this._treeStruct.get(parent)?.parent;
    }
    return path;
  }

  private _updateSelectedItems(): void {
    this._selectionChangeSubject$.next(this._selectedItems.selected);
  }
}
