import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormControl, AbstractControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  errorNotification,
  getFormControlName,
  NotificationEntry,
  Notifications,
} from '@demica/core/core';

import { ngbDateMsg } from '../../forms/validation-messages/common-validation-messages';
import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';

const incorrectDateValidationError = errorNotification('VALIDATION.DATE_CORRECT');
const missingDateError = errorNotification('VALIDATION.PROVIDE_AT_LEAST_ONE_DATE');

const dateFromGreaterError = (key: string) => errorNotification(key);
const missingFromError = (key: string) => errorNotification(key);
const missingToError = (key: string) => errorNotification(key);

interface DoubleDateTimes {
  dateTimeFrom: Date;
  dateTimeTo: Date;
}

@Component({
  selector: 'trf-double-datetime-picker',
  templateUrl: './double-datetime-picker.component.html',
  styleUrls: ['./double-datetime-picker.component.sass'],
})
export class DoubleDatetimePickerComponent implements OnInit, OnDestroy {
  @Input()
  labelDateTimeFrom: string;

  @Input()
  labelDateTimeTo: string;

  @Input()
  clearSubject: Subject<void>;

  @Input()
  dateTimeFromControl: FormControl;

  @Input()
  dateTimeToControl: FormControl;

  @Input()
  disabled? = false;

  @Input()
  dateTimeFromTestIdName? = 'datetime-from';

  @Input()
  dateTimeToTestIdName? = 'datetime-to';

  @Input()
  requireAtLeastOneDateTime = false;

  @Input()
  bothRequired = false;

  @Input()
  requiredFromMsgErrorKey = 'VALIDATION.PROVIDE_FROM_DATE';

  @Input()
  requiredToMsgErrorKey = 'VALIDATION.PROVIDE_TO_DATE';

  @Input()
  dateRangeMsgErrorKey = 'DASHBOARD_RECEIVABLES.DATE_VALIDATION_ERROR_MESSAGE';

  group: FormGroup;
  dates: DoubleDateTimes = {
    dateTimeFrom: null,
    dateTimeTo: null,
  };

  dateFromControlName: string;
  dateToControlName: string;

  fromDateTimeValidations: ValidationMessage[];
  toDateTimeValidations: ValidationMessage[];

  notifications = new Notifications();

  private _destroy$ = new Subject<void>();

  constructor(private _fgd: FormGroupDirective) {}

  ngOnInit(): void {
    this._createFormControls();

    this.fromDateTimeValidations = this.createDateValidationMessages();
    this.toDateTimeValidations = this.createDateValidationMessages();

    this.clearSubject?.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.notifications.clear();
    });

    this.group.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      this.dates.dateTimeFrom =
        value[this.dateFromControlName] === null ? null : value[this.dateFromControlName];

      this.dates.dateTimeTo =
        value[this.dateToControlName] === null ? null : value[this.dateToControlName];

      this._handleValueChanges(this.dates);
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  createDateValidationMessages(): ValidationMessage[] {
    return ngbDateMsg(this.group, () => false);
  }

  private _createFormControls(): void {
    this.group = this._fgd.form;

    this._createDateToControlName();
    this._createDateToControl();
  }

  private _createDateToControlName(): void {
    this.dateFromControlName = getFormControlName(this.dateTimeFromControl);
  }

  private _createDateToControl(): void {
    this.dateToControlName = getFormControlName(this.dateTimeToControl);
  }

  private _hasDatePickerInvalid(control: AbstractControl): boolean {
    return control.getError('ngbDate');
  }

  private _handleValueChanges(value: DoubleDateTimes): void {
    this.notifications.clear();

    this._invalidDateValidation();
    this._missingDateValidation(value);
    this._fromDateGreaterThanToDateValidation(value);
  }

  private _invalidDateValidation(): void {
    if (
      this._hasDatePickerInvalid(this.dateTimeFromControl) ||
      this._hasDatePickerInvalid(this.dateTimeToControl)
    ) {
      this._addNotification(incorrectDateValidationError);
    }
  }

  private _missingDateValidation(value: DoubleDateTimes): void {
    const dateFromValue = value.dateTimeFrom;
    const dateToValue = value.dateTimeTo;
    const dateFromTouched = this.dateTimeFromControl.touched;
    const dateToTouched = this.dateTimeToControl.touched;

    const missingDate = this.requireAtLeastOneDateTime && !dateFromValue && !dateToValue;
    const missingAny =
      this.bothRequired && ((!dateFromValue && dateFromTouched) || (!dateToValue && dateToTouched));

    if (missingDate) {
      this._addNotification(missingDateError);
    }
    this._changeErrorStatus('missingDate', missingDate);

    if (missingAny) {
      this._addNotification(
        dateFromValue
          ? missingToError(this.requiredToMsgErrorKey)
          : missingFromError(this.requiredFromMsgErrorKey),
      );
    }

    this._changeErrorFieldStatus(this.dateTimeFromControl, 'require', missingAny && !dateFromValue);
    this._changeErrorFieldStatus(this.dateTimeToControl, 'require', missingAny && !dateToValue);
  }

  private _fromDateGreaterThanToDateValidation(value: DoubleDateTimes): void {
    const dateFromGreater =
      value.dateTimeFrom !== null &&
      value.dateTimeTo !== null &&
      value.dateTimeFrom > value.dateTimeTo;

    if (dateFromGreater) {
      this._addNotification(dateFromGreaterError(this.dateRangeMsgErrorKey));
    }

    this._changeErrorStatus('dateFromGreater', dateFromGreater);
  }

  private _addNotification(entry: NotificationEntry): void {
    if (!this.notifications.hasAnyError()) {
      this.notifications.add(entry);
    }
  }

  private _changeErrorStatus(errorKey: string, errorExists: boolean): void {
    this._changeErrorFieldStatus(this.dateTimeFromControl, errorKey, errorExists);
    this._changeErrorFieldStatus(this.dateTimeToControl, errorKey, errorExists);
  }

  private _changeErrorFieldStatus(
    control: AbstractControl,
    errorKey: string,
    errorExists: boolean,
  ): void {
    const errors = control.errors ?? {};

    if (errorExists) {
      errors[errorKey] = true;
    } else {
      delete errors[errorKey];
    }

    control.setErrors(Object.keys(errors).length ? errors : null);
  }
}
