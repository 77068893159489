import { Locale } from '../config/locale.enum';

export function normalizeLanguageList(...langList: string[]): Locale[] {
  const SEPARATOR = ' ';
  const mergedList = langList
    .join(SEPARATOR)
    .split(SEPARATOR)
    .map((it) => it.trim() as Locale)
    .filter(Boolean);
  return [...new Set(mergedList)];
}
