import { NgModule } from '@angular/core';

import { AuthUserContextChangeClient } from './auth-user-context-change-client/auth-user-context-change-client.service';
import { BroadcastChannelService } from './broadcast-channel.service';
import { HelloBroadcastClientService } from './hello-broadcast-client/hello-broadcast-client.service';
import { BroadcastChannelInitializerService } from './initializer/broadcast-channel-initializer.service';
import { LogoutBroadcastClientService } from './logout-client/logout-broadcast-client.service';

import { BROADCAST_AUTOWIRED_CLIENT } from './broadcast-channel.types';

@NgModule({
  providers: [
    BroadcastChannelInitializerService,
    BroadcastChannelService,
    {
      provide: BROADCAST_AUTOWIRED_CLIENT,
      multi: true,
      useExisting: HelloBroadcastClientService,
    },
    {
      provide: BROADCAST_AUTOWIRED_CLIENT,
      multi: true,
      useExisting: AuthUserContextChangeClient,
    },
    {
      provide: BROADCAST_AUTOWIRED_CLIENT,
      multi: true,
      useExisting: LogoutBroadcastClientService,
    },
  ],
})
export class BroadcastChannelModule {}
