import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

import { of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import {
  ajaxDelay,
  AliasesResourceService,
  AvailabilityResponse,
  EntityId,
  maxPredefinedColumnLength,
  maxUserDefinedColumnLength,
} from '@demica/core/core';

import {
  defaultMaxLengthMsg,
  requiredMsg,
} from '../forms/validation-messages/common-validation-messages';
import { buildMessages } from '../forms/validation-messages/message-builder';
import { msgMaxLength, msgRequired } from '../forms/validation-messages/message-definitions';
import { hasError } from '../forms/validation-messages/message-predicates';

export const AliasesValidator = {
  require: requiredMsg,
  maxLength: defaultMaxLengthMsg,
  code: buildMessages([
    msgRequired,
    msgMaxLength(maxUserDefinedColumnLength),
    { func: hasError('analysis-code-unavailable'), key: 'ERRORS.ANALYSIS_CODE_NOT_UNIQUE' },
  ]),
  aliasValueUserDefined: buildMessages([
    msgRequired,
    msgMaxLength(maxUserDefinedColumnLength),
    { func: hasError('name-unavailable'), key: 'ERRORS.ALIAS_VALUE_NOT_UNIQUE' },
  ]),
  aliasValuePredefined: buildMessages([
    msgRequired,
    msgMaxLength(maxPredefinedColumnLength),
    { func: hasError('name-unavailable'), key: 'ERRORS.ALIAS_VALUE_NOT_UNIQUE' },
  ]),

  hasCodeAvailable: function (
    aliasResourceService: AliasesResourceService,
    transactionId: EntityId,
    columnTypeId: EntityId,
    analysisCodeId?: EntityId,
  ): AsyncValidatorFn {
    return function (control: AbstractControl) {
      let controlValue = control.value;
      if (controlValue.entityId) {
        controlValue = controlValue.entityId;
      }
      if (!controlValue || controlValue.length < 1) {
        return of(null);
      }

      return ajaxDelay.pipe(
        switchMap(() =>
          aliasResourceService.checkAnalysisCodeAvailable(
            transactionId,
            columnTypeId,
            controlValue,
            analysisCodeId,
          ),
        ),
        distinctUntilChanged(),
        map(responseToValidationErrors),
      );
    };
  },
};

function responseToValidationErrors(response: AvailabilityResponse) {
  if (!response.available) return { 'analysis-code-unavailable': true };

  return null;
}
