/*
  Directory name for trf client specific files: theme, translations etc...
 */
export const CLIENT_ASSETS_DIR = 'client-assets';
export const SRC_DIR = 'apps/trfgui/src/';
export const PUBLIC_APP_PATH = 'public-app';
export const SESSION_TIMEOUT_PATH = 'session-timeout';
export const POST_LOGOUT = 'post-logout';
export const PRIVACY_NOTICE_PATH = 'privacy-notice';

export const getURIToPathInPublicApp = (lang: string, path: string) =>
  [window.location.origin, PUBLIC_APP_PATH, lang, path].join('/');

export const getPublicSessionTimeoutURI = (lang: string) =>
  getURIToPathInPublicApp(lang, SESSION_TIMEOUT_PATH);
export const getPublicPostLogoutPageURI = (lang: string) =>
  getURIToPathInPublicApp(lang, POST_LOGOUT);

/* used in other places
1  app-config.json
2  {theme}/vars.css => --trf-brand-logo
3  index.html => favicon + link:css:varsc.css
4  <root>/deployment/docker/configure-nginx.ts

 */
