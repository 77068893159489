const developmentLicense = {
  company: 'Demica Ltd',
  contact: 'Kishore Patel',
  date: '12/06/2022',
  distribution: false,
  domains: ['*'],
  email: 'kishore.patel@demica.com',
  fileSystemAllowed: true,
  licensefileversion: '1.1',
  localhost: true,
  oobAllowed: true,
  package: 'complete',
  product: 'yFiles for HTML',
  projectname: 'Trade Receivable Finance',
  subscription: '12/04/2023',
  type: 'project',
  version: '2.5',
  watermark: 'yFiles HTML Development License',
  key: '7ed3c2957364713cd5da42992eea1d039a850697',
};

const productionLicense = {
  company: 'Demica Ltd',
  contact: 'Kishore Patel',
  date: '12/06/2022',
  distribution: true,
  domains: ['demica.com'],
  email: 'kishore.patel@demica.com',
  fileSystemAllowed: false,
  licensefileversion: '1.1',
  localhost: false,
  oobAllowed: false,
  package: 'complete',
  product: 'yFiles for HTML',
  projectname: 'Trade Receivable Finance',
  subscription: '12/04/2023',
  type: 'project',
  version: '2.5',
  key: '579031301a0cee8626b72a224253bef4449cbdac',
};

export function configureYFilesLicense(licenseType: string) {
  if (licenseType === 'development') return developmentLicense;
  else if (licenseType === 'production') return productionLicense;
  else throw new Error('Invalid license type!');
}
