import { Injectable } from '@angular/core';

import { from, Observable, of } from 'rxjs';
import { flatMap, map, reduce } from 'rxjs/operators';

import { FxRatesService } from './fx-rates.service';

import { Money } from '../model/money.interface';

@Injectable({
  providedIn: 'root',
})
export class MoneyService {
  constructor(private rateService: FxRatesService) {}

  convertMoney(money: Money, targetCurrency: string): Observable<Money> {
    if (money.currency === targetCurrency) {
      return of(this.createMoney(+money.amount, targetCurrency));
    }

    if (this.shouldConvertToReferenceCurrency(money)) {
      return this.convertAmount(money, money.referenceCurrency).pipe(
        flatMap((amount) => this.convertMoney(amount, targetCurrency)),
      );
    }

    return this.convertAmount(money, targetCurrency);
  }

  getFxRates() {
    return this.rateService.getFxRatesObservable();
  }

  private convertAmount(
    money: Money,
    referenceCurrency: string,
    referenceCurrencyRate?: string,
  ): Observable<Money> {
    return this.rateService
      .convertAmount(
        money.amount.toString(),
        money.currency,
        referenceCurrency,
        referenceCurrencyRate,
      )
      .pipe(
        map((amount) => this.createMoney(+amount, referenceCurrency)), // TODO: amount should be a string
      );
  }

  sumInCurrency(values: Money[], currency: string): Observable<Money> {
    return from(values).pipe(
      flatMap((debt) => this.convertMoney(debt, currency)),
      reduce((acc: number, val: Money) => acc + val.amount, 0),
      map((sum) => this.createMoney(sum, currency)),
    );
  }

  private shouldConvertToReferenceCurrency(money: Money) {
    return money.referenceCurrency && money.currency !== money.referenceCurrency;
  }

  private createMoney(amount: number, currency: string): Money {
    return {
      amount: amount,
      currency: currency,
      referenceCurrency: currency,
    };
  }
}
