import { Inject, Injectable } from '@angular/core';

import { BroadcastChannelService } from '../broadcast-channel.service';

import { BroadcastAutowiredClient, BROADCAST_AUTOWIRED_CLIENT } from '../broadcast-channel.types';

@Injectable({
  providedIn: 'root',
})
export class BroadcastChannelInitializerService {
  constructor(
    @Inject(BROADCAST_AUTOWIRED_CLIENT) private _autowiredClients: BroadcastAutowiredClient[],
    private _broadcastChannelService: BroadcastChannelService,
  ) {}

  initialize() {
    this._broadcastChannelService.initialize();
    this._initializeAutowiredClients();
  }

  private _initializeAutowiredClients() {
    this._autowiredClients.forEach((client: BroadcastAutowiredClient) => client.autowire());
  }
}
