import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { RestResponse, encodeEndpoint, toData } from '@demica/core/core';

import { EnvironmentTransaction } from '../model/environment.interface';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentTransactionResourceService {
  constructor(private http: HttpClient) {}

  getEnvironments(transactionId: number) {
    const url = encodeEndpoint('resources/transactions/{}/environments', transactionId);
    return this.http.get<RestResponse<EnvironmentTransaction[]>>(url).pipe(map(toData));
  }

  putEnvironment(transactionId: number, environmentId: number, data: unknown) {
    const url = encodeEndpoint(
      'resources/transactions/{}/environments/{}',
      transactionId,
      environmentId,
    );
    return this.http.put<RestResponse<EnvironmentTransaction>>(url, data).pipe(map(toData));
  }
}
