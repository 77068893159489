import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { encodeParams } from '../../_commons_/helpers/encode-params/encode-params.helper';
import { encodeEndpoint } from '../../_commons_/helpers/encode-url/encode-url';
import { toData } from '../../_commons_/helpers/response-mapping/response-mapping.helper';

import { CodeAvailabilityResponse } from '../../_commons_/interface/domain/availability-response.interface';
import { EntityId } from '../../_commons_/interface/entity/entity-id.interface';
import { EntityReference } from '../../_commons_/interface/entity/entity-reference.interface';
import { DeleteResponse } from '../../_commons_/interface/rest-response/delete-response.interface';
import { PostResponse } from '../../_commons_/interface/rest-response/post-response.interface';
import { PutResponse } from '../../_commons_/interface/rest-response/put-response.interface';
import { RestResponse } from '../../_commons_/interface/rest-response/rest-response.interface';
import { CalendarBase } from '../model/calendar-base.interface';
import { CalendarSchedulerUploadResponse } from '../model/calendar-schedule-upload.interface';
import { CalendarSchedule } from '../model/calendar-schedule.interface';
import { Calendar } from '../model/calendar.interface';
import { CalendarsSearchParam } from '../model/calendars-search-param';

@Injectable({
  providedIn: 'root',
})
export class CalendarResourceService {
  constructor(private _http: HttpClient) {}

  getCalendars$(
    transactionId: number,
    entityRevision: number,
    versionPreviewMode: boolean,
    searchParams?: CalendarsSearchParam,
    sort?: string,
  ): Observable<CalendarBase[]> {
    const params = encodeParams({ sort });
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/calendars/search',
          transactionId,
          entityRevision,
        )
      : encodeEndpoint('resources/transactions/{}/calendars/search', transactionId);

    return this._http
      .post<RestResponse<CalendarBase[]>>(url, searchParams, { params })
      .pipe(map(toData));
  }

  getCalendarsDictionary$(
    transactionId: number,
    entityRevision: number,
    versionPreviewMode: boolean,
  ): Observable<EntityReference[]> {
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/calendars-dictionary',
          transactionId,
          entityRevision,
        )
      : encodeEndpoint('resources/transactions/{}/calendars-dictionary', transactionId);

    return this._http.get<RestResponse<EntityReference[]>>(url).pipe(map(toData));
  }

  getCalendar$(
    transactionId: number,
    calendarId: number,
    entityRevision: number,
    versionPreviewMode: boolean,
  ): Observable<Calendar> {
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/calendars/{}',
          transactionId,
          entityRevision,
          calendarId,
        )
      : encodeEndpoint('resources/transactions/{}/calendars/{}', transactionId, calendarId);

    return this._http.get<RestResponse<Calendar>>(url).pipe(map(toData));
  }

  createCalendar$(transactionId: number, data: Calendar): Observable<PostResponse> {
    const url = encodeEndpoint('resources/transactions/{}/calendars', transactionId);

    return this._http.post<RestResponse<PostResponse>>(url, data).pipe(map(toData));
  }

  updateCalendar$(
    transactionId: number,
    calendarId: EntityId,
    data: Calendar,
  ): Observable<PutResponse> {
    const url = encodeEndpoint('resources/transactions/{}/calendars/{}', transactionId, calendarId);

    return this._http.put<RestResponse<PutResponse>>(url, data).pipe(map(toData));
  }

  checkCalendarCodeAvailable$(
    code: string,
    transactionId: number,
    calendarId?: EntityId,
  ): Observable<CodeAvailabilityResponse> {
    const url = encodeEndpoint(
      'resources/transactions/{}/calendars-code-availability',
      transactionId,
    );
    const params = encodeParams({ code, calendarId });

    return this._http
      .get<RestResponse<CodeAvailabilityResponse>>(url, { params })
      .pipe(map(toData));
  }

  removeCalendar$(
    transactionId: number,
    calendarId: EntityId,
    entityRevision: number,
  ): Observable<DeleteResponse> {
    const url = encodeEndpoint('resources/transactions/{}/calendars/{}', transactionId, calendarId);
    const params = encodeParams({ entityRevision });

    return this._http.delete<RestResponse<DeleteResponse>>(url, { params }).pipe(map(toData));
  }

  uploadSchedulerFiles$(
    file: File,
    existingSchedules: CalendarSchedule[],
    isWorkingDay: boolean,
  ): Observable<CalendarSchedulerUploadResponse> {
    const formData = new FormData();

    formData.append('file', file, file.name);
    formData.append(
      'readCSVCalendarScheduleRequest',
      new Blob([JSON.stringify({ existingSchedules, isWorkingDay })], { type: 'application/json' }),
    );

    return this._http
      .post<RestResponse<CalendarSchedulerUploadResponse>>(
        encodeEndpoint('resources/transactions/calendars/read-schedules'),
        formData,
      )
      .pipe(map(toData));
  }
}
