<ng-container>
  <div class="form-row">
    <div
      class="decimal-row"
      *ngIf="!isPercentage"
    >
      <trf-form-decimal-row
        [name]="nameMin"
        [label]="labelMin"
        placeholder="MINIMUM_PLACEHOLDER"
        aria-describedby="decimal_notification"
        testid="amount-min-value"
      ></trf-form-decimal-row>
      <trf-form-decimal-row
        [name]="nameMax"
        [label]="labelMax"
        placeholder="MAXIMUM_PLACEHOLDER"
        aria-describedby="decimal_notification"
        testid="amount-max-value"
      ></trf-form-decimal-row>
    </div>
    <div
      class="decimal-row"
      *ngIf="isPercentage"
    >
      <trf-form-percentage-row
        [maxValue]="percentageMaxValue"
        [name]="nameMin"
        [label]="labelMin"
        placeholder="MINIMUM_PLACEHOLDER"
        aria-describedby="decimal_notification"
        [showPercentLabel]="true"
        testid="percentage-min-value"
      ></trf-form-percentage-row>
      <trf-form-percentage-row
        [maxValue]="percentageMaxValue"
        [name]="nameMax"
        [label]="labelMax"
        placeholder="MAXIMUM_PLACEHOLDER"
        aria-describedby="decimal_notification"
        [showPercentLabel]="true"
        testid="percentage-max-value"
      ></trf-form-percentage-row>
    </div>
    <ngp-contextual-notifications
      id="decimal_notification"
      [notifications]="notifications"
    ></ngp-contextual-notifications>
  </div>
</ng-container>
