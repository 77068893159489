import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Preview } from '@demica/core/core';

@Component({
  selector: 'trf-address',
  templateUrl: 'address.component.html',
  styleUrls: ['address.component.sass'],
})
export class AddressComponent {
  @Input()
  preview?: Preview;

  constructor(private translateService: TranslateService) {}

  getCountry() {
    if (this.preview.country) {
      return this.translateService.instant('COUNTRIES.' + this.preview.country.entityId);
    }
  }
}
