import { NgZone } from '@angular/core';

import { tap } from 'rxjs/operators';

import { AppConfig } from '@demica/resources/app-config';

import { AppConfigService } from '../app-config/app-config.service';
import { AuthKeycloakService } from '../auth/auth-keycloak.service';
import { AuthService } from '../auth/auth.service';
import { HybridFlowTokenStoreService } from '../auth/hybrid-flow-token-store.service';
import { LogoutRedirectInitializerService } from '../auth/logout-redirect/logout-redirect-initializer.service';
import { TokenStoreService } from '../auth/token-store.service';
import { AccessTokenExpirationTimerService } from './access-token-expiration-timer.service';
import { AuthenticationFlowStore } from './authentication-flow-store.service';

import { initializeFlow } from '../auth/initialize-flow';
import { LOGOUT_REASONS } from '../auth/logout-redirect/logout-reasons.enum';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';

export function initializeAuthentication(
  keycloak: KeycloakService,
  appConfig: AppConfigService,
  tokenStore: TokenStoreService,
  flowStore: AuthenticationFlowStore,
  authService: AuthService,
  authKeycloakService: AuthKeycloakService,
  hybridFlowTokenStoreService: HybridFlowTokenStoreService,
  ngZone: NgZone,
  logoutRedirectInitializerService: LogoutRedirectInitializerService,
  accessTokenExpirationTimerService: AccessTokenExpirationTimerService,
) {
  return () => {
    keycloak.keycloakEvents$
      .pipe(
        tap((ev) => ngZone.run(() => onAuthRefreshSuccess(ev))),
        tap((ev) => ngZone.run(() => onAuthSuccess(ev))),
      )
      .subscribe();

    accessTokenExpirationTimerService.aboutToExpire$.subscribe(() =>
      authKeycloakService.refreshToken(-1),
    );

    return appConfig.appConfigAsPromise.then((appConfigData) => {
      return initializeFlow(
        keycloak,
        appConfig,
        tokenStore,
        flowStore,
        authService,
        authKeycloakService,
        ngZone,
        hybridFlowTokenStoreService,
        kcConfig(appConfigData),
      )
        .then((result: boolean) => (result ? Promise.resolve() : Promise.reject()))
        .then(() => {
          logoutRedirectInitializerService.initialize(appConfigData);
          authKeycloakService.validateKeycloakConfiguration();
        })
        .catch((e) => {
          initFailHandler(e);
          throw e;
        });
    });
  };

  function kcConfig(appConfigData: AppConfig): KeycloakConfig {
    return {
      url: appConfigData.authorizationUrl,
      realm: 'demica',
      clientId: 'trf-client',
    };
  }

  function onAuthRefreshSuccess(e: KeycloakEvent) {
    if (e.type === KeycloakEventType.OnAuthRefreshSuccess) {
      observeAccessTokenExpiration();
    }
  }

  function onAuthSuccess(e: KeycloakEvent) {
    if (e.type === KeycloakEventType.OnAuthSuccess) {
      observeAccessTokenExpiration();
      authService.setLoggedIn();
    }
  }

  function initFailHandler(e: Error) {
    hybridFlowTokenStoreService.clearTokens();
    flowStore.clearFlow();
    console.error(e);

    alert('Application failed to start');
    document.write('<h2 style="text-align: center">Service is unavailable</h2>');
    throw new Error(`Service is unavailable`);
  }

  function observeAccessTokenExpiration() {
    // timeSkew is in seconds, and try handle difference between server time and local time + transport overhead
    const keycloakInstance = keycloak.getKeycloakInstance();
    const timeSkew = keycloakInstance.timeSkew ?? 0;
    const tokenExpiration = keycloakInstance.tokenParsed?.exp ?? 0;

    if (tokenExpiration <= 0) {
      return authService.signOut(LOGOUT_REASONS.TOKEN_NO_EXP_OR_EXP_LESS_THAN_ZERO);
    }

    accessTokenExpirationTimerService.scheduleTokenExpiration(tokenExpiration + timeSkew);
  }
}
