import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { tap } from 'rxjs/operators';

import { apiUrl } from '@demica/core/core';

import { LogoHolder } from './logo-holder.interface';

@Component({
  selector: 'trf-logo-display',
  templateUrl: 'logo-display.component.html',
  styleUrls: ['logo-display.component.sass'],
})
export class LogoDisplayComponent implements OnInit {
  @Input()
  logoHolder: LogoHolder;
  @Input()
  logoPath: string;
  @Input()
  clientName: string;

  url: SafeUrl;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.logoHolder.logoId) {
      this.http
        .get(this.getLogoUrl(), { responseType: 'blob' })
        .pipe(tap(this.saveImageAsUrl.bind(this)))
        .subscribe();
    }
  }

  saveImageAsUrl(data: Blob) {
    const objectUrl = URL.createObjectURL(data);
    this.url = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
  }

  getLogoUrl(): string {
    return (
      apiUrl +
      '/resources/' +
      this.logoPath +
      '/' +
      this.logoHolder.entityId +
      '/logos/' +
      this.logoHolder.logoId
    );
  }
}
