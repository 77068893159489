import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CurrencyCode, EmittedFormValue, EntityId, notEmpty, Range } from '@demica/core/core';

import { DEFAULT_DECIMAL_PLACES } from '../../model/point-range-defaults.consts';
import {
  createEmptyRange,
  createFirstRange,
  RangeParams,
  RangeRow,
} from '../../model/range.interface';

@Component({
  selector: 'trf-ranges',
  templateUrl: './ranges.component.html',
  styleUrls: ['./ranges.component.sass'],
})
export class RangesComponent implements OnInit {
  @Input()
  previewMode: boolean;
  @Input()
  versionPreviewMode: boolean;
  @Input()
  ranges: Range[];
  @Input()
  title = 'RANGES.TITLE';
  @Input()
  valueLabel: string = null;
  @Input()
  lowerRangeFormLabel = 'RANGES.FORM_RANGE_LOWER_RANGE';
  @Input()
  upperRangeFormLabel = 'RANGES.FORM_RANGE_UPPER_RANGE';
  @Input()
  lowerRangeTableLabel = 'RANGES.TABLE_COLUMN_LOWER_RANGE';
  @Input()
  upperRangeTableLabel = 'RANGES.TABLE_COLUMN_UPPER_RANGE';
  @Input()
  numberOfDecimalPlacesForValue = DEFAULT_DECIMAL_PLACES;
  @Input()
  numberOfDecimalPlacesForRangePoints = DEFAULT_DECIMAL_PLACES;
  @Input()
  maxValueForRangePoints: number;
  @Input()
  editRangeRequiredAuthority: string;
  @Input()
  currency: CurrencyCode;
  @Output()
  rangesChange = new EventEmitter<EmittedFormValue<Range[]>>();
  @Output()
  rangeModified = new EventEmitter<void>();
  @Output()
  rangeFormVisibilityChange = new EventEmitter<boolean>();
  @Input()
  removeTrailingZeros = false;
  @Input()
  acceptNegativeValues: boolean;
  @Input()
  validateMinMaxValue: boolean;

  rangeType: EntityId;
  rateType: EntityId;
  editedRange: Range;
  rangeFormVisible = false;
  areRangesConfigured = true;

  @Input()
  set rangeParams(rangeParams: RangeParams) {
    this.rangeType = rangeParams.rangeType;
    this.rateType = rangeParams.rateType;
  }

  ngOnInit(): void {
    this.sortRanges();
    this.rangesAreChanged();
  }

  onFormSaveButtonClick(savedRange: Range) {
    let rangeIndex = this.ranges.findIndex((range) => savedRange.lowerRange === range.lowerRange);
    if (this.rangeChanged(savedRange, rangeIndex)) {
      this.updateRange(savedRange, rangeIndex);
      rangeIndex += 1;
      if (rangeIndex === this.ranges.length) {
        this.addNewRange(createFirstRange(this.rangeType), savedRange.upperRange);
      } else if (
        rangeIndex < this.ranges.length &&
        this.compareRange(this.ranges[rangeIndex].lowerRange, savedRange.upperRange) > 0
      ) {
        this.addNewRange(
          createEmptyRange(),
          savedRange.upperRange,
          this.ranges[rangeIndex].lowerRange,
        );
      } else {
        this.removeOverlappingRanges(savedRange, rangeIndex);
      }
    } else {
      this.updateRange(savedRange, rangeIndex);
    }

    this.closeForm();
    this.rangesAreChanged();
    this.rangeModified.emit();
  }

  onFormCancelButtonClick() {
    this.closeForm();
  }

  onEditRangeButtonClick(range: Range) {
    this.editedRange = range;
    this.openForm();
  }

  private compareRange(r1: string | number, r2: string | number) {
    const range1Empty = r1 == null || r1 === '';
    const range2Empty = r2 == null || r2 === '';
    if (range1Empty || range2Empty) {
      return range1Empty && range2Empty ? 0 : range1Empty ? 1 : -1;
    }

    const range1 = Number(r1);
    const range2 = Number(r2);
    if (isNaN(range1) || isNaN(range2)) {
      throw new Error('Range is not a number');
    }
    return range1 < range2 ? -1 : range1 === range2 ? 0 : 1;
  }

  private rangeChanged(range: Range, rangeIndex: number): boolean {
    return Number(this.ranges[rangeIndex].upperRange) !== Number(range.upperRange);
  }

  private updateRange(range: Range, rangeIndex: number) {
    this.ranges[rangeIndex].upperRange = range.upperRange;
    this.ranges[rangeIndex].value = range.value;
  }

  private addNewRange(newRange: Range, lowerRange: string, upperRange?: string) {
    if (lowerRange) {
      newRange.lowerRange = lowerRange;
    }
    if (upperRange) {
      newRange.upperRange = upperRange;
    }
    this.ranges = [...this.ranges, newRange];
    this.sortRanges();
  }

  private removeOverlappingRanges(updatedRange: Range, rangeIndex: number) {
    type RangeWithRemove = Range & { toRemove: boolean };
    while (rangeIndex < this.ranges.length) {
      if (this.compareRange(updatedRange.upperRange, this.ranges[rangeIndex].upperRange) >= 0) {
        (this.ranges[rangeIndex] as RangeWithRemove).toRemove = true;
        rangeIndex += 1;
      } else {
        this.ranges[rangeIndex].lowerRange = updatedRange.upperRange;
        break;
      }
    }
    this.ranges = (this.ranges as RangeWithRemove[]).filter((range) => !range.toRemove);
  }

  private openForm() {
    this.rangeFormVisible = true;
    this.rangeFormVisibilityChange.emit(true);
  }

  private closeForm() {
    this.rangeFormVisible = false;
    this.rangeFormVisibilityChange.emit(false);
  }

  private sortRanges() {
    if (this.ranges) {
      this.ranges.sort(this.rangeSortFunction());
    }
  }

  private rangeSortFunction() {
    return (r1: Range, r2: Range) => {
      const lower1 = Number(r1.lowerRange);
      const lower2 = Number(r2.lowerRange);
      return lower1 < lower2 ? -1 : lower1 === lower2 ? 0 : 1;
    };
  }

  private validateRanges() {
    const valueEmpty = (value: string) => value == null || value === '';
    const validateRange = (range: Range) => !valueEmpty(range.value);

    this.areRangesConfigured =
      notEmpty(this.ranges) && this.ranges.every((range) => validateRange(range));
  }

  private rangesAreChanged() {
    this.validateRanges();
    this.rangesChange.emit({
      data: this.ranges.map((range) => this.rangeRowToRange(range)),
      isValid: this.areRangesConfigured,
    });
  }

  private rangeRowToRange(range: Range): Range {
    const rangeRow = range as RangeRow;
    const result = createEmptyRange();
    for (const key in result) {
      if (Object.prototype.hasOwnProperty.call(rangeRow, key)) {
        result[key as keyof Range] = rangeRow[key as keyof Range];
      }
    }
    return result;
  }
}
