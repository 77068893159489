import { MenuItemConfig, MenuItemsConfig, MenuOptionType } from '@demica/core/core';

import { administrationMenuOptionRoutePermissions } from '../routes/administration.routes-permissions';
import { alertsMenuOptionRoutePermissions } from '../routes/alerts.routes-permissions';
import { clientsMenuOptionRoutePermissions } from '../routes/clients.routes-permissions';
import { currentRegionMenuOptionRoutePermissions } from '../routes/current-region.routes-permissions';
import { dashboardMenuOptionPermissions } from '../routes/dashboard.routes-permissions';
import { dataUploadsMenuOptionRoutePermissions } from '../routes/data-uploads.routes-permissions';
import { funderGroupsMenuOptionRoutePermissions } from '../routes/funder-groups.routes-permissions';
import { insurersMenuOptionRoutePermissions } from '../routes/insurers.routes-permissions';
import { onboardingMenuOptionRoutePermissions } from '../routes/onboarding.routes-permissions';
import { technicalDataMenuOptionRoutePermissions } from '../routes/technical-data.routes-permissions';
import { templatesMenuOptionRoutePermissions } from '../routes/templates.routes-permissions';
import { transactionsMenuOptionRoutePermissions } from '../routes/transaction.routes-permissions';

export const menuConfig: MenuItemsConfig = {
  'finance-section': {
    sectionTitle: 'MENU.SECTION.FINANCE',
    type: MenuOptionType.SECTION,
    options: {
      'overview-option': {
        type: MenuOptionType.ITEM,
        permissions: dashboardMenuOptionPermissions,
        titleKey: 'MENU.DASHBOARD',
        icon: 'home',
        link: ['/dashboard'],
      },
    },
  },
  'operations-section': {
    sectionTitle: 'MENU.SECTION.OPERATIONS',
    type: MenuOptionType.SECTION,
    options: {
      'data-uploads-option': {
        type: MenuOptionType.ITEM,
        permissions: dataUploadsMenuOptionRoutePermissions,
        titleKey: 'MENU.DATA_UPLOADS',
        icon: 'upload',
        link: ['/data-uploads'],
      },
      'transaction-instances-option': {
        type: MenuOptionType.ITEM,
        permissions: technicalDataMenuOptionRoutePermissions,
        titleKey: 'MENU.TECHNICAL_DATA',
        icon: 'file-cog',
        link: ['/technical-data'],
      },
      'alerts-option': {
        type: MenuOptionType.ITEM,
        permissions: alertsMenuOptionRoutePermissions,
        titleKey: 'MENU.ALERTS',
        icon: 'bell',
        link: ['/alerts'],
      },
    },
  },
  'administration-section': {
    sectionTitle: 'MENU.SECTION.ADMINISTRATION',
    type: MenuOptionType.SECTION,
    options: {
      'clients-option': {
        type: MenuOptionType.ITEM,
        permissions: clientsMenuOptionRoutePermissions,
        titleKey: 'MENU.CLIENTS',
        icon: 'address-book',
        link: ['/clients'],
      },
      'funder-groups-option': {
        type: MenuOptionType.ITEM,
        permissions: funderGroupsMenuOptionRoutePermissions,
        titleKey: 'MENU.FUNDERS',
        icon: 'university',
        link: ['/funder-groups'],
      },
      'transactions-option': {
        type: MenuOptionType.ITEM,
        permissions: transactionsMenuOptionRoutePermissions,
        titleKey: 'MENU.TRANSACTIONS',
        icon: 'exchange-alt',
        link: ['/transactions'],
      },
      'templates-option': {
        type: MenuOptionType.ITEM,
        permissions: templatesMenuOptionRoutePermissions,
        titleKey: 'MENU.TEMPLATES',
        icon: 'puzzle-piece',
        link: ['/templates'],
      },
      'current-region-option': {
        type: MenuOptionType.ITEM,
        permissions: currentRegionMenuOptionRoutePermissions,
        titleKey: 'MENU.CURRENT_REGION',
        icon: 'globe',
        link: ['/current-region'],
      },
      'settings-option': {
        type: MenuOptionType.ITEM,
        permissions: administrationMenuOptionRoutePermissions,
        titleKey: 'MENU.SETTINGS',
        icon: 'cogs',
        link: ['/settings'],
      },
      'insurers-option': {
        type: MenuOptionType.ITEM,
        permissions: insurersMenuOptionRoutePermissions,
        titleKey: 'MENU.INSURERS',
        icon: 'expeditedssl',
        link: ['/insurers'],
      },
      'onboarding-option': {
        type: MenuOptionType.ITEM,
        permissions: onboardingMenuOptionRoutePermissions,
        titleKey: 'MENU.ONBOARDING',
        icon: 'briefcase',
        link: ['/onboarding'],
      },
    },
  },
  'help-option': {
    type: MenuOptionType.ITEM,
    permissions: {},
    titleKey: 'MENU.HELP',
    icon: 'question',
    link: ['/no-access'],
  } as MenuItemConfig,
  'sign-out-option': {
    type: MenuOptionType.ITEM,
    permissions: {},
    titleKey: 'MENU.SIGN_OUT',
    icon: 'sign-out-alt',
    link: ['/no-access'],
  } as MenuItemConfig,
};
