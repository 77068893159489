import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { TransactionResourceService } from '../_domain/transaction/_common/service/transaction-resource.service';
import { AppConfigService } from '../service/app-config/app-config.service';
import { FeatureToggleService } from '../service/feature-toggle.service';
import { UserService } from '../service/user.service';

import { Transaction } from '../_domain/transaction/_common/model/transaction.interface';
import { TRFRoute, TRFRoutes } from '../interface/route.interface';
import { User } from '../model/user';

export const authorizedSiblingGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean> => {
  const router = inject(Router);
  const userService = inject(UserService);
  const appConfigResource = inject(AppConfigService);
  const featureToggleService = inject(FeatureToggleService);
  const transactionResourceService = inject(TransactionResourceService);

  return checkIfCanActivate();

  function checkIfCanActivate() {
    return appConfigResource.appConfig$.pipe(
      // workaround for toggles missing in feature-toggle service
      switchMap(() => userService.currentUser),
      switchMap((user: User) => {
        const siblings = (route.parent.routeConfig.children as TRFRoutes).filter(
          (s) => s.path !== '',
        );

        return _firstAccessibleSibling(route, siblings, user).pipe(
          map((navigableSibling) => {
            if (navigableSibling) {
              const siblingTabRoute = _constructSiblingTabRoute(state.url, navigableSibling);
              router.navigateByUrl(siblingTabRoute);
            } else return true;

            return false;
          }),
        );
      }),
    );
  }

  function _firstAccessibleSibling(
    route: ActivatedRouteSnapshot,
    siblings: TRFRoutes,
    user: User,
  ): Observable<TRFRoute | undefined> {
    return _getTransaction(siblings, route).pipe(
      map((transaction) => {
        return siblings.find((candidateRoute) => {
          const requiredAuthority = candidateRoute.requiredAuthority;

          const authoritySatisfied = requiredAuthority
            ? user.hasAuthority(requiredAuthority)
            : true;
          const toggleSatisfied = featureToggleService.hasGroupEnabled(
            candidateRoute.requiredFeatureToggleGroup,
          );
          const allToggleSatisfied = featureToggleService.hasAllGroupsEnabled(
            candidateRoute.requiredAllFeatureToggleGroup,
          );
          const transactionTypeSatisfied = candidateRoute.requiredTransactionType
            ? transaction.type.entityId === candidateRoute.requiredTransactionType
            : true;
          const liabilityCalculationFeaturesSatisfied =
            candidateRoute.requiredLiabilityCalculationFeatures
              ? transaction.liabilityCalculationFeatures.find(
                  (value) =>
                    value.entityReference.entityId ===
                    candidateRoute.requiredLiabilityCalculationFeatures,
                ).value
              : true;

          if (
            !(
              authoritySatisfied &&
              toggleSatisfied &&
              allToggleSatisfied &&
              transactionTypeSatisfied &&
              liabilityCalculationFeaturesSatisfied
            )
          ) {
            return false;
          }

          if (!candidateRoute.children?.length) {
            return true;
          }

          return _firstAccessibleSibling(route, candidateRoute.children, user);
        });
      }),
    );
  }

  /** Construct a full route tree that includes sibling tab route segment while preserving URL parameters;
   * Warning: this method handles sibling tab routes - not true Angular sibling routes */
  function _constructSiblingTabRoute(url: string, navigableSibling: TRFRoute): UrlTree {
    const routeTree = router.parseUrl(url);
    const segments = routeTree.root.children.primary.segments;
    segments.push(new UrlSegment(navigableSibling.path, {}));
    return routeTree;
  }

  function _getTransaction(
    siblings: TRFRoute[],
    route: ActivatedRouteSnapshot,
  ): Observable<Transaction | null> {
    const needsTransaction = siblings.some(
      (sibling) =>
        !!sibling.requiredTransactionType || !!sibling.requiredLiabilityCalculationFeatures,
    );
    if (!needsTransaction) {
      return of(null);
    }

    const isInTransactionContext = _isInTransactionContext(route);
    if (!isInTransactionContext) {
      return of(null);
    }

    const transactionId = _findTransactionId(route);
    if (!transactionId) {
      return of(null);
    }

    return transactionResourceService.getTransaction(transactionId);
  }

  function _isInTransactionContext(route: ActivatedRouteSnapshot): boolean {
    const paths = route.pathFromRoot.flatMap((pathItem) => pathItem.url.map((url) => url.path));

    return paths.includes('transaction-preview');
  }

  function _findTransactionId(route: ActivatedRouteSnapshot): number | null {
    if (route.params['id']) {
      return route.params['id'];
    }

    if (route.parent) {
      return _findTransactionId(route.parent);
    }

    return null;
  }
};
