import * as _ from 'lodash';

export type SortFn<T = any> = (a: T, b: T) => -1 | 0 | 1;

/**
 * @deprecated use Array.some instead and other ES6 APIs instead
 * @param collection
 * @param predicate
 */
export function any<T>(collection: T[], predicate: (elem: T) => boolean): boolean {
  const result = false;

  for (const element of collection) {
    const isSatisfied = predicate(element);

    if (isSatisfied) return true;
  }

  return result;
}

export function intersection<T>(collection1: T[], collection2: T[]) {
  return collection1.filter((value) => collection2.includes(value));
}

/**
 * Check if two collections contain exactly the same items.
 * Note that this function doesn't do deep object comparison; nested (complex) objects are compared by reference!
 * @param collection1
 * @param collection2
 */
export function hasSameContents<T>(collection1: T[], collection2: T[]): boolean {
  return (
    collection1.length === collection2.length &&
    intersection(collection1, collection2).length === collection1.length
  );
}

/** move array element from one index to another */
export function moveElement<T>(arr: T[], fromIndex: number, toIndex: number): void {
  arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
}

// TODO: TRFV2-3891 Refactor to proper types from "any"
/** nested comparator */
// eslint-disable-next-line
export function multiComparator(...comparators: Function[]): SortFn {
  // eslint-disable-next-line
  return (a: any, b: any) => {
    for (const f of comparators) {
      const result = f(a, b);
      if (result !== 0) {
        return result;
      }
    }
  };
}

export function byName(): SortFn {
  return byProperty('name');
}

export function byKey(): SortFn {
  return byProperty('key');
}

export function byProperty(propertyName: string): SortFn {
  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line
  return (a: any, b: any) => {
    if (a[propertyName] == null && b[propertyName] == null) return 0;

    if (a[propertyName] == null) return 1;

    if (b[propertyName] == null) return -1;

    if (a[propertyName] > b[propertyName]) return 1;

    if (a[propertyName] < b[propertyName]) return -1;
    return 0;
  };
}
export function byMappedProperty<T>(
  propertyName: keyof T | string,
  mapper: (prop: unknown) => number,
): SortFn<T> {
  return (a: T, b: T) => {
    if (a[propertyName as keyof T] == null && b[propertyName as keyof T] == null) return 0;

    if (a[propertyName as keyof T] == null) return 1;

    if (b[propertyName as keyof T] == null) return -1;

    if (mapper(a[propertyName as keyof T]) > mapper(b[propertyName as keyof T])) return 1;

    if (mapper(a[propertyName as keyof T]) < mapper(b[propertyName as keyof T])) return -1;
    return 0;
  };
}
export function byPropertyWithExclusion<T extends object, K extends (keyof T & string) | string>(
  propertyName: K,
  exclusionProperty: K,
  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line
  excluded: any[],
): SortFn<T> {
  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line
  return (a: T, b: T) => {
    if (excluded.includes(b[exclusionProperty as keyof T])) return -1;

    if (excluded.includes(a[exclusionProperty as keyof T])) return 1;

    return byProperty(propertyName as keyof T & string)(a, b);
  };
}

export function byNumericProperty<T>(propertyName: keyof T): SortFn {
  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line
  return (a: any, b: any) => {
    if (a[propertyName] == null && b[propertyName] == null) return 0;

    if (a[propertyName] == null) return 1;

    if (b[propertyName] == null) return -1;

    if (parseFloat(a[propertyName]) > parseFloat(b[propertyName])) return 1;

    if (parseFloat(a[propertyName]) < parseFloat(b[propertyName])) return -1;

    return 0;
  };
}

export function byObjectProperty(objectName: string, propertyName: string): SortFn {
  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line
  return (a: any, b: any) => {
    const aValue = a[objectName] && a[objectName][propertyName];
    const bValue = b[objectName] && b[objectName][propertyName];

    if (aValue == null && bValue == null) return 0;

    if (aValue > bValue || aValue == null) return 1;

    if (aValue < bValue || bValue == null) return -1;

    return 0;
  };
}

export function byStringPropertyCaseInsensitive(propertyName: string): SortFn {
  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line
  return (a: any, b: any) => {
    if (a[propertyName] == null && b[propertyName] == null) return 0;

    if (a[propertyName] == null) return 1;

    if (b[propertyName] == null) return -1;

    if (a[propertyName].toLowerCase() > b[propertyName].toLowerCase()) return 1;

    if (a[propertyName].toLowerCase() < b[propertyName].toLowerCase()) return -1;

    return 0;
  };
}

export function byStringObjectPropertyCaseInsensitive(
  objectName: string,
  propertyName: string,
): SortFn {
  // TODO: TRFV2-3891 Refactor to proper types from "any"
  // eslint-disable-next-line
  return (a: any, b: any) => {
    const aValue =
      a[objectName] && a[objectName][propertyName] && a[objectName][propertyName].toLowerCase();
    const bValue =
      b[objectName] && b[objectName][propertyName] && b[objectName][propertyName].toLowerCase();

    if (aValue == null && bValue == null) return 0;

    if (aValue > bValue || aValue == null) return 1;

    if (aValue < bValue || bValue == null) return -1;

    return 0;
  };
}

export function byNaturalOrder(a: number, b: number) {
  return a - b;
}

export function reverseComparator<T>(f: (a: T, b: T) => number) {
  return reversedComparator;

  function reversedComparator(a: T, b: T): number {
    return f(a, b) * -1;
  }
}

export function removeElement<T>(array: T[], predicate: (elem: T) => boolean) {
  const elementToRemove = array.find((elem) => predicate(elem));
  const indexOfElementToRemove = array.indexOf(elementToRemove);
  if (indexOfElementToRemove > -1) {
    array.splice(indexOfElementToRemove, 1);
    return indexOfElementToRemove;
  }
  return -1;
}

export function addElementAtIndex<T>(array: T[], index: number, element: T) {
  array.splice(index, 0, element);
}

export function removeElementAtIndex<T>(array: T[], index: number) {
  array.splice(index, 1);
}

export function replaceElement<T>(
  array: T[],
  searchPredicate: (elem: T) => boolean,
  newElement: T,
) {
  const index = removeElement(array, searchPredicate);

  array.splice(index, 0, newElement);
}

export function hasIndex(index: number) {
  return index !== undefined && index !== -1;
}

export function notEmpty(obj: unknown[]) {
  return obj != null && obj.length;
}

export function groupBy<T>(objectArray: T[], property: keyof T) {
  return objectArray.reduce((acc, obj) => {
    const valueAsKey = String(obj[property]);
    if (!acc[valueAsKey]) {
      acc[valueAsKey] = [];
    }
    acc[valueAsKey].push(obj);
    return acc;
  }, {} as Record<string, T[]>);
}

/**
 * Collection group by key defined by function to map class
 * @param list Items collection to process
 * @param keyFn Function which define a way of getting key from item
 * @param excluded Specific item from collection which will be ignored
 * @example
 * list = [{id:1, name: 'foo'},{id:2, name: 'boo'},{id:3, name: 'foo'},{id:4, name: 'boo'}]
 * keyFn = (item) => item.name
 * excluded {id:3, name: 'foo'}
 * map = groupByWithExlude(list, keyFn, excluded) // -> {'foo': [{id:1, name: 'foo'}], 'boo': [{id:2, name: 'boo'}, {id:4, name: 'boo'}]}
 */
export function groupByWithExclude<K, T>(
  list: T[],
  keyFn: (item: T) => K,
  excluded: T,
): Map<K, T[]> {
  const map = new Map();
  list
    .filter((item) => item !== excluded)
    .forEach((item) => {
      const key = keyFn(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
  return map;
}

export function groupByFlat<T>(objectArray: T[], property: keyof T): Array<T> {
  const groupedArrays: Array<Array<T>> = Object.values(groupBy<T>(objectArray, property));
  return groupedArrays.reduce((prev, current) => [...prev, ...current], []);
}

export function onlyFirstOccurence<K>(value: K, index: number, array: K[]) {
  return array.indexOf(value) === index;
}

export function removeElements<T>(array: T[], predicate: (item: T) => boolean) {
  _.remove(array, predicate);
}
export function removeDuplicate<T>(array: T[], identity: (obj: T) => unknown = _.identity) {
  return _.uniqBy(array, identity);
}
export const uniq = _.uniq;
