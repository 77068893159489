import { UntypedFormGroup } from '@angular/forms';

import { objectMap } from '@demica/core/core';

import { flattenFormGroups } from './dynamic-form-utils';
import { ControlType, Field, FieldBuilder } from './field';

export interface FieldBuildersMap {
  [x: string]: FieldBuilder;
}

export interface FieldMap {
  [x: string]: Field;
}

/** Take a map of fieldName:string -> FieldBuilder and create an angular form definition (extended with metadata) */
export function buildFormDefinition(fieldBuildersMap: FieldBuildersMap): FieldMap {
  const toFieldDefinition = (fieldName: string) => ({
    [fieldName]: fieldBuildersMap[fieldName].build(),
  });

  return objectMap<FieldMap>(fieldBuildersMap, toFieldDefinition);
}

/**
 * Retrieve a form value given a FormGroup and FieldMap (form definition).
 * The returned object is adjusted in case of empty select inputs (returns null instead of 'undefined').
 * Fields from dynamic form controls are automatically flattened.
 */
export function getFormValue(form: UntypedFormGroup, formDefinition: FieldMap) {
  const formValue = form.value;

  const isEmptySelect = (fieldName: string) =>
    formDefinition[fieldName].metadata.controlType === ControlType.SELECT &&
    formValue[fieldName] == null;

  const isDynamic = (fieldName: string) => formDefinition[fieldName].metadata.dynamic;

  const toFormValue = (fieldName: string) => {
    if (isDynamic(fieldName)) return flattenFormGroups(formValue[fieldName + 'Group']);
    else if (isEmptySelect(fieldName)) return null;

    return { [fieldName]: formValue[fieldName] };
  };

  return objectMap(formDefinition, toFormValue);
}

/**
 * @deprecated use typed form with explicit mapping instead
 *
 * Patch a from given a form definition and input value.
 * Properties attached to select inputs that are null/undefined are automatically cast to null',-
 * so that empty selects conform to cross-browser DOM api (IE11 included)
 */
export function patchFormValue(
  form: UntypedFormGroup,
  formDefinition: FieldMap,
  val: Record<keyof FieldMap, unknown>,
) {
  const isEmptySelect = (fieldName: keyof FieldMap) =>
    formDefinition[fieldName].metadata.controlType === ControlType.SELECT && val[fieldName] == null;

  const toFormValue = (fieldName: string) => ({
    [fieldName]: isEmptySelect(fieldName) ? null : val[fieldName],
  });

  const newVal = objectMap(formDefinition, toFormValue);
  form.patchValue(newVal);
}
