import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Alias,
  byStringObjectPropertyCaseInsensitive,
  byStringPropertyCaseInsensitive,
  ComponentConfiguration,
  DataSorterUtils,
  EntityId,
  EntityReference,
  errorNotification,
  maxPredefinedColumnLength,
  maxUserDefinedColumnLength,
  MetaData,
  Notifications,
  Order,
  orNull,
  Sorter,
  SortParams,
  TransactionDataSource,
  TypedChanges,
} from '@demica/core/core';

import { TextTableHeaderComponent } from '../../../data-table/text-table-header.component';
import { ListAliasMappingTableRowComponent } from '../list-alias-mapping-table-row/list-alias-mapping-table-row.component';

import {
  createUniqueValueByPropertyIgnoringCaseValidator,
  validateNotEmpty,
} from '../../../../forms/validators';
import { inactiveAnalysisGroups } from '../../../../model/alias/inactive-analysis-groups-constants';
import { AliasesValidator } from '../../../../validation/analysis-code-form-validations';
import { Actions } from '../../../actions/model/actions.interface';
import { ColumnDefinition } from '../../../data-table/column-definition.interface';
import { DataSource } from '../../../data-table/data-source.interface';

export type AliasMappingsForm = FormGroup<{
  environment: FormControl<EntityReference>;
  dataSource: FormControl<TransactionDataSource>;
  aliasValue: FormControl<string>;
}>;

@Component({
  selector: 'trf-list-alias-mappings',
  templateUrl: './list-alias-mappings.component.html',
  styleUrls: ['./list-alias-mappings.component.sass'],
})
export class ListAliasMappingsComponent implements OnInit, OnChanges {
  @Input()
  environments: EntityReference[];
  @Input()
  transactionDataSource: TransactionDataSource[];
  @Input()
  aliasMappings: Alias[];
  @Input()
  metaData: MetaData;
  @Input()
  columnTypeId: EntityId;
  @Input()
  previewMode: boolean;

  @Output()
  deleteChanged = new EventEmitter<Alias>();
  @Output()
  addChanged = new EventEmitter<Alias>();
  @Output()
  environmentChanged = new EventEmitter<EntityReference>();

  dataSource: DataSource<Alias[]> = {
    data: new BehaviorSubject<Alias[]>([]),
  };

  rowActions: Actions = {
    delete: {
      titleKey: 'ALIASES_MAPPING.TABLE.ACTIONS.DELETE',
      handler: this.onDeleteAction.bind(this),
      hidden: () => this.previewMode,
      icon: 'trash-alt',
      testId: 'action-delete-alias',
    },
  };

  headerConfig: ComponentConfiguration;
  rowConfig: ComponentConfiguration;

  notificationsTop = new Notifications();
  notificationsBottom = new Notifications();
  submitted = false;
  noDataSourceSelected = true;
  maxLength = maxUserDefinedColumnLength;

  form: AliasMappingsForm = this._fb.group({
    environment: [null],
    dataSource: [null as TransactionDataSource, [validateNotEmpty]],
    aliasValue: [
      null as string,
      [
        validateNotEmpty,
        createUniqueValueByPropertyIgnoringCaseValidator<Alias>(
          () => this._getAliasesToCompare(),
          'value',
        ),
      ],
    ],
  });

  aliasValidations = AliasesValidator.aliasValueUserDefined(this.form, () => this.submitted);

  private _initialSort: SortParams = {
    property: 'dataSourceId',
    order: Order.ASC,
    comparator: byStringObjectPropertyCaseInsensitive('dataSourceId', 'name'),
  };
  private _sorter: Sorter<Alias>;
  private _destroyed$ = new Subject<void>();

  constructor(private _fb: FormBuilder) {
    this._sorter = new DataSorterUtils<Alias>(this._initialSort);
    this.notificationsTop.add({ type: 'warning', key: 'ALIASES_MAPPING.MAPPINGS_CHANGES' });
  }

  ngOnChanges(changes: TypedChanges<ListAliasMappingsComponent>): void {
    if (changes.aliasMappings) {
      this._sorter.sortData(changes.aliasMappings.currentValue);
      this.form.controls.aliasValue.updateValueAndValidity();
    }
    if (
      changes.transactionDataSource &&
      changes.transactionDataSource.currentValue !== changes.transactionDataSource.previousValue
    ) {
      this._setFirstEnvironmentFromTheList();
    }
    if (changes.columnTypeId) {
      const isPredefinedAnalysisGroup = inactiveAnalysisGroups.includes(
        changes.columnTypeId.currentValue as number,
      );
      this.aliasValidations = isPredefinedAnalysisGroup
        ? AliasesValidator.aliasValuePredefined(this.form, () => this.submitted)
        : AliasesValidator.aliasValueUserDefined(this.form, () => this.submitted);
      this.maxLength = isPredefinedAnalysisGroup
        ? maxPredefinedColumnLength
        : maxUserDefinedColumnLength;
    }
  }

  ngOnInit(): void {
    this.notificationsBottom.add(
      errorNotification(
        'NOTIFICATION.ERROR_CODE.ANALYSIS_CODE_ALIAS_VALUE_USED_BY_OTHER_ANALYSIS_CODE',
      ),
    );

    this.headerConfig = this._defineTableHeader();
    this.rowConfig = this._defineTableRows();

    this.form.controls.environment.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((selectedEnvironment) => {
        this.environmentChanged.emit(selectedEnvironment);
      });

    this.form.controls.dataSource.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((selectedDataSource) => {
        this.noDataSourceSelected = !selectedDataSource;
        this.form.controls.aliasValue.setValue(null);
      });

    this._sorter
      .getResultSorterObservable()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((sorted) => {
        this.dataSource.data.next(sorted);
      });
  }

  canAddMapping(): boolean {
    return (
      this.form.valid &&
      !!this.form.controls.environment.value &&
      !!this.form.controls.aliasValue.value
    );
  }

  onAddAliasMapping(): void {
    if (!this.canAddMapping()) {
      return;
    }
    const dataSource: TransactionDataSource = this.form.controls.dataSource.value;
    const aliasValue = this.form.controls.aliasValue.value;
    const environment = this.form.controls.environment.value;
    this.addChanged.emit({
      dataSourceId: dataSource,
      value: aliasValue,
      environmentId: environment.entityId,
    } as Alias);
    this.form.controls.dataSource.setValue(null);
    this.form.controls.aliasValue.setValue(null);
  }

  onDeleteAction(aliasMapping: Alias): void {
    this.deleteChanged.emit(aliasMapping);
    this.metaData = null;
  }

  private _configureTableColumns(): ColumnDefinition[] {
    return [
      {
        nameKey: 'ALIASES_MAPPING.TABLE.COLUMN_DATA_SOURCE',
        property: 'dataSourceId',
        comparator: byStringObjectPropertyCaseInsensitive('dataSourceId', 'name'),
        position: 1,
      },
      {
        nameKey: 'ALIASES_MAPPING.TABLE.COLUMN_ALIAS_VALUE',
        property: 'value',
        comparator: byStringPropertyCaseInsensitive('value'),
        position: 2,
      },
      {
        nameKey: 'ALIASES_MAPPING.TABLE.COLUMN_ACTIONS',
        classes: 'actions',
        property: 'actions',
        position: 3,
      },
    ];
  }

  private _defineTableHeader(): ComponentConfiguration {
    return {
      component: TextTableHeaderComponent,
      inputs: {
        allSelected: false,
        columns: this._configureTableColumns(),
        initialSort: this._initialSort,
      },
      outputs: {
        sortChanged: (sp: SortParams) => this._sorter.sortDataByParams(sp, this.aliasMappings),
      },
    };
  }

  private _defineTableRows(): ComponentConfiguration {
    return {
      component: ListAliasMappingTableRowComponent,
      inputs: {
        actions: this.rowActions,
      },
    };
  }

  private _setFirstEnvironmentFromTheList(): void {
    this.form.controls.environment.setValue(this.environments[0]);
    this.environmentChanged.emit(this.environments[0]);
  }

  private _getAliasesToCompare(): Alias[] {
    const selectedDataSource: TransactionDataSource = orNull(
      () => this.form.controls.dataSource.value,
    );
    return this.aliasMappings?.filter(
      (alias) =>
        !!selectedDataSource &&
        (alias.dataSourceId as TransactionDataSource).entityId === selectedDataSource.entityId,
    );
  }
}
