import { MAX_DECIMAL_PLACES_INT, MAX_INTEGER_PLACES_INT } from '../../../forms/number-validators';
import { NumberRegexBuilder } from '../../form-number-row/regex/number-regex.builder';

export class DecimalRegexBuilder extends NumberRegexBuilder {
  private integerPlacesInt = 100;
  private decimalPlacesInt = 100;

  setIntegerPlacesInt(value: number) {
    this.integerPlacesInt = Math.min(value, MAX_INTEGER_PLACES_INT);
    return this;
  }

  setDecimalPlacesInt(value: number) {
    this.decimalPlacesInt = Math.min(value, MAX_DECIMAL_PLACES_INT);
    return this;
  }

  build(): RegExp {
    const regexpStr =
      '^' +
      (this.acceptNegatives ? '(?:-|$)?' : '') +
      '(?:\\d{0,' +
      this.integerPlacesInt +
      '}|$)((?:\\.|\\,|$)(?:\\d{0,' +
      this.decimalPlacesInt +
      '}|$))?$';

    return new RegExp(regexpStr);
  }
}
