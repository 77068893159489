import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { LoggerService } from '@demica/logger';

import { BroadcastChannelService } from '../broadcast-channel.service';

import { BroadcastMessage, BroadcastMessageType } from '../../../model/broadcast-message.interface';
import { BroadcastAutowiredClient } from '../broadcast-channel.types';

type HelloMessage = null;

@Injectable({
  providedIn: 'root',
})
export class HelloBroadcastClientService implements BroadcastAutowiredClient {
  // client name
  readonly name = 'HELLO';
  // hello events stream
  messages$: Observable<BroadcastMessage<HelloMessage>>;
  private _messages$ = new Subject<BroadcastMessage<HelloMessage>>();

  constructor(
    private _broadcastChannelService: BroadcastChannelService,
    private _logger: LoggerService,
  ) {
    this.messages$ = this._messages$.asObservable();
  }

  autowire(): void {
    this._logger.addConsoleLog('Autowiring HelloBroadcastClientService');
    this._broadcastHello();
    this._initHelloBroadcastMessageListener();
  }

  private _broadcastHello(): void {
    this._broadcastChannelService.broadcastMessage(this._getHelloMessage());
  }

  private _initHelloBroadcastMessageListener(): void {
    this._broadcastChannelService
      .getMessagesOfType$<BroadcastMessage<HelloMessage>>(BroadcastMessageType.Hello)
      .subscribe(this._messages$);
  }

  private _getHelloMessage(): BroadcastMessage {
    return this._broadcastChannelService.generateMessage(BroadcastMessageType.Hello);
  }
}
