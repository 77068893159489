<ng-container [formGroup]="group">
  <div>
    <div
      class="column-label"
      [ngClass]="{ 'row-disabled': group.get(name).disabled }"
    >
      <label
        class="{{ labelClasses }}"
        for="{{ name }}"
        >{{ label | translate }}</label
      >
    </div>

    <div class="column-input">
      <textarea
        id="{{ name }}"
        class="form-control"
        rows="5"
        formControlName="{{ name }}"
        [attr.aria-required]="labelClasses?.includes('required')"
        (blur)="onBlur()"
        [attr.aria-describedby]="name + '_notification'"
      ></textarea>

      <div class="validation-error">
        <ngp-inline-notification
          [id]="name + '_notification'"
          [message]="validations | firstError: name"
        >
        </ngp-inline-notification>
      </div>
    </div>
  </div>
</ng-container>
