<div class="dates-row">
  <trf-form-datepicker-row
    [label]="labelDateFrom"
    [control]="fromControl"
    [validations]="fromValidationMsg"
    [disabled]="disabled"
    [attr.data-testid]="dateFromTestIdName"
  ></trf-form-datepicker-row>
  <trf-form-datepicker-row
    [label]="labelDateTo"
    [control]="toControl"
    [validations]="toValidationMsg"
    [disabled]="disabled"
    [updateSlideInContext]="false"
    [attr.data-testid]="dateToTestIdName"
  ></trf-form-datepicker-row>
</div>
