import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';

import { generateUUID, getFormControlName } from '@demica/core/core';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';

@Component({
  selector: 'trf-form-date-time-picker-row',
  templateUrl: 'form-date-time-picker-row.component.html',
  styleUrls: ['./form-date-time-picker-row.component.sass'],
})
export class FormDateTimePickerRowComponent implements OnInit {
  @Input()
  control?: FormControl<Date | string>;
  @Input()
  disabled?: boolean;
  @Input()
  label?: string;
  @Input()
  placeholder?: string;
  @Input()
  labelClasses?: string;
  @Input()
  validations?: ValidationMessage[];
  @Input()
  group: UntypedFormGroup;
  uniqueId: string;
  @Input()
  name = 'dummyInputName';

  formControlName: string;

  constructor() {
    this.uniqueId = generateUUID();
  }

  ngOnInit(): void {
    this._createFormControl();
  }

  private _createFormControl(): void {
    this.formControlName = getFormControlName(this.control);
  }
}
