<body class="skin-blue sidebar-mini">
  <div
    class="wrapper"
    [class.sidebar-collapse]="sidebarClosed"
  >
    <trf-menu-aside [menuOptions]="menuOptions"></trf-menu-aside>
    <trf-header (arrowClicked)="onArrowClicked()"></trf-header>
    <div class="content-wrapper">
      <section class="content">
        <router-outlet></router-outlet>
      </section>
    </div>
  </div>
</body>
