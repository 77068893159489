import { EntityId, HasEntityId } from '../../../interface/has-entity-id.interface';
import { FundingInstrumentFunder } from './funding-instrument.interface';
import { FundingReserve } from './funding-reserve.interface';

export interface FundingProgramme {
  entityId: string;
  assetBase: HasEntityId;
  name: string;
  code: string;
  instruments?: Instrument[];
}

export interface Instrument extends HasColorId {
  id: string;
  parentInstrumentId: string;
  assetBaseId: EntityId;
  transactionEntityRevision: number;
  name: string;
  amortisation: HasEntityId;
  legalMaturity: string;
  expectedMaturity: string;
  currency: HasEntityId;
  minimumIssueValue: number | string;
  minimumIssueValueType: HasEntityId;
  maximumIssueValue: number | string;
  maximumIssueValueType: HasEntityId;
  listed: boolean;
  cleared: boolean;
  rated: boolean;
  rating: HasEntityId;
  agency: HasEntityId;
  funders?: FundingInstrumentFunder[];
  reserves?: FundingReserve[];
}

export interface HasColorId {
  colorId?: number;
}

export enum FundingIssueValueType {
  ABSOLUTE = 1,
  PERCENT_OF_NERB = 2,
}
