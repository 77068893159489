<trf-modal-container
  [closable]="false"
  headerText="CONFIRMATION_MODAL.TITLE"
  testid="confirmation-modal"
  trfSlideinFormChanges
>
  <div class="trf-section-content my-4">
    <span>{{ messageKey | translate: messageVariables }}</span>
  </div>

  <div class="trf-modal-controls-divider py-3 trf-section-buttons space-between">
    <ngp-button
      classes="tertiary"
      [key]="cancelNameKey"
      (click)="onClose()"
      testid="confirmation-modal-cancel"
    ></ngp-button>

    <ngp-button
      classes="primary"
      [key]="actionNameKey"
      (click)="invokeAction()"
      testid="confirmation-modal-action"
    ></ngp-button>
  </div>
</trf-modal-container>
