<ng-container [formGroup]="group">
  <div
    class="column-row"
    [ngClass]="{ 'row-disabled': group.get(formControlName).disabled || disabled }"
  >
    <div
      *ngIf="!!label"
      class="column-label"
    >
      <label
        [attr.aria-label]="label | translate"
        [attr.for]="formControlName"
        [attr.class]="labelClasses"
      >
        {{ label | translate }}
      </label>
      <ngp-tooltip-icon
        *ngIf="showHelperToolTip()"
        [tooltipKey]="helperToolTip"
      ></ngp-tooltip-icon>
    </div>
    <div class="column-input">
      <div class="column-input-wrapper">
        <div class="input-wrapper">
          <ng-select
            #select
            class="form-control"
            role="combobox"
            [attr.tabindex]="group.get(formControlName).disabled || disabled ? -1 : 0"
            [attr.aria-expanded]="select.isOpen"
            [attr.aria-label]="label ? label : defaultLabel"
            [attr.aria-describedby]="formControlName + '_notification'"
            [formControlName]="formControlName"
            [items]="options"
            [closeOnSelect]="closeOnSelect"
            [placeholder]="defaultLabel | translate"
            [searchable]="searchable"
            [searchFn]="searchFn"
            [multiple]="true"
            [hideSelected]="options?.length"
            [tabIndex]="-1"
            [clearable]="clearable"
            [clearOnBackspace]="false"
            [groupBy]="groupByKey"
            [labelForId]="formControlName"
            [notFoundText]="'NG_SELECT.NOT_FOUND' | translate"
            [bindValue]="selectConfig.bindValue"
            [compareWith]="selectConfig.compareFn"
            [attr.aria-required]="labelClasses?.includes('required')"
            (close)="select.element.focus()"
            (keydown.delete)="select.clearModel()"
            (add)="onAddItem()"
            (open)="markAsDirty()"
          >
            <ng-container *ngIf="selectAll">
              <ng-template ng-header-tmp>
                <div
                  class="select-all-item"
                  (click)="selectAllItems()"
                  data-testid="multiselect-select-all"
                  >{{ "NG_SELECT.SELECT_ALL" | translate }}</div
                >
              </ng-template>
            </ng-container>

            <ng-template
              ng-label-tmp
              let-item="item"
              let-clear="clear"
            >
              <span
                #itemLabel
                class="ng-value-label"
                [attr.title]="item | optionName: translationPrefix"
                data-testid="select-value-text"
              >
                {{ item | optionName: translationPrefix | translate }}
              </span>
              <span
                class="ng-value-icon"
                tabindex="0"
                role="button"
                [attr.aria-label]="
                  'FILTER_DASHBOARD.REMOVE' | translate: { content: itemLabel.innerText }
                "
                (keydown.enter)="clear(item)"
                (click)="clear(item)"
              >
                <ngp-svg-icon iconName="times"></ngp-svg-icon>
              </span>
            </ng-template>

            <ng-template
              ng-option-tmp
              let-item="item"
              let-search="searchTerm"
              let-index="index"
            >
              <span
                [attr.title]="item | optionName: translationPrefix"
                data-testid="select-option-text"
              >
                {{ item | optionName: translationPrefix | translate }}
              </span>
            </ng-template>
          </ng-select>
        </div>

        <div class="input-addon">
          <ng-content></ng-content>
        </div>
      </div>

      <div class="validation-error">
        <ngp-inline-notification
          [id]="formControlName + '_notification'"
          [message]="validations | firstError: (fieldPath ? fieldPath : formControlName)"
        >
        </ngp-inline-notification>
      </div>
    </div>
  </div>
</ng-container>
