import { HttpContextToken } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

export const KEEP_ALIVE_SESSION_HTTP_CONTEXT_TOKEN = new HttpContextToken<boolean>(() => false);
export const KEEP_ALIVE_SESSION_EVENTS_SOURCE = new InjectionToken('Keep alive events producer');

export enum KeepAliveEventType {
  UserActivity = 'UserActivity',
  IntentionalPing = 'IntentionalPing',
  OtherInstanceActivity = 'OtherInstanceActivity',
}

export interface KeepAliveEvent {
  broadcast: boolean;
  type: KeepAliveEventType;
}

export interface KeepAliveEventsSource {
  events$: Observable<KeepAliveEvent>;
}
