import { Injectable } from '@angular/core';

import { PasswordChangeService } from '../../model/auth/password-change-service.interface';

import { getLocationOrigin } from '../../utils/window';
import { KeycloakService } from 'keycloak-angular';

@Injectable({ providedIn: 'root' })
export class OidcPasswordChangeService implements PasswordChangeService {
  constructor(private kc: KeycloakService) {}

  changePassword(): void {
    this.kc.login({ action: 'UPDATE_PASSWORD', redirectUri: getLocationOrigin() });
  }
}
