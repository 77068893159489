import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { CurrenciesDecimalPlacesService, TypedChanges } from '@demica/core/core';

import { FormDecimalRowComponent } from '../form-decimal-row/form-decimal-row.component';

import { DecimalValuePipe } from '../../pipe/decimal-value.pipe';

import { DecimalFormatterDirective } from '../../directive/decimal-formatter.directive';

@Component({
  selector: 'trf-currency-amount-row',
  templateUrl: 'form-currency-amount-row.component.html',
  styleUrls: ['./form-currency-amount-row.component.sass'],
  providers: [DecimalValuePipe, DecimalFormatterDirective],
})
export class FormCurrencyAmountRowComponent
  extends FormDecimalRowComponent
  implements OnInit, OnChanges
{
  @Input()
  currency: string;

  constructor(
    private currenciesFormat: CurrenciesDecimalPlacesService,
    protected fgd: FormGroupDirective,
  ) {
    super(fgd);
  }

  ngOnChanges(changes: TypedChanges<FormCurrencyAmountRowComponent>): void {
    if (changes.currency) {
      if (this.formControl?.value && this.formControl?.untouched) {
        this.formControl.markAsTouched();
      }
      this._onChangeDecimalPlaces(changes.currency.currentValue);
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  private _onChangeDecimalPlaces(currency: string): void {
    this.setUpDecimalPlaces(this.currenciesFormat.currencyDecimalPlaces(currency));
  }
}
