import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormControl, AbstractControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  errorNotification,
  getFormControlName,
  NotificationEntry,
  Notifications,
} from '@demica/core/core';

import { ngbDateMsg } from '../../forms/validation-messages/common-validation-messages';
import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';

const incorrectDateValidationError = errorNotification('VALIDATION.DATE_CORRECT');
const missingDateError = errorNotification('VALIDATION.PROVIDE_AT_LEAST_ONE_DATE');

const dateFromGreaterError = (key: string) => errorNotification(key);
const missingFromError = (key: string) => errorNotification(key);
const missingToError = (key: string) => errorNotification(key);

interface DoubleDates {
  dateFrom: Date;
  dateTo: Date;
}

@Component({
  selector: 'trf-double-date-picker',
  templateUrl: './double-date-picker.component.html',
  styleUrls: ['./double-date-picker.component.sass'],
})
export class DoubleDatePickerComponent implements OnInit, OnDestroy {
  @Input()
  labelDateFrom: string;

  @Input()
  labelDateTo: string;

  @Input()
  clearSubject: Subject<void>;

  @Input()
  dateFromKey: string;

  @Input()
  dateFromControl: FormControl;

  @Input()
  dateToKey: string;

  @Input()
  dateToControl: FormControl;

  @Input()
  disabled? = false;

  @Input()
  dateFromTestIdName? = 'date-from';

  @Input()
  dateToTestIdName? = 'date-to';

  @Input()
  requireAtLeastOneDate = false;

  @Input()
  bothRequired = false;

  @Input()
  requiredFromMsgErrorKey = 'VALIDATION.PROVIDE_FROM_DATE';

  @Input()
  requiredToMsgErrorKey = 'VALIDATION.PROVIDE_TO_DATE';

  @Input()
  dateRangeMsgErrorKey = 'DASHBOARD_RECEIVABLES.DATE_VALIDATION_ERROR_MESSAGE';

  group: FormGroup;
  dates: DoubleDates = {
    dateFrom: null,
    dateTo: null,
  };

  dateFromTargetControl: AbstractControl;
  dateToTargetControl: AbstractControl;
  dateFromControlName: string;
  dateToControlName: string;

  calendarScheduleStartDateValidations: ValidationMessage[];
  calendarScheduleEndDateValidations: ValidationMessage[];

  notifications = new Notifications();

  private destroySubject = new Subject<void>();

  constructor(private fgd: FormGroupDirective) {}

  ngOnInit(): void {
    this.createFormControls();

    this.calendarScheduleStartDateValidations = this.createDateValidationMessages();
    this.calendarScheduleEndDateValidations = this.createDateValidationMessages();

    this.clearSubject?.pipe(takeUntil(this.destroySubject)).subscribe(() => {
      this.notifications.clear();
    });

    this.group.valueChanges.subscribe((value) => {
      this.dates.dateFrom =
        value[this.dateFromControlName] === null
          ? null
          : this.transformDate(value[this.dateFromControlName]);
      this.dates.dateTo =
        value[this.dateToControlName] === null
          ? null
          : this.transformDate(value[this.dateToControlName]);
      this.handleValueChanges(this.dates);
    });
  }

  private transformDate(date: Date): Date {
    if (date instanceof Date) {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    }
    return date;
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  createDateValidationMessages() {
    return ngbDateMsg(this.group, () => false);
  }

  // TODO: dateToKey and dateFromKey should be removed after EPIC TRFV2-22133 is finished
  // also dateFromTargetControl and dateToTargetControl won't be needed then
  private createFormControls(): void {
    this.group = this.fgd.form;

    this.createDateFromControl();
    this.createDateToControl();
  }

  private createDateFromControl(): void {
    this.dateFromControlName = this.dateFromKey ?? getFormControlName(this.dateFromControl);
    this.dateFromTargetControl = this.dateFromControl ?? this.group.get(this.dateFromControlName);
  }

  private createDateToControl(): void {
    this.dateToControlName = this.dateToKey ?? getFormControlName(this.dateToControl);
    this.dateToTargetControl = this.dateToControl ?? this.group.get(this.dateToControlName);
  }

  private hasDatePickerInvalid(control: AbstractControl): boolean {
    return control.getError('ngbDate');
  }

  private handleValueChanges(value: DoubleDates) {
    this.notifications.clear();

    this.invalidDateValidation();
    this.missingDateValidation(value);
    this.fromDateGreaterThanToDateValidation(value);
  }

  private invalidDateValidation() {
    if (
      this.hasDatePickerInvalid(this.dateFromTargetControl) ||
      this.hasDatePickerInvalid(this.dateToTargetControl)
    ) {
      this.addNotification(incorrectDateValidationError);
    }
  }

  private missingDateValidation(value: DoubleDates) {
    const dateFromValue = value.dateFrom;
    const dateToValue = value.dateTo;
    const dateFromTouched = this.dateFromTargetControl.touched;
    const dateToTouched = this.dateToTargetControl.touched;

    const missingDate = this.requireAtLeastOneDate && !dateFromValue && !dateToValue;
    const missingAny =
      this.bothRequired && ((!dateFromValue && dateFromTouched) || (!dateToValue && dateToTouched));

    if (missingDate) {
      this.addNotification(missingDateError);
    }
    this.changeErrorStatus('missingDate', missingDate);

    if (missingAny) {
      this.addNotification(
        dateFromValue
          ? missingToError(this.requiredToMsgErrorKey)
          : missingFromError(this.requiredFromMsgErrorKey),
      );
    }

    this.changeErrorFieldStatus(
      this.dateFromTargetControl,
      'require',
      missingAny && !dateFromValue,
    );
    this.changeErrorFieldStatus(this.dateToTargetControl, 'require', missingAny && !dateToValue);
  }

  private fromDateGreaterThanToDateValidation(value: DoubleDates) {
    const dateFromGreater =
      value.dateFrom !== null && value.dateTo !== null && value.dateFrom > value.dateTo;

    if (dateFromGreater) {
      this.addNotification(dateFromGreaterError(this.dateRangeMsgErrorKey));
    }

    this.changeErrorStatus('dateFromGreater', dateFromGreater);
  }

  private addNotification(entry: NotificationEntry) {
    if (!this.notifications.hasAnyError()) {
      this.notifications.add(entry);
    }
  }

  private changeErrorStatus(errorKey: string, errorExists: boolean): void {
    this.changeErrorFieldStatus(this.dateFromTargetControl, errorKey, errorExists);
    this.changeErrorFieldStatus(this.dateToTargetControl, errorKey, errorExists);
  }

  private changeErrorFieldStatus(control: AbstractControl, errorKey: string, errorExists: boolean) {
    const errors = control.errors ?? {};

    if (errorExists) {
      errors[errorKey] = true;
    } else {
      delete errors[errorKey];
    }

    control.setErrors(Object.keys(errors).length ? errors : null);
  }
}
