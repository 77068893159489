<div
  class="outer-content"
  [ngClass]="{ 'show-loader': loadingData }"
>
  <div
    *ngIf="loadingData"
    class="inner-content"
    testid="loader"
  >
    <ngp-svg-icon
      class="svg-icon-spin svg-icon-size-4"
      iconName="loading-spinner"
    ></ngp-svg-icon>
    <span>{{ message | translate }}</span>
  </div>
</div>
