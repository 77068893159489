import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { HasEntityId, SlideinContext, Transitional } from '@demica/core/core';

@Component({
  selector: 'trf-transitions',
  templateUrl: 'transitions.component.html',
  styleUrls: ['transitions.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionsComponent implements OnInit {
  @Input()
  componentHeader: string;
  @Input()
  elementName: string;
  @Input()
  editedElement: string;
  @Input()
  elements: Transitional[];
  @Input()
  initialTransitions: HasEntityId[];
  @Input()
  previewMode? = false;

  @Output()
  transitionsChanged = new EventEmitter<HasEntityId[]>();

  transitions: Transitional[] = [];
  selectModel: Transitional;

  availableTransitions: Transitional[] = [];

  constructor(private slideinContext: SlideinContext) {}

  ngOnInit() {
    if (this.initialTransitions) {
      this.transitions = this.initialTransitions.map((it) =>
        this.elements.find((el) => el.entityId === it.entityId),
      );

      this.emitTransitions();
    }

    this.getAvailableTransitions();
  }

  addTransition() {
    if (this.canAdd) {
      this.transitions.push(this.selectModel as Transitional);
      this.selectModel = null;
      this.emitTransitions();
      this.getAvailableTransitions();
    }
  }

  removeTransition(transition: Transitional) {
    this.transitions = this.transitions.filter((t) => t !== transition);
    this.emitTransitions();
    this.getAvailableTransitions();
    this.slideinContext.isFormDirty = true;
  }

  private emitTransitions() {
    this.transitionsChanged.emit(this.transitions.map((t) => ({ entityId: t.entityId })));
  }

  private getAvailableTransitions(): void {
    const alreadyUsed = (el: Transitional) => this.transitions.indexOf(el) === -1;
    const editedElement = (el: Transitional) => this.editedElement !== el.entityId;
    this.availableTransitions = this.elements.filter(alreadyUsed).filter(editedElement);
  }

  get canAdd() {
    return !!(this.elementName && !!this.selectModel);
  }
}
