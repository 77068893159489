<ng-container [formGroup]="group">
  <div>
    <div
      *ngIf="!!label"
      class="column-label"
    >
      <label
        *ngIf="!!label"
        class="{{ labelClasses }}"
        >{{ label | translate }}</label
      >
      <ngp-tooltip-icon
        *ngIf="infoTooltip"
        [tooltipKey]="infoTooltip"
        [reversed]="true"
      ></ngp-tooltip-icon>
    </div>
    <div class="column-input">
      <div class="logo-input">
        <input
          #selectLogoFileInput
          class="hidden-input"
          type="file"
          accept="image/*"
          (change)="logoFileSelected($event)"
        />

        <ngp-button
          classes="tertiary"
          key="CLIENT_EDIT.CHOOSE_FILE"
          (click)="selectLogoFileInput.click()"
          testid="select-logo-button"
        ></ngp-button>
      </div>
      <div
        class="logo-preview"
        [ngClass]="{ hide: !isPreviewLogoVisible() }"
      >
        <div>
          <trf-logo-display
            *ngIf="logoHolder"
            class="logo-container bigger"
            [logoHolder]="logoHolder"
            [logoPath]="logoPath"
          ></trf-logo-display>

          <img
            #logoPreviewImage
            id="logo-preview-image"
            class="logo-image"
          />
        </div>
        <div class="trf-actions">
          <ngp-svg-icon
            tabindex="0"
            role="button"
            iconName="trash-alt"
            [attr.aria-label]="'CLIENT_EDIT.DELETE_LOGO' | translate"
            (click)="onDeleteLogo()"
            (keyup.enter)="onDeleteLogo()"
            testid="delete-logo-button"
          ></ngp-svg-icon>
        </div>
      </div>
    </div>
    <div class="validation-error">
      <span *ngFor="let validation of logoValidations">
        <ngp-inline-notification
          *ngIf="validation.func(formLogoContentControlName)"
          [message]="validation.key | translate"
        >
        </ngp-inline-notification>
        <ngp-inline-notification
          *ngIf="validation.func(formLogoNameControlName)"
          [message]="validation.key | translate"
        >
        </ngp-inline-notification>
      </span>
    </div>
  </div>
</ng-container>
