import {
  CustomRestResponseWithMetadata,
  RestResponse,
  RestResponseWithMetadata,
} from '../../interface/rest-response/rest-response.interface';

export function toData<T>(response: RestResponse<T>): T {
  return response.data;
}

export function toPageableData<T>(
  pageResponse: RestResponseWithMetadata<T>,
): RestResponseWithMetadata<T> {
  return {
    data: pageResponse.data,
    meta: pageResponse.meta,
  };
}

export function toCustomPageableData<T, R>(
  pageResponse: CustomRestResponseWithMetadata<T, R>,
): CustomRestResponseWithMetadata<T, R> {
  return {
    data: pageResponse.data,
    meta: pageResponse.meta,
  };
}
