import { AbstractControl, Validators } from '@angular/forms';

import {
  createMaxDecimalPlacesRegex,
  createMaxIntegerPlacesRegex,
} from '../component/form-decimal-row/regex/create-decimal-regex';
import {
  DECIMAL_PLACES_EXCEEDED_ERROR_KEY,
  INTEGER_PLACES_EXCEEDED_ERROR_KEY,
} from '../component/form-number-row/form-number-row-error-keys';

export const decimalValidator = (decimalPlaces: number) => {
  const regex = createMaxDecimalPlacesRegex(decimalPlaces);

  return function (fc: AbstractControl) {
    return Validators.pattern(regex)(fc) ? { [DECIMAL_PLACES_EXCEEDED_ERROR_KEY]: true } : null;
  };
};

export const integerValidator = (integerPlaces: number) => {
  const regex = createMaxIntegerPlacesRegex(integerPlaces);

  return function (fc: AbstractControl) {
    return Validators.pattern(regex)(fc) ? { [INTEGER_PLACES_EXCEEDED_ERROR_KEY]: true } : null;
  };
};

export const MAX_INTEGER_PLACES_INT = 13;
export const MAX_DECIMAL_PLACES_INT = 6;
