<td
  [title]="row.bankAccountName"
  testid="bank-account-name"
  >{{ row.bankAccountName }}
</td>

<td
  [title]="row.bankAccountNumber"
  testid="bank-account-number"
  >{{ row.bankAccountNumber }}
</td>
<td
  [title]="row.branchCodeOrRoutingNumber"
  testid="branch-code"
  >{{ row.branchCodeOrRoutingNumber }}
</td>
<td
  [title]="row.swiftBic"
  testid="swift-bic"
  >{{ row.swiftBic }}
</td>
<td
  [title]="
    'SELLER_BANK_ACCOUNT.ACCOUNT_TYPES.' + (row.bankAccountType.name || row.bankAccountType.key)
      | translate
  "
  testid="bank-account-type"
  >{{
    "SELLER_BANK_ACCOUNT.ACCOUNT_TYPES." + (row.bankAccountType.name || row.bankAccountType.key)
      | translate
  }}
</td>
<td
  [title]="row.accountHolder"
  testid="account-holder"
  >{{ row.accountHolder }}
</td>
<td
  [title]="row.bankName"
  S
  testid="bank-name"
  >{{ row.bankName }}
</td>
<td
  class="bank-address-lines"
  [title]="
    row.address1 +
    '\n' +
    row.address2 +
    '\n' +
    row.address3 +
    '\n' +
    row.address4 +
    '\n' +
    row.postCode
  "
  testid="bank-address"
>
  <p>
    {{ row.address1 }}
  </p>
  <p *ngIf="row.address2">
    {{ row.address2 }}
  </p>
  <p *ngIf="row.address3">
    {{ row.address3 }}
  </p>
  <p *ngIf="row.address4">
    {{ row.address4 }}
  </p>
  <p *ngIf="row.postCode">
    {{ row.postCode }}
  </p>
</td>
<td
  *ngIf="!isPreview"
  class="cell-actions"
  testid="cell-actions"
>
  <trf-table-actions
    [actions]="actions"
    [actionSubject]="row"
    [hasAnyUsages]="!!row.usageRelations?.length"
  ></trf-table-actions>
</td>
