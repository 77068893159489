import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { ExportType, identity } from '@demica/core/core';

import { ChartExportResourceService } from './chart-export-resource.service';

import { ExportDataHelper, LABEL_CHART_TITLE } from '../model/export-data/export-data-helper';

import { RequestExport } from '../model/chart-export';
import { saveAs } from 'file-saver';

export const MAX_FILENAME_LENGTH = 186;

@Injectable({
  providedIn: 'root',
})
export class ChartExportService {
  constructor(
    private _chartExportResourceService: ChartExportResourceService,
    private _translate: TranslateService,
  ) {}

  getMultipleConfigurationExport(
    exportDataHelpers: ExportDataHelper[],
    exportTitle: string,
    exportType: ExportType,
  ): void {
    const requests = exportDataHelpers.map((item) => item.getRequestExport());
    this._requestDownload(exportType, requests, exportTitle);
  }

  getTableExport(exportDataHelper: ExportDataHelper): void {
    const request = exportDataHelper.getRequestExport();

    this._requestDownload(
      exportDataHelper.getExportType(),
      [request],
      request.labelsMap[LABEL_CHART_TITLE],
      exportDataHelper.getConfiguration().extraSubtitleFileName,
    );
  }

  getChartExport(
    exportType: ExportType,
    requestExport: RequestExport[],
    name: string,
    extraName?: string,
  ): Observable<Blob> {
    return this._chartExportResourceService.postExportChart(exportType, requestExport).pipe(
      tap((fileBlob: Blob) => {
        const fileName = createFileName(name, extraName, exportType);
        downloadFile(fileBlob, exportType, fileName);
      }),
    );
  }

  getTranslatedLabelWithFallback(
    translationBase: string,
    translationSuffixKey: string,
    fallbackTransformFn: (arg: string) => string = identity,
  ): string {
    const translationKey = `${translationBase}.${translationSuffixKey}`;
    const translation = this._translate.instant(translationKey);
    return translationKey === translation ? fallbackTransformFn(translationSuffixKey) : translation;
  }

  getTranslatedLabel(chart: string, translationKey: string): string {
    return this._translate.instant(`${chart}.${translationKey}`);
  }

  private _requestDownload(
    exportType: ExportType,
    requestChartExport: RequestExport[],
    exportTitle: string,
    exportSubtitle?: string,
  ): void {
    this._chartExportResourceService
      .postExportChart(exportType, requestChartExport)
      .subscribe((result) => {
        const fileName = createFileName(exportTitle, exportSubtitle, exportType);

        downloadFile(result, exportType === ExportType.CSV ? ExportType.ZIP : exportType, fileName);
      });
  }
}

function downloadFile(result: Blob, exportType: ExportType, fileName: string) {
  saveAs(result, fileName);
}

export function createFileName(title: string, subTitle: string, exportType: ExportType) {
  const nameAndSubtitle = `${getName(title)}${getSubName(subTitle)}`;
  const truncatedNameAndSubtitle = getLimitedName(nameAndSubtitle);
  return `${truncatedNameAndSubtitle}_${getDate()}.${getExtension(exportType)}`;
}

export function getExtension(exportType: ExportType): string {
  return exportType === ExportType.CSV
    ? ExportType.ZIP.toString().toLowerCase()
    : exportType.toString().toLowerCase();
}

export function getDate(): string {
  const fillWithZero = (a: number) => (a <= 9 ? `0${a}` : `${a}`);
  const now = new Date(Date.now());
  const date = `${now.getFullYear()}${fillWithZero(now.getMonth() + 1)}${fillWithZero(
    now.getDate(),
  )}`;
  const time = `${fillWithZero(now.getHours())}${fillWithZero(now.getMinutes())}${fillWithZero(
    now.getSeconds(),
  )}`;
  return `${date}_${time}`;
}

export function getSubName(subTitle: string): string {
  return subTitle !== undefined ? `_${getName(subTitle)}` : '';
}

export function getName(title: string): string {
  return `${title.toLowerCase().replace(new RegExp(' ', 'g'), '_')}`;
}

export function getLimitedName(toTruncate: string): string {
  return toTruncate.substring(0, MAX_FILENAME_LENGTH);
}
