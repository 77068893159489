import { Component, Input } from '@angular/core';

import { AuthService, LOGOUT_REASONS, MenuOption } from '@demica/core/core';

@Component({
  selector: 'trf-menu-sign-out',
  templateUrl: './menu-sign-out.component.html',
  styleUrls: ['./menu-sign-out.component.sass'],
})
export class MenuSignOutComponent {
  @Input()
  menuOption: MenuOption;

  constructor(private _auth: AuthService) {}

  signOut(clickEvent: MouseEvent): void {
    clickEvent.preventDefault();
    this._auth.signOut(LOGOUT_REASONS.USER_LOGOUT);
  }
}
