import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { DialogTrackingService } from '../../service/dialog-tracking.service';
import { MovableModalService } from '../modal-container/movable-modal-service/lib/movable-modal.service';

@Component({
  selector: 'trf-modal-container',
  templateUrl: 'modal-container.component.html',
  styleUrls: ['./modal-container.component.sass'],
  viewProviders: [MovableModalService],
})
export class ModalContainerComponent implements AfterViewInit {
  @Input()
  icon: string;
  @Input()
  movable = true;
  @Input()
  headerText: string;
  @Input()
  closable = true;

  @Output()
  modalClose = new EventEmitter<void>();

  @ViewChild('dragHandler', { read: ElementRef, static: true })
  dragHandlerRef: ElementRef<HTMLElement>;

  @ViewChild('modalContent', { read: ElementRef, static: true })
  modalContentRef: ElementRef<HTMLElement>;

  constructor(
    private movableModalService: MovableModalService,
    private dialogTrackingService: DialogTrackingService,
    private elemenetRef: ElementRef,
  ) {}

  onClose() {
    this.dialogTrackingService.removeModal();
    this.modalClose.emit();
  }

  ngAfterViewInit(): void {
    if (this.movable) {
      this.movableModalService.makeMovable(this.dragHandlerRef, this.modalContentRef);
    }
    this.dialogTrackingService.addNewModal(this.elemenetRef.nativeElement);
  }
}
