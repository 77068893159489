import { Injectable } from '@angular/core';

import {
  defaultMaxLengthMsg,
  emailFormatMsg,
  requiredMsg,
  requiredTextFieldMsgs,
} from '../forms/validation-messages/common-validation-messages';

@Injectable()
export class OrganisationFormValidations {
  addressLineValidations = requiredTextFieldMsgs;
  maxLengthValidations = defaultMaxLengthMsg;
  emailValidations = emailFormatMsg;
  requireValidations = requiredMsg;
}
