import { Component, Input } from '@angular/core';

import { MenuOption, MenuOptionConfig } from '@demica/core/core';

import { IfHasMenuSectionAuthorityDirective } from '../../directive/if-has-menu-section-authority.directive';

@Component({
  selector: 'trf-menu-section',
  templateUrl: './menu-section.component.html',
  styleUrls: ['./menu-section.component.sass'],
  providers: [IfHasMenuSectionAuthorityDirective],
})
export class MenuSectionComponent {
  @Input()
  element: MenuOptionConfig;

  @Input()
  menuOptions?: MenuOption[];

  menuOptionTrackBy(index: number, menuOption: MenuOption) {
    return menuOption.id;
  }
}
