export function getFileNameFromContentDisposition(text: string) {
  const fileNameRegex = new RegExp('filename[^;\\n=]*=([\'\\"])*(.*)');
  const fileName = fileNameRegex.exec(text);
  if (fileName.length > 3) {
    return fileName[2].substr(0, fileName[2].length - 1);
  } else if (fileName.length > 0) {
    return fileName[0].substr('filename="'.length, fileName[0].length - 'filename="'.length - 1);
  }
  return '';
}
