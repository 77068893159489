import { Directive, HostListener, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { LocalisationService } from '@demica/core/core';

import { KeyboardEventKeyMatcher } from './keyboard-event-key.matcher';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[onlyDateInput]',
})
export class OnlyDateInputDirective implements OnDestroy {
  private _destroyed$ = new Subject<void>();

  private _validKeysRegExp: RegExp;

  constructor(
    private _localisationService: LocalisationService,
    private _keyboardEventKeyMatcher: KeyboardEventKeyMatcher,
  ) {
    this._setupDefaults();
    this._setupLocaleWatch();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this._keyboardEventKeyMatcher.isSpecialKey(event)) {
      return true;
    }
    if (!this._validKeysRegExp.test(event.key)) {
      event.preventDefault();
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _constructRegExp(separator = ''): RegExp {
    return new RegExp(`^([0-9${separator}])*$`);
  }

  private _setupDefaults(): void {
    this._validKeysRegExp = this._constructRegExp();
  }

  private _setupLocaleWatch(): void {
    this._localisationService.locale
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe(this._handleLocaleChange);
  }

  private _handleLocaleChange = (locale: string): void => {
    const localeData = this._localisationService.getLocaleId(locale);
    const usedSeparator = this._localisationService.getDateSeparator(localeData);

    this._validKeysRegExp = this._constructRegExp(usedSeparator);
  };
}
