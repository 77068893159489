export const INVOICE_ID_COLUMN = 1; // Contract ID in Contract RF transactions
export const CURRENCY = 2;
export const AMOUNT_COLUMN = 3;
export const DUE_DATE_COLUMN = 4;
export const RECEIVABLE_DATE_COLUMN = 5; // Receivable can be an Invoice / Contract / Schedule
export const TRUST_COLUMN = 7;
export const COUNTERPARTY_NAME_COLUMN = 8;
export const COUNTERPARTY_CODE_COLUMN = 9;
export const COUNTERPARTY_CITY_COLUMN = 15;
export const COUNTERPARTY_STATE_COLUMN = 16;
export const COUNTERPARTY_COUNTRY_COLUMN = 17;
export const SELLER_CODE_COLUMN = 19;
export const PERCENTAGE = 20;
export const RECEIVABLE_TYPE = 49;
export const FUNDING_DATE = 50;
export const CREDIT_RATING = 81;
export const COUNTERPARTY_GROUP_NAME = 82;
export const COUNTERPARTY_GROUP_CODE_COLUMN = 83;
export const SCHEDULE_ID_COLUMN = 200;
export const RISK_FACTOR_VALUE = 201;
