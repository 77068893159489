import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { emitChangeEvent } from '../../utils/emit-change-event.util';

@Component({
  selector: 'ngp-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
  @Input()
  disabled = false;
  @Input()
  checked = false;
  @Input()
  labelText?: string;
  @Input()
  indeterminate?: boolean;

  @Output()
  checkboxChecked = new EventEmitter<boolean>();

  @ViewChild('checkbox')
  checkbox!: ElementRef;

  labelId = '';

  ngOnInit() {
    if (this.labelText)
      this.labelId = this.labelText.replace(' ', '-').replace('& ', '').toLowerCase();
  }

  writeValue(obj: boolean) {
    this.checked = obj;
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleCheckbox() {
    if (this.disabled) return;

    this.checked = !this.checked;
    this._onChange(this.checked);
    this.checkboxChecked.emit(this.checked);
    emitChangeEvent(this.checkbox.nativeElement);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  private _onChange = (_: boolean) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onTouched = () => {};
}
