import { Pipe, PipeTransform } from '@angular/core';

const defaultMax = 10;

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, maxOpt?: number) {
    const max = maxOpt ? maxOpt : defaultMax;
    return value.length <= max ? value : value.substr(0, max) + '...';
  }
}
