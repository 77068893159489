import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { InsurersViewComponent } from './view/insurers-view.component';

@NgModule({
  exports: [InsurersViewComponent],
  imports: [TranslateModule.forChild()],
  declarations: [InsurersViewComponent],
})
export class InsurersModule {}
