import { API_URL, API_V2_URL } from '../../api.config';

const DELIMITER = '{}';
const MOCK_API_URL = '/api/mock';

/**
 * Encode a template and parameters into a secure URL
 * @param template in a form of "foo/{}/bar" where "{}" is a parameter placeholder
 * @param params that will be applied to a template. Either a string or a number. Numbers are converted to strings.
 */
export function encodeEndpoint(template: string, ...params: (string | number)[]): string {
  return `${API_URL}/${encodeUrl(template, ...params)}`;
}

export function encodeEndpointApiV2(template: string, ...params: (string | number)[]): string {
  return `${API_V2_URL}/${encodeUrl(template, ...params)}`;
}

export function encodeMockEndpoint(template: string, ...params: (string | number)[]): string {
  return `${MOCK_API_URL}/${encodeUrl(template, ...params)}`;
}

export function encodeUrl(template: string, ...params: (string | number)[]): string {
  const stringParams = params.map((p) => String(p));
  const expectedParams = template.split(DELIMITER).length - 1;

  if (expectedParams !== params.length)
    throw new Error(`Wrong number of parameters. Expected ${expectedParams} got ${params.length}`);

  return stringParams.reduce(
    (url, param) => url.replace(DELIMITER, encodeURIComponent(param)),
    template,
  );
}
