import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { byProperty } from '@demica/core/core';

import { ColumnDefinition } from './column-definition.interface';

@Injectable()
export class ColumnToggleStateService {
  private _columnDefinitions: ColumnDefinition[];
  private _staticColumns: ColumnDefinition[];
  private _toggleableColumns: ColumnDefinition[];
  private _visibleColumnsSubject: BehaviorSubject<ColumnDefinition[]> = new BehaviorSubject<
    ColumnDefinition[]
  >([]);
  private _toggleableColumnsSubject: BehaviorSubject<ColumnDefinition[]> = new BehaviorSubject<
    ColumnDefinition[]
  >([]);
  private _visibleColumns$: Observable<ColumnDefinition[]> =
    this._visibleColumnsSubject.asObservable();
  private _toggleableColumns$: Observable<ColumnDefinition[]> =
    this._toggleableColumnsSubject.asObservable();

  private readonly SORT_PROPERTY: string = 'position';

  getVisibleColumns$(): Observable<ColumnDefinition[]> {
    return this._visibleColumns$;
  }

  getToggleableColumns$(): Observable<ColumnDefinition[]> {
    return this._toggleableColumns$;
  }

  setColumnDefinitions(value: ColumnDefinition[]): void {
    if (!value) return;
    this._columnDefinitions = value;
    this._toggleableColumns = [];
    this._staticColumns = [];
    this._columnDefinitions.forEach((column) => {
      if (column.toggleable) {
        this._toggleableColumns.push(column);
      } else {
        this._staticColumns.push(column);
      }
    });

    this._updateColumnsState();
  }

  onSelectionChange(checked: boolean, column: ColumnDefinition): void {
    this._toggleableColumns = this._toggleableColumns.map((col) => {
      return {
        ...col,
        checked: col.nameKey === column.nameKey ? checked : col.checked,
      };
    });
    this._updateColumnsState();
  }

  private _updateColumnsState(): void {
    const columnSortFn = byProperty(this.SORT_PROPERTY);

    const allVisibleColumns = [
      ...this._staticColumns,
      ...this._toggleableColumns.filter((col) => col.checked),
    ];
    this._visibleColumnsSubject.next(allVisibleColumns.sort(columnSortFn));
    this._toggleableColumnsSubject.next(this._toggleableColumns.sort(columnSortFn));
  }
}
