<ul
  *ngIf="translationKeys$ | async as keys"
  data-testid="translatable-list"
>
  <ng-container *ngFor="let item of keys">
    <li
      *ngIf="!config || !config.keys[item.key]?.hidden || !config.keys[item.key].hidden()"
      [innerHtml]="item.fullKey | translate"
      data-testid="translatable-list-item"
    >
    </li>
  </ng-container>
</ul>
