import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { KeyboardNavigationStrategyService } from '@demica/accessibility';
import { AliasAwaitingApproval } from '@demica/core/core';

import { Actions } from '../../../actions/model/actions.interface';

@Component({
  selector: 'trf-aliases-awaiting-approval-row',
  templateUrl: 'aliases-awaiting-approval-row.component.html',
  styleUrls: ['aliases-awaiting-approval-row.component.sass'],
})
export class AliasesAwaitingApprovalRowComponent implements OnInit {
  @Input()
  row: AliasAwaitingApproval;
  @Input()
  actions: Actions;
  @Input()
  showTransactions: boolean;
  @Input()
  displayCheckbox: boolean;

  @Output()
  checkboxChanged = new EventEmitter<AliasAwaitingApproval>();

  constructor(private keyboardNavigationService: KeyboardNavigationStrategyService) {}

  ngOnInit(): void {
    this.row.tableId = `row-${this.row.transaction.entityId}-${this.row.dataSource.entityId}-${this.row.analysisGroup.entityId}-${this.row.environment.entityId}-${this.row.value}`;
  }

  onCheckboxClick(checked: boolean) {
    this.row.selected = checked;
    this.checkboxChanged.emit(this.row);
  }

  onCheckboxKeyup(event: KeyboardEvent) {
    this.keyboardNavigationService.onTableCheckboxKeyup(event, (checked: boolean) =>
      this.onCheckboxClick(checked),
    );
  }
}
