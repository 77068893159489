import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RoutingService } from '../service/routing.service';
import { UserService } from '../service/user.service';

import { TRFRoute } from '../interface/route.interface';
import { User } from '../model/user';

export const authorisationGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean> => {
  const routingService = inject(RoutingService);

  return canActivate();

  function canActivate() {
    return inject(UserService).currentUser.pipe(
      map((user: User) => {
        const requestedRoute = route.routeConfig as TRFRoute;
        const userHasRequiredAuthority = user.hasAuthority(requestedRoute.requiredAuthority);

        if (userHasRequiredAuthority) {
          return true;
        }

        if (!userHasRequiredAuthority && requestedRoute.loadNotAvailableResourcePage) {
          return routingService.redirectToNotAvailableResourcePage(route.data['fallbackRoute']);
        }

        return routingService.redirectToValidRouteAccess(route, state, user);
      }),
    );
  }
};
