import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  EntityReference,
  maxUserDefinedColumnLength,
  TransactionEnvironmentMapping,
} from '@demica/core/core';

import { AliasesSearchClass } from '../../aliases-search.class';

export type AliasMissingSearchForm = FormGroup<{
  file: FormControl<string>;
  transaction: FormControl<EntityReference | null>;
  dataSource: FormControl<EntityReference>;
  analysisGroup: FormControl<EntityReference>;
  environment: FormControl<EntityReference>;
  dateFrom: FormControl<string>;
  dateTo: FormControl<string>;
  analysisCode: FormControl<string>;
}>;

export type MissingMappingSearchFormValue = AliasMissingSearchForm['value'];

@Component({
  selector: 'trf-alias-missing-search',
  templateUrl: 'alias-missing-search.component.html',
  styleUrls: ['alias-missing-search.component.sass'],
})
export class AliasMissingSearchComponent extends AliasesSearchClass implements OnInit, OnDestroy {
  @Input()
  environmentsMapping: TransactionEnvironmentMapping[];

  @Output()
  searchChange = new EventEmitter<MissingMappingSearchFormValue>();

  @Output()
  clearChange = new EventEmitter<void>();

  @Output()
  filterChange = new EventEmitter<void>();

  form: AliasMissingSearchForm = this._fb.group({
    file: [null],
    transaction: [null],
    dataSource: [null],
    analysisGroup: [null],
    environment: [null],
    dateFrom: [null],
    dateTo: [null],
    analysisCode: [null],
  });

  clearSubject = new Subject<void>();

  readonly maxLength = maxUserDefinedColumnLength;
  private _destroyed$ = new Subject<void>();

  constructor(private _fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.loadDefaultFilters();

    this.form.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.filterChange.emit());

    this.form.controls.transaction.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((transaction) => {
        if (!transaction) {
          this.loadDefaultFilters();
        } else {
          this.filterByTransaction(transaction);
        }
        this.form.controls.dataSource.reset(null);
        this.form.controls.analysisGroup.reset(null);
        this.form.controls.environment.reset(null);
      });
  }

  onSearch(): void {
    this.searchChange.emit(this.form.getRawValue());
  }

  onClear(): void {
    this.form.reset();
    this.clearSubject.next();
    this.clearChange.emit();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
