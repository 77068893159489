import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { DictionaryService, EmittedFormValue } from '@demica/core/core';
import { SellerBankAccount } from '@demica/resources/seller';

import { FieldDefinition } from '../../../forms/form';
import { definitionGroupToMessages } from '../../../forms/validation-messages/message-builder';
import {
  msgMaxLength,
  msgMinLength,
  msgRequired,
} from '../../../forms/validation-messages/message-definitions';
import { requireSelect, validateNotEmpty } from '../../../forms/validators';
import { SellerBankAccountFormFields } from '../model/seller-bank-accoun-form-fields.enum';
import {
  maxBankAddressLength,
  maxBranchCodeRoutingNumberLength,
  maxOpcoAccountHolder,
  maxOpcoBankAccountNameLength,
  maxOpcoBankAccountNumberLength,
  maxOpcoBankNameLength,
  maxPostCodeLength,
  maxSwiftBicCodeLength,
  minOpcoBankAccountNameLength,
} from '../validation/opco-bank-account-validation-constants';

type FormDefinition = Record<SellerBankAccountFormFields, FieldDefinition>;

function maxLength(max: number) {
  return Validators.maxLength(max);
}

@Component({
  selector: 'trf-seller-bank-account',
  templateUrl: './seller-bank-account.component.html',
  styleUrls: ['./seller-bank-account.component.sass'],
})
export class SellerBankAccountComponent implements OnInit {
  @Input()
  bankAccountData: SellerBankAccount;
  @Input()
  submitted: boolean;

  @Output()
  dataChanged = new EventEmitter<EmittedFormValue<SellerBankAccount>>();

  maxPostCodeLength = maxPostCodeLength;
  maxBranchCodeRoutingNumberLength = maxBranchCodeRoutingNumberLength;
  maxOpcoBankAccountNameLength = maxOpcoBankAccountNameLength;
  maxOpcoBankNameLength = maxOpcoBankNameLength;

  validationMessages = this.initValidationMessages();

  sellerBankAccountTypes = this.dictionaryService.getSellerBankAccountTypes();

  FormFields = SellerBankAccountFormFields;

  formDefinition: FormDefinition = {
    bankAccountName: ['', [validateNotEmpty, maxLength(maxOpcoBankAccountNameLength)]],
    bankAccountNumber: ['', [validateNotEmpty, maxLength(maxOpcoBankAccountNumberLength)]],
    branchCodeOrRoutingNumber: ['', [maxLength(maxBranchCodeRoutingNumberLength)]],
    swiftBic: ['', [maxLength(maxSwiftBicCodeLength)]],
    bankAccountType: [null, [requireSelect]],
    accountHolder: ['', [validateNotEmpty, maxLength(maxOpcoAccountHolder)]],
    bankName: ['', [maxLength(maxOpcoBankNameLength)]],
    address1: ['', [maxLength(maxBankAddressLength)]],
    address2: ['', [maxLength(maxBankAddressLength)]],
    address3: ['', [maxLength(maxBankAddressLength)]],
    address4: ['', [maxLength(maxBankAddressLength)]],
    postCode: ['', [maxLength(maxPostCodeLength)]],
  };
  form: UntypedFormGroup = this.fb.group(this.formDefinition);

  constructor(private fb: UntypedFormBuilder, private dictionaryService: DictionaryService) {}

  ngOnInit(): void {
    if (this.bankAccountData) this.fillFormValues(this.bankAccountData);

    this.form.statusChanges.subscribe(() => {
      this.dataChanged.emit({
        data: {
          ...this.bankAccountData,
          ...this.form.value,
        },
        isValid: this.form.valid,
      });
    });
  }

  fillFormValues(formValue: SellerBankAccount) {
    this.form.patchValue(formValue);
  }

  private initValidationMessages() {
    return definitionGroupToMessages(
      {
        bankAccountNameValidations: [
          msgRequired,
          msgMaxLength(maxOpcoBankAccountNameLength),
          msgMinLength(minOpcoBankAccountNameLength),
        ],
        bankAccountNumberValidations: [msgRequired, msgMaxLength(maxOpcoBankAccountNumberLength)],
        swiftBicCodeValidations: [msgMaxLength(maxSwiftBicCodeLength)],
        branchCodeRoutingNumberValidations: [msgMaxLength(maxBranchCodeRoutingNumberLength)],
        accountHolderValidations: [msgRequired, msgMaxLength(maxOpcoAccountHolder)],
        bankAddressValidations: [msgMaxLength(maxBankAddressLength)],
        bankPostCodeValidations: [msgMaxLength(maxPostCodeLength)],
        bankAccountTypeValidations: [msgRequired],
        bankNameValidations: [msgMaxLength(maxOpcoBankNameLength)],
      },
      () => this.form,
      () => this.submitted,
    );
  }
}
