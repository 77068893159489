<ng-container *ngIf="userCurrencyCode">
  <div
    class="currency"
    role="application"
    (click)="$event.stopPropagation()"
    attr.aria-label="{{ 'HEADER.CURRENCY' | translate }}"
  >
    <button
      #dropdownButton
      class="trf-header-btn"
      [ngClass]="{ on: dropdownVisible }"
      (click)="toggleDropdown()"
      (keyup.escape)="closeDropdown()"
      (keyup.enter)="focusOnFirstElement()"
      testid="header-currency-btn"
      attr.aria-label="{{
        (dropdownVisible
          ? 'HEADER.CURRENCY_BUTTON_LABEL_EXPANDED'
          : 'HEADER.CURRENCY_BUTTON_LABEL_COLLAPSED'
        ) | translate: { userCurrency: 'CURRENCIES.' + selectedCurrencyCode() | translate }
      }}"
    >
      <span>{{ userCurrencyCode }}</span>
      <ngp-svg-icon
        class="dropdown-chevron"
        iconName="chevron-down"
        [ngClass]="{ dropdownVisible: dropdownVisible }"
      ></ngp-svg-icon>
    </button>

    <div
      *ngIf="dropdownVisible"
      trfOutsideClickMonitor
      (trfOutsideClick)="closeDropdown()"
      class="dropdown"
      testid="currencies-dropdown-container"
    >
      <ngp-inline-loader [loading]="loading"></ngp-inline-loader>

      <ng-container *ngIf="!loading">
        <div class="currencies-header">
          <span>{{ "HEADER.SELECT_CURRENCY" | translate }}</span>
        </div>
        <div class="currencies-list">
          <p *ngIf="!loading && currenciesCodes.length === 0">{{
            "CURRENCIES.NO_CURRENCY_RATE_AVAILABLE" | translate
          }}</p>
          <ul
            *ngIf="!loading && currenciesCodes.length > 0"
            #ulElement
            class="list-unstyled"
            role="listbox"
          >
            <li
              *ngFor="let currencyCode of currenciesCodes; let i = index"
              class="currency-item"
              trfArrowKeysFocus
              role="option"
              tabindex="0"
              [elementsLength]="currenciesCodes.length"
              [ngClass]="{ active: currencyCode === userCurrencyCode }"
              [attr.testid]="'currency-' + currencyCode"
              [index]="i"
              (keyup.escape)="closeDropdown($event)"
              (click)="setCurrency(currencyCode)"
              (keyup.enter)="setCurrency(currencyCode)"
              (focusOnFirst)="focusOnFirstElement()"
              (focusOnLast)="focusOnLastElement()"
            >
              <span class="currency-symbol">{{ currencyCode }}</span>
              <span class="currency-name">{{ "CURRENCIES." + currencyCode | translate }}</span>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
