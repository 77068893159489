import { Injectable } from '@angular/core';

export type TreeStructuredData<T> = T & {
  children?: TreeStructuredData<T>[];
};

export interface SelectItem<T> {
  id: string;
  label: string;
  value: T;
  level: number;
  hasChildren: boolean;
}

@Injectable()
export class TreeSelectConverter {
  // flat array with depth level
  convert<DATA>(
    data: TreeStructuredData<DATA>[],
    idProvider: (item: DATA) => string,
    labelProvider: (item: DATA) => string,
  ): SelectItem<DATA>[] {
    return this.flatten(data, 0, idProvider, labelProvider);
  }
  private flatten<DATA>(
    data: Array<TreeStructuredData<DATA>>,
    level: number,
    idProvider: (item: DATA) => string,
    labelProvider: (item: DATA) => string,
  ): SelectItem<DATA>[] {
    return data.reduce((acc, item) => {
      const id = idProvider(item);
      const label = labelProvider(item);
      const selectItem: SelectItem<DATA> = {
        id,
        label,
        value: item,
        level,
        hasChildren: !!item.children?.length,
      };
      const { children, ...props } = item;
      if (children) {
        return [
          ...acc,
          selectItem,
          ...this.flatten(children, level + 1, idProvider, labelProvider),
        ];
      }
      return [...acc, selectItem];
    }, []);
  }
}
