import { Injectable } from '@angular/core';

import { EMPTY, ReplaySubject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import {
  AnalyticsEventTypes,
  AnalyticsPageLoadEventData,
  AnalyticsRequest,
} from '@demica/resources/analytics';
import { AnalyticsClientType } from '@demica/resources/app-config';

import { AnalyticsDataBusService } from '../../analytics-data-bus.service';
import { AnalyticsRemoteStorageService } from '../../analytics-remote-storage.service';

import { mapRouteToPageParams } from '../../map-route-to-page-params.helper';

import { cutAfterChars } from '../../../utils/string-utils';
import { getLocationOrigin, getLocationPath, getLocationUrl } from '../../../utils/window';
import { AnalyticsClient, AnalyticsEventProperties } from '../abstract-analytics-client.class';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsServerSideClient extends AnalyticsClient {
  logoutFinish$ = new ReplaySubject<void>();

  clientType = AnalyticsClientType.SERVER_SIDE;

  constructor(
    private _analyticsDataBus: AnalyticsDataBusService,
    private _analyticsStorage: AnalyticsRemoteStorageService,
  ) {
    super();
    this._analyticsStorage.persist();
  }

  postEvent(event: AnalyticsEventTypes, props?: AnalyticsEventProperties): void {
    switch (event) {
      case AnalyticsEventTypes.PAGE_LOAD: {
        const { lang } = props;
        const url = getLocationUrl();
        const rootPage = cutAfterChars(getLocationPath().substring(1), ['/', '?']);
        const { pageName, category } = mapRouteToPageParams(rootPage);
        const payload = this._createPayload(event, { pageName, category, url, language: lang });

        this._analyticsStorage.record(payload).subscribe();
        break;
      }

      case AnalyticsEventTypes.USER_LOGOUT: {
        this._analyticsStorage
          .record(this._createPayload(event))
          .pipe(
            catchError(() => EMPTY),
            finalize(() => {
              this.logoutFinish$.next();
              this.logoutFinish$.complete();
            }),
          )
          .subscribe();
        break;
      }

      case AnalyticsEventTypes.USER_LOGIN: {
        this._analyticsStorage.record(this._createPayload(event));
        break;
      }

      case AnalyticsEventTypes.UNLOAD_APP: {
        this._analyticsStorage.sendAnalyticsBeacon(this._createPayload(event));
        break;
      }

      default: {
        console.warn(`Analytics: unhandled event ${event}`);
      }
    }
  }

  private _createPayload(
    eventType: AnalyticsEventTypes,
    extraEventData?: AnalyticsPageLoadEventData,
  ): AnalyticsRequest {
    const sessionId = this._analyticsDataBus.sessionId;
    const clientId = this._analyticsDataBus.clientId;
    const domain = getLocationOrigin();
    const dateTime = new Date().toISOString();

    return {
      sessionId,
      clientId,
      domain,
      events: [
        {
          dateTime,
          eventType,
          eventData: extraEventData,
        },
      ],
    };
  }
}
