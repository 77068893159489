import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { apiUrl } from '../../config/api.config';
import { CurrencyCode } from '../../model/currency-code.interface';
import { RestResponse } from '../../model/response.interface';

@Injectable()
export class CurrenciesService {
  constructor(private http: HttpClient) {}

  getCurrencyCodes() {
    return this.http
      .get(`${apiUrl}/resources/currency-codes`)
      .pipe(map((r: RestResponse<CurrencyCode[]>) => r.data));
  }
}
