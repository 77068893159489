import { Directive, Input } from '@angular/core';

import {
  AnalysisGroupResource,
  DictionaryEntry,
  EntityId,
  EntityReference,
  Environment,
  TransactionBaseResource,
  TransactionDataSource,
  TransactionEnvironmentMapping,
} from '@demica/core/core';

import { removeDuplicatedCounterpartyCodes } from './aliases-common';

@Directive()
export abstract class AliasesSearchClass {
  @Input()
  transactions: TransactionBaseResource[];
  @Input()
  dataSources: TransactionDataSource[];
  @Input()
  analysisGroups: AnalysisGroupResource[];
  @Input()
  environments: Environment[];
  @Input()
  environmentsMapping: TransactionEnvironmentMapping[];

  filteredDataSources: TransactionDataSource[];
  filteredAnalysisGroups: DictionaryEntry[];
  filteredEnvironments: Environment[];

  loadDefaultFilters(): void {
    this.filteredDataSources = this.dataSources;
    this.filteredAnalysisGroups = removeDuplicatedCounterpartyCodes(this.analysisGroups);
    this.filteredEnvironments = this.environments;
  }

  filterByTransaction(transaction: EntityReference): void {
    this.filteredDataSources = this.dataSources?.filter(
      (dataSource) => dataSource.transactionId === transaction.entityId,
    );
    this.filteredAnalysisGroups = this.analysisGroups?.filter(
      (analysisGroup) =>
        !analysisGroup.transactionId || analysisGroup.transactionId === transaction.entityId,
    );
    const transactionEnvironments = this.environmentsMapping
      ?.filter((mapping) => mapping.transactionId === transaction.entityId)
      .map((mapping) => mapping.environmentId as EntityId);
    this.filteredEnvironments = this.environments?.filter((environment) =>
      transactionEnvironments.includes(environment.entityId),
    );
  }
}
