<div
  cdkDropList
  ngpDragAndDropNested
  [cdkDropListData]="data"
  (cdkDropListDropped)="onDragDrop$.next($event)"
  [id]="baseId"
  [ngStyle]="styleData"
  class="ngp-dnd-container"
  tabindex="-1"
>
  <div
    *ngIf="data?.length; else emptyInfo"
    trfTableKeyHandler
    class="ngp-dnd-list-container"
    role="table"
    a11y-keyup-container
    tabindex="0"
    [attr.aria-label]="tableLabel"
  >
    <div
      trfTableRowAttributesSetter
      class="ngp-dnd-column-container"
      a11y-keyup-header-container
      role="row"
    >
      <span
        *ngFor="let column of columnDefinitions; let i = index"
        class="ngp-dnd-label"
        role="columnheader"
        [title]="column.nameKey | translate"
        [class]="column.classes"
        [attr.data-not-focusable]="i === 0 || null"
        [attr.aria-label]="column.nameKey | translate"
      >
        {{ column.nameKey | translate }}
      </span>
    </div>
    <ul
      class="ngp-dnd-list"
      role="rowgroup"
      a11y-keyup-body-container
    >
      <ngp-drag-and-drop-nested-item
        *ngFor="let item of data"
        [item]="item"
        [onDragDrop]="onDragDrop$"
        [nestingLevel]="1"
        [previewMode]="false"
        [styleData]="styleData"
        [rowConfiguration]="rowConfiguration"
        [containerTranslation]="containerTranslation"
        class="ngp-dnd-item ngp-dnd-item-first"
        a11y-keyup-row-container
      >
      </ngp-drag-and-drop-nested-item>
    </ul>
  </div>
  <ng-template #emptyInfo>
    <ngp-not-defined
      [message]="viewData.emptyMsg"
      class="ngp-dnd-empty"
      role="row"
      tabindex="0"
      [attr.aria-label]="tableLabel"
    ></ngp-not-defined>
  </ng-template>
</div>
