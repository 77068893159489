<div
  *ngIf="page$ | async as page"
  class="trf-section"
>
  <div class="trf-section-content">
    <h1>{{ "NOT_AVAILABLE_RESOURCE.TITLE" | translate }}</h1>
    <p>{{ "NOT_AVAILABLE_RESOURCE.MAIN_MESSAGE" | translate }}</p>
    <ngp-button
      classes="primary"
      [key]="page.buttonText || 'NOT_AVAILABLE_RESOURCE.BUTTON_TEXT' | translate"
      (click)="goToUrl(page.url)"
    ></ngp-button>
  </div>
</div>
