import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { from, Subject } from 'rxjs';
import { debounceTime, distinct, filter, takeUntil, tap, toArray } from 'rxjs/operators';

import { byNaturalOrder, NumericRange } from '@demica/core/core';

const range = (a: number, b: number) => new NumericRange(a, b).asArray();
const pageChangeDebounceTime = 300;
const showGapIndicatorsThreshold = 10;

@Component({
  selector: 'trf-pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  numberOfPages: number;
  @Input()
  firstPage: boolean;
  @Input()
  lastPage: boolean;
  @Input()
  currentPage: number;
  @Input()
  disabled: boolean;

  @Output()
  pageChange = new EventEmitter<number>();

  pages: number[];

  private destroyed$ = new Subject<void>();
  private deferredPageChanges$ = new Subject().pipe(
    debounceTime(pageChangeDebounceTime),
    takeUntil(this.destroyed$),
  ) as Subject<number>;

  ngOnInit(): void {
    this.initPages();
    this.deferredPageChanges$.subscribe((p) => this.pageChange.emit(p));
  }

  ngOnChanges(): void {
    this.initPages();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onPageClick(pageNumber: number) {
    if (pageNumber > this.numberOfPages - 1 || pageNumber < 0) return;

    // optimistic update
    this.currentPage = pageNumber;
    this.initPages();

    this.deferredPageChanges$.next(pageNumber);
  }

  private initPages() {
    const fullRange = range(0, this.numberOfPages - 1);
    if (fullRange.length > showGapIndicatorsThreshold) {
      from([
        ...range(0, 2),
        ...range(this.currentPage - 1, this.currentPage + 1),
        ...range(this.numberOfPages - 3, this.numberOfPages - 1),
      ])
        .pipe(
          distinct(),
          filter((p) => p >= 0 && p < this.numberOfPages),
          toArray(),
          tap((pages) => {
            this.pages = pages.sort(byNaturalOrder);
          }),
        )
        .subscribe();
    } else {
      this.pages = fullRange;
    }
  }

  isCurrentPage(page: number) {
    return page === this.currentPage;
  }
}
