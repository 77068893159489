import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Transaction } from '../model/transaction.interface';

@Injectable({
  providedIn: 'root',
})
/** A stateful service for communicating transaction changes between routed views and containers */
export class TransactionContext {
  /** a subject for emitting transaction when it is first loaded... and reloaded after changes */
  private loadTransaction: BehaviorSubject<Transaction> = new BehaviorSubject(null);
  loadTransaction$: Observable<Transaction> = this.loadTransaction.asObservable();

  /** a subject for emitting display mode change: transaction edit / transaction version preview */
  private versionPreviewMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  versionPreviewMode$: Observable<boolean> = this.versionPreviewMode.asObservable();

  /** notify subscribers that the transaction has been updated (also on backend),
   so they can take appropriate action - i.e. reload their state */
  transactionEntityRevisionChanged(entityRevision: number) {
    this.setTransaction({ ...this.loadTransaction.value, entityRevision });
  }

  setTransaction(transaction: Transaction) {
    this.loadTransaction.next({ ...transaction });
  }

  setVersionPreviewMode(previewMode: boolean) {
    this.versionPreviewMode.next(previewMode);
  }
}
