import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import {
  AllProd,
  DashboardFiltersResourceService,
  EntityReference,
  FilterParameters,
  FilterResource,
  SlideinContainerAbstractClass,
  TransactionFilterResource,
} from '@demica/core/core';

@Component({
  selector: 'trf-filters-slidein',
  templateUrl: './filters-slidein.container.html',
  styleUrls: ['./filters-slidein.container.sass'],
})
export class FiltersSlideinContainerComponent
  extends SlideinContainerAbstractClass
  implements OnInit
{
  @Input()
  initialFilterParameters: FilterParameters;
  @Input()
  authority: string;
  @Input()
  opcosEnabled = true;

  environments: EntityReference[];
  clients: FilterResource[];
  opcos: FilterResource[];
  transactions: TransactionFilterResource[];

  loading = true;

  form: UntypedFormGroup = this.fb.group({
    environments: [null, []],
    clients: [null, []],
    opcos: [null, []],
    transactions: [null, []],
    availableEnvironments: [null, []],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private dashboardFiltersResourceService: DashboardFiltersResourceService,
  ) {
    super();
  }

  ngOnInit() {
    this.form.patchValue(this.initialFilterParameters);
    this.dashboardFiltersResourceService.getAvailableFilters().subscribe((availableFilters) => {
      this.environments = availableFilters.environments;
      this.environments.unshift(AllProd);
      this.clients = availableFilters.clients;
      this.opcos = availableFilters.opcos;
      this.transactions = availableFilters.transactions;
      this.loading = false;
    });
  }

  onApply() {
    this.modal.close(this.prepareFilterResults(this.form.value));
  }

  prepareFilterResults(currentFilterParameters: FilterParameters) {
    return {
      currentFilterParameters: currentFilterParameters,
      initialFilterParameters: {
        availableEnvironments: currentFilterParameters.availableEnvironments,
        environments: this.environments,
        clients: this.clients,
        opcos: this.opcos,
        transactions: this.transactions,
      },
    };
  }

  clearAllFilters() {
    this.form.reset({
      environments: [AllProd],
      clients: [],
      opcos: [],
      transactions: [],
      availableEnvironments: this.initialFilterParameters.availableEnvironments,
    });
  }
}
