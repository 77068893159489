<div class="toast-content">
  <div [ngClass]="titleClass">{{ toast.title }}</div>
  <div
    [ngClass]="messageClass"
    [ngSwitch]="toast.bodyOutputType"
  >
    <div
      *ngSwitchCase="bodyOutputType.Component"
      #componentBody
    ></div>
    <div
      *ngSwitchCase="bodyOutputType.TrustedHtml"
      [innerHTML]="toast.body | trustHtml"
    ></div>
    <div *ngSwitchCase="bodyOutputType.Default">{{ toast.body }}</div>
  </div>
</div>
<button
  class="toast-close-button"
  type="button"
  *ngIf="toast.showCloseButton"
  (click)="click($event, toast)"
  [innerHTML]="toast.closeHtml | trustHtml"
  [attr.aria-label]="toasterConfig.closeButtonAriaLabel"
>
</button>
<div *ngIf="toast.progressBar">
  <div
    class="toast-progress-bar"
    [style.width]="progressBarWidth + '%'"
  ></div>
</div>
