import { Pipe, PipeTransform, QueryList } from '@angular/core';

import { TabComponent } from './tab/tab.component';

@Pipe({
  name: 'filterTabs',
  pure: false,
})
export class FilterTabsPipe implements PipeTransform {
  transform(tabs: QueryList<TabComponent>): TabComponent[] {
    return tabs?.filter((tab) => tab.enabled ?? true);
  }
}
