import { NgModule } from '@angular/core';

import { InactivityLogoutInitializerService } from './initializer/inactivity-logout-initializer.service';
import { InactivityLogoutMangerService } from './manager/inactivity-logout-manager.service';
import { InactivityTimerService } from './timer/inactivity-timer.service';

@NgModule({
  providers: [
    InactivityLogoutInitializerService,
    InactivityLogoutMangerService,
    InactivityTimerService,
  ],
})
export class InactivityLogoutModule {}
