<div
  role="application"
  data-testid="tooltip-icon-component"
>
  <div
    #tooltipScreenReaderButton
    tabindex="0"
    role="button"
    [attr.aria-label]="iconAriaDesc || 'tooltip'"
    (keydown.enter)="activateTooltipInScreenReader($event)"
    (keydown.escape)="deactivateTooltipInScreenReader($event, tooltipScreenReaderButton)"
  >
    <div
      #tooltipIconContainer
      ngpTooltip="{{ tooltipKey | translate }}"
      [ngpTooltipPosition]="getCurrentDir()"
      [attr.testid]="tooltipTestId + '-tooltip'"
    >
      <ngp-svg-icon
        *ngIf="iconName"
        [iconName]="iconName"
        [ngClass]="iconClasses"
      ></ngp-svg-icon>
      <span
        *ngIf="!iconName"
        class="icon-symbol"
        >{{ iconSymbol }}</span
      >
    </div>
  </div>
</div>
