import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { InputFileStatus, InputFileStatusService } from '@demica/core/core';

@Component({
  selector: 'trf-header-missing-files',
  templateUrl: './header-missing-files.component.html',
})
export class HeaderMissingFilesComponent implements OnInit, OnDestroy {
  @Output()
  loaded = new EventEmitter<boolean>();

  COUNTER_MAX = 99;

  loading = true;

  missingFilesCounter: string;

  filters = {};

  private destroy$ = new Subject<void>();

  constructor(private inputFileStatusService: InputFileStatusService) {}

  ngOnInit() {
    combineLatest([
      this.inputFileStatusService.allInputFilesStatus$,
      this.inputFileStatusService.inputFileStatus$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([allInputFiles, inputFiles]) => {
        this.missingFilesCounter = this.getNumberOfMissingFiles(inputFiles, allInputFiles);

        this.loading = false;
        this.loaded.emit(true);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getNumberOfMissingFiles(
    inputFilesStatus: InputFileStatus[],
    allInputFilesStatus: InputFileStatus[],
  ): string {
    const numberOfMissingFiles = inputFilesStatus.filter(
      (inputFileStatus) => !inputFileStatus.present,
    ).length;
    const numberOfAllInputFilesStatus = allInputFilesStatus.length;

    return `${numberOfMissingFiles} / ${numberOfAllInputFilesStatus}`;
  }
}
