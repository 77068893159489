import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, pairwise, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { ApplicationEventService } from '../../../application-events/application-events.service';
import { UserService } from '../../../user.service';
import { UserDetailsComparator } from '../user-details-comparator.service';

import { UserAuthContextChangeReason } from '../user-auth-context-change.reason';
import { AuthContextChangeEventSourceInterface } from './auth-context-change-event-source.interface';

@Injectable({
  providedIn: 'root',
})
export class ExternalChangeEventSourceService implements AuthContextChangeEventSourceInterface {
  constructor(
    private _userService: UserService,
    private _userAuthContextComparator: UserDetailsComparator,
    private _applicationEventService: ApplicationEventService,
  ) {}

  getEvents$(): Observable<UserAuthContextChangeReason> {
    return this._userService.currentUser.pipe(
      takeUntil(this._applicationEventService.applicationKillEvent$),
      startWith(null),
      pairwise(),
      filter(([prev, current]) => !!prev && !!current),
      switchMap(([prev, current]) =>
        this._userAuthContextComparator.getUsersContextDiff$(prev, current),
      ),
      filter((reason) => !!reason),
    );
  }
}
