import { TranslateService } from '@ngx-translate/core';

import { notEmpty } from '@demica/core/core';

import { ChartType } from '../../model/chart-type';
import { ChartConfiguration, LegendTooltip } from './chart-configuration';
import { AxisY } from './configuration/axis-y';
import { LineChartValues } from './line-chart-values';
import { VerticalAxisLineChartValues } from './vertical-axis-line-chart-values';
import { LegendOptions, XTickConfiguration, YAxisConfiguration, YTickConfiguration } from 'c3';

export class LineChartData {
  constructor(
    private configuration: ChartConfiguration,
    private translateService: TranslateService,
  ) {}

  getY2AxisConfiguration(): YAxisConfiguration {
    if (this.configuration.axisY2) {
      return this.translateAxis(this.configuration.axisY2.getAxisYConfiguration());
    }

    return AxisY.createNoneAxisY();
  }

  getYAxisConfiguration(): YAxisConfiguration {
    if (this.configuration.axisY) {
      return this.translateAxis(this.configuration.axisY.getAxisYConfiguration());
    }

    return AxisY.createNoneAxisY();
  }

  getXAxisValues(): VerticalAxisLineChartValues[] {
    return this.configuration.xAxisConfiguration.xAxisValues;
  }

  getAxisType(): string {
    return this.configuration.axisType ? this.configuration.axisType : 'timeseries';
  }

  getHiddenCharts() {
    return this.configuration.lineChartValues
      .filter((lineChart) => !lineChart.showInLegend)
      .map((lineChart) => lineChart.name);
  }

  getChartValues(): string[][] {
    let chartValues = this.configuration.lineChartValues.map((lineCharValue) =>
      lineCharValue.getData(),
    );
    if (this.getXAxisValues()) {
      this.getXAxisValues().forEach((x) => (chartValues = [x.getData()].concat(chartValues)));
    }
    if (this.hasAdditionalAxisValuesDefined()) {
      chartValues = [
        this.configuration.yAxisAdditionalConfiguration.yAxisAdditionalValues.getData(),
      ].concat(chartValues);
    }
    return chartValues.sort((a, b) => this.orderLabels(a[0], b[0]));
  }

  getMaxChartValue(): number {
    if (this.configuration.groupChartValues && this.configuration.lineChartValues.length > 0) {
      let maxSum = 0;
      for (let i = 0; i < this.configuration.lineChartValues[0].values.length; i++) {
        let currentSum = 0;

        for (let j = 0; j < this.configuration.lineChartValues.length; j++) {
          currentSum += Number(this.configuration.lineChartValues[j].values[i]);
        }

        if (currentSum > maxSum) {
          maxSum = currentSum;
        }
      }
      return maxSum;
    }

    return Math.max(
      ...this.configuration.lineChartValues.map((dataSeries) =>
        Math.max(
          ...dataSeries
            .getData()
            .slice(1)
            .map((value) => Number(value)),
        ),
      ),
    );
  }

  getChartValueNames(): string[] {
    const chartValueNames = this.configuration.lineChartValues
      .filter((lineChartValue) => !lineChartValue.excludedFromGroup)
      .map((lineChartValue) => lineChartValue.name);
    if (this.getXAxisValues()) {
      this.getXAxisValues().forEach((x) => [x.name].concat(chartValueNames));
    }
    return chartValueNames;
  }

  hasVerticalAxisValuesDefined() {
    return this.configuration.xAxisConfiguration.xAxisValues != null;
  }

  getBarValuesLength() {
    const barValuesLength = this.configuration.lineChartValues
      .filter((lineChartValue) => lineChartValue.chartType === ChartType.BAR)
      .map((value) => value.values.length);

    return barValuesLength ? Math.max(...barValuesLength) : 0;
  }

  getChartValueGroups() {
    return this.configuration.groupChartValues ? this.getChartValueNames() : [];
  }

  orderLabels(firstLabel: string, secondLabel: string) {
    if (firstLabel === secondLabel) return 0;

    for (let i = 0; i < this.configuration.lineChartValues.length; i++) {
      if (this.configuration.lineChartValues[i].name === firstLabel) return -1;
      else if (this.configuration.lineChartValues[i].name === secondLabel) return 1;
      else if (firstLabel === 'y2') return 1;
      else if (secondLabel === 'y2') return -1;
    }

    return 0;
  }

  hasAdditionalAxisValuesDefined(): boolean {
    return (
      !!this.configuration.yAxisAdditionalConfiguration &&
      !!this.configuration.yAxisAdditionalConfiguration.yAxisAdditionalValues
    );
  }

  getCustomOrder() {
    return this.configuration.order;
  }

  private hasYAxisLinesDefined() {
    return this.configuration.yAxisLines !== undefined;
  }

  getYAxisLines() {
    return this.hasYAxisLinesDefined() ? this.configuration.yAxisLines : [];
  }

  getChartName() {
    return this.configuration.chartName;
  }

  setChartName(chartName: string) {
    this.configuration.chartName = chartName;
  }

  getTooltipLabelOrder() {
    if (this.configuration.tooltip.orderByIds) {
      return this.configuration.tooltip.orderByIds;
    }

    return this.configuration.tooltip.order
      ? this.configuration.tooltip.order
      : this.configuration.tooltipOrderByName
      ? (label1: LineChartValues, label2: LineChartValues) => (label1.name > label2.name ? 1 : 0)
      : undefined;
  }

  getLineChartValues() {
    return this.configuration.lineChartValues;
  }

  showChartLinePoints() {
    return this.configuration.showPoints;
  }

  getXAxisLabel() {
    return this.translate(this.configuration.xAxisConfiguration.xAxisLabel);
  }

  showYAxisGridLines() {
    return this.configuration.showYAxisGridLines;
  }

  showXAxisGridLines() {
    return this.configuration.showXAxisGridLines;
  }

  getYAxisAdditionalLabel() {
    return this.translate(this.configuration.yAxisAdditionalConfiguration.yAxisAdditionalLabel);
  }

  getYAxisAdditionalLabelWithOutTranslation() {
    return this.configuration.yAxisAdditionalConfiguration.yAxisAdditionalLabel;
  }

  getChartType() {
    return this.configuration.chartType;
  }

  hasDataAvailable(): boolean {
    return this.hasData('every');
  }

  hasSomeDataAvailable(): boolean {
    return this.hasData('some') || this.hasAdditionalDataAvailable();
  }

  getLegend(): LegendOptions {
    const legend = this.configuration.legend;
    return {
      show: legend ? legend.show : this.showLegend(),
      position: legend && legend.position ? legend.position : 'bottom',
    };
  }

  showLegend(): boolean {
    return (
      this.configuration.lineChartValues.length +
        (this.hasAdditionalAxisValuesDefined()
          ? this.configuration.yAxisAdditionalConfiguration.yAxisAdditionalValues.getData().length
          : 0) >
      1
    );
  }

  getXAxesTick(): XTickConfiguration {
    if (!this.configuration.xAxisConfiguration.xAxesTick) {
      return {};
    }

    const xAxesTick = this.configuration.xAxisConfiguration.xAxesTick;

    return {
      fit: xAxesTick.fit ? xAxesTick.fit : true,
      rotate: xAxesTick.rotate ? xAxesTick.rotate : 0,
      multiline: xAxesTick.multiline ? xAxesTick.multiline : false,
      format: xAxesTick.format,
      culling: {
        max: 32,
      },
    };
  }

  getY2AxesTick(): YTickConfiguration {
    if (
      !this.configuration.yAxisAdditionalConfiguration ||
      !this.configuration.yAxisAdditionalConfiguration.y2AxesTick
    ) {
      return {};
    }

    return {
      format: this.configuration.yAxisAdditionalConfiguration.y2AxesTick.format,
    };
  }

  getDataCustomColors(defaultColors: string[]): Record<string, string> {
    const ids: string[] = this.getChartValues()
      .map((values) => values[0])
      .filter((item) => item !== 'x')
      .map((item) => this.translate(item));

    const colors = this.hasDataCustomColors()
      ? [...this.configuration.customColors]
      : [...defaultColors];
    const maxColorCount = colors.length - 1;
    let colorIndex = 0;

    const result: Record<string, string> = {};
    ids.forEach((id) => {
      if (colorIndex > maxColorCount) {
        colorIndex = 0;
      }
      result[id] = colors[colorIndex++];
    });

    return result;
  }

  getHidden(): string[] {
    return this.getChartValues()
      .filter((values) => values.length === 1)
      .map((values) =>
        values[0] === 'y2' ? this.getYAxisAdditionalLabelWithOutTranslation() : values[0],
      );
  }

  hasDataCustomColors(): boolean {
    return this.configuration.customColors !== undefined;
  }

  getLegendTooltip(): LegendTooltip {
    if (!this.configuration.legendTooltip) {
      return undefined;
    }

    return this.configuration.legendTooltip;
  }

  hasLegendTooltip(): boolean {
    return this.configuration.legendTooltip !== undefined;
  }

  getTooltipOptions() {
    return {
      value: (value: number, ratio?: number, id?: string, index?: number) =>
        this.configuration.tooltip.value(value, ratio, id, index),
      title: (v: string) => this.configuration.tooltip.title(v),
    };
  }

  getLabelsNames() {
    const obj: { [key: string]: string } = {};
    this.configuration.lineChartValues.forEach((o) => {
      obj[o.name] = o.label ?? o.name.toLowerCase();
    });

    return obj;
  }

  getOnRenderedCallback() {
    return this.configuration.onRendered;
  }

  getColors() {
    return this.configuration.colors;
  }

  private hasAdditionalDataAvailable(): boolean {
    if (!this.hasAdditionalAxisValuesDefined()) {
      return false;
    }

    return this.configuration.yAxisAdditionalConfiguration.yAxisAdditionalValues.values.length > 0;
  }

  private translate(value: string): string {
    return value ? this.unboxSuffixWithTranslation(value) : value;
  }

  private unboxSuffixWithTranslation(value: string): string {
    const suffixIndex = value.lastIndexOf(' [');
    if (suffixIndex > -1) {
      return this.translate(value.slice(0, suffixIndex)) + value.slice(suffixIndex);
    } else {
      return this.translateService.instant(value);
    }
  }

  private translateAxis(axis: YAxisConfiguration): YAxisConfiguration {
    if (typeof axis.label === 'string') {
      axis.label = this.unboxSuffixWithTranslation(axis.label);
    } else {
      axis.label.text = this.unboxSuffixWithTranslation(axis.label.text);
    }
    return axis;
  }

  getColorGenerator() {
    return this.configuration.colorGenerator;
  }

  getCustomTooltip() {
    return this.configuration.tooltip.tooltipContentFunction;
  }

  private hasData(compareType: 'some' | 'every'): boolean {
    const values = this.configuration.lineChartValues;
    return notEmpty(values) ? values.map((v) => v.values.length > 0)[compareType]((v) => v) : false;
  }
}
