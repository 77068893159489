import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { toData } from '../../../../service/rest/response-mapping';

import { EntityId, HasEntityId } from '../../../../interface/has-entity-id.interface';
import { RestResponse } from '../../../../model/response.interface';
import { NameAvailabilityResponse } from '../../../../model/rest-response/availability-response';
import { encodeParams } from '../../../../security/encode-params';
import { encodeEndpoint } from '../../../../security/encode-url';
import { FileFormatColumnType } from '../model/file-format-column-type.interface';
import { FileFormatInheritedColumnType } from '../model/file-format-inherited-column-type.interface';
import {
  TemplateFileFormatPayload,
  TemplateFileFormat,
} from '../model/template-file-format.interface';

@Injectable({
  providedIn: 'root',
})
export class FileFormatTemplateResourceService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  getTemplates() {
    const url = encodeEndpoint('resources/templates/file-formats');
    return this.http.get<RestResponse<TemplateFileFormat[]>>(url).pipe(map(toData));
  }

  getTemplatesForTransaction(transactionId: EntityId) {
    const params = encodeParams({ transactionId });
    const url = encodeEndpoint('resources/templates/file-formats');
    return this.http.get<RestResponse<TemplateFileFormat[]>>(url, { params }).pipe(map(toData));
  }

  getTemplate(id: EntityId) {
    const url = encodeEndpoint('resources/templates/file-formats/{}', id);
    return this.http.get<RestResponse<TemplateFileFormat>>(url).pipe(map(toData));
  }

  postTemplate(template: TemplateFileFormatPayload) {
    const url = encodeEndpoint('resources/templates/file-formats');
    return this.http
      .post<RestResponse<TemplateFileFormat>>(url, { ...template, entityRevision: 0 })
      .pipe(map(toData));
  }

  putTemplate(id: EntityId, entityRevision: number, template: TemplateFileFormatPayload) {
    const url = encodeEndpoint('resources/templates/file-formats/{}', id);
    return this.http
      .put<RestResponse<TemplateFileFormat>>(url, { ...template, entityRevision })
      .pipe(map(toData));
  }

  deleteTemplate(id: EntityId, entityRevision: number) {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint('resources/templates/file-formats/{}', id);
    return this.http.delete<RestResponse<HasEntityId>>(url, { params }).pipe(map(toData));
  }

  checkTemplateNameAvailable(name: string, templateId?: EntityId) {
    const params = encodeParams({ name, templateId });
    const url = encodeEndpoint('resources/templates/file-formats/name-availability');
    return this.http.get<RestResponse<NameAvailabilityResponse>>(url, { params }).pipe(map(toData));
  }

  getColumnDefinitionTypes(templateId?: EntityId, isLimitsFileFormat?: boolean) {
    const params = encodeParams({ templateId });
    const url = encodeEndpoint('resources/templates/file-formats/column-types');
    const translationColumnDefinitions = isLimitsFileFormat
      ? 'LIMITS_COLUMN_DEFINITION_TYPES.'
      : 'COLUMN_DEFINITION_TYPES.';
    return this.http.get<RestResponse<FileFormatColumnType[]>>(url, { params }).pipe(
      map(toData),
      map((columnDefinitions) =>
        columnDefinitions.map((definition) => ({
          entityId: definition.entityId,
          key: definition.displayName ? undefined : definition.entityId,
          isDefinedByUser: !!definition.displayName,
          displayName: definition.displayName
            ? definition.displayName
            : this.translate.instant(translationColumnDefinitions + definition.entityId),
          name: definition.displayName
            ? definition.displayName
            : this.translate.instant(translationColumnDefinitions + definition.entityId),
          supportedVariant: definition.supportedVariant,
          analysisGroup: definition.analysisGroup,
        })),
      ),
    );
  }

  getInheritedColumnDefinitionTypes(templateId?: EntityId) {
    const params = encodeParams({ templateId });
    const url = encodeEndpoint('resources/templates/file-formats/inherited-column-types');
    return this.http.get<RestResponse<FileFormatInheritedColumnType[]>>(url, { params }).pipe(
      map(toData),
      map((columnDefinitions) =>
        columnDefinitions.map((definition) => ({
          entityId: definition.entityId,
          key: definition.displayName ? undefined : definition.entityId,
          name: definition.displayName
            ? definition.displayName
            : this.translate.instant('COLUMN_DEFINITION_TYPES.' + definition.entityId),
          displayName: definition.displayName
            ? definition.displayName
            : this.translate.instant('COLUMN_DEFINITION_TYPES.' + definition.entityId),
        })),
      ),
    );
  }
}
