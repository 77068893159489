import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AliasesResourceService,
  AliasMissingMapping,
  AnalysisCodeReference,
  CountriesService,
  CurrenciesService,
  CurrencyCode,
  DictionaryEntry,
  EntityId,
  maxUserDefinedColumnLength,
  ProposedAlias,
  SlideinContainerAbstractClass,
} from '@demica/core/core';

import { requireSelect } from '../../../../forms/validators';
import {
  COUNTRY_COLUMN_ID,
  CURRENCY_COLUMN_ID,
  inactiveAnalysisGroups,
} from '../../../../model/alias/inactive-analysis-groups-constants';
import { AliasFormValidations } from '../../../../validation/alias-form-validations';

export type AliasesMissingForm = FormGroup<{
  analysisCodeId: FormControl<AnalysisCodeReference>;
  enterAnalysisCode: FormControl<string>;
}>;

@Component({
  selector: 'trf-aliases-missing-slidein',
  templateUrl: './aliases-missing-slidein.container.html',
  styleUrls: ['./aliases-missing-slidein.container.sass'],
})
export class AliasesMissingSlideinContainerComponent
  extends SlideinContainerAbstractClass
  implements OnInit, OnDestroy
{
  @Input()
  aliasMissingMapping!: AliasMissingMapping;
  @Input()
  transactionId: EntityId;
  @Input()
  previewMode? = false;

  @Output()
  enterAnalysisCode = new EventEmitter<string>();

  analysisCodes: AnalysisCodeReference[];
  titleParams: Record<string, string>;
  isAnalysisCodeActive = true;
  currencyCodes: CurrencyCode[];
  countries: DictionaryEntry[];

  loading = true;
  submitted: boolean;

  form: AliasesMissingForm = this._fb.group({
    analysisCodeId: [null as AnalysisCodeReference, [requireSelect]],
    enterAnalysisCode: [null],
  });

  requireValidationsMessages = this._aliasFormValidations.requireValidationsMessages(
    this.form,
    () => this.submitted,
  );
  maxLengthValidationsMessages = this._aliasFormValidations.maxLengthValidationsMessages(
    this.form,
    () => this.submitted,
  );

  readonly maxLength = maxUserDefinedColumnLength;
  private _destroyed$ = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _aliasFormValidations: AliasFormValidations,
    private _aliasesResourceService: AliasesResourceService,
    private _countriesService: CountriesService,
    private _currenciesService: CurrenciesService,
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this._currenciesService.getCurrencyCodes(),
      this._countriesService.countriesAsSelectOptions(),
      this._aliasesResourceService.getAnalysisCodesReferences(
        this.transactionId,
        this.aliasMissingMapping.analysisGroup.entityId,
      ),
    ]).subscribe(([currency, countries, analysisCodes]) => {
      this.currencyCodes = currency;
      this.countries = countries.filter((country) => country.name);
      this.analysisCodes = this._returnProperAnalysisCodes(analysisCodes);
      this.loading = false;
    });

    this.titleParams = { value: this.aliasMissingMapping.value };

    this.form.controls.analysisCodeId.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.form.controls.enterAnalysisCode.setValue(null));

    this.isAnalysisCodeActive = !inactiveAnalysisGroups.includes(
      this.aliasMissingMapping.analysisGroup.entityId as number,
    );
  }

  canAdd(): boolean {
    const enterAnalysisCodeControl = this.form.controls.enterAnalysisCode;

    return enterAnalysisCodeControl.valid && enterAnalysisCodeControl.getRawValue()?.trim() !== '';
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  addAnalysisCode(): void {
    if (!this.canAdd()) {
      return;
    }
    const enterAnalysisCodeControl = this.form.controls.enterAnalysisCode;
    const addedAnalysisCode = enterAnalysisCodeControl.getRawValue().trim();
    this._addAnalysisCodeValue(addedAnalysisCode);
    enterAnalysisCodeControl.setValue(null);
    enterAnalysisCodeControl.markAsUntouched();
  }

  onSave(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = this._preparePayload(this.form.controls.analysisCodeId.getRawValue());
    this._aliasesResourceService.addMappings({ proposedAliases: [payload] }).subscribe(() => {
      this.modal.close();
    });
  }

  private _returnProperAnalysisCodes(
    analysisCodes: AnalysisCodeReference[],
  ): DictionaryEntry[] | CurrencyCode[] | AnalysisCodeReference[] {
    const analysisGroupEntityId = this.aliasMissingMapping.analysisGroup.entityId;

    switch (analysisGroupEntityId) {
      case COUNTRY_COLUMN_ID:
        return this.countries;
      case CURRENCY_COLUMN_ID:
        return this.currencyCodes;
      default:
        return analysisCodes.filter((missing) => missing.columnTypeId === analysisGroupEntityId);
    }
  }

  private _preparePayload(analysisCode: AnalysisCodeReference): ProposedAlias {
    return {
      transactionId: this.transactionId,
      dataSourceId: this.aliasMissingMapping.dataSource.entityId,
      columnTypeId: this.aliasMissingMapping.analysisGroup.entityId,
      environmentId: this.aliasMissingMapping.environment.entityId,
      value: this.aliasMissingMapping.value,
      proposedAnalysisCodeValue: this.isAnalysisCodeActive
        ? analysisCode.name
        : (analysisCode.entityId as string),
      appearanceDate: this.aliasMissingMapping.appearanceDate,
    };
  }

  private _addAnalysisCodeValue(addedAnalysisCode: string): void {
    const existingCode = this.analysisCodes.find((code) => code.name === addedAnalysisCode);
    if (existingCode) {
      this.form.controls.analysisCodeId.setValue(existingCode);
    } else {
      const addedAnalysisCodeItem = { entityId: null as EntityId, name: addedAnalysisCode };
      this.analysisCodes.push(addedAnalysisCodeItem);
      this.form.controls.analysisCodeId.setValue(addedAnalysisCodeItem);
    }
  }
}
