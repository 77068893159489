import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngp-inline-loader',
  templateUrl: 'inline-loader.component.html',
  styleUrls: ['./inline-loader.component.sass'],
})
export class InlineLoaderComponent {
  @Input()
  loading = false;
}
