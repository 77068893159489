import { CdkDragDrop, CdkDragExit } from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  Component,
  ComponentRef,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { Subject } from 'rxjs';

import {
  DndComponentConfiguration,
  DndNestedElement,
  DndNestedRowComponent,
} from '../../../model/drag-and-drop-nested.model';

@Component({
  selector: 'ngp-drag-and-drop-nested-item',
  templateUrl: './drag-and-drop-nested-item.component.html',
})
export class DragAndDropNestedItemComponent<T extends DndNestedElement>
  implements AfterContentInit
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('onDragDrop')
  onDragDrop$!: Subject<CdkDragDrop<T[]>>;
  @Input()
  item!: T;
  @Input()
  nestingLevel!: number;
  @Input()
  previewMode!: boolean;
  @Input()
  parentName!: string | undefined;
  @Input()
  containerTranslation!: string | undefined;
  @Input()
  styleData!: {
    width: string;
    'grid-template-columns': string;
    'grid-column-end': string;
  };
  @Input()
  rowConfiguration!: DndComponentConfiguration;

  @ViewChild('row', { read: ViewContainerRef, static: true }) row!: ViewContainerRef;

  ngAfterContentInit(): void {
    const rowRef: ComponentRef<DndNestedRowComponent<T>> = this.row.createComponent(
      this.rowConfiguration.component,
    );
    const instance = rowRef.instance;
    instance.row = this.item;
    instance.parentName = this.parentName || this.containerTranslation;
    rowRef.location.nativeElement.className = 'ngp-dnd-row-element';

    Object.assign(rowRef.instance, this.rowConfiguration.inputs);
  }

  addDraggingStyles($event: CdkDragExit<T>) {
    const containerList = $event?.container?.element?.nativeElement?.children;
    const lastElement = Array.from(containerList).pop();
    const items = lastElement?.querySelectorAll(':scope > ngp-drag-and-drop-nested-item');

    if (items) {
      const preLastItem = Array.from(items).splice(items.length - 2, items.length - 1);
      preLastItem[0]?.classList?.add('ngp-pre-dragging');
    }
  }
}
