import { Injectable } from '@angular/core';

import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { RuntimeTranslationsKeysService } from '../runtime-translations-keys/runtime-translations-keys.service';

@Injectable({ providedIn: 'root' })
export class MissingTranslationService implements MissingTranslationHandler {
  constructor(private runtimeTranslationsKeysService: RuntimeTranslationsKeysService) {}
  handle(params: MissingTranslationHandlerParams): string {
    this.runtimeTranslationsKeysService.setTranslateService(params.translateService);
    return this.runtimeTranslationsKeysService.get(params.key) ?? params.key;
  }
}
