import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PageResponse } from '@demica/core/core';

@Component({
  selector: 'trf-page-number',
  templateUrl: './page-number.component.html',
  styleUrls: ['./page-number.component.sass'],
})
export class PageNumberComponent {
  @Input()
  pageResponse: PageResponse;

  @Output()
  pageChange = new EventEmitter<number>();

  onPageChange(pageNumber: number) {
    this.pageChange.emit(pageNumber);
  }
}
