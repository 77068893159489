<div
  *ngIf="!!notifications?.entries?.length || !!type"
  class="context-notification"
>
  <ng-template
    [ngIfElse]="contentProjection"
    [ngIf]="!type"
  >
    <ng-container *ngFor="let entry of notifications.entries">
      <ngp-contextual-notification-body [type]="entry.type">
        {{ entry.key | translate: entry.variables }}
      </ngp-contextual-notification-body>
    </ng-container>
  </ng-template>

  <ng-template #contentProjection>
    <ngp-contextual-notification-body
      [expandableHeader]="expandableHeader"
      [expandable]="expandable"
      [type]="type!"
    >
      <ng-content></ng-content>
    </ngp-contextual-notification-body>
  </ng-template>
</div>
