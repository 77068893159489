import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import type { TabComponent } from './tab/tab.component';

@Injectable()
export class TabsService {
  activatedTab$ = new Subject<TabComponent>();

  markAsActive(tab: TabComponent) {
    this.activatedTab$.next(tab);
  }
}
