<div>
  <div
    *ngIf="!!label"
    class="column-label"
    [ngClass]="{ 'row-disabled': control.disabled }"
  >
    <label
      [for]="name"
      [class]="labelClasses"
      >{{ label | translate }}</label
    >
  </div>
  <div
    class="column-input input-box"
    [id]="uniqueId"
  >
    <div class="input-wrapper">
      <trf-date-time-picker
        [id]="formControlName"
        [formControl]="control"
        [attr.name]="formControlName"
        [attr.aria-required]="labelClasses?.includes('required')"
        [attr.aria-describedby]="formControlName + '_notification'"
      ></trf-date-time-picker>

      <div class="validation-error">
        <ngp-inline-notification
          [id]="formControlName + '_notification'"
          [message]="validations | firstError: formControlName"
        >
        </ngp-inline-notification>
      </div>
    </div>
  </div>
</div>
