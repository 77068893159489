export enum UserActivityType {
  REQUEST = 'REQUEST',
  CHANGE_ROUTE = 'CHANGE_ROUTE',
}

export interface UserActivity {
  activityType: UserActivityType;
  data: unknown;
}

export interface UserActivityAudit extends UserActivity {
  createdAt: number | string;
}
