<button
  class="{{ type ? 'ngp-btn-' + type : 'ngp-btn' }} {{ classes }}"
  type="button"
  [disabled]="disabled"
  [attr.tabindex]="disabled ? '-1' : '0'"
>
  <ngp-svg-icon
    *ngIf="icon"
    [iconName]="icon"
    (click)="preventClickEvent($event)"
  ></ngp-svg-icon>
  <span
    *ngIf="key"
    (click)="preventClickEvent($event)"
    >{{ key | translate }}</span
  >
</button>
