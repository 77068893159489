import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EntityId } from '@demica/resources/common';

@Injectable()
export abstract class IdProvider {
  abstract id$: Observable<EntityId | null>;

  abstract getId(): EntityId | null;
}
