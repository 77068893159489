import { Injectable } from '@angular/core';

import { ExtendedConnectedPosition, NgpTooltipPosition } from '@demica/ui-core-api';

import { CssVariableService } from './css-variable.service';

import { buildPositions } from '../utils/tooltip-positions.utils';

export const DEFAULT_OFFSET = 10;
const TOOLTIP_MARGIN_VARIABLE_NAME = '--ngp-tooltip-margin';

@Injectable({ providedIn: 'root' })
export class TooltipPositionUtilsService {
  offset = this.setOffset();
  positions: Record<NgpTooltipPosition, ExtendedConnectedPosition> = this.buildPositions();

  constructor(private cssVariableService: CssVariableService) {
    this.offset = this.setOffset();
    this.positions = this.buildPositions();
  }

  buildPositions(): Record<NgpTooltipPosition, ExtendedConnectedPosition> {
    return buildPositions(this.offset);
  }

  private setOffset() {
    let offset: number = DEFAULT_OFFSET;
    try {
      const offsetFromCssVar = parseInt(this.cssVariableService.get(TOOLTIP_MARGIN_VARIABLE_NAME));
      if (!isNaN(offsetFromCssVar)) offset = offsetFromCssVar;
    } catch (e) {
      return DEFAULT_OFFSET;
    }
    return offset;
  }
}
