import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NotAvailableResourceComponent } from '@demica/components';
import { NOT_AVAILABLE_RESOURCE, TRFRoutes } from '@demica/core/core';

import { NoAccessViewComponent } from './feature-modules/no-access/view/no-access-view.component';

import { LayoutAuthComponent } from './pages/auth/auth';
import { diagnosticRoutes } from './routes/diagnostic.routes';
import { insurersRoutes } from './routes/insurers.routes';
import { onboardingRoutes } from './routes/onboarding.routes';

export const TRANSACTIONS = 'transactions';
export const DATA_UPLOADS = 'data-uploads';
export const DASHBOARD = 'dashboard';

const routes: TRFRoutes = [
  {
    children: [
      {
        path: DASHBOARD,
        loadChildren: () =>
          import('./feature-modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: DATA_UPLOADS,
        loadChildren: () =>
          import('./feature-modules/data-uploads/data-uploads.module').then(
            (m) => m.DataUploadsModule,
          ),
      },
      {
        path: 'technical-data',
        loadChildren: () =>
          import('./feature-modules/technical-data/technical-data.module').then(
            (m) => m.TechnicalDataModule,
          ),
      },
      {
        path: 'funder-groups',
        loadChildren: () =>
          import('./feature-modules/funder-groups/funder-groups.module').then(
            (m) => m.FunderGroupsModule,
          ),
      },
      ...insurersRoutes,
      {
        path: 'templates',
        loadChildren: () =>
          import('./feature-modules/templates/templates.module').then((m) => m.TemplatesModule),
      },
      ...onboardingRoutes,
      {
        path: TRANSACTIONS,
        loadChildren: () =>
          import('./feature-modules/transactions/transactions.module').then(
            (m) => m.TransactionsModule,
          ),
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('./feature-modules/clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: 'sellers',
        loadChildren: () =>
          import('./feature-modules/sellers/sellers.module').then((m) => m.SellersModule),
      },
      {
        path: 'funders',
        loadChildren: () =>
          import('./feature-modules/funders/funders.module').then((m) => m.FundersModule),
      },
      {
        path: 'current-region',
        loadChildren: () =>
          import('./feature-modules/current-region/current-region.module').then(
            (m) => m.CurrentRegionModule,
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./feature-modules/administration/administration.module').then(
            (m) => m.AdministrationModule,
          ),
      },
      ...diagnosticRoutes,
      {
        path: 'alerts',
        loadChildren: () =>
          import('./feature-modules/alerts/alerts.module').then((m) => m.AlertsModule),
      },
      {
        path: 'no-access',
        component: NoAccessViewComponent,
      },
      {
        path: NOT_AVAILABLE_RESOURCE,
        component: NotAvailableResourceComponent,
      },
      {
        path: 'organization-unit-change',
        loadChildren: () =>
          import('./feature-modules/organization-unit-change/organization-unit-change.module').then(
            (m) => m.OrganizationUnitChangeModule,
          ),
      },
      {
        path: 'ui-kit',
        loadChildren: () =>
          import('./feature-modules/ui-kit/ui-kit.module').then((m) => m.UiKitModule),
      },
      {
        path: '**',
        redirectTo: DASHBOARD,
      },
    ],
    component: LayoutAuthComponent,
    path: '',
  },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes);
