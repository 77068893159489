import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  EntityId,
  EntityReference,
  Environment,
  EnvironmentResourceService,
  HasEntityId,
  RelatedSubjects,
  Version,
} from '@demica/core/core';

import { hasRequiredError } from '../../forms/validation-messages/message-predicates';
import { SubmittedProvider } from '../../forms/validation-messages/message-typedefs';
import { requireSelect } from '../../forms/validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'trf-new-push-request-modal',
  templateUrl: 'new-push-request-modal.component.html',
  styleUrls: ['./new-push-request-modal.component.sass'],
})
export class NewPushRequestModalComponent implements OnInit, OnDestroy {
  @Input()
  pushAction: (any: unknown) => Observable<unknown>;
  @Input()
  environmentOptions: HasEntityId[] = [];
  @Input()
  version: Version;
  @Input()
  loading: boolean;

  @Input()
  set relatedSubjects(value: RelatedSubjects[]) {
    this.internalRelatedSubjects = value;
    this.buildErrorMessage();
  }

  @Output()
  selectedEnvironment = new EventEmitter<Environment>();

  form: UntypedFormGroup = this._fb.group({
    environment: [null, [requireSelect]],
  });

  submitted = false;
  requireValidations = requireValidations(this.form, () => this.submitted);
  validationErrors: Set<EntityId> = new Set();
  internalRelatedSubjects: RelatedSubjects[] = [];

  loadingEnvironments = true;
  destroyed$ = new Subject<void>();
  private _pushInProgress: boolean;

  constructor(
    private _fb: UntypedFormBuilder,
    private _modal: NgbActiveModal,
    private _environmentResource: EnvironmentResourceService,
  ) {}

  ngOnInit(): void {
    const environments$ = this._environmentResource.getPushEligibleEnvironments(
      this.version.entityId,
    );

    environments$.subscribe((environmentOptions) => {
      this.environmentOptions = environmentOptions;
      this.loadingEnvironments = false;
    });

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      if (value.environment != null) {
        this.loading = true;
        this.selectedEnvironment.emit(value.environment);
      } else {
        this.internalRelatedSubjects = [];
      }
      this.validationErrors = new Set();
    });
  }

  errorsExists(): boolean {
    return !!this.validationErrors.size;
  }

  buildErrorMessage(): void {
    const mainObjectValidationError: EntityReference =
      this.form.get('environment').value.validationError;
    const validationErrors = this.internalRelatedSubjects
      .filter((subject) => !!subject.validationError)
      .map((subject) => subject.validationError.entityId);

    if (mainObjectValidationError) {
      validationErrors.push(mainObjectValidationError.entityId);
    }

    this.validationErrors = new Set(validationErrors);
  }

  onSave(): void {
    if (this._pushInProgress) return;
    if (!this.errorsExists()) {
      this.submitted = true;

      if (this.form.valid) {
        this._pushInProgress = true;
        const data = {
          environmentId: this.form.value.environment.entityId,
          versionId: this.version.entityId,
        };
        this.pushAction(data).subscribe({
          next: () => this._modal.dismiss(),
          error: (e) => console.error(e),
          complete: () => (this._pushInProgress = false),
        });
      }
    }
  }

  onClose(): void {
    this._modal.dismiss();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}

function requireValidations(form: UntypedFormGroup, submittedFunc: SubmittedProvider) {
  const required = hasRequiredError(form, submittedFunc);

  return [{ func: required, key: 'VALIDATION.FIELD_REQUIRED' }];
}
