import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'trf-form-text-area',
  templateUrl: 'form-text-area.component.html',
  styleUrls: ['./form-text-area.component.sass'],
})
export class FormTextAreaComponent implements OnInit {
  @Input()
  name: string;
  @Input()
  label?: string;
  @Input()
  labelClasses?: string;
  @Input()
  validations?: { func: (fieldPath: string) => boolean; key: string }[];

  group: UntypedFormGroup;

  constructor(private fgd: FormGroupDirective) {}

  ngOnInit() {
    this.group = this.fgd.form;
  }

  onBlur() {
    const input = this.group.get(this.name);
    if (input.value) input.setValue(input.value.trim());
  }
}
