import { NotificationType, NotificationTypes } from '../interface/notification.types';

export interface NotificationEntry {
  type: NotificationType;
  key: string;
  descriptionKey?: string;
  icon?: string;
  variables?: { [key: string]: string };
}

export function errorNotification(
  key: string,
  variables?: { [key: string]: string },
): NotificationEntry {
  return {
    type: 'error',
    key,
    variables,
  };
}

export function warningNotification(
  key: string,
  variables?: { [key: string]: string },
): NotificationEntry {
  return {
    type: 'warning',
    key,
    variables,
  };
}

export function infoNotification(
  key: string,
  variables?: { [key: string]: string },
): NotificationEntry {
  return {
    type: 'info',
    key,
    variables,
  };
}

export class Notifications {
  entries: NotificationEntry[] = [];

  add(entry: NotificationEntry) {
    if (!this.hasEntry(entry)) this.entries.push(entry);
  }

  addAll(entries: NotificationEntry[]) {
    entries.forEach((entry) => this.add(entry));
  }

  remove(entry: NotificationEntry) {
    const index = this.entries.findIndex((e) => entry.key === e.key);
    if (index >= 0) this.entries.splice(index, 1);
  }

  hasEntry(entry: NotificationEntry) {
    for (const e of this.entries)
      if (e.key === entry.key && e.variables === entry.variables) return true;

    return false;
  }

  clear() {
    this.entries = [];
  }

  isEmpty() {
    return this.entries.length === 0;
  }

  hasAnyError(): boolean {
    return this.entries.filter((e) => e.type === NotificationTypes.error).length !== 0;
  }
}
