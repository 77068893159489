import { Directive, Input } from '@angular/core';

import { DynamicFormDirective } from './dynamic-form.directive';
import { TFormControls, TParentFormGroupValue } from './typed-form-dynamic.model';

/**
 * This is experimental version of DynamicFormDirective to solve problems with change detection error:
 * Template cannot properly update ng-valid class in the <form>
 * after multiple actions taken by isActive and isDisabled inputs in one event loop.
 *
 * Error details:
 * ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
 * Previous value for 'ng-valid': 'true'. Current value: 'false'.
 */
@Directive()
export abstract class AsyncDynamicFormDirective<
  TParentModel extends TFormControls<TParentModel> = any,
  TFormValueKey extends keyof TParentFormGroupValue<TParentModel> = any,
> extends DynamicFormDirective<TParentModel, TFormValueKey> {
  @Input()
  override set isActive(isActive: boolean) {
    this.formGroup = this.createFormGroup();

    // add or remove dynamic groups in next frame so the effects can be calculated in next step
    queueMicrotask(() => {
      if (isActive) {
        this.addGroup();
        this.onFormCreated();
      } else {
        this.removeGroup();
        this.onFormDestroyed();
      }
    });
  }

  @Input()
  override set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;

    // disable or enable group in next frame so the effects can be calculated in next step
    queueMicrotask(() => {
      if (this.isAlreadyInForm()) {
        isDisabled ? this.disableGroup() : this.enableGroup();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFormCreated() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFormDestroyed() {}
}
