import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

export interface AuthInterceptorRetryStrategy {
  renewTokenAndTryAgain(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>>;

  pickErrorHandler(
    url: string,
    loginHandler: (err: HttpErrorResponse) => Observable<HttpEvent<unknown>>,
    defaultHandler: (err: HttpErrorResponse) => Observable<never>,
  ): (err: HttpErrorResponse) => Observable<HttpEvent<unknown>>;

  pickFinalizer(
    url: string,
    loginFinalizer: () => boolean,
    defaultFinalizer: () => void,
  ): () => void;
}

export const AUTH_INTERCEPTOR_RETRY_STRATEGY_TOKEN =
  new InjectionToken<AuthInterceptorRetryStrategy>('authInterceptorRetryStrategyToken');
