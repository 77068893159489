import { Injectable } from '@angular/core';

import {
  CalendarSchedule,
  CalendarScheduleDto,
  TemplateCalendar,
  TemplateCalendarDetails,
  TemplateCalendarDetailsDto,
  TemplateCalendarDto,
} from '@demica/resources/calendar';

import { DictionaryService } from '../../../../service/dictionary.service';

import { HasEntityId } from '../../../../interface/has-entity-id.interface';
import { DictionaryEntry } from '../../../../model/dictionary-entry.interface';
import { isDefined } from '../../../../utils/is-defined.utils';

@Injectable()
export class CalendarResourceConvertService {
  // TODO: This conversions are meant to be temporary - they, along with double models for calendars should be removed after epic TRFV2-22365 is done

  private months: DictionaryEntry[] = this.dictionaryService.getMonths();
  private weeks: DictionaryEntry[] = this.dictionaryService.getWeeks();
  private daysOfWeek: DictionaryEntry[] = this.dictionaryService.getDaysOfWeek();

  constructor(private dictionaryService: DictionaryService) {}

  private convertScheduleFromResource(initialSchedule: CalendarScheduleDto): CalendarSchedule {
    const { daysOfWeek, dayOfWeek, month, weekNumber, ...schedule } = initialSchedule;
    return {
      ...schedule,
      daysOfWeek: daysOfWeek?.map((dayOfWeek) => this.findEnumKey(dayOfWeek, this.daysOfWeek)),
      dayOfWeek: this.findEnumKey(dayOfWeek, this.daysOfWeek),
      month: this.findEnumKey(month, this.months),
      weekNumber: this.findEnumKey(weekNumber, this.weeks),
    };
  }

  private findEnumKey(value: HasEntityId, availableValues: DictionaryEntry[]): string | null {
    if (!isDefined(value)) {
      return null;
    }
    return availableValues.find((entry) => entry.entityId === value.entityId).key;
  }

  private convertScheduleToResource(initialSchedule: CalendarSchedule): CalendarScheduleDto {
    const { daysOfWeek, dayOfWeek, month, weekNumber, ...schedule } = initialSchedule;
    return {
      ...schedule,
      daysOfWeek: daysOfWeek?.map((dayOfWeek) => this.findEnumId(dayOfWeek, this.daysOfWeek)),
      dayOfWeek: this.findEnumId(dayOfWeek, this.daysOfWeek),
      month: this.findEnumId(month, this.months),
      weekNumber: this.findEnumId(weekNumber, this.weeks),
    };
  }

  private findEnumId(value: string, availableValues: DictionaryEntry[]): HasEntityId | null {
    if (!isDefined(value)) {
      return null;
    }
    return { entityId: availableValues.find((entry) => entry.key === value).entityId };
  }

  convertCalendarTemplateFromResource(initialCalendar: TemplateCalendarDto): TemplateCalendar {
    const { draftVersion, finalVersion, ...calendar } = initialCalendar;
    return {
      ...calendar,
      draftVersion: isDefined(draftVersion)
        ? this.convertCalendarTemplateDetailsFromResource(draftVersion)
        : draftVersion,
      finalVersion: isDefined(finalVersion)
        ? this.convertCalendarTemplateDetailsFromResource(finalVersion)
        : finalVersion,
    };
  }

  convertCalendarTemplateDetailsFromResource(
    initialDetails: TemplateCalendarDetailsDto,
  ): TemplateCalendarDetails {
    const { calendarScheduleTemplates, ...details } = initialDetails;
    return {
      ...details,
      calendarScheduleTemplates: calendarScheduleTemplates.map((schedule) =>
        this.convertScheduleFromResource(schedule),
      ),
    };
  }

  convertCalendarTemplateDetailsToResource(
    initialDetails: TemplateCalendarDetails,
  ): TemplateCalendarDetailsDto {
    const { calendarScheduleTemplates, ...details } = initialDetails;
    return {
      ...details,
      calendarScheduleTemplates: calendarScheduleTemplates.map((schedule) =>
        this.convertScheduleToResource(schedule),
      ),
    };
  }
}
