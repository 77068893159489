import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HasEntityId } from '@demica/resources/common';
import { UserSettingsResourceService, UserSettingType } from '@demica/resources/user-settings';

import { FILTER_IDS_SCHEMA, FilterIds } from '../../model/filter-ids.interface';
import { FilterParameters } from '../../model/filter/filter-parameters.interface';

function ignoreWithMessage(m: string) {
  return function () {
    console.warn(m);
    return of(null);
  };
}

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  constructor(private _userSettingsResourceService: UserSettingsResourceService) {}

  saveFilters(
    userSettingType: UserSettingType,
    filterParameters: FilterParameters,
  ): Observable<unknown> {
    const filters = this._mapToIds(filterParameters);

    return this._userSettingsResourceService.saveSettings$(filters, userSettingType);
  }

  getFilters(userSettingType: UserSettingType): Observable<null | FilterIds> {
    return this._userSettingsResourceService.getSettings$<FilterIds>(userSettingType).pipe(
      catchError(
        ignoreWithMessage('Invalid JSON. Ignoring ' + userSettingType + ' filter settings'),
      ),
      tap((filterIds) => FILTER_IDS_SCHEMA.parse(filterIds)),
      catchError(
        ignoreWithMessage(
          'Schema validation failed. Ignoring ' + userSettingType + ' filter settings',
        ),
      ),
    );
  }

  private _mapToIds(filterParameters: FilterParameters): FilterIds {
    const toId = (entity: HasEntityId) => entity.entityId;

    return {
      environmentIds: filterParameters.environments.map(toId),
      clientIds: filterParameters.clients.map(toId),
      opcoIds: filterParameters.opcos.map(toId),
      transactionIds: filterParameters.transactions.map(toId),
    };
  }
}
