export interface InputFileStatus {
  processingDate: string;
  inputFileId: string;
  sellerNames: string[];
  directory: string;
  fileName: string;
  environmentId: number;
  transactionId: number;
  environmentName: string;
  present: boolean;
  canSkip: boolean;
  sortFlag?: MissingFileSortFlag;
}

export enum MissingFileSortFlag {
  TO_SKIP = 0,
  PROCESSED = 1,
  PRESENT = 2,
}
