import { Injectable } from '@angular/core';

import { LogoutReasonStateService } from './logout-reason-state.service';

import { getLocationOrigin } from '../../../utils/window';
import { LOGOUT_REASONS } from './logout-reasons.enum';

@Injectable({
  providedIn: 'root',
})
export class LogoutRedirectUrlService {
  private _defaultRedirect = getLocationOrigin();
  private _inactivityRedirectURL = this._defaultRedirect;
  private _postLogoutRedirectURL = this._defaultRedirect;

  constructor(private _logoutReasonStateService: LogoutReasonStateService) {}

  getRedirectUrl(): string {
    switch (this._logoutReasonStateService.getReason()) {
      case LOGOUT_REASONS.INACTIVITY:
        return this._inactivityRedirectURL;
      default:
        return this._postLogoutRedirectURL;
    }
  }

  setInactivityRedirect(value: string): void {
    this._inactivityRedirectURL = value;
  }
  setPostLogoutRedirect(value: string): void {
    this._postLogoutRedirectURL = value;
  }
}
