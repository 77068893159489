export const maxCodeLength = 10;
export const minNumberValue = 0;
export const maxNumberValue = 365;
export const minPercentageValue = 0;
export const maxPercentageValue = 100;
export const minDelinquencyDefinitionValue = 0;
export const minDelinquencyRuleValue = 0;
export const defaultMinAttributeValue = -100000000;
export const defaultMaxAttributeValue = 100000000;
export const minIntegerValue = -2147483648;
export const maxIntegerValue = 2147483647;
export const maxUserDefinedColumnLength = 500;
export const maxPredefinedColumnLength = 255;
export const metadataTextMaxLength = 255;
