import { Component, Input } from '@angular/core';

import { MenuOption } from '@demica/core/core';

@Component({
  selector: 'trf-menu-help',
  templateUrl: './menu-help.component.html',
  styleUrls: ['./menu-help.component.sass'],
})
export class MenuHelpComponent {
  @Input()
  menuOption: MenuOption;
}
