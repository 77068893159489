import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import {
  SessionIdForAnalyticsService,
  ClientIdForAnalyticsService,
} from '@demica/user-identification';

import { UserPreferences } from '../service/user-preferences/user-preferences.service';

/**
 * Service for decoupling "data publishers" from different modules than core-core
 */
@Injectable({
  providedIn: 'root',
})
export class AnalyticsDataBusService {
  locale: string;
  clientId: string;
  sessionId: string;
  locale$ = new ReplaySubject<string>(1);

  constructor(
    private _sessionIdProvider: SessionIdForAnalyticsService,
    private _clientIdProvider: ClientIdForAnalyticsService,
    private _userPref: UserPreferences,
  ) {
    this._clientIdProvider.id$.subscribe((clientId) => (this.clientId = clientId));
    this._sessionIdProvider.id$.subscribe((sessionId) => (this.sessionId = sessionId));

    this._userPref.locale
      .pipe(
        filter((lang) => !!lang),
        tap((locale) => {
          this.locale = locale;
        }),
      )
      .subscribe(this.locale$);
  }
}
