import { EntityReference } from '../interface/entity-reference.interface';
import { EntityId } from '../interface/has-entity-id.interface';
import { RequiredAuthority } from '../interface/route.interface';
import { AuthoritySet } from '../security/authority-set';
import { OrganizationReferenceType, OrganizationUnitContext } from './user.interface';

export class User {
  constructor(
    public accountId: number,
    public fullName: string,
    public disabledPasswordAuthentication: boolean,
    public fullAccessRegions: EntityId[],
    public internal: boolean,
    private email: string,
    private authorities: string[],
    private organizationUnitContext: OrganizationUnitContext,
  ) {}

  getAuthorities(): string[] {
    return this.authorities;
  }

  hasAuthority(authority: string | RequiredAuthority): boolean {
    if (!authority) return true;
    if (!this.authorities) return false;

    if (typeof authority === 'string') return !!this.authorities.find((a) => a === authority);
    else return new AuthoritySet(authority).isSatisfiedBy(this.authorities);
  }

  hasId(id: number) {
    return this.accountId === id;
  }

  hasFullAccessRegion(): boolean {
    return this.fullAccessRegions?.includes(this.getOrganisationUnitId());
  }

  getOrganisationUnitId(): EntityId {
    return this.organizationUnitContext.id;
  }

  getOrganizationUnitName(): string {
    return this.organizationUnitContext.name;
  }

  getOrganizationUnitReferenceId(): EntityId {
    return this.organizationUnitContext.referenceId;
  }

  getOrganizationUnitReferenceType(): OrganizationReferenceType {
    return this.organizationUnitContext.referenceType;
  }

  getOrganizationUnitReference(): EntityReference {
    return {
      entityId: this.getOrganisationUnitId(),
      name: this.getOrganizationUnitName(),
    };
  }
}
