import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SORTABLE_TABLE_HEADER } from '@demica/accessibility';
import { SortDirection } from '@demica/core/core';

import { TableSortService } from '../../service/table-sort.service';

/* Use this component only by directive SortableColumnHeaderDirective. See example in Client table.
 Selector is defined in component-selector.constants file in shared module's model folder. */

@Component({
  selector: SORTABLE_TABLE_HEADER,
  styleUrls: ['sortable-table-column.component.sass'],
  templateUrl: 'sortable-table-column.component.html',
})
export class SortableTableColumnComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  key?: string;
  @Input()
  name: string;
  @Input()
  sortable = true;
  @ViewChild('columnContainer')
  columnContainer: ElementRef;
  @Output()
  sendDirection = new EventEmitter<string>();

  SORT_DIRECTION = SortDirection;

  direction: SortDirection = null;

  manualSortingEnabled: boolean;

  private destroySubject = new Subject<void>();

  constructor(private overviewTableSort: TableSortService) {}

  ngOnInit() {
    this.overviewTableSort.sortParamsChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe((sortParams) => {
        this.direction = sortParams.sortColumn === this.key ? sortParams.sortDirection : null;
        this.sendDirection.emit(this.direction === 'ASC' ? 'ascending' : 'descending');
      });
    this.manualSortingEnabled = this.overviewTableSort.manualSorted.getValue();
  }

  @HostListener('click')
  onClick() {
    this.sort();
  }

  @HostListener('keyup.enter')
  onKeyUp() {
    this.sort();
  }

  sort() {
    if (this.key && this.sortable) {
      this.overviewTableSort.changeSorting(this.key);
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  ngAfterViewInit() {
    if (this.direction && this.manualSortingEnabled) {
      this.columnContainer.nativeElement.focus();
    }
  }
}
