import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  getDir,
  HasEntityId,
  PushRequest,
  PushRequestLog,
  statusDefinitionForId,
} from '@demica/core/core';

@Component({
  selector: 'trf-push-row',
  templateUrl: 'push-row.component.html',
  styleUrls: ['./push-row.component.sass'],
})
export class PushRowComponent {
  @Input()
  row: PushRequest;

  @Input()
  canDisplayRollbackButton = false;

  @Output()
  versionClick = new EventEmitter<HasEntityId>();

  @Output()
  approveClick = new EventEmitter<HasEntityId>();

  @Output()
  rollbackClick = new EventEmitter<HasEntityId>();

  mapStatusIdToTranslationKey(statusId: string) {
    return statusDefinitionForId(statusId).translationKey;
  }

  mapStatusIdToClass(statusId: string) {
    return statusDefinitionForId(statusId).cssClass;
  }

  getErrorDetails(log: PushRequestLog) {
    return log.entries
      .filter((e) => e.errorCode !== undefined)
      .map((e) => e.info)
      .join(' ');
  }

  onVersionClick(version: HasEntityId) {
    this.versionClick.emit(version);
  }

  onApproveClick(pushRequest: PushRequest) {
    if (pushRequest.canEnableApproveButton) {
      this.approveClick.emit(pushRequest);
    }
  }

  onRollbackClick(pushRequest: PushRequest) {
    if (pushRequest.rollbackPossibility.canBeRolledBack) {
      this.rollbackClick.emit(pushRequest);
    }
  }

  getCurrentDir() {
    return getDir();
  }

  getCannotRollbackReason(pushRequest: PushRequest) {
    if (!pushRequest.rollbackPossibility.canBeRolledBack) {
      return `TRANSACTION_VERSIONS.CANNOT_ROLLBACK_MESSAGES.${pushRequest.rollbackPossibility.cannotRollbackReason.entityId}`;
    }
  }

  onKeypressRollbackClick(event: KeyboardEvent, pushRequest: PushRequest) {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (event.key === 'Enter') this.onRollbackClick(pushRequest);
  }

  onKeypressApproveClick(event: KeyboardEvent, pushRequest: PushRequest) {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (event.key === 'Enter') this.onApproveClick(pushRequest);
  }
}
