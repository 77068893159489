import { Component, Input } from '@angular/core';

import { SellerBankAccount } from '@demica/resources/seller';

import { Actions } from '../actions/model/actions.interface';

@Component({
  selector: 'trf-seller-accounts-list-row',
  templateUrl: './seller-accounts-list-row.component.html',
  styleUrls: ['./seller-accounts-list-row.component.sass'],
})
export class SellerAccountsListRowComponent {
  @Input()
  row: SellerBankAccount;

  @Input()
  actions: Actions;

  @Input()
  isPreview = false;
}
