<div
  class="tab-buttons  {{ type }}"
  role="tablist"
>
  <p
    *ngIf="label && showLabel"
    class="trf-tab-label"
    >{{ label | translate }}</p
  >
  <a
    *ngFor="let tab of tabs | filterTabs; let index = index"
    #tabLink
    class="tab-button {{ tab.headerClasses }}"
    role="tab"
    tabindex="0"
    [ngClass]="{
      active: tab === activeTab,
      hidden: tab.hidden,
      disabled: tab.disabled,
      error: tab.error
    }"
    [attr.title]="tab.titleAttr"
    [attr.aria-label]="tab.title | translate"
    [attr.aria-selected]="tab === activeTab"
    [attr.aria-controls]="tab.title"
    (click)="tabClicked(tab)"
    (keydown.enter)="tabLink.click()"
    [attr.testid]="[tab.testId]"
    [attr.data-testid]="[tab.testId]"
  >
    <span class="tab-title">{{ tab.title | translate }}</span>
    <span class="tab-suffix">{{ tab.titleSuffix | translate }}</span>
    <ngp-tooltip-icon
      *ngIf="tab.tooltipContent"
      [iconAriaDesc]="tab.iconAriaDesc ?? tab.title ?? '' | translate"
      [tooltipKey]="tab.tooltipContent | translate"
      [tooltipTestId]="tab.tooltipTestId"
    ></ngp-tooltip-icon>
  </a>
</div>
<div
  class="content"
  role="tabpanel"
  [attr.aria-labelledby]="activeTab?.title"
>
  <ng-container
    *ngTemplateOutlet="activeTab?.contentTemplate || contentTemplate || activeTab?.view || null"
  ></ng-container>
</div>
