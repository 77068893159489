import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { interval, Observable } from 'rxjs';
import { concatMap, map, takeWhile, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import {
  ApiVersionNumber,
  encodeEndpoint,
  encodeEndpointApiV2,
  EntityId,
  FundingReportExportParams,
  KEEP_ALIVE_SESSION_HTTP_CONTEXT_TOKEN,
  PageableExportParams,
  PageableResponse,
  PdfPageableFileName,
  PostResponse,
  RestResponse,
  RestResponseWithHeaders,
  SimplePageableExportParams,
  XlsxPageableFileName,
  ZipPageableFileName,
} from '@demica/core/core';
import { ExportType } from '@demica/ui-core-api';

import { ExportStatusService } from '../component/modal-export/model/export-status-service';

import { PostExportResponseWithHeaders } from '../component/modal-export/model/export-modal.model';
import { ExportStatusResponse } from '../component/modal-export/model/export-status-response.interface';
import { ExportStatusStage } from '../component/modal-export/model/export-status-stage';

import { saveAs } from 'file-saver';
import { $enum } from 'ts-enum-util';

@Injectable({
  providedIn: 'root',
})
export class TraceableExportResourceService {
  contextStatusService: ExportStatusService = ExportStatusService.DASHBOARDS;

  constructor(private _http: HttpClient, private _translate: TranslateService) {}

  requestExportFile$(
    url: string,
    version: ApiVersionNumber,
    exportParams: SimplePageableExportParams | PageableExportParams | FundingReportExportParams,
  ): Observable<
    HttpResponse<RestResponseWithHeaders<PostResponse> | PostExportResponseWithHeaders>
  > {
    const urlEncoded =
      version === ApiVersionNumber.V1 ? encodeEndpoint(url) : encodeEndpointApiV2(url);

    return this._http.post<RestResponseWithHeaders<PostResponse> | PostExportResponseWithHeaders>(
      urlEncoded,
      exportParams,
      {
        observe: 'response',
        context: new HttpContext().set(KEEP_ALIVE_SESSION_HTTP_CONTEXT_TOKEN, true),
      },
    );
  }

  schedulePings$(
    sec: number,
    exportId: EntityId,
    requestsPerInterval: number,
  ): Observable<RestResponse<ExportStatusResponse>> {
    return interval(sec * 1000).pipe(
      concatMap(() => this._getExportStatus(exportId)),
      takeWhile((response, index) => {
        return (
          response.data.stage === ExportStatusStage.GENERATING_FILE && index < requestsPerInterval
        );
      }, true),
    );
  }

  downloadExportedData$(exportId: EntityId): Observable<PageableResponse> {
    const url = encodeEndpoint(`resources/${this.contextStatusService}/download`);
    const body = { exportId: exportId };

    return this._http
      .post(url, body, {
        responseType: 'arraybuffer',
        observe: 'response',
        context: new HttpContext().set(KEEP_ALIVE_SESSION_HTTP_CONTEXT_TOKEN, true),
      })
      .pipe(
        map((res) => ({
          limit: res.headers.get('incomplete-data'),
          data: new Blob([res.body], { type: 'application/octet-stream' }),
        })),
      );
  }

  saveFile(
    blob: Blob,
    exportType: ExportType,
    fileName: string,
    translationParams: string[],
  ): void {
    saveAs(blob, this._extractedFileName(fileName, translationParams, exportType));
  }

  private _extractedFileName(
    fileName: string,
    translationParams: string[],
    exportType: ExportType,
  ): string {
    const pageableFileName = $enum.visitValue(exportType).with({
      [ExportType.PDF]: () => new PdfPageableFileName(this._translate),
      [ExportType.XLSX]: () => new XlsxPageableFileName(this._translate),
      [ExportType.CSV]: () => new ZipPageableFileName(this._translate),
      [ExportType.ZIP]: () => new ZipPageableFileName(this._translate),
    });

    return pageableFileName.getValidFileName(fileName, translationParams);
  }

  private _getExportStatus(exportId: EntityId): Observable<RestResponse<ExportStatusResponse>> {
    const url = encodeEndpoint(`resources/${this.contextStatusService}/ping`);

    return this._http.post<RestResponse<ExportStatusResponse>>(
      url,
      {
        exportId: exportId,
      },
      {
        context: new HttpContext().set(KEEP_ALIVE_SESSION_HTTP_CONTEXT_TOKEN, true),
      },
    );
  }
}
