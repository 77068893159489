<ng-container [formGroup]="group">
  <div>
    <ngp-checkbox
      [formControlName]="formControlName"
      [checked]="value"
      [disabled]="disabled"
      [labelText]="label"
      (ngModelChange)="onChange($event)"
    ></ngp-checkbox>

    <ngp-tooltip-icon
      *ngIf="infoTooltip || (disabled && infoTooltipOnDisabled)"
      [tooltipKey]="disabled ? infoTooltipOnDisabled : infoTooltip"
    ></ngp-tooltip-icon>
  </div>
</ng-container>
