<trf-slidein-container
  headerText="FILTER_DASHBOARD.FILTER_FORM_TITLE"
  (slideinClose)="onClose()"
  testid="filters-slidein"
>
  <ngp-inline-loader [loading]="loading"></ngp-inline-loader>

  <div
    class="trf-section"
    *ngIf="!loading"
    trfSlideinFormChanges
  >
    <div class="trf-section-content">
      <form
        [formGroup]="form"
        class="trf-form-fields"
      >
        <trf-form-multi-select-row
          *ifHasAuthority="authority"
          label="FILTER_DASHBOARD.ENVIRONMENTS"
          name="environments"
          [options]="environments"
          defaultLabel="FILTER_DASHBOARD.FILTER_ALL_ENVIRONMENTS"
          testid="dashboard-filter-environments"
        ></trf-form-multi-select-row>
        <trf-form-multi-select-row
          label="FILTER_DASHBOARD.FILTER_CLIENTS_TITLE"
          name="clients"
          [options]="clients"
          defaultLabel="FILTER_DASHBOARD.FILTER_ALL_CLIENTS"
          testid="dashboard-filter-clients"
        ></trf-form-multi-select-row>
        <trf-form-multi-select-row
          *ngIf="opcosEnabled"
          label="FILTER_DASHBOARD.FILTER_OPCO_TITLE"
          name="opcos"
          [options]="opcos"
          defaultLabel="FILTER_DASHBOARD.FILTER_ALL_OPCO"
          testid="dashboard-filter-sellers"
        ></trf-form-multi-select-row>
        <trf-form-multi-select-row
          label="FILTER_DASHBOARD.FILTER_TRANSACTIONS_TITLE"
          name="transactions"
          [options]="transactions"
          defaultLabel="FILTER_DASHBOARD.FILTER_ALL_TRANSACTIONS"
          testid="dashboard-filter-transactions"
        ></trf-form-multi-select-row>
      </form>
    </div>

    <div class="trf-section-buttons">
      <ngp-button
        key="FILTER_DASHBOARD.BUTTON_CLOSE"
        ngbAutofocus
        classes="tertiary"
        (click)="onClose()"
        testid="close-button"
      ></ngp-button>
      <trf-button-controls
        positiveName="FILTER_DASHBOARD.BUTTON_APPLY"
        negativeName="FILTER_DASHBOARD.CLEAR_ALL"
        (positiveEvent)="onApply()"
        (negativeEvent)="clearAllFilters()"
      ></trf-button-controls>
    </div>
  </div>
</trf-slidein-container>
