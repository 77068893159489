import { Injectable } from '@angular/core';

declare global {
  interface Window {
    LoadOneTrust?: () => void;
  }
}

@Injectable()
export class OneTrustService {
  loadScript(): void {
    if (window.LoadOneTrust) {
      window.LoadOneTrust();
    }
  }
}
