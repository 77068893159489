import { Injectable } from '@angular/core';

import { AppConfig } from '@demica/resources/app-config';

import { AppConfigService } from './app-config/app-config.service';

/**
 * TODO Refactor all public methods to return observables and
 * remove workarounds in feature-toggle-guard and activate-authorized-sibling guard
 */
@Injectable()
export class FeatureToggleService {
  protected featureToggles = new Map<string, boolean>();
  protected defaultValue = false;

  constructor(private _appConfig: AppConfigService) {
    this._appConfig.appConfig$.subscribe((config: AppConfig) => {
      this.parseMapValues(config.featureToggles.toggles);
      this.defaultValue = config.featureToggles.defaultValue;
    });
  }

  hasEnabled(featureToggle: string): boolean {
    return this.hasGroupEnabled([featureToggle]);
  }

  hasGroupEnabled(featureToggleGroup: string[]): boolean {
    if (!featureToggleGroup) {
      return true;
    }

    return featureToggleGroup.some((toggle) => this.isFeatureToggleEnabled(toggle));
  }

  hasAllGroupsEnabled(featureToggleGroup: string[]): boolean {
    if (!featureToggleGroup) {
      return true;
    }

    return featureToggleGroup.every((toggle) => this.isFeatureToggleEnabled(toggle));
  }

  protected parseMapValues(featureTogglesObject: Record<string, boolean>) {
    const keys = Object.keys(featureTogglesObject);
    keys.forEach((key) => this.featureToggles.set(key, featureTogglesObject[key]));
  }

  private isFeatureToggleEnabled(key: string): boolean {
    return this.featureToggles.has(key) ? this.featureToggles.get(key) : this.defaultValue;
  }
}
