import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { KeyboardNavigationStrategyService } from '@demica/accessibility';
import { AliasMissingMapping } from '@demica/core/core';

import { Actions } from '../../../actions/model/actions.interface';

@Component({
  selector: 'trf-alias-missing-row',
  templateUrl: 'alias-missing-row.component.html',
  styleUrls: ['alias-missing-row.component.sass'],
})
export class AliasMissingRowComponent implements OnInit {
  @Input()
  row: AliasMissingMapping;
  @Input()
  actions: Actions;
  @Input()
  displayTransactionColumn: boolean;
  @Input()
  displayCheckbox: boolean;

  @Output()
  checkboxChanged = new EventEmitter<AliasMissingMapping>();

  constructor(
    private translateService: TranslateService,
    private keyboardNavigationService: KeyboardNavigationStrategyService,
  ) {}

  ngOnInit(): void {
    this.row.tableId = `row-${this.row.transaction.entityId}-${this.row.dataSource.entityId}-${this.row.analysisGroup.entityId}-${this.row.environment.entityId}-${this.row.value}`;
  }

  onCheckboxClick(checked: boolean) {
    this.row.selected = checked;
    this.checkboxChanged.emit(this.row);
  }

  onCheckboxKeyup(event: KeyboardEvent) {
    this.keyboardNavigationService.onTableCheckboxKeyup(event, (checked: boolean) =>
      this.onCheckboxClick(checked),
    );
  }

  getAnalysisGroupName() {
    return this.row.analysisGroup.key
      ? this.translateService.instant('COLUMN_DEFINITION_TYPES.' + this.row.analysisGroup.key)
      : this.row.analysisGroup.name;
  }
}
