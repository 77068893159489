import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import {
  ComponentConfiguration,
  DictionaryEntry,
  DictionaryService,
  FeatureToggleService,
  generateUUID,
  HasEntityId,
  maxPercentageValue,
  SellerCodeValidator,
  removeTrailingZeros,
  SlideinService,
} from '@demica/core/core';
import { Seller, SellerBankAccount, SellerResourceService } from '@demica/resources/seller';

import { TextTableHeaderComponent } from '../data-table/text-table-header.component';
import { SellerAccountsListRowComponent } from './seller-accounts-list-row.component';

import { isControlValid$ } from '../../forms/dynamic-form-utils';
import { FieldDefinition } from '../../forms/form';
import { FormStatus } from '../../forms/model/form-status.interface';
import { definitionGroupToMessages } from '../../forms/validation-messages/message-builder';
import {
  msgDefaultMaxLength,
  msgEmailFormat,
  msgMaxLength,
  msgMaxNumber,
  msgMinLength,
  msgRequired,
} from '../../forms/validation-messages/message-definitions';
import { hasError } from '../../forms/validation-messages/message-predicates';
import {
  requireSelect,
  validateDefaultMaxLength,
  validateEmail,
  validateMinLength,
  validateNotEmpty,
} from '../../forms/validators';
import { SellerFormFields } from '../../model/seller-form-fields.enum';
import { SicCode } from '../../model/sic-codes-translation';
import {
  maxSellerCodeLength,
  maxSellerExternalSystemReferenceLength,
  maxSellerPostCodeLength,
  minSellerNameLength,
  sellerCinLength,
} from '../../validation/seller-validation.consts';
import { ACTION_REMOVE, Actions } from '../actions/model/actions.interface';
import { DataSource } from '../data-table/data-source.interface';
import { SellerBankAccountSlideinContainerComponent } from './seller-bank-account-slidein.container';

type FormDefinition = Record<SellerFormFields, FieldDefinition>;

@Component({
  selector: 'trf-create-seller-form',
  templateUrl: './create-seller-form.component.html',
  styleUrls: ['./create-seller-form.component.sass'],
})
export class CreateSellerFormComponent implements OnInit {
  @Input()
  nameClasses: string[];
  @Input()
  clientName: string;
  @Input()
  countryOptions: HasEntityId[] = [];
  @Input()
  standardIndustryCodeOptions: SicCode[] = [];
  @Input()
  legalStatusOptions: DictionaryEntry[];
  @Input()
  sellerId: number = null;
  @Input()
  hasEnabledSellerAccounts: boolean;
  @Input()
  partialsEnabled: boolean;

  @Output()
  sellerClose = new EventEmitter<void>();
  @Output()
  save = new EventEmitter<Seller>();
  @Output()
  sellerPrevName = new EventEmitter<string>();
  @Output()
  nameInputChanges = new EventEmitter<Observable<string>>();

  maxSellerCodeLength = maxSellerCodeLength;
  maxSellerPostCodeLength = maxSellerPostCodeLength;
  sellerCinLength = sellerCinLength;
  maxOpcoESRLength = maxSellerExternalSystemReferenceLength;
  loadedOpco: Seller;

  messages = this.initValidationMessages();

  loading = true;
  extendedSeller = false;
  currentLang: string;

  submitted = false;
  disabledSaveButton = false;

  FormFields = SellerFormFields;
  formDefinition: FormDefinition = {
    clientName: ['', []],
    name: ['', [validateNotEmpty]],
    code: [
      '',
      [validateNotEmpty],
      this.sellerId
        ? this.SellerCodeValidator.validate.bind(this.SellerCodeValidator)
        : this.SellerCodeValidator.validateWithId(() => this.sellerId).bind(
            this.SellerCodeValidator,
          ),
    ],
    contactPersonFirstName: ['', [validateMinLength(minSellerNameLength)]],
    contactPersonLastName: ['', [validateMinLength(minSellerNameLength)]],
    address1: ['', [validateNotEmpty]],
    address2: ['', []],
    address3: ['', []],
    address4: ['', []],
    country: [null, [requireSelect]],
    telephoneCountryCode: ['', [validateDefaultMaxLength]],
    telephoneNumber: ['', [validateDefaultMaxLength]],
    email: ['', [validateEmail]],
    minimalPartialPortionThreshold: [null, []],
  };
  form: UntypedFormGroup = this.fb.group(this.formDefinition);

  dataSource: DataSource<SellerBankAccount[]> = {
    data: new BehaviorSubject([]),
  };

  headerConfig: ComponentConfiguration;
  rowConfig: ComponentConfiguration;
  bankAccountData: SellerBankAccount[] = [];

  isPartialPortionThresholdWarning$ = combineLatest([
    isControlValid$(this.form.controls.minimalPartialPortionThreshold),
    this._isPartialPortionThresholdValue$(),
  ]).pipe(map(([valid, warning]) => valid && warning));

  constructor(
    private fb: UntypedFormBuilder,
    private SellerCodeValidator: SellerCodeValidator,
    private opcoResource: SellerResourceService,
    private toggleService: FeatureToggleService,
    private http: HttpClient,
    private dictionaryService: DictionaryService,
    private translate: TranslateService,
    private slideInService: SlideinService,
  ) {
    this.currentLang = translate.currentLang;
  }

  ngOnInit(): void {
    this.form.get('clientName').setValue(this.clientName);
    this.form.get('clientName').disable();

    if (this.hasEnabledSellerAccounts) {
      this.addAdditionalFields();
      this.headerConfig = this.configureTableHeader();
      this.rowConfig = this.configureTableRows();
      this.extendedSeller = true;
      if (!this.bankAccountData.length) {
        this.disabledSaveButton = true;
      }
    }

    if (this.sellerId) {
      this.opcoResource.getSeller$(this.sellerId).subscribe((opco) => {
        this.loadedOpco = opco;
        this.sellerPrevName.emit(this.loadedOpco.name);

        const formValue = { ...this.loadedOpco };

        if (this.hasEnabledSellerAccounts) {
          this.bankAccountData = formValue.bankAccounts;
          this.dataSource.data.next(this.bankAccountData);
        }
        if (opco.minimalPartialPortionThreshold) {
          formValue.minimalPartialPortionThreshold = removeTrailingZeros(
            opco.minimalPartialPortionThreshold,
          );
        }
        delete formValue.entityId;
        delete formValue.clientId;
        this.form.patchValue(formValue);

        if (this.bankAccountData.length) {
          this.disabledSaveButton = false;
        }

        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  addAdditionalFields(): void {
    const fields = [
      {
        name: 'postCode',
        control: new UntypedFormControl(null, Validators.maxLength(maxSellerPostCodeLength)),
      },
      {
        name: 'customerIdentificationNumber',
        control: new UntypedFormControl(null, Validators.maxLength(sellerCinLength)),
      },
      { name: 'standardIndustryCode', control: new UntypedFormControl(null) },
      { name: 'pointOfContact', control: new UntypedFormControl(null, validateNotEmpty) },
      {
        name: 'externalSystemReference',
        control: new UntypedFormControl(
          null,
          Validators.maxLength(maxSellerExternalSystemReferenceLength),
        ),
      },
      { name: 'legalStatus', control: new UntypedFormControl(null) },
      { name: 'localTaxReferenceNumber', control: new UntypedFormControl(null) },
    ];

    fields.forEach((field) => {
      this.form.addControl(field.name, field.control);
    });
  }

  onNameInputChanges(nameChanges$: Observable<string>) {
    this.nameInputChanges.emit(nameChanges$);
  }

  onClose() {
    this.sellerClose.emit();
  }

  onSave() {
    if (this.disabledSaveButton) return;
    this.submitted = true;

    if (this.form.status !== FormStatus.PENDING) {
      this.saveOpco();
    } else {
      const sub = this.form.statusChanges
        .pipe(
          filter((s) => s !== FormStatus.PENDING),
          tap(() => {
            sub.unsubscribe();
            this.saveOpco();
          }),
        )
        .subscribe();
    }
  }

  saveOpco() {
    if (this.form.valid) {
      let payload = {
        ...this.form.value,
        entityRevision: this.loadedOpco ? this.loadedOpco.entityRevision : null,
      };

      if (this.extendedSeller) {
        if (!this.bankAccountData.length) return;
        this.removeTemporaryId();

        payload = {
          ...this.form.value,
          entityRevision: this.loadedOpco ? this.loadedOpco.entityRevision : null,
          bankAccounts: this.bankAccountData,
        };
      }

      this.disabledSaveButton = true;
      this.save.emit(payload);
    }
  }

  addAccount() {
    const slideInRef = this.slideInService.openSlideIn(SellerBankAccountSlideinContainerComponent);
    slideInRef.onSuccess = this.onBankAccountAdd;
  }

  editAccount = (bankAccountToEdit: SellerBankAccount) => {
    const slideInRef = this.slideInService.openSlideIn(SellerBankAccountSlideinContainerComponent);
    slideInRef.instance.bankAccountData = bankAccountToEdit;

    const index = this.findBankAccountIndex(bankAccountToEdit);

    slideInRef.onSuccess = (editedOpcoBankAccount: SellerBankAccount) => {
      this.onBankAccountEdited(editedOpcoBankAccount, index);
    };
  };

  private onBankAccountEdited = (editedBankAccount: SellerBankAccount, index: number) => {
    this.bankAccountData[index] = editedBankAccount;
    this.dataSource.data.next(this.bankAccountData);
  };

  private onBankAccountAdd = (newBankAccount: SellerBankAccount) => {
    newBankAccount.tempId = generateUUID();

    this.bankAccountData.push(newBankAccount);
    this.dataSource.data.next(this.bankAccountData);
    this.disabledSaveButton = false;
  };

  private onBankAccountRemove = (bankAccountToRemove: SellerBankAccount) => {
    const index = this.findBankAccountIndex(bankAccountToRemove);
    if (index > -1) {
      this.bankAccountData.splice(index, 1);
      this.dataSource.data.next(this.bankAccountData);
    }
    if (!this.bankAccountData.length) {
      this.disabledSaveButton = true;
    }
  };

  private configureTableHeader() {
    return {
      component: TextTableHeaderComponent,
      inputs: {
        columns: [
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_BANK_ACCOUNT_NAME', classes: 'text-wrap' },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_BANK_ACCOUNT_NUMBER', classes: 'text-wrap' },
          {
            nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_BRANCH_CODE_ROUTING_NUMBER',
            classes: 'text-wrap',
          },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_SWIFT_BIC_CODE', classes: 'text-wrap' },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_TYPE' },
          {
            nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_ACCOUNT_HOLDER',
            classes: 'text-wrap',
          },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_BANK_NAME', classes: 'text-wrap' },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_ADDRESS' },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_ACTIONS', classes: 'actions fixed-width' },
        ],
      },
    };
  }

  private configureTableRows() {
    const rowActions: Actions = {
      edit: {
        titleKey: 'SELLER_BANK_ACCOUNT.TABLE_ACTION_EDIT',
        handler: this.editAccount,
        icon: 'pencil-alt',
        testId: 'action-edit',
      },
      remove: {
        titleKey: 'SELLER_BANK_ACCOUNT.TABLE_ACTION_REMOVE',
        handler: this.onBankAccountRemove,
        icon: 'trash-alt',
        testId: 'action-delete',
        actionId: ACTION_REMOVE,
      },
    };

    return {
      component: SellerAccountsListRowComponent,
      inputs: {
        actions: rowActions,
      },
    };
  }

  private findBankAccountIndex(bankAccount: SellerBankAccount) {
    return this.bankAccountData.findIndex((oba) => {
      if (bankAccount.id) {
        return oba.id === bankAccount.id;
      } else {
        return oba.tempId === bankAccount.tempId;
      }
    });
  }

  private removeTemporaryId() {
    this.bankAccountData.map((ba) => {
      if (ba.tempId) {
        delete ba.tempId;
      }
    });
  }

  private initValidationMessages() {
    return definitionGroupToMessages(
      {
        opcoNameValidations: [msgRequired, msgDefaultMaxLength],
        opcoCodeValidations: [
          msgRequired,
          msgDefaultMaxLength,
          msgMinLength(minSellerNameLength),
          { func: hasError('opco-code-unavailable'), key: 'ERRORS.OPCO_CODE_NOT_UNIQUE' },
        ],
        opcoContactName: [msgDefaultMaxLength, msgMinLength(minSellerNameLength)],
        addressLine1: [msgRequired],
        maxLength: [msgDefaultMaxLength],
        email: [msgEmailFormat, msgDefaultMaxLength],
        required: [msgRequired],
        postCode: [msgMaxLength(maxSellerPostCodeLength)],
        customerIdentificationNumber: [msgMaxLength(sellerCinLength)],
        pointOfContact: [msgRequired],
        externalSystemReference: [msgMaxLength(maxSellerExternalSystemReferenceLength)],
        minimalPartialPortionThreshold: [msgMaxNumber(maxPercentageValue)],
      },
      () => this.form,
      () => this.submitted,
    );
  }

  private _isPartialPortionThresholdValue$(): Observable<boolean> {
    return this.form.controls.minimalPartialPortionThreshold.valueChanges.pipe(
      startWith(this.form.controls.minimalPartialPortionThreshold.value),
      map((value) => value !== null && value > 0),
    );
  }
}
