import { Inject, Injectable } from '@angular/core';

import { merge, Observable } from 'rxjs';

import { InactivityLogoutMangerService } from '../inactivity-logout/manager/inactivity-logout-manager.service';
import { KeepAliveBroadcastClientService } from './broadcast-client/keep-alive-broadcast-client.service';

import { KEEP_ALIVE_SESSION_EVENTS_SOURCE } from './keep-alive-session.types';
import { KeepAliveEvent, KeepAliveEventsSource } from './keep-alive-session.types';

@Injectable({
  providedIn: 'root',
})
export class KeepAliveSessionService {
  // internal stream of any alive trigger
  // emitted value states if this should get broadcasted
  private _keepAliveTriggers$!: Observable<KeepAliveEvent>;

  constructor(
    @Inject(KEEP_ALIVE_SESSION_EVENTS_SOURCE)
    private _keepAliveEventSources: KeepAliveEventsSource[],
    private _inactivityLogoutManager: InactivityLogoutMangerService,
    private _keepAliveBroadcastClient: KeepAliveBroadcastClientService,
  ) {}

  initialize(): void {
    // extract all keep alive streams from producers
    const producersEventStreams$ = this._keepAliveEventSources.map((producer) => producer.events$);
    // merge streams into one
    this._keepAliveTriggers$ = merge(...producersEventStreams$);
    // subscribe and handle valid events
    this._keepAliveTriggers$.subscribe(this._keepAlive);
  }

  private _keepAlive = (event: KeepAliveEvent): void => {
    this._restartInactivityTimer();
    this._broadcastToOtherInstances(event);
  };

  private _restartInactivityTimer(): void {
    this._inactivityLogoutManager.restartTimer();
  }

  private _broadcastToOtherInstances(event: KeepAliveEvent): void {
    this._keepAliveBroadcastClient.broadcastKeepAliveEvent(event);
  }
}
