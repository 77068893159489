import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { State } from '../interface/state.interface';

@Injectable()
export class StatesService {
  states: State[] = [];

  constructor(private router: Router) {}

  init() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => this.splitIntoPathAndQueryParams(e.urlAfterRedirects));

    this.splitIntoPathAndQueryParams(this.router.url);
  }

  splitIntoPathAndQueryParams(url: string) {
    const currentState: State = {
      url: this.getUrlWithoutParams(url),
      params: this.getParams(url),
    };

    this.states.push(currentState);
  }

  getUrlWithoutParams(url: string) {
    const paramsIndex = url.indexOf('?');

    return url.substring(0, paramsIndex > -1 ? paramsIndex : url.length);
  }

  getParams(url: string) {
    const params: Record<string, string> = {};
    const paramsIndex = url.indexOf('?');

    if (paramsIndex > -1) {
      const paramsString = url.substr(paramsIndex + 1, url.length);

      paramsString.split('&').forEach((paramString) => {
        params[paramString.split('=')[0]] = paramString.split('=')[1];
      });
    }

    return params;
  }

  getCurrentState() {
    return this.states.length > 0 ? this.states[this.states.length - 1] : null;
  }

  reloadState(queryParamsToOverwrite?: unknown) {
    const currentState = this.getCurrentState();

    this.router.navigate([currentState.url], {
      queryParams: Object.assign({}, currentState.params, queryParamsToOverwrite),
    });
  }
}
