<ng-container *ngIf="selectedRegion">
  <div
    #dropdownContainer
    class="regions"
    role="application"
    (click)="$event.stopPropagation()"
    attr.aria-label="{{ 'HEADER.REGION' | translate }}"
  >
    <button
      #dropdownButton
      class="trf-header-btn"
      [attr.aria-label]="
        (dropdownVisible
          ? 'HEADER.REGION_BUTTON_LABEL_EXPANDED'
          : 'HEADER.REGION_BUTTON_LABEL_COLLAPSED'
        ) | translate: { selectedRegion: selectedRegion.name }
      "
      [ngClass]="{ on: dropdownVisible }"
      (click)="toggleDropdown()"
      (keyup.escape)="closeDropdown()"
      (keyup.enter)="focusOnFirstElement()"
      testid="header-region-btn"
    >
      <span
        [title]="selectedRegion.name"
        class="selected-region"
      >
        {{ selectedRegion.name }}
      </span>
      <ngp-svg-icon
        class="dropdown-chevron"
        iconName="chevron-down"
        [ngClass]="{ dropdownVisible: dropdownVisible }"
      >
      </ngp-svg-icon>
    </button>

    <div
      *ngIf="dropdownVisible"
      trfOutsideClickMonitor
      (trfOutsideClick)="closeDropdown()"
      [ignoreElements]="[dropdownButton]"
      class="dropdown"
      testid="regions-dropdown-container"
    >
      <ngp-inline-loader [loading]="!availableRegions"></ngp-inline-loader>

      <div class="regions-header">
        <span>{{ "HEADER.SELECT_REGION" | translate }}</span>
      </div>
      <div
        *ngIf="availableRegions"
        class="regions-list"
      >
        <ul
          #ulElement
          class="list-unstyled"
          role="listbox"
        >
          <li
            *ngFor="let region of availableRegions; let i = index"
            class="region-item"
            trfArrowKeysFocus
            role="option"
            tabindex="0"
            [elementsLength]="availableRegions.length"
            [ngClass]="{ active: isActive(region) }"
            [index]="i"
            (keyup.escape)="closeDropdown($event)"
            (click)="onRegionSelect(region)"
            (keyup.enter)="onRegionSelect(region)"
            (focusOnFirst)="focusOnFirstElement()"
            (focusOnLast)="focusOnLastElement()"
            [attr.testid]="'region-' + region.entityId"
          >
            <span
              [title]="region.name"
              class="region-name"
              >{{ region.name }}</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
