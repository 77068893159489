import { getLocationOrigin } from '../../../utils/window';

declare global {
  interface Window {
    utag: {
      data: {
        tealium_account: string;
      };
    };
    TMS: {
      trackView: (tealiumEvent: TealiumEvent) => void;
    };
    utag_data: Record<string, string>;
  }
}

export function trackView(data: TealiumEvent) {
  if (window.TMS?.trackView) {
    window.TMS.trackView({ ...data });
  }
}

export function getUTagData(lang: string, url: string) {
  const [, category, subcategory = ''] = url.split('/');
  return {
    ...window.utag_data,
    site_domain: getLocationOrigin(),
    page_language: normalizeLand(lang),
    page_category: category,
    page_name: getName(category),
    page_type: getPageType(category),
    page_subcategory: subcategory,
    page_url: getPageURL(category, subcategory),
  };
}

const PAGE_NAME_SEPARATOR = ' : ';

type TealiumEvent = Record<string, string> & {
  site_domain?: string;
  page_url?: string;
  page_name?: string;
  page_language?: string;
  page_type?: string;
  page_category?: string;
  page_subcategory?: string;
};

function normalizeLand(lang: string) {
  const [lang1, lang2] = lang.split('-');
  return lang1.toLocaleLowerCase() + lang2.toUpperCase();
}

function getPageType(category: string) {
  return category === 'dashboard' ? 'homepage' : 'other';
}

function getName(category: string) {
  return (
    {
      dashboard: `dashboard${PAGE_NAME_SEPARATOR}funding${PAGE_NAME_SEPARATOR}overview`,
      'data-uploads': `data-uploads${PAGE_NAME_SEPARATOR}status${PAGE_NAME_SEPARATOR}data uploads`,
      alerts: `alerts${PAGE_NAME_SEPARATOR}actions${PAGE_NAME_SEPARATOR}messages and alerts`,
      clients: `clients${PAGE_NAME_SEPARATOR}clients`,
      transactions: `transactions${PAGE_NAME_SEPARATOR}transactions`,
      templates: `templates${PAGE_NAME_SEPARATOR}file-formats${PAGE_NAME_SEPARATOR}templates`,
      settings: `settings${PAGE_NAME_SEPARATOR}users${PAGE_NAME_SEPARATOR}settings`,
      'current-region': `current-region${PAGE_NAME_SEPARATOR}calendars${PAGE_NAME_SEPARATOR}current region`,
      'funder-groups': `funder-groups${PAGE_NAME_SEPARATOR}funders`,
      'technical-data': `technical-data${PAGE_NAME_SEPARATOR}technical-data`,
    }[category] ?? `${category}${PAGE_NAME_SEPARATOR}${category}`
  );
}

function getPageURL(category: string, subcategory: string) {
  return (
    '/' +
    [
      category,
      subcategory,
      getName(category).split(PAGE_NAME_SEPARATOR).slice(-1)[0].replace(/\s/g, '-'),
    ]
      .filter(Boolean)
      .join('/')
  );
}
