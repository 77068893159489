<ng-container *ngIf="message">
  <ngp-svg-icon
    class="icon {{ type }}"
    data-testid="svg-icon-component"
    [iconName]="icons[type]!"
  ></ngp-svg-icon>
  <span
    class="msg"
    role="alert"
    >{{ message }}</span
  >
</ng-container>
