import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Preview } from '@demica/core/core';

@Component({
  selector: 'trf-preview-header',
  templateUrl: 'preview-header.component.html',
  styleUrls: ['preview-header.component.sass'],
})
export class PreviewHeaderComponent {
  @Input()
  preview: Preview;

  @Input()
  logoPath?: string;

  @Input()
  translateName?: boolean;

  @Input()
  header: string;

  @Output()
  viewVersions = new EventEmitter();

  @Output()
  editClick = new EventEmitter();

  @Output()
  deleteClick = new EventEmitter();

  constructor(private translate: TranslateService) {}

  onViewVersions() {
    this.viewVersions.emit();
  }

  onEdit() {
    this.editClick.emit();
  }

  onDelete() {
    this.deleteClick.emit();
  }

  getName() {
    if (this.translateName) {
      return this.translate.instant(this.preview.name);
    }
    return this.preview.name;
  }
}
