import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { MenuItemConfig, MenuOption, UserService } from '@demica/core/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifHasMenuSectionAuthority]',
})
export class IfHasMenuSectionAuthorityDirective implements OnInit, OnDestroy {
  @Input()
  ifHasMenuSectionAuthority: MenuOption[];

  private destroySubject = new Subject<void>();

  private createdView: ViewRef;

  constructor(
    private userService: UserService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
  ) {}

  ngOnInit(): void {
    if (!this.templateRef || this.ifHasMenuSectionAuthority.length === 0) return;

    // TODO TRFUI-4819: To discuss what we should do in case of permissions changing in the token
    this.userService.currentUser.pipe(first()).subscribe((user) => {
      const result = this.ifHasMenuSectionAuthority
        .map((value) => <MenuItemConfig>value.element)
        .map((value) => user.hasAuthority(value.permissions.requiredAuthority))
        .reduce((previousValue, currentValue, currentIndex, array) =>
          array.some((value) => value === true),
        )
        .valueOf();

      if (result) {
        if (this.createdView) {
          this.createdView.destroy();
        }
        this.createdView = this.viewContainer.createEmbeddedView(this.templateRef);
      }
      return;
    });
    return;
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
