import { inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { browserConfirm, ignoreDiscardUnsavedChanges } from './window';

export function useConfirmDiscardOfUnsavedChanges(): () => boolean {
  const translateService = inject(TranslateService);

  return () => {
    if (!ignoreDiscardUnsavedChanges()) {
      return browserConfirm(translateService.instant('UNSAVED_CHANGES_MODAL.MESSAGE'));
    } else return true;
  };
}
