import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Key } from '@demica/accessibility';
import { Order, SortParams } from '@demica/core/core';

import { DataTableFocusOnSortService } from '../../service/data-table-focus-on-sort.service';

import { DataTableComponent } from './data-table.component';

import { ColumnDefinition } from './column-definition.interface';

enum SortOrder {
  ASC = 'ascending',
  DESC = 'descending',
}

@Component({
  selector: 'trf-table-header',
  templateUrl: 'text-table-header.component.html',
  styleUrls: ['text-table-header.component.sass'],
})
export class TextTableHeaderComponent implements OnInit, AfterViewInit {
  @Input()
  columns?: ColumnDefinition[];
  @Input()
  initialSort?: SortParams;
  @Input()
  hasSelectAllCheckbox?: boolean;
  @Input()
  allSelected = false;
  @Input()
  indeterminateSelected = false;

  @Output()
  sortChanged = new EventEmitter<SortParams>();

  @Output()
  checkboxClicked = new EventEmitter<boolean>();

  @Output()
  moveFocusToRows = new EventEmitter<number>();

  @ViewChildren('tdElement')
  tdElements: QueryList<ElementRef>;

  currentSort: SortParams;

  private _destroyed$ = new Subject<void>();

  constructor(
    private focusOnSortService: DataTableFocusOnSortService,
    @Optional() private tableComponent: DataTableComponent,
  ) {}

  ngOnInit(): void {
    this.currentSort = this.initialSort ? this.initialSort : null;
    if (this.tableComponent?.visibleColumns$) {
      this.tableComponent.visibleColumns$.pipe(takeUntil(this._destroyed$)).subscribe((columns) => {
        this.columns = columns;
      });
    }
  }

  ngAfterViewInit(): void {
    this.tdElements.toArray().forEach((item: ElementRef, index: number) => {
      item.nativeElement.setAttribute('data-index', index);
    });
    if (this.focusOnSortService.manualSorted.getValue()) {
      this.restoreFocusOnSortedColumn();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onCheckboxClick() {
    this.checkboxClicked.emit(!this.allSelected);
  }

  onColumnClick(column: ColumnDefinition): void {
    if (!isSortable(column)) return;

    if (column.property === this.currentSort.property) {
      this.currentSort.order = this.currentSort.order === Order.ASC ? Order.DESC : Order.ASC;
    } else {
      this.currentSort.property = column.property;
      this.currentSort.order = Order.ASC;
    }

    this.currentSort.comparator = column.comparator;
    this.focusOnSortService.manualSorted.next(true);

    this.sortChanged.emit(this.currentSort);
  }

  shouldShowAsc(column: ColumnDefinition): boolean {
    return this.shouldShowArrow(column) && this.currentSort.order === Order.ASC;
  }

  shouldShowDesc(column: ColumnDefinition): boolean {
    return this.shouldShowArrow(column) && this.currentSort.order === Order.DESC;
  }

  shouldShowArrow(column: ColumnDefinition): boolean {
    return isSortable(column) && this.currentSort.property === column.property;
  }

  shouldShowInactiveArrow(column: ColumnDefinition): boolean {
    return isSortable(column) && this.currentSort.property !== column.property;
  }

  classes(column: ColumnDefinition): string {
    const classes = [];

    if (this.isLastColumn(column)) {
      if (column.classes) classes.push(column.classes);
      else classes.push('fixed-width');
    } else {
      classes.push(column.classes);
    }

    if (isSortable(column)) classes.push('sortable');

    return classes.join(' ');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const key = new Key(event);
    if (key.isUp || key.isDown) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onKeyup(e: KeyboardEvent, column: ColumnDefinition, i: number): void {
    if (e.key === 'ArrowDown' || e.key === 'Down') {
      return this.moveFocusToRows.emit(i);
    }
    if (e.key === 'Enter') {
      return this.onColumnClick(column);
    }
  }

  onArrowDownKeyUp(e: KeyboardEvent): void {
    const td = e.target as HTMLTableCellElement;
    this.moveFocusToRows.emit(Number(td.getAttribute('index')));
  }

  prepareAriaSort(column: ColumnDefinition): SortOrder {
    if (isSortable(column) && column.property === this.currentSort.property)
      return this.shouldShowAsc(column) ? SortOrder.ASC : SortOrder.DESC;
    return null;
  }

  private isLastColumn(column: ColumnDefinition): boolean {
    return this.columns.indexOf(column) + 1 === this.columns.length;
  }

  private async restoreFocusOnSortedColumn(): Promise<void> {
    const sortedColumn = await this.columns.findIndex(
      (item: ColumnDefinition) => item.property === this.currentSort.property,
    );

    Array.from(this.tdElements)[
      this.hasSelectAllCheckbox ? sortedColumn + 1 : sortedColumn
    ].nativeElement.focus();
  }
}

function isSortable(column: ColumnDefinition): string {
  return column.comparator && column.property;
}
