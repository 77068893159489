import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { asyncScheduler, defer, Observable, Subject } from 'rxjs';
import { startWith, subscribeOn, takeUntil, tap } from 'rxjs/operators';

import { EnvironmentsDetailsResourcesService } from '../../service/environments-details-resources.service';
import { FiltersContext } from '../../service/filters-context.service';
import { FiltersOptionsResolverService } from './filter-options-resolver/filters-options-resolver.service';

import {
  DEFAULT_DYNAMIC_FILTERS_CONFIGURATION,
  DynamicFiltersConfiguration,
} from './dynamic-filters-configuration.interface';
import { EnvironmentDetailFilterFn } from './filter-options-resolver/environment-detail-filter-fn';
import { FilterViewResources } from './filter-view-resources.interface';
import { FormFields } from './form-fields';

@Component({
  selector: 'trf-dynamic-filters',
  templateUrl: './dynamic-filters.component.html',
  styleUrls: ['./dynamic-filters.component.sass'],
  providers: [EnvironmentsDetailsResourcesService, FiltersOptionsResolverService],
})
export class DynamicFiltersComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  additionalFilters: EnvironmentDetailFilterFn[];
  @Input()
  configuration: DynamicFiltersConfiguration = DEFAULT_DYNAMIC_FILTERS_CONFIGURATION;
  @Input()
  form: UntypedFormGroup;
  @Output()
  loaded = new EventEmitter<boolean>();
  @Output()
  valueChanged = new EventEmitter();
  @ViewChild('template', { read: TemplateRef, static: true })
  template: TemplateRef<unknown>;

  formFields = FormFields;
  destroyed$ = new Subject<void>();
  availableEntries$: Observable<FilterViewResources>;

  constructor(
    private filterContext: FiltersContext,
    private transactionsDetailsResourceService: EnvironmentsDetailsResourcesService,
    private viewContainerRef: ViewContainerRef,
    private filterResolver: FiltersOptionsResolverService,
  ) {}

  ngOnInit(): void {
    this.initControls();
    this.appendFiltersToView();
    this.initFilterResolver();
    this.initGlobalFilterChangeAction();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.additionalFilters?.currentValue) {
      this.filterResolver.additionalFilters(this.additionalFilters);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private appendFiltersToView() {
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  private initControls() {
    if (!this.form) {
      this.form = new UntypedFormGroup({});
      Object.entries(this.configuration)
        .filter(([, v]) => !!v)
        .forEach(([k]) => this.form.addControl(k, new UntypedFormControl(null)));
    }
  }

  private initFilterResolver() {
    this.filterResolver.setFiltersChangeStream(
      defer(() => this.form.valueChanges.pipe(startWith(this.form.value))),
    );
    if (this.additionalFilters) this.filterResolver.additionalFilters(this.additionalFilters);
    this.availableEntries$ = this.filterResolver.getAvailableEntries().pipe(
      tap(() => this.loaded.next(false)),
      subscribeOn(asyncScheduler),
    );
  }

  private initGlobalFilterChangeAction() {
    this.filterContext.loadFilterParametersSubject
      .pipe(
        tap(() => this.form.reset()),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
