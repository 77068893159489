import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AliasesSearchParams,
  EntityId,
  EntityReference,
  HasEntityId,
  maxUserDefinedColumnLength,
  Transaction,
} from '@demica/core/core';

import { requireSelect } from '../../../../forms/validators';
import { AliasFormValidations } from '../../../../validation/alias-form-validations';
import { AliasesValidator } from '../../../../validation/analysis-code-form-validations';
import { AliasesSearchClass } from '../../aliases-search.class';

export type AliasesManagementSearchForm = FormGroup<{
  transaction: FormControl<EntityReference>;
  analysisGroup: FormControl<EntityReference>;
  analysisCodeValue: FormControl<string>;
}>;

@Component({
  selector: 'trf-aliases-management-search',
  templateUrl: './aliases-management-search.component.html',
  styleUrls: ['./aliases-management-search.component.sass'],
})
export class AliasesManagementSearchComponent
  extends AliasesSearchClass
  implements OnInit, OnDestroy
{
  @Input()
  transaction: Transaction;
  @Input()
  showAddButton: boolean;

  @Output()
  searchChange = new EventEmitter<AliasesSearchParams>();
  @Output()
  clearChange = new EventEmitter<void>();
  @Output()
  addChange = new EventEmitter<void>();

  submitted = false;

  form: AliasesManagementSearchForm = this._fb.group({
    transaction: [null],
    analysisGroup: [null],
    analysisCodeValue: [null],
  });

  requireValidationsMessages = this.aliasFormValidations.requireValidationsMessages(
    this.form,
    () => this.submitted,
  );

  maxLengthValidation = AliasesValidator.code(this.form, () => false);

  readonly maxLength = maxUserDefinedColumnLength;
  private _destroyed$ = new Subject<void>();

  constructor(private _fb: FormBuilder, private aliasFormValidations: AliasFormValidations) {
    super();
  }

  ngOnInit(): void {
    if (!this.transaction) {
      this.form.controls.transaction.setValidators(requireSelect);
    }

    this.loadDefaultFilters();

    this.form.controls.transaction.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((transaction) => {
        if (!transaction) {
          this.loadDefaultFilters();
        } else {
          this.filterByTransaction(transaction);
        }
        this.form.controls.analysisGroup.setValue(null);
      });
  }

  onAddSuccess(): void {
    if (this.submitted) {
      this.onSearch();
    }
  }

  onAddNew(): void {
    this.addChange.emit();
  }

  onSearch(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.searchChange.emit(this._preparePayload());
    }
  }

  onClear(): void {
    this.form.reset();
    this.clearChange.emit();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _preparePayload(): AliasesSearchParams {
    const formValue = this.form.getRawValue();
    return {
      analysisGroup: formValue.analysisGroup,
      analysisCode: formValue.analysisCodeValue,
      transactionIds: this._prepareTransactions(formValue.transaction),
    };
  }

  private _prepareTransactions(value: HasEntityId): EntityId[] {
    return value ? [value.entityId] : this.transactions.map((transaction) => transaction.entityId);
  }
}
