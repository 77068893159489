import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { NotAvailableResource } from '../../interface/not-available-resource.model';

export const NOT_AVAILABLE_RESOURCE = 'not-available-resource';

@Injectable()
export class NotAvailableResourceService {
  private notAvailableResourceSubject$ = new BehaviorSubject<NotAvailableResource | null>(null);
  notAvailableResourcePage$ = this.notAvailableResourceSubject$.asObservable();

  createPageObject(data: NotAvailableResource): void {
    this.notAvailableResourceSubject$.next(data);
  }

  clearPage(): void {
    this.notAvailableResourceSubject$.next(null);
  }
}
