import { requiredTextFieldMsgs } from '../forms/validation-messages/common-validation-messages';
import { buildMessages } from '../forms/validation-messages/message-builder';
import {
  msgDefaultMaxLength,
  msgMaxLength,
  msgMinLength,
  msgRequired,
} from '../forms/validation-messages/message-definitions';
import { hasError } from '../forms/validation-messages/message-predicates';
import {
  maxSellerCodeLength,
  maxSellerExternalSystemReferenceLength,
  maxSellerPostCodeLength,
  minSellerNameLength,
  sellerCinLength,
} from './seller-validation.consts';

export const sellerNameValidations = requiredTextFieldMsgs;
export const sellerCodeValidations = buildMessages([
  msgRequired,
  msgMaxLength(maxSellerCodeLength),
  { func: hasError('opco-code-unavailable'), key: 'ERRORS.OPCO_CODE_NOT_UNIQUE' },
  { func: hasError('code-not-unique'), key: 'TRANSACTION_COPY.SELLER.NOT_UNIQUE_CODE' },
]);
export const sellerContactNameValidations = buildMessages([
  msgDefaultMaxLength,
  msgMinLength(minSellerNameLength),
]);

export const sellerPostCodeValidations = buildMessages([msgMaxLength(maxSellerPostCodeLength)]);

export const sellerCinValidations = buildMessages([
  msgMaxLength(sellerCinLength),
  msgMinLength(sellerCinLength),
]);

export const sellerESRValidations = buildMessages([
  msgMaxLength(maxSellerExternalSystemReferenceLength),
]);
