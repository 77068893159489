<div *ngIf="loading">
  <div
    class="loader"
    data-testid="loader"
  >
    <ngp-svg-icon
      class="svg-icon-spin svg-icon-size-4"
      iconName="loading-spinner"
    ></ngp-svg-icon>
  </div>
</div>
