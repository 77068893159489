import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { maxIntegerValue } from '@demica/core/core';

import { findIndexOf, getValidationMessages, uniqPointValidator } from '../../helpers';

import { FieldDefinition } from '../../../../forms/form';
import { ValidationMessagesConfig } from '../../../../forms/validation-messages/validation-message.interface';
import { PointValue } from '../../../../model/pointValue';
import { EditedPoint } from '../../model/edited-point.interface';

export enum FormFields {
  x = 'x',
  value = 'value',
}

type FormDefinition = Record<FormFields, FieldDefinition>;

@Component({
  selector: 'trf-points-form',
  templateUrl: './points-form.component.html',
  styleUrls: ['./points-form.component.sass'],
})
export class PointsFormComponent implements OnChanges {
  @Input()
  pointToEdit: EditedPoint;
  @Input()
  points: PointValue[];
  @Input()
  pointLabel: string;
  @Input()
  pointPlaceholder: string;
  @Input()
  numberOfDecimalPlacesForValue: number;
  @Input()
  numberOfDecimalPlacesForX: number;

  @Output()
  editComplete = new EventEmitter<EditedPoint>();

  formFields = FormFields;
  form: UntypedFormGroup;
  submitted = false;
  validations: ValidationMessagesConfig = getValidationMessages(
    () => this.form,
    () => this.submitted,
  );
  isXReadOnly: boolean;
  maxIntegerValue = maxIntegerValue;

  constructor(private fb: UntypedFormBuilder) {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pointToEdit) {
      this.initForm();
      this.shouldBeReadOnly();
    }
  }

  cancel() {
    this.editComplete.emit();
  }

  save() {
    this.submitted = true;
    if (this.form.invalid) return;
    this.editComplete.emit({ ...this.pointToEdit, point: this.form.value });
  }

  isPointUnique(fc: AbstractControl) {
    if (!fc.value || !Array.isArray(this.points)) return true;
    const existingPoints = [...this.points];
    if (this.pointToEdit.index !== -1) existingPoints.splice(this.pointToEdit.index, 1);
    return findIndexOf(existingPoints, { x: fc.value }) === -1;
  }

  shouldBeReadOnly() {
    if (this.pointToEdit.index > 0) this.isXReadOnly = false;
    if (this.pointToEdit.point.x === '0') this.isXReadOnly = true;
  }

  private getFormControls(): FormDefinition {
    return {
      x: [
        null,
        [Validators.required, uniqPointValidator((fc: AbstractControl) => this.isPointUnique(fc))],
      ],
      value: [null, [Validators.required]],
    };
  }

  private initForm() {
    this.form.patchValue({
      ...this.pointToEdit.point,
      value: this.pointToEdit.point.value,
    });
  }

  private buildForm() {
    this.form = this.fb.group(this.getFormControls());
  }
}
