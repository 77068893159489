import { AfterViewInit, Directive, inject } from '@angular/core';

import { useConfirmDiscardOfUnsavedChanges } from '../../utils/browser-modal';
import { SlideinContext } from './slidein-context';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface SlideInOnClose {
  onClose(cb?: () => void): void;
}

@Directive()
export abstract class SlideinContainerAbstractClass implements SlideInOnClose, AfterViewInit {
  protected slideinContext = inject(SlideinContext);
  protected modal = inject(NgbActiveModal);
  protected confirmDiscardOfUnsavedChanges = useConfirmDiscardOfUnsavedChanges();

  ngAfterViewInit() {
    this.slideinContext.closeModal.subscribe(() => this.closeModal());
  }

  onClose(cb?: () => void) {
    if (this.slideinContext.isFormDirty) {
      if (this.confirmDiscardOfUnsavedChanges()) {
        this.closeModal(cb);
      }
    } else {
      this.closeModal(cb);
    }
  }

  protected closeModal(cb?: () => void) {
    if (cb) {
      cb();
    }
    this.modal.dismiss();
  }
}
