import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import {
  INVALID_NUMBER_FORMAT_ERROR_KEY,
  INVALID_NUMBER_FORMAT_MESSAGE_KEY,
  MAX_INT_EXCEEDED_ERROR_KEY,
  MIN_INT_EXCEEDED_ERROR_KEY,
} from '../form-number-row/form-number-row-error-keys';

@Component({
  selector: 'trf-form-number-row-default-validation-messages',
  templateUrl: './form-number-row-default-validation-messages.component.html',
  styleUrls: ['./form-number-row-default-validation-messages.component.sass'],
})
export class FormNumberRowDefaultValidationMessagesComponent {
  @Input()
  input: FormControl;
  @Input()
  name: string;
  @Input()
  validations?: ValidationMessage[];
  @Input()
  invalidFormatMessageKey = INVALID_NUMBER_FORMAT_MESSAGE_KEY;

  invalidFormatErrorKey = INVALID_NUMBER_FORMAT_ERROR_KEY;
  minIntExceededErrorKey = MIN_INT_EXCEEDED_ERROR_KEY;
  maxIntExceededErrorKey = MAX_INT_EXCEEDED_ERROR_KEY;

  hasError(errorName: string): boolean {
    return this.input.touched && !!this.input.errors && this.input.hasError(errorName);
  }
}
