import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

import { KeyboardNavigationStrategyService } from '../service/keyboard-navigation-strategy.service';

@Directive({
  selector: '[trfTableAccessibilityAttributesSetter]',
})
// TODO TRFV2-22638 - this could be merged with TableAttributesSetterService, and maybe unified with TableRowAttributesSetterDirective
export class TableAccessibilityAttributesSetterDirective implements AfterViewInit {
  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private keyboardNavigation: KeyboardNavigationStrategyService,
  ) {}

  ngAfterViewInit(): void {
    Array.from(this.el.nativeElement.children).forEach((value, index) => {
      this.renderer.setAttribute(value, 'data-index', index.toString());
      this.setTabindex(value as HTMLElement);
    });
  }

  setTabindex(value: HTMLElement): void {
    const focusableElement = this.keyboardNavigation.isTabIndexNeeded(value);

    if (!focusableElement) {
      this.renderer.setAttribute(value, 'tabindex', '-1');
    }
  }
}
