<ngp-tabs
  [contentTemplate]="outlet"
  [type]="tabsConfig.type"
  [label]="tabsConfig.label"
  [activeFirst]="true"
  [showLabel]="showLabel"
>
  <ngp-tab
    *ngFor="let tab of tabsToShow"
    [title]="tab.title | translate"
    [titleSuffix]="tab.titleSuffix | translate"
    [tooltipContent]="tab.tooltipContent"
    [iconAriaDesc]="tab.title"
    [enabled]="tab.enabled"
    [hidden]="tab.hidden"
    [isActive]="tab.active"
    (selected)="onTabClick(tab)"
    [testId]="tab.testId"
  ></ngp-tab>
</ngp-tabs>
<ng-template #outlet>
  <router-outlet (activate)="setActiveTabs()"></router-outlet>
</ng-template>
