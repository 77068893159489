import { Component, ElementRef, Input, OnChanges } from '@angular/core';

import { joinIfNotEmpty, Preview } from '@demica/core/core';

@Component({
  selector: 'trf-contact-table-cell',
  templateUrl: 'contact-table-cell.component.html',
  styleUrls: ['contact-table-cell.component.sass'],
})
export class ContactTableCellComponent implements OnChanges {
  @Input()
  preview?: Preview;

  title: string;

  constructor(private elRef: ElementRef) {}

  ngOnChanges() {
    this.title = joinIfNotEmpty(
      [
        this.preview.contactPersonFirstName + ' ' + this.preview.contactPersonLastName,
        this.preview.telephoneCountryCode + ' ' + this.preview.telephoneNumber,
        this.preview.email,
      ],
      '\n',
    );
  }
}
