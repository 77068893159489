import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';

import { DELEGATE_TRANSLATE_PIPE } from '../tokens/translate-pipe.token';

/**
 * Pipe for providing translations to components within this library through dependency inversion -
 * This pipe delegates to a pipe that is provided by the application that consumes NGP components.
 *
 * Each micro frontend can provide it's own translations implementation as long
 * as it conforms to PipeTransform contract.
 */
@Pipe({
  name: 'translate',
  /* We expect the user to be able to change the language on the fly, so it cannot be a pure pipe */
  pure: false,
})
export class DefaultTranslatePipe<T> implements PipeTransform {
  constructor(@Optional() @Inject(DELEGATE_TRANSLATE_PIPE) private delegate?: PipeTransform) {}

  transform(value: T, ...args: unknown[]): string | T {
    if (!this.delegate) return value;

    return this.delegate.transform(value, ...args);
  }
}
