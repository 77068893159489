<ngp-inline-loader [loading]="loading"></ngp-inline-loader>
<form
  *ngIf="!loading"
  class="my-3"
  [formGroup]="form"
  [ngClass]="{ 'trf-form-submitted': submitted }"
>
  <div class="trf-section-content pb-4">
    <legend>{{ "LEGEND.REQUIRED_ASTERISK" | translate }}</legend>
  </div>

  <div class="trf-section-content">
    <div class="row">
      <div [class]="extendedSeller ? 'col-4' : 'col-6'">
        <trf-form-text-row
          name="clientName"
          label="OPCO_DETAILS_SLIDEIN.CLIENT_NAME"
          labelClasses="required"
          [disable]="true"
          testid="client-name"
        ></trf-form-text-row>

        <trf-form-text-row
          name="name"
          label="OPCO_DETAILS_SLIDEIN.OPCO_NAME"
          labelClasses="required"
          placeholder="OPCO_DETAILS_SLIDEIN.OPCO_NAME_PLACEHOLDER"
          [validations]="messages.opcoNameValidations"
          [inputClasses]="nameClasses"
          (inputChanges)="onNameInputChanges($event)"
          testid="opco-name"
        ></trf-form-text-row>

        <trf-form-text-row
          name="code"
          label="OPCO_DETAILS_SLIDEIN.OPCO_CODE"
          labelClasses="required"
          placeholder="OPCO_DETAILS_SLIDEIN.OPCO_CODE_PLACEHOLDER"
          [maxLength]="maxSellerCodeLength"
          [validations]="messages.opcoCodeValidations"
          testid="opco-code"
        ></trf-form-text-row>

        <trf-form-select-row
          name="country"
          label="OPCO_DETAILS_SLIDEIN.COUNTRY"
          labelClasses="required"
          defaultLabel="OPCO_DETAILS_SLIDEIN.COUNTRY_PLACEHOLDER"
          [options]="countryOptions"
          [validations]="messages.required"
          testid="country"
        ></trf-form-select-row>

        <trf-form-percentage-row
          *ngIf="partialsEnabled"
          name="minimalPartialPortionThreshold"
          label="OPCO_DETAILS_SLIDEIN.MINIMAL_PARTIAL_PORTION_THRESHOLD"
          placeholder="0"
          [helperToolTip]="
            'OPCO_DETAILS_SLIDEIN.MINIMAL_PARTIAL_PORTION_THRESHOLD_INFO' | translate
          "
          [showPercentLabel]="true"
          [formValueAsNumber]="true"
          [trfWarningValidation]="isPartialPortionThresholdWarning$ | async"
          [trfWarningValidationMessage]="
            'OPCO_DETAILS_SLIDEIN.MINIMAL_PARTIAL_PORTION_THRESHOLD_WARNING' | translate
          "
          data-testid="minimal-partial-portion-threshold"
        ></trf-form-percentage-row>

        <trf-form-text-row
          name="contactPersonFirstName"
          label="OPCO_DETAILS_SLIDEIN.CONTACT_PERSON_FIRST_NAME"
          placeholder="OPCO_DETAILS_SLIDEIN.CONTACT_PERSON_FIRST_NAME_PLACEHOLDER"
          [validations]="messages.opcoContactName"
          testid="contact-person-first-name"
        ></trf-form-text-row>

        <trf-form-text-row
          name="contactPersonLastName"
          label="OPCO_DETAILS_SLIDEIN.CONTACT_PERSON_LAST_NAME"
          placeholder="OPCO_DETAILS_SLIDEIN.CONTACT_PERSON_LAST_NAME_PLACEHOLDER"
          [validations]="messages.opcoContactName"
          testid="contact-person-last-name"
        ></trf-form-text-row>
      </div>
      <div [class]="extendedSeller ? 'col-4' : 'col-6'">
        <trf-form-text-row
          name="address1"
          label="OPCO_DETAILS_SLIDEIN.OPCO_ADDRESS_LINE_1"
          labelClasses="required"
          placeholder="OPCO_DETAILS_SLIDEIN.OPCO_ADDRESS_PLACEHOLDER"
          [validations]="messages.addressLine1"
          testid="address-line1"
        ></trf-form-text-row>

        <trf-form-text-row
          name="address2"
          label="OPCO_DETAILS_SLIDEIN.OPCO_ADDRESS_LINE_2"
          placeholder="OPCO_DETAILS_SLIDEIN.OPCO_ADDRESS_PLACEHOLDER"
          [validations]="messages.maxLength"
          testid="address-line2"
        ></trf-form-text-row>

        <trf-form-text-row
          name="address3"
          label="CLIENTS.CLIENT_ADDRESS_LINE_3"
          placeholder="CLIENTS.CLIENT_ADDRESS_PLACEHOLDER"
          [validations]="messages.maxLength"
          testid="address-line3"
        ></trf-form-text-row>

        <trf-form-text-row
          name="address4"
          label="OPCO_DETAILS_SLIDEIN.OPCO_ADDRESS_LINE_4"
          placeholder="OPCO_DETAILS_SLIDEIN.OPCO_ADDRESS_PLACEHOLDER"
          [validations]="messages.maxLength"
          testid="address-line4"
        ></trf-form-text-row>

        <trf-form-text-row
          *ngIf="extendedSeller"
          name="postCode"
          label="OPCO_DETAILS_SLIDEIN.POST_CODE"
          placeholder="OPCO_DETAILS_SLIDEIN.POST_CODE_PLACEHOLDER"
          [validations]="messages.postCode"
          [maxLength]="maxSellerPostCodeLength"
          data-testid="post-code-input"
        ></trf-form-text-row>

        <trf-form-telephone-row
          numberField="telephoneNumber"
          countryCodeField="telephoneCountryCode"
          label="OPCO_DETAILS_SLIDEIN.TELEPHONE"
          countryCodeLabel="OPCO_DETAILS_SLIDEIN.TELEPHONE_COUNTRY_CODE_LABEL"
          numberPlaceholder="OPCO_DETAILS_SLIDEIN.TELEPHONE_PLACEHOLDER"
          countryCodePlaceholder="OPCO_DETAILS_SLIDEIN.TELEPHONE_COUNTRY_CODE_PLACEHOLDER"
          testid="telephone"
        ></trf-form-telephone-row>

        <trf-form-text-row
          *ngIf="!extendedSeller"
          name="email"
          label="OPCO_DETAILS_SLIDEIN.EMAIL"
          placeholder="OPCO_DETAILS_SLIDEIN.EMAIL_PLACEHOLDER"
          [validations]="messages.email"
          testid="email"
        ></trf-form-text-row>
      </div>
      <div
        *ngIf="extendedSeller"
        class="col-4"
      >
        <trf-form-text-row
          name="email"
          label="OPCO_DETAILS_SLIDEIN.EMAIL"
          placeholder="OPCO_DETAILS_SLIDEIN.EMAIL_PLACEHOLDER"
          [validations]="messages.email"
          testid="email"
        ></trf-form-text-row>

        <trf-form-text-row
          name="customerIdentificationNumber"
          label="OPCO_DETAILS_SLIDEIN.CUSTOMER_IDENTIFICATION_NUMBER"
          placeholder="OPCO_DETAILS_SLIDEIN.CUSTOMER_IDENTIFICATION_NUMBER_PLACEHOLDER"
          [validations]="messages.customerIdentificationNumber"
          [maxLength]="sellerCinLength"
          data-testid="customer-id-number-input"
        ></trf-form-text-row>

        <trf-form-select-row
          name="standardIndustryCode"
          label="OPCO_DETAILS_SLIDEIN.STANDARD_INDUSTRY_CODE"
          defaultLabel="OPCO_DETAILS_SLIDEIN.STANDARD_INDUSTRY_CODE_PLACEHOLDER"
          [options]="standardIndustryCodeOptions"
          data-testid="industry-code-select"
        ></trf-form-select-row>

        <trf-form-text-row
          name="pointOfContact"
          label="OPCO_DETAILS_SLIDEIN.POINT_OF_CONTACT"
          labelClasses="required"
          placeholder="OPCO_DETAILS_SLIDEIN.POINT_OF_CONTACT_PLACEHOLDER"
          [validations]="messages.required"
          data-testid="point-of-contact-input"
        ></trf-form-text-row>

        <trf-form-text-row
          name="externalSystemReference"
          label="OPCO_DETAILS_SLIDEIN.EXTERNAL_SYSTEM_REFERENCE"
          placeholder="OPCO_DETAILS_SLIDEIN.EXTERNAL_SYSTEM_REFERENCE_PLACEHOLDER"
          [validations]="messages.externalSystemReference"
          [maxLength]="maxOpcoESRLength"
          data-testid="external-system-reference-input"
        ></trf-form-text-row>

        <trf-form-select-row
          name="legalStatus"
          label="OPCO_DETAILS_SLIDEIN.LEGAL_STATUS"
          translationPrefix="OPCO_LEGAL_STATUS"
          defaultLabel="OPCO_DETAILS_SLIDEIN.LEGAL_STATUS_PLACEHOLDER"
          [options]="legalStatusOptions"
          data-testid="legal-status-input"
        ></trf-form-select-row>

        <trf-form-text-row
          name="localTaxReferenceNumber"
          label="OPCO_DETAILS_SLIDEIN.LOCAL_TAX_REFERENCE_NUMBER"
          placeholder="OPCO_DETAILS_SLIDEIN.LOCAL_TAX_REFERENCE_NUMBER_PLACEHOLDER"
          data-testid="tax-reference-number-input"
        ></trf-form-text-row>
      </div>
    </div>
  </div>
  <ng-container *ngIf="extendedSeller">
    <div class="trf-section-header space-between">
      <h4>{{ "SELLER_BANK_ACCOUNT.TABLE_HEADER_TITLE" | translate }}</h4>
      <ngp-button
        classes="tertiary"
        key="SELLER_BANK_ACCOUNT.ADD_ACCOUNT_BUTTON"
        icon="plus"
        (click)="addAccount()"
        data-testid="add-account-button"
      ></ngp-button>
    </div>
    <div
      *ngIf="!!bankAccountData.length"
      class="trf-section table"
    >
      <trf-data-table
        tableClasses="disabled-rows"
        [dataSource]="dataSource"
        [headerConfiguration]="headerConfig"
        [rowConfiguration]="rowConfig"
        [tableLabel]="'SELLER_BANK_ACCOUNT.TABLE_HEADER_TITLE'"
        testid="pricing-rules-table"
      >
      </trf-data-table>
    </div>

    <div
      *ngIf="!bankAccountData.length"
      class="no-account-information"
    >
      <span>{{ "SELLER_BANK_ACCOUNT.NO_ACCOUNTS_INFORMATION" | translate }}</span>
    </div>
  </ng-container>

  <div class="trf-modal-controls-divider pt-3 trf-section-buttons space-between">
    <ngp-button
      classes="tertiary"
      key="OPCO_DETAILS_SLIDEIN.BUTTON_CLOSE"
      (click)="onClose()"
      testid="opco-close"
    ></ngp-button>

    <ngp-button
      key="OPCO_DETAILS_SLIDEIN.BUTTON_SAVE"
      [classes]="disabledSaveButton ? 'inactive' : 'primary'"
      (click)="onSave()"
      testid="opco-save"
    ></ngp-button>
  </div>
</form>
