import { NameAvailabilityResponse } from '@demica/resources/common';

export interface NameAvailabilityValidationErrorDescriptor {
  'name-unavailable': boolean;
  [k: string]: boolean;
}

export type NameAvailabilityValidationErrors = NameAvailabilityValidationErrorDescriptor | null;

export function nameAvailabilityResponseToValidationErrors(
  response: NameAvailabilityResponse,
): NameAvailabilityValidationErrors {
  if (!response.nameAvailable) return { 'name-unavailable': true };

  return null;
}
