import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ReportDefinition } from '@demica/core/core';

import { requiredMsg } from '../forms/validation-messages/common-validation-messages';
import { buildMessages } from '../forms/validation-messages/message-builder';
import { msgRequired } from '../forms/validation-messages/message-definitions';
import { hasError } from '../forms/validation-messages/message-predicates';

@Injectable({
  providedIn: 'root',
})
export class ReportFormValidations {
  requireValidations = requiredMsg;
  reportDefinitionValidations = buildMessages([
    msgRequired,
    {
      func: hasError('report-definition-deactivated'),
      key: 'ERRORS.REPORT_DEFINITION_DEACTIVATED',
    },
  ]);

  validate(): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
      const definition = control.value;
      return responseToValidationErrors(definition);
    };
  }
}

function responseToValidationErrors(report: ReportDefinition): ValidationErrors | null {
  if (report != null && !report.active) return { 'report-definition-deactivated': true };

  return null;
}
