import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import {
  CustomRestResponseWithMetadata,
  encodeEndpointApiV2,
  PostResponse,
} from '@demica/resources/common';

import { toCustomPageableData, toData } from '../../../../service/rest/response-mapping';

import { EntityId } from '../../../../interface/has-entity-id.interface';
import { HttpContextType } from '../../../../interface/http-context.interface';
import { DictionaryEntry } from '../../../../model/dictionary-entry.interface';
import {
  createPaginationParams,
  defaultTransactionCustomResponse,
  PageRequest,
  TransactionMeta,
} from '../../../../model/pageable-data';
import { PushRequest } from '../../../../model/push-request.interface';
import { CustomPageRestResponse, RestResponse } from '../../../../model/response.interface';
import {
  CodeAvailabilityResponse,
  NameAvailabilityResponse,
} from '../../../../model/rest-response/availability-response';
import { Version } from '../../../../model/version.interface';
import { encodeParams } from '../../../../security/encode-params';
import { encodeEndpoint } from '../../../../security/encode-url';
import { AnalysisGroupResource } from '../../alias/analysis-group.interface';
import { TransactionClientOverview } from '../../organisation/model/transaction-client-overview.interface';
import { TransactionOpcoAssignment } from '../../organisation/model/transaction-opco-assignment.interface';
import { RelatedSubjects } from '../model/related-subjects.interface';
import { TransactionCollection } from '../model/transaction-collection.interface';
import { TransactionCopyRequest } from '../model/transaction-copy-request.interface';
import { TransactionDataSource } from '../model/transaction-data-source.interface';
import { TransactionPushResponse } from '../model/transaction-push-response.interface';
import { Transaction, TransactionBaseResource } from '../model/transaction.interface';

@Injectable()
export class TransactionResourceService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  checkNameAvailable(name: string, transactionId?: EntityId): Observable<boolean> {
    const params = encodeParams({ name, transactionId });
    const url = encodeEndpoint('resources/transactions/name-availability');
    return this.http
      .get<RestResponse<NameAvailabilityResponse>>(url, { params })
      .pipe(map((resp) => resp.data.nameAvailable));
  }

  checkCodeAvailable(code: string, transactionId?: EntityId): Observable<CodeAvailabilityResponse> {
    const params = encodeParams({ code, transactionId });
    const url = encodeEndpoint('resources/transactions/code-availability');
    return this.http.get<RestResponse<CodeAvailabilityResponse>>(url, { params }).pipe(map(toData));
  }

  // TODO: define proper return-type
  postTransaction(transaction: unknown): Observable<unknown> {
    return this.http.post(encodeEndpoint('resources/transactions'), transaction);
  }

  getTimeZoneDictionary(): Observable<DictionaryEntry[]> {
    return this.http
      .get<RestResponse<DictionaryEntry[]>>(
        encodeEndpoint('resources/transactions/time-zone-dictionary'),
      )
      .pipe(
        map(toData),
        map((timeZoneIds) =>
          timeZoneIds.map(
            (tz) =>
              ({
                entityId: tz.entityId,
                key: this.translateDictionary('TIME_ZONES.', tz.name),
                name: tz.name,
              } as DictionaryEntry),
          ),
        ),
      );
  }

  translateDictionary(prefix: string, value: string): string {
    const translatedValue = this.translate.instant(prefix + value);
    if (translatedValue.includes(prefix)) {
      return value;
    }
    return translatedValue;
  }

  getTransaction(id: EntityId): Observable<Transaction> {
    const url = encodeEndpoint('resources/transactions/{}', id);

    return this.http.get<RestResponse<Transaction>>(url).pipe(map(toData));
  }

  verifyTransactionStatus(id: EntityId, contextType: HttpContextType): Observable<null> {
    const url = encodeEndpoint('resources/transactions/{}', id);
    const context = new HttpContext().set(contextType, true);
    return this.http.head<null>(url, { context });
  }

  getTransactionInRevision(id: EntityId, entityRevision: number): Observable<Transaction> {
    const url = encodeEndpoint('resources/transactions/{}/entity-revisions/{}', id, entityRevision);
    return this.http.get<RestResponse<Transaction>>(url).pipe(map(toData));
  }

  // TODO: define proper return-type
  putTransaction(id: EntityId, transaction: unknown): Observable<unknown> {
    return this.http.put(encodeEndpoint('resources/transactions/{}', id), transaction);
  }

  getDataSources(transactionId: EntityId, entityRevision: number, versionPreviewMode: boolean) {
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/data-sources',
          transactionId,
          entityRevision,
        )
      : encodeEndpoint('resources/transactions/{}/data-sources', transactionId);
    return this.http.get<RestResponse<TransactionDataSource[]>>(url).pipe(map(toData));
  }

  getDataSourcesForTransactions(transactionIds: EntityId[]): Observable<TransactionDataSource[]> {
    const data = { transactionIds };
    const url = encodeEndpoint('resources/transactions/data-sources');
    return this.http.post<RestResponse<TransactionDataSource[]>>(url, data).pipe(map(toData));
  }

  getAnalysisGroups(
    transactionId: EntityId,
    entityRevision: number,
    versionPreviewMode: boolean,
  ): Observable<AnalysisGroupResource[]> {
    const params = versionPreviewMode ? encodeParams({ entityRevision }) : null;
    const url = encodeEndpoint('resources/transactions/{}/analysis-groups', transactionId);
    return this.http.get<RestResponse<AnalysisGroupResource[]>>(url, { params }).pipe(map(toData));
  }

  getAnalysisGroupsForTransactions(
    transactionIds: EntityId[],
  ): Observable<AnalysisGroupResource[]> {
    const data = { transactionIds };
    const url = encodeEndpoint('resources/transactions/analysis-groups');
    return this.http.post<RestResponse<AnalysisGroupResource[]>>(url, data).pipe(map(toData));
  }

  checkTransactionVersionNameAvailable(
    name: string,
    transactionId: EntityId,
  ): Observable<NameAvailabilityResponse> {
    const params = encodeParams({ name });
    const url = encodeEndpoint(
      'resources/transactions/{}/version-name-availability',
      transactionId,
    );
    return this.http
      .get<RestResponse<NameAvailabilityResponse>>(url, { params: params })
      .pipe(map(toData));
  }

  getTransactionVersions(id: EntityId): Observable<Version[]> {
    const url = encodeEndpoint('resources/transactions/{}/versions', id);
    return this.http.get<RestResponse<Version[]>>(url).pipe(map(toData));
  }

  // TODO: define proper return-type
  postTransactionVersion(transactionId: EntityId, data: unknown): Observable<unknown> {
    return this.http.post(
      encodeEndpoint('resources/transactions/{}/versions', transactionId),
      data,
    );
  }

  getAvailableOpcos(clientId: number): Observable<TransactionOpcoAssignment[]> {
    const url = encodeEndpoint(
      'resources/transaction-dependencies/clients/{}/available-opcos',
      clientId,
    );
    return this.http.get<RestResponse<TransactionOpcoAssignment[]>>(url).pipe(map(toData));
  }

  getPushedRelatedSubjects(
    transactionId: EntityId,
    environmentId: EntityId,
    versionId: number,
  ): Observable<RelatedSubjects[]> {
    const params = encodeParams({ environmentId, versionId });
    const url = encodeEndpoint(
      'resources/transactions/{}/push-requests/related-objects-info',
      transactionId,
    );
    return this.http.get<RestResponse<RelatedSubjects[]>>(url, { params }).pipe(map(toData));
  }

  getCollections(
    transactionId: EntityId,
    entityRevision: number,
    versionPreviewMode: boolean,
    templateCollections: boolean,
  ): Observable<TransactionCollection[]> {
    const params = encodeParams({ templateCollections });
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/collections',
          transactionId,
          entityRevision,
        )
      : encodeEndpoint('resources/transactions/{}/collections', transactionId);
    return this.http.get<RestResponse<TransactionCollection[]>>(url, { params }).pipe(map(toData));
  }

  postPushRequest(transactionId: EntityId, data: unknown): Observable<TransactionPushResponse> {
    return this.http
      .post<RestResponse<TransactionPushResponse>>(
        encodeEndpoint('resources/transactions/{}/push-requests', transactionId),
        data,
      )
      .pipe(map(toData));
  }

  getPushRequests(transactionId: EntityId): Observable<PushRequest[]> {
    const params = encodeParams({ size: 1000 });
    const url = encodeEndpoint('resources/transactions/{}/push-requests', transactionId);
    return this.http.get<RestResponse<PushRequest[]>>(url, { params }).pipe(map(toData));
  }

  getTransactionsClientOverview(): Observable<TransactionClientOverview[]> {
    const params = encodeParams({ size: 1000 });
    const url = encodeEndpoint('resources/transactions/overview');
    return this.http
      .get<RestResponse<TransactionClientOverview[]>>(url, { params })
      .pipe(map(toData));
  }

  // TODO: define proper return-type
  deleteTransaction(transactionId: EntityId): Observable<unknown> {
    return this.http.delete(encodeEndpoint('resources/transactions/{}', transactionId));
  }

  // TODO: define proper return-type
  markPushRequestAsRolledBack(transactionId: EntityId, pushRequestId: number): Observable<unknown> {
    const url = encodeEndpoint(
      'resources/transactions/{}/rolled-back-push-requests/{}',
      transactionId,
      pushRequestId,
    );
    return this.http.post(url, null);
  }

  getTransactionPageable(
    searchTerm: string,
    pageRequest: PageRequest,
  ): Observable<CustomRestResponseWithMetadata<TransactionClientOverview, TransactionMeta>> {
    let httpParams = createPaginationParams(pageRequest);
    if (searchTerm) {
      httpParams = httpParams.set('searchTerm', searchTerm);
    }
    const url = encodeEndpoint('resources/transactions/overview');
    return this.http
      .get<CustomPageRestResponse<TransactionClientOverview, TransactionMeta>>(url, {
        params: httpParams,
      })
      .pipe(
        catchError(() => defaultTransactionCustomResponse()),
        map(toCustomPageableData),
      );
  }

  getTransactionsResource(): Observable<TransactionBaseResource[]> {
    return this.http
      .get<RestResponse<TransactionBaseResource[]>>(
        encodeEndpoint('resources/transactions/base-resources'),
      )
      .pipe(map(toData));
  }

  copyTransaction(request: TransactionCopyRequest): Observable<PostResponse> {
    return this.http
      .post<RestResponse<PostResponse>>(
        encodeEndpointApiV2('resources/configuration/copy'),
        request,
      )
      .pipe(map(toData));
  }
}
