import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ComponentConfiguration, HasEntityId, PushRequest, UserService } from '@demica/core/core';

import { DataSource } from '../../component/data-table/data-source.interface';
import { TextTableHeaderComponent } from '../../component/data-table/text-table-header.component';
import { PushRowComponent } from '../../component/push-row/push-row.component';

const SUPERUSER = 'SUPERUSER';
const WAITING_FOR_APPROVAL_STATUS = 'WAITING_FOR_APPROVAL';
const PUSH_REVIEW_AUTHORITY = 'REVIEW_PUSH_REVIEW';

@Component({
  selector: 'trf-push-table',
  templateUrl: 'push-table.container.html',
  styleUrls: ['./push-table.container.sass'],
})
export class PushTableContainerComponent implements OnChanges {
  @Input()
  pushRequests: PushRequest[];

  @Input()
  canDisplayRollbackButton = false;

  @Output()
  versionClicked = new EventEmitter<HasEntityId>();

  @Output()
  approveClicked = new EventEmitter<HasEntityId>();

  @Output()
  rollbackClicked = new EventEmitter<HasEntityId>();

  dataSource: DataSource<PushRequest[]> = {
    data: new BehaviorSubject([]),
  };

  headerConfig: ComponentConfiguration = {
    component: TextTableHeaderComponent,
    inputs: {
      columns: [
        { nameKey: 'PUSH_TABLE.VERSION', classes: 'column-version' },
        { nameKey: 'PUSH_TABLE.ENVIRONMENT', classes: 'column-environment' },
        { nameKey: 'PUSH_TABLE.DATE', classes: 'column-date' },
        { nameKey: 'PUSH_TABLE.REQUESTER', classes: 'column-requester' },
        { nameKey: 'PUSH_TABLE.APPROVER', classes: 'column-approver' },
        { nameKey: 'PUSH_TABLE.STANDALONE', classes: 'column-standalone' },
        { nameKey: 'PUSH_TABLE.STATUS', classes: 'column-status' },
        { nameKey: 'PUSH_TABLE.ACTIONS', classes: 'actions' },
      ],
    },
  };

  rowConfig: ComponentConfiguration = {
    component: PushRowComponent,
    inputs: {
      canDisplayRollbackButton: this.canDisplayRollbackButton,
    },
    outputs: {
      versionClick: (v: HasEntityId) => this.versionClicked.emit(v),
      approveClick: (v: HasEntityId) => this.approveClicked.emit(v),
      rollbackClick: (v: HasEntityId) => this.rollbackClicked.emit(v),
    },
  };

  constructor(private userService: UserService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.pushRequests.forEach((pushRequest) => this.updateApproveButtonAvailability(pushRequest));
    this.dataSource.data.next(changes.pushRequests.currentValue);
    this.rowConfig.inputs.canDisplayRollbackButton = this.canDisplayRollbackButton;
  }

  updateApproveButtonAvailability(pushRequest: PushRequest) {
    if (pushRequest.status.entityId !== WAITING_FOR_APPROVAL_STATUS || !pushRequest.standalone) {
      return;
    }

    let superuser = false;
    let hasAuthority = false;
    let isAuthor = false;
    this.userService.currentUser.subscribe((user) => {
      superuser = user.hasAuthority(SUPERUSER);
      hasAuthority = user.hasAuthority(PUSH_REVIEW_AUTHORITY);
      isAuthor = user.hasId(pushRequest.createdById);

      if (superuser) {
        pushRequest.canDisplayApproveButton = true;
        pushRequest.canEnableApproveButton = true;
      } else if (hasAuthority) {
        pushRequest.canDisplayApproveButton = true;
        pushRequest.canEnableApproveButton = !isAuthor && pushRequest.approvePossibility;
      }
    });
  }
}
