import { NgZone } from '@angular/core';

import { SchedulerLike, Subscription } from 'rxjs';
import { SchedulerAction } from 'rxjs/internal/types';

class EnterZoneScheduler implements SchedulerLike {
  constructor(private _zone: NgZone, private _scheduler: SchedulerLike) {}

  schedule<T>(
    work: (this: SchedulerAction<T>, state?: T) => void,
    delay?: number,
    state?: T,
  ): Subscription {
    return this._zone.run(() => this._scheduler.schedule(work, delay, state));
  }

  now(): number {
    return this._scheduler.now();
  }
}

export function enterZone(zone: NgZone, scheduler: SchedulerLike): SchedulerLike {
  return new EnterZoneScheduler(zone, scheduler);
}
