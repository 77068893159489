import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { DialogTrackingService } from '../service/dialog-tracking.service';

@Directive({
  selector: '[trfToasterAccessibility]',
})
export class ToasterAccessibilityDirective implements OnInit, OnDestroy {
  constructor(
    private dialogTrackingService: DialogTrackingService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    if (this.dialogTrackingService.openedSlideins > 0) {
      this.elementRef.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    if (this.dialogTrackingService.openedSlideins > 0) {
      this.dialogTrackingService.lastFocusedDialogElement.focus();
    }
  }
}
