import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { asyncScheduler, forkJoin, of, ReplaySubject, timer } from 'rxjs';
import { catchError, map, subscribeOn, tap } from 'rxjs/operators';

import { enterZone, leaveZone } from '@demica/utils';

import { apiUrl } from '../config/api.config';
import { AlertType } from '../interface/alert-type';
import { RestResponse } from '../model/response.interface';

const intervalAmount = 1;

@Injectable({ providedIn: 'root' })
export class AlertsCounter {
  public unreadAlertsSubject = new ReplaySubject<AlertType[]>(1);

  constructor(private http: HttpClient, private ngZone: NgZone) {
    this.initialize();
  }

  initialize() {
    const second = 1000;

    timer(second, intervalAmount * 60 * second, leaveZone(this.ngZone, asyncScheduler)).subscribe(
      () => this.updateCounter(),
    );
  }

  updateCounter() {
    this.countUnreadAlerts()
      .pipe(
        subscribeOn(enterZone(this.ngZone, asyncScheduler)),
        tap((data) =>
          this.unreadAlertsSubject.next([
            { type: 'notifications', count: data[0] },
            { type: 'actions', count: data[1] },
          ]),
        ),
      )
      .subscribe();
  }

  countUnreadAlerts() {
    const notifications = this.http
      .get<RestResponse<number>>(`${apiUrl}/resources/alerts/unread-notifications-count`)
      .pipe(catchError(() => of(0)));
    const actions = this.http
      .get<RestResponse<number>>(`${apiUrl}/resources/alerts/unread-actions-count`)
      .pipe(catchError(() => of(0)));

    return forkJoin([notifications, actions]).pipe(map((responses) => [].concat(...responses)));
  }
}
