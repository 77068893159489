import { ChangeDetectorRef, Component, HostBinding, Input, TemplateRef } from '@angular/core';

import { NgpTooltipPosition } from '@demica/ui-core-api';

@Component({
  selector: 'ngp-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.sass'],
})
export class TooltipComponent {
  @Input()
  tooltip!: string;
  position?: keyof typeof NgpTooltipPosition;
  @Input()
  tooltipTemplate!: TemplateRef<unknown>;
  @HostBinding('class')
  classes = '';

  constructor(private chdr: ChangeDetectorRef) {}

  setPosition(position: keyof typeof NgpTooltipPosition) {
    this.position = position;
    // needed because we change this property in directive and angular doesn't see this change
    this.chdr.detectChanges();
  }
}
