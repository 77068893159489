import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EntityId } from '../../interface/has-entity-id.interface';
import { EnvironmentWithRegion } from '../../model/environment.interface';
import { createPaginationParams, defaultResponse, PageRequest } from '../../model/pageable-data';
import {
  BasicRegionResponse,
  RegionCodeAvailability,
  RegionNameAvailability,
  RegionRequest,
  RegionResponse,
} from '../../model/region.interface';
import { PageRestResponse, RestResponse } from '../../model/response.interface';
import { encodeParams } from '../../security/encode-params';
import { encodeEndpoint } from '../../security/encode-url';
import { toData, toPageableData } from './response-mapping';

@Injectable({
  providedIn: 'root',
})
export class RegionsResourceService {
  constructor(private http: HttpClient) {}

  getEnvironments() {
    const url = encodeEndpoint('resources/regions/environments');
    return this.http.get<RestResponse<EnvironmentWithRegion[]>>(url).pipe(map(toData));
  }

  getAvailableRegionEnvironments(deployedTransactionsOnly: boolean) {
    const url = encodeEndpoint('resources/environments');
    const params = encodeParams({ deployedTransactionsOnly });
    return this.http.get<RestResponse<EnvironmentWithRegion[]>>(url, { params }).pipe(map(toData));
  }

  getRegionsPageable(pageRequest: PageRequest) {
    const httpParams = createPaginationParams(pageRequest);
    const url = encodeEndpoint('resources/regions');
    return this.http.get<PageRestResponse<RegionResponse>>(url, { params: httpParams }).pipe(
      catchError(() => defaultResponse<RegionResponse>()),
      map(toPageableData),
    );
  }

  getRegion(regionId: EntityId) {
    const url = encodeEndpoint('resources/regions/{}', regionId);
    return this.http.get<RestResponse<RegionResponse>>(url).pipe(map(toData));
  }

  postRegion(data: RegionRequest) {
    const url = encodeEndpoint('resources/regions');
    return this.http
      .post<RestResponse<RegionResponse>>(url, {
        ...data,
        type: 'REGION',
      })
      .pipe(map(toData));
  }

  putRegion(regionId: EntityId, data: RegionRequest) {
    const url = encodeEndpoint('resources/regions/{}', regionId);
    return this.http
      .put<RestResponse<RegionResponse>>(url, {
        ...data,
        type: 'REGION',
      })
      .pipe(map(toData));
  }

  removeRegion(regionId: EntityId) {
    const url = encodeEndpoint('resources/regions/{}', regionId);
    return this.http.delete<RestResponse<RegionResponse>>(url).pipe(map(toData));
  }

  checkCodeAvailable(code: string, regionId?: EntityId) {
    const url = encodeEndpoint('resources/regions/code-availability');
    const params = encodeParams({ code, regionId });
    return this.http.get<RestResponse<RegionCodeAvailability>>(url, { params }).pipe(map(toData));
  }

  checkNameAvailable(name: string, regionId?: EntityId) {
    const url = encodeEndpoint('resources/regions/name-availability');
    const params = encodeParams({ name, regionId });
    return this.http.get<RestResponse<RegionNameAvailability>>(url, { params }).pipe(map(toData));
  }

  getCurrentUserRegion(): Observable<BasicRegionResponse> {
    const url = encodeEndpoint('resources/regions/current/basic');
    return this.http.get<RestResponse<BasicRegionResponse>>(url).pipe(map(toData));
  }
}
