import { D3Sel } from '../d3/d3-model';
import { wrapText } from '../text/svg-text-wrap';
import { LegendConfiguration } from './model/legend-configuration.interface';
import { LegendElement } from './model/legend-element.interface';

export function appendLegend(parent: D3Sel, legendConfig: LegendConfiguration) {
  const { elements, lineHeight } = legendConfig;
  const container = parent.append('g').attr('id', legendConfig.legendContainerId);

  let height = 0;
  elements.forEach((element) => {
    const g = container.append('g');

    g.attr('data-id', element.text);
    legendConfig.interactionHandlers.forEach((ea) => g.on(ea.eventName, ea.eventHandler));

    appendLegendRectangle(g, element, lineHeight / 2 + height, legendConfig.iconSize);
    height += appendLegendText(g, element, legendConfig, height, lineHeight);
  });
}

function appendLegendRectangle(group: D3Sel, legendElem: LegendElement, y: number, size: number) {
  group
    .append('rect')
    .attr('x', 0)
    .attr('y', y)
    .attr('width', size)
    .attr('height', size)
    .attr('fill', legendElem.color)
    .attr('fill', legendElem.color);
}

function appendLegendText(
  group: D3Sel,
  element: LegendElement,
  legendConfig: LegendConfiguration,
  y: number,
  dy: number,
) {
  const { labelMarginTop, maxWidth, textMarginLeft } = legendConfig;

  group
    .append('text')
    .attr('class', element.class)
    .attr('x', 0)
    .attr('y', y)
    .attr('dy', dy)
    .text(element.text.trimLeft());

  return labelMarginTop + wrapText(group.select('.' + element.class), maxWidth, textMarginLeft);
}
