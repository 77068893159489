import { NgZone } from '@angular/core';

import { AppConfigService } from '../app-config/app-config.service';
import { AuthenticationFlowStore } from '../auth2/authentication-flow-store.service';
import { HybridFlowTokenStoreService } from './hybrid-flow-token-store.service';

import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';

export function configureHybridFlow(
  accessToken: string,
  refreshToken: string,
  keycloak: KeycloakService,
  appConfig: AppConfigService,
  flowStore: AuthenticationFlowStore,
  ngZone: NgZone,
  hybridFlowTokenStoreService: HybridFlowTokenStoreService,
  kcConfig: KeycloakConfig,
) {
  hybridFlowTokenStoreService.init();
  return ngZone.runOutsideAngular(() =>
    keycloak
      .init({
        config: kcConfig,
        initOptions: {
          flow: 'hybrid',
          token: accessToken,
          refreshToken: refreshToken,
          checkLoginIframe: false,
        },
        enableBearerInterceptor: false,
      })
      .then((result: boolean) => {
        ngZone.run(() => flowStore.setHybridFlow());
        return result;
      }),
  );
}
