import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UserActivityAuditService, UserActivityType } from '@demica/logger';

import { poolingUrls } from '../config/pooling-urls';

const AUDIT_BLACK_LIST: string[] = [...poolingUrls, 'user-accounts/my-account/login-details'];

@Injectable()
export class UserRequestAuditInterceptor implements HttpInterceptor {
  constructor(private userActivityAuditService: UserActivityAuditService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.shouldLogActivity(request)) {
      this.userActivityAuditService.addActivity({
        activityType: UserActivityType.REQUEST,
        data: {
          requestUrl: request.urlWithParams,
          requestMethod: request.method,
        },
      });
    }

    return next.handle(request);
  }

  private shouldLogActivity(request: HttpRequest<unknown>) {
    return !AUDIT_BLACK_LIST.find((url) => request.url.includes(url));
  }
}
