<div class="organisation-header">
  <div class="organisation-header-row border-bottom">
    <div class="header-start">
      <div
        *ngIf="preview.logoId"
        class="logo-container"
      >
        <trf-logo-display
          [logoHolder]="preview"
          logoPath="clients"
        ></trf-logo-display>
      </div>

      <div class="organisation-information">
        <p
          class="name"
          [title]="preview.name"
          (click)="onNameClick()"
          [attr.testId]="parentType + '-name'"
          >{{ preview.name }}</p
        >
        <p class="code">{{ preview.code }}</p>
      </div>
    </div>

    <div class="header-end">
      <div
        class="entity"
        testId="versions-revision"
      >
        <div class="entity-label"> {{ "VERSIONS.REVISION" | translate }}</div>
        <div
          class="entity-value"
          testId="versions-revision-number"
        >
          {{ preview.entityRevision }}</div
        >
      </div>

      <div class="buttons">
        <ngp-button
          *ifHasAuthority="createVersionRequiredAuthority"
          (click)="onNewButtonClick()"
          testid="create-version-button"
          classes="primary"
          icon="plus"
          key="CLIENT_VERSIONS.CREATE_NEW"
        >
        </ngp-button>
      </div>
    </div>
  </div>
</div>
